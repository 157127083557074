import { useForm } from 'react-hook-form';
import FormInput from '../../../shared/FormInput/FormInput';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import appStrings from '../../../../core/strings/appStrings';
import CloseIcon from '@mui/icons-material/Close';
import { filterLineItems } from '../cartService';
import { useDispatch, useSelector } from 'react-redux';
import { setFilteredProducts } from '../../../../core/redux/slices/cartSlice';
import { useCallback, useEffect, useState } from 'react';

function LineItemsFilter({ displayFilter }) {
  const dispatch = useDispatch();
  const { control, setValue, getValues } = useForm();

  const cartProducts = useSelector(
    state => state.cart.cartInfo.cartDetails?.cartItems
  );
  const [disabledRemoveBtn, setDisabledRemoveBtn] = useState(true);

  const onChange = useCallback(
    value => {
      if (value) {
        filterLineItems({
          filterValue: value.toLowerCase().trim(),
          products: cartProducts,
        });
      } else {
        dispatch(setFilteredProducts(null));
      }
      setDisabledRemoveBtn(value ? false : true);
    },
    [cartProducts, dispatch]
  );

  const removeFilterValue = () => {
    setValue('lineItemsFilter', '');
    dispatch(setFilteredProducts(null));
    setDisabledRemoveBtn(true);
  };

  useEffect(() => {
    if (cartProducts) {
      onChange(getValues('lineItemsFilter'));
    }
  }, [cartProducts, getValues, onChange]);

  return (
    <Grid item container alignItems="center" spacing={3}>
      <Grid item>{appStrings.order.lineItems}</Grid>
      {displayFilter && (
        <Grid item xs={3} id="lineItemsFilter">
          <FormInput
            field={{
              name: 'lineItemsFilter',
              type: 'text',
              label: appStrings.order.searchPartNo,
              noHelperText: true,
              onClick: e => e.stopPropagation(),
              onChangeCallback: value => onChange(value),
            }}
            control={control}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={removeFilterValue}
                    disabled={disabledRemoveBtn}
                    data-test-id="removeFilterValueTestId"
                    id="removeFilterValueBtn"
                  >
                    <CloseIcon
                      color={disabledRemoveBtn ? 'disabled' : 'warning'}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default LineItemsFilter;
