import CustomSkeleton from './CustomSkeleton';

function GeneralTableRowSkeleton({ columnsNumber, columnXs }) {
  const skeletonLayout = Array(columnsNumber).fill({
    xs: columnXs,
    width: '70%',
    container: true,
    justifyContent: 'center',
  });
  return (
    <CustomSkeleton data-test-id="tableRowSkeleton" layout={skeletonLayout} />
  );
}

export default GeneralTableRowSkeleton;
