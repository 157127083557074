import { Grid } from '@mui/material';
import WizardMenu from '../WizardMenu/WizardMenu';
import WizardPageContainer from '../WizardPageContainer/WizardPageContainer';
import WizardSubmitted from '../WizardSubmitted/WizardSubmitted';
import { useSelector } from 'react-redux';
import useWizardStyles from '../useWizardStyles';

function WizardContent({ isStudent }) {
  const css = useWizardStyles().classes;
  const isECAppSubmitted = useSelector(state => state.wizard.isECAppSubmitted);

  return (
    <Grid container justifyContent="center" className={css.contentContainer}>
      {isECAppSubmitted ? (
        <Grid item>
          <WizardSubmitted isStudent={isStudent} />
        </Grid>
      ) : (
        <Grid item container xs={10} md={8}>
          <Grid item xs={12}>
            <WizardMenu isStudent={isStudent} />
          </Grid>

          <Grid item xs={12}>
            <WizardPageContainer />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
export default WizardContent;
