import { makeStyles } from 'tss-react/mui';

const useNameCardStyles = makeStyles()(theme => ({
  name: {
    textTransform: 'capitalize',
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.spacing(2),
  },
  container: {
    maxHeight: theme.spacing(5),
  },
  smallButton: {
    padding: '5px !important',
    minWidth: '37px',
    height: '37px',
  },
  divider: {
    height: '36px',
    paddingTop: '2px',
  },
  inactiveStudent: {
    color: theme.palette.common.inactive,
  },
  inactiveStudentLink: {
    '& a': {
      color: theme.palette.common.inactive,
    },
  },
}));

export default useNameCardStyles;
