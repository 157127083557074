import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import CustomBox from '../CustomBox/CustomBox';
import GeneralTableRowSkeleton from './GeneralTableRowSkeleton';
import useCustomTableStyles from '../CustomTable/useCustomTableStyles';

function CustomTableSkeleton({ columns, columnsNumber = 6, columnXs = 2 }) {
  const css = useCustomTableStyles().classes;

  return (
    <CustomBox fadeBorder customClass={css.tableContainer}>
      <Table className={css.table} data-test-id="customTableComponent">
        <TableHead className={css.tableHeader}>
          <TableRow>
            {columns &&
              columns.map(column => {
                return (
                  <TableCell
                    key={column.name}
                    align={column.align ? column.align : 'inherit'}
                  >
                    {column.label}
                  </TableCell>
                );
              })}
          </TableRow>
        </TableHead>

        {Array.from(Array(3).keys()).map(index => {
          return (
            <TableBody key={`row${index}`} className={css.bodySkeleton}>
              <TableRow>
                <TableCell className={css.loadingCell}>
                  <GeneralTableRowSkeleton
                    columnsNumber={columnsNumber}
                    columnXs={columnXs}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          );
        })}
      </Table>
    </CustomBox>
  );
}

export default CustomTableSkeleton;
