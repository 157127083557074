import { FormLabel, Grid } from '@mui/material';
import {
  resetSelectedConsignedItems,
  saveInventoryList,
  updateLoadingState,
  updateSelectedCode,
} from '../../../../core/redux/slices/consignedInventorySlice';
import { useDispatch, useSelector } from 'react-redux';

import CustomBox from '../../../shared/CustomBox/CustomBox';
import FormInput from '../../../shared/FormInput/FormInput';
import appStrings from '../../../../core/strings/appStrings';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import {
  getConsignedInventoriesForEmployer,
  getRepByStudentId,
} from '../consignedInventoryService';
import useConsignedSourceStyle from './useConsignedSourceStyle';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ADMIN } from '../../../../core/navigation/roles';
import { useParams } from 'react-router-dom';

function ConsignedSource() {
  const css = useConsignedSourceStyle().classes;
  const dispatch = useDispatch();
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useForm();
  const fieldName = 'selectedWarehouse';
  const watchSelectedInventory = watch(fieldName);

  const { id: studentId } = useParams();
  const { employeeId: loggedInEmployeeId, role } = useSelector(
    state => state.login.userDetails
  );
  const isAdmin = role === ADMIN;
  const employeeIdSearched = useSelector(
    state => state.schoolsWithStudents.employeeIdSearched
  );
  const { inventoryList, selectedEmployeeId, selectedInventoryCode } =
    useSelector(state => state.consignedInventory);
  const checkEmployeeIdSearched =
    employeeIdSearched === loggedInEmployeeId ? null : employeeIdSearched;
  const [currentEmployeeId, setCurrentEmployeeId] = useState(
    isAdmin ? checkEmployeeIdSearched : loggedInEmployeeId
  );

  const getLabel = item => `${item.name} (${item.code})`;

  const inventoryOptions =
    inventoryList?.map(elem => ({
      label: getLabel(elem),
      value: elem.code,
    })) || [];

  if (!inventoryOptions.length) {
    inventoryOptions.push({ label: 'Select Warehouse', value: '' });
  }

  const selectField = {
    name: fieldName,
    type: 'select',
    options: inventoryOptions || [],
    gridLayout: { xs: 12, sm: 6, md: 4, lg: 4 },
    select: true,
    validations: { required: false },
  };

  useEffect(() => {
    if (isAdmin && !currentEmployeeId) {
      dispatch(updateLoadingState(true));
      getRepByStudentId(studentId).then(resp => {
        setCurrentEmployeeId(resp);
        if (resp === selectedEmployeeId) {
          dispatch(updateLoadingState(false));
        }
      });
    }
  }, [currentEmployeeId, dispatch, isAdmin, selectedEmployeeId, studentId]);

  useEffect(() => {
    if (currentEmployeeId && currentEmployeeId !== selectedEmployeeId) {
      dispatch(updateLoadingState(true));

      getConsignedInventoriesForEmployer({
        empId: currentEmployeeId,
        isOrder: true,
        isAdmin: isAdmin,
      })
        .then(res => {
          dispatch(saveInventoryList({ list: res, id: currentEmployeeId }));
          if (!res.length) {
            dispatch(updateLoadingState(false));
          }
        })
        .catch(e => {
          dispatch(displayError({ message: e }));
          dispatch(updateLoadingState(false));
        });
    }
  }, [currentEmployeeId, dispatch, selectedEmployeeId]);

  useEffect(() => {
    setValue(fieldName, selectedInventoryCode);
  }, [selectedInventoryCode, setValue]);

  useEffect(() => {
    if (watchSelectedInventory) {
      const newValue =
        inventoryList?.filter(elem => elem.code === watchSelectedInventory) ||
        [];
      if (newValue.length) {
        dispatch(
          updateSelectedCode({
            code: newValue[0].code,
            name: newValue[0].name,
          })
        );
        dispatch(resetSelectedConsignedItems());
      }
    }
  }, [dispatch, inventoryList, watchSelectedInventory]);

  return (
    <CustomBox customClass={css.customBox}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        className={css.container}
        data-test-id="consignedSource"
      >
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormLabel className={css.label}>
            {appStrings.consigned.loadInventoryFromWarehouse}
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} className={css.selectInventory}>
          <form name="inventory">
            <FormInput
              field={selectField}
              control={control}
              setValue={setValue}
              errors={errors}
              getValues={getValues}
            />
          </form>
        </Grid>
      </Grid>
    </CustomBox>
  );
}

export default ConsignedSource;
