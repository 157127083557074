import { makeStyles } from 'tss-react/mui';

export const useRawHtmlStyles =  makeStyles()(() => ({
  sectionWrapper: {
    overflow: 'hidden',
    backgroundSize: 'cover',
    padding: '0 5%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));
