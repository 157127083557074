/*
 * Copyright 2020 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';

// eslint-disable-next-line no-shadow
export const ErrorCode = {
  GENERAL_ERROR: 'GENERAL_ERROR',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  NOT_FOUND: 'NOT_FOUND',
};

export const ErrorContext = React.createContext({});
export const ErrorContextConsumer = ErrorContext.Consumer;
export default class ErrorContextProvider extends React.Component {
  // eslint-disable-next-line react/no-unused-class-component-methods
  hasError = false;

  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error) {
    let errorCode;
    let requestURL;

    if (error && error.response && error.response.status === 404) {
      errorCode = ErrorCode.NOT_FOUND;
    } else if (error && error.response && error.response.status === 500) {
      errorCode = ErrorCode.INTERNAL_SERVER_ERROR;
    } else {
      errorCode = ErrorCode.GENERAL_ERROR;
    }

    ErrorContextProvider.hasError = true;
    return { error, errorCode, requestURL };
  }

  componentDidCatch() {
    ErrorContextProvider.hasError = false;
  }

  render() {
    const { errorCode, error, requestURL } = this.state;
    const value = ErrorContextProvider.hasError ? { error, errorCode, requestURL } : {};
    const { children } = this.props;
    return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>;
  }
}
