import { makeStyles } from 'tss-react/mui';

const useListStudentsCardsStyles = makeStyles()(theme => {
  const spacing2 = theme.spacing(2);
  const spacing4 = theme.spacing(4);

  return {
    actionButtonsContainer: {
      width: '80%',
    },
    actionButtons: {
      width: '170px',
    },
    capitalize: {
      textTransform: 'capitalize',
    },
    paginationContainer: {
      margin: `${spacing2} ${spacing2} ${spacing4} ${spacing2}`,
    },
    hideContainer: {
      display: 'none',
    },
    loader: {
      position: 'relative',
      height: '40vh',
    },
    customBox: {
      background: theme.palette.common.neutral4,
      margin: theme.spacing(10),
      padding: theme.spacing(10),
    },
    cardsContainer: {
      background: theme.palette.common.neutral2,
    },
    infoIcon: {
      color: theme.palette.common.primary5,
    },
  };
});

export default useListStudentsCardsStyles;
