import {
  formatNumberWithCurrency,
  formatNumberWithPercentage,
} from '../../../core/services/utilsService';

import ApiService from '../../shared/Api/apiService';
import { cartTypes } from '../Cart/cartConstants';
import { defaultWarehouse } from './addProductConstants';
import { notesIds } from '../../../core/strings/IDs';
import store from '../../../core/redux/store';
import { updateProductDetails } from './productDetailsService';
import urls from '../../../core/strings/urls';
import { trackGAEvent } from '../../shared/Analytics/analyticsService';

export const addToCart = (studentId, data, isSavedItems) => {
  const cartDetails = store.getState().cart.cartInfo.cartDetails;
  const finalNotes = [];
  const itemIndex = cartDetails.cartItems.findIndex(item => {
    return (
      item.productNumber.toLowerCase() === data.productNumber.toLowerCase()
    );
  });

  let initialLnLineNotes, initialStudentNotes;
  if (itemIndex !== -1) {
    if (cartDetails.cartItems[itemIndex].notes[0]?.noteType === 1) {
      initialLnLineNotes = cartDetails.cartItems[itemIndex].notes[0].content;
    }
    const studentNotesIndex = cartDetails.cartItems[itemIndex].notes.findIndex(
      note => {
        return note.noteType === notesIds.StudentNote;
      }
    );
    if (studentNotesIndex !== -1) {
      initialStudentNotes =
        cartDetails.cartItems[itemIndex].notes[studentNotesIndex].content;
    }
  }

  if (data.lnLineNotes || initialLnLineNotes) {
    finalNotes.push({
      noteType: notesIds.LnLineNote,
      content: data.lnLineNotes,
      initialContent: initialLnLineNotes,
    });
  }
  if (data.studentNotes || initialStudentNotes) {
    finalNotes.push({
      noteType: notesIds.StudentNote,
      content: data.studentNotes,
      initialContent: initialStudentNotes,
    });
  }

  if (itemIndex !== -1) {
    return ApiService.put(urls.mergeItem, {
      studentId,
      cartId: cartDetails.cartId,
      cartItemId: cartDetails.cartItems[itemIndex].recordId,
      quantity: Number(data.quantity),
      initialQuantity: cartDetails.cartItems[itemIndex].quantity,
      notes: finalNotes,
    });
  } else {
    return ApiService.post(urls.addItem, {
      studentId,
      productNumber: data.productNumber,
      quantity: data.quantity,
      cartId: cartDetails.cartId,
      notes: finalNotes,
      cartType: isSavedItems ? cartTypes.saved : cartTypes.cart,
    });
  }
};

export const getProductDetails = (product, bp, quantity) => {
  return ApiService.get(urls.getItemDetails(product, bp, quantity));
};

export const addToOrder = (studentId, data) => {
  const cartItems = store.getState().cart.cartInfo.cartDetails.cartItems;
  if (!data.warehouse) {
    data.warehouse = defaultWarehouse.value;
  }
  const itemIndex = cartItems.findIndex(item => {
    return (
      item.productNumber.toLowerCase() === data.productNumber.toLowerCase() &&
      item.warehouse.name.toLowerCase() === data.warehouse.name.toLowerCase()
    );
  });

  const payloadInfo = {
    studentId,
    productNumber: data.productNumber,
    promoPrice: Number(data.studentPrice) || null,
    quantity: Number(data.quantity),
    warehouse: data.warehouse,
    notes: data.lnLineNotes,
    serialNumbers: data.serialNumberValue || [],
  };

  if (itemIndex !== -1) {
    return ApiService.put(urls.mergeItemToOrder, payloadInfo);
  } else {
    return ApiService.post(urls.addItemToOrder, payloadInfo);
  }
};

export const getAddingProductWarehouse = (isOrder, data) =>
  isOrder ? data.warehouse || defaultWarehouse.value : undefined;

export const handleAddProductQuantityChange = (
  productNumber,
  bp,
  quantity,
  listPrice,
  setItemDetails
) => {
  updateProductDetails(productNumber, bp, {
    quantity: quantity,
    listPrice: listPrice.substring(1),
  }).then(response => {
    setItemDetails(prev => ({
      ...prev,
      studentPrice: formatNumberWithCurrency(response.itemPriceDto.netPrice),
      discountPercentage: formatNumberWithPercentage(
        response.pricingDiscountsDto.discountPercentage
      ),
      discountAmount: formatNumberWithCurrency(
        response.pricingDiscountsDto.discountAmount
      ),
    }));
  });
};

export const handleTrackGAEvent = (isOrder, productNumber) => {
  if (!isOrder) {
    trackGAEvent('Add from Rep Search', 'Shopping Cart', productNumber);
  }
};

export const getProducts = productNumber => {
  return ApiService.get(urls.getSearchedProduct(productNumber, 10, 0));
};
