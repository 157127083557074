import React, { useEffect, useState } from 'react';
import { Avatar, Badge, Input, Skeleton } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { EDITPROFILE } from '../../../../core/navigation/paths';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteStudentProfilePicture,
  setStudentProfilePicture,
} from '../../../../core/redux/slices/commonSlice';
import {
  deleteStudentPicture,
  getStudentPicture,
  updateStudentPicture,
} from '../accountServices';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import { notificationRole } from '../../../../core/strings/appConstants';
import { useAccountInfoStyles } from '../accountStyles';

function StudentProfilePicture() {
  const dispatch = useDispatch();
  const css = useAccountInfoStyles().classes;
  const { pathname } = useLocation();
  const isEditProfile = pathname.indexOf(EDITPROFILE('')) !== -1;
  const profilePicture = useSelector(
    state => state.common.studentProfilePicture
  );
  const [loadingImage, setLoadingImage] = useState(false);
  const [field, setField] = useState(null);
  const { userId } = useSelector(state => state.login.userDetails);

  const openChoosePicture = () => {
    field.click();
  };

  const handleChangePicture = file => {
    setLoadingImage(true);
    updateStudentPicture(file, userId)
      .then(() => dispatch(setStudentProfilePicture(URL.createObjectURL(file))))
      .catch(() => dispatch(displayError({ role: notificationRole.student })))
      .finally(() => setLoadingImage(false));
  };

  const deletePicture = () => {
    setLoadingImage(true);
    deleteStudentPicture(userId)
      .then(() => dispatch(deleteStudentProfilePicture()))
      .catch(() => dispatch(displayError({ role: notificationRole.student })))
      .finally(() => setLoadingImage(false));
  };

  const editPicture = (
    <>
      <Input
        type="file"
        onChange={e => handleChangePicture(e.target.files[0])}
        id="choosePicture"
        data-test-id="choosePicture-testId"
        inputProps={{
          accept: 'image/*',
        }}
        sx={{ display: 'none' }}
      />
      {isEditProfile ? (
        <Badge
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          overlap="circular"
          badgeContent={
            <DeleteOutlineIcon
              data-test-id="deletePictureButton"
              onClick={deletePicture}
            />
          }
        >
          <Badge
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            overlap="circular"
            badgeContent={
              <ModeEditOutlineOutlinedIcon onClick={openChoosePicture} />
            }
          >
            <Avatar
              id="editProfilePicture"
              data-test-id="editProfilePicture-testId"
              className={css.avatarStyle}
              alt=""
              src={profilePicture}
            />
          </Badge>
        </Badge>
      ) : (
        <img
          src={profilePicture}
          alt=""
          id="profilePicture"
          data-test-id="profilePicture-testId"
        />
      )}
    </>
  );

  useEffect(() => {
    if (!loadingImage) {
      setField(document.getElementById('choosePicture'));
    }
  }, [loadingImage]);

  useEffect(() => {
    if (userId) {
      setLoadingImage(true);
      getStudentPicture(userId)
        .then(data => {
          if (data.size) {
            dispatch(setStudentProfilePicture(URL.createObjectURL(data)));
          }
        })
        .catch(() => {
          dispatch(displayError({ role: notificationRole.student }));
        })
        .finally(() => setLoadingImage(false));
    }
  }, [dispatch, userId]);

  return loadingImage ? (
    <Skeleton
      width="85px"
      height="85px"
      variant="circular"
      data-test-id="loadingPictureSkeleton"
      sx={{ borderRadius: '50% !important' }}
    />
  ) : (
    editPicture
  );
}

export default StudentProfilePicture;
