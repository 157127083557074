import ApiService from '../../shared/Api/apiService';
import urls from '../../../core/strings/urls';
import {
  displayError,
  displayInfo,
  displaySuccess,
} from '../../../core/redux/slices/notificationsSlice';
import appStrings from '../../../core/strings/appStrings';
import { openExportDocument } from '../../../core/services/utilsService';
import store from '../../../core/redux/store';

export const getHistoryDetailsByParams = params => {
  if (!params) {
    return Promise.reject();
  }

  params.startDate = new Date(params.startDate);
  params.endDate = new Date(params.endDate);

  return ApiService.post(urls.getHistoryDetails, params);
};

export const handleECAppPDFDownload = (studentId, transactionNumber) => {
  store.dispatch(displayInfo({ message: appStrings.export.exportInProgress }));
  ApiService.get(urls.exportEcApplicationPDF(studentId, transactionNumber))
    .then(res => openExportDocument(res))
    .catch(e => store.dispatch(displayError({ message: e })))
    .finally(() =>
      store.dispatch(
        displaySuccess({ message: appStrings.export.reportExported })
      )
    );
};
