import ApiService from '../../shared/Api/apiService';
import urls from '../../../core/strings/urls';

export const getListOfItemsAvailable = payload =>
  ApiService.post(urls.getRestoreItemsList, payload);

export const restorePurchasesSelectedItems = payload =>
  ApiService.post(urls.restorePurchasePower, payload);

export const restorePurchasesAllItems = payload =>
  ApiService.post(urls.restorePurchasePowerForAllItems, payload);
