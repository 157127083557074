import ProductListContextProvider from './context/ProductListContextProvider';
import ProductsListIndex from './components/ProductListIndex';
import { propTypes } from './productsListConstants';

function ProductsList({
  activePrompt,
  addingProduct,
  cartId,
  deletingItems,
  setSectionErrors,
  setRowsLoading,
}) {
  return (
    <ProductListContextProvider>
      <ProductsListIndex
        activePrompt={activePrompt}
        addingProduct={addingProduct}
        cartId={cartId}
        deletingItems={deletingItems}
        setSectionErrors={setSectionErrors}
        setRowsLoading={setRowsLoading}
      />
    </ProductListContextProvider>
  );
}

ProductsList.propTypes = propTypes;

export default ProductsList;
