import { Card, CardContent, Divider, Grid } from '@mui/material';

import { Skeleton } from '@mui/material';
import classNames from 'classnames';
import useItemAvailabilityStyles from './useItemAvailabilityStyles';

function ItemAvailabilitySkeleton() {
  const css = useItemAvailabilityStyles().classes;

  return (
    <>
      {Array.from(Array(3).keys()).map((key, index) => {
        return (
          <Card
            key={`warehouse${key}`}
            className={css.card}
            data-test-id={`itemAvailabilitySkeleton${index}`}
          >
            <CardContent className={classNames(css.cardContent, css.skeleton)}>
              <Grid container direction="column">
                <Skeleton width={'40%'} />
                <Divider />
                <Skeleton width={'70%'} />
                <Skeleton width={'70%'} />
              </Grid>
            </CardContent>
          </Card>
        );
      })}
    </>
  );
}

export default ItemAvailabilitySkeleton;
