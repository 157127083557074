import { makeStyles } from 'tss-react/mui';

const useCustomAlertStyles = makeStyles()(theme => ({
  alert: {
    width: '100%',
    padding: 0,
    background: 'transparent',
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.common.fadeGrey}`,
    color: theme.palette.text.primary,
    fontSize: theme.typography.subtitle2.fontSize,
    '& .MuiAlert-icon': {
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
      alignItems: 'center',
      fontSize: theme.typography.h1.fontSize,
      '& >svg': {
        padding: theme.spacing(1),
      },
    },
    '& .MuiAlert-message': {
      textAlign: 'justify',
      display: 'flex',
      flexDirection: 'column',
    },
    '& .MuiAlertTitle-root': {
      fontSize: theme.typography.subtitle2.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      margin: 0,
    },
    '& .MuiFormControlLabel-label': {
      fontSize: theme.typography.subtitle2.fontSize,
    },
    '& .MuiAlert-action': {
      marginRight: 0,
    },
    '&.MuiAlert-standardWarning': {
      '& svg': {
        color: theme.palette.common.yellow,
      },
      '& .MuiAlert-icon': {
        backgroundColor: theme.palette.common.fadeYellow,
      },
    },
    '&.MuiAlert-standardSuccess': {
      '& svg': {
        color: theme.palette.common.green,
      },
      '& .MuiAlert-icon': {
        backgroundColor: theme.palette.common.fadeGreen,
      },
    },
    '&.MuiAlert-standardError': {
      border: `1px solid ${theme.palette.common.red}`,
      '& .MuiAlert-icon': {
        backgroundColor: theme.palette.common.fadeRed,
        color: theme.palette.common.red,
        fontSize: theme.typography.h2.fontSize,
        padding: '10px',
      },
    },
    '&.MuiAlert-standardInfo': {
      '& svg': {
        color: theme.palette.common.blue,
      },
      '& .MuiAlert-icon': {
        backgroundColor: theme.palette.common.fadeBlue,
      },
    },
    '& .MuiRadio-root': {
      '& .MuiSvgIcon-root': {
        color: theme.palette.common.darkGrey,
        fontSize: theme.typography.h3.fontSize,
      },
    },
  },
  alertButton: {
    marginRight: theme.spacing(4.5),
  },
  audit: {
    '& .MuiAlert-icon': {
      backgroundColor: `${theme.palette.common.primary} !important`,
      color: theme.palette.common.neutral2,
      fontSize: '21px',
      padding: '10px',
    },
  },
}));

export default useCustomAlertStyles;
