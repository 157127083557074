import { Grid, Typography } from '@mui/material';
import {
  displayError,
  displaySuccess,
} from '../../../../core/redux/slices/notificationsSlice';
import {
  restorePurchasesAllItems,
  restorePurchasesSelectedItems,
} from '../restorePurchasesService';
import { useDispatch, useSelector } from 'react-redux';

import CustomBox from '../../../shared/CustomBox/CustomBox';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import appStrings from '../../../../core/strings/appStrings';
import { completeRestoreSelected } from '../../../../core/redux/slices/restorePurchasesSlice';
import useRestorePurchaseStyles from '../useRestorePurchaseStyles';

function CompleteRestoreSelectedItems({
  setTriggerReloadData,
  setCallInProgress,
  updatePurchasePower,
}) {
  const css = useRestorePurchaseStyles().classes;
  const dispatch = useDispatch();
  const {
    selectedItems,
    selectedAll,
    filters,
    selectedCount,
    hasSelectedAllItems,
    exceptionListSelectAll,
  } = useSelector(state => state.restorePurchases);
  const student = useSelector(
    state => state.schoolsWithStudents.selectedStudent.student
  );

  const selectedItemsLabel = `${selectedCount} ${appStrings.common.itemsSelected}`;

  const handleErrorOnCalls = e => {
    dispatch(displayError({ message: e }));
    setCallInProgress(false);
  };

  const handleSuccessOnCalls = resp => {
    dispatch(completeRestoreSelected(resp ? { restoredItems: resp } : {}));
    updatePurchasePower();
    setTriggerReloadData(true);
    dispatch(
      displaySuccess({
        message: appStrings.restorePurchase.restoreSuccessfulMsg,
      })
    );
  };

  const handleCompleteRestore = () => {
    setCallInProgress(true);

    const payload = {
      studentId: student.id,
      undoChanges: false,
      returnItems: selectedItems,
    };

    restorePurchasesSelectedItems(payload)
      .then(handleSuccessOnCalls)
      .catch(handleErrorOnCalls);
  };

  const handleCompleteRestoreSelectedAll = () => {
    setCallInProgress(true);

    const payload = {
      studentId: student.id,
      ...filters,
    };

    if (!selectedAll) {
      payload.unselectedItems = exceptionListSelectAll;
    }

    restorePurchasesAllItems(payload)
      .then(handleSuccessOnCalls)
      .catch(handleErrorOnCalls);
  };

  return (
    <Grid
      container
      className={!selectedCount ? css.hide : null}
      data-test-id="completeRestoreSelectedItemsContainer"
    >
      <Grid item xs={12}>
        <CustomBox customClass={css.greyContainer}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography className={css.bold}>
                    {selectedItemsLabel}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {appStrings.restorePurchase.completeRestoreDisclaimer}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <CustomButton
                id="restoreSelectedItems"
                data-test-id="restoreSelectedItems"
                label={appStrings.restorePurchase.completeRestore}
                onClick={() =>
                  hasSelectedAllItems
                    ? handleCompleteRestoreSelectedAll()
                    : handleCompleteRestore()
                }
              />
            </Grid>
          </Grid>
        </CustomBox>
      </Grid>
    </Grid>
  );
}

export default CompleteRestoreSelectedItems;
