import { makeStyles } from 'tss-react/mui';

const useConsignedFilterStyle = makeStyles()(theme => ({
  customBox: {
    background: theme.palette.common.neutral4,
    marginTop: theme.spacing(2),
  },
  label: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightBold,
  },
  container: {
    padding: theme.spacing(1.5),
  },
  selectInventory: {
    '& .MuiSelect-root': {
      background: theme.palette.common.neutral1,
    },
  },
}));

export default useConsignedFilterStyle;
