import { makeStyles } from 'tss-react/mui';

const useDateTimeStyles = makeStyles()(theme => ({
  root: {
    width: '100%',

    '& .MuiFormHelperText-contained': {
      margin: 0,
    },
  },
  container: {
    width: '100%',
  },
  error: {
    '& fieldset': {
      borderColor: theme.palette.common.red,
    },
    '& label': {
      color: `${theme.palette.common.red} !important`,
    },
  },
  smallIcon: {
    fontSize: '15px',
  },
  errorMessage: {
    fontSize: '0.8rem',
    color: theme.palette.common.red,
    marginTop: '6px',
  },
}));

export default useDateTimeStyles;
