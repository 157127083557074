import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import appStrings from '../../../../core/strings/appStrings';
import PropTypes from 'prop-types';
import { useOrderDetailsStyles } from '../orderDetailsStyles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TrackingNumbersSkeleton from '../Skeletons/TrackingNumbersSkeleton';
import { getTrackingNumbers } from '../orderDetailsServices';
import { useDispatch } from 'react-redux';
import { notificationRole } from '../../../../core/strings/appConstants';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';

function TrackingNumbers({ orderNo, lnOrderNumber }) {
  const css = useOrderDetailsStyles().classes;
  const dispatch = useDispatch();
  const [trackingNumbers, setTrackingNumbers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (lnOrderNumber) {
      setLoading(true);
      getTrackingNumbers(lnOrderNumber)
        .then(resp => {
          setTrackingNumbers(resp);
        })
        .catch(() => dispatch(displayError({ role: notificationRole.student })))
        .finally(() => setLoading(false));
    }
  }, [dispatch, lnOrderNumber]);

  return (
    <Grid
      container
      id="Orders-trackingNumbers"
      data-test-id="Orders-trackingNumbersTestId"
    >
      <Grid item xs={12}>
        <Typography>{orderNo}</Typography>
      </Grid>
      <Grid item xs>
        <CustomBox customClass={css.trackingNumbersStyle}>
          {loading ? (
            <TrackingNumbersSkeleton />
          ) : (
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography>{appStrings.order.trackingNumber}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{appStrings.order.carrier}</Typography>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={css.tableBody}>
                  {trackingNumbers?.map((elem, index) => (
                    <TableRow
                      key={index}
                      id={`trackingNumber-${index}`}
                      data-test-id="trackingNumber"
                    >
                      <TableCell>
                        <Typography id={`trackingNumber-${index}`}>
                          {elem.trackingNumber}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography id={`carrier-${index}`}>
                          {elem.carrier}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {elem.trackingUrl ? (
                          <a
                            href={elem.trackingUrl}
                            target="_blank"
                            rel="noreferrer"
                            id={`trackingUrl-${index}`}
                          >
                            <OpenInNewIcon />
                          </a>
                        ) : (
                          <OpenInNewIcon />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {trackingNumbers.length === 0 && (
                <Typography textAlign="center" paddingTop={2}>
                  {appStrings.order.noTrackingNumber}
                </Typography>
              )}
            </>
          )}
        </CustomBox>
      </Grid>
    </Grid>
  );
}

TrackingNumbers.prototypes = {
  orderNo: PropTypes.string,
};

export default TrackingNumbers;
