import CustomSkeleton from '../CustomSkeleton/CustomSkeleton';
import { Grid } from '@mui/material';
import { Skeleton } from '@mui/material';
import usePaymentDialogStyles from './usePaymentDialogStyles';

function PaymentDialogSkeleton() {
  const css = usePaymentDialogStyles().classes;
  const firstColumn = { xs: 4, width: '70%' };
  const secondColumn = { xs: 6, width: '85%' };

  let layout = [];
  for (let i = 0; i < 6; i++) {
    layout.push(firstColumn);
    layout.push(secondColumn);
  }

  return (
    <Grid className={css.paymentFrame}>
      <CustomSkeleton layout={layout} />
      <Skeleton
        className={css.captchaSkeleton}
        width={'80%'}
        height={'8rem'}
        variant="rect"
      />
    </Grid>
  );
}

export default PaymentDialogSkeleton;
