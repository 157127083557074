import { makeStyles } from 'tss-react/mui';

export const useAddressesStyles = makeStyles()(theme => ({
  addressContainer: {
    '&.MuiBox-root': {
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  titleInsideTheBox: {
    textAlign: 'start',
  },
  deleteContainer: {
    textAlign: 'right',
    marginTop: theme.spacing(-2),
  },
  studentDeleteContainer: {
    '& button': {
      border: '0px !important',
      padding: 0,
    },
  },
  deleteIcon: {
    color: theme.palette.common.red,
  },
  actionsContainer: {
    marginTop: theme.spacing(2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(3),
    },
  },
}));
