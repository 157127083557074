import { grey } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';

const addProductSummaryStyles = makeStyles()(theme => ({
  buttonContainer: {
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
  },
  searchIcon: {
    cursor: 'pointer',
    color: theme.palette.common.grey,
  },
  shortInput: {
    maxWidth: '150px',
  },
  productNumberField: {
    position: 'relative',
  },
  productsList: {
    position: 'absolute',
    zIndex: 9,
    backgroundColor: 'white',
    border: `${theme.spacing(0.1)} solid ${theme.palette.common.grey}`,
    borderTop: 0,
    borderRadius: `0 0 ${theme.spacing(0.75)} ${theme.spacing(0.75)}`,
    width: '95%',
    height: 'fit-content',
    maxHeight: '40vh',
    overflowY: 'auto',
    paddingTop: 0,
    '& p': {
      margin: 0,
      padding: 0,
      
    },
    '&::-webkit-scrollbar': {
      width: '12px'
    },

    '&:hover::-webkit-scrollbar': {
      width: '15px'
    },

    '&::-webkit-scrollbar-thumb': {
      border: '4px solid rgba(0, 0, 0, 0)',
      backgroundClip: 'padding-box',
      borderRadius: '9999px',
      backgroundColor: grey['400']
    }
  },
  numberSearchResults: {
    background: theme.palette.common.grey,
    width: 'calc(95% + 17px)',
    color: theme.palette.common.white,
    borderRadius: `${theme.spacing(0.35)} ${theme.spacing(0.35)} 0 0`,
    padding: '4px 16px 4px 16px',

    '& .MuiTypography-root': {
      fontSize: '12px',
    },
  }
}));

export default addProductSummaryStyles;
