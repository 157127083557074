import React, { useEffect, useState } from 'react';
import { notificationIcons, propTypes } from '../notificationConstants';
import { useStylesForAlert, useStylesForSnackbar } from '../notificationStyles';

import Alert from '@mui/material/Alert';
import { IconButton, Snackbar } from '@mui/material';
import appStrings from '../../../../core/strings/appStrings';
import { hideNotification } from '../../../../core/redux/slices/notificationsSlice';
import { useDispatch } from 'react-redux';
import { notificationRole } from '../../../../core/strings/appConstants';
import classNames from 'classnames';
import CloseIcon from '@mui/icons-material/Close';

function NotificationSnackbar({
  notice = appStrings.errors.somethingWentWrong,
  notificationType,
  permanent,
  action,
  onCloseCallback,
  id = 0,
  role,
  index,
}) {
  const isStudentNotification = role === notificationRole.student;
  const timeout = 6000;
  const snackBarClasses = useStylesForSnackbar().classes;
  const alertClasses = useStylesForAlert().classes;
  const dispatch = useDispatch();
  const anchorOrigin = {
    vertical: !isStudentNotification ? 'bottom' : 'top',
    horizontal: !isStudentNotification ? 'center' : 'right',
  };
  const { vertical, horizontal } = anchorOrigin;

  useEffect(() => {
    setOpen(true);
  }, []);

  if (!permanent) {
    setTimeout(() => {
      setOpen(false);
      dispatch(hideNotification({ id: id, role: role }));
    }, timeout);
  }

  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    if (onCloseCallback) {
      onCloseCallback();
    }
    setOpen(false);
    dispatch(hideNotification({ id: id, role: role }));
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical, horizontal }}
      onClick={action}
      onClose={handleClose}
      className={
        !isStudentNotification
          ? snackBarClasses.rootIndustrial
          : snackBarClasses.rootStudent
      }
      id={`notificationSnackbar-${role}-${id}`}
      data-test-id={`notification-displayed-snackbar-${role}`}
      key={notice}
      TransitionProps={{ onExited: handleClose }}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={notificationType}
        icon={notificationIcons[notificationType]}
        className={classNames(alertClasses.root, {
          [alertClasses.studentAlert]: isStudentNotification,
        })}
        action={
          <IconButton
            onClick={handleClose}
            id={`closeNotificationBtn${role}-${index}`}
          >
            <CloseIcon />
          </IconButton>
        }
        id={`notificationAlert-${role}`}
        data-test-id={`notification-displayed-alert-${role}`}
      >
        {notice}
      </Alert>
    </Snackbar>
  );
}

NotificationSnackbar.propTypes = propTypes;

export default NotificationSnackbar;
