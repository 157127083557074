import { makeStyles } from 'tss-react/mui';

const useStudentSearchStyles = makeStyles()(theme => ({
  popOverRoot: {
    padding: `${theme.spacing(5)} ${theme.spacing(3.5)}`,
    background: theme.palette.common.neutral2,
  },
  clearInputIcon: {
    fontSize: theme.typography.fontSize,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  invalidClearIcon: {
    color: theme.palette.common.red,
  },
  buttonIcon: {
    fontSize: `${theme.typography.h2.fontSize} !important`,
    marginBottom: '2px',
  },
  searchInput: {
    width: '100%',
    '& .MuiInputBase-root': {
      cursor: 'pointer',
    },
    '& input': {
      cursor: 'pointer',
    },
  },
  formContainer: {
    minWidth: '410px',
    maxWidth: '500px',
    '& >div': {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    },
    '& .MuiFormControlLabel-labelPlacementStart': {
      marginLeft: 0,
      marginRight: theme.spacing(5),
    },
    '& .MuiAutocomplete-root': {
      paddingBottom: theme.spacing(2.5),
    },
    '& fieldset': {
      paddingTop: theme.spacing(2),
    },
  },
  studentSearchFormContainer: {
    minWidth: '450px',
    maxWidth: '450px',
    '& .MuiFormControlLabel-labelPlacementStart': {
      marginLeft: 0,
      marginRight: theme.spacing(4),
    },
  },
  filterIcon: {
    color: theme.palette.common.darkGrey,
  },
  clearIcon: {
    color: theme.palette.common.neutral2,
    background: theme.palette.common.lightGrey,
    borderRadius: '10px',
    fontSize: '20px',
  },
  searchIcon: {
    color: theme.palette.common.grey,
  },
  buttonsContainer: {
    marginTop: theme.spacing(2),
  },
}));

export default useStudentSearchStyles;
