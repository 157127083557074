import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, useMediaQuery } from '@mui/material';
import Slider from 'react-slick';
import { ChevronLeftOutlined, ChevronRightOutlined } from '@mui/icons-material';
import useImageCarouselStyles from './useImageCarouselStyles';
import classNames from 'classnames';
import noProductImage from '../../../core/assets/img/noImageAvailable.jpg';

function ImageCarousel({
  images,
  initialSlide,
  zoomed,
  setCurrentCarouselImageIndex
}) {
  const isSmallDevice = useMediaQuery('(max-width:768px)');
  const css = useImageCarouselStyles().classes;
  

  if (!images || !images.length) {
    return null;
  }

  const LeftArrow = ({currentSlide, slideCount, children, ...props}) => {
    return (
      <Grid {...props} container item>
        <IconButton className={css.arrowButton}>
          <ChevronLeftOutlined />
        </IconButton>
      </Grid>
    );
  }

  const RightArrow = ({currentSlide, slideCount, children, ...props}) => {
    return (
      <Grid {...props} container item>
        <IconButton className={css.arrowButton}>
          <ChevronRightOutlined />
        </IconButton>
      </Grid>
    );
  }

  const settings = {
    adaptiveHeight: true,
    afterChange: index => setCurrentCarouselImageIndex && setCurrentCarouselImageIndex(index),
    arrows: !isSmallDevice,
    dots: true,
    initialSlide,
    lazyLoad: true,
    mobileFirst: isSmallDevice,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
  };

  return images.length > 1 ?
    (
      <Grid data-test-id="renderedSlider" container id="imageCarouselContainer" className={classNames(css.imageCarouselContainer, zoomed ? css.carouselZoomedContainer : '')}>
        <Slider {...settings} id="imageCarousel">
          {images.map((image, index) => (
            <div key={index}>
              <img
                key={index}
                src={`${image}?auto=format`}
                alt="productImage"
                className="imagesCarousel-image"
                id={`imagesCarouselImage-${index}`}
                loading="lazy"
              />
            </div>
          ))}
        </Slider>
      </Grid>
    ) : (
      <Grid
        data-test-id="renderedProductImage"
        container
        id="imageCarouselContainer"
        justifyContent="center"
        className={classNames(css.productImage, 'product-image-container')}>
        <img
            className="product-image"
            id="productImage"
            loading="lazy"
            src={images[0] || noProductImage}
            alt=""
          />
      </Grid>
    ) 
}

ImageCarousel.propTypes = {
  images: PropTypes.array,
  initialSlide: PropTypes.number,
  zoomed: PropTypes.bool,
}

export default ImageCarousel;