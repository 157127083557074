import { makeStyles } from 'tss-react/mui';

export const useReferenceStyles = makeStyles()(theme => ({
  referenceContainer: {
    '&.MuiBox-root': {
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
  },
  refsTitle: {
    '&::after': {
      counterIncrement: 'line-number',
      content: 'counter(line-number)',
    },
    marginBottom: theme.spacing(1),
  },
  refsCounterReset: { counterReset: 'line-number' },
  deleteContainer: {
    textAlign: 'right',
    marginTop: theme.spacing(-2),
  },
  hideInput: {
    display: 'none',
  },
  actionsContainer: {
    marginTop: theme.spacing(2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(3),
    },
  },
  refCounter: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.common.green,
    textAlign: 'right',
    fontWeight: theme.typography.fontWeightBold,
  },
  deleteIcon: {
    color: theme.palette.common.red,
  },
}));
