import React, { useEffect, useState } from 'react';
import {
  buttonColors,
  buttonTypes,
} from '../../../../core/strings/appConstants';
import {
  addNewReference,
  deleteReference,
  getSpeedDialOptions,
  getStudentReferences,
  handleReferencesInfo,
} from './referencesService';

import CustomAlert from '../../../shared/CustomAlert/CustomAlert';
import CustomBox from '../../CustomBox/CustomBox';
import CustomButton from '../../CustomButton/CustomButton';
import CustomDialog from '../../CustomDialog/CustomDialog';
import CustomIcon from '../../CustomIcon/CustomIcon';
import CustomSpeedDial from '../../CustomSpeedDial/CustomSpeedDial';
import FormInput from '../../FormInput/FormInput';
import { Grid } from '@mui/material';
import NoDataAvailable from '../../NoDataAvailable/NoDataAvailable';
import PropTypes from 'prop-types';
import StandardFormSkeleton from '../../CustomSkeleton/StandardFormSkeleton';
import { alertTypes } from '../../CustomAlert/customAlertConstants';
import appStrings from '../../../../core/strings/appStrings';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import { generateReference } from './referencesInputs';
import { icons } from '../../../../core/strings/icons';
import setMultipleValues from '../../../../core/services/formService';
import { useDispatch, useSelector } from 'react-redux';
import { useReferenceStyles } from './useReferencesStyles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import classNames from 'classnames';
import useStudentProfileFormStyles from '../useStudentProfileFormStyles';
import { setRefreshReferences } from '../../../../core/redux/slices/commonSlice';
const propTypes = {
  control: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

function References({
  control,
  setValue,
  errors,
  getValues,
  id,
  reset,
  isDirty,
  emptySections,
  isStudent,
  contractFields,
  watch,
}) {
  const [refs, setRefs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [notFormattedRefs, setNotFormattedRefs] = useState([]);
  const [deletedRefs, setDeletedRefs] = useState([]);
  const [disabledStates, setDisabledStates] = useState({});
  const [refsActualLength, setRefsActualLength] = useState(0);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const dispatch = useDispatch();

  const refCss = useReferenceStyles().classes;
  const formStyle = useStudentProfileFormStyles().classes;
  const getStyle = () => (isLoading ? { display: 'none' } : { width: '100%' });
  const refsContainerClasses = classNames(refCss.refsCounterReset);
  const refreshReferences = useSelector(
    state => state.common.refreshReferences
  );

  const hasNoRefs =
    !refs.some(ref => ref !== undefined) && refsActualLength === 0;

  useEffect(() => {
    if (id && isLoading && !notFormattedRefs.length) {
      getStudentReferences(id)
        .then(res => {
          if (res && res.references?.length) {
            setValue('references', []);
            reset(getValues());
            setNotFormattedRefs(res.references);
          } else {
            setValue('references', []);
            setIsLoading(false);
          }
        })
        .catch(() => {
          dispatch(displayError());
          setIsLoading(false);
        });
    }
  }, [id, dispatch, isLoading, notFormattedRefs, setValue, reset, getValues]);

  useEffect(() => {
    if (notFormattedRefs.length && isLoading) {
      handleReferencesInfo({
        res: { references: notFormattedRefs, studentId: id },
        setRefs,
        setIsLoading,
        setRefsActualLength,
      });
    }
  }, [id, isLoading, notFormattedRefs]);

  useEffect(() => {
    if (refs !== null && Boolean(refs?.length) && isLoading) {
      setMultipleValues({ setValue, values: { references: refs } });
      if (!isDirty) {
        reset(getValues());
      }
      // setTimeout is necessary to allow setMultipleValues to set values
      setTimeout(() => {
        setIsLoading(false);
      });
    }
  }, [isLoading, refs, setValue, isDirty, reset, getValues]);

  useEffect(() => {
    if (deletedRefs.length) {
      setValue('deletedRefs', JSON.stringify(deletedRefs), {
        shouldDirty: true,
      });
    }
  }, [deletedRefs, setValue]);

  useEffect(() => {
    if (emptySections?.emptyRefs?.length) {
      // sort indexes in descending order to not mess up refs indexes
      emptySections.emptyRefs
        ?.sort((a, b) => b - a)
        .forEach(index =>
          deleteReference({
            index,
            addressId: null,
            id: null,
            setDeletedRefs,
            setRefs,
            setRefsActualLength,
            getValues,
          })
        );
    }
  }, [emptySections, getValues, refs, refsActualLength]);

  useEffect(() => {
    if (contractFields && !refsActualLength && !isLoading) {
      reset(getValues());
      addNewReference({ refs, setRefs, refsActualLength, setRefsActualLength });
    }
  }, [contractFields, getValues, isLoading, refs, refsActualLength, reset]);

  useEffect(
    () => () => {
      if (contractFields && isStudent) {
        dispatch(setRefreshReferences(true));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (isStudent && refreshReferences && !contractFields) {
      setIsLoading(true);
      setRefs([]);
      setNotFormattedRefs([]);
      setDeletedRefs([]);
      setRefsActualLength(0);
      dispatch(setRefreshReferences(false));
    }
  }, [contractFields, dispatch, isStudent, refreshReferences]);

  const formProps = {
    control: control,
    setValue: setValue,
    errors: errors,
    getValues: getValues,
  };

  const handleDialogOpen = index => {
    setShowDeleteDialog(index);
  };

  const handleDialogClose = () => {
    setShowDeleteDialog(false);
  };

  const deleteButtons = index => [
    {
      btnType: buttonTypes.secondary,
      label: appStrings.common.cancel,
      onClick: handleDialogClose,
    },
    {
      btnType: buttonTypes.primary,
      color: buttonColors.red,
      label: appStrings.common.remove,
      onClick: () => {
        deleteReference({
          index,
          addressId: getValues(`references.${index}.addressId`),
          id: getValues(`references.${index}.id`),
          refsActualLength,
          setRefs,
          setDeletedRefs,
          setRefsActualLength,
          getValues,
          setValue,
          studentId: id,
          reset,
        });
        handleDialogClose();
      },
    },
  ];

  const speedDialOptions = getSpeedDialOptions({
    refsActualLength,
    refs,
    setRefs,
    setRefsActualLength,
  });

  const getDeleteBtn = index => (
    <div className={!isStudent ? refCss.deleteContainer : ''}>
      <CustomButton
        sx={{
          display: contractFields && index === 0 ? 'none' : '',
          border: isStudent ? '0px !important' : '',
          padding: isStudent ? '0px !important' : '',
        }}
        color={!isStudent ? buttonColors.red : buttonColors.black}
        btnType={buttonTypes.secondary}
        label={
          !isStudent
            ? appStrings.studentProfile.deleteReference
            : appStrings.common.delete
        }
        onClick={() => handleDialogOpen(index)}
        id={`deleteReference-${index}`}
        data-test-id={`deleteReference-${index}`}
        startIcon={isStudent && <DeleteOutlineIcon />}
        size={isStudent ? 'small' : ''}
      />
      <CustomDialog
        // false positive sonar issue here
        open={showDeleteDialog === index}
        handleClose={handleDialogClose}
        icon={
          <CustomIcon icon={icons.trash} customClasses={refCss.deleteIcon} />
        }
        title={appStrings.studentProfile.deleteReferenceConfirmation}
        buttons={deleteButtons(index)}
      />
    </div>
  );

  return (
    <>
      {isLoading && <StandardFormSkeleton />}
      <div style={getStyle()} className={refsContainerClasses}>
        {hasNoRefs && (
          <NoDataAvailable
            message={appStrings.studentProfile.noReferences}
            icon={icons.noReferences}
          />
        )}
        {refs?.length > 0 && (
          <>
            {!isLoading && !hasNoRefs && (
              <CustomAlert
                messageType={alertTypes.info}
                messages={[{ text: appStrings.studentProfile.referenceNote }]}
              />
            )}

            {refs.map((r, index) => (
              <Grid
                item
                id={`reference-${index}`}
                key={`${id}-reference-${index + 1}`}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <Grid item xs>
                    <h3
                      className={refCss.refsTitle}
                      data-test-id={`referenceCounter-${index + 1}`}
                    >
                      {appStrings.studentProfile.referenceNr}
                    </h3>
                  </Grid>
                  <Grid item marginBottom={1}>
                    {isStudent && getDeleteBtn(index)}
                  </Grid>
                </Grid>

                <CustomBox customClass={refCss.referenceContainer} fadeBorder>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    data-test-id={`reference-${index + 1}`}
                  >
                    {generateReference({
                      index,
                      getValues,
                      setValue,
                      disabledStates,
                      setDisabledStates,
                      isStudent,
                    }).map(input => (
                      <Grid
                        item
                        {...input.gridLayout}
                        key={`references-${input.name}-container`}
                        className={
                          input.type === 'hidden' ? refCss.hideInput : ''
                        }
                      >
                        <FormInput
                          field={input}
                          {...formProps}
                          size={isStudent && 'medium'}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </CustomBox>

                {!isStudent && getDeleteBtn(index)}
              </Grid>
            ))}
          </>
        )}
        <FormInput
          {...formProps}
          field={{ name: 'deletedRefs', type: 'hidden' }}
          data-test-id="deletedRefs"
          size={isStudent && 'medium'}
        />
      </div>
      {!isLoading && (
        <Grid
          container
          justifyContent={isStudent ? 'flex-start' : 'flex-end'}
          className={formStyle.hide}
        >
          <CustomSpeedDial
            options={speedDialOptions}
            show
            id="references"
            isStudent={isStudent}
            customClass={formStyle.speedDialReferences}
          />
        </Grid>
      )}
    </>
  );
}

References.propTypes = propTypes;

export default References;
