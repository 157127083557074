import {
  MYORDERS,
  MYSAVEDITEMS,
  PROFILE,
} from '../../../core/navigation/paths';
import appStrings from '../../../core/strings/appStrings';

export const accountProfileSections = [
  {
    name: appStrings.common.myProfile,
    to: PROFILE,
    id: 'account-studentProfileMyProfileLink',
    dataTestId: 'account-studentProfileMyProfileLinkTestId',
  },
  {
    name: appStrings.common.mySavedItems,
    to: MYSAVEDITEMS,
    id: 'account-studentProfileMySavedItmLink',
    dataTestId: 'account-studentProfileMySavedItmLinkTestId',
  },
  {
    name: appStrings.menu.orders,
    to: MYORDERS,
    id: 'account-studentProfileOrdersLink',
    dataTestId: 'account-studentProfileOrdersLinkTestId',
  },
];

export const mySectionsPaths = ['account', 'profile', 'savedItems', 'orders'];
