/* eslint-disable sonarjs/no-duplicate-string */
import { LOGIN, REGISTER } from '../navigation/paths';

const appStrings = {
  common: {
    amount: 'Amount',
    apply: 'Apply',
    applyFilters: 'Apply Filters',
    applyForCredit: 'Apply for credit',
    approved: 'Approved',
    bpNumber: 'BP Number',
    cancel: 'Cancel',
    checkout: 'Checkout',
    city: 'City',
    clear: 'Clear',
    close: 'Close',
    ignore: 'Ignore',
    confirm: 'Confirm',
    termsAndConditions: 'Terms & Conditions',
    creditCard: 'Credit Card',
    currencySign: '$',
    delete: 'Delete',
    edit: 'Edit',
    export: 'Export',
    editProfile: 'Edit Profile',
    emailTo: 'Email to',
    featuredProducts: 'Featured products',
    filters: 'Filters',
    firstName: 'First Name',
    forgotPassword: 'Forgot Password',
    itemsSelected: 'item(s) selected',
    keep: 'Keep',
    lastName: 'Last Name',
    mandatoryFields: ' mandatory fields',
    more: 'More ...',
    myAccount: 'My Account',
    myProfile: 'My Profile',
    mySavedItems: 'My Saved Items',
    newAddress: 'New Address',
    na: 'n/A',
    no: 'No',
    ok: 'Ok',
    order: 'Order',
    or: 'or',
    pending: 'Pending',
    percentageSign: '%',
    pleaseWait: 'Please wait...',
    privacyPolicy: 'Privacy Policy',
    refresh: 'Refresh',
    declined: 'Declined',
    remove: 'Remove',
    replace: 'Replace',
    restorePrice: 'Restore Price',
    save: 'Save',
    signIn: 'Sign In',
    signOut: 'Sign Out',
    state: 'State',
    student: 'Student',
    studentCart: 'Student Cart',
    students: 'Students',
    to: 'to',
    weAlsoRecommend: 'We also recommend you',
    weightUnit: 'lb',
    yes: 'Yes',
    zeroItems: '0 items',
    zipCode: 'Zip Code',
    sku: 'SKU: ',
    goBack: 'Go Back',
    agree: 'I agree',
    sepDiscountPrice: value => (
      <>
        SEP Discount Price:{' '}
        <span style={{ color: '#483D8B' }}>{value ? `$${value}` : 'N/A'}</span>
      </>
    ),
    signInOrRegister: (
      <>
        <a href={LOGIN}>Sign In</a> or
        <a href={REGISTER}> Register</a> to see student price.
      </>
    ),
    overview: 'Overview',
    setIncludes: 'Set Includes',
    itemsNumber: length => `(${length} items)`,
    id: 'ID: ',
    welcome: 'Welcome',
    diagnosticTools: 'Diagnostic Tools',
    toolStorage: 'Tool Storage',
    purchase: 'Purchase',
    date: 'Date',
    free: 'FREE',
  },
  errors: {
    somethingWentWrong: 'Something went wrong.',
    amountExceed: 'Allocated amount exceeded',
  },
  footer: {
    snaponCorporate: 'Snapon Corporate',
    customerService: 'Customer Service',
    privacyPolicy: 'Privacy Policy',
    firstParagraph:
      'By accessing and using this web site you agree that you have read and are bound by the terms and conditions set forth in the Terms and Conditions of Use, including the arbitration provisions.',
    secondParagraph:
      'Snap-on is a trademark, registered in the United States and other countries, of Snap-on Incorporated. Other marks are marks of their respective holders.',
    thirdParagraph: currentYear =>
      `©${currentYear} Snap-on Incorporated. All rights reserved.`,
  },
  menu: {
    account: 'Account',
    administration: 'Administration',
    approveRejectStudent: 'Approve/Reject Student',
    cart: 'Shopping Cart',
    consignedInventory: 'Consigned Inventory',
    conversationNotes: 'Conversation Notes',
    editStudentProfile: 'Edit Student Profile',
    history: 'History',
    logout: 'Logout',
    orders: 'My Orders',
    registerStudent: 'Register a Student',
    savedItems: 'Saved Items',
    search: 'Search',
    shop: 'Shop',
    sepCatalog: 'SEP CATALOG',
  },
  register: {
    acceptTerms: 'I accept the Terms and Agreement to register in the program.',
    address: 'Address',
    approveStudent: 'Approve Student?',
    cellPhone: 'Cell Phone #',
    confirmPassword: 'Confirm Password',
    contactInformation: 'Contact Information',
    currentInstructorName: 'Current Instructor Name',
    educationAccManagerName: 'Education Account Manager Name',
    email: 'Email',
    graduationDate: 'Graduation Date',
    graduationDay: 'Graduation Day',
    graduationDayDefaultInfo:
      'If exact date is not known, please select the last day of the month',
    industrialTitle: 'Create new Account',
    instructorName: 'Instructor Name',
    isEmployed: 'Are you currently employed?',
    password: 'Password',
    permanentHome: 'Permanent Home',
    promotionalEmails: 'Would you like to receive promotional e-mails?',
    promotionalMessages: 'Would you like to receive promotional text messages?',
    registerNewStudent: 'Register new Student',
    registerStudent: 'Register Student',
    continue: 'Continue',
    createAnAccount: 'Create an Account',
    registerSuccessful: 'Student registered successfully',
    registerSuccessfulGuest:
      'Your registration is awaiting approval by your Industrial Representative. You will receive an email with more information shortly.',
    registrationConfirmationPage: 'Registration Confirmation Page',
    schoolInformation: 'School Information',
    schoolName: 'Technical School Campus Name',
    schoolState: 'State of Technical School Campus',
    studentId: 'Student ID',
    studentWillReceiveEmail:
      'The student will receive soon an email notification about the next steps that need to be done.',
    successfullyRegistered: name =>
      `You Successfully registered a new student: ${name}`,
    switchInputNo: 'No',
    switchInputYes: 'Yes',
    usesTools: 'Do You Use Hand Tools or Power Tools at Work?',
  },
  searchInputs: {
    approvalStatus: 'Approval Status',
    activeStudents: 'Active',
    allStudents: 'All Students',
    approved: 'Approved',
    bpNumber: 'BP Number',
    clearAll: 'Clear All',
    deleteAllFilters: 'Delete all Filters',
    email: 'Email Address',
    endDate: 'End Date',
    getNoSearchResultMessage: searchInput => (
      <>
        {'Your search for '}
        <span
          style={{ color: 'black', fontSize: '20px' }}
        >{`“${searchInput}“`}</span>
        {' didn’t return any results.'}
      </>
    ),
    getNoSearchProductsResultMessage: searchInput => (
      <>
        {'Nothing matches your search '}
        <span style={{ color: 'red' }}>{`“${searchInput}“`}</span>
      </>
    ),
    goBack: 'Go Back',
    graduationDateRange: 'Graduation Date Range',
    graduationStartDate: 'Graduation Start Date',
    graduationEndDate: 'Graduation End Date',
    inactiveStudents: 'Inactive',
    or: 'Or',
    pending: 'Pending',
    phoneNumber: 'Phone Number',
    registerDate: 'Register Date',
    graduationDate: 'Graduation Date',
    registrationDateRange: 'Registration Date Range',
    rejected: 'Rejected',
    schoolName: 'School Name',
    search: 'Search',
    searchResult: 'Search Result',
    yourProductSearchResults: (name, resultsLength) => (
      <>
        {'Your search results for '}
        <span style={{ color: 'red' }}>{`“${name}“ `}</span>
        <span
          style={{ fontSize: 'small', fontWeight: 'normal' }}
        >{`(${resultsLength} items)`}</span>
      </>
    ),
    searchStudent: 'Search Student',
    searchTips: 'Search tips',
    trySearchTips: 'Try these search tips:',
    searchTipsList: [
      'Check your spelling.',
      'Verify that you entered the search criteria in the correct field.',
      'Broaden your search by using fewer or more general words.',
      'Entering a single character in the fields will show you the results of all students starting with the entered character.',
    ],
    searchTipsProducts: [
      'Check your spelling. Are the words in your query spelled correctly?',
      "Try using synonyms. Maybe what you're looking for uses slightly different words.",
      'Make your search more general. Try more general terms in place of specific ones.',
      'If you are searching for repair parts, search for the item that needs repair and then select Repair Parts button to see list of repair parts available.',
    ],
    selectStudentsApprovalStatus: 'Select students approval status:',
    startDate: 'Start Date',
    studentName: 'Student Name',
    studentStatus: 'Student Status',
    successMessageUpdateStatus: status =>
      `The selected students were successfully ${status}`,
    searchReps: 'Search industrial reps',
    allReps: 'All Industrial Rep.',
    selectRep: 'Select an industrial rep.',
    products: 'PRODUCTS',
    suggestions: 'SUGGESTIONS',
    goToStudent: 'Go to student',
  },
  modal: {
    btnStay: 'Stay on page',
    btnLeave: 'Leave page',
    btnLeaveNoSave: "Leave and don't save",
    btnLeaveAndSave: 'Leave and save',
    btnDiscard: 'Discard Changes',
    register: {
      title: 'Do you want to leave this page?',
      description: 'Changes you made will not be saved',
    },
    editProfile: {
      title: 'You have unsaved profile changes.',
      description: 'Would you like to save the changes before leaving?',
    },
    editCart: {
      title: 'What do you want to do with your changes?',
      description:
        'Do you want to leave this page? Changes you made will not be saved.',
    },
    moveSavedToCart: {
      title:
        'Do you wish to perform item validation before moving the Saved Items to Student Cart?',
    },
    moveCartToSaved: {
      title:
        'Do you wish to perform item validation before moving the selected Student Cart items to Saved Items?',
    },
    moveOrderToSaved: {
      title:
        'Do you wish to perform item validation before moving the selected Order items to Saved Items?',
    },
    moveOrderToCart: {
      title:
        'Do you wish to perform item validation before moving the selected Order items to Student Cart?',
    },
    moveCartToOrder: {
      title:
        'Do you wish to perform item validation before moving the selected Student Cart items to Order?',
    },
    moveSavedToOrder: {
      title:
        'Do you wish to perform item validation before moving the selected Saved items to Order?',
    },
    moveDescription:
      'Please note that price and item validation will also be performed at order completion.',
    order: {
      title: 'You have unsaved changes.',
      description: 'Are you sure you want to discard the changes?',
    },
    ecApp: {
      title: 'You have unsaved changes.',
      description:
        'Closing this will result in the loss of all unsaved data in the form.\n' +
        'Are you sure you want to proceed',
    },
  },
  studentsPage: {
    noStudentsMsg: 'There are no students available for this school',
    showInactive: 'Show Inactive Students',
    goToCart: 'Go to Cart',
    goToOrder: 'Go to Order',
  },
  validationMessages: {
    nameString: 'Only letters, space, hyphens and apostrophes are accepted',
    emailFormat: 'Email needs to be in the following format: local-part@domain',
    emailBadDomain: 'Please enter a non Snap-on email address',
    address:
      "Only alphanumeric and the following: ., :, #, !, /, _, @, -, ', [, ] and , characters are accepted",
    xDigitsOnly: nr => `Only ${nr} digits are accepted`,
    digitsOnly: 'Only digits are accepted',
    passwordFormat:
      'Password must have at least 8 characters, 1 letter, 1 number, 1 special character.',
    passwordCapsLock: 'Caps Lock is on',
    passwordLength: 'Must contain 8 to 16 characters',
    passwordLetter: 'Must contain 1 letter',
    passwordNumber: 'Must contain 1 number',
    passwordSpecialCharacter: 'Must contain 1 special character',
    passwordMatch: 'The passwords do not match. Please try again',
    noSpaces: 'Space is not allowed',
    emptyField: 'Field cannot be empty',
    saveChangesToEnable: 'Please save changes to enable this functionality',
    ecAppBtnDisabled:
      'To be eligible, the student must use tools at work or be within 90 days of graduation.',
    ecAppAlreadySubmitted:
      'An application has already been submitted. Please contact your Snap-on Sales representative',
    requiredFields: 'Please complete all required fields',
    correctErrors: 'Please correct the errors before continuing',
    existingEmail: 'The email is already in use. Please choose another one',
    existingEmailHelperText: 'Email is already in use',
    graduationDateFuture: 'Graduation date should be in the future',
    graduationDate: 'Graduation date should be in the next 10 years',
    graduationDateMax: 'Date should not be after maximal date',
    mandatoryFields: 'Please fill all the mandatory fields',
    invalidDateFormat: 'Invalid Date Format',
    maxDate: 'Date should not be after maximal date',
    minDate: 'Date should not be before minimal date',
    maxBirthdate: 'The date should be in the past',
    minBirthdate: 'The date should be in the last 100 years',
    presentEmployerMinDate: 'The start date should be in the last 100 years',
    presentEmployerMaxDate: 'The start date should be in the past',
    futureEmployerMinDate: 'The start date should be in the future',
    futureEmployerMaxDate: 'The start date should be in the next 10 years',
    noStateZipCode: 'This zip code does not have a valid state',
    invalidZipCode: 'Invalid zip code',
    minMoveInDate: 'The move-in date should be in the last 100 years',
    maxMoveInDate: 'The move-in date should be in the past',
    acceptedProductNumber: `Only alphanumeric and - * ' " . / are allowed`,
    minAmount: 'Please enter an amount greater than 0',
    minAmountDefined: amount =>
      `Please enter an amount that is at least $${amount}`,
    amountFormat: 'Please enter an amount with max 8 digits and two decimals',
    minQuantity: 'Please enter a quantity greater than 0',
    minQuantityInput: 'Quantity must be greater than 0',
    paymentAmountIncorrect: isECOrder =>
      `Payment Amount should be equal to ${
        isECOrder ? 'Down Payment' : 'Grand Total'
      }.`,
    lineItemsErrors: 'Please check the errors from Line Items',
    ecContractErrors: 'Please check the errors from EC Contract',
    orderTypeErrors: 'Please select an Account Number and a Promotion',
    lineItemsPricingUpdates:
      'Please check for the Line Items pricing updates and be aware that the taxes might have changed.',
    ecAppIneligible:
      'You are not qualified to apply for a credit. Please contact your Snap-on Sales representative.',
    inactiveStudentMessage:
      'Your account is inactive. Please contact your Account Manager for any questions.',
    paymentNumberRequired: 'Payment Number is required',
    paymentTypeRequired: 'Payment Type is required',
    voucherNumberRequired: 'Voucher Number is required',
  },
  infoMessages: {
    noOptions: 'No options',
    minCharAutocomplete: minLength =>
      `Please enter at least ${minLength} characters in the search field.`,
  },
  studentProfile: {
    activeStudent: 'Active Student?',
    addresses: 'Addresses',
    addReference: 'Add a Reference',
    allReferencesAdded: 'All references added',
    birthDate: 'Birthdate',
    cellPhoneCompany: 'Cell Phone Company',
    customerInformation: 'Customer Information',
    deleteReference: 'Delete Reference',
    deleteReferenceConfirmation:
      'Are you sure you want to delete this reference?',
    driverLicenseNumber: 'Driver License Number',
    driverLicenseState: 'Driver License State',
    emailAddress: 'Email Address',
    employerInformation: 'Employer Information',
    maritalStatus: 'Marital Status',
    middleName: 'Middle Name',
    noReferences: 'There are no references to show',
    nrMoreToAdd: nr => `${nr} more to add`,
    phoneNumber: 'Phone Number',
    phoneType: nr => `Phone ${nr} Type`,
    references: 'References',
    referenceAddress1: 'Reference Address #1',
    referenceAddress2: 'Reference Address #2',
    referenceCity: 'Reference City',
    referenceNr: 'Reference #',
    referencePhoneNumber: 'Reference Phone Number',
    referenceRelationship: 'Reference Relationship',
    referenceState: 'Reference State',
    referenceNote:
      'For EC contract, 3 References are required: 2 Relatives (not living together or with you) and 1 Friend (not living with you)',
    referenceZipCode: 'Reference ZIP Code',
    saveProfile: 'Save Profile',
    saveChanges: 'Save Changes',
    schoolInformation: 'School Information',
    sendResetPass: 'Send email to reset password',
    ECApp: 'EC Application',
    socAccountInfo: 'SOC Account Info',
    creditAccountInfo: 'Credit Account Info',
    socialSecurityNumber: 'Social Security Number',
    studentSuccessfulSave: 'The student details have been saved',
    suffix: 'Suffix',
    studentApproval: 'Student Approval',
    noEmployers: 'There are no employers to show',
    addPresentEmployer: 'Add Present Employer',
    addFutureEmployer: 'Add Future Employer',
    addPreviousEmployer: 'Add Previous Employer',
    presentEmployerAdded: 'Present Employer added',
    futureEmployerAdded: 'Future Employer added',
    previousEmployerAdded: 'Previous Employer added',
    presentEmployer: 'Present Employer',
    presentEmployerAddress1: 'Present Employer Address #1',
    presentEmployerAddress2: 'Present Employer Address #2',
    presentEmployerZip: 'Present Employer ZIP Code',
    presentEmployerCity: 'Present Employer City',
    presentEmployerState: 'Present Employer State',
    presentEmployerPhone: 'Present Employer Phone Number',
    presentEmployerPosition: 'Position at Present Employer',
    presentEmployerStartDate: 'Employment Start Date',
    presentEmployerLengthOfEmployment: 'Length of Employment',
    futureEmployer: 'Future Employer',
    futureEmployerAddress1: 'Future Employer Address #1',
    futureEmployerAddress2: 'Future Employer Address #2',
    futureEmployerZip: 'Future Employer ZIP Code',
    futureEmployerCity: 'Future Employer City',
    futureEmployerState: 'Future Employer State',
    futureEmployerPhone: 'Future Employer Phone Number',
    futureEmployerPosition: 'Position at Future Employer',
    futureEmployerStartDate: 'Future Employer Start Date',
    previousEmployerNumber: 'Previous Employer #',
    previousEmployer: 'Previous Employer',
    previousEmployerAddress1: 'Previous Employer Address #1',
    previousEmployerAddress2: 'Previous Employer Address #2',
    previousEmployerZip: 'Previous Employer ZIP Code',
    previousEmployerCity: 'Previous Employer City',
    previousEmployerState: 'Previous Employer State',
    previousEmployerPhone: 'Previous Employer Phone Number',
    previousEmployerPosition: 'Position at Previous Employer',
    previousEmployerLengthOfEmployment:
      'Previous Employer Length of Employment',
    years: 'Years',
    months: 'Months',
    yearsAndMonths: (years, months) => `${years} years and ${months} months`,
    removeProfileInfo: section =>
      `Do you want to remove the ${section} information?`,
    myPurchaseLimit: 'My Purchase Limit',
    myAchievements: 'My Achievements',
    applicationFields: 'Application Fields',
    contractFields: 'Contract Fields',
    requiredForContract: 'Required for Contract',
    noInfo: 'No information available',
    debtorNumber: 'Debtor Number',
    productType: 'Product Type:',
    availableCredit: 'Available Credit:',
    snapOnCredit: 'Snap-On Credit',
    myInformation: 'My Information',
  },
  phoneTypes: {
    business: 'Business',
    cellPhone: 'Cell Phone',
    home: 'Home',
    other: 'Other',
    none: 'None',
  },
  maritalStatus: {
    married: 'Married',
    single: 'Single',
    none: 'None',
  },
  positions: {
    aftermarketAccessoryInstaller: 'Aftermarket / Accessory Installer',
    apprentice: 'Apprentice',
    autoTechRoutineMaintenance: 'Auto Tech / Routine Maintenance',
    aviationTechnician: 'Aviation Technician',
    bodyRepairTechnician: 'Body Repair Technician',
    diagnosticDrivabilityTechnician: 'Diagnostic / Drivability Technician',
    heavyDutyEquipmentTechnician: 'Heavy Duty Equipment Technician',
    heavyDutyTruckDieselTechnician: 'Heavy Duty Truck / Diesel Technician',
    machinist: 'Machinist',
    mobileRepairTechnician: 'Mobile Repair Technician',
    motorcycleMarineTechnician: 'Motorcycle / Marine Technician',
    none: 'None',
    other: 'Other',
    painter: 'Painter',
    serviceManagerWriter: 'Service Manager / Service Writer',
    shopOwnerTechnician: 'Shop Owner / Technician',
    transmissionSpecialist: 'Transmission Specialist',
  },
  addresses: {
    addCurrentAddress: 'Add Current Address',
    addPreviousAddress: 'Add Previous Address',
    additional: 'Additional',
    billingAddress: 'Billing Address',
    current: 'Current',
    currentAddress: 'Current Address',
    currentAddressAdded: 'Current Address Added',
    deleteCurrentAddress: 'Delete Current Address',
    deletePreviousAddress: 'Delete Previous Address',
    month: 'month',
    months: 'months',
    mortgageName: 'Mortgage Holder/Landlord/Parents Name',
    moveInDate: 'Move-in Date',
    none: 'None',
    permanentHome: 'Permanent Home',
    previous: 'Previous',
    previousAddress: 'Previous Address',
    previousAddressAdded: 'Previous Address Added',
    residencyLength: 'Length of Residency',
    shippingAddress: 'Shipping Address',
    typeOfResidence: 'Type of Residence',
    year: 'year',
    years: 'years',
  },
  residenceType: {
    buy: 'Buy',
    other: 'Other',
    rent: 'Rent',
  },
  cart: {
    addedByAdmin: 'Added by Admin',
    addedByStudent: 'Added by Student',
    addedToCart: 'Product Added to Cart!',
    addNotes: 'Add notes',
    addToCart: 'Add to Cart',
    addToSavedItems: 'Add to Saved Items',
    availabilityFor: item => `Availability for ${item}`,
    cartReview: 'Review',
    catalogPage: 'Catalog Page',
    checkAvailability: 'Check Availability',
    currentCartValue: 'Current Cart Value',
    currentSavedValue: 'Current Saved Value',
    deleteSelectedItems: 'Delete Selected Items',
    deliveryDate: 'Delivery Date',
    description: 'Description',
    directDelivery: 'Direct Delivery',
    discountAmount: 'Discount Amount',
    discountPercent: 'Discount Percent',
    downloadedUploadTemplateMsg:
      'Upload Excel Template was successfully downloaded.',
    emptyCartHelperText: 'Enter a part number to get started',
    emptyCartLabel: 'There are no items added to the cart.',
    emptySavedItems: 'There are no items saved for later',
    exportCartToPdf: 'Export Cart to .pdf',
    exportCartToXls: 'Export Cart to .xlsx',
    frequentlyBoughtTogether: 'Frequently Bought Together',
    grandTotal: 'Grand Total',
    itemSaved: 'Item details saved successfully',
    itemsAvailable: 'Items available',
    lifeCycle: 'Life Cycle',
    lineTotal: 'Line Total',
    listPrice: 'List Price',
    lnLineNotes: 'LN Line Notes',
    menuDownloadTemplate: 'Download Template',
    menuImportExcel: 'Import Items from Excel',
    mergeToOrder: 'Merge to Order',
    moveToCartSuccess:
      'Selected items were successfully moved to Student Cart.',
    moveToSavedSuccess:
      'Selected items were successfully moved to Saved Items.',
    moveToOrderSuccess: 'Selected items were successfully moved to Order.',
    moveToCart: 'Move to Cart',
    moveToCartSelectedItems:
      'Do you want to move the selected item(s) to the shopping cart?',
    moveToOrder: 'Move to Order',
    moveToSavedItems: 'Move to Saved Items',
    moveWithoutValidation: 'Move without validation',
    moveWithValidation: 'Move with validation',
    numberSign: '#',
    partNumber: 'Part Number',
    pleaseEnterProduct: 'Please enter a product',
    pleaseEnterProductMessage:
      'Click on the Search icon or hit Shift + Enter combination to see its details.',
    productAvailabilityError: 'Product Availability cannot be displayed',
    productNotFound: 'Product Not Found',
    quantity: 'Quantity',
    removeSelectedItems: 'Do you want to remove the selected item(s)?',
    savedCart: 'Saved Cart',
    savedForLater: 'Product Saved for Later',
    saveForLaterSelectedItems:
      'Do you want to save the selected item(s) for later?',
    shoppingCart: 'studentCart',
    shippingAndHandling: 'Shipping and Handling',
    studentCartTotals: 'Student Cart Totals',
    studentNotes: 'Student Notes',
    studentNotesPlaceholder:
      'This is a placeholder for instructions, preferences, or special requests.',
    studentPrice: 'Student Price',
    sepSavings: 'SEP Savings',
    showLess: 'Show Less',
    showMore: 'Show More',
    subtotal: 'Subtotal',
    supersede: 'Supersede',
    tax: 'Tax',
    templateName: 'UploadProductsTemplate.xlsx',
    unableToCalculateTax: 'Unable to calculate Tax',
    unableToCalculateTaxStudent:
      'Unable to Calculate Tax. Please Contact Support',
    unableToCalculateDownPayment: 'Unable to calculate Down Payment',
    viewPicture: 'View Picture',
    weight: 'Weight',
    productNotFoundMessage:
      'The part number entered is not a valid Snap-on product.',
    purchasedValue: 'Purchased Value',
    remainingValue: 'Remaining Value',
    maximumValue: 'Maximum Value',
    updatedByAdmin: 'Updated by Admin',
    updatedByStudent: 'Updated by Student',
    saveForLater: 'Save for later',
    orderTotals: 'Order Totals',
    orderTotal: 'Order Total',
    orderList: 'Order List',
    orderTotalStudent: 'Order total',
    continueShopping: 'Continue Shopping',
    checkoutNow: 'Checkout Now',
    lastItemsAdded: 'Last items added',
    viewCart: 'View cart',
    freeShipping: 'Free Shipping',
    unselectAll: 'Unselect All',
    noItemsMessage: isSavedCart =>
      `There are no items in the ${isSavedCart ? 'saved' : 'shopping'} cart`,
    movedToCart: 'Selected items were successfully moved to Cart.',
    movedToSaved: 'Selected items were successfully moved to Saved Cart.',
    unitPrice: 'Unit price',
    productNoResults: 'No results found.',
    productsLoading: 'Loading...',
    numberOfResults: num => `Search Results (${num} Results Found)`,
  },
  order: {
    addItemsFromConsigned: 'Add Items from Consigned',
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',
    addSerialNumbers: 'Add Serial Numbers',
    addSerialNumbersTitle: qty => `Add Serial Numbers for ${qty} products:`,
    addToOrder: 'Add to Order',
    backTo: 'Back to',
    billingTo: 'Billing to',
    canCompleteOrder: 'You can complete your order now.',
    cannotCompleteOrder: isECOrder =>
      `Please make sure the amount you entered is equal to ${
        isECOrder ? 'Down Payment' : 'Grand Total'
      }.`,
    check: 'Check',
    completeOrder: 'Complete Order',
    resendToExchange: 'Resend',
    creditDebitCard: 'Credit/Debit Card',
    currentOrderValue: 'Current Order Value',
    customerName: 'Customer Name',
    defaultDc: 'Default DC',
    debtorNo: 'Debtor No.',
    ec: 'EC',
    exportOrderToPdf: 'Export Order to .pdf',
    exportOrderToXls: 'Export Order to .xlsx',
    giftCard: 'Gift Card',
    item: 'item',
    items: 'items',
    lineItems: 'Line Items',
    moneyOrder: 'Money Order',
    moveFromOrderSuccess: 'Items from Default DC were successfully moved.',
    moveFromOrderWarning:
      "Items that are part of Consigned Inventory can't be moved.",
    noItems: 'There are no items added to the order',
    noItemsHelperText: 'Enter a part number to get started',
    orderConfirmationPage: 'Order Confirmation Page',
    orderPlaced: 'Order Placed',
    orderPlacedFor: studentName =>
      `Order Successfully Placed for ${studentName}`,
    orderSales: 'Order Sales',
    orderTotals: 'Order Totals',
    orderType: 'Order Type',
    otherPayments: 'Other Payments',
    payment: 'Payment',
    paymentType: 'Payment Type',
    paymentNumber: 'Payment Number',
    paymentAmount: 'Payment Amount',
    purchaseLimit: 'Purchase Limit',
    printOrder: 'Print Order',
    processingOrderFor: studentName =>
      `We're processing it now. The Student, ${studentName} will receive soon a confirmation email`,
    privacyPolicy: 'By placing your order, you agree to the ',
    placeOrder: 'Place Order',
    sameAsShipping: 'Same As Shipping',
    selectedCounterPt1: 'Click on the "',
    selectedCounterPt2: '" icon to move or delete the selected items.',
    selectedCounterPt3: '" icon to move or delete the selected item.',
    selectPaymentMethod: 'Select Payment Method',
    selectDebtorNo: 'Select Debtor No.',
    selectPromotion: 'Select Promotion',
    serialNumber: 'Serial Number',
    serialNumbersLimit:
      'You can add a maximum of 99 Serial Numbers for a line item.',
    shippingAndBilling: 'Shipping & Billing',
    shippingTo: 'Shipping to',
    studentOrder: 'studentOrder',
    studentPrice: 'Student Price',
    thxForOrder: 'Thank you for your Order! Your order No is:',
    totalAmountEntered: 'The total amount you entered is:',
    usePermanentHomeAddress: 'Use Permanent Home Address',
    useSchoolAddress: 'Use School Address',
    useDefaultAddress: 'Use Default Profile Address',
    useSameAsShipping: 'Use Same As Shipping Address',
    voucher: 'Voucher',
    voucherAmount: 'Voucher Amount',
    voucherNumber: 'Voucher Number',
    warehouse: 'Warehouse',
    youSelected: number => `You selected ${number}`,
    ecContract: 'EC Contract',
    ecContractFields: {
      downPayment: 'Down Payment',
      securityInterestCharge: 'Security Interest Charge',
      firstInstallmentDueDate: 'First Installment Due Date',
      totalCashSale: 'Total Cash Sale:',
      downPaymentTradeIn: 'Down Payment and/or Trade-in:',
      unpaidBalance: 'Unpaid Balance of Cash Price:',
      amountFinanced: 'Principal Balance (Amt Financed):',
      unpaidPrior: 'Unpaid Prior Principle:',
      otherCharges: 'Other Charges:',
      newPrincipleBalance: 'New Principle Balance:',
      amountOfFinance: 'Amount of Finance Charge:',
      totalOfCredit: 'Total of Credit Sale:',
      paymentInfo: 'Payment Info (APR%):',
      totalOfPayments: 'Total of Payments:',
    },
    ecOrder: 'EC Order',
    promotion: 'Promotion',
    preparingDocuments: 'Preparing Documents. Please wait ...',
    appForm: 'Would you like to fill an EC application form?',
    yesLetsDoIt: "Yes let's do it now:",
    noMaybeLater: 'No, maybe later',
    sureDeleteOrder: 'Are you sure you want to delete this order?',
    sureDeleteCart: 'Are you sure you want to delete all items from the cart?',
    sureDeleteSaved: 'Are you sure you want to delete all saved items?',
    sureSendToExchange: 'Are you sure you want to resend this order?',
    pleaseSelectPromotionAndAccount:
      'Please select an EC Order account and promotion first.',
    directDebit: 'Direct Debit:',
    directDebitFields: {
      drawOption: 'Draw Option',
      date: 'Date',
      amount: 'Amount',
      institution: 'Institution',
      routingNumber: 'Routing #',
      accountNumber: 'Account #',
    },
    trackingNumber: 'Tracking Number',
    carrier: 'Carrier',
    orderSummary: 'Order Summary',
    ecValidation: 'EC Validation',
    profileValidation: 'Profile Validation',
    status: 'Status',
    description: 'Description',
    howToFix: 'How to fix',
    signContract: 'Sign contract',
    confirmSignContract: 'Would you like to sign this contract?',
    track: 'Track Your Order',
    orderTrackingNumbers: 'Order Tracking Numbers',
    noTrackingNumber: 'There is no tracking number available for your Order.',
    creditApp: 'Credit Application',
    noAvailableCreditDialog:
      'The selected application does not have available credit. Would you like to fill an EC Application form?',
    noAvailableCredit:
      'You must have an approved EC Application with Available Credit to be able to continue with the order.',
    validatingOrder: 'Please wait. Your order is processing...',
    searchPartNo: 'Search Part Number',
    sentToExchangeSuccess: 'Order has been sent.',
  },
  pagination: {
    showingFromTo: (from, to, total) => `Showing ${from}-${to} of ${total}`,
    outOf: (from, to, total) => (
      <>
        <strong>
          {from}-{to}
        </strong>{' '}
        out of <strong>{total}</strong>
      </>
    ),
  },
  consigned: {
    actionAddItemsToOrder:
      "This action will add the selected items from the Consigned Warehouse to the Student's Order.",
    addFromWarehouseToOrder: 'Add Items from Warehouse to Order',
    addFromConsignedGetStarted:
      "In order to get started, please select a line item or modify the 'Quantity to Add' field",
    consignedInventoryReport: 'Consigned Inventory Report',
    exportReportToPdf: 'Export Report to .pdf',
    exportReportToXlsx: 'Export Report to .xlsx',
    loadInventoryFromWarehouse: 'Load Inventory from Warehouse:',
    selectInventory: 'Select Inventory:',
    noInventoryAvailableMsg: 'There is no warehouse available.',
    noItemsAvailableMsg: 'There are no items available in this Warehouse.',
    pleaseSelectWarehouse: 'Search warehouses by name or code.',
    partNumber: 'Part Number',
    description: 'Description',
    qtyToAdd: 'Quantity to Add',
    qtyUnavailable: (newQty, availableQty) =>
      `The requested quantity is unavailable. Maximum items available: ${availableQty}. Items requested: ${newQty}. The quantity to add was updated accordingly.`,
    quantity: 'Quantity',
    selectProduct: 'Please select at least one product',
    listPrice: 'List Price',
    lastDateReceived: 'Last Date Received',
    itemDiscontinued: 'Item Discontinued',
    warehouseQty: 'Warehouse Qty',
  },
  export: {
    exportInProgress: 'Export in progress ...',
    cartExported: 'Student Cart exported.',
    orderExported: 'Student Order exported.',
    reportExported: 'Report exported.',
  },
  history: {
    date: 'Date',
    orderPending: 'Pending...',
    placedBy: 'Placed By',
    returnItem: 'Return item',
    selectDateRange: 'Select Date Range:',
    showTransactionTypeFor: 'Show transaction type for:',
    status: 'Status',
    transactionNo: 'Transaction #',
    transactionNotFoundMessage:
      'There are no transactions to be displayed for the selected date range.',
    transactionTotal: 'Transaction Total',
    type: 'Type',
    viewEcApp: 'View EC App',
    viewOrderDetails: 'View Order Details',
    orderDetails: 'Order Details',
    restorePurchasePower: 'Restore Purchase Power',
    orderConfirmationMessage:
      'We’ve sent you a confirmation email with all the details of your order.',
    thankYou: 'Thank you!',
    orderConfirmationNumber: 'Order confirmation number',
    creditDetails: 'Credit Details',
    orderReceipt: 'Order Receipt & Credit Contract',
    updateECContract: 'Update EC Contract',
    contractRejected: 'The contract was rejected',
    updateContract: 'Update Contract',
    deleteContract: 'Delete Contract and Cancel Order',
    updateContractConfirmation:
      'Updating the EC Contract will invalidate the contract signatures and will overwrite the active order. Do you want to continue?',
  },
  approveReject: {
    approve: 'Approve',
    approved: 'Approved',
    approveAll: 'Approve all',
    approveSelected: 'Approve Selected',
    approvedStudents: 'Approve Student Registration',
    deselect: 'Deselect',
    noStudentsAvailableText: section => `There are no ${section} students`,
    pendingStudents: 'Pending Student Registration',
    reject: 'Reject',
    rejected: 'Rejected',
    rejectAll: 'Reject all',
    rejectSelected: 'Reject Selected',
    rejectedStudents: 'Reject Student Registration',
    select: 'Select',
    pending: 'Pending',
    confirmationModalText: (status, count) =>
      `Are you sure you want to ${status} all ${count} students?`,
    noFilter:
      'Fill one or more filters at the top to populate the lists with results.',
    dialogMessage: action =>
      `Are you sure you want to ${action} the following number of students?`,
  },
  login: {
    logIn: 'Log In',
    signIn: 'Sign In',
    studentEmailOrId: 'Student Email / Snap-on Username',
    loggedInMsg: 'You are now logged in.',
    createAnAccount: 'Create an Account',
    register: 'Register',
    createAccountDescription:
      'If you are new to the Student Excellence Program, please make sure to follow the steps in the registration form, so you can create an account.',
    snaponAccount: 'Don’t have a Snap-on Account?',
    newToSnapOn: 'New to Snap-on SEP?',
    registerDescription:
      'Join our Student Excellence Program so you can purchase professional quality Snap-on tools at a discount.',
  },
  forgotPassword: {
    description:
      'Please enter the email adress associated with your account and we’ll send you an email with instructions to reset your password.',
    confirmNewPassword: 'Confirm New Password',
    expiredResetToken:
      "The token is invalid or expired. You can have a new link sent by clicking on 'RESET MY PASSWORD' button.",
    newPassword: 'New Password',
    passwordLinkExpired: 'Password link expired',
    resendLink: 'Resend Link',
    resetMyPassword: 'Reset my Password',
    resetPassConfirmation: 'You have successfully reset your account password.',
    resetPassword: 'Reset password',
    studentEmail: 'Student Email',
    submittedMessage:
      'If the account is valid you’ll receive an e-mail shortly.',
  },
  orderDetailsHistory: {
    authorizationNumber: 'CC Authorization #',
    creditCard: 'Credit Card',
    creditCardAmount: 'Credit Card Amount',
    freeShipping: 'Free shipping',
    here: 'here.',
    lineType: 'Line Type',
    orderNumber: 'Order Number',
    orderStatus: 'Order Status',
    orderSubmitDate: 'Order Submitted Date',
    orderedBy: 'Ordered By',
    orderedItems: 'Ordered items',
    otherPaymentAmount: 'Other Payment Amount',
    otherPaymentNumber: 'Other Payment Number',
    otherPaymentType: 'Other Payment Type',
    paymentMethod: 'Payment Method',
    restorePurchaseItems: 'Restore Purchase Items',
    restorePurchaseMsg:
      'In case you need to restore purchased power for any of the already ordered items, click ',
    shippingAndHandling: 'Shipping and Handling',
    noOrder: 'No. Order: ',
    placedBy: `Placed by: `,
    inDate: ' in ',
    viewDetails: 'View Details',
    inventorySource: 'Inventory Source',
  },
  productPage: {
    downloadManual: 'Download Manual',
    noOverviewAvailable: 'There is no description for this product.',
    noSpecsAvailable: 'There are no specifications for this product.',
    specs: 'Specs',
    userManual: 'User Manual',
    videoPlayerError: 'There was a problem playing the video.',
    warnings: 'Warnings',
    itemInBackorder:
      'Item Backorder. Shipment dates are subject to change. Estimated in stock',
    itemInStock: 'Item available in stock',
    productAvailability: 'Product Availability cannot be displayed',
    remainingQtyShipped:
      'The remaining quantity will be shipped once available.',
    requestedQtyIs:
      'The requested quantity is not currently available. The current available shipping quantity is',
    sets: 'SETS',
    setMembers: 'SET MEMBERS',
    backToProduct: 'Back to Product',
    loadingAvailability:
      'Please wait, we are checking availability for the item.',
    itemUnavailable:
      'Item is unavailable for purchase. Please contact your Snap-on Sales representative.',
  },
  creditCard: {
    cvvVisaTitle: 'Visa®, Mastercard®, and Discover®:',
    cvvVisaText:
      'Turn your card over and look at the signature box. You should see either the entire 16-digit credit card number or just the last four digits followed by a special 3-digit code. This 3-digit code is your CVV/CVC number / Card Security Code.',
    cvvAmericanTitle: 'American Express®:',
    cvvAmericanText:
      'Look for the 4-digit code printed on the front of your card just above and to the right of your main credit card number. This 4-digit code is your Card Identification Number (CID).',
  },
  restorePurchase: {
    noItemsToRestoreMsg:
      'The student no longer has any items that can be used to restore purchasing power. Purchasing power is at its maximum level.',
    returnListPrice: 'Return List Price',
    amountRestoredToStdPP: '(Amount Restored to Student Purchasing Power)',
    totalListPrice: 'Total List Price',
    completeRestoreDisclaimer:
      'This Transaction will only restore Student Purchasing Power at List Price (No Money is Refunded).',
    completeRestore: 'Complete Restore',
    undoRestoreTitle: 'A restore was performed for the following items: ',
    undoRestoreMessage: 'Changed your mind? You can ',
    undoRestoreBtnLabel: 'undo restore here.',
    restoreSuccessfulMsg:
      'The Purchasing value was successfully restored with the amount selected.',
    undoRestoreSuccessMsg: 'The changes were reverted with success.',
    noItemsFoundMsg:
      'There are no items to be displayed for the search term entered.',
  },
  termsAndConditions: {
    title: 'Terms and Conditions of the Snap-on Student Excellence Program',
    content: (
      <>
        <p>
          1. Snap-on Industrial, a division of IDSC Holdings LLC, (“Snap-on”)
          and its affiliates and partners have the right to change, limit,
          modify or cancel the program, program rules, and regulations at any
          time.
        </p>
        <p>
          2. Eligibility for the Snap-on Student Excellence Program (”Snap-on
          SEP Program”) is limited to individuals who qualify as a full-time
          student attending a public or private educational institution
          (excluding correspondence and online education programs) at the
          secondary, post-secondary or professional training level and are
          enrolled in a degree, diploma or recognized certificate program, in
          which the use of hand tools is essential (”Student”).
        </p>
        <p>
          3. Eligibility for the Snap-on SEP Program is further limited to
          Students whose public or private educational institution is a
          participating school in the Snap-on SEP Program (”SEP School”).
        </p>
        <p>
          4. Eligibility of each Student requires that the Student register at
          http://buy1.snapon.com/sep (”Snap-on SEP Website”) and submit the
          following personal information for eligibility and verification
          purposes: first name, last name, home address, email address, phone
          number, fax number, cell phone number, name of school and campus, name
          of program, name of instructor and anticipated graduation date. The
          eligibility of each Student must be verified by the designated Snap-on
          SEP Account Manager or Snap-on SEP Customer Service.
        </p>
        <p>
          5. By checking the box to sign up for one or more text messaging
          programs, you expressly consent to receive non-marketing and marketing
          text messages from Snap-on and others texting on its behalf, including
          text messages made with an autodialer, at the telephone number(s) that
          you provide. You may opt-out of these communications at any time, and
          consent to receive marketing text messages is not required to purchase
          any goods or services. Message frequency varies. Student’s cellular
          service provider may charge the Student a fee for transmitting and
          delivering text messages. By providing your consent to participate in
          this program you approve any such charges from your mobile carrier.
          Charges for text messages may appear on your mobile phone bill or be
          deducted from your prepaid balance. To stop receiving text messages,
          reply with <strong>STOP</strong> to this text.{' '}
          <strong>
            By enrolling in this subscription program, you consent that
            following such a request to unsubscribe, you will receive one (1)
            final message from Snap-on confirming that you have been inactivated
            in our system. Following such confirmation message, no additional
            text messages will be sent unless you re-activate your subscription.
          </strong>{' '}
          Snap-on may use an automatic dialing system to deliver text messages
          to you. The Federal Communications Commission defines an "automatic
          telephone dialing system" or autodialer as equipment that has the
          capacity to store or produce telephone numbers to be called and to
          call such numbers. 47 C.F.R. § 64.1200. By opting in to Snap-on’s SMS
          texting, you give your consent to Snap-on to use an automatic dialing
          system to deliver text messages to the telephone or cell phone number
          to which you have directed Snap-on to transmit these text messages.
          Student should notify Snap-on of a change in phone number, and
          information about how to provide that information.
        </p>
        <p>
          6. Participation is available to qualified and verified Students
          through a link provided on the Snap-on SEP Website.
        </p>
        <p>
          7. Each Student may purchase up to $12,000 worth of Snap-on SEP
          product at List price along with one (1) roll cabinet tool box and one
          (1) top chest tool box for the entire period during which the Student
          participates in the Snap-on SEP Program. All tools purchased under
          this program are for personal use of the student and not for resale.
        </p>
        <p>
          8. Product must be redeemed during the Student's Enrollment Period.
          All Product not redeemed prior to the end of the Student's Enrollment
          Period will be forfeited.
        </p>
        <p>
          9. Snap-on is the selling entity for all sales transactions made
          during and as part of the Snap- on SEP Program. Snap-on products and
          other branded products may be subject to sales tax. Such tax
          liability, including disclosure, connected with Student's receipt
          and/or use of the Snap-on products or other branded products is the
          Student's responsibility.
        </p>
        <p>
          10. Snap-on products offered at the Snap-on SEP Program discounted
          rates and available for sale to Students include those Snap-on
          products and other branded products listed on the Snap-on SEP Website
          (”SEP Products”). Snap-on reserves the right to modify the offering of
          SEP Products. Discounted rates and availability of SEP Products are
          subject to change.
        </p>
        <p>
          11. Each Student's Total SEP Purchases for Snap-on tool storage
          products is limited to one (1) roll cabinet tool box and one (1) top
          chest tool box. In the event Student purchases one (1) roll cabinet
          tool box in the KRA5000 series, the Student may only combine this
          purchase with a top chest tool box in the KRA4059 series or the
          KRA4055 series.
        </p>
        <p>
          12. All other Snap-on products or other branded products not offered
          for sale through the Snap-on SEP Program ('Non-SEP Products') may be
          purchased by Students at current published retail list prices.
        </p>
        <p>
          13. Student personal information collected via the Snap-on SEP Program
          and/or the Snap-on SEP Website may be disclosed to affiliated
          companies and authorized independent dealers and distributors for
          purposes of facilitating direct marketing and services and sales of
          Non-SEP Products during the Enrollment Period, as well as future
          direct marketing and services and sales of Snap-on products and other
          branded products after the Student’s Enrollment Period.
        </p>
        <p>
          14. All Terms and Conditions of Product Sale, not inconsistent with
          the Terms and Conditions of the Snap-on SEP Program apply. Terms of
          payment will require an authorized credit card transaction, except
          that certain lease financing may be made available through Snap-on
          Credit LLC. See the Terms and Conditions of Product Sale for
          information related to payment method, shipping and returns, and other
          terms of sale. In the event of returns, previously awarded Points may
          be credited to the Student’s account at the sole discretion of
          Snap-on. Snap-on may revise, modify, or amend these Terms and
          Conditions at any time. Any such revision, modification, or amendment
          shall take effect when it is posted to Snap-on’s website. You agree to
          review these Terms and Conditions periodically to ensure that you are
          aware of any changes.
        </p>
        <p>
          15. Participation in the Snap-on SEP Program may be suspended at the
          sole discretion of Snap-on, if the Student fails to pay any bills or
          accounts when due.
        </p>
        <p>
          16. In case of fraud or abuse involving the Snap-on SEP Program and/or
          the Snap-on SEP Website, Snap-on and its affiliates and partners will
          have the right to take appropriate administrative and/or legal action.
        </p>
        <p>
          17. Employees of Snap-on and its affiliates and independent Snap-on
          Dealers and employees of Snap-on Dealers are ineligible to participate
          in the Snap-on SEP Program.
        </p>
      </>
    ),
  },
  wizard: {
    subTitle:
      'You can choose to fill the application gradually, or go directly to the EC validation and sign.',
    addressInfo: 'Address Info',
    customerInfo: 'Customer Info',
    employerInfo: 'Employer Info',
    validation: 'Validation',
    saveAndContinue: 'Save & Continue',
    signNow: 'Sign Now',
    signLater: 'Sign Later',
    currentAddSameAsPermLabel:
      'Current Address is the same with my Permanent Address:',
    ECApp: 'EC Application',
    ECAppFor: 'EC Application for: ',
    validationMessage:
      'Please make sure to fix the possible errors in the previous steps. And remember that warning messages are not mandatory to be fixed.',
    noValidationMsg: 'There are no errors or warnings.',
    youCanSignNow: 'You can sign EC Application',
    viewApplication: 'View Application',
    emailECApp: 'Email EC Application',
    emailFiles: 'Email Files',
    sendEmail: 'Send email(s)',
    industrialRep: 'Industrial Rep.',
    addEmail: 'Add Email',
    additionalRecipients: 'Additional recipients',
    addNewEmail: 'Add new email',
    loading: 'Loading...',
    emailsSent: 'Emails sent successfully',
  },
  ecContractSigning: {
    ecOrderFor: 'EC Order for:',
    askSign: 'Would you like to sign this Contract?',
    sign: 'Sign',
    creditSaleContract: '1. Credit Sale Contract',
    disclosure: state =>
      `The ${state} state requires the signing of a disclosure before viewing the contract.`,
    pleaseSignFirstContract:
      'Please sign the first contracts(s) to enable the signing of this one.',
    contractAlreadySigned: 'This contract is already signed.',
    orderSubmitted: name => `Your order for ${name} was submitted.`,
    document: 'Document',
    sendEmail: 'Send Email',
    sendEmails: 'Send Email(s)',
    viewDocument: 'View Document',
    emailTo: 'Email to',
    student: 'Student',
    industrialRep: 'Industrial Rep.',
    additionalRecipients: 'Additional recipients',
    addEmail: 'Add email',
    addNewEmail: 'Add new email',
    invalidEmail: 'Invalid email',
  },
  cookiesModal: {
    cookiesSettings: 'Cookies Settings',
  },
  printDialog: {
    title: 'Student Excellence Program',
    orderConfirmation: 'Order Confirmation',
    summaryTitle: 'Summary - Order',
    description: 'Description',
    listPrice: 'List price',
    discountPrice: 'SEP Discount Price',
    sku: 'SKU',
    quantity: 'Quantity',
    total: 'Total',
    serialNumber: 'Serial#',
    date: 'Date',
    studentName: 'Student Name',
    studentPhone: 'Student Phone No',
    schoolName: 'School Name',
    salesRep: 'Sales Rep',
    customerService: 'Snap-on Customer Service',
    customerServiceNumber: '877-740-1900',
    shippingAddress: 'Shipping Address',
    administrator: 'Administrator',
    subtotal: 'Subtotal',
    tax: 'Tax',
    shippingAndHandling: 'Shipping and Handling',
    freeShipping: 'Free Shipping',
    orderTotal: 'Order Total',
    sepSavings: 'Your SEP Savings',
    paymentMethods: 'Payment methods',
    ccAuthorization: 'CC Authorization #',
    unableToCalculateTax: 'Unable to Calculate Tax. Please Contact Support',
  },
};

export default appStrings;
