import { customIconMapping, propTypes } from './customIconConstants';

import React from 'react';
import _uniqueId from 'lodash/uniqueId';
import classNames from 'classnames';
import sepFonts from '../../../core/assets/sep-industrial-font.module.css';
import { sepIndustrialFont } from '../../../core/strings/appConstants';

function CustomIcon({ icon, customClasses, size, ...props }) {
  const numOfShapes = Array(customIconMapping[icon])
    .fill(0)
    .map((el, i) => i + 1);

  const iconClasses = classNames(
    sepFonts[sepIndustrialFont],
    sepFonts[`icon-${icon}`],
    customClasses
  );

  const pathClasses = index => classNames(sepFonts[`path${index}`]);

  const renderedPaths = numOfShapes.map(shape => (
    <span key={_uniqueId('icon_')} className={pathClasses(shape)} />
  ));

  return (
    <span
      className={iconClasses}
      style={{ fontSize: size }}
      data-test-id={`customIcon-${icon}`}
      {...props}
    >
      {renderedPaths}
    </span>
  );
}

CustomIcon.propTypes = propTypes;
export default CustomIcon;
