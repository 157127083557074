import appStrings from '../../../core/strings/appStrings';
import { emailPattern } from '../../../core/strings/regexValidations';
import { snaponDomain } from '../../../core/strings/appConstants';

export const gridLayout = {
  xs: 12,
};
// TODO: Ask Vali about validations
export const inputs = [
  {
    name: 'email',
    label: appStrings.forgotPassword.studentEmail,
    type: 'text',
    defaultValue: '',
    validations: {
      required: true,
      validate: {
        emailDomain: value =>
          !value.includes(snaponDomain) ||
          appStrings.validationMessages.emailBadDomain,
        emailPattern: value =>
          emailPattern.test(value) || appStrings.validationMessages.emailFormat,
      },
      maxLength: 100,
    },
    gridLayout,
  },
];
