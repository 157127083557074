import AutoComplete from './AutoComplete/AutoComplete';
import CurrencyField from './CurrencyField/CurrencyField';
import CheckboxField from './CheckboxField/CheckboxField';
import DateTimeField from './DateTimeField/DateTimeField';
import HiddenField from './HiddenField/HiddenField';
import MaskedField from './MaskedField/MaskedField';
import NormalField from './NormalField/NormalField';
import NumberField from './NumberField/NumberField';
import PasswordField from './PasswordField/PasswordField';
import RadioField from './RadioField/RadioField';
import SelectField from './SelectField/SelectField';
import SliderField from './SliderField/SliderField';
import SocialSecurityField from './SocialSecurityField/SocialSecurityField';
import SwitchField from './SwitchField/SwitchField';
import propTypes from './propTypes';

function FormInput({ field, ...props }) {
  let fieldErrors = props.errors;
  const fieldPath = field.name.split('.');
  fieldPath.forEach(node => {
    fieldErrors = fieldErrors ? fieldErrors[node] : null;
  });

  const getInput = InputTypeComponent => () =>
    <InputTypeComponent field={field} fieldErrors={fieldErrors} {...props} />;

  const inputTypes = {
    autocomplete: getInput(AutoComplete),
    checkbox: getInput(CheckboxField),
    date: getInput(DateTimeField),
    dateTime: getInput(DateTimeField),
    hidden: getInput(HiddenField),
    other: getInput(NormalField),
    password: getInput(PasswordField),
    radio: getInput(RadioField),
    select: getInput(SelectField),
    slider: getInput(SliderField),
    ssn: getInput(SocialSecurityField),
    switch: getInput(SwitchField),
    tel: getInput(MaskedField),
    number: getInput(NumberField),
    currency: getInput(CurrencyField),
  };

  return inputTypes[field.type] ? inputTypes[field.type]() : inputTypes.other();
}

FormInput.propTypes = propTypes;

export default FormInput;
