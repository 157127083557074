import { REGISTER_IND } from '../../../core/navigation/paths';
import appStrings from '../../../core/strings/appStrings';

export const listOfPathsRegister = [
  {
    link: REGISTER_IND,
    label: appStrings.register.registerNewStudent,
  },
];

export const currentPathRegister = {
  label: appStrings.register.registrationConfirmationPage,
};
