import { Grid, Typography } from '@mui/material';
import React from 'react';
import appStrings from '../../../../core/strings/appStrings';
import PropTypes from 'prop-types';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import { buttonTypes } from '../../../../core/strings/appConstants';
import { useNavigate } from 'react-router-dom';
import { SHOPPINGCART } from '../../../../core/navigation/paths';
import { useSelector } from 'react-redux';
import { calculateProductsTotal, formatPrice } from '../ShoppingCartService';

function CartSummaryFooter({ cartLength, handleClose }) {
  const navigate = useNavigate();
  const isLoggedIn = useSelector(state => state.login.isLoggedIn);
  const studentOrderTotal = useSelector(
    state => state.guestCart.studentCartSummaryOrderTotal
  );
  const guestOrderTotal = calculateProductsTotal(
    JSON.parse(localStorage.getItem('guestItemsCart'))?.value || []
  );

  return (
    <Grid
      container
      alignItems="center"
      id="cartSummaryFooter"
      data-test-id="cartSummaryFooter-testId"
      spacing={1}
    >
      <Grid item container spacing={1}>
        <Grid
          item
          container
          xs={12}
          id="cartSummaryTotal"
          data-test-id="cartSummaryTotal-testId"
          spacing={1}
        >
          <Grid container item sm={7}>
            <Grid item sm={5} id="cartSummary-orderTotalLabel">
              <Typography variant="h1" fontWeight="bold" fontSize="medium">
                {appStrings.cart.orderTotal}
              </Typography>
            </Grid>
            <Grid item sm={7}>
              <Typography
                variant="h1"
                fontSize="small"
                id="orderItemCount"
                data-test-id="orderItemCount-testId"
              >
                {cartLength ? appStrings.common.itemsNumber(cartLength) : ''}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item justifyContent="right" sm={5} alignItems="center">
            <Typography
              variant="h1"
              fontWeight="bold"
              fontSize="medium"
              id="orderTotalValue"
              data-test-id="orderTotalValue-testId"
            >
              {formatPrice(isLoggedIn ? studentOrderTotal : guestOrderTotal)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CustomButton
          id="viewCartBtn"
          data-test-id="viewCartBtn-testId"
          size="large"
          btnType={buttonTypes.primary}
          label={appStrings.cart.viewCart}
          color={'red'}
          onClick={() => {
            navigate(SHOPPINGCART);
            handleClose();
          }}
        />
      </Grid>
    </Grid>
  );
}

CartSummaryFooter.propTypes = {
  cartLength: PropTypes.number,
  handleClose: PropTypes.func,
};

export default CartSummaryFooter;
