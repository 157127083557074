import { makeStyles } from 'tss-react/mui';

const useConsignedInventoryDetailsStyle = makeStyles()(theme => ({
  container: {
    marginTop: theme.spacing(3),
    '& .MuiFormHelperText-root': {
      lineHeight: 0,
    },
  },
  subTitle: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightBold,
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  customBox: {
    background: theme.palette.common.neutral4,
    margin: theme.spacing(10),
    padding: theme.spacing(10),
  },
  pagination: {
    marginLeft: theme.spacing(2),
  },
  marginTop2: {
    marginTop: theme.spacing(2),
  },
  marginBottom1: {
    marginBottom: theme.spacing(1),
  },
  greyContainer: {
    background: theme.palette.common.neutral4,
    padding: '16px',
  },
  bold: { fontWeight: theme.typography.fontWeightBold },
  editableField: {
    width: '70px',
    '& .MuiInputBase-input': {
      textAlign: 'center',
      marginLeft: '8px',
    },
  },
  htmlTooltip: {
    backgroundColor: theme.palette.common.neutral1,
    color: theme.palette.common.red,
    maxWidth: 220,
    fontSize: theme.typography.caption.fontSize,
    border: `1px solid ${theme.palette.common.lightGrey}`,
    padding: theme.spacing(2),
  },
}));

export default useConsignedInventoryDetailsStyle;
