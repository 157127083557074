import ApiService from '../Api/apiService';
import urls from '../../../core/strings/urls';

export const submitResetPassword = data => {
  const formattedData = { ...data, newPassword: btoa(data.newPassword) };

  return ApiService.post(urls.resetPassword, formattedData);
};

export const validateResetPasswordLink = token => {
  const payload = {
    token
  };
  return ApiService.post(urls.validateResetPasswordLink, payload);
};
