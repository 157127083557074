import { makeStyles } from 'tss-react/mui';

export const useAccountStyles = makeStyles()(theme => ({
  mainContainer: {
    '& .MuiBox-root': {
      border: `1px solid ${theme.palette.common.grey4}`,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(3),

      '@media screen and (max-width:768px)': {
        border: 0,
        borderTop: `1px solid ${theme.palette.common.grey4}`,
        borderBottom: `1px solid ${theme.palette.common.grey4}`,
        borderRadius: 0,
      },
    },

    '& .MuiBreadcrumbs-li>:is(a, p)': {
      fontSize: '14px',
    },

    '& .MuiSkeleton-root': {
      borderRadius: theme.spacing(2),
    },
  },

  profileSectionLink: {
    '& .MuiGrid-container': {
      borderBottom: `1px solid ${theme.palette.common.grey4}`,
      paddingBottom: theme.spacing(2),
      margin: theme.spacing(1, 0),
    },

    '& .MuiButtonBase-root': {
      color: 'black !important',
      textDecoration: 'none',
    },

    '& .MuiSvgIcon-root': {
      color: theme.palette.common.grey1,
      border: `1px solid ${theme.palette.common.grey4}`,
      borderRadius: theme.spacing(0.5),
      background: theme.palette.common.grey6,
    },

    '& .MuiTypography-root': {
      textAlign: 'start',
      wordBreak: 'break-word',
    },
  },

  profileSectionLinkMobile: {
    padding: '0px !important',
    overflowX: 'scroll',
    whiteSpace: 'nowrap',

    '& .MuiButtonBase-root': {
      color: 'black !important',
      textDecoration: 'none',
      padding: `0px !important`,
      margin: theme.spacing(0, 1),
    },

    '& .MuiTypography-root': {
      padding: theme.spacing(3, 2),
    },
  },

  activeSection: {
    '& .MuiTypography-root': {
      borderBottom: '2px solid',
      borderColor: theme.palette.common.red,
    },
  },

  breadCrumbsStyle: {
    paddingBottom: theme.spacing(2),

    '@media screen and (max-width:768px)': {
      borderBottom: '1px solid',
      borderColor: theme.palette.common.grey4,
      padding: theme.spacing(2),
      background: theme.palette.background.paper,
    },
  },

  sectionErrors: {
    background: `${theme.palette.common.neutralRed1} !important`,
  },
}));

export const useAccountInfoStyles = makeStyles()((theme, isMyAccountPage) => ({
  mainContainer: {
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      justifyContent: !isMyAccountPage && 'center',
    },
  },

  textsContainer: {
    direction: 'column',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      textAlign: isMyAccountPage ? 'flex-start' : 'center',
      marginLeft: isMyAccountPage ? theme.spacing(2) : 0,
    },
  },

  accountInfoDivider: {
    background: theme.palette.common.grey1,
  },

  welcomeText: { fontSize: '16px', color: theme.palette.common.grey2 },

  userNameText: { fontSize: '18px', fontWeight: 'bold' },

  button: {
    padding: theme.spacing(2, 0),
    display: 'block',
    [theme.breakpoints.only('xs')]: {
      display: isMyAccountPage && 'none',
    },
  },

  avatarStyle: {
    border: '2px solid',
    borderColor: theme.palette.common.red,
    borderRadius: '50%',
    borderStyle: 'dashed',
    width: '85px',
    height: '85px',
    padding: '2px',
  },

  editPictureIcon: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.text.primary,
      padding: theme.spacing(0.5),
      border: '1px solid',
      borderColor: theme.palette.common.grey5,
      borderRadius: theme.spacing(3),
      cursor: 'pointer',
    },

    '& img': {
      borderRadius: '50%',
      width: '85px',
      height: '85px',
    },
  },
}));

export const useCustomInfoBoxStyles = makeStyles()(() => ({
  title: {
    alignItems: 'center',
    display: 'flex',
    borderBottom: '1px solid',
  },
}));
