const wizardStepsCodes = {
  customerInfo: 0,
  addressInfo: 1,
  employerInfo: 2,
  validation: 3,
};
// keep update with list from wizardConstants.js - unable to import it, bc circular import.
// import { wizardStepsCodes } from "../../wizardConstants";

const updateCustomerRecord = 'Update Customer Record';

const n97PossibleValidation = [
  {
    possibleFieldName: 'lastName',
    status: 1,
    description: 'Customer Last Name Missing',
    howToFix: updateCustomerRecord,
    wizardStep: wizardStepsCodes.customerInfo,
  },
  {
    possibleFieldName: 'CustAddr',
    status: 1,
    description: 'Customer Address Missing',
    howToFix: updateCustomerRecord,
    wizardStep: wizardStepsCodes.addressInfo,
  },
  {
    possibleFieldName: 'currentAddress.city',
    status: 1,
    description: 'Customer City Missing',
    howToFix: updateCustomerRecord,
    wizardStep: wizardStepsCodes.addressInfo,
  },
  {
    possibleFieldName: 'currentAddress.zipCode',
    status: 1,
    description: 'Customer Zip Code Missing',
    howToFix: updateCustomerRecord,
    wizardStep: wizardStepsCodes.addressInfo,
  },
  {
    possibleFieldName: 'CustHomePhone',
    status: 0,
    description: 'Customer Phone Number Missing',
    howToFix: updateCustomerRecord,
    wizardStep: wizardStepsCodes.customerInfo,
  },
  {
    possibleFieldName: 'permanentAddress.residenceType',
    status: 0,
    description: 'Residence Status Unknown',
    howToFix: 'Update Customer Record with Buy, Rent, or Other',
    wizardStep: wizardStepsCodes.addressInfo,
  },
  {
    possibleFieldName: 'currentAddress.residencyLength',
    status: 0,
    description: 'Length of Residence Missing',
    howToFix: updateCustomerRecord,
    wizardStep: wizardStepsCodes.addressInfo,
  },
  {
    possibleFieldName: 'driverLicenseNumber',
    status: 0,
    description: "Driver's License Number Missing",
    howToFix: updateCustomerRecord,
    wizardStep: wizardStepsCodes.customerInfo,
  },
  {
    possibleFieldName: 'firstName',
    status: 1,
    description: 'Customer First Name Missing',
    howToFix: 'Complete the Customer record',
    wizardStep: wizardStepsCodes.customerInfo,
  },
  {
    possibleFieldName: 'driverLicenseState',
    status: 0,
    description: "Driver's License State Invalid",
    howToFix: "Update Driver's License State",
    wizardStep: wizardStepsCodes.customerInfo,
  },
  {
    possibleFieldName: 'phone1.phoneNumber',
    status: 1,
    description: 'Bad Customer Home Phone Number',
    howToFix: 'Enter a Valid Home Phone Number',
    wizardStep: wizardStepsCodes.customerInfo,
  },
  {
    possibleFieldName: 'EmplPhone',
    status: 1,
    description: 'Bad Employer Phone Number',
    howToFix: 'Enter a Valid Employer Phone Number',
    wizardStep: wizardStepsCodes.employerInfo,
  },
  {
    possibleFieldName: 'socialSecurityNumber',
    status: 1,
    description: 'Bad Customer SSN',
    howToFix: 'Enter a Valid SSN',
    wizardStep: wizardStepsCodes.customerInfo,
  },
  {
    possibleFieldName: 'birthdate',
    status: 1,
    description: 'Date of Birth Missing',
    howToFix: 'Enter Customers Date of Birth',
    wizardStep: wizardStepsCodes.customerInfo,
  },
  {
    possibleFieldName: 'CustBirthDate',
    status: 1,
    description: 'Customer Under 18',
    howToFix: 'Customer Under 18',
    wizardStep: wizardStepsCodes.customerInfo,
  },
  {
    possibleFieldName: 'birthdate',
    status: 1,
    description: 'Verify Customers Date of Birth',
    howToFix: 'Verify the Customers Date of Birth is less than 100 years old',
    wizardStep: wizardStepsCodes.customerInfo,
  },
  {
    possibleFieldName: 'CustState',
    status: 1,
    description: 'Invalid Customer State',
    howToFix: 'Enter a Valid State',
    wizardStep: wizardStepsCodes.addressInfo,
  },
  {
    possibleFieldName: 'socialSecurityNumber',
    status: 1,
    description: 'SSN Missing',
    howToFix: 'Populate SSN',
    wizardStep: wizardStepsCodes.customerInfo,
  },
  {
    possibleFieldName: 'socialSecurityNumber',
    status: 1,
    description: 'Invalid SSN',
    howToFix: 'Update SSN',
    wizardStep: wizardStepsCodes.customerInfo,
  },
  {
    possibleFieldName: 'presentEmployer',
    status: 1,
    description: 'Missing Employer Information',
    howToFix: 'Update Employer Information',
    wizardStep: wizardStepsCodes.employerInfo,
  },
];

export const getPossibleStep = validation => {
  let index = 0;
  let fieldName = '';

  const possibleValidation = n97PossibleValidation.filter(
      val => (val.description === validation.description?.trim() && val.howToFix === validation.howToFix)
  );

  if (possibleValidation.length) {
    index = possibleValidation[0].wizardStep;
    fieldName = possibleValidation[0].possibleFieldName;
  }

  return {
    index: index,
    possibleFieldName: fieldName
  };
};
