import { makeStyles } from 'tss-react/mui';

const useInactiveNotificationStyles = makeStyles()(theme => ({
  inactiveAlert: {
    backgroundColor: '#ffecee',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(3),

    '& .MuiAlert-icon': {
      backgroundColor: 'unset !important',
    }
  },

  homeScreenNotification: {
    marginTop: theme.spacing(3),
    maxWidth: '82vw',
    alignSelf: 'center',

    '@media screen and (max-width: 768px)': {
      maxWidth: '95vw',
    },
  }
}));

export default useInactiveNotificationStyles;