import appStrings from './appStrings';
import { historyTransactionTypeByCode } from './appConstants';

export const phoneTypeOptions = [
  {
    label: appStrings.phoneTypes.none,
    value: '',
  },
  {
    label: appStrings.phoneTypes.cellPhone,
    value: 1,
  },
  {
    label: appStrings.phoneTypes.home,
    value: 2,
  },
  {
    label: appStrings.phoneTypes.business,
    value: 3,
  },
  {
    label: appStrings.phoneTypes.other,
    value: 4,
  },
];

export const maritalStatusOptions = [
  {
    label: appStrings.maritalStatus.none,
    value: '',
  },
  {
    label: appStrings.maritalStatus.single,
    value: 1,
  },
  {
    label: appStrings.maritalStatus.married,
    value: 2,
  },
];

export const referenceRelationships = [
  { value: '', label: 'None' },
  { value: '1', label: 'Friend' },
  { value: '3', label: 'Parent' },
  { value: '4', label: 'Sibling' },
  { value: '5', label: 'Spouse' },
  { value: '6', label: 'Other' },
];

export const residenceTypeOptions = [
  {
    label: appStrings.addresses.none,
    value: '',
  },
  {
    label: appStrings.residenceType.buy,
    value: 1,
  },
  {
    label: appStrings.residenceType.rent,
    value: 2,
  },
  {
    label: appStrings.residenceType.other,
    value: 3,
  },
];

export const approvalStatusOptions = [
  { label: appStrings.common.approved, value: 1 },
  { label: appStrings.common.declined, value: 2 },
  { label: appStrings.common.pending, value: 0 },
];

export const positionOptions = [
  { label: appStrings.positions.none, value: '' },
  { label: appStrings.positions.aftermarketAccessoryInstaller, value: 1 },
  { label: appStrings.positions.apprentice, value: 2 },
  { label: appStrings.positions.autoTechRoutineMaintenance, value: 3 },
  { label: appStrings.positions.aviationTechnician, value: 4 },
  { label: appStrings.positions.bodyRepairTechnician, value: 5 },
  { label: appStrings.positions.diagnosticDrivabilityTechnician, value: 6 },
  { label: appStrings.positions.heavyDutyEquipmentTechnician, value: 7 },
  { label: appStrings.positions.heavyDutyTruckDieselTechnician, value: 8 },
  { label: appStrings.positions.machinist, value: 9 },
  { label: appStrings.positions.mobileRepairTechnician, value: 10 },
  { label: appStrings.positions.motorcycleMarineTechnician, value: 11 },
  { label: appStrings.positions.other, value: 12 },
  { label: appStrings.positions.painter, value: 13 },
  { label: appStrings.positions.serviceManagerWriter, value: 14 },
  { label: appStrings.positions.shopOwnerTechnician, value: 15 },
  { label: appStrings.positions.transmissionSpecialist, value: 16 },
];

export const getTransactionTypeOptions = () => {
  const transactionTypeOptions = [];

  for (const property in historyTransactionTypeByCode) {
    transactionTypeOptions.push({
      label: historyTransactionTypeByCode[property],
      value: property,
    });
  }

  return transactionTypeOptions;
};
