import CustomBox from '../../../shared/CustomBox/CustomBox';
import CustomSkeleton from '../../../shared/CustomSkeleton/CustomSkeleton';
import CustomTableSkeleton from '../../../shared/CustomSkeleton/CustomTableSkeleton';
import { Grid } from '@mui/material';
import { Skeleton } from '@mui/material';
import { lineItemsTableColumns } from '../orderHistoryDetailsConstants';
import useOrderHistoryDetailsStyles from '../useOrderHistoryDetailsStyles';

function OrderHistoryDetailsSkeleton() {
  const css = useOrderHistoryDetailsStyles().classes;
  const topLayout = [
    { xs: 3, width: '90%', variant: 'rect' },
    { xs: 3, width: '70%', variant: 'rect' },
    {
      xs: 6,
      width: '20%',
      variant: 'rect',
      container: true,
      justifyContent: 'flex-end',
    },
  ];
  return (
    <Grid
      data-test-id="orderHistoryDetailsSkeleton"
      container
      spacing={2}
      direction="column"
    >
      {/* name card */}
      <Grid item>
        <CustomSkeleton layout={topLayout} />
      </Grid>
      {/* order details */}
      <Grid item>
        <CustomBox customClass={css.accordion}>
          <Grid>
            <Skeleton width={'20%'} />
            <Skeleton width={'30%'} />
            <Skeleton width={'40%'} />
            <Skeleton width={'20%'} />
            <Skeleton width={'50%'} />
            <Skeleton width={'35%'} />
          </Grid>
        </CustomBox>
      </Grid>
      {/* order total */}
      <Grid item>
        <CustomBox customClass={css.accordion}>
          <Grid>
            <Skeleton width={'20%'} />
            <Skeleton width={'70%'} />
          </Grid>
        </CustomBox>
      </Grid>
      {/* order items */}
      <Grid item>
        <CustomBox customClass={css.accordion}>
          <Grid item xs={12}>
            <Skeleton width={'30%'} />
          </Grid>
          <CustomTableSkeleton columns={lineItemsTableColumns} />
          <Grid item xs={12}>
            <Skeleton width={'70%'} />
          </Grid>
        </CustomBox>
      </Grid>
      {/* Shipping and billing */}
      <Grid item>
        <CustomBox customClass={css.accordion}>
          <Grid container>
            <Grid item xs={12}>
              <Skeleton width={'30%'} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Skeleton width={'20%'} />
              <Skeleton width={'40%'} />
              <Skeleton width={'65%'} />
              <Skeleton width={'15%'} />
              <Skeleton width={'35%'} />
              <Skeleton width={'38%'} />
              <Skeleton width={'45%'} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Skeleton width={'23%'} />
              <Skeleton width={'70%'} />
              <Skeleton width={'65%'} />
              <Skeleton width={'37%'} />
              <Skeleton width={'35%'} />
              <Skeleton width={'38%'} />
              <Skeleton width={'50%'} />
            </Grid>
          </Grid>
        </CustomBox>
      </Grid>

      {/* payment*/}
      <Grid item>
        <CustomBox customClass={css.accordion}>
          <Grid>
            <Skeleton width={'50%'} />
            <Skeleton width={'70%'} />
            <Skeleton width={'50%'} />
            <Skeleton width={'45%'} />
            <Skeleton width={'65%'} />
          </Grid>
        </CustomBox>
      </Grid>

      {/* restore*/}
      <Grid item>
        <CustomBox customClass={css.accordion}>
          <Grid>
            <Skeleton width={'30%'} />
            <Skeleton width={'70%'} />
          </Grid>
        </CustomBox>
      </Grid>
    </Grid>
  );
}

export default OrderHistoryDetailsSkeleton;
