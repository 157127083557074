const encoder = new TextEncoder('utf-8');

export class SimpleOttp {
  /**
   * Creates a new Simple OTTP instance
   * @param {*} skey - The secret key used to generate token
   * @param {*} pkey - The RSA public key - {mod: '', exp: ''}
   * @param {*} expiration - The expiration window (30s - default)
   * @param {*} length - The token length (6 - default)
   */
  constructor(skey, pkey, expiration = 30, length = 6) {
    // validation for parameters
    if (!skey) {
      throw new Error('Error: Key not provided');
    }
    if (!pkey || !pkey.mod || !pkey.exp) {
      throw new Error('Error: Public key not provided or incorrect format');
    }
    if (length > 8 || length < 6) {
      throw new Error('Error: Invalid token length');
    }

    const paddedKeyLength = Math.ceil(skey.length / 16) * 16; // compute padded key length and encode as Byte Array
    this.skey = new Uint8Array(paddedKeyLength);
    this.skey.set(encoder.encode(skey));

    this.pkey = pkey;
    this.length = length;
    this.expiration = expiration;
  }

  padLeft(str, len, pad) {
    let string = str;
    if (len + 1 >= string.length) {
      string = Array(len + 1 - string.length).join(pad) + string;
    }
    return string;
  }

  getTimeData(now) {
    const epochTime = Math.round(now / 1000);
    const timeInput = Math.floor(epochTime / this.expiration);

    const encodedTime = new DataView(new ArrayBuffer(8));
    encodedTime.setInt32(0, timeInput, true);

    const timeArray = new Uint8Array(encodedTime.buffer);
    timeArray.reverse(); // reverse to use big endian with padding first

    return timeArray;
  }

  /**
   * Generates a new encrypted Token
   * @param {*} now - Optional NOW parameter
   */
  async generate(now = new Date().getTime()) {
    const Crypto = window.crypto.subtle;

    // import key for totp
    const ckey = await Crypto.importKey(
      'raw',
      this.skey,
      { name: 'HMAC', hash: { name: 'SHA-1' } },
      false,
      ['sign']
    );
    const encodedHmac = new Uint8Array(
      await Crypto.sign('HMAC', ckey, this.getTimeData(now))
    );

    const offset = encodedHmac[encodedHmac.byteLength - 1] & 0x0f;
    let timeToken = (
      ((encodedHmac[offset] & 0x7f) << 24) |
      ((encodedHmac[offset + 1] & 0xff) << 16) |
      ((encodedHmac[offset + 2] & 0xff) << 8) |
      (encodedHmac[offset + 3] & 0xff) % 1000000
    ).toString();

    if (timeToken.length > this.length) {
      timeToken = timeToken.substr(timeToken.length - this.length, this.length);
    } else {
      timeToken = this.padLeft(timeToken, this.length, '0');
    }

    // import public key for RSA
    const pukey = await Crypto.importKey(
      'jwk',
      {
        kty: 'RSA',
        n: this.pkey.mod,
        e: this.pkey.exp,
        alg: 'RSA-OAEP-256',
      },
      {
        name: 'RSA-OAEP',
        hash: { name: 'SHA-256' },
      },
      false,
      ['encrypt']
    );

    // use RSA to encrypt the token
    const encryptedTotp = await Crypto.encrypt(
      {
        name: 'RSA-OAEP',
      },
      pukey,
      encoder.encode(timeToken)
    );

    // convert to base64
    return btoa(String.fromCharCode(...new Uint8Array(encryptedTotp)));
  }
}
