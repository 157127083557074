import ApiService from '../../shared/Api/apiService';
import urls from '../../../core/strings/urls';

export const getOrderDetailsHistory = (orderNumber, studentId) => {
  return ApiService.get(urls.getOrderDetailsHistory(orderNumber, studentId));
};

export const exportCompletedOrderByType = (payload, isExcel = true) => {
  return ApiService.post(
    isExcel ? urls.exportCompletedOrderAsExcel : urls.exportCompletedOrderAsPdf,
    payload
  );
};
