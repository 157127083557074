import React from 'react';
import CustomButton from '../../CustomButton/CustomButton';
import CustomDialog from '../../CustomDialog/CustomDialog';
import {
  buttonColors,
  buttonTypes,
} from '../../../../core/strings/appConstants';
import appStrings from '../../../../core/strings/appStrings';
import { icons } from '../../../../core/strings/icons';
import CustomIcon from '../../CustomIcon/CustomIcon';
import useEmployerStyles from './useEmployerInformationStyles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

function DeleteButton({
  deleteLabel,
  handleClickOpen,
  idHelper,
  open,
  handleClose,
  deleteButtons,
  isStudent,
}) {
  const css = useEmployerStyles().classes;

  return (
    <div className={css.deleteContainer}>
      <CustomButton
        sx={{ border: isStudent ? '0px !important' : '' }}
        color={!isStudent ? buttonColors.red : buttonColors.black}
        btnType={buttonTypes.secondary}
        label={
          !isStudent
            ? `${appStrings.common.delete} ${deleteLabel}`
            : appStrings.common.delete
        }
        onClick={handleClickOpen}
        id={'delete' + idHelper}
        startIcon={isStudent && <DeleteOutlineIcon />}
        size={isStudent ? 'small' : ''}
      />
      <CustomDialog
        open={open}
        handleClose={handleClose}
        icon={<CustomIcon icon={icons.trash} customClasses={css.deleteIcon} />}
        title={appStrings.studentProfile.removeProfileInfo(deleteLabel)}
        buttons={deleteButtons}
        id={`delete${idHelper}Dialog`}
      />
    </div>
  );
}

export default DeleteButton;
