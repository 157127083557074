import { Checkbox, Grid, TextField } from '@mui/material';
import useSendEmailModalDialogStyles from '../useSendEmailDialogStyles';
import { isInvalidEmail } from '../SendEmailDialogService';
import appStrings from '../../../../core/strings/appStrings';

const EmailInput = ({ value, setValue, label, isChecked, setIsChecked }) => {
  const css = useSendEmailModalDialogStyles().classes;

  return (
    <Grid item xs={12}>
      <Checkbox
        checked={isChecked}
        onClick={() => {
          setIsChecked(!isChecked);
        }}
        className={css.emailCheckbox}
      />

      <TextField
        error={isInvalidEmail(value)}
        size="small"
        defaultValue={value}
        onBlur={e => setValue(e.target.value)}
        label={label}
        className={css.emailInput}
        helperText={
          isInvalidEmail(value) ? appStrings.ecContractSigning.invalidEmail : ''
        }
      />
    </Grid>
  );
};

export default EmailInput;
