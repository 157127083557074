import {
  currentPathOrderHistory,
  listOfPathsOrderHistory,
  menuItemsOrderHistory,
} from './orderHistoryDetailsConstants';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';

import CustomBreadcrumbs from '../../shared/CustomBreadcrumbs/CustomBreadcrumbs';
import { Grid } from '@mui/material';
import NameCard from '../NameCard/NameCard';
import OrderDetails from './components/OrderDetails';
import OrderHistoryDetailsSkeleton from './components/OrderHistoryDetailsSkeleton';
import OrderPaymentHistory from './components/OrderPaymentHistory';
import OrderRestore from './components/OrderRestore';
import OrderShippingAndBilling from './components/OrderShippingAndBilling';
import OrderTotals from './components/OrderTotals';
import OrderedItems from './components/OrderedItems';
import { displayError } from '../../../core/redux/slices/notificationsSlice';
import { getOrderDetailsHistory } from './orderDetailsHistoryService';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import PrintOrder from './components/PrintOrder/PrintOrder';
import { getStudentDetails } from '../../shared/StudentProfileForm/studentProfileFormService';
import { selectedStudent } from '../../../core/redux/slices/schoolsWithStudentsSlice';
import useOrderHistoryDetailsStyles from './useOrderHistoryDetailsStyles';

function OrderHistoryDetails() {
  const { id, bp, orderId } = useParams();
  const dispatch = useDispatch();
  const student = useSelector(
    state => state.schoolsWithStudents.selectedStudent.student
  );
  const [orderDetails, setOrderDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const css = useOrderHistoryDetailsStyles().classes;
  const studentLoadingRef = useRef(false);
  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    getOrderDetailsHistory(orderId, id)
      .then(resp => {
        if (resp) {
          setOrderDetails(resp);
        }
      })
      .catch(err => {
        dispatch(displayError({ message: err }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, id, orderId]);

  useEffect(() => {
    if (!student && !studentLoadingRef.current) {
      studentLoadingRef.current = true;
      getStudentDetails(id).then(resp => {
        dispatch(
          selectedStudent({
            bpNumber: bp,
            student: {
              ...resp,
              active: resp.studentAccountStatus === 'Active',
            },
          })
        );
        studentLoadingRef.current = false;
      });
    }
  }, [bp, dispatch, id, student]);

  return (
    <>
      {isLoading ? (
        <OrderHistoryDetailsSkeleton />
      ) : (
        <Grid container>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <NameCard
                student={student}
                showName={false}
                moreMenuItems={menuItemsOrderHistory(id, orderId, handlePrint)}
                showMoreMenu
                resendToExchange={{
                  isDisplayed: !orderDetails.isSentToExchange,
                  orderNumber: orderId,
                  callback: () =>
                    setOrderDetails(prev => ({
                      ...prev,
                      isSentToExchange: true,
                    })),
                }}
              >
                {
                  <CustomBreadcrumbs
                    listOfPaths={listOfPathsOrderHistory(bp, id, student)}
                    currentPath={currentPathOrderHistory}
                  />
                }
              </NameCard>
            </Grid>

            <OrderDetails orderDetails={orderDetails} />

            <OrderTotals orderDetails={orderDetails} />

            <OrderedItems orderDetails={orderDetails} />

            <OrderShippingAndBilling orderDetails={orderDetails} />

            <OrderPaymentHistory orderDetails={orderDetails} />

            <OrderRestore
              bp={bp}
              id={id}
              orderNumber={orderDetails?.orderNumber}
            />
          </Grid>
          {Boolean(student) && Boolean(orderDetails) && (
            <div className={css.hidden}>
              <PrintOrder
                ref={printRef}
                orderDetails={orderDetails}
                student={student}
              />
            </div>
          )}
        </Grid>
      )}
    </>
  );
}

export default OrderHistoryDetails;
