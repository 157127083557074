import { makeStyles } from 'tss-react/mui';

const useOrderTypeStyles = makeStyles()(theme => ({
  orderTypeContainer: {
    padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(1.5)}`,
    height: `${theme.spacing(9)}`,
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  buttonGroup: {
    '& button': {
      padding: '5px 15px',
      border: `1px solid ${theme.palette.common.lightGrey}`,
      color: theme.palette.common.black,
      background: theme.palette.common.white,
      textTransform: 'none',
      '&.Mui-selected': {
        color: theme.palette.common.white,
        background: theme.palette.common.primary,
        '&:hover': {
          background: theme.palette.common.primary5,
        },
      },
    },
  },
  inputContainer: {
    paddingLeft: theme.spacing(4),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  dialog: {
    width: '500px',
    padding: theme.spacing(2),
    '.MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '.MuiDialogActions-root': {
      '.MuiButton-contained': {
        width: '220px',
      },
    },
  },
}));

export default useOrderTypeStyles;
