import Addresses from './Addresses/Addresses';
import CustomerInformation from './CustomerInformation/CustomerInformation';
import EmployerInformation from './EmployerInformation/EmployerInformation';
import PropTypes from 'prop-types';
import References from './References/References';
import SOCAccountInfo from './SOCAccountInfo/SOCAccountInfo';
import SchoolInformation from './SchoolInformation/SchoolInformation';
import appStrings from '../../../core/strings/appStrings';

export const profileComponents = ({ sectionErrors, ...properties }) => [
  {
    label: appStrings.studentProfile.customerInformation,
    id: 'customerInfo',
    component: <CustomerInformation {...properties} />,
    hasErrors: sectionErrors.customerInfo,
  },
  {
    label: appStrings.studentProfile.schoolInformation,
    id: 'schoolInformation',
    component: <SchoolInformation {...properties} />,
    hasErrors: sectionErrors.schoolInfo,
  },
  {
    label: appStrings.studentProfile.addresses,
    id: 'addresses',
    component: <Addresses {...properties} />,
    hasErrors: sectionErrors.addresses,
  },
  {
    label: appStrings.studentProfile.employerInformation,
    id: 'employerInformation',
    component: <EmployerInformation {...properties} />,
    hasErrors: sectionErrors.employerInfo,
  },
  {
    label: appStrings.studentProfile.references,
    id: 'references',
    component: <References {...properties} />,
    hasErrors: sectionErrors.references,
  },
  {
    label: appStrings.studentProfile.creditAccountInfo,
    id: 'socAccountInfo',
    component: <SOCAccountInfo {...properties} />,
  },
];

export const propTypes = {
  formResources: PropTypes.shape({
    control: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
    getValues: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
    isDirty: PropTypes.bool,
    onSubmit: PropTypes.func,
  }),
  id: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
};
