import { useRef, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { useNavigate } from 'react-router-dom';

import {
  currentPathRegister,
  listOfPathsRegister,
} from './registerConfirmationConstants';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CustomBox from '../CustomBox/CustomBox';
import CustomBreadcrumbs from '../CustomBreadcrumbs/CustomBreadcrumbs';
import NameCard from '../../industrial/NameCard/NameCard';
import appStrings from '../../../core/strings/appStrings';
import useRegisterConfirmationStyles from './useRegisterConfirmationStyles';
import { clearRegisteredStudent } from '../../../core/redux/slices/registerSlice';
import { REGISTER_IND } from '../../../core/navigation/paths';
import { useEffect } from 'react';

function RegisterConfirmation() {
  const registerState = useSelector(state => state.register);
  const [student, setStudent] = useState({ firstName: '', lastName: '' });
  const isInitialised = useRef(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const css = useRegisterConfirmationStyles().classes;

  useEffect(() => {
    if (!isInitialised.current) {
      isInitialised.current = true;
      if (registerState.isRegistered) {
        setStudent(cloneDeep(registerState));
        dispatch(clearRegisteredStudent());
      } else {
        navigate(REGISTER_IND);
      }
    }
  }, [registerState, dispatch, navigate]);

  return (
    <Grid container data-test-id="registerConfirmationPage">
      <Grid item xs={12}>
        <NameCard
          student={student}
          showName={false}
          isRegisterConfirmation={true}
        >
          <CustomBreadcrumbs
            listOfPaths={listOfPathsRegister}
            currentPath={currentPathRegister}
          />
        </NameCard>
      </Grid>
      <Grid item xs={12}>
        <CustomBox customClass={css.registerConfirmationContainer}>
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="h2">
                <Grid container alignItems="center" flexWrap="nowrap">
                  <CheckCircleOutlineRoundedIcon
                    data-test-id="checkIcon"
                    color="primary"
                  />
                  <Typography variant="h2" className={css.leftMargin}>
                    <strong>
                      {appStrings.register.successfullyRegistered(
                        `${student.firstName} ${student.lastName}`
                      )}
                    </strong>
                  </Typography>
                </Grid>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h3">
                {appStrings.register.studentWillReceiveEmail}
              </Typography>
            </Grid>
          </Grid>
        </CustomBox>
      </Grid>
    </Grid>
  );
}

export default RegisterConfirmation;
