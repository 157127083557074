import CONSTANTS from '../constants';
import { createSlice } from '@reduxjs/toolkit';

const wizardSlice = createSlice({
  name: CONSTANTS.wizard,
  initialState: {
    activeStep: 0,
    isECAppSubmitted: false,
    transactionNumber: '',
    possibleFieldName: {
      fieldName: '',
      howToFix: ''
    },
    
  },
  reducers: {
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    setPossibleFieldName: (state, action) => {
      state.possibleFieldName = action.payload;
    },
    closeWizard: state => {
      state.activeStep = 0;
      state.isECAppSubmitted = false;
    },
    submittedECApp: state => {
      state.isECAppSubmitted = true;
    },
    setTransactionNumber: (state, action) => {
      state.transactionNumber = action.payload;
    },
  },
});

export const {
  setActiveStep,
  setPossibleFieldName,
  closeWizard,
  submittedECApp,
  setTransactionNumber
} = wizardSlice.actions;
export default wizardSlice.reducer;
