import CustomBox from '../../../shared/CustomBox/CustomBox';
import appStrings from '../../../../core/strings/appStrings';
import useProductsListStyles from '../useProductsListStyles';

function SelectedItemsCounter({ selectedCounter }) {
  const css = useProductsListStyles().classes;

  return (
    selectedCounter > 0 && (
      <CustomBox
        data-test-id="selectedItemsCounter"
        customClass={css.selectedItemsCounterContainer}
      >
        <strong>
          {`${appStrings.order.youSelected(selectedCounter)} ${
            selectedCounter === 1
              ? appStrings.order.item
              : appStrings.order.items
          }. ${appStrings.order.selectedCounterPt1}`}
          <span className={css.plusSymbol}>+</span>
          {selectedCounter === 1
            ? appStrings.order.selectedCounterPt3
            : appStrings.order.selectedCounterPt2}
        </strong>
      </CustomBox>
    )
  );
}

export default SelectedItemsCounter;
