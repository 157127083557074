import { useState } from 'react';
import CustomBackdrop from '../../shared/CustomBackdrop/CustomBackdrop';
import CustomButton from '../../shared/CustomButton/CustomButton';
import CustomDialog from '../../shared/CustomDialog/CustomDialog';
import appStrings from '../../../core/strings/appStrings';
import { buttonTypes } from '../../../core/strings/appConstants';
import ApiService from '../../shared/Api/apiService';
import urls from '../../../core/strings/urls';
import { useDispatch, useSelector } from 'react-redux';
import {
  displayError,
  displaySuccess,
} from '../../../core/redux/slices/notificationsSlice';
import { ADMIN } from '../../../core/navigation/roles';
import { useParams } from 'react-router-dom';

function ResendToExchange({
  orderNumber,
  btnType = buttonTypes.primary,
  callback,
}) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { role } = useSelector(state => state.login.userDetails);
  const isAdmin = role === ADMIN;
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const buttons = [
    {
      label: appStrings.common.yes,
      onClick: () => {
        setIsDialogOpen(false);
        setIsLoading(true);
        sendToExchange(id, orderNumber)
          .then(() => {
            dispatch(
              displaySuccess({
                message: appStrings.order.sentToExchangeSuccess,
              })
            );
            callback();
          })
          .catch(err => {
            dispatch(displayError({ message: err }));
          })
          .finally(() => setIsLoading(false));
      },
    },
    {
      label: appStrings.common.no,
      btnType: buttonTypes.secondary,
      onClick: () => setIsDialogOpen(false),
    },
  ];

  const sendToExchange = (studentId, orderNumber) => {
    return ApiService.post(urls.sendToExchange, { studentId, orderNumber });
  };

  return (
    isAdmin && (
      <>
        <CustomBackdrop open={isLoading} />
        <CustomDialog
          id="resend-to-exchange-confirmation"
          open={isDialogOpen}
          buttons={buttons}
          title={appStrings.order.sureSendToExchange}
        />
        <CustomButton
          data-test-id="resend-to-exchange-btn"
          id="resend-to-exchange-btn"
          label={appStrings.order.resendToExchange}
          btnType={btnType}
          onClick={() => setIsDialogOpen(true)}
        />
      </>
    )
  );
}

export default ResendToExchange;
