import {
  saveEmployerInformation,
  validateDatePickers,
} from '../../../../industrial/StudentProfile/Services/studentProfileService';

import { cloneDeep } from 'lodash';
import { isSignNowBtnClicked } from '../wizardPageContainerService';

export const saveEmployerInfo = ({
  data,
  id,
  onSuccessCallback,
  reset,
  setValue,
  onErrorCallback,
}) => {
  const initialData = cloneDeep(data);

  if (validateDatePickers().valid) {
    Promise.all([saveEmployerInformation({ id, data, setValue })])
      .then(() => {
        if (onSuccessCallback) {
          onSuccessCallback(true, isSignNowBtnClicked());
        }
      })
      .catch(err => {
        if (onErrorCallback) {
          onErrorCallback(err);
        }
      });

    reset(initialData);
  }
};
