import appStrings from '../../../core/strings/appStrings';

export const addProductSummaryInputs = ({
  handleSubmit,
  onSubmit,
  searchItem,
  onProductNumberChange,
  onQuantityChange,
  isOrder,
  onProductNumberFocus,
}) => {
  const fields = [
    {
      name: 'productNumber',
      label: appStrings.cart.partNumber,
      type: 'text',
      gridLayout: { xs: 12, sm: 5, md: 3, lg: 3 },
      onChangeCallback: onProductNumberChange,
      onEnterCallback: handleSubmit(onSubmit),
      onShiftEnterCallback: searchItem,
      autoFocus: true,
      noHelperText: true,
      onFocus: onProductNumberFocus,
    },
    {
      name: `quantity`,
      label: appStrings.cart.quantity,
      type: 'number',
      validations: {
        maxLength: 4,
        validate: {
          minQty: value =>
            Number(value) > 0 || appStrings.validationMessages.minQuantityInput,
        },
      },
      gridLayout: { xs: 12, sm: 3, md: 3, lg: 2 },
      onChangeCallback: onQuantityChange,
      onEnterCallback: handleSubmit(onSubmit),
      onShiftEnterCallback: searchItem,
      removeStartZero: true,
    },
  ];
  if (isOrder) {
    fields.push({
      name: 'studentPrice',
      label: appStrings.order.studentPrice,
      type: 'currency',
      validations: {
        validate: {
          minValue: value =>
            !value ||
            Number(value) >= 0.01 ||
            appStrings.validationMessages.minAmount,
        },
      },
      gridLayout: { xs: 12, sm: 3, md: 3, lg: 2 },
      onEnterCallback: handleSubmit(onSubmit),
      onShiftEnterCallback: searchItem,
    });
  }
  return fields;
};
