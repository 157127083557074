import { makeStyles } from 'tss-react/mui';

export const useSwitchLabelStyles = makeStyles()(theme => ({
  root: {
    alignItems: 'unset',
    margin: 0,
  },
  label: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h3.fontSize,
  },
}));

export const useBtnStyles = makeStyles()(theme => ({
  root: {
    padding: '0px !important',
    border: 0,
    marginTop: theme.spacing(1),
    minWidth: '60px',
    width: '60px',
    height: '24px',
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'uppercase',
    fontSize: `12px !important`,
    '& div': {
      width: '50%',
    },
  },
  whiteBtn: {
    width: '24px',
    height: '20px',
    background: theme.palette.common.neutral1,
    borderRadius: '3px',
    margin: '2px',
    display: 'block',
  },
  moveRight: {
    float: 'right',
  },
  text: {
    textAlign: 'center',
    padding: '0 2px',
    lineHeight: '24px',
  },
  default: {
    background: theme.palette.common.veryLightGrey,
    color: theme.palette.common.darkGrey,
  },
}));
