import React, { useCallback, useEffect, useState } from 'react';
import {
  getSchoolAddress,
  getSchoolsByStateAndRep,
} from '../../Register/registerService';
import {
  getSchoolInformation,
  getSortedRepSchoolStates,
  handleError,
} from './schoolInformationService';
import {
  stateOptionsSortedByLabel,
  states,
} from '../../../../core/strings/states';

import { ADMIN, STUDENT } from '../../../../core/navigation/roles';
import CustomBox from '../../CustomBox/CustomBox';
import FormInput from '../../FormInput/FormInput';
import { Grid } from '@mui/material';
import StandardFormSkeleton from '../../CustomSkeleton/StandardFormSkeleton';
import { inputs } from './schoolInfoInputs';
import setMultipleValues from '../../../../core/services/formService';
import { useParams } from 'react-router';
import useSchoolInfoStyles from './useSchoolInformationStyles';
import { useSelector } from 'react-redux';
import MandatoryFieldsMessage from '../../MandatoryFieldsMessage/MandatoryFieldsMessage';

function SchoolInformation({
  control,
  setValue,
  getValues,
  watch,
  errors,
  isStudent,
}) {
  const css = useSchoolInfoStyles().classes;
  const { id } = useParams();
  const { employeeId, role } = useSelector(state => state.login.userDetails);
  const [previousSchoolState, setPreviousSchoolState] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [schoolBpOptions, setSchoolBpOptions] = useState([]);
  const [disableUsesTools, setDisableUsesTools] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);

  // TODO fix multiple renders
  // TODO fix autocomplete
  const updateData = useCallback(
    ({ data, res }) => {
      setSchoolBpOptions(data ?? []);
      setPreviousSchoolState(res.schoolState);
      if (!res.isEmployed) {
        setDisableUsesTools(true);
      }
      setMultipleValues({ setValue, values: res });
      setIsLoading(false);
    },
    [setValue]
  );

  useEffect(() => {
    const getSchools = res => {
      if (res.schoolState) {
        getSchoolsByStateAndRep(res.schoolState, employeeId, true)
          .then(result => {
            if (result?.length) {
              const data = result.map(item => {
                return getSchoolAddress(item);
              });
              updateData({ data, res, setValue });
              setMultipleValues({ setValue, values: res });
            }
          })
          .catch(error => {
            handleError({ error });
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
      }
    };

    if (!previousSchoolState) {
      Promise.all([
        role !== ADMIN && role !== STUDENT
          ? getSortedRepSchoolStates(employeeId)
          : stateOptionsSortedByLabel(Object.getOwnPropertyNames(states)),
        getSchoolInformation(id),
      ])
        .then(res => {
          setStateOptions(res[0]);
          if (res[1]) {
            getSchools(res[1]);
          }
        })
        .catch(error => handleError({ error }));
    }
  }, [employeeId, id, previousSchoolState, role, setValue, updateData]);

  const allInputs = inputs({
    getValues,
    previousSchoolState,
    setPreviousSchoolState,
    setValue,
    schoolBpOptions,
    setSchoolBpOptions,
    disableUsesTools,
    setDisableUsesTools,
    stateOptions,
    employeeId,
    isStudent,
  });
  const firstGroupInputs = allInputs.slice(0, !isStudent ? 6 : 5);
  const secondGroupInputs = allInputs.slice(!isStudent ? 6 : 7);

  const displayInputsGroup = groupFields => (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      spacing={2}
      style={getStyle()}
    >
      {' '}
      {groupFields.map(field => (
        <Grid
          item
          {...field.gridLayout}
          key={`${field.name}-container`}
          data-test-id={`${field.name}`}
        >
          <FormInput
            field={field}
            control={control}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
            errors={errors}
            size={isStudent && 'medium'}
          />
        </Grid>
      ))}
    </Grid>
  );

  const getStyle = () => (isLoading ? { display: 'none' } : {});

  return (
    <>
      {isLoading ? (
        <StandardFormSkeleton />
      ) : (
        <>
          <CustomBox
            customClass={css.schoolInfoContainer}
            fadeBorder
            style={getStyle()}
          >
            {displayInputsGroup(firstGroupInputs)}
          </CustomBox>
          {displayInputsGroup(secondGroupInputs)}
          <Grid container className={css.mandatoryMessageContainer}>
            <MandatoryFieldsMessage />
          </Grid>
        </>
      )}
    </>
  );
}

export default SchoolInformation;
