import {
  checkPricingUpdates,
  deleteCartItems,
  getCartDetails,
  getSelectedItems,
  isCorrectCartType,
  setCurrentCartType,
  shouldGetCartDetails,
  updateTotals,
  validateTotals,
} from '../cartService';
import {
  resetOrderData,
  resetReloadCart,
  resetReloadOrderItems,
  resetTotals,
  setContractDetails,
  setDownPayment,
  setECContractLoading,
  setItemsList,
  setTotals,
  updateCart,
} from '../../../../core/redux/slices/cartSlice';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import CartContext from './CartContext';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import { loadingStatuses } from '../../../../core/strings/appConstants';
import { getContractData } from '../../ECContract/ecContractService';
import { cartTypes } from '../cartConstants';
import { setShouldGetPurchase } from '../../../../core/redux/slices/purchasePowerSlice';
import { getStudentDetails } from '../../../shared/StudentProfileForm/studentProfileFormService';
import { selectedStudent } from '../../../../core/redux/slices/schoolsWithStudentsSlice';
import { getPurchasePower } from '../../PurchasePower/purchasePowerService';
import { purchaseTypes } from '../../PurchasePower/purchasePowerConstants';
import { trackGAEvent } from '../../../shared/Analytics/analyticsService';

function CartContextProvider(props) {
  const { pathname } = useLocation();
  const { id, bp } = useParams();
  const dispatch = useDispatch();
  const cartResetDone = useRef(false);
  const getCartDetailsInProgress = useRef(false);
  const currentCartType = useRef(null);
  const purchasePowerLoading = useSelector(
    state => state.purchasePower.isLoading
  );

  const isSavedItems = pathname.indexOf('saved') !== -1;
  const isOrder = pathname.indexOf('order') !== -1;

  const student = useSelector(
    state => state.schoolsWithStudents.selectedStudent.student
  );
  const {
    cartInfo,
    orderInfo,
    uploadInProgress,
    reloadCart,
    reloadOrderItems,
    addItemLoading,
    selectedAccount,
    selectedPromotion,
    isECOrder,
    totals,
  } = useSelector(state => state.cart);
  const schoolsLoading = useSelector(
    state => state.schoolsWithStudents.loading
  );
  const [orderStatus, setOrderStatus] = useState({
    completed: false,
    orderNumber: null,
  });
  const [rowsLoading, setRowsLoading] = useState([]);
  const [isPdfExportDisabled, setIsPdfExportDisabled] = useState(false);
  const [addingProduct, setAddingProduct] = useState(null);
  const [deletingItems, setDeletingItems] = useState(null);
  const [expandedSection, setExpandedSection] = useState('lineItems');
  const [onlyConsignedSelected, setOnlyConsignedSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sectionErrors, setSectionErrors] = useState({
    lineItems: false,
    shippingAndBilling: false,
    payment: false,
  });
  const [completeOrderClicked, setCompleteOrderClicked] = useState(false);
  const [resetAddresses, setResetAddresses] = useState(false);
  const [completingOrder, setCompletingOrder] = useState(false);
  const [resetPaymentData, setResetPaymentData] = useState(false);
  const studentLoadingRef = useRef(false);

  const isInactiveStudent = !student?.active;
  const showLoading =
    isLoading || schoolsLoading === loadingStatuses.pending || uploadInProgress;

  const handleTotals = useCallback(
    totals => {
      const newTotals = validateTotals(totals);
      dispatch(setTotals(newTotals));
      if (totals.taxErrorMessage) {
        dispatch(displayError({ message: totals.taxErrorMessage }));
        if (!isSavedItems) {
          setIsPdfExportDisabled(true);
        }
      }
    },
    [dispatch, isSavedItems]
  );

  const updateTotalsAndPurchasePower = useCallback(() => {
    if (isSavedItems) {
      getPurchasePower(id, purchaseTypes.saved);
    } else {
      updateTotals()
        .then(totals => {
          handleTotals(totals);
        })
        .catch(() => {
          dispatch(displayError());
        });
    }
  }, [dispatch, handleTotals, id, isSavedItems]);

  const deleteItems = itemsToDelete => {
    let selectedItems = {
      studentId: id,
      cartId: cartInfo.cartDetails.cartId,
      cartItemIds: [],
      productsNumber: [],
    };
    const {
      selectedItems: selected,
      productsNumber,
    } = getSelectedItems(null, itemsToDelete);
    selectedItems.cartItemIds = selected;
    selectedItems.productsNumber = productsNumber;
    setDeletingItems(selectedItems.cartItemIds);
    deleteCartItems(selectedItems)
      .then(resp => {
        const {unselectedItems} = getSelectedItems(null, itemsToDelete);
        dispatch(setItemsList(unselectedItems));
        setDeletingItems(null);
        updateTotalsAndPurchasePower();
        if (resp.orderDeleted) {
          dispatch(resetOrderData());
          setResetAddresses(true);
          setResetPaymentData(true);
        }
        selectedItems.productsNumber.forEach(productNumber => {
          trackGAEvent(
            'Remove from Cart by Rep',
            'Shopping Cart',
            productNumber
          );
        });
      })
      .catch(() => {
        dispatch(displayError());
        setDeletingItems(null);
      });
  }

  const value = {
    addingProduct,
    bp,
    cartResetDone,
    deletingItems,
    expandedSection,
    handleTotals,
    id,
    isInactiveStudent,
    isOrder,
    isPdfExportDisabled,
    isSavedItems,
    onlyConsignedSelected,
    orderStatus,
    rowsLoading,
    sectionErrors,
    setAddingProduct,
    setDeletingItems,
    setExpandedSection,
    setIsPdfExportDisabled,
    setOnlyConsignedSelected,
    setOrderStatus,
    setRowsLoading,
    setSectionErrors,
    showLoading,
    student,
    completeOrderClicked,
    setCompleteOrderClicked,
    resetAddresses,
    setResetAddresses,
    completingOrder,
    setCompletingOrder,
    resetPaymentData,
    setResetPaymentData,
    deleteItems,
  };

  useEffect(() => {
    if (!student && !studentLoadingRef.current) {
      studentLoadingRef.current = true;
      getStudentDetails(id).then(resp => {
        dispatch(
          selectedStudent({
            bpNumber: bp,
            student: {
              ...resp,
              active: resp.studentAccountStatus === 'Active',
            },
          })
        );
        studentLoadingRef.current = false;
      });
    }
  }, [bp, dispatch, id, student]);

  useEffect(() => {
    setCurrentCartType({ currentCartType, isSavedItems, isOrder });
    if (getCartDetailsInProgress.current) {
      getCartDetailsInProgress.current = false;
    }
  }, [isSavedItems, isOrder]);

  const handleUpdateCart = useCallback(
    resp => {
      if (isCorrectCartType({ currentCartType, isSavedItems, isOrder })) {
        dispatch(updateCart({ studentId: id, ...resp }));
      }
    },
    [dispatch, id, isOrder, isSavedItems]
  );

  const handleFinallyGetCart = useCallback(() => {
    if (isCorrectCartType({ currentCartType, isSavedItems, isOrder })) {
      setIsLoading(false);
      checkPricingUpdates(reloadOrderItems);
      getCartDetailsInProgress.current = false;
    }
  }, [isOrder, isSavedItems, reloadOrderItems]);

  const handleUpdateTotals = useCallback(
    resp => {
      if (
        currentCartType.current !== cartTypes.saved &&
        isCorrectCartType({ currentCartType, isSavedItems, isOrder })
      ) {
        if (resp.cartDetails?.cartId || resp.orderId || reloadOrderItems) {
          return updateTotals() // return is necessary to keep the loading until totals are updated, to avoid redundant calls for purchase power
            .then(totals => {
              handleTotals(totals);
            })
            .catch(() => {
              dispatch(displayError());
            });
        }
        dispatch(resetTotals());
        if (purchasePowerLoading) {
          dispatch(setShouldGetPurchase(true));
        }
      }
    },
    [
      dispatch,
      handleTotals,
      isOrder,
      isSavedItems,
      purchasePowerLoading,
      reloadOrderItems,
    ]
  );

  useEffect(() => {
    if (
      shouldGetCartDetails({
        id,
        isOrder,
        cartInfo,
        reloadCart,
        orderInfo,
        reloadOrderItems,
        cartResetDone,
        addItemLoading,
        getCartDetailsInProgress: getCartDetailsInProgress.current,
      })
    ) {
      getCartDetailsInProgress.current = true;

      if (!reloadOrderItems) {
        setIsLoading(true);
      }
      dispatch(resetReloadCart());
      dispatch(resetReloadOrderItems());

      getCartDetails({
        studentId: id,
        isSavedItems,
        isOrder,
        reloadOrderItems,
      })
        .then(resp => {
          handleUpdateCart(resp);
          // if the shipping address is not valid, mark section as containing errors
          if (isOrder && !resp.shippingAddress?.recipient && !reloadOrderItems) {
            setSectionErrors(prev => ({ ...prev, shippingAndBilling: true }));
          }

          return handleUpdateTotals(resp);
        })
        .catch(() => {
          dispatch(displayError());
        })
        .finally(() => {
          handleFinallyGetCart();
        });
    }
  }, [
    addItemLoading,
    cartInfo,
    dispatch,
    handleFinallyGetCart,
    handleUpdateCart,
    handleUpdateTotals,
    id,
    isOrder,
    isSavedItems,
    orderInfo,
    reloadCart,
    reloadOrderItems,
  ]);

  useEffect(() => {
    if (
      (isECOrder, selectedAccount && selectedPromotion && totals.grandTotal > 0)
    ) {
      dispatch(setECContractLoading(true));
      getContractData(orderInfo.studentId)
        .then(resp => {
          if (resp) {
            dispatch(setContractDetails(resp));
            dispatch(
              setDownPayment({ downPayment: resp.contract?.downPayment })
            );
          }
        })
        .catch(err => {
          dispatch(displayError({ message: err }));
        })
        .finally(() => {
          dispatch(setECContractLoading(false));
        });
    }
  }, [
    dispatch,
    selectedPromotion,
    selectedAccount,
    orderInfo.studentId,
    totals.grandTotal,
    isECOrder,
  ]);

  return (
    <CartContext.Provider value={value}>{props.children}</CartContext.Provider>
  );
}
export default CartContextProvider;
