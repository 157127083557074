import {
  displayError,
  displayInfo,
  displaySuccess,
} from '../../../core/redux/slices/notificationsSlice';

import ApiService from '../../shared/Api/apiService';
import appStrings from '../../../core/strings/appStrings';
import { defaultWarehouse } from '../AddProduct/addProductConstants';
import { downloadFileFromBlob } from '../../../core/services/downloadService';
import store from '../../../core/redux/store';
import urls from '../../../core/strings/urls';
import { openExportDocument } from '../../../core/services/utilsService';

export function getConsignedInventoriesForEmployer({
  empId,
  isOrder,
  isAdmin,
}) {
  return isOrder || !isAdmin
    ? ApiService.get(urls.getConsignedInventoriesForEmployer(empId))
    : ApiService.get(urls.getAllConsignedInventories);
}

export function getConsignedInventoryDetailsByCode(code) {
  return ApiService.get(urls.getConsignedInventoryDetailsByCode(code));
}

export function getConsignedInventoryByProduct(product, warehouseCode) {
  return ApiService.get(
    urls.getConsignedInventoryDetailsByProduct(product, warehouseCode)
  );
}

export const getRepByStudentId = studentId =>
  ApiService.get(`${urls.getRepByStudentId}/${studentId}`);

export function exportInventory(code, name, isExcel = true) {
  store.dispatch(displayInfo({ message: appStrings.export.exportInProgress }));

  const fileName = `${
    appStrings.consigned.consignedInventoryReport
  } - ${code}.${isExcel ? 'xlsx' : 'pdf'}`;

  const urlToCall = isExcel
    ? urls.downloadConsignedInventoryAsExcel
    : urls.downloadConsignedInventoryAsPdf;

  ApiService.post(urlToCall, { code, name })
    .then(blob => {
      isExcel ? downloadFileFromBlob(blob, fileName) : openExportDocument(blob);
      store.dispatch(
        displaySuccess({ message: appStrings.export.reportExported })
      );
    })
    .catch(e => store.dispatch(displayError({ message: e })));
}

export const getWarehouseDetails = (
  productNumber,
  setWarehouseOptions,
  withItems = false,
  isOrder
) => {
  const employeeId = store.getState().login.userDetails.employeeId;
  return getConsignedInventoriesForEmployer({
    empId: employeeId,
    isOrder,
  }).then(warehouses => {
    let options = [defaultWarehouse];
    return Promise.all(
      warehouses.map(warehouse => {
        return getConsignedInventoryByProduct(
          productNumber,
          warehouse.code
        ).then(inventoryDetails => {
          options.push({
            label: `${warehouse.name} (${warehouse.code}) (${
              inventoryDetails.quantity
            } ${
              inventoryDetails.quantity === 1
                ? appStrings.order.item
                : appStrings.order.items
            })`,
            value: warehouse,
          });
        });
      })
    ).then(() => {
      if (withItems) {
        options = options.filter(
          option => !option.label.includes(appStrings.common.zeroItems)
        );
      }
      setWarehouseOptions(options);
    });
  });
};
