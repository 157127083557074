import { grey } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';

const useBrSearchBarStyles = makeStyles()(theme => ({
  field: {
    width: '100%',
    backgroundColor: 'white',
    border: `${theme.spacing(0.3)} solid`,
    borderRadius: theme.spacing(0.75),
    borderColor: grey['400'],
    position: 'absolute',
    zIndex: 9,
    padding: theme.spacing(0.3),
    marginLeft: '12px',
    marginTop: '10px',

    '& .MuiTextField-root': {
      width: '98%',

      '& .MuiInputBase-root': {
        paddingRight: theme.spacing(0.1),
        borderRadius: theme.spacing(3),

        '& .MuiInputBase-input': {
          padding: '0',
        },
      },

      '& .MuiButtonBase-root': {
        transform: 'none',

        '& #SearchIcon': {
          fontSize: theme.spacing(4),
          backgroundColor: 'red',
          borderRadius: theme.spacing(0.75),

          '& path': { color: 'white' },
        },

        '& #CloseIcon': {
          fontSize: theme.spacing(4),

          '& path': { color: 'red' },
        },
      },
    },

    '& .MuiDivider-root': {
      margin: 'unset',
      marginTop: theme.spacing(1),
      width: '100%',
    },
  },
  list: {
    width: '98%',
    padding: theme.spacing(2, 0, 2, 0),
    textAlign: 'start',
    height: 'fit-content',
    maxHeight: '70vh',
    overflowY: 'scroll',

    '& p': {
      color: grey['500'],
      marginTop: theme.spacing(0.2),
      fontSize: theme.spacing(2),
    },

    '& .MuiList-root': {
      '& span': {
        fontWeight: 'bold',
      },
    },

    '& #search-suggestions': {
      color: 'black',
    },
    '&::-webkit-scrollbar': {
      width: '12px'
    },

    '&:hover::-webkit-scrollbar': {
      width: '15px'
    },

    '&::-webkit-scrollbar-thumb': {
      border: '4px solid rgba(0, 0, 0, 0)',
      backgroundClip: 'padding-box',
      borderRadius: '9999px',
      backgroundColor: grey['400']
    }
  },
}));

export default useBrSearchBarStyles;