import { useDispatch, useSelector } from 'react-redux';
import useOrderTypeStyles from './useOrderTypeStyles';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { buttonTypes, orderType } from '../../../core/strings/appConstants';
import {
  resetDownPayment,
  setAccountAndPromo,
  setHasNoAvailableCredit,
  setIsECOrder,
} from '../../../core/redux/slices/cartSlice';
import {
  getECOrderAccounts,
  getECOrderPromos,
  saveInfo,
} from '../Cart/cartService';
import { displayError } from '../../../core/redux/slices/notificationsSlice';
import { getOrderTypeInputs } from '../Cart/cartConstants';
import {
  Divider,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import CustomBox from '../../shared/CustomBox/CustomBox';
import appStrings from '../../../core/strings/appStrings';
import FormInput from '../../shared/FormInput/FormInput';
import ECAppRedirect from '../ECAppRedirect/ECAppRedirect';
import { useNavigate, useParams } from 'react-router-dom';
import { STUDENT_PROFILE } from '../../../core/navigation/paths';
import CartContext from '../Cart/context/CartContext';
import CustomDialog from '../../shared/CustomDialog/CustomDialog';

function OrderType() {
  const css = useOrderTypeStyles().classes;
  const { id, bp } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartCtx = useContext(CartContext);
  const { completeOrderClicked } = cartCtx;

  const { isECOrder, selectedAccount, selectedPromotion, orderInfo } =
    useSelector(state => state.cart);
  const {
    trigger,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ecOrder: selectedAccount,
      promotion: selectedPromotion,
    },
  });
  const [selectedOrderType, setSelectedOrderType] = useState(
    isECOrder ? orderType.ec : orderType.orderSales
  );
  const [accounts, setAccounts] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [isECAppRedirect, setIsECAppRedirect] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showECAppModal, setShowECAppModal] = useState(false);
  const onChange = (e, selected) => {
    if (selected && selected !== selectedOrderType) {
      setSelectedOrderType(selected);
      saveData({ selected });
    }
  };

  const saveData = ({ selected }) => {
    let accountDebtorNumber = getValues('ecOrder'),
      promoId = getValues('promotion') === '' ? null : getValues('promotion');
    const type = selected ?? selectedOrderType;

    if (type === orderType.orderSales) {
      accountDebtorNumber = null;
      promoId = null;
      setValue('ecOrder', accountDebtorNumber);
      setValue('promotion', promoId);
    }

    saveInfo({
      studentId: orderInfo.studentId,
      accountDebtorNumber,
      promoId,
      type,
    })
      .then(() => {
        dispatch(
          setAccountAndPromo({
            selectedAccount: accountDebtorNumber,
            selectedPromotion: promoId,
          })
        );
        dispatch(setIsECOrder({ orderType: type }));
        dispatch(resetDownPayment());
        if (completeOrderClicked) {
          trigger();
        }
      })
      .catch(err => {
        dispatch(displayError({ message: err }));
      });
  };

  const displaySection = section => {
    if (isECOrder && !isLoading) {
      return section === 'ecAppRedirect' ? isECAppRedirect : !isECAppRedirect;
    }
    return false;
  };

  const orderTypeInputs = getOrderTypeInputs(
    saveData,
    accounts,
    promotions,
    setShowECAppModal
  );

  const handleToggle = () => {
    setIsECAppRedirect(false);
    setSelectedOrderType(orderType.orderSales);
    saveData({ selected: orderType.orderSales });
  };

  useEffect(() => {
    if (completeOrderClicked && isECOrder) {
      trigger();
    }
  }, [completeOrderClicked, isECOrder, trigger]);

  useEffect(() => {
    const selectedAccountDetails = accounts.find(
      acc => acc.value === selectedAccount
    );
    if (selectedAccount && selectedAccountDetails) {
      dispatch(setHasNoAvailableCredit(!selectedAccountDetails.isOpenToBuy));
    }
  }, [accounts, dispatch, selectedAccount]);

  useEffect(() => {
    if (isECOrder) {
      getECOrderAccounts(orderInfo.studentId)
        .then(resp => {
          if (resp) {
            const mappedAccounts = resp.map(item => {
              return {
                label: item.displayName,
                value: item.accountDebtorNumber,
                isOpenToBuy: item.isOpenToBuy,
              };
            });
            setAccounts(mappedAccounts);
            if (!mappedAccounts.length) {
              setIsECAppRedirect(true);
            }
          }
        })
        .catch(err => {
          dispatch(displayError({ message: err }));
        })
        .finally(() => {
          setIsLoading(false);
        });

      getECOrderPromos(orderInfo.studentId)
        .then(resp => {
          if (resp) {
            const mappedPromos = resp.map(item => {
              return {
                label: item.promoName,
                value: item.promoId,
                customLabel: (
                  <Grid item>
                    <Grid item>
                      <Typography>{item.promoName}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{item.description}</Typography>
                    </Grid>
                  </Grid>
                ),
              };
            });
            setPromotions(mappedPromos);
          }
        })
        .catch(err => {
          dispatch(displayError({ message: err }));
        });
    }
  }, [dispatch, getValues, isECOrder, orderInfo.studentId]);

  return (
    <CustomBox customClass={css.orderTypeContainer} id="orderTypeContainer">
      <Grid container>
        <Grid
          item
          xs={isECAppRedirect ? 4 : 12}
          container
          flexDirection="column"
        >
          <Grid item xs={4} id="orderTypeLabel">
            <Typography
              fontWeight="bold"
              className={css.title}
              id="orderTypeLabel"
            >
              {appStrings.order.orderType}:
            </Typography>
          </Grid>
          <Grid item container flexDirection="row">
            <Grid item>
              <ToggleButtonGroup
                exclusive
                value={selectedOrderType}
                onChange={onChange}
                className={css.buttonGroup}
                id="orderTypeOptions"
              >
                <ToggleButton
                  value={orderType.orderSales}
                  id="orderTypeOption-orderSales"
                >
                  {appStrings.order.orderSales}
                </ToggleButton>
                <ToggleButton value={orderType.ec} id="orderTypeOption-ec">
                  {appStrings.order.ec}
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            {displaySection('inputContainer') && (
              <Grid
                item
                xs={8}
                container
                className={css.inputContainer}
                flexDirection="row"
                spacing={2}
              >
                <Divider
                  className={css.divider}
                  orientation="vertical"
                  flexItem
                />
                <Grid item {...orderTypeInputs.ecOrder.gridLayout}>
                  <FormInput
                    errors={errors}
                    field={orderTypeInputs.ecOrder}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                  />
                </Grid>
                {selectedAccount && (
                  <Grid item {...orderTypeInputs.promotion.gridLayout}>
                    <FormInput
                      errors={errors}
                      field={orderTypeInputs.promotion}
                      control={control}
                      setValue={setValue}
                      getValues={getValues}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={8}>
          {displaySection('ecAppRedirect') && (
            <ECAppRedirect
              handleToggle={handleToggle}
              handleRedirect={() => navigate(STUDENT_PROFILE(bp, id))}
            />
          )}
        </Grid>
      </Grid>
      <CustomDialog
        customClass={css.dialog}
        open={showECAppModal}
        title={appStrings.order.creditApp}
        message={appStrings.order.noAvailableCreditDialog}
        buttons={[
          {
            label: appStrings.common.yes,
            onClick: () => navigate(STUDENT_PROFILE(bp, id)),
          },
          {
            label: appStrings.common.no,
            btnType: buttonTypes.secondary,
            onClick: () => setShowECAppModal(false),
          },
        ]}
      />
    </CustomBox>
  );
}

export default OrderType;
