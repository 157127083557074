import { PUBLIC, STUDENT } from './roles';
import guestRoutes from './Routes/guestRoutes';
import studentRoutes from './Routes/studentRoutes';
import industrialRoutes from './Routes/industrialRoutes';
import { useSelector } from 'react-redux';

const PrivateRoute = role => {
  const { isActive } = useSelector(state => state.login.userDetails);

  const studentIndustrialRoutes =
    role === STUDENT
      ? { routes: studentRoutes(isActive), key: 'studentRoute_' }
      : { routes: industrialRoutes, key: 'industrialRoute_' };

  return role === PUBLIC
    ? { routes: guestRoutes, key: 'guestRoute_' }
    : studentIndustrialRoutes;
};

export default PrivateRoute;
