import React from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import appStrings from '../../../../core/strings/appStrings';
import useShoppingCartStyles from '../shoppingCartStyles';
import { useLocation } from 'react-router-dom';
import { ACCOUNT, CHECKOUT } from '../../../../core/navigation/paths';
import PropTypes from 'prop-types';

function ItemsTitle({ isSavedCart, isOrderList, isOrderSummary, cartLength }) {
  const { pathname } = useLocation();
  const isCartReview = pathname.indexOf(CHECKOUT) !== -1;
  const isAccountPage = pathname.indexOf(ACCOUNT) !== -1;
  const isOrderDetails = pathname.indexOf('/orderDetails') !== -1;
  const isSmallDevice = useMediaQuery('(max-width:768px)');
  const css = useShoppingCartStyles({
    isSmallDevice,
    isCartSummary: false,
  }).classes;

  const getTitle = () => {
    if (isCartReview) {
      return `3. ${appStrings.cart.cartReview}`;
    } else if (isAccountPage && !isOrderDetails) {
      return appStrings.common.mySavedItems;
    } else if (isSavedCart) {
      return appStrings.cart.savedCart;
    } else if (isOrderList) {
      return appStrings.cart.orderList;
    } else if (isOrderSummary) {
      return appStrings.order.orderSummary;
    } else {
      return appStrings.menu.cart;
    }
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        padding={2}
        className={css.title}
        id="ShoppingCart-itemsTitle"
      >
        {isSmallDevice && !isCartReview && !isOrderDetails && (
          <ShoppingCartOutlinedIcon
            fontSize="small"
            data-test-id="ShoppingCartOutlinedIcon"
            sx={{ transform: 'scaleX(-1)', marginLeft: '2%' }}
          />
        )}
        <Grid item>
          <Typography fontWeight="bold" fontSize="medium">
            {getTitle()}
          </Typography>
        </Grid>
        <Grid item marginLeft={1} xs>
          <Typography fontSize="small">
            {cartLength !== undefined
              ? appStrings.common.itemsNumber(cartLength)
              : ''}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

ItemsTitle.prototypes = {
  cartLength: PropTypes.number,
  isSavedCart: PropTypes.bool,
};

export default ItemsTitle;
