import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../modules/shared/CustomButton/CustomButton';
import useProductContentStyles from './productContentStyles';
import appStrings from '../../../core/strings/appStrings';
import { PRODUCT } from '../../../core/navigation/paths';
import CartButton from './Components/CartButton';
import PropTypes from 'prop-types';
import noProductImg from '../../../core/assets/img/noProduct-img.png';
import { useEffect, useState } from 'react';
import { formatProductName } from './productContentService';
import classNames from 'classnames';
import SaveButton from './Components/SaveButton';
import { formatNumberWithCurrency } from '../../../core/services/utilsService';

function ProductContent({
  productNumber,
  image,
  productName,
  listPrice = false,
  studentPrice,
  customClassName,
  id,
}) {
  const css = useProductContentStyles().classes;
  const navigate = useNavigate();
  const productNameFormatted = formatProductName(productName);
  const productDetails = {
    name: productNameFormatted,
    productNumber,
    image,
    listPrice,
    studentPrice,
  };
  const [img, setImage] = useState(image);

  useEffect(() => {
    setImage(image);
  }, [image]);

  const getProductView = () => {
    return (
      <Grid container height="100%">
        <Grid item xs={12}>
          <Grid item container>
            <Grid item xs alignSelf="center">
              <Typography
                id="productContent-SKU"
                data-test-id="productContentSKU-testId"
                textAlign="start"
                sx={{ wordBreak: 'break-word' }}
              >
                {appStrings.common.sku}
                <strong>{productNumber}</strong>
              </Typography>
            </Grid>
            <SaveButton productDetails={productDetails} />
          </Grid>
          <Grid
            item
            xs={12}
            className={css.imgContainer}
            id="productContent-Image"
          >
            <img src={img} alt={''} onError={() => setImage(noProductImg)} />
          </Grid>
          <Typography
            className={css.productTitle}
            data-test-id={`${productNumber}-description`}
            id="productContent-Title"
          >
            {productNameFormatted}
          </Typography>
        </Grid>
        <Grid item container alignItems="flex-end">
          <Grid item container xs={12}>
            <Grid
              item
              container
              direction="column"
              justifyContent="center"
              alignContent="flex-start"
              xs={6}
              sm={6}
              md={6}
              xl={6}
            >
              {studentPrice ? (
                <>
                  <Typography
                    className={css.textOverline}
                    id="productContent-ListPrice"
                  >
                    {`${formatNumberWithCurrency(listPrice)}`}
                  </Typography>
                  <Typography
                    className={css.studentPriceStyle}
                    id="productContent-StudentPrice"
                  >
                    {`${formatNumberWithCurrency(studentPrice)}`}
                  </Typography>
                </>
              ) : (
                listPrice && (
                  <Typography
                    id="productContent-ListPrice"
                    className={css.textBold}
                  >{`${formatNumberWithCurrency(listPrice)}`}</Typography>
                )
              )}
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <Grid
                item
                container
                justifyContent="center"
                className={css.cartButton}
              >
                <CartButton productDetails={productDetails} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      id={id}
      item
      xs={6}
      sm={4}
      md={4}
      xl={2}
      className={classNames(css.contentContainer, customClassName ?? null)}
    >
      <CustomButton
        id="product-content"
        data-test-id={`${productNameFormatted}-product`}
        btnType="secondary"
        label={getProductView()}
        onClick={() => {
          window.scrollTo(0, 0);
          navigate(PRODUCT(productNumber));
        }}
      />
    </Grid>
  );
}

ProductContent.propTypes = {
  productNumber: PropTypes.string,
  image: PropTypes.elementType,
  productName: PropTypes.string,
  listPrice: PropTypes.number,
  studentPrice: PropTypes.number,
  id: PropTypes.string,
};

export default ProductContent;
