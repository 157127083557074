export const sKey =
  (function () {
    var L = Array.prototype.slice.call(arguments),
      N = L.shift();
    return L.reverse()
      .map(function (s, v) {
        return String.fromCharCode(s - N - 1 - v);
      })
      .join('');
  })(49, 139, 139, 137, 132, 120, 133) +
  (32173).toString(36).toLowerCase() +
  (17)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (l) {
      return String.fromCharCode(l.charCodeAt() + -71);
    })
    .join('') +
  (1).toString(36).toLowerCase() +
  (function () {
    var H = Array.prototype.slice.call(arguments),
      i = H.shift();
    return H.reverse()
      .map(function (r, R) {
        return String.fromCharCode(r - i - 32 - R);
      })
      .join('');
  })(44, 133) +
  (0).toString(36).toLowerCase() +
  (function () {
    var E = Array.prototype.slice.call(arguments),
      G = E.shift();
    return E.reverse()
      .map(function (J, R) {
        return String.fromCharCode(J - G - 54 - R);
      })
      .join('');
  })(5, 107);

export const pkjwk = {
  mod:
    'sDiLFH3zEq0JNtTvXEl_X9rEh0lGm5o47HJjHFPbt4pVdnP-NEkdG2CzaHxOQVQr2gY8-y' +
    '624prIttsURDQP5ViGtaW1E4JEXHbqohXugYOTN2faftXurFO60pyYYQlY51zU4ki75tMyDVXxuk9J7lktwd2ZUpvU5HE0rxScLhk',
  exp: 'AQAB',
};
