import { makeStyles } from 'tss-react/mui';

const inlineBlock = 'inline-block';
export const videoSingleImageBannerXStyles = makeStyles()(theme => ({
  VideoSingleImageBannerX: {
    display: inlineBlock,
    position: 'relative',
    width: '100%',
    background: '#000',
    overflow: 'hidden',
    marginBottom: '-1vh',

    'text-white': {
      color: 'white',
    },

    '& .text-left': {
      textAlign: 'left',
    },
    '& .text-center': {
      textAlign: 'center',
    },
    '& .text-right': {
      textAlign: 'right',
    },
    '& .right-container': {
      alignSelf: 'flex-end',
    },
    '& .center-container': {
      alignSelf: 'center',
    },
    '& .left-container': {
      alignSelf: 'flex-start',
    },
  },
  iframeContainer: {
    backgroundColor: 'black',
    position: 'relative',
    paddingBottom: '56.25%',
  },
  iframe: {
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  articleContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0%',
    left: 0,
  },
  article: {
    maxWidth: '50%',
  },
  'a.tag': {
    color: 'white !important',
  },
    // CTA style
  redOnWhite: {
    display: inlineBlock,
    marginTop: '24px',
    background: 'red',
    color: '#fff',
    padding: '1em',
    textTransform: 'uppercase',
    fontWeight: '700',
    fontSize: '1rem',
  },
  blackOnWhite: {
    display: inlineBlock,
    marginTop: '24px',
    background: '#fff',
    border: '2px solid #333',
    color: '#333 !important',
    padding: '1em',
    textTransform: 'uppercase',
    fontWeight: '700',
    fontSize: '1rem',
  },
  sectionWrapper: {
    overflow: 'hidden',
    backgroundSize: 'cover',
    padding: '0 5%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
}));