import appStrings from '../../../../core/strings/appStrings';
import Addresses from '../../../shared/StudentProfileForm/Addresses/Addresses';
import CustomerInformation from '../../../shared/StudentProfileForm/CustomerInformation/CustomerInformation';
import EmployerInformation from '../../../shared/StudentProfileForm/EmployerInformation/EmployerInformation';
import References from '../../../shared/StudentProfileForm/References/References';
import SOCAccountInfo from '../../../shared/StudentProfileForm/SOCAccountInfo/SOCAccountInfo';
import SchoolInformation from '../../../shared/StudentProfileForm/SchoolInformation/SchoolInformation';
import ApplicationFields from './Components/ApplicationFields';
import ContractFields from './Components/ContractFields';

export const editProfileSectionsConstants = ({ sectionErrors }) => [
  {
    index: 0,
    name: appStrings.studentProfile.myInformation,
    component: props => <CustomerInformation {...props} />,
    hasErrors: sectionErrors.customerInfo,
  },
  {
    index: 1,
    name: appStrings.studentProfile.schoolInformation,
    component: props => <SchoolInformation {...props} />,
    hasErrors: sectionErrors.schoolInfo,
  },
  {
    index: 2,
    name: appStrings.studentProfile.addresses,
    component: props => <Addresses {...props} />,
    hasErrors: sectionErrors.addresses,
  },
  {
    index: 3,
    name: appStrings.studentProfile.employerInformation,
    component: props => <EmployerInformation {...props} />,
    hasErrors: sectionErrors.employerInfo,
  },
  {
    index: 4,
    name: appStrings.studentProfile.references,
    component: props => <References {...props} />,
    hasErrors: sectionErrors.references,
  },
  {
    index: 5,
    name: appStrings.studentProfile.creditAccountInfo,
    component: props => <SOCAccountInfo {...props} />,
  },
];

export const applicationContractFields = ({ props }) => [
  {
    index: 'contract',
    name: appStrings.studentProfile.applicationFields,
    component: <ContractFields {...props} />,
  },
  {
    index: 'application',
    name: appStrings.studentProfile.applicationFields,
    component: <ApplicationFields {...props} />,
  },
];
