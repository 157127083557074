import PropTypes from 'prop-types';

export const propTypes = {
  customClasses: PropTypes.object,
  customSummary: PropTypes.node,
  details: PropTypes.node,
  expand: PropTypes.func,
  expanded: PropTypes.string,
  expandByIcon: PropTypes.bool, // used for when you want to expand panel using the expand icon only
  id: PropTypes.string.isRequired,
};
