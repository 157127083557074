import {
  ClearSchoolBpNumber,
  GetStateOptions,
  UpdateEducationManager,
  UpdateSchools,
  UpdateStateAndCityByZipCode,
  UpdateUsesTools,
} from './Hooks/updaters';
import { Grid, Typography } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { UseErrorSnackbars, UseUpdateErrors } from './Hooks/errorHandler';
import {
  displayError,
  displaySuccess,
} from '../../../core/redux/slices/notificationsSlice';
import {
  getRegisterInputs,
  registerSections,
  updateSelectOptions,
} from './registerInputs';
import { useDispatch, useSelector } from 'react-redux';

import CustomAccordion from '../CustomAccordion/CustomAccordion';
import CustomBox from '../CustomBox/CustomBox';
import CustomButton from '../CustomButton/CustomButton';
import FormInput from '../FormInput/FormInput';
import LeaveDialog from '../LeaveDialog/LeaveDialog';
import MD5 from 'crypto-js/md5';
import appStrings from '../../../core/strings/appStrings';
import classNames from 'classnames';
import { dialogBtnActions } from '../LeaveDialog/leaveDialogConstants';
import { getStateCodeByName } from '../../../core/strings/states';
import { passwordHashSalt } from './registerConstants';
import {
  getSelectedSchoolDetails,
  postStudentRegistration,
} from './registerService';
import { useForm } from 'react-hook-form';
import { useRegisterStyles } from './useRegisterStyles';
import { registerStudentInList } from '../../../core/redux/slices/schoolsWithStudentsSlice';
import { updateNewStudentRegistered } from '../../../core/redux/slices/approveRejectSlice';
import { trackGAPageViews } from '../Analytics/analyticsService';
import { useNavigate } from 'react-router-dom';
import { REGISTER_IND_CONFIRMATION } from '../../../core/navigation/paths';
import { setRegisteredStudent } from '../../../core/redux/slices/registerSlice';
import { ADMIN } from '../../../core/navigation/roles';
import { getDateWithoutOffset, getDisplayErrors } from '../../../core/services/utilsService';
import MandatoryFieldsMessage from '../MandatoryFieldsMessage/MandatoryFieldsMessage';
import PasswordValidation from '../PasswordValidation/PasswordValidation';

function Register() {
  const navigate = useNavigate();
  const css = useRegisterStyles().classes;
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { isDirty, errors, touchedFields },
    clearErrors,
    setError,
    getValues,
  } = useForm({ criteriaMode: 'all', mode: 'onTouched' });

  const formFields = watch();
  const loginState = useSelector(state => state.login);
  const userData = loginState.userDetails;
  const isAdmin = userData.role === ADMIN;
  const [schools, setSchools] = useState({});
  const [formWasSubmitted, setFormWasSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const registerInputs = getRegisterInputs;

  trackGAPageViews('/industrial/register');

  registerInputs.accountDetails.fields.find(
    field => field.name === 'passwordConfirmation'
  ).validations.validate = {
    passwordConfirm: value =>
      formFields.password &&
      formFields.passwordConfirmation &&
      value === formFields.password,
  };

  GetStateOptions({
    employeeId: userData.employeeId,
    role: userData.role,
  });
  UpdateStateAndCityByZipCode({
    formFields,
    setValue,
    setError,
    clearErrors,
    updateSelectOptions,
    employeeId: userData.employeeId,
  });
  UpdateSchools({
    formFields,
    updateSelectOptions,
    setSchools,
    employeeId: userData.employeeId,
    role: userData.role,
    setValue,
  });
  UpdateEducationManager({ formFields, schools, setValue, userData });
  UpdateUsesTools({ formFields, setValue });
  ClearSchoolBpNumber({ formFields, updateSelectOptions, setValue, errors });
  UseErrorSnackbars({ errors });
  UseUpdateErrors({ formFields, clearErrors });

  useEffect(() => {
    reset();
    // need component did mount event to reset form after is loaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formWasSubmitted && !isDirty) {
      setSubmitting(false);
      navigate(REGISTER_IND_CONFIRMATION);
    }
  }, [isDirty, formWasSubmitted, navigate]);

  const onSubmit = data => {
    const {
      educationAccountManagerName,
      password,
      passwordConfirmation,
      approveStudent = false,
      graduationDate,
      ...submitData
    } = data;
    const hashedPassword = MD5(password + passwordHashSalt).toString();
    const registrationData = {
      ...submitData,
      approveStudent,
      hashedPassword,
      graduationDate: getDateWithoutOffset(graduationDate),
      ...getSelectedSchoolDetails({
        schoolBpNumber: data.schoolBpNumber,
        schools,
      }),
    };
    registrationData.state = getStateCodeByName(registrationData.state);
    if (isAdmin) {
      const selectedSchool = schools.find(school => school.schoolBpNumber === submitData.schoolBpNumber);
      if (selectedSchool) {
        registrationData.accountManagerId = selectedSchool.accountManagerId;
      }
    }
    setSubmitting(true);

    postStudentRegistration(registrationData)
      .then(resp => {
        registrationData.id = resp;
        delete registrationData.graduationDate;

        dispatch(registerStudentInList(registrationData));

        dispatch(updateNewStudentRegistered(true));

        dispatch(
          displaySuccess({ message: appStrings.register.registerSuccessful })
        );
        dispatch(setRegisteredStudent(registrationData));
        reset(data);
        setFormWasSubmitted(true);
      })
      .catch(e => {
        dispatch(
          displayError({
            message: getDisplayErrors(e),
          })
        );
        setSubmitting(false);
      });
  };

  const dialogData = {
    title: appStrings.modal.register.title,
    description: appStrings.modal.register.description,
    isDirty,
    reset,
    watch,
    buttons: [dialogBtnActions.stay, dialogBtnActions.leave],
    errors,
    handleSubmit,
    dispatch,
  };

  // Enable or disable State according to its value
  if (getValues('state')) {
    registerInputs.userDetails.fields[8].disabled = false;
  } else {
    registerInputs.userDetails.fields[8].disabled = true;
  }

  const submitSection = index => {
    return (
      registerSections.length - 1 === index && (
        <Grid item xs={12} className={css.submitContainer}>
          <CustomButton
            label={appStrings.register.registerStudent}
            data-test-id="register-submit-button"
            id="register-submit-button"
            onClick={handleSubmit(onSubmit)}
            disabled={submitting}
          />
        </Grid>
      )
    );
  };

  const studentIdField = (section, field) => {
    return registerInputs[section].sectionTitle ===
      appStrings.register.schoolInformation &&
      field.label === appStrings.register.studentId &&
      !isAdmin ? (
      <Grid item xs={12} sm={6} md={4} />
    ) : null;
  };

  const notDisplayedForRole = field => {
    return field.roles && !field.roles.includes(userData.role);
  };

  const customFieldDetails = (section, field, errors) => {
    if (
      registerInputs[section].sectionTitle === appStrings.register.password &&
      field.label === appStrings.register.password
    ) {
      return (
        <PasswordValidation
          isTouched={touchedFields.password}
          warnCapsLock
          validateItems={[
            appStrings.validationMessages.passwordLength,
            appStrings.validationMessages.passwordLetter,
            appStrings.validationMessages.passwordNumber,
            appStrings.validationMessages.passwordSpecialCharacter,
          ]}
          errors={Object.values(errors?.password?.types ?? {})}
        />
      );
    } else if (
      registerInputs[section].sectionTitle ===
        appStrings.register.schoolInformation &&
      field.label === appStrings.register.graduationDate
    ) {
      return (
        <Grid item className={css.info}>
          <Typography variant="caption">
            {appStrings.register.graduationDayDefaultInfo}
          </Typography>
        </Grid>
      );
    }
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={css.registerContainer}
      data-test-id="register-section"
    >
      <Typography variant="h2" className={css.title} id="registerPageTitle">
        {appStrings.menu.registerStudent}
      </Typography>
      <form
        name="registration"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
      >
        {registerSections.map((section, index) => (
          <CustomBox
            key={`registration-${section}`}
            customClass={classNames({
              [css.sectionBox]: index < registerSections.length - 1,
            })}
          >
            <CustomAccordion
              identifier={section}
              title={registerInputs[section].sectionTitle}
            >
              <Grid
                id={`content-${section}`}
                container
                spacing={2}
                data-test-id="small-container"
                justifyContent={
                  registerInputs[section].sectionTitle ===
                  appStrings.register.password
                    ? 'center'
                    : 'flex-start'
                }
              >
                {registerInputs[section].fields.map(field => {
                  if (notDisplayedForRole(field)) {
                    return false;
                  }
                  return (
                    <Fragment key={`${section}-${field.name}-container`}>
                      <Grid
                        item
                        {...field.gridLayout}
                        data-test-id={`${section}-${field.name}`}
                      >
                        <FormInput
                          field={field}
                          control={control}
                          setValue={setValue}
                          watch={watch}
                          getValues={getValues}
                          errors={errors}
                        />
                        {customFieldDetails(section, field, errors)}
                      </Grid>

                      {studentIdField(section, field)}
                    </Fragment>
                  );
                })}
                {submitSection(index)}
              </Grid>
            </CustomAccordion>
            <Grid container className={css.mandatoryMessageContainer}>
              <MandatoryFieldsMessage />
            </Grid>
          </CustomBox>
        ))}
      </form>
      <LeaveDialog {...dialogData} />
    </Grid>
  );
}

export default Register;
