import appStrings from '../../../../core/strings/appStrings';

export const columns = [
  {
    label: appStrings.consigned.partNumber,
    name: 'productNumber',
    bold: true,
  },
  {
    label: appStrings.consigned.description,
    name: 'description',
  },
  {
    label: appStrings.consigned.quantity,
    name: 'quantity',
    align: 'center',
  },
  {
    label: appStrings.consigned.listPrice,
    name: 'listPrice',
    align: 'center',
    isCurrency: true,
  },
  {
    label: appStrings.consigned.lastDateReceived,
    name: 'formattedLastDateReceived',
    align: 'center',
  },
  {
    label: appStrings.consigned.itemDiscontinued,
    name: 'itemDiscontinued',
    align: 'center',
    bold: true,
  },
];
