import React from 'react';
import { Grid, Skeleton } from '@mui/material';

function ImageCarouselSkeleton() {
  return (
    <Grid data-test-id="imageCarouselSkeleton" item container justifyContent="center">
      <Grid item xs={10} sm={8} md={8} lg={5}>
        <Skeleton variant="rounded" height={400} />
      </Grid>
    </Grid>
  );
}

export default ImageCarouselSkeleton;