import PropTypes from 'prop-types';
import appStrings from '../../../../core/strings/appStrings';
import { Divider } from '@mui/material';

export const propTypes = {
  students: PropTypes.array.isRequired,
};

export const columns = (approveReject = false) => [
  {
    label: appStrings.searchInputs.studentName,
    name: 'studentName',
    bold: true,
  },
  {
    label: appStrings.searchInputs.email,
    name: 'email',
    colSpan: 2,
  },
  {
    label: appStrings.searchInputs.phoneNumber,
    name: 'phoneNumber',
  },
  {
    label: appStrings.searchInputs.graduationDate,
    name: 'graduationDate',
    isDateType: true,
  },
  {
    label: appStrings.searchInputs.schoolName,
    name: 'schoolName',
    colSpan: 2,
  },
  {
    label: !approveReject
      ? appStrings.searchInputs.bpNumber
      : appStrings.searchInputs.approvalStatus,
    name: !approveReject ? 'bpNumber' : 'formattedStatus',
    align: 'center',
  },
];

export const itemsPerPage = 15;

export const dialogFields = value => [
  {
    iconId: 'approvedSquare',
    name: 'approved',
    text: `${appStrings.approveReject.approved}: ${value?.approved}`,
    divider: <Divider orientation="vertical" variant="middle" flexItem />,
  },
  {
    iconId: 'rejectedSquare',
    name: 'rejected',
    text: `${appStrings.approveReject.rejected}: ${value?.rejected}`,
    divider: <Divider orientation="vertical" variant="middle" flexItem />,
  },
  {
    iconId: 'pendingSquare',
    name: 'pending',
    text: `${appStrings.approveReject.pending}: ${value?.pending}`,
  },
];
