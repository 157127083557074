import { Grid, Tooltip, Typography } from '@mui/material';

import CustomBox from '../../../shared/CustomBox/CustomBox';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import appStrings from '../../../../core/strings/appStrings';
import useConsignedInventoryDetailsStyle from '../ConsignedInventoryDetails/useConsignedInventoryDetailsStyles';
import { useSelector } from 'react-redux';

function SelectedConsignedItems({ addToOrder }) {
  const css = useConsignedInventoryDetailsStyle().classes;
  const { selectedItemsCounter } = useSelector(
    state => state.consignedInventory
  );

  const selectedItemsLabel = `${selectedItemsCounter} ${appStrings.common.itemsSelected}`;

  return (
    <Grid
      container
      className={css.marginTop2}
      id="consignedSelectedItemsContainer"
    >
      <Grid item xs={12}>
        <CustomBox customClass={css.greyContainer}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid
                container
                direction="column"
                spacing={1}
                id="consignedSelectedItemsText"
              >
                <Grid item>
                  <Typography className={css.bold}>
                    {selectedItemsLabel}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {appStrings.consigned.actionAddItemsToOrder}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Tooltip
                disableHoverListener={selectedItemsCounter > 0}
                title={appStrings.consigned.selectProduct}
                classes={{ tooltip: css.htmlTooltip }}
                enterTouchDelay={0}
              >
                {/*span is necessary for tooltip to work on disabled buttons*/}
                <span>
                  <CustomButton
                    label={appStrings.order.addToOrder}
                    onClick={addToOrder}
                    disabled={selectedItemsCounter === 0}
                    id="consignedAddToOrderBtn"
                  />
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </CustomBox>
      </Grid>
    </Grid>
  );
}

export default SelectedConsignedItems;
