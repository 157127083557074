import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import appStrings from '../../../../core/strings/appStrings';
import { cartTotalsPrices } from '../shoppingCartConstants';
import PropTypes from 'prop-types';

function CartTotalsTabletDesktop({
  totalsButtons,
  isLoggedIn,
  isSmallDevice,
  totals,
  css,
  isCheckOut,
}) {
  return (
    <Grid
      container
      alignItems="center"
      id="ShoppingCart-cartTotalsTabletDesktop"
    >
      <Grid item container xs={12} spacing={1} sm={isSmallDevice && 5}>
        <Grid item container xs={12} data-test-id="subtotalCart">
          <Grid item id="subtotalCart-label">
            <Typography>{appStrings.cart.subtotal}</Typography>
          </Grid>
          <Grid item id="subtotalCart-value">
            <Typography data-test-id="subtotalCart-value">
              {totals.subtotal}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          spacing={1}
          xs={12}
          data-test-id={`cartTotalsSectionOne-${isLoggedIn}`}
        >
          {isLoggedIn &&
            cartTotalsPrices.sectionOne.map((elem, index) => (
              <Grid item container key={index}>
                <Grid item xs id={`${elem.name}-label`}>
                  <Typography>{elem.label}</Typography>
                </Grid>
                <Grid item id={`${elem.name}-value`} className={elem.valueClass ?? ''}>
                  <Typography>{totals[elem.name]}</Typography>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
      {!isCheckOut ? (
        <Grid item className={css.cartTotalsDivider}>
          <Divider orientation={isSmallDevice ? 'vertical' : 'horizontal'} />
        </Grid>
      ) : (
        <Grid item width="100%" paddingY={1}>
          <Divider orientation={'horizontal'} />
        </Grid>
      )}
      <Grid
        item
        container
        xs={12}
        spacing={1}
        sm={isSmallDevice && 5}
        id="sectionTwo"
        data-test-id={`cartTotalsSectionTwo-${isLoggedIn}`}
      >
        <Grid item xs={12}>
          <Grid item container spacing={1}>
            {isLoggedIn &&
              cartTotalsPrices.sectionTwo.map((elem, index) => (
                <Grid item container key={index}>
                  <Grid item id={`${elem.name}-label`}>
                    <Typography>{elem.label}</Typography>
                  </Grid>
                  <Grid item id={`${elem.name}-value`}>
                    <Typography>{totals[elem.name]}</Typography>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {totalsButtons()}
        </Grid>
      </Grid>
    </Grid>
  );
}

CartTotalsTabletDesktop.propTypes = {
  totalsButtons: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  isSmallDevice: PropTypes.bool,
  totals: PropTypes.object,
  css: PropTypes.object,
  isCheckOut: PropTypes.bool,
};

export default CartTotalsTabletDesktop;
