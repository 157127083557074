import {
  addItem,
  setAddItemLoading,
  setCartId,
  setReloadCart,
  setTotals,
} from '../../../core/redux/slices/cartSlice';
import {
  addToCart,
  addToOrder,
  getAddingProductWarehouse,
  getProductDetails,
  handleAddProductQuantityChange,
  handleTrackGAEvent,
} from './addProductService';
import {
  formatNumberWithCurrency,
  formatNumberWithPercentage,
} from '../../../core/services/utilsService';
import { useLocation, useParams } from 'react-router-dom';
import { useRef, useState } from 'react';

import AccordionTemplate from '../../shared/AccordionTemplate/AccordionTemplate';
import AddProductSkeleton from './AddProductSkeleton';
import AddProductSummary from './AddProductSummary';
import CustomBox from '../../shared/CustomBox/CustomBox';
import ProductDetails from './ProductDetails';
import addProductStyles from './useAddProductStyles';
import { addProductTab } from './addProductConstants';
import appStrings from '../../../core/strings/appStrings';
import { cloneDeep } from 'lodash';
import { displayError } from '../../../core/redux/slices/notificationsSlice';
import { getPurchasePower } from '../PurchasePower/purchasePowerService';
import { getWarehouseDetails } from '../ConsignedInventory/consignedInventoryService';
import { purchaseTypes } from '../PurchasePower/purchasePowerConstants';
import { updateTotals } from '../Cart/cartService';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

function AddProduct({ setAddingProduct }) {
  const { pathname } = useLocation();
  const isSavedItems = pathname.indexOf('saved') !== -1;
  const isOrder = pathname.indexOf('order') !== -1;
  const { id, bp } = useParams();
  const css = addProductStyles().classes;
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
    watch,
  } = useForm();
  const [expanded, setExpanded] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialChange, setInitialChange] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [itemDetails, setItemDetails] = useState(null);
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const dispatch = useDispatch();
  const productNumberRef = useRef();
  const quantityRef = useRef();
  const studentPriceRef = useRef();
  const { cartInfo } = useSelector(state => state.cart);

  const formatDetails = details => {
    details.listPrice = formatNumberWithCurrency(details.listPrice);
    details.discountPercentage = formatNumberWithPercentage(
      details.discountPercentage
    );
    details.discountAmount = formatNumberWithCurrency(details.discountAmount);
    details.studentPrice = formatNumberWithCurrency(details.studentPrice);
    details.weight = `${details.weight} ${appStrings.common.weightUnit}`;
    details.directDelivery = details.directDelivery
      ? appStrings.common.yes
      : appStrings.common.no;
    return details;
  };

  const getItemDetails = (productNumber, quantity) => {
    let qty = quantity;
    if (!qty) {
      qty = 1;
      setValue('quantity', 1);
    }
    return getProductDetails(productNumber, bp, qty).then(details => {
      if (details) {
        setItemDetails(formatDetails(details));
      } else {
        setItemDetails({});
      }
    });
  };

  const getDetails = () => {
    const productNumber = getValues('productNumber');
    const quantity = getValues('quantity');
    if (productNumber) {
      setLoading(true);
      return Promise.all([
        getItemDetails(productNumber, quantity),
        isOrder
          ? getWarehouseDetails(
              productNumber,
              setWarehouseOptions,
              true,
              isOrder
            )
          : Promise.resolve(),
      ])
        .catch(() => {
          dispatch(displayError());
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setItemDetails(null);
      return Promise.resolve();
    }
  };

  const expand = tabId => {
    if (tabId === addProductTab) {
      getDetails().then(() => {
        setExpanded(tabId);
      });
    } else {
      setExpanded(tabId);
    }
  };

  const searchItem = () => {
    if (expanded !== addProductTab) {
      getDetails().then(() => {
        setExpanded(addProductTab);
      });
    }
  };

  const onProductNumberChange = resetInitial => {
    const currentQty = getValues('quantity');
    const currentProductNumber = getValues('productNumber');
    if (currentProductNumber && !currentQty) {
      setValue('quantity', 1);
      setInitialChange(false);
    } else if (!currentProductNumber && currentQty) {
      setValue('quantity', '');
    }
    setButtonDisabled((!currentQty || !currentProductNumber) && !initialChange);
    setExpanded(null);
    setValue('lnLineNotes', null);
    setValue('studentNotes', null);
    setValue('warehouse', warehouseOptions[0]?.value);
    setValue('serialNumber', null);
    setValue('serialNumberValue', null);
    if (typeof resetInitial === 'boolean' && resetInitial) {
      setInitialChange(true);
    }
  };

  const onQuantityChange = () => {
    const currentQty = getValues('quantity');
    const currentProductNumber = getValues('productNumber');
    setButtonDisabled(!currentQty || !currentProductNumber);
    if (
      itemDetails?.listPrice &&
      currentQty &&
      currentQty > 0 &&
      isOrder &&
      expanded
    ) {
      handleAddProductQuantityChange(
        currentProductNumber,
        bp,
        currentQty,
        itemDetails.listPrice,
        setItemDetails
      );
    }
  };

  const refreshTotals = () => {
    updateTotals()
      .then(totals => {
        dispatch(setTotals(totals));
        if (totals.taxErrorMessage) {
          dispatch(displayError({ message: totals.taxErrorMessage }));
        }
        if (!isOrder && totals.cartId) {
          dispatch(setCartId(totals.cartId));
        }
      })
      .catch(() => dispatch(displayError()));
  };

  const reloadCartDetails = () => {
    if (!cartInfo.cartDetails?.cartItems?.length) {
      dispatch(setReloadCart(true));
    }
  };

  const onSubmit = data => {
    if (!buttonDisabled) {
      setLoading(true);
      dispatch(setAddItemLoading(true));
      setAddingProduct({
        ...data,
        warehouse: getAddingProductWarehouse(isOrder, data),
      });
      const productNumberInput = productNumberRef.current;
      productNumberInput.blur();
      quantityRef.current.blur();
      if (isOrder) {
        studentPriceRef.current.blur();
      }
      const submitAction = isOrder
        ? addToOrder(id, data)
        : addToCart(id, data, isSavedItems);
      submitAction
        .then(item => {
          if (item) {
            dispatch(addItem(item));
            reset({
              productNumber: '',
              quantity: '',
              studentPrice: '',
              lnLineNotes: '',
              studentNotes: '',
            });
            setInitialChange(true);
            setExpanded(null);
            if (isSavedItems) {
              getPurchasePower(id, purchaseTypes.saved);
              reloadCartDetails();
            } else {
              refreshTotals();
            }
            handleTrackGAEvent(isOrder, item.productNumber);
          } else {
            setItemDetails({});
            setExpanded(addProductTab);
          }
        })
        .catch(() => {
          dispatch(displayError());
        })
        .finally(() => {
          dispatch(setAddItemLoading(false));
          setLoading(false);
          setTimeout(() => {
            productNumberInput.focus();
            setAddingProduct(null);
          });
        });
    }
  };

  const removeNotification = index => {
    const newNotifications = cloneDeep(itemDetails.itemNotifications);
    newNotifications.splice(index, 1);
    setItemDetails({ ...itemDetails, itemNotifications: newNotifications });
  };

  const getStyle = () => (loading ? css.hideContainer : null);

  return (
    <>
      {loading && <AddProductSkeleton expanded={expanded} />}
      <CustomBox customClass={getStyle()}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          autoComplete="off"
          className={css.form}
        >
          <AccordionTemplate
            key={addProductTab}
            id={addProductTab}
            customClasses={{ accordion: { root: css.accordionContainer } }}
            customSummary={
              <AddProductSummary
                handleSubmit={handleSubmit}
                control={control}
                errors={errors}
                onSubmit={onSubmit}
                buttonDisabled={buttonDisabled}
                searchItem={searchItem}
                onProductNumberChange={onProductNumberChange}
                onQuantityChange={onQuantityChange}
                productNumberRef={productNumberRef}
                quantityRef={quantityRef}
                studentPriceRef={studentPriceRef}
                watch={watch}
                setValue={setValue}
              ></AddProductSummary>
            }
            expand={expand}
            expanded={expanded}
            expandByIcon
            details={
              <ProductDetails
                control={control}
                setValue={setValue}
                getValues={getValues}
                itemDetails={itemDetails}
                setItemDetails={setItemDetails}
                onProductNumberChange={onProductNumberChange}
                getProductDetails={getDetails}
                removeNotification={removeNotification}
                warehouseOptions={warehouseOptions}
                id="addProduct"
              />
            }
          />
        </form>
      </CustomBox>
    </>
  );
}

export default AddProduct;
