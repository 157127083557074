import './index.css';

import * as serviceWorker from './serviceWorker';

import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import React from 'react';
import { createRoot } from 'react-dom/client';
import store from './app/core/redux/store';
import GTMPageView from './app/modules/shared/GTMPageView';

try {
  const root = createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <GTMPageView>
          <Provider store={store}>
            <App />
          </Provider>
        </GTMPageView>
      </BrowserRouter>
    </React.StrictMode>
  );
} catch (error) {
  console.log(error);
  debugger;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
