import { makeStyles } from 'tss-react/mui';

const useECContractSigningStyles = makeStyles()(theme => ({
  mainContainer: {
    background: theme.palette.common.neutral2,
    minHeight: '100vh',
  },
  logo: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  title: {
    paddingLeft: theme.spacing(6),
  },
  header: {
    padding: theme.spacing(2, 3),
    backgroundColor: theme.palette.common.neutral1,
    color: theme.palette.text.primary,
    borderBottom: `1px solid ${theme.palette.common.lightGrey}`,
    boxShadow: 'none',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
    zIndex: '9',

    '& .MuiButtonBase-root': {
      padding: 0,
    },
  },
  container: {
    marginTop: theme.spacing(3),
  },
  detailsContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3, 0, 3, 3),
  },
  customBox: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  sectionTitle: {
    paddingBottom: theme.spacing(3),
  },
  sectionItem: {
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  checkIcon: {
    fontSize: '60px',
    color: theme.palette.common.secondary,
  },
  orderSubmitted: {
    padding: theme.spacing(4),
  },
}));

export default useECContractSigningStyles;
