import {
  addressPattern,
  digitPattern,
  nameStringPattern,
} from '../../../../core/strings/regexValidations';
import {
  getZipCodeInfo,
  isValidZipCode,
} from '../../../../core/services/zipCodeService';

import appStrings from '../../../../core/strings/appStrings';
import { phoneMask } from '../../../../core/strings/appConstants';
import { referenceRelationships } from '../../../../core/strings/options';
import store from '../../../../core/redux/store';

const gridLayout = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 4,
};

export const studentGridLayout = {
  xs: 12,
  sm: 6,
  md: 6,
  lg: 4,
};

const onZipCodeChange = async ({
  index,
  getValues,
  setValue,
  setDisabledStates,
}) => {
  const newZipCode = getValues(`references.${index}.zipCode`);
  const currentState = getValues(`references.${index}.state`);
  const currentCity = getValues(`references.${index}.city`);

  const hasData = currentState || currentCity;
  if (hasData) {
    setValue(`references.${index}.city`, null);
    setValue(`references.${index}.state`, null);
  }

  if (newZipCode && newZipCode.length === 5) {
    const suggestions = await getZipCodeInfo(newZipCode);
    if (suggestions) {
      setValue(`references.${index}.state`, suggestions?.state);
    }

    // Enable State field
    setDisabledStates(prevStates => ({
      ...prevStates,
      ...{ [`references.${index}`]: !suggestions },
    }));
  } else {
    // Disable State field
    setDisabledStates(prevStates => ({
      ...prevStates,
      ...{ [`references.${index}`]: true },
    }));
  }
};

export const generateReference = ({
  index,
  getValues,
  setValue,
  disabledStates,
  setDisabledStates,
  isStudent,
}) => {
  const grid = isStudent ? studentGridLayout : gridLayout;
  const getCities = () => {
    const zipCodes = store.getState().zipCode.formattedCodeList;
    const code = getValues(`references.${index}.zipCode`);
    const options = zipCodes?.[code]?.cities || [];
    if (options.length === 1) {
      // use setTimeout to allow References component to render before City value is updated (prevent unit tests error)
      setTimeout(() => {
        setValue(`references.${index}.city`, options[0].label);
      });
    }
    return options;
  };

  const zipCodeChangeCallback = () => {
    onZipCodeChange({
      index,
      getValues,
      setValue,
      setDisabledStates,
    });
  };

  return [
    {
      name: `references.${index}.id`,
      type: 'hidden',
    },
    {
      name: `references.${index}.firstName`,
      label: appStrings.common.firstName,
      type: 'text',
      validations: {
        maxLength: 50,
        pattern: nameStringPattern,
      },
      gridLayout: grid,
      helperText: appStrings.validationMessages.nameString,
    },
    {
      name: `references.${index}.lastName`,
      label: appStrings.common.lastName,
      type: 'text',
      validations: {
        maxLength: 50,
        pattern: nameStringPattern,
      },
      gridLayout: grid,
      helperText: appStrings.validationMessages.nameString,
    },
    {
      name: `references.${index}.addressId`,
      type: 'hidden',
    },
    {
      name: `references.${index}.addressLine1`,
      label: appStrings.studentProfile.referenceAddress1,
      type: 'text',
      validations: {
        maxLength: 50,
        pattern: addressPattern,
      },
      gridLayout: grid,
      helperText: appStrings.validationMessages.address,
    },
    {
      name: `references.${index}.addressLine2`,
      label: appStrings.studentProfile.referenceAddress2,
      type: 'text',
      validations: {
        maxLength: 50,
        pattern: addressPattern,
      },
      gridLayout: grid,
      helperText: appStrings.validationMessages.address,
    },
    {
      name: `references.${index}.zipCode`,
      label: appStrings.studentProfile.referenceZipCode,
      type: 'text',
      validations: {
        maxLength: 5,
        minLength: {
          value: 5,
          message: appStrings.validationMessages.xDigitsOnly(5),
        },
        pattern: {
          value: digitPattern,
          message: appStrings.validationMessages.xDigitsOnly(5),
        },
        validate: {
          invalidZipCode: value => isValidZipCode(value),
        },
      },
      onChangeCallback: zipCodeChangeCallback,
      gridLayout: grid,
    },
    {
      name: `references.${index}.city`,
      label: appStrings.studentProfile.referenceCity,
      type: 'select',
      options: getCities(),
      gridLayout: grid,
    },
    {
      name: `references.${index}.state`,
      label: appStrings.studentProfile.referenceState,
      type: 'text',
      gridLayout: grid,
      readOnly: true,
      disabled:
        disabledStates[`references.${index}`] ??
        !getValues(`references.${index}.state`),
    },
    {
      name: `references.${index}.phoneNumber`,
      label: appStrings.studentProfile.referencePhoneNumber,
      type: 'tel',
      gridLayout: grid,
      validations: {
        minLength: 10,
        maxLength: 10,
      },
      helperText: appStrings.validationMessages.xDigitsOnly(10),
      maskFormat: phoneMask,
    },
    {
      name: `references.${index}.relationship`,
      label: appStrings.studentProfile.referenceRelationship,
      type: 'select',
      options: referenceRelationships,
      gridLayout: grid,
    },
  ];
};
