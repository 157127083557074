import React from 'react';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import { Grid } from '@mui/material';
import ItemsContentSkeleton from './ItemsContentSkeleton';

function CartItemsSkeleton() {
  return (
    <CustomBox
      fadeBorder
      id="cartItemsSkeleton"
      data-test-id="cartItemsSkeleton-testId"
    >
      <Grid container justifyContent="center">
        <Grid
          item
          xs={12}
          sx={{
            '& div:last-of-type': {
              '& .MuiGrid-root': {
                border: 0,
              },
            },
          }}
        >
          {Array(3)
            .fill(true)
            .map((item, index) => (
              <div key={`item-${index}`}>
                <ItemsContentSkeleton />
              </div>
            ))}
        </Grid>
      </Grid>
    </CustomBox>
  );
}

export default CartItemsSkeleton;
