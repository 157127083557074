import { makeStyles } from 'tss-react/mui';

const useApproveRejectStyles = makeStyles()(theme => ({
  topContainer: {
    marginBottom: theme.spacing(3),
  },
  studentsContainer: {
    '& .MuiAccordionDetails-root': {
      padding: 0,
    },
    '& .MuiAccordion-root': {
      background: theme.palette.common.neutral1,
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      background: theme.palette.common.neutral5,
    },
  },
  greyText: {
    color: theme.palette.common.darkGrey,
  },
  disabledCounter: {
    color: theme.palette.common.veryLightGrey,
  },
  customBox: {
    margin: `${theme.spacing(16)} ${theme.spacing(10)} 0`,
    background: theme.palette.common.neutral4,
    padding: `${theme.spacing(6)} ${theme.spacing(12)} ${theme.spacing(8)}`,
    textAlign: 'center',
    '& h2': {
      margin: `${theme.spacing(6)} 0`,
    },
    '& .MuiAutocomplete-root': {
      backgroundColor: 'white',
    },
  },
  noFilterContainer: {
    margin: theme.spacing(10),
    '& span': {
      fontSize: theme.spacing(10),
      color: theme.palette.common.primary,
    },
    '& p': {
      paddingTop: theme.spacing(5),
      fontSize: theme.spacing(2),
      fontWeight: 'bold',
    },
  },
}));

export default useApproveRejectStyles;
