import urls from '../../../core/strings/urls';
import ApiService from '../../shared/Api/apiService';
import { sanitizeString } from '../ProductContent/productContentService';

export const getProductDetails = productNumber => {
  return ApiService.get(urls.getProductDetails(productNumber));
};

export const shouldDisplayAccordion = (productDetails, dataProps) => 
  dataProps.some(prop => {
    if (Array.isArray(productDetails[prop])) {
      return Boolean(productDetails[prop]?.length);
    }
    return Boolean(productDetails[prop]?.length) || typeof productDetails[prop] === 'object'
  });

export const getProductSets = productNumber => {
  return ApiService.get(urls.getProductSets(productNumber));
};

export const getProductSetMembers = productNumber => {
  return ApiService.get(urls.getProductSetMembers(productNumber));
};

export const getProductAvailability = productNumber => {
  return ApiService.get(urls.getProductPageAvailability(productNumber));
};

export const processProductSetItems = (setItems = []) => {
  setItems.forEach(item => {
    if (item.description) {
      item.description = sanitizeString(item.description);
    }
  });

  return setItems;
};
