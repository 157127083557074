import { Grid } from '@mui/material';
import React from 'react';
import classNames from 'classnames';
import useCategoriesMenuStyles from './categoriesMenuStyles';
import { NavLink, useLocation } from 'react-router-dom';
import { CATEGORY_ID } from '../../../../core/navigation/paths';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import { snaponCatalogLink } from './categoriesMenuConstants';
import appStrings from '../../../../core/strings/appStrings';
import { grey } from '@mui/material/colors';
import { useSelector } from 'react-redux';
import { menuCategoriesList } from './categoriesMenuService';

function HamburgerCategories() {
  const css = useCategoriesMenuStyles().classes;
  const { pathname } = useLocation();
  const categories = useSelector(
    state => state.categories.categoriesData?.subCategories
  );
  const isActivePath =
    pathname.indexOf('category/') !== -1 || pathname.indexOf('product/') !== -1;
  const listOfPaths = useSelector(state => state.categories.listOfPaths);
  const categoriesList = menuCategoriesList.slice(1);

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={1}
        className={css.hamburgerContainer}
        id="hamburger-categories-menu"
        data-test-id="hamburger-categories-menu-testId"
      >
        {categories?.map((elem, index) => (
          <Grid key={index} item xs={12}>
            <NavLink
              id={elem.name}
              to={CATEGORY_ID(elem.id)}
              data-test-id={`${elem.name}-testId`}
            >
              <Grid item container alignItems="center">
                <img src={elem.image} alt={elem.name} />
                <p>{elem.name}</p>
              </Grid>
            </NavLink>
          </Grid>
        ))}
        <Grid item xs={12}>
          <hr color={grey[200]} />
        </Grid>
        {categoriesList?.map((category, index) => (
          <Grid item container xs={12} key={index} className={css.hamburgerCategory}>
            <NavLink
              to={category.path}
              id={category.name}
              className={
                ({ isActive }) =>
                  classNames(
                    'category-link',
                    isActive ||
                    Boolean(
                      listOfPaths?.find(path => path.id === category.id) &&
                        isActivePath
                    ) ? css.hamburgerCategoryActive : null
                  )
              }
            >
              <Grid item>
                {category.name}
              </Grid>
            </NavLink>
          </Grid>
        ))}
        <Grid item xs={12} sm={5} className={css.sepCatalog}>
          <NavLink
            to={snaponCatalogLink}
            target="_blank"
            id={'SepCatalogButton'}
            data-test-id={'SepCatalog'}
          >
            <CustomButton
              btnType="secondary"
              label={appStrings.menu.sepCatalog}
            ></CustomButton>
          </NavLink>
        </Grid>
      </Grid>
    </>
  );
}

export default HamburgerCategories;
