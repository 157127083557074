import { makeStyles } from 'tss-react/mui';

const unset = 'unset !important';

const useFooterStyles = makeStyles()(theme => ({
  footer: {
    textAlign: 'start',
    padding: theme.spacing(4),
    backgroundColor: theme.palette.common.white,
    borderTop: 'solid',
    borderColor: theme.palette.grey['100'],

    '& .MuiTypography-root': {
      margin: theme.spacing(1),
    },

    '& a': {
      fontSize: theme.spacing(2),
      textDecoration: 'none',
      fontWeight: 'bold',
      color: theme.palette.grey['900'],
    },

    '& .MuiSvgIcon-root': {
      fontSize: theme.spacing(5),
    },

    '& .cookies-settings': {
      color: `${theme.palette.common.black} !important`,
      border: unset,
      cursor: 'pointer',
      alignSelf: 'center',
      marginLeft: 'auto',
      fontSize: unset,
      padding: unset,

      '&:hover': {
        backgroundColor: unset,
      },
    },
  },
}));

export default useFooterStyles;
