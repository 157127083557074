import React from 'react';
import appStrings from '../../../core/strings/appStrings';
import { Typography } from '@mui/material';
import { useMandatoryFieldsStyles } from './useMandatoryFieldsStyles';

function MandatoryFieldsMessage() {
  const css = useMandatoryFieldsStyles().classes;

  return (
    <Typography variant="body2">
      <span className={css.redInfo}>* </span>{appStrings.common.mandatoryFields}
    </Typography>
  );
}

export default MandatoryFieldsMessage;