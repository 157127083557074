import React, { useState, useEffect } from 'react';
import ProductContentSkeleton from '../ProductContent/ProductContentSkeleton';
import { useLocation } from 'react-router';
import { Grid, List, ListItem, Skeleton, Typography } from '@mui/material';
import CustomPagination from '../../shared/CustomPagination/CustomPagination';
import ProductContent from '../ProductContent/ProductContent';
import { useDispatch } from 'react-redux';
import { displayError } from '../../../core/redux/slices/notificationsSlice';
import {
  getBrGlobalRecommendationProducts,
  getSearchedProduct,
  parseBrRecommendations,
} from './productSearchResultsService';
import appStrings from '../../../core/strings/appStrings';
import BrSearchFacet from '../../shared/BrSearchFacet/BrSearchFacet';
import { v4 as uuidv4 } from 'uuid';
import useBrSearchFacetStyles from '../../shared/BrSearchFacet/useBrSearchFacetStyles';
import { trackGTMEvent } from '../../shared/Analytics/analyticsService';
import MobileBrSearchFacet from '../../shared/BrSearchFacet/MobileBrSearchFacet';
import CustomButton from '../../shared/CustomButton/CustomButton';
import { buttonTypes } from '../../../core/strings/appConstants';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import BrRecommendationsWidget from '../../shared/BrRecommendationsWidget/BrRecommendationsWidget';
function ProductSearchResults() {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [searchedValue, setSearchedValue] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paginationDetails, setPaginationDetails] = useState({
    from: 0,
    to: 12,
    page: 1,
    itemsPerPage: 12,
  });
  const [products, setProducts] = useState([]);
  const [resultsLength, setResultsLength] = useState(null);
  const [selectedFacets, setSelectedFacets] = useState(null);
  const [facets, setFacets] = useState([]);
  const [facetsFromServer, setFacetsFromServer] = useState(null);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
  const [isFacetModalOpen, setFacetModalOpen] = useState(false);
  const [globalRecommendations, setGlobalRecommendations] = useState([]);

  const css = useBrSearchFacetStyles().classes;

  const updateMenuOnResize = () => {
    setIsDesktop(window.innerWidth > 1024);
  };
  useEffect(() => {
    window.addEventListener('resize', updateMenuOnResize);
    return () => window.removeEventListener('resize', updateMenuOnResize);
  }, []);

  const handlePageChange = ({ from, to, page, itemsPerPage }) => {
    if (page !== paginationDetails.page) {
      setPaginationDetails({ from, to, page, itemsPerPage });
    }
  };

  useEffect(() => {
    if (state.value !== searchedValue) {
      setSearchedValue(state.value);
      setPaginationDetails(prev => ({ ...prev, page: 1 }));
      setSelectedFacets(null);
    }
  }, [searchedValue, state.value]);

  const handleSearch = () => {
    if (searchedValue) {
      setLoading(true);
      getSearchedProduct(
        searchedValue,
        paginationDetails.itemsPerPage,
        paginationDetails.page,
        facets
      )
        .then(resp => {
          trackGTMEvent('pageview', {
            search_term: searchedValue,
            page_type: 'search',
            orig_ref_url: document.location.href,
          });

          setProducts(resp.value.products || []);
          setResultsLength(resp.value?.rowsNumber);
          setFacetsFromServer(resp.value.facets);
          const productNumbers = resp.value.products
            .map(pr => pr.modelNumber)
            .join();
          getBrGlobalRecommendationProducts(productNumbers).then(res =>
            setGlobalRecommendations(parseBrRecommendations(res))
          );
        })
        .catch(() => dispatch(displayError()))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    paginationDetails.itemsPerPage,
    paginationDetails.page,
    searchedValue,
  ]);

  const title = () =>
    loading ? (
      <Grid item xs={8}>
        <Skeleton variant="text" />
      </Grid>
    ) : products.length ? (
      <Grid item xs={12} data-test-id="productSearchResults-title">
        <Typography
          fontWeight="bold"
          fontSize="large"
          id="productSearchResults-title"
        >
          {appStrings.searchInputs.yourProductSearchResults(
            searchedValue,
            resultsLength
          )}
        </Typography>
      </Grid>
    ) : null;

  const noResults = () => (
    <Grid
      item
      container
      spacing={3}
      id="productSearchResults-noResults"
      data-test-id="productSearchResults-noResultsTestId"
    >
      <Grid item xs={12}>
        <Typography fontWeight="bold" fontSize="large">
          {appStrings.searchInputs.getNoSearchProductsResultMessage(
            searchedValue || ''
          )}
        </Typography>
      </Grid>
      <Grid item>
        <Typography fontWeight="bold" fontSize="medium">
          {appStrings.searchInputs.trySearchTips}
        </Typography>
        <List sx={{ listStyleType: 'disc', pl: 4 }}>
          {appStrings.searchInputs.searchTipsProducts.map((tip, index) => (
            <ListItem sx={{ display: 'list-item' }} key={index}>
              {tip}
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );

  const clearAllFilters = () => {
    setSelectedFacets(null);
    setFacets([]);
  };

  const clearAllFiltersElement = () => {
    if (selectedFacets && selectedFacets.length) {
      return (
        <div id="clearAllFilters" className="clearAllFilters-container">
          <CustomButton
            btnType={buttonTypes.tertiary}
            label={appStrings.searchInputs.deleteAllFilters}
            onClick={clearAllFilters}
            customClass={css.deleteAllFiltersBtn}
          />
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    setPaginationDetails(prev => ({ ...prev, page: 1 }));
    if (isDesktop || (!isFacetModalOpen && !selectedFacets)) {
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFacets]);

  const handleSelectedOptions = (filterName, values) => {
    const item = { name: filterName, properties: values };
    const existingOption = facets.find(elem => elem.name === filterName);
    if (existingOption) {
      const index = facets.indexOf(existingOption);
      setFacets(facets.splice(index, 1));
    }
    const facetsCopy = [...facets, item];
    setFacets(facetsCopy);
    setSelectedFacets(facetsCopy);
  };

  const handleUnselectedOptions = (filterName, values) => {
    const item = { name: filterName, properties: values };
    const facetsCopy = [...facets];
    facetsCopy.forEach((facet, index) => {
      if (JSON.stringify(facet) === JSON.stringify(item)) {
        facetsCopy.splice(index, 1);
      }
    });
    setFacets(facetsCopy);
    setSelectedFacets(facetsCopy);
  };

  return (
    <Grid
      container
      direction={'row'}
      className={css.productSearchResultsContainer}
    >
      {!isDesktop && !loading && resultsLength ? (
        <Grid xs={12} sm={4} className="facets-button-container">
          <CustomButton
            id="mobileFilters-button"
            onClick={() => setFacetModalOpen(!isFacetModalOpen)}
            label={appStrings.common.applyFilters}
            btnType={buttonTypes.secondary}
            startIcon={<FilterAltOutlinedIcon />}
            customClass={css.applyFiltersBtn}
          />
        </Grid>
      ) : null}
      <div className={css.facetsComponentContainer}>
        {clearAllFiltersElement()}
        {Boolean(isDesktop && facetsFromServer?.length) && (
          <h3
            id="filtersLabel"
            className={css.filtersLabel}
          >{`${appStrings.common.filters}:`}</h3>
        )}
        {isDesktop &&
          facetsFromServer?.map(facet => (
            <BrSearchFacet
              key={uuidv4()}
              filterName={facet.name}
              filterOptions={facet.properties}
              onSelectedOptions={handleSelectedOptions}
              onUnselectedOptions={handleUnselectedOptions}
              selectedFacets={selectedFacets}
            />
          ))}
        {!isDesktop && isFacetModalOpen && (
          <MobileBrSearchFacet
            clearAllFiltersElement={clearAllFiltersElement}
            key={uuidv4()}
            onSelectedOptions={handleSelectedOptions}
            onUnselectedOptions={handleUnselectedOptions}
            selectedFacets={selectedFacets}
            onClose={() => setFacetModalOpen(false)}
            facets={facetsFromServer}
            openState={isFacetModalOpen}
            searchProducts={handleSearch}
          />
        )}
      </div>
      <Grid
        id="searchResultsContainer"
        container
        spacing={2}
        direction={'row'}
        className={css.resultsContainer}
      >
        {title()}
        {loading ? (
          <ProductContentSkeleton />
        ) : products.length ? (
          products.map((product, index) => (
            <ProductContent
              id={`product_${index}`}
              key={index}
              productNumber={product.modelNumber}
              image={product.imageUrl}
              productName={product.name}
              listPrice={product.listPrice}
              studentPrice={product.netPrice}
            />
          ))
        ) : (
          noResults()
        )}

        <Grid item xs={12}>
          {resultsLength > paginationDetails.itemsPerPage && (
            <CustomPagination
              itemsLength={resultsLength}
              itemsPerPage={paginationDetails.itemsPerPage}
              onPageChangeCallback={handlePageChange}
              isStudent={true}
              isLoading={loading}
              shape={'rounded'}
              variant={'outlined'}
              scrollTop
            />
          )}
        </Grid>
        <Grid item xs>
          <BrRecommendationsWidget
            recommendations={globalRecommendations}
            label={appStrings.common.featuredProducts}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ProductSearchResults;
