import { ClickAwayListener, Divider, Grid, InputAdornment, List, ListItem, ListItemButton, ListItemText } from '@mui/material';

import CustomButton from '../../shared/CustomButton/CustomButton';
import FormInput from '../../shared/FormInput/FormInput';
import { addProductSummaryInputs } from './addProductSummaryInputs';
import addProductSummaryStyles from './useAddProductSummaryStyles';
import appStrings from '../../../core/strings/appStrings';
import classNames from 'classnames';
import { fullIcons } from '../../../core/strings/icons';
import sepFonts from '../../../core/assets/sep-industrial-font.module.css';
import { sepIndustrialFont } from '../../../core/strings/appConstants';
import { useLocation } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getProducts } from './addProductService';
import { getHighlightedText } from '../../shared/BrSearchBar/brSearchBarService';
import { debounce } from 'lodash';

function AddProductSummary({
  handleSubmit,
  control,
  errors,
  onSubmit,
  buttonDisabled,
  searchItem,
  onProductNumberChange,
  onQuantityChange,
  productNumberRef,
  quantityRef,
  studentPriceRef,
  watch,
  setValue,
}) {
  const css = addProductSummaryStyles().classes;
  const { pathname } = useLocation();
  const isSavedItems = pathname.indexOf('saved') !== -1;
  const isOrder = pathname.indexOf('order') !== -1;
  const fieldRefs = [productNumberRef, quantityRef, studentPriceRef];
  let addBtnLabel = isSavedItems
    ? appStrings.cart.addToSavedItems
    : appStrings.cart.addToCart;
  addBtnLabel = isOrder ? appStrings.order.addToOrder : addBtnLabel;
  const productNumber = watch('productNumber');
  const [products, setProducts] = useState([]);
  const prevProductNumber = useRef('');
  const [productListOpen, setProductListOpen] = useState(false);
  const [productsLoading, setProductsLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getProductsDebounced = useCallback(
    debounce((product, callback) => {
      setProducts([]);
      getProducts(product).then(callback);
    }, 200), []
  );

  useEffect(() => {
    if (prevProductNumber.current === productNumber?.trim()) {
      return;
    }
    prevProductNumber.current = productNumber?.trim();
    if (!productNumber?.trim()?.length) {
      setProducts([]);
      return;
    }

    setProductsLoading(true);
    getProductsDebounced(productNumber.trim(), productsRes => {
      if (productsRes.products?.length) {
        setProducts(productsRes.products);
        if (!productListOpen) {
          setProductListOpen(true);
        }
      } else {
        setProducts([]);
      }
      setProductsLoading(false);
    });
  }, [getProductsDebounced, onProductNumberChange, productListOpen, productNumber]);

  const onProductClick = (e, productNumber) => {
    e.stopPropagation();
    prevProductNumber.current = productNumber;
    setValue('productNumber', productNumber);
    productNumberRef.current?.focus();
    setProductListOpen(false);
  }

  const renderFormInput = (field, index) => (
    <FormInput
      fieldRef={fieldRefs[index]}
      field={field}
      control={control}
      errors={errors}
      InputProps={
        index === 0
          ? {
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <span
                    className={classNames(
                      sepFonts[sepIndustrialFont],
                      sepFonts[fullIcons.search],
                      css.searchIcon
                    )}
                    onClick={searchItem}
                    data-test-id="searchProductIcon"
                  />
                </InputAdornment>
              ),
            }
          : null
      }
    />
  );

  return (
    <Grid container spacing={2} alignItems="flex-start">
      {addProductSummaryInputs({
        handleSubmit,
        onSubmit,
        searchItem,
        onProductNumberChange,
        onQuantityChange,
        isOrder,
        onProductNumberFocus: () => setProductListOpen(true),
      }).map((field, index) => (
        <ClickAwayListener
          onClickAway={() => index === 0 && setProductListOpen(false)}
          touchEvent={false}
          key={index}
        >
          <Grid
            item
            {...field.gridLayout}
            className={index > 0 ? css.shortInput : css.productNumberField}
          >
            {renderFormInput(field, index)}
            {
              index === 0 && productNumber?.length && productListOpen ?
              <>
                {
                  !productsLoading &&
                  <ListItem className={css.numberSearchResults}>
                    <ListItemText>
                      {appStrings.cart.numberOfResults(products.length)}
                    </ListItemText>
                  </ListItem>
                }
                <List className={css.productsList}>
                  {
                    productNumber?.length && !products.length && productsLoading &&
                    <ListItem>
                      <ListItemText>{appStrings.cart.productsLoading}</ListItemText>
                    </ListItem>
                  }
                  {
                    productNumber?.length && !products.length && !productsLoading &&
                    <ListItem>
                      <ListItemText>{appStrings.cart.productNoResults}</ListItemText>
                    </ListItem>
                  }
                  {products.map((product, productIndex) => (
                    <Grid key={productIndex}>
                      <Divider />
                      <ListItem disablePadding>
                        <ListItemButton
                          disableRipple
                          onClick={e => onProductClick(e, product.productNumber)}
                        >
                          <ListItemText>
                            {getHighlightedText(
                              `${product.productNumber} ${product.description}`,
                              productNumber.trim()
                            )}
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    </Grid>
                  ))}
                </List>
              </> : null
            }
          </Grid>
        </ClickAwayListener>
      ))}
      <Grid item className={css.buttonContainer}>
        <CustomButton
          label={addBtnLabel}
          id="addToCartBtn"
          data-test-id="addToCartBtn"
          disabled={buttonDisabled}
          onClick={handleSubmit(onSubmit)}
        ></CustomButton>
      </Grid>
    </Grid>
  );
}

export default AddProductSummary;
