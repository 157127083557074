const responseTypeConstants = {
  ApplicationJSON: 'application/json',
  ApplicationPDF: 'application/pdf',
  ArrayBuffer: 'arraybuffer',
  FormData: 'multipart/form-data;boundary=something',
  JSON: 'json',
  OctetStream: 'application/octet-stream',
  Text: 'text/plain',
  Image: 'image/jpeg',
};

export const isBlobResponseType = respType => {
  return Boolean(
    respType === responseTypeConstants.ApplicationPDF ||
      respType === responseTypeConstants.OctetStream ||
      respType === responseTypeConstants.Image
  );
};
export const isTextResponseType = respType =>
  respType.includes(responseTypeConstants.Text);

export default responseTypeConstants;
