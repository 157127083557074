import { makeStyles } from 'tss-react/mui';

const useStudentDetailsCardStyles = makeStyles()(theme => ({
  selectedCard: {
    background: `${theme.palette.common.neutral5} !important`,
  },
  cards: {
    background: theme.palette.common.neutral1,
    border: `1px solid ${theme.palette.common.veryLightGrey}`,
    borderRadius: '5px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    padding: theme.spacing(2),
    wordBreak: 'break-all',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
}));

export default useStudentDetailsCardStyles;
