import { Grid, Typography, useTheme } from '@mui/material';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PropTypes from 'prop-types';
import appStrings from '../../../core/strings/appStrings';
import useKeyLock from '../../../../utils/keylock';
import usePasswordValidationStyles from './usePasswordValidationStyles';

const propTypes = {
  isTouched: PropTypes.bool,
  warnCapsLock: PropTypes.bool,
  validateItems: PropTypes.array,
  errors: PropTypes.array,
};

function PasswordValidation({
  isTouched,
  warnCapsLock,
  validateItems,
  errors,
}) {
  const css = usePasswordValidationStyles().classes;
  const { palette } = useTheme();
  const capsLock = useKeyLock('CapsLock');

  const errorStyle = text => {
    if (!isTouched) {
      return {
        color: palette.common.primary,
      };
    }
    if (errors?.includes(text)) {
      return { color: palette.common.red };
    }
    return { color: palette.common.green };
  };

  const validationIcon = text => {
    if (!isTouched) {
      return <CircleOutlinedIcon fontSize="small" className={css.statusIcon} />;
    }
    if (errors?.includes(text)) {
      return <CancelOutlinedIcon fontSize="small" className={css.statusIcon} />;
    }
    return (
      <CheckCircleOutlinedIcon fontSize="small" className={css.statusIcon} />
    );
  };

  return (
    <Grid item xs={12} className={css.validationContainer}>
      {warnCapsLock && capsLock && (
        <Grid item xs={12}>
          <Typography
            id="password-validation-caps-lock"
            style={{ color: palette.common.primary }}
            variant="caption"
            className={css.validationMessage}
          >
            <InfoOutlinedIcon fontSize="small" className={css.statusIcon} />
            {appStrings.validationMessages.passwordCapsLock}
          </Typography>
        </Grid>
      )}
      {validateItems.map((item, index) => {
        return (
          <Grid item xs={12} key={index}>
            <Typography
              xs={12}
              id={`password-validation-length-${index}`}
              style={errorStyle(item)}
              variant="caption"
              className={css.validationMessage}
            >
              {validationIcon(item)}
              {item}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
}

PasswordValidation.propTypes = propTypes;

export default PasswordValidation;
