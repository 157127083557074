import {
  displayError,
  displaySuccess,
} from '../../../core/redux/slices/notificationsSlice';
import {
  setPurchasePower,
  setPurchasePowerLoading,
} from '../../../core/redux/slices/purchasePowerSlice';

import ApiService from '../../shared/Api/apiService';
import appStrings from '../../../core/strings/appStrings';
import { floor } from 'lodash';
import { formatNumberWithCurrency } from '../../../core/services/utilsService';
import store from '../../../core/redux/store';
import { trackGAEvent } from '../../shared/Analytics/analyticsService';
import urls from '../../../core/strings/urls';

export const getPercentageOfTotal = (value, total) => {
  let percentage = (value * 100) / total;
  percentage = Math.abs(floor(percentage, 2));
  return percentage;
};

const getGARange = purchasePower => {
  let range = '';
  if (purchasePower > 9500) {
    range = '>9500';
  } else if (purchasePower > 7000) {
    range = '7000-9500';
  } else if (purchasePower > 4000) {
    range = '4000-7000';
  } else if (purchasePower > 1000) {
    range = '1000-4000';
  } else if (purchasePower > 0) {
    range = '0-1000';
  } else {
    range = '<=0';
  }
  return range;
};

export const getPurchasePower = (studentId, purchaseType, isSavedItems) => {
  const ppSlice = store.getState().purchasePower;

  if (!ppSlice.isLoading) {
    store.dispatch(setPurchasePowerLoading());
    ApiService.get(urls.getPurchasePower(studentId, purchaseType))
      .then(result => {
        store.dispatch(setPurchasePower(result));
        if (isSavedItems) {
          store.dispatch(
            displaySuccess({ message: appStrings.cart.itemSaved })
          );
        }
        trackGAEvent(
          getGARange(result.currentValue),
          'Remaining Purchasing Power',
          studentId
        );
      })
      .catch(() => store.dispatch(displayError()));
  }
};

export const getCurrentCartColorClass = (currentVal, remainingVal, css) => {
  let currentCartColor = currentVal > 0 && remainingVal >= 0 ? 'yellow' : 'red';
  currentCartColor = currentVal === 0 ? '' : currentCartColor;
  return currentCartColor ? css[currentCartColor] : '';
};

export const getFormattedValue = currentVal => {
  return formatNumberWithCurrency(currentVal);
};
