import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomDialog from '../CustomDialog/CustomDialog';
import useImageCarouselStyles from './useImageCarouselStyles';
import ImageCarousel from './ImageCarousel';

function ImageCarouselModal({images, initialSlide, dialogOpen, handleClose}) {
  const css = useImageCarouselStyles().classes;
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  const updateModalOnResize = () => {
    setIsDesktop(window.innerWidth > 1024);
  };

  useEffect(() => {
    window.addEventListener('resize', updateModalOnResize);
    return () => window.removeEventListener('resize', updateModalOnResize);
  }, []);

  return (
    <CustomDialog
      id="productImageZoomedModal"
      open={dialogOpen}
      handleClose={handleClose}
      customClass={css.imageDialog}
      showCloseButton
      message={
        <ImageCarousel images={images} initialSlide={initialSlide} zoomed/>
      }
      fullScreen={!isDesktop}
    />
  );
}

ImageCarouselModal.propTypes = {
  images: PropTypes.array,
  initialSlide: PropTypes.number,
  dialogOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default ImageCarouselModal;