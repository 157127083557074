import { useEffect, useState } from 'react';

import CustomButton from '../../shared/CustomButton/CustomButton';
import CustomDialog from '../../shared/CustomDialog/CustomDialog';
import CustomPagination from '../../shared/CustomPagination/CustomPagination';
import FormInput from '../../shared/FormInput/FormInput';
import { Grid } from '@mui/material';
import appStrings from '../../../core/strings/appStrings';
import { buttonTypes } from '../../../core/strings/appConstants';
import { useForm } from 'react-hook-form';
import useSerialNumbersDialogStyles from './useSerialNumbersDialogStyles';

const serialNumbersLimit = 9999;

function SerialNumbersDialog({
  serialNumbers,
  quantity,
  handleClose,
  onSubmit,
}) {
  const css = useSerialNumbersDialogStyles().classes;
  const { handleSubmit, control, setValue } = useForm();
  const [paginationDetails, setPaginationDetails] = useState({
    from: 0,
    to: 10,
    itemsPerPage: 10,
    page: 1,
  });
  const displayedQuantity =
    quantity > serialNumbersLimit ? serialNumbersLimit : quantity;
  const serialNumberInput = {
    name: 'serialNumber',
    type: 'text',
    validations: {
      maxLength: 30,
    },
  };

  const handlePageChange = ({ from, to, page, itemsPerPage }) => {
    setPaginationDetails({ from, to, page, itemsPerPage });
  };

  const handleClear = index => {
    setValue(`serialNumber${index}`, '');
  };

  useEffect(() => {
    if (serialNumbers) {
      setTimeout(() => {
        // setTimeout is necessary for the first render of the inputs, when serial numbers were already saved
        serialNumbers.forEach((serialNumber, index) => {
          setValue(`serialNumber${index}`, serialNumber);
        });
      });
    }
  }, [serialNumbers, setValue]);

  const generateSerialNumberInputs = () => {
    const inputs = [];
    for (
      let i = (paginationDetails.page - 1) * 10;
      i < paginationDetails.page * 10 && i < displayedQuantity;
      i++
    ) {
      inputs.push(
        <Grid
          item
          container
          xs={12}
          key={`serialNumber${i}`}
          className={
            i < paginationDetails.from || i > paginationDetails.to - 1
              ? css.hide
              : ''
          }
        >
          <Grid item xs={10}>
            <FormInput
              field={{
                ...serialNumberInput,
                name: `${serialNumberInput.name}${i}`,
              }}
              control={control}
            />
          </Grid>
          <Grid item xs={2}>
            <CustomButton
              btnType={buttonTypes.tertiary}
              label={appStrings.common.clear}
              onClick={() => handleClear(i)}
            />
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container className={css.inputsContainer}>
        {quantity > serialNumbersLimit ? (
          <Grid item xs={12} className={css.limit}>
            {appStrings.order.serialNumbersLimit}
          </Grid>
        ) : null}
        {inputs}
        <Grid item xs={12} className={css.pagination}>
          <CustomPagination
            itemsLength={displayedQuantity}
            itemsPerPage={paginationDetails.itemsPerPage}
            id={'serialNumbersPagination'}
            onPageChangeCallback={handlePageChange}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <CustomDialog
      customClass={css.serialNumberDialog}
      open
      title={appStrings.order.addSerialNumbersTitle(quantity)}
      showCloseButton
      handleClose={handleClose}
      message={generateSerialNumberInputs()}
      buttons={[
        {
          type: 'submit',
          label: appStrings.order.addSerialNumbers,
          onClick: handleSubmit(onSubmit),
        },
      ]}
    />
  );
}

export default SerialNumbersDialog;
