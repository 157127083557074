import { IconButton, InputAdornment, TextField } from '@mui/material'
import React, { useState } from 'react'
import useFormInputStyles, { helperTextStyle } from '../useFormInputStyles'

import { Controller } from 'react-hook-form'
import CustomIcon from '../../CustomIcon/CustomIcon'
import NumberFormat from 'react-number-format'
import { icons } from '../../../../core/strings/icons'
import propTypes from '../propTypes'
import { ssnMask } from '../../../../core/strings/appConstants'
import classNames from 'classnames'
import useNumberFieldStyles from '../NumberField/useNumberFieldStyles'

function SocialSecurityField({
  field,
  fieldErrors,
  control,
  errors,
  setValue,
  getValues,
  ...props
}) {
  const css = useFormInputStyles().classes;
  const helperCss = helperTextStyle().classes;
  const numberCss = useNumberFieldStyles().classes;

  const [showSsn, setShowSsn] = useState(false);
  // TODO should hide ssn when changing tabs or clicking/writing on a diff input
  const handleClickShowSsn = () => {
    setShowSsn(!showSsn);
  };

  const handleMouseDownSsn = event => {
    event.preventDefault();
  };

  const withValueLimit = inputObj => {
    const { value } = inputObj;

    return value.length <= field.validations.maxLength;
  };
  const getIcon = () => (!showSsn ? icons.show : icons.hide);

  const handleOnBlur = () => {
    setShowSsn(false);
  };

  return (
    <>
      <Controller
        render={({ field: { onChange, ...prop } }) => (
          <div onBlur={handleOnBlur}>
            <NumberFormat
              className={`${css.simpleInput} ${
                fieldErrors ? numberCss.errors : ''
              }`}
              isAllowed={withValueLimit}
              customInput={TextField}
              format={!showSsn ? null : ssnMask}
              mask={!showSsn ? ' ' : '_'}
              onValueChange={v => {
                onChange(v.value);
              }}
              variant="outlined"
              {...prop}
              FormHelperTextProps={{
                classes: { root: helperCss.root },
              }}
              label={field.label}
              name={field.name}
              type={showSsn ? 'text' : 'password'}
              helperText={errors[field.name] ? field.helperText || '' : ''}
              autoComplete="off"
              data-test-id="ssn-input"
              id={field.name}
              InputProps={{
                ...props.InputProps,
                endAdornment: field.showVisibilityBtn ? (
                  <InputAdornment
                    position="end"
                    data-test-id="ssn-input-visibility"
                    id="visibilityIcon"
                  >
                    <IconButton
                      aria-label="toggle ssn visibility"
                      onClick={handleClickShowSsn}
                      onMouseDown={handleMouseDownSsn}
                      size="large"
                    >
                      <CustomIcon icon={getIcon()} size="12px" />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
              fullWidth={props.fullWidth}
              size={props.size ? props.size : 'small'}
            />
          </div>
        )}
        control={control}
        defaultValue={field.defaultValue ?? ''}
        name={field.name}
        rules={{ ...field.validations }}
      />
      {fieldErrors?.message && (
        <span id="errorMessage" className={classNames(numberCss.errorMessage)}>
          {fieldErrors.message}
        </span>
      )}
    </>
  );
}

SocialSecurityField.propTypes = propTypes;

export default SocialSecurityField;
