import './App.css';

import {
  getAuthToken,
  getDuration,
  getIdentity,
} from './app/modules/shared/Authentication/authenticationService';
import { useDispatch, useSelector } from 'react-redux';

import ErrorBoundary from './app/core/errorBoundary/ErrorBoundary';
import { LOGIN, ACCEPTTERMSANDCONDITIONS } from './app/core/navigation/paths';
import MainContainer from './app/modules/shared/MainContainer/MainContainer';
import RouterConfig from './app/core/navigation/routerConfig';
// TODO: import Switch from '@mui/material/Switch';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
// TODO: import darkTheme from './app/core/themes/darkTheme';
import lightTheme from './app/core/themes/lightTheme';
import { logout } from './app/core/redux/slices/loginSlice';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getPageTitle } from './pageTitleValues';
import { STUDENT } from './app/core/navigation/roles';
import { initGA, initializeTagManager } from './app/modules/shared/Analytics/analyticsService';

function App() {
  const theme = createTheme(lightTheme);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn, userDetails } = useSelector(state => state.login);
  const cookieConsent = useRef(window.OnetrustActiveGroups?.includes('C0002'));

  /* TODO: Uncomment below when dark theme will be available
  const [darkState, setDarkState] = useState(false);
  const theme = createTheme(darkState ? darkTheme : lightTheme);
  const handleThemeChange = () => {
    setDarkState(!darkState);
  };*/

  useEffect(() => {
    const onConsentUpdated = event => {
      const consent = event.detail.includes('C0002');
      if (consent) {
        if (!cookieConsent.current) {
          initGA();
          initializeTagManager();
        }
      } else {
        if (cookieConsent.current) {
          window.location.reload();
        }
      }
      cookieConsent.current = consent;
    }

    window.addEventListener('OneTrustGroupsUpdated', onConsentUpdated);
    initGA();
    initializeTagManager();
    return () => {
      window.removeEventListener('OneTrustGroupsUpdated', onConsentUpdated);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      const durationIntervalId = setInterval(() => {
        const duration = getDuration();
        const now = moment();

        if (!duration || moment(duration) <= now) {
          if (isLoggedIn) {
            dispatch(logout());
          }
          clearInterval(durationIntervalId);
        }
      }, 30000);

      const logoutIntervalId = setInterval(() => {
        const userDetailsCookies = getIdentity();
        const token = getAuthToken();
        const isTokenExpired = Boolean(
          moment(userDetailsCookies?.expirationDate || '') <= moment()
        );

        if (
          isLoggedIn &&
          (!userDetailsCookies?.name || !token || isTokenExpired)
        ) {
          dispatch(logout());

          clearInterval(logoutIntervalId);
          clearInterval(durationIntervalId);

          navigate(LOGIN);
        }
      }, 1000);
    }
    document.title = getPageTitle(document.location.pathname);
  }, [dispatch, isLoggedIn, navigate]);

  useEffect(() => {
    if (
      userDetails.role === STUDENT &&
      !userDetails.hasAcceptedTermsAndConditions
    ) {
      navigate(ACCEPTTERMSANDCONDITIONS);
    }
  }, [navigate, userDetails]);

  return (
    <ErrorBoundary>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MainContainer
              setMenuToLeft={isLoggedIn && userDetails.isIndustrial}
            >
              {/* TODO: Uncomment below when dark theme will be available */}
              {/* <div
                style={{
                  textAlign: 'right',
                  position: 'absolute',
                  right: 15,
                  top: 15,
                  zIndex: 99999,
                }}
              >
                <Switch
                  checked={darkState}
                  onChange={handleThemeChange}
                  data-test-id="themeSwitch"
                />
              </div> */}

              {/* TODO: Remove this once payment modal is implemented 
                <CustomButton
                  onClick={() => setShowPayment(true)}
                  label={'Trigger Payment'}
                />

               {showPayment ? (
                <PaymentDialog
                  orderId={'SEP-12345'}
                  onClose={() => setShowPayment(false)}
                  onPaymentComplete={emptyFunc}
                  onError={emptyFunc}
                />
              ) : null} */}
              <RouterConfig />
            </MainContainer>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ErrorBoundary>
  );
}

export default App;
