import { useDispatch, useSelector } from 'react-redux';

import CustomPagination from '../../../shared/CustomPagination/CustomPagination';
import CustomTable from '../../../shared/CustomTable/CustomTable';
import CustomTableSkeleton from '../../../shared/CustomSkeleton/CustomTableSkeleton';
import { Grid } from '@mui/material';
import { lineItemsTableColumns } from '../restorePurchasesConstants';
import { updateSelectedItemsRestore } from '../../../../core/redux/slices/restorePurchasesSlice';
import useRestorePurchaseStyles from '../useRestorePurchaseStyles';
import { useState } from 'react';

function RestorePurchasesItems({ onPageChange, callInProgress = false }) {
  const css = useRestorePurchaseStyles().classes;
  const dispatch = useDispatch();

  const { details, rowsCount, filters, selectedCount } = useSelector(
    state => state.restorePurchases
  );

  const [paginationDetails, setPaginationDetails] = useState({
    from: 0,
    to: filters.page.customPageSize,
    itemsPerPage: filters.page.customPageSize,
    page: filters.page.pageNumber,
  });

  const onSelectRow = (index, selected, rowDetails) => {
    dispatch(updateSelectedItemsRestore({ rowDetails }));
  };

  const onSelectAll = isAllSelected => {
    dispatch(
      updateSelectedItemsRestore({
        [!isAllSelected ? 'reset' : 'all']: true,
      })
    );
  };

  const onPageChangeCallback = ({ from, to, page, itemsPerPage }) => {
    setPaginationDetails({ from, to, page, itemsPerPage });
    if (onPageChange) {
      onPageChange(page - 1);
    }
  };

  return (
    <Grid container>
      {callInProgress && (
        <Grid item xs={12}>
          <CustomTableSkeleton columns={lineItemsTableColumns} />
        </Grid>
      )}

      <Grid container className={callInProgress ? css.hide : ''}>
        {details?.length ? (
          <>
            <Grid item xs={12}>
              <CustomTable
                columns={lineItemsTableColumns}
                rowData={details}
                useIndex
                useCheckbox
                currentPage={paginationDetails.page}
                itemsPerPage={paginationDetails.itemsPerPage}
                rowCount={rowsCount}
                selectedCount={selectedCount}
                onSelectRow={onSelectRow}
                onSelectAll={onSelectAll}
                tableId={'restorePurchasePower'}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomPagination
                itemsLength={rowsCount}
                itemsPerPage={paginationDetails.itemsPerPage}
                id={'restorePurchasesLineItemsPagination'}
                onPageChangeCallback={onPageChangeCallback}
              />
            </Grid>
          </>
        ) : null}
      </Grid>
    </Grid>
  );
}
export default RestorePurchasesItems;
