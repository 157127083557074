import React from 'react';
import NotificationsViewStudent from './Components/NotificationsViewStudent';
import NotificationsViewIndustrial from './Components/NotificationsViewIndustrial';
import { notificationRole } from '../../../core/strings/appConstants';

function Notification({ role }) {
  return role === notificationRole.student ? (
    <NotificationsViewStudent />
  ) : (
    <NotificationsViewIndustrial />
  );
}

export default Notification;
