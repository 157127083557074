import { makeStyles } from 'tss-react/mui';

const useNoDataAvailableStyle = makeStyles()(theme => ({
  noDataContainer: {
    padding: `${theme.spacing(3)} 0`,
  },
  message: {
    color: theme.palette.common.darkGrey,
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(2),
  },
  helperText: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  icon: {
    fontSize: 4 * theme.typography.fontSize,
    color: theme.palette.common.primary,
    '& svg': {
      fontSize: 4 * theme.typography.fontSize,
      color: theme.palette.common.primary,
    },
  },
  largeIcon: {
    fontSize: 6 * theme.typography.fontSize,
    '& svg': {
      fontSize: 6 * theme.typography.fontSize,
    },
  },
}));

export default useNoDataAvailableStyle;
