import React from 'react';
import { NavLink } from 'react-router-dom';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import { Grid, Typography } from '@mui/material';
import { CATEGORY_ID } from '../../../../core/navigation/paths';
import PropTypes from 'prop-types';
import noProductImage from '../../../../core/assets/img/noProduct-img.png';
import classNames from 'classnames';

function CategoryContent({ id, name, image, css }) {
  const getLabel = () => {
    return (
      <Grid container justifyContent="center" height="100%">
        <Grid item alignSelf="center">
          <img
            src={image}
            alt={name}
            onError={e => (e.target.src = noProductImage)}
          />
        </Grid>
        <Grid item xs={12} alignSelf="flex-end">
          <Typography
            className={classNames(css.textBold, css.categoryContentTitle)}
          >
            {name}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <NavLink to={CATEGORY_ID(id)} onClick={() => window.scrollTo(0, 0)}>
      <CustomButton
        id="category-content"
        data-test-id={`${name}-category`}
        btnType="secondary"
        label={getLabel()}
      />
    </NavLink>
  );
}

CategoryContent.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  image: PropTypes.elementType,
  css: PropTypes.object,
};

export default CategoryContent;
