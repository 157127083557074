import { Checkbox, Grid } from '@mui/material';
import {
  buttonColors,
  buttonTypes,
  dateFormat,
  studentsApprovalState,
  studentsApprovalStateByCode,
} from '../../../../core/strings/appConstants';
import { useDispatch, useSelector } from 'react-redux';

import CustomBox from '../../../shared/CustomBox/CustomBox';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import PropTypes from 'prop-types';
import appStrings from '../../../../core/strings/appStrings';
import classNames from 'classnames';
import { formatPhoneNumberUS } from '../../../../core/services/utilsService';
import { icons } from '../../../../core/strings/icons';
import moment from 'moment';
import { updateCheckedStudent } from '../../../../core/redux/slices/approveRejectSlice';
import useStudentDetailsCardStyles from './useStudentDetailsCardStyles';

const propTypes = {
  student: PropTypes.object,
  showSelectAll: PropTypes.bool,
  handleStatusUpdate: PropTypes.func,
  index: PropTypes.number,
};

function StudentDetailsCard({
  student,
  showSelectAll,
  handleStatusUpdate,
  index,
}) {
  const css = useStudentDetailsCardStyles().classes;
  const sectionType = student.approval;
  const section = studentsApprovalStateByCode[sectionType];
  const { checkedStudents, sortedList } = useSelector(
    state => state.approveReject
  );
  const masterSelectorText =
    checkedStudents.length === sortedList[section]?.length
      ? appStrings.approveReject.deselect
      : appStrings.approveReject.select;

  const dispatch = useDispatch();
  const isChecked = Boolean(
    checkedStudents.find(elem => elem.id === student.id)
  );

  const showApproveBtn = Boolean(
    sectionType === studentsApprovalState.pending ||
      sectionType === studentsApprovalState.rejected
  );

  const showDeclineBtn = Boolean(
    sectionType === studentsApprovalState.pending ||
      sectionType === studentsApprovalState.approved
  );
  const iconSize = '0.8rem';
  const leftSection = [
    {
      key: 'studentName',
      label: appStrings.searchInputs.studentName,
      data: `${student.firstName} ${student.lastName}`,
      boldData: true,
      capitalize: true,
    },
    {
      key: 'schoolName',
      label: appStrings.searchInputs.schoolName,
      data: student.schoolName,
    },
    {
      key: 'actionButtons',
      approveBtn: () => (
        // TODO: update colors to orange and darkgreen
        <CustomButton
          label={appStrings.approveReject.approve}
          color={buttonColors.green}
          onClick={() => handleStatusUpdate([{ studentId: student.id }])}
          icon={icons.checked}
          iconSize={iconSize}
          id={`approveStudentBtn-${index}`}
          data-test-id={`approveStudentBtn-${student.id}`}
        />
      ),
      declineBtn: () => (
        <CustomButton
          label={appStrings.approveReject.reject}
          color={buttonColors.red}
          icon={icons.clear}
          iconSize={iconSize}
          id={`rejectStudentBtn-${index}`}
          data-test-id={`rejectStudentBtn-${student.id}`}
          onClick={() =>
            handleStatusUpdate(
              [{ studentId: student.id }],
              studentsApprovalState.rejected
            )
          }
        />
      ),
    },
  ];
  const rightSection = [
    {
      key: 'registerDate',
      label: appStrings.searchInputs.registerDate,
      data: moment(student.registerDate).format(dateFormat),
    },
    {
      key: 'graduationDate',
      label: appStrings.register.graduationDate,
      data: moment(student.graduationDate).format(dateFormat),
    },
    {
      key: 'phoneNumber',
      label: appStrings.searchInputs.phoneNumber,
      data: formatPhoneNumberUS(student.phoneNumber),
    },
    {
      key: 'emailAddress',
      label: appStrings.searchInputs.email,
      data: student.emailAddress,
    },
    {
      key: 'instructorName',
      label: appStrings.register.instructorName,
      data: student.instructorName,
      capitalize: true,
    },
  ];

  const getSectionDetails = elem => {
    if (elem.label) {
      return (
        <Grid
          item
          container
          spacing={1}
          key={elem.key}
          id={elem.key + 'Container-' + index}
        >
          <Grid item>
            <strong>{elem.label}:</strong>
          </Grid>
          <Grid
            item
            className={classNames({ [css.capitalize]: elem.capitalize })}
          >
            <span>
              {elem.boldData ? <strong>{elem.data}</strong> : elem.data}
            </span>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid
        item
        container
        xs={12}
        spacing={2}
        marginTop
        key={elem.key}
        id={elem.key + 'Container-' + index}
      >
        {showApproveBtn && <Grid item>{elem.approveBtn()}</Grid>}
        {showDeclineBtn && <Grid item>{elem.declineBtn()}</Grid>}
      </Grid>
    );
  };

  const onStudentChecked = selectedStudent => {
    dispatch(updateCheckedStudent({ students: [selectedStudent] }));
  };

  const toggleSelectAll = () => {
    if (checkedStudents.length === sortedList[section].length) {
      dispatch(updateCheckedStudent({ reset: true }));
      return;
    }

    dispatch(updateCheckedStudent({ students: sortedList[section] }));
  };

  return (
    <Grid container alignItems="center">
      {showSelectAll ? (
        <Grid
          item
          xs={12}
          container
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <Grid
            item
            xs={2}
            container
            alignItems="center"
            justifyContent="center"
          >
            <CustomButton
              label={`${masterSelectorText} All (${checkedStudents.length})`}
              btnType={buttonTypes.tertiary}
              onClick={toggleSelectAll}
              data-test-id="toggleSelectAll"
              id="toggleSelectAllBtn"
            />
          </Grid>
        </Grid>
      ) : null}

      <Grid item xs={10}>
        <CustomBox
          className={classNames(css.cards, {
            [css.selectedCard]: isChecked,
          })}
        >
          <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item container xs spacing={2}>
              {leftSection.map(elem => getSectionDetails(elem))}
            </Grid>
            <Grid item container xs spacing={1}>
              {rightSection.map(elem => getSectionDetails(elem))}
            </Grid>
          </Grid>
        </CustomBox>
      </Grid>

      <Grid item xs={2} container alignItems="center" justifyContent="center">
        <Checkbox
          onChange={() => onStudentChecked(student)}
          checked={isChecked || false}
          id={`checkStudent-${index}`}
          data-test-id={`checkStudent-${student.id}`}
        />
      </Grid>
    </Grid>
  );
}

StudentDetailsCard.propTypes = propTypes;

export default StudentDetailsCard;
