import {
  saveHistoryDetails,
  updateFilters,
} from '../../../core/redux/slices/historySlice';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

import CustomBox from '../../shared/CustomBox/CustomBox';
import CustomButton from '../../shared/CustomButton/CustomButton';
import CustomPagination from '../../shared/CustomPagination/CustomPagination';
import { Grid } from '@mui/material';
import HistoryDetails from './HistoryDetails/HistoryDetails';
import HistoryDetailsSkeleton from './HistoryDetails/HistoryDetailsSkeleton';
import HistoryFilters from './HistoryFilters/HistoryFilters';
import HistorySkeleton from './HistorySkeleton';
import NameCard from '../NameCard/NameCard';
import PurchasePower from '../PurchasePower/PurchasePower';
import { RESTORE_PURCHASES } from '../../../core/navigation/paths';
import appStrings from '../../../core/strings/appStrings';
import { displayError } from '../../../core/redux/slices/notificationsSlice';
import { getHistoryDetailsByParams } from './historyService';
import { purchaseTypes } from '../PurchasePower/purchasePowerConstants';
import { resetRestoreData } from '../../../core/redux/slices/restorePurchasesSlice';
import useHistoryStyles from './useHistoryStyles';
import { useNavigate, useParams } from 'react-router';

function History() {
  const css = useHistoryStyles().classes;
  const { id, bp } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const student = useSelector(
    state => state.schoolsWithStudents.selectedStudent.student
  );
  const { rowsCount, filters } = useSelector(state => state.history);
  const [paginationDetails, setPaginationDetails] = useState({
    from: 0,
    to: 15,
    itemsPerPage: 15,
    page: 1,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isDetailsLoading, setIsDetailsLoading] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(true);

  const reloadHistory = useCallback(() => {
    setIsDetailsLoading(true);
    getHistoryDetailsByParams({ ...filters, studentId: id })
      .then(res => {
        if (res) {
          dispatch(
            saveHistoryDetails({
              details: res.transactions,
              pageSize: res.pageSize,
              rowsCount: res.count,
            })
          );
        }
      })
      .catch(e => {
        dispatch(displayError({ message: e }));
      })
      .finally(() => {
        setIsLoading(false);
        setIsDetailsLoading(false);
      });
  }, [dispatch, filters, id]);

  useEffect(() => {
    setFiltersLoading(false);
  }, [dispatch]);

  useEffect(() => {
    if (id && !filtersLoading) {
      reloadHistory();
    }
  }, [filtersLoading, id, reloadHistory]);

  useEffect(() => {
    if (filters.page.pageNumber + 1 !== paginationDetails.page) {
      dispatch(
        updateFilters({
          ...filters,
          page: { pageNumber: paginationDetails.page - 1 },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, paginationDetails.page]);

  const handlePaginationChanges = newPaginationDetails => {
    if (newPaginationDetails.page !== paginationDetails.page) {
      setPaginationDetails(newPaginationDetails);
    }
  };

  return (
    <>
      {isLoading && <HistorySkeleton />}
      <Grid
        container
        direction="column"
        alignItems="stretch"
        className={isLoading ? css.hideHistory : ''}
      >
        <Grid item>
          <NameCard student={student} />
        </Grid>
        <Grid item>
          <CustomBox customClass={css.customBox}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={12} sm={12} md={9}>
                <PurchasePower purchaseType={purchaseTypes.none} />
              </Grid>
              <Grid item>
                <CustomButton
                  id="restorePurchasePowerBtn"
                  label={appStrings.history.restorePurchasePower}
                  onClick={() => {
                    dispatch(resetRestoreData());
                    navigate(RESTORE_PURCHASES(bp, id));
                  }}
                />
              </Grid>
            </Grid>
          </CustomBox>
        </Grid>

        <Grid item>
          <HistoryFilters filtersLoading={filtersLoading} />
        </Grid>

        <Grid item>
          {isDetailsLoading ? (
            <HistoryDetailsSkeleton />
          ) : (
            <HistoryDetails bpId={bp} studentId={id} onReload={reloadHistory} />
          )}
        </Grid>
        {rowsCount ? (
          <Grid item xs={12}>
            <CustomPagination
              itemsLength={rowsCount}
              itemsPerPage={15}
              onPageChangeCallback={handlePaginationChanges}
            />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}

export default History;
