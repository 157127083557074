import {
  Breadcrumbs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import CustomSkeleton from '../CustomSkeleton/CustomSkeleton';
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import useCustomBreadcrumbsStyles from './useCustomBreadcrumbsStyles';
import { useEffect } from 'react';
import { trackGTMEvent } from '../Analytics/analyticsService';

function CustomBreadcrumbs({
  listOfPaths,
  currentPath,
  isLoading = false,
  currentLinkColor,
  textDecoration,
}) {
  const theme = useTheme();
  const isXsDevice = useMediaQuery(theme.breakpoints.only('xs'));
  const css = useCustomBreadcrumbsStyles().classes;

  useEffect(() => {
    if (listOfPaths?.length && currentPath) {
      let breadcrumbsFormatted = listOfPaths.map(b => b.label).join('|');
      breadcrumbsFormatted+= `|${currentPath.label}`;
      trackGTMEvent(null, {
        breadcrumbs: breadcrumbsFormatted,
        orig_ref_url: document.location.href,
      });
    }
  }, [listOfPaths, currentPath]);

  const breadcrumbLayout = [
    { xs: 4, width: '90%', height: '1.7rem', variant: 'rect' },
    {
      xs: 4,
      width: '80%',
      height: '1.7rem',
      variant: 'rect',
      container: true,
      justifyContent: 'flex-start',
    },
  ];

  return isLoading ? (
    <CustomSkeleton layout={breadcrumbLayout} />
  ) : (
    <Breadcrumbs
      maxItems={isXsDevice ? 4 : 8}
      separator={'>'}
      aria-label="breadcrumb"
      data-test-id="breadcrumbsContainer"
    >
      {listOfPaths?.map(path => (
        <Link
          key={path.link}
          to={path.link}
          className={css.links}
          style={{ textDecoration: textDecoration ?? 'underline' }}
          id={path.elementId}
        >
          {path.label}
        </Link>
      ))}

      {currentPath && (
        <Typography
          classes={{ root: css.currentLink }}
          color={currentLinkColor ?? theme.palette.common.primary}
          id={currentPath.id}
        >
          {currentPath.label}
        </Typography>
      )}
    </Breadcrumbs>
  );
}

export default CustomBreadcrumbs;
