import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

import { Controller } from 'react-hook-form';
import React from 'react';
import propTypes from '../propTypes';
import useFormInputStyles from '../useFormInputStyles';

function RadioField({ field, ...props }) {
  const css = useFormInputStyles().classes;

  return (
    <FormControl component="fieldset" size={field.size || 'small'}>
      {field.label ? (
        <FormLabel component="legend">{field.label}:</FormLabel>
      ) : null}
      <Controller
        control={props.control}
        defaultValue={field.defaultValue ?? ''}
        name={field.name}
        rules={{ ...field.validations }}
        render={({ field: { onChange, name, value } }) => (
          <RadioGroup
            row
            aria-label={field.label}
            name={name}
            onChange={e => {
              onChange(e);
              if (field.onChangeCallback) {
                field.onChangeCallback(e.target.value);
              }
            }}
            value={value}
            id={field.name}
          >
            {field.options?.map(option => (
              <FormControlLabel
                key={`${option.value}-option`}
                value={option.value}
                control={
                  <Radio
                    size={field.size || 'small'}
                    disableRipple
                    id={`option-${option.value}`}
                    classes={{ root: css.radio }}
                  />
                }
                label={option.label}
                labelPlacement={field.labelPlacement || 'end'}
              />
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
}

RadioField.propTypes = propTypes;

export default RadioField;
