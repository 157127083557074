import appStrings from '../../../core/strings/appStrings';
import urls from '../../../core/strings/urls';
import ApiService from '../../shared/Api/apiService';

const gridLayout = { xs: 6 };

export const getContractData = studentId => {
  const offset = new Date().getTimezoneOffset();
  return ApiService.get(urls.getContractData(studentId, offset));
};

export const updateContractData = contractData => {
  return ApiService.post(urls.updateContractData, contractData);
};

export const getInputFields = saveData => {
  return [
    {
      name: 'downPayment',
      label: appStrings.order.ecContractFields.downPayment,
      type: 'currency',
      gridLayout,
      onBlurCallback: saveData,
    },
    {
      name: 'administrationFee',
      label: appStrings.order.ecContractFields.securityInterestCharge,
      type: 'currency',
      gridLayout,
      onBlurCallback: saveData,
    },
    {
      name: 'firstInstallmentDueDate',
      label: appStrings.order.ecContractFields.firstInstallmentDueDate,
      type: 'date',
      minDate: new Date(),
      gridLayout,
      onCloseCallback: saveData,
      validations: {
        required: true,
      },
    },
  ];
};

export const contractData = [
  {
    name: 'totalCashSalePrice',
    label: appStrings.order.ecContractFields.totalCashSale,
    gridLayout,
  },
  {
    name: 'downPayment',
    label: appStrings.order.ecContractFields.downPaymentTradeIn,
    gridLayout,
  },
  {
    name: 'unpaidBalanceOfCashPrice',
    label: appStrings.order.ecContractFields.unpaidBalance,
    gridLayout,
  },
  {
    name: 'principalBalance',
    label: appStrings.order.ecContractFields.amountFinanced,
    gridLayout,
  },
  {
    name: 'unpaidNetPrevBalance',
    label: appStrings.order.ecContractFields.unpaidPrior,
    gridLayout,
  },
  {
    name: 'otherCharges',
    label: appStrings.order.ecContractFields.otherCharges,
    gridLayout,
  },
  {
    name: 'principalBalance',
    label: appStrings.order.ecContractFields.newPrincipleBalance,
    gridLayout,
  },
  {
    name: 'financeCharge',
    label: appStrings.order.ecContractFields.amountOfFinance,
    gridLayout,
  },
  {
    name: 'totalCreditSalePrice',
    label: appStrings.order.ecContractFields.totalOfCredit,
    gridLayout,
  },
  {
    name: 'interestRate',
    label: appStrings.order.ecContractFields.paymentInfo,
    gridLayout,
  },
  {
    name: 'totalPayments',
    label: appStrings.order.ecContractFields.totalOfPayments,
    gridLayout,
  },
];

export const validateDate = date => {
  if (typeof date === 'object' && '_isValid' in date) {
    return date._isValid;
  } else {
    return !!date;
  }
};
