import { makeStyles } from 'tss-react/mui';

const useCustomDialogStyles = makeStyles()(theme => ({
  dialogContainer: {
    padding: '50px 45px',
    textAlign: 'center',
    background: theme.palette.common.neutral2,
    border: `1px solid ${theme.palette.common.lightGrey}`,
  },
  iconContainer: {
    fontSize: '40px',
    lineHeight: '40px',
    '& svg': { fontSize: '40px' },
    marginBottom: theme.spacing(2.5),
  },
  dialogTitle: {
    padding: 0,
    marginBottom: theme.spacing(2.5),
    '& .MuiTypography-root': {
      fontSize: theme.typography.h3.fontSize,
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  messageContainer: {
    paddingTop: 0,
    marginBottom: theme.spacing(2.5),
  },
  buttonContainer: {
    justifyContent: 'space-evenly',
    paddingBottom: 0,

    '&.save-changes-buttons': {
      '@media screen and (max-width: 768px)': {
        flexDirection: 'column',
  
        '& button': {
          marginLeft: 'unset !important',
          marginBottom: theme.spacing(2),
        },
      },
    },
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.common.black,
  },
}));

export default useCustomDialogStyles;
