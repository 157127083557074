import React, { useEffect, useState } from 'react';
import CartItems from '../../ShoppingCart/Components/CartItems';
import { useDispatch, useSelector } from 'react-redux';
import { setIsActionBarOpen, setIsShoppingCartLoading, setShouldGetCart, setShouldGetCartSummary } from '../../../../core/redux/slices/guestCartSlice';
import { useMySavedItemsStyles } from './mySavedItemsStyles';
import { Grid } from '@mui/material';
import { displayError, displaySuccess } from '../../../../core/redux/slices/notificationsSlice';
import { notificationRole } from '../../../../core/strings/appConstants';
import appStrings from '../../../../core/strings/appStrings';
import { updateStudentSummary } from '../../ShoppingCart/ShoppingCartService';
import { setMoveItemsStatus } from '../../../../core/redux/slices/cartSlice';

function MySavedItems() {
  const css = useMySavedItemsStyles().classes;
  const dispatch = useDispatch();
  const { selectedSavedCartItems } = useSelector(
    state => state.guestCart.selectedItems
  );

  const { userDetails } = useSelector(state => state.login);
  const moveItemsStatus = useSelector(state => state.cart.moveItemsStatus);
  const [moveFlow, setMoveFlow] = useState(null);

  useEffect(() => {
    if (moveItemsStatus) {
      if (moveItemsStatus.message.length) {
        dispatch(
          displayError({
            message: moveItemsStatus.message[0],
            role: notificationRole.student,
          })
        );
      } else {
        dispatch(
          displaySuccess({
            message: 
              appStrings.cart.movedToCart
            })
        );
      }
      dispatch(setIsShoppingCartLoading(true));
      updateStudentSummary(userDetails.userId).then(() => {
        dispatch(setShouldGetCart(true));
        dispatch(setShouldGetCartSummary(true));
        dispatch(setIsShoppingCartLoading(false));
      });
      dispatch(setMoveItemsStatus(null));
    }
  }, [dispatch, moveFlow, moveItemsStatus, userDetails.userId]);

  useEffect(() => {
    if (selectedSavedCartItems?.length) {
      dispatch(setIsActionBarOpen(true));
    } else {
      dispatch(setIsActionBarOpen(false));
    }
  }, [selectedSavedCartItems, dispatch]);

  return (
    <Grid className={css.mainContainer} id="MySavedItems">
      <CartItems
        className={css.mainContainer}
        isSavedCart={true}
        selectedItems={selectedSavedCartItems}
        setMoveFlow={setMoveFlow}
      />
    </Grid>
  );
}

export default MySavedItems;
