import {
  APPROVE_REJECT,
  CART,
  CONSIGNED,
  EC_APP_WIZARD,
  EC_CONTRACT_SIGNING,
  HISTORY,
  LOGIN,
  ORDER,
  ORDER_CONFIRMATION,
  ORDER_CONSIGNED_PAGE,
  ORDER_HISTORY_DETAILS,
  REGISTER_IND,
  REGISTER_IND_CONFIRMATION,
  RESTORE_PURCHASES,
  SAVED,
  SEARCH,
  STUDENTS,
  STUDENT_PROFILE,
} from '../paths';

import ApproveReject from '../../../modules/industrial/ApproveReject/ApproveReject';
import Cart from '../../../modules/industrial/Cart/Cart';
import CartContextProvider from '../../../modules/industrial/Cart/context/CartContextProvider';
import ConsignedInventory from '../../../modules/industrial/ConsignedInventory/ConsignedInventory';
import ECAppWizard from '../../../modules/shared/ECAppWizard/ECAppWizard';
import History from '../../../modules/industrial/History/History';
import Industrial from '../../../modules/industrial/Industrial';
import ListSearchResult from '../../../modules/industrial/StudentSearch/ListSearchResult/ListSearchResult';
import OrderHistoryDetails from '../../../modules/industrial/OrderHistoryDetails/OrderHistoryDetails';
import Register from '../../../modules/shared/Register/Register';
import RegisterConfirmation from '../../../modules/shared/RegisterConfirmation/RegisterConfirmation';
import RestorePurchases from '../../../modules/industrial/RestorePurchases/RestorePurchases';
import StudentProfile from '../../../modules/industrial/StudentProfile/StudentProfile';
import ECContractSigning from '../../../modules/industrial/ECContractSigning/ECContractSigning';
import OrderConfirmation from '../../../modules/industrial/OrderConfirmation/OrderConfirmation';
import Login from '../../../modules/shared/Login/Login';

const industrialRoutes = [
  {
    component: <ApproveReject />,
    exact: true,
    path: APPROVE_REJECT,
  },
  {
    component: <Register />,
    exact: true,
    path: REGISTER_IND,
  },
  {
    component: <RegisterConfirmation />,
    exact: true,
    path: REGISTER_IND_CONFIRMATION,
  },
  {
    component: <ListSearchResult />,
    exact: true,
    path: SEARCH(),
  },
  {
    component: <StudentProfile />,
    exact: true,
    path: STUDENT_PROFILE(),
  },
  {
    component: <ECAppWizard />,
    exact: true,
    path: EC_APP_WIZARD(),
  },
  {
    component: (
      <CartContextProvider>
        <Cart />
      </CartContextProvider>
    ),
    exact: true,
    path: CART(),
  },
  {
    component: <ConsignedInventory />,
    exact: true,
    path: CONSIGNED,
  },
  {
    component: <ConsignedInventory />,
    exact: true,
    path: ORDER_CONSIGNED_PAGE(),
  },
  {
    component: <History />,
    exact: true,
    path: HISTORY(),
  },
  {
    component: (
      <CartContextProvider>
        <Cart />
      </CartContextProvider>
    ),
    exact: true,
    path: SAVED(),
  },
  {
    component: (
      <CartContextProvider>
        <Cart />
      </CartContextProvider>
    ),
    exact: true,
    path: ORDER(),
  },
  {
    component: <OrderHistoryDetails />,
    exact: true,
    path: ORDER_HISTORY_DETAILS(),
  },
  {
    component: <RestorePurchases />,
    exact: true,
    path: RESTORE_PURCHASES(),
  },
  {
    component: <ECContractSigning />,
    exact: true,
    path: EC_CONTRACT_SIGNING(),
  },
  {
    // Leave last because of the employee id on the route
    component: <Industrial />,
    exact: true,
    path: STUDENTS(),
  },
  {
    component: <OrderConfirmation />,
    exact: true,
    path: ORDER_CONFIRMATION(),
  },
  {
    component: <Industrial />,
    exact: true,
    path: LOGIN,
  }
];

export default industrialRoutes;
