import React, { useState } from 'react';

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';
import propTypes from '../propTypes';
import useSelectFieldStyles from './useSelectFieldStyles';

function SelectField({ field, control, errors, fieldErrors, ...props }) {
  const selectStyles = useSelectFieldStyles().classes;
  const [displayedLabel, setDisplayedLabel] = useState(
    field.focusedLabel && props.getValues(field.name)
      ? field.focusedLabel
      : field.label
  );

  const getDisplayedLabel = shouldGet => {
    if (!field.focusedLabel) {
      return;
    }
    if (shouldGet) {
      setDisplayedLabel(field.focusedLabel);
    } else {
      setDisplayedLabel(field.label);
    }
  };

  const setSelectValue = value => {
    const index = field.options.findIndex(option => {
      return (
        option.value === value ||
        (option.value.name && option.value.name === value.name)
      );
    });
    return index !== -1 && value !== null && value !== undefined
      ? field.options[index].value
      : '';
  };

  const getDisplayValue = value => {
    return field.options.find(option => {
      return (
        option.value === value ||
        (option.value.name && option.value.name === value.name)
      );
    })?.label;
  };

  return (
    <>
      <FormControl
        variant="outlined"
        size={props.size ? props.size : 'small'}
        error={fieldErrors ? true : false}
        className={classNames(selectStyles.wrapper, {
          [selectStyles.required]: field.validations?.required,
        })}
        data-test-id={`selectField-${field.name}`}
        required={field.validations?.required}
      >
        <InputLabel
          id={`${field.name}-labelId`}
          className={classNames(selectStyles.label)}
        >
          {displayedLabel}
        </InputLabel>
        <Controller
          control={control}
          defaultValue={field.defaultValue ?? ''}
          name={field.name}
          rules={{ ...field.validations }}
          render={({ field: { ref, onChange, value } }) => (
            <Select
              labelId={`${field.name}-labelId`}
              onFocus={() => getDisplayedLabel(true)}
              onBlur={() => getDisplayedLabel(value)}
              label={
                field.label &&
                `${field.label}${field.validations?.required ? ' *' : ''}`
              }
              inputRef={ref}
              onChange={e => {
                onChange(e.target.value);
                if (field.onChangeCallback) {
                  field.onChangeCallback(e.target.value);
                }
              }}
              disabled={field.disabled ?? field.options.length === 0}
              classes={{
                disabled: errors && errors[field.name] && selectStyles.disabled,
                icon: errors && errors[field.name] && selectStyles.icon,
              }}
              inputProps={{
                readOnly: Boolean(field.options.length === 0) || field.readOnly,
                'data-test-id': `selectInput-${field.name}`,
                id: field.name,
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                classes: { paper: selectStyles.paper },
              }}
              SelectDisplayProps={{
                'data-test-id': `select-btn-${field.name}`,
              }}
              value={setSelectValue(value)}
              renderValue={() => getDisplayValue(value)}
            >
              {field.options &&
                field.options.map(option => (
                  <MenuItem
                    data-test-id={`select-option-${field.name}`}
                    key={JSON.stringify(option.value)}
                    value={option.value}
                    id={`option-${option.value}`}
                  >
                    {option.customLabel ?? option.label}
                  </MenuItem>
                ))}
            </Select>
          )}
        />
      </FormControl>
      {fieldErrors?.message && (
        <span
          id="errorMessage"
          className={classNames(selectStyles.errorMessage)}
        >
          {fieldErrors.message}
        </span>
      )}
    </>
  );
}

SelectField.propTypes = propTypes;

export default SelectField;
