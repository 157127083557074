import CartAddProductSection from './CartAddProductSection';
import CartContext from '../context/CartContext';
import CartHeaderSection from './CartHeaderSection';
import CartLineItemsSection from './CartLineItemsSection';
import CartOrderTypeSection from './CartOrderTypeSection';
import CartTotalSection from './CartTotalSection';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { EC_CONTRACT_SIGNING, ORDER_CONFIRMATION } from '../../../../core/navigation/paths';

function CartContent() {
  const navigate = useNavigate();
  const cartCtx = useContext(CartContext);
  const { bp, id } = useParams();
  const { isECOrder } = useSelector(state => state.cart);

  useEffect(() => {
    if (cartCtx.orderStatus.completed && isECOrder) {
      navigate(EC_CONTRACT_SIGNING(bp, id, cartCtx.orderStatus.orderNumber));
    }

    if (cartCtx.orderStatus.completed && !isECOrder) {
      navigate(ORDER_CONFIRMATION(bp, id, cartCtx.orderStatus.orderNumber));
    }
  }, [
    bp,
    cartCtx.orderStatus.completed,
    cartCtx.orderStatus.orderNumber,
    id,
    isECOrder,
    navigate,
  ]);

  return (
    <>
      {!cartCtx.orderStatus.completed && (
        <>
          <CartHeaderSection />
          <CartTotalSection />
          <CartOrderTypeSection />
          <CartAddProductSection />
          <CartLineItemsSection />
        </>
      )}
    </>
  );
}

export default CartContent;
