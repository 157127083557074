import { makeStyles } from 'tss-react/mui';

const useCustomPaginationStyles = makeStyles()(theme => ({
  paginationContainer: {
    position: 'relative',
    marginTop: theme.spacing(3),
  },
  pagination: {
    '& .Mui-selected': {
      backgroundColor: theme.palette.common.secondary1,
      color: theme.palette.common.black,
    },
    '& button': {
      minWidth: '22px',
      height: '22px',
    },
  },
  paginationText: {
    position: 'absolute',
    left: 0,
  },

  studentPaginationContainer: {
    border: `${theme.spacing(0.2)} solid`,
    borderColor: `${theme.palette.grey['200']} !important`,
    padding: theme.spacing(3),
    backgroundColor: 'white',
    borderRadius: theme.spacing(1),
  },

  studentPagination: {
    '& .Mui-selected': {
      backgroundColor: 'red !important',
      color: 'white',
    },
    '& button': {
      margin: theme.spacing(0.5),
      width: `${theme.spacing(5)}`,
      height: `${theme.spacing(5)}`,
    },
  },

  studentPaginationText: { margin: theme.spacing(1, 0, 1, 0) },

  paginationSkeleton: {
    justifyContent: 'space-between',
    padding: theme.spacing(3),
  },

  studentPaginationSkeleton: {
    justifyContent: 'space-between',
  },
}));

export default useCustomPaginationStyles;
