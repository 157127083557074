import { createSlice } from '@reduxjs/toolkit';
import CONSTANTS from '../constants';

const resetSelectedCartItems = state => {
  state.selectedItems.selectedCartItems = [];
  state.selectedItems.allCartItemsSelected = false;
};

const resetSelectedSavedCartItems = state => {
  state.selectedItems.selectedSavedCartItems = [];
  state.selectedItems.allSavedCartItemsSelected = false;
};

const guestCartSlice = createSlice({
  name: CONSTANTS.guestCart,
  initialState: {
    cartLength: 0,
    loadingAddProduct: [],
    studentCartId: null,
    cartSummaryItems: [],
    shouldGetCartSummary: true,
    studentCartSummaryOrderTotal: null,
    cartTotalsWithoutDiscount: 0,
    selectedItems: {
      selectedCartItems: [],
      selectedSavedCartItems: [],
      allCartItemsSelected: false,
      allSavedCartItemsSelected: false,
      isActionBarOpen: false,
    },
    isShoppingCartLoading: false,
    shouldGetCart: true,
    refreshSummary: false,
  },

  reducers: {
    setCartLength: (state, action) => {
      state.cartLength = action.payload;
    },

    setLoadingAddProduct: (state, action) => {
      state.loadingAddProduct = action.payload;
    },

    setStudentCartId: (state, action) => {
      state.studentCartId = action.payload.studentCartId;
    },

    setCartSummaryItems: (state, action) => {
      state.cartSummaryItems = action.payload.cartItems.slice(0, 5);
    },

    setShouldGetCartSummary: (state, action) => {
      state.shouldGetCartSummary = action.payload;
    },

    setCartSummaryOrderTotal: (state, action) => {
      state.studentCartSummaryOrderTotal = action.payload;
    },

    setCartTotalsWithoutDiscount: (state, action) => {
      state.cartTotalsWithoutDiscount = action.payload;
    },

    setSelectedItems: (state, action) => {
      if (action.payload?.isSavedCart) {
        state.selectedItems.selectedSavedCartItems = action.payload?.items;
        resetSelectedCartItems(state);
      } else {
        state.selectedItems.selectedCartItems = action.payload.items;
        resetSelectedSavedCartItems(state);
      }
    },

    setAllItemsSelected: (state, action) => {
      if (action.payload?.isSavedCart) {
        state.selectedItems.allSavedCartItemsSelected = action.payload.checked;
      } else {
        state.selectedItems.allCartItemsSelected = action.payload.checked;
      }
    },

    setIsActionBarOpen: (state, action) => {
      state.selectedItems.isActionBarOpen = action.payload;
    },

    setIsShoppingCartLoading: (state, action) => {
      state.isShoppingCartLoading = action.payload;
    },

    setShouldGetCart: (state, action) => {
      state.shouldGetCart = action.payload;
    },

    resetSelectedItems: state => {
      resetSelectedCartItems(state);
      resetSelectedSavedCartItems(state);
    },

    setRefreshSummary: (state, action) => {
      state.refreshSummary = action.payload;
    },
  },
});

export const {
  setCartLength,
  setLoadingAddProduct,
  setStudentCartId,
  setCartSummaryItems,
  setShouldGetCartSummary,
  setCartSummaryOrderTotal,
  setCartTotalsWithoutDiscount,
  setSelectedItems,
  setAllItemsSelected,
  setIsActionBarOpen,
  resetSelectedItems,
  setIsShoppingCartLoading,
  setShouldGetCart,
  setRefreshSummary,
} = guestCartSlice.actions;
export default guestCartSlice.reducer;
