import CustomIcon from '../../../shared/CustomIcon/CustomIcon';
import { Grid } from '@mui/material';
import React from 'react';
import classNames from 'classnames';
import { propTypes } from './menuEntryConstants';
import { useLocation } from 'react-router-dom';
import useMenuEntryStyles from './useMenuEntryStyles';

function MenuEntry(props) {
  const location = useLocation();
  const css = useMenuEntryStyles().classes;

  const isActiveEntry = () => location.pathname === props.redirectTo;

  const iconClasses = classNames(css.icon, {
    [css.selectedName]: isActiveEntry(),
  });

  const nameClasses = classNames(css.name, {
    [css.selectedName]: isActiveEntry(),
  });

  const containerClasses = classNames(css.container, {
    [css.containerCollapsed]: !props.isExpanded,
    [css.selectedContainer]: isActiveEntry(),
    [css[props.className]]: props.className,
  });

  return (
    <>
      <Grid
        container
        direction={props.isExpanded ? 'row' : 'column'}
        justifyContent={props.isExpanded ? 'flex-start' : 'center'}
        alignItems="center"
        id={`menuEntry_${props.name}`}
        className={classNames(containerClasses)}
        data-test-id="menuEntry-displayed-container"
      >
        <Grid
          item
          xs={props.isExpanded ? 2 : 'auto'}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <CustomIcon icon={props.icon} customClasses={iconClasses} />
        </Grid>
        {props.isExpanded ? (
          <Grid
            item
            data-test-id="menuEntry-displayed-name"
            className={nameClasses}
            xs={9}
          >
            {props.name}
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}

MenuEntry.propTypes = propTypes;

export default MenuEntry;
