import { makeStyles } from 'tss-react/mui';

const useStudentProfileFormStyles = makeStyles()(theme => ({
  lineContainer: {
    counterReset: 'line-number',
  },
  line: {
    counterIncrement: 'line-number',

    '&::after': {
      content: 'counter(line-number)',
    },
  },
  loadingContainer: {
    minHeight: '100px',
    position: 'relative',
    '& >div': {
      backgroundColor: 'inherit',
    },
  },
  mandatoryMessageContainer: {
    marginBottom: theme.spacing(2),
  },
  hide: {
    height: 0,
  },
  speedDialAddresses: {
    bottom: '165px',
  },
  speedDialReferences: {
    bottom: '105px',
  },
  speedDialEmployerInfo: {
    bottom: '225px',
  },
}));

export default useStudentProfileFormStyles;
