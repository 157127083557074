import { makeStyles } from 'tss-react/mui';

const useProductPageStyles = makeStyles()(theme => ({
  productContainer: {
    '& .MuiBreadcrumbs-ol': {
      '& a, p': {
        fontSize: '14px !important',
      },
    },

    '& span': {
      fontWeight: 'bold',
    },

    '& button': {
      width: '100%',
    },

    '& .MuiAccordion-root ': {
      border: `solid ${theme.palette.divider}`,
      borderWidth: '1px 0px',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
    },

    '& .MuiAccordionDetails-root': {
      paddingTop: theme.spacing(0),
      '& p': {
        paddingLeft: theme.spacing(1),
      },
    },
    '@media screen and (max-width: 768px)': {
      maxWidth: '100vw !important',
      overflow: 'hidden',
    },
  },

  addSaveButtons: {
    '#productPage-saveItem': { fontWeight: 'bold' },
  },

  productSkeleton: {
    '& .MuiSkeleton-root': { borderRadius: theme.spacing(2) },
  },

  addSaveButtonsLoading: {
    '& .MuiButtonBase-root': {
      backgroundColor: `${theme.palette.common.grey4} !important`,
      opacity: '1 !important',
      color: `${theme.palette.common.grey2} !important`,
    },
  },

  loadingIcon: {
    color: theme.palette.text.primary,
  },

  specificationsContainer: {
    marginTop: theme.spacing(1),
  },

  specificationsTable: {
    marginBottom: theme.spacing(2),
    '& .MuiGrid-container': {
      paddingBottom: theme.spacing(0.6),
      borderBottom: `1px solid ${theme.palette.divider}`,
      '&:last-child': {
        borderBottom: 0,
      },
    },
  },

  warningImages: {
    '& img': {
      height: theme.spacing(5),
      width: 'unset',
      marginRight: '12px',
    },
  },

  imageDialog: {
    backgroundColor: theme.palette.common.white,
    padding: 0,
    '@media screen and (max-width: 768px)': {
      height: '100%',
      margin: 0,
      padding: 0,
      width: '100%',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '100%',
      overflowX: 'hidden',
      overflowY: 'hidden',
      position: 'absolute',
      top: 0,
      justifyContent: 'center',

      '& #specificationsImageZoomedModal-content': {
        flex: 'unset',
        overflowY: 'unset',
        padding: 0,
        marginBottom: 0,
        height: '90%',
        display: 'flex',
        flexDirection: 'column', 
        justifyContent: 'center',
      },
    },
    '@media screen and (min-width: 768px)': {
      height: '650px',
      minWidth: theme.spacing(120),
  
      '& #specificationsImageZoomedModal-content': {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      },
  
      '& .product-image-container':{
        height: '100%',
        alignItems: 'center',
      },
    },
    '@media screen and (max-width: 960px)': {
      minWidth: '50vw',
      height: '100%',
    },
  },

  videoPlayer: {
    '#productVideoPlayer': {
      position: 'relative',
    },
  },

  videoPlayerButton: {
    position: 'absolute',
    width: 'unset !important',
    bottom: '20px',
    left: '20px',
    color: theme.palette.common.red,
    '& svg': {
      fontSize: '32px',
    },
  },

  specificationsImage: {
    '& img': {
      maxHeight: '450px',
      maxWidth: '450px',

      '@media screen and (max-width: 768px)': {
        maxWidth: '90%',
      }
    },
  },

  productImageContainer: {
    '@media screen and (max-width: 960px)': {
      paddingLeft: '24px',
    },
    '@media screen and (max-width: 768px)': {
      width: 'fit-content',
      paddingLeft: '0',
    },
  },

  zoomButtonContainer: {
    '@media screen and (min-width: 769px)': {
      marginLeft: '10%',
    },
  },

  specificationsImageZooomed: {
    '@media screen and (max-width: 768px)': {
      height: 'unset !important',
    },
    '@media screen and (min-width: 768px)': {
      height: '100%',
      alignItems: 'center',
    },
    '@media screen and (max-width: 960px)': {
      height: '100%',
    },
    '& img': {
      maxHeight: '100%',
      maxWidth: '95%',

      '@media screen and (max-width: 960px)': {
        maxWidth: '60%'
      },
      '@media screen and (max-width: 768px)': {
        maxWidth: '100%',
      },
    },
  },

  setsButtonsContainer: {
    margin: `${theme.spacing(2)} 0 ${theme.spacing(2)} 0`,
  },

  setsButton: {
    fontSize: '16px !important',
    color: `${theme.palette.common.black} !important`,
    padding: '10px 24px !important',
    fontWeight: '700 !important',
    border: `2px solid ${theme.palette.common.black} !important`,
  },

  availabilityContainer: {
    flexDirection: 'column',
    '& .in-stock': {
      color: '#22B14C',
    },

    '& .out-stock': {
      color: theme.palette.common.red,
    },
  },
}));

export default useProductPageStyles;
