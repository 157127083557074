import { Grid, Skeleton } from '@mui/material';
import React from 'react';

function ItemsButtonsSkeleton() {
  return (
    <Grid
      id="ShoppingCart-itemsButtonsSkeleton"
      data-test-id="ShoppingCart-itemsButtonsSkeletonTestId"
      item
      container
      alignItems="flex-end"
      paddingTop={1}
      spacing={1}
      sm={12}
      md={6}
      xl={4}
    >
      <Grid item xs={6}>
        <Skeleton variant="rounded" height={30} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="rounded" height={30} />
      </Grid>
    </Grid>
  );
}

export default ItemsButtonsSkeleton;
