function SnaponLogoSmall(props) {
  return (
    <svg
      xmlSpace="preserve"
      width={54.978}
      height={40}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2622.79 834.051s165.45 259.419 117.33 386.599c-62.45 165.01-214 146.67-214 146.67H1498.05s-48.33-12.05-62.14 50.05c-13.82 62.15 42.01 125.47 42.01 125.47s40.83 67.84 82.26 102.35c41.42 34.51 131.15 34.51 131.15 34.51h1201.21s34.49 6.91 96.62-13.8c38.2-12.73 75.96-69.02 75.96-69.02l338.25 579.87H1822.48s-278.87 2.44-407.27-62.13c-169.6-85.26-214.02-144.98-289.96-262.33-41.93-64.81-89.75-158.77-89.75-158.77l-324.43-545.36s-81.121-108.76-69.043-193.332c6.891-48.308 20.707-89.726 82.817-117.328 62.16-27.602 172.597-20.711 172.597-20.711h434.919s82.85-6.891 117.36 20.711c70.27 56.219 152.99 147.16 220.9 165.69 75.95 20.71 179.93 23.49 255.42-28.26 60.31-41.371 64.63-48.578 112.91-120.258 46.29-68.641 52.77-120.723 52.77-120.723l-186.4 69.02s-64.18 32.48-96.62 20.75c-34.16-12.379-83.89-.547-131.17-131.199-36.21-100.008-32.28-166.801 34.51-200.211 96.66-48.309 276.12-89.727 276.12-89.727s-96.66-89.742-227.77-103.551c-116.74-12.293-222.5 32.481-269.26 82.84-48.28 52.027-26.27 52.027-82.84 89.719-62.13 41.43-186.04 34.871-186.04 34.871H511.176s-27.946-7.223-110.766 20.379c-70.859 23.601-62.129 41.422-62.129 41.422L0 1.872h1615.4s260.19-13.653 414.19 55.507c176.62 79.41 283.06 255.461 283.06 255.461l310.14 521.211M2386.33 159.18v-18.891h-53.57V0h-22v140.289h-53.54v18.891h129.11M2405.53 159.18h31.07l45.83-134.559 45.58 134.559h30.9V0h-20.78v93.93c0 3.27.08 8.652.35 16.152.26 7.508.39 15.59.39 24.137L2492.96 0h-21.44l-45.96 134.219v-4.84c0-3.891.13-9.84.4-17.82.26-8.028.39-13.868.39-17.63V0h-20.82v159.18"
        style={{
          fill: '#ed1c24',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="matrix(.01615 0 0 -.01615 0 35.163)"
      />
    </svg>
  );
}

export default SnaponLogoSmall;
