import { Badge, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import appStrings from '../../../../core/strings/appStrings';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useNavigate } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useStudentAchievementsStyles } from './studentAchievementsStyles';
import classNames from 'classnames';

function AchievementContent({ image, text, goTo, isDisabled, section }) {
  const css = useStudentAchievementsStyles().classes;
  const navigate = useNavigate();

  return (
    <Grid item>
      <CustomBox>
        <Badge
          badgeContent={
            isDisabled ? (
              <LockOutlinedIcon
                fontSize="small"
                className={css.lockIcon}
                id="StudentAchievements-LockIcon"
                data-test-id="StudentAchievementsLockIcon"
              />
            ) : (
              <CheckCircleIcon
                className={css.checkIcon}
                id="StudentAchievements-CheckIcon"
                data-test-id="StudentAchievementsCheckIcon"
              />
            )
          }
        >
          <Grid
            item
            id={`StudentAchievements-${section}`}
            data-test-id={`StudentAchievements-${section}-testId`}
          >
            <img
              src={image}
              alt=""
              className={classNames(css.imageStyle, {
                [css.disabledFilter]: isDisabled,
              })}
            />
            <Typography fontWeight="medium">{text}</Typography>
          </Grid>
        </Badge>
      </CustomBox>
      <Grid item container className={css.purchaseContent}>
        <Button
          disableRipple
          onClick={() => navigate(goTo)}
          id={`StudentAchievements-purchaseButton${section}`}
          data-test-id={`StudentAchievements-purchaseButton${section}-testId`}
        >
          <ShoppingCartOutlinedIcon />
          <Typography fontWeight="bold" fontSize="medium">
            {appStrings.common.purchase}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
}

export default AchievementContent;
