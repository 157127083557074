import CustomBox from '../../../shared/CustomBox/CustomBox';
import { Grid } from '@mui/material';
import { Skeleton } from '@mui/material';
import useConsignedSourceStyle from './useConsignedSourceStyle';

function ConsignedSourceSkeleton() {
  const css = useConsignedSourceStyle().classes;

  return (
    <CustomBox customClass={css.customBox}>
      <Grid
        container
        alignItems="center"
        className={css.container}
        data-test-id="consignedSource"
      >
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Skeleton width={'15rem'} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} className={css.selectInventory}>
          <Skeleton width={'100%'} height={'2rem'} variant="rect" />
        </Grid>
      </Grid>
    </CustomBox>
  );
}

export default ConsignedSourceSkeleton;
