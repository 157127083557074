import { useDispatch } from 'react-redux';
import { buttonTypes } from '../../../core/strings/appConstants';
import appStrings from '../../../core/strings/appStrings';
import CustomDialog from '../../shared/CustomDialog/CustomDialog';
import useUpdateContractDialogStyles from './UpdateContractDialogStyles';
import { deleteContract, updateContract } from './updateContractDialogService';
import CustomBackdrop from '../../shared/CustomBackdrop/CustomBackdrop';
import { useState } from 'react';
import { displayError } from '../../../core/redux/slices/notificationsSlice';
import { resetFilters } from '../../../core/redux/slices/historySlice';
import { useNavigate, useParams } from 'react-router-dom';
import { ORDER } from '../../../core/navigation/paths';

function UpdateContractDialog({
  studentId,
  orderNumber,
  statusText,
  handleClose,
}) {
  const css = useUpdateContractDialogStyles().classes;
  const { id, bp } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const updateMessage = `${appStrings.history.contractRejected} ${
    statusText?.length ? `- ${statusText}` : ''
  }`;
  const updateButtons = [
    {
      label: appStrings.common.cancel,
      onClick: handleClose,
    },
    {
      label: appStrings.history.updateContract,
      btnType: buttonTypes.secondary,
      onClick: () => setIsConfirmation(true),
    },
    {
      label: appStrings.history.deleteContract,
      btnType: buttonTypes.secondary,
      onClick: () => {
        setIsLoading(true);
        deleteContract(studentId, orderNumber)
          .then(() => {
            handleClose();
            dispatch(resetFilters());
            setIsLoading(false);
          })
          .catch(e => {
            dispatch(displayError({ message: e }));
          });
      },
    },
  ];

  const confirmationButtons = [
    {
      label: appStrings.common.yes,
      onClick: () => {
        setIsLoading(true);
        updateContract(studentId, orderNumber)
          .then(() => {
            handleClose();
            navigate(ORDER(bp, id));
            setIsLoading(false);
          })
          .catch(e => {
            dispatch(displayError({ message: e }));
          });
      },
    },
    {
      label: appStrings.common.no,
      btnType: buttonTypes.secondary,
      onClick: handleClose,
    },
  ];

  return (
    <>
      <CustomBackdrop open={isLoading} />
      <CustomDialog
        id="contract-rejected-dialog"
        open={!isLoading}
        showCloseButton
        handleClose={handleClose}
        customClass={css.dialog}
        message={
          isConfirmation
            ? appStrings.history.updateContractConfirmation
            : updateMessage
        }
        title={appStrings.history.updateECContract}
        buttons={isConfirmation ? confirmationButtons : updateButtons}
      />
    </>
  );
}

export default UpdateContractDialog;
