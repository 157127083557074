import React from 'react';
import CustomBox from '../../../../shared/CustomBox/CustomBox';
import { editProfileSectionsConstants } from '../editProfileConstants';
import { Grid, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import SectionLink from '../../Components/SectionLink';
import SectionLinkMobile from '../../Components/SectionLinkMobile';
import useEditProfileStyles from '../editProfileStyles';

function EditProfileSections({ setFormIndex, sectionErrors, formIndex }) {
  const css = useEditProfileStyles().classes;
  const isSmallDevice = useMediaQuery('(max-width:768px)');

  const clickAction = prop => {
    setFormIndex(prop);
  };

  return !isSmallDevice ? (
    <CustomBox>
      <Grid container>
        {editProfileSectionsConstants({ sectionErrors }).map(
          (section, index) => (
            <SectionLink
              key={index}
              section={section}
              onClick={() => clickAction(index)}
              customClass={index === formIndex ? css.activeSectionText : ''}
            />
          )
        )}
      </Grid>
    </CustomBox>
  ) : (
    <SectionLinkMobile
      sections={editProfileSectionsConstants({ sectionErrors })}
      onClick={clickAction}
      sectionErrors={sectionErrors}
      formIndex={formIndex}
    />
  );
}

EditProfileSections.propTypes = {
  setFormIndex: PropTypes.func,
  sectionErrors: PropTypes.object,
  formIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default EditProfileSections;
