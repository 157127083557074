import AddressInfo from './WizardPageContainer/AddressInfo/AddressInfo';
import CustomerInfo from './WizardPageContainer/CustomerInfo/CustomerInfo';
import EmployerInfo from './WizardPageContainer/EmployerInfo/EmployerInfo';
import Validations from './WizardPageContainer/Validations/Validations';
import appStrings from '../../../core/strings/appStrings';

export const wizardSteps = [
  {
    index: 0,
    label: appStrings.wizard.customerInfo,
    component: props => <CustomerInfo {...props} />,
  },
  {
    index: 1,
    label: appStrings.wizard.addressInfo,
    component: props => <AddressInfo {...props} />,
  },
  {
    index: 2,
    label: appStrings.wizard.employerInfo,
    component: props => <EmployerInfo {...props} />,
  },
  {
    index: 3,
    label: appStrings.wizard.validation,
    component: props => <Validations {...props} />,
  },
];

export const wizardStepsStudent = [
  {
    index: 0,
    label: appStrings.studentProfile.myInformation,
    component: props => <CustomerInfo {...props} />,
  },
  ...wizardSteps.slice(1),
];

export const wizardStepsCodes = {
  customerInfo: 0,
  addressInfo: 1,
  employerInfo: 2,
  validation: 3,
};
