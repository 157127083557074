import React, { Fragment, useState } from 'react';
import { profileComponents, propTypes } from './studentProfileFormConstants';

import CustomAccordion from '../CustomAccordion/CustomAccordion';
import CustomAccordionContainer from '../CustomAccordionContainer/CustomAccordionContainer';
import { Divider } from '@mui/material';
import { trackGAPageViews } from '../Analytics/analyticsService';

function StudentProfileForm({
  formResources,
  id,
  setLoading,
  sectionErrors,
  refreshSectionErrors,
  formWasSubmitted,
  emptySections,
  customerInfo,
  setCustomerInfo,
}) {
  const [expanded, setExpanded] = useState('customerInfo');

  trackGAPageViews('/industrial/student');

  const expand = tabId => {
    setExpanded(tabId);
    if (formWasSubmitted) {
      refreshSectionErrors();
    }
  };

  const components = profileComponents({
    ...formResources,
    id,
    setLoading,
    sectionErrors,
    formWasSubmitted,
    emptySections,
    customerInfo,
    setCustomerInfo,
  });

  return (
    <CustomAccordionContainer>
      {components.map((elem, index) => (
        <Fragment key={elem.id}>
          <CustomAccordion
            id={elem.id}
            title={elem.label}
            expanded={expanded}
            hasErrors={elem.hasErrors}
            onExpandChange={expand}
            identifier={elem.id}
          >
            {elem.component}
          </CustomAccordion>
          {index < components.length - 1 ? <Divider /> : null}
        </Fragment>
      ))}
    </CustomAccordionContainer>
  );
}
StudentProfileForm.propTypes = propTypes;

export default StudentProfileForm;
