import React, { forwardRef } from 'react';
import usePrintOrderStyles from './usePrintOrderStyles';
import { Divider, Grid, Typography } from '@mui/material';
import printLogo from '../../../../../core/assets/img/printLogo.jpg';
import appStrings from '../../../../../core/strings/appStrings';
import { formatNumberWithCurrency, formatPhoneNumberUS } from '../../../../../core/services/utilsService';
import moment from 'moment';
import { dateFormat, paymentMethod, paymentMethodLabel, paymentTypeLabel } from '../../../../../core/strings/appConstants';

const PrintOrder = forwardRef(function PrintOrder(props, ref) {
  const css = usePrintOrderStyles().classes;
  const { orderDetails, student } = {...props};

  const formatAddress = addressData => {
    if (!addressData) {
      return '';
    }

    if (addressData.addressLine2?.trim().length) {
      return `${addressData.addressLine1}, ${addressData.addressLine2}, ${addressData.city}, ${addressData.state}, ${addressData.zipCode}`;
    } else {
      return `${addressData.addressLine1}, ${addressData.city}, ${addressData.state}, ${addressData.zipCode}`;
    }
  };

  const calculateDiscount = () => {
    let price = 0;
    orderDetails.lines?.map(lineItem => (price += lineItem.listPrice * (lineItem.quantity ?? 1)));
    if (!price) {
      return 0;
    }

    return price - orderDetails.subtotal;
  };

  const renderLineItem = (lineItem, index, listLength) => {
    return <Grid container item xs={12} key={index}>
      <Grid container item xs={12} className={css.itemRow}>
        <Grid item xs={3}>
            <Typography>{lineItem.description}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>{formatNumberWithCurrency(lineItem.listPrice)}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>{formatNumberWithCurrency(lineItem.studentPrice)}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>{lineItem.productNumber}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography>{lineItem.quantity}</Typography>
          </Grid>
          <Grid item xs={2} className={css.textRight}>
            <Typography>{formatNumberWithCurrency(lineItem.lineTotal)}</Typography>
          </Grid>
      </Grid>
      {Boolean(lineItem.serialNumbers?.length) && <Grid container item xs={12}>
        <Grid className={css.serialNumbers}>
          <Typography>
            {`${appStrings.printDialog.serialNumber}: ${lineItem.serialNumbers.join(', ')}`}
          </Typography>
        </Grid>
      </Grid>}
      {index < listLength - 1 &&
        <Grid container item xs={12} className={css.itemRow}>
          <Divider className={css.divider} />
        </Grid>
      }
    </Grid>;
  };

  const getPaymentName = payment => {
    if (payment.type === paymentMethod.otherPayments) {
      return paymentTypeLabel[payment.paymentType];
    }

    return paymentMethodLabel[payment.type];
  }

  const renderPaymentMethod = (payment, index) => {
    return <Grid container item xs={12} key={index}>
      <Grid container className={css.itemRow}>
        <Grid item xs={6}>{getPaymentName(payment)}</Grid>
        <Grid item xs={6} className={css.textRight}>{formatNumberWithCurrency(payment.amount)}</Grid>
      </Grid>
      {
        payment.type === paymentMethod.card && Boolean(payment.paymentIdentifier) &&
        <Grid container className={css.itemRow}>
          <Grid item xs={6}>{appStrings.printDialog.ccAuthorization}</Grid>
          <Grid item xs={6} className={css.textRight}>{payment.paymentIdentifier}</Grid>
        </Grid>
      }
    </Grid>
  }
  
  const renderHeaderTable = () => {
    return <Grid container item xs={12} className={css.tableContent}>
      <Grid item xs={3}>
        <Typography fontWeight="500">{appStrings.printDialog.description}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography fontWeight="500">{appStrings.printDialog.listPrice}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography fontWeight="500">{appStrings.printDialog.discountPrice}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography fontWeight="500">{appStrings.printDialog.sku}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography fontWeight="500">{appStrings.printDialog.quantity}</Typography>
      </Grid>
      <Grid item xs={2} className={css.textRight}>
        <Typography fontWeight="500">{appStrings.printDialog.total}</Typography>
      </Grid>
    </Grid>;
  };

  const renderTableContent = () => {
    return <Grid container item xs={12} className={css.tableContent}>
      {
        orderDetails.lines?.map((lineItem, index) => renderLineItem(lineItem, index, orderDetails.lines.length))
      }
    </Grid>;
  };

  const renderContactDetails = () => {
    return <Grid container item xs={6} className={css.contactDetailsColumn}>
      <Grid container className={css.itemRow}>
        <Grid item xs={6}>{appStrings.printDialog.date}</Grid>
        <Grid item xs={6} className={css.textRight}>{moment(
          orderDetails?.submittedDate
        ).format(dateFormat)}</Grid>
      </Grid>
      <Grid container className={css.itemRow}>
        <Grid item xs={6}>{appStrings.printDialog.studentName}</Grid>
        <Grid item xs={6} className={css.textRight}>{`${student.firstName} ${student.lastName}`}</Grid>
      </Grid>
      <Grid container className={css.itemRow}>
        <Grid item xs={6}>{appStrings.printDialog.studentPhone}</Grid>
        <Grid item xs={6} className={css.textRight}>
          {formatPhoneNumberUS(student.phoneNumber || student.phone1?.phoneNumber || student.phone2?.phoneNumber)}
        </Grid>
      </Grid>
      <Grid container className={css.itemRow}>
        <Grid item xs={6}>{appStrings.printDialog.schoolName}</Grid>
        <Grid item xs={6} className={css.textRight}>{orderDetails.schoolName}</Grid>
      </Grid>
      <Grid container className={css.itemRow}>
        <Grid item xs={4}>{appStrings.printDialog.salesRep}</Grid>
        <Grid item xs={8} className={`${css.textRight} ${css.repInfo}`}>
          <Typography>{`${orderDetails.salesRep?.name ?? ''},`}</Typography>
          <Typography>{`${formatPhoneNumberUS(orderDetails.salesRep?.phoneNumber) ?? ''},`}</Typography>
          <Typography>{orderDetails.salesRep?.email}</Typography>
        </Grid>
      </Grid>
      <Grid container className={css.itemRow}>
        <Grid item xs={7}>{appStrings.printDialog.customerService}</Grid>
        <Grid item xs={5} className={css.textRight}>{appStrings.printDialog.customerServiceNumber}</Grid>
      </Grid>
      {orderDetails.userAudit?.createdByRole === 'Admin' ? <Grid container className={css.itemRow}>
        <Grid item xs={5}>{appStrings.printDialog.administrator}</Grid>
        <Grid item xs={7} className={css.textRight}>{orderDetails.placedByName}</Grid>
      </Grid> : null}
      <Grid container className={css.itemRow}>
        <Grid item xs={6}>{appStrings.printDialog.shippingAddress}</Grid>
        <Grid item xs={6} className={css.textRight}>{formatAddress(orderDetails.shippingAddress?.address)}</Grid>
      </Grid>
    </Grid>;
  };

  const renderTotalsSection = () => {
    return <Grid container item xs={6} className={css.contactDetailsColumn}>
      <Grid container className={css.itemRow}>
        <Grid item xs={6}>{appStrings.printDialog.subtotal}</Grid>
        <Grid item xs={6} className={css.textRight}>{formatNumberWithCurrency(orderDetails.subtotal)}</Grid>
      </Grid>
      <Grid container className={css.itemRow}>
        <Grid item xs={6}>{appStrings.printDialog.tax}</Grid>
        <Grid item xs={6} className={css.textRight}>
          {orderDetails.tax === null ? appStrings.printDialog.unableToCalculateTax : formatNumberWithCurrency(orderDetails.tax)}
        </Grid>
      </Grid>
      <Grid container className={`${css.itemRow} ${css.totalsBorder}`}>
        <Grid item xs={6}>{appStrings.printDialog.shippingAndHandling}</Grid>
        <Grid item xs={6} className={css.textRight}>{appStrings.printDialog.freeShipping}</Grid>
      </Grid>
      <Grid container className={css.itemRow}>
        <Grid item xs={6}>{appStrings.printDialog.orderTotal}</Grid>
        <Grid item xs={6} className={`${css.textRight} ${css.priceTotal}`}>{formatNumberWithCurrency(orderDetails.total)}</Grid>
      </Grid>
      <Grid container className={`${css.itemRow} ${css.totalsBorder}`}>
        <Grid item xs={6}>{appStrings.printDialog.sepSavings}</Grid>
        <Grid item xs={6} className={`${css.textRight} ${css.savingsTotal}`}>
          {formatNumberWithCurrency(calculateDiscount())}
        </Grid>
      </Grid>
      {
        Boolean(orderDetails.payments?.length) &&
        <>
          <Grid container className={css.itemRow}>
            <Grid item xs={6}>
              <Typography fontWeight="bold">{`${appStrings.printDialog.paymentMethods}:`}</Typography>
            </Grid>
          </Grid>
          {
            orderDetails.payments.map((payment, index) => renderPaymentMethod(payment, index))
          }
        </>
      }
    </Grid>;
  };

  return (
    <Grid container className={css.printContainer} ref={ref}>
      <Grid container className={css.printHeader}>
        <img src={printLogo} alt="Snap-on Logo"/>
        <Typography className={css.printTitle}>{appStrings.printDialog.title}</Typography>
      </Grid>
      <Grid container className={css.printContainer}>
        <Grid item xs={12}>
          <Typography variant="h1" className={css.orderConfirmation}>{appStrings.printDialog.orderConfirmation}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h2" className={css.summaryTitle}>
            {`${appStrings.printDialog.summaryTitle} ${orderDetails.orderNumber}`}
          </Typography>
        </Grid>
        {renderHeaderTable()}
        {renderTableContent()}
        <Grid container item xs={12} className={css.contactDetailsContainer} spacing={2}>
          {renderContactDetails()}
          {renderTotalsSection()}
        </Grid>
      </Grid>
    </Grid>
  )
});

export default PrintOrder;