import React, { useEffect } from 'react';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import { Divider, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import appStrings from '../../../../core/strings/appStrings';
import {
  buttonColors,
  buttonTypes,
} from '../../../../core/strings/appConstants';
import { accountProfileSections } from '../accountConstants';
import { useLocation, useNavigate } from 'react-router-dom';
import { ACCOUNT, EDITPROFILE } from '../../../../core/navigation/paths';
import SectionLink from './SectionLink';
import { useAccountInfoStyles } from '../accountStyles';
import StudentProfilePicture from './StudentProfilePicture';
import classNames from 'classnames';
import { trackGTMEvent } from '../../../shared/Analytics/analyticsService';

function AccountInfo() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const userDetails = useSelector(state => state.login.userDetails);
  const isMyAccountPage = pathname === ACCOUNT;
  const isEditProfile = pathname.indexOf(EDITPROFILE('')) !== -1;
  const css = useAccountInfoStyles(isMyAccountPage).classes;

  useEffect(() => {
    trackGTMEvent('other-pageview', {
      page_type: 'other',
      page_title: document.title,
      orig_ref_url: document.location.href,
    });
  }, []);
  return (
    <CustomBox
      id="studentAccount-accountInfo"
      data-test-id="studentAccount-accountInfoTestId"
    >
      <Grid container rowGap={3}>
        <Grid
          item
          container
          xs={12}
          sm
          className={classNames(css.mainContainer, css.editPictureIcon)}
        >
          <StudentProfilePicture />
          <Grid
            item
            xs={isMyAccountPage ? 'auto' : 12}
            sm
            className={css.textsContainer}
            id="account-welcomeUserContainer"
          >
            {isMyAccountPage && (
              <div id="account-welcomeText">
                <Typography
                  className={css.welcomeText}
                  data-test-id="account-welcomeTestId"
                >
                  {appStrings.common.welcome}
                </Typography>
              </div>
            )}
            <div id="account-usernameText">
              <Typography
                className={css.userNameText}
                data-test-id="account-usernameTestId"
              >
                {userDetails.name}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} className={css.button}>
            {!isEditProfile && !isMyAccountPage && (
              <CustomButton
                btnType={buttonTypes.secondary}
                color={buttonColors.black}
                label={appStrings.common.editProfile}
                fullWidth
                onClick={() => navigate(EDITPROFILE(userDetails.userId))}
                size="large"
                id="accountInfo-button"
                data-test-id="accountInfo-buttonTestId"
              />
            )}
          </Grid>
        </Grid>
        {isMyAccountPage && (
          <>
            <Grid item xs={12}>
              <Divider className={css.accountInfoDivider} />
            </Grid>
            <Grid
              item
              container
              xs={12}
              columnSpacing={6}
              data-test-id="accountInfoLinksTestId"
            >
              {accountProfileSections.map((section, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <SectionLink
                    section={section}
                    onClick={() => navigate(section.to)}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Grid>
    </CustomBox>
  );
}

export default AccountInfo;
