import { makeStyles } from 'tss-react/mui';

const useOrderPaymentStyles = makeStyles()(theme => ({
  paymentContainer: {
    maxWidth: '440px',
    margin: 'auto',
  },
  sectionContainer: {
    padding: '14px 14px 14px 20px',
    marginBottom: '14px',
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerExpanded: {
    marginBottom: '28px',
  },
  paymentTitle: {
    textDecoration: 'underline',
    marginBottom: theme.spacing(2.5),
  },
  sectionTitle: {
    color: theme.palette.common.darkGrey,
  },
  methodIcon: {
    marginRight: theme.spacing(1.5),
    color: theme.palette.common.primary,
    display: 'flex',
    alignItems: 'center',
  },
  otherPaymentsIcon: {
    fontSize: '16px',
  },
  headerLeftContainer: {
    display: 'flex',
  },
  checkbox: {
    padding: 0,
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentInput: {
    color: theme.palette.common.darkGrey,
  },
  fieldsContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    textAlign: 'center',
    '& .MuiFormControl-root': {
      maxWidth: '275px',
    },
  },
  voucherFieldsContainer: {
    '& >div:first-of-type': {
      paddingRight: '15px',
    },
    '& >div:nth-of-type(2)': {
      paddingLeft: '15px',
    },
  },
  otherFieldsContainer: {
    '& >div': {
      marginBottom: '10px',
    },
    '& >div:first-of-type': {
      marginBottom: '30px',
      '& .MuiSelect-root': {
        textAlign: 'left',
      },
    },
  },
  completeOrderContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    '& button': {
      marginLeft: theme.spacing(3),
    },
  },
  redText: {
    color: theme.palette.common.red,
  },
  greenText: {
    color: theme.palette.common.green,
  },
  hidden: {
    display: 'none !important',
  },
  paymentTotal: {
    h3: { fontWeight: 'bold' },
  },
}));

export default useOrderPaymentStyles;
