import { Checkbox, FormControl, FormControlLabel } from '@mui/material';

import { Controller } from 'react-hook-form';
import classNames from 'classnames';
import propTypes from '../propTypes';
import useCheckboxFieldStyles from './useCheckboxFieldStyles';

function CheckboxField({ field, fieldErrors, ...props }) {
  const css = useCheckboxFieldStyles().classes;

  return (
    <FormControl size={field.size || 'small'}>
      <FormControlLabel
        className={classNames(
          css.checkboxField,
          fieldErrors ? css.required : ''
        )}
        label={field.label ?? ''}
        labelPlacement={field.labelPlacement ?? 'end'}
        id={`${field.name}-label`}
        control={
          <Controller
            control={props.control}
            defaultValue={field.defaultValue ?? false}
            name={field.name}
            id={field.name}
            rules={{
              ...field.validations,
            }}
            render={properties => (
              <Checkbox
                className={fieldErrors ? css.required : ''}
                onChange={e => {
                  properties.field.onChange(e.target.checked);
                  if (field.onChangeCallback) {
                    field.onChangeCallback(e.target.checked);
                  }
                }}
                checked={properties.field.value}
                disabled={field.disabled ?? false}
                icon={field.icon}
                checkedIcon={field.checkedIcon}
                id={`${field.name}-checkbox`}
              />
            )}
          />
        }
      />
    </FormControl>
  );
}
CheckboxField.propTypes = propTypes;
export default CheckboxField;
