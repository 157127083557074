import { makeStyles } from 'tss-react/mui';

const addProductStyles = makeStyles()(theme => ({
  form: {
    width: '100%',
    position: 'relative',
  },
  accordionContainer: {
    boxShadow: 'none',
    borderRadius: '4px',
    '& .MuiAccordionSummary-content': {
      marginBottom: '0',
      marginTop: '20px',
    },
    '& .MuiAccordionSummary-root': {
      backgroundColor: 'transparent',
    },
  },
  addProductSkeletonContainer: {
    padding: theme.spacing(2),
    height: '45px',
  },
  addProductSkeleton: {
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    height: '90%',
  },
  skeletonSummary: {
    marginBottom: theme.spacing(4),
  },
  hideContainer: {
    display: 'none',
  },
}));

export default addProductStyles;
