import { makeStyles } from 'tss-react/mui';

export const useStudentOrderConfirmationPageStyles = makeStyles()(theme => ({
  orderConfirmation: {
    padding: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '600px',
    '@media screen and (max-width:768px)': {
      padding: 0,
    }
  },
  container: {
    textAlign: 'center',
  },
  orderConfirmationContainer: {
    padding: '40px',
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    '@media screen and (max-width:768px)': {
      borderRadius: 0,
    }
  },
  goBackBtn: {
    width: 'fit-content !important',
    marginTop: '36px',
    padding: '5px 15px !important',
  },
  checkIcon: {
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: '131px',
    marginBottom: '16px',
    color: theme.palette.common.red,
  },
  label: {
    marginBottom: '24px',
  },
  orderConfirmationDetails: {
    marginTop: '32px',
  },
  buttonsContainer: {
    width: '320px',
    marginLeft: 'auto',
    marginRight: 'auto',
  }
}));