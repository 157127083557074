import { ADMIN, REP } from '../../../core/navigation/roles';
import {
  addItem,
  mergeCartItems,
  setItemsList,
  setTotals,
  updateCartItem,
  updateItemPrice,
} from '../../../core/redux/slices/cartSlice';
import { addToCart, addToOrder } from '../AddProduct/addProductService';
import { cloneDeep, findIndex, uniq } from 'lodash';
import { deleteCartItems, updateTotals } from '../Cart/cartService';
import {
  displayError,
  displaySuccess,
} from '../../../core/redux/slices/notificationsSlice';
import {
  notificationsLimit,
  qtyInput,
  studentPriceInput,
} from './productsListConstants';

import ApiService from '../../shared/Api/apiService';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CustomIcon from '../../shared/CustomIcon/CustomIcon';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LayersRoundedIcon from '@mui/icons-material/LayersRounded';
import { Typography } from '@mui/material';
import { alertTypes } from '../../shared/CustomAlert/customAlertConstants';
import appStrings from '../../../core/strings/appStrings';
import { defaultWarehouse } from '../AddProduct/addProductConstants';
import { getAuthToken } from '../../shared/Authentication/authenticationService';
import { getPurchasePower } from '../PurchasePower/purchasePowerService';
import { icons } from '../../../core/strings/icons';
import { notesIds } from '../../../core/strings/IDs';
import { purchaseTypes } from '../PurchasePower/purchasePowerConstants';
import store from '../../../core/redux/store';
import { updateProductDetails } from '../AddProduct/productDetailsService';
import urls from '../../../core/strings/urls';

const saveEditedItem = itemInfo => {
  return ApiService.put(
    itemInfo.warehouse ? urls.editOrderItem : urls.editItem,
    itemInfo
  );
};

const hasChanges = (formattedCurrentValues, initialValues) => {
  let hasChange = false;
  Object.keys(initialValues).forEach(key => {
    if (
      JSON.stringify(initialValues[key]) !==
      JSON.stringify(formattedCurrentValues[key])
    ) {
      hasChange = true;
    }
  });
  return hasChange;
};

const setFinalNotes = (
  finalNotes,
  currentLnLineNotes,
  currentStudentNotes,
  initialValues
) => {
  // only save notes if they are valid or if they remove an old one
  if (currentLnLineNotes || initialValues.lnLineNotes) {
    finalNotes.push({
      noteType: notesIds.LnLineNote,
      content: currentLnLineNotes,
    });
  }
  if (currentStudentNotes || initialValues.studentNotes) {
    finalNotes.push({
      noteType: notesIds.StudentNote,
      content: currentStudentNotes,
    });
  }
};

const formatCartValues = (product, currentValues, initialValues) => {
  const currentQuantity = Number(
    currentValues['editableQuantity' + product.recordId]
  );
  const finalNotes = [];
  const currentLnLineNotes =
    currentValues[`lnLineNotes${product.recordId}`] || null;
  const currentStudentNotes =
    currentValues[`studentNotes${product.recordId}`] || null;
  setFinalNotes(
    finalNotes,
    currentLnLineNotes,
    currentStudentNotes,
    initialValues
  );
  return {
    quantity: currentQuantity.toString(),
    lnLineNotes: currentLnLineNotes,
    studentNotes: currentStudentNotes,
    finalNotes: finalNotes,
  };
};

const formatOrderValues = (product, currentValues) => {
  return {
    quantity: Number(
      currentValues['editableQuantity' + product.recordId]
    ).toString(),
    studentPrice: Number(
      currentValues[`editableStudentPrice${product.recordId}`]
    ),
    warehouse:
      currentValues[`warehouse${product.recordId}`] || product.warehouse,
    serialNumber: currentValues[`serialNumberValue${product.recordId}`],
    lnLineNotes: currentValues[`lnLineNotes${product.recordId}`] || null,
  };
};

const getNewItemDetails = ({
  studentDetails,
  cartId,
  product,
  formattedCurrentValues,
  replaceItem,
  isOrder,
  initialQty,
}) => {
  return isOrder
    ? {
        recordId: product.recordId,
        studentId: studentDetails.id,
        productNumber: replaceItem || product.productNumber,
        promoPrice:
          formattedCurrentValues.studentPrice !== product.initialStudentPrice
            ? formattedCurrentValues.studentPrice
            : null,
        quantity: formattedCurrentValues.quantity,
        previousQuantity: initialQty,
        warehouse: formattedCurrentValues.warehouse || product.warehouse,
        notes: formattedCurrentValues.lnLineNotes,
        serialNumbers: formattedCurrentValues.serialNumber,
      }
    : {
        studentId: studentDetails.id,
        cartId,
        cartItemId: product.recordId,
        quantity: formattedCurrentValues.quantity,
        previousQuantity: initialQty,
        notes: formattedCurrentValues.finalNotes,
        lnLineNotes: formattedCurrentValues.lnLineNotes ?? null,
        studentNotes: formattedCurrentValues.studentNotes ?? null,
        productNumber: replaceItem || product.productNumber,
      };
};

export const saveItem = async ({
  studentDetails,
  cartId,
  product,
  errorCallback,

  replaceItem,
  isReplaceItem,
  currentValues,
  prodListCtx,
}) => {
  const {
    initialValues,

    reset,
    isSavedItems,
    isOrder,
  } = prodListCtx;
  let formattedCurrentValues;
  if (isOrder) {
    formattedCurrentValues = formatOrderValues(product, currentValues);
  } else {
    formattedCurrentValues = formatCartValues(
      product,
      currentValues,
      initialValues.current
    );
  }

  let saveCompleted;
  const saveCall = new Promise((resolve, reject) => {
    saveCompleted = resolve;
  });
  // only save item if there are changes
  if (
    hasChanges(formattedCurrentValues, initialValues.current) ||
    isReplaceItem
  ) {
    // setTimeout is necessary to allow the leave dialog to be displayed, if the case, and if so then prevent the save action
    setTimeout(() => {
      if (!document.getElementsByClassName('MuiDialog-paper').length) {
        const newItemDetails = getNewItemDetails({
          studentDetails,
          cartId,
          product,
          formattedCurrentValues,
          replaceItem,
          isOrder,
          initialQty: initialValues.current.quantity,
        });

        if (isReplaceItem) {
          return handleReplaceItem(
            product,
            studentDetails.id,
            newItemDetails,
            isSavedItems
          )
            .then(() => {
              handleUpdateTotals();
              saveCompleted(true);
            })
            .catch(() => {
              errorCallback();
              saveCompleted();
            });
        }
        saveEditedItem(newItemDetails)
          .then(savedItem => {
            saveCallback(savedItem, product, studentDetails, isSavedItems);
            saveCompleted(true);
          })
          .catch(() => {
            errorCallback();
            saveCompleted();
          });
      } else {
        saveCompleted();
      }
    }, 300);
  } else {
    reset(currentValues);
    saveCompleted();
  }
  return saveCall;
};

export const deleteNotification = notificationId => {
  return ApiService.put(urls.deleteNotification, { id: notificationId });
};

const saveCallback = (item, initialItem, studentDetails, isSavedItems) => {
  if (getAuthToken()) {
    const products = store.getState().cart.cartInfo.cartDetails?.cartItems;
    const index = getProductIndexFromList(products, initialItem);
    if (index !== -1) {
      if (item.recordId !== initialItem.recordId) {
        store.dispatch(
          mergeCartItems({ oldItemId: initialItem.recordId, newItem: item })
        );
      } else {
        store.dispatch(updateCartItem({ item, index }));
      }
      if (isSavedItems) {
        getPurchasePower(studentDetails.id, purchaseTypes.saved, isSavedItems);
      } else {
        handleUpdateTotals();
      }
    } else {
      store.dispatch(displaySuccess({ message: appStrings.cart.itemSaved }));
    }
  } else {
    store.dispatch(displaySuccess({ message: appStrings.cart.itemSaved }));
  }
};

const handleUpdateTotals = () => {
  updateTotals()
    .then(totals => {
      store.dispatch(setTotals(totals));
      if (totals.taxErrorMessage) {
        store.dispatch(displayError({ message: totals.taxErrorMessage }));
      } else {
        store.dispatch(displaySuccess({ message: appStrings.cart.itemSaved }));
      }
    })
    .catch(() => {
      store.dispatch(displayError());
    });
};

export const saveInitialValues = (expandedProduct, initialValues, isOrder) => {
  if (expandedProduct) {
    let lnLineNotes, studentNotes, specificValues;
    if (isOrder) {
      lnLineNotes = expandedProduct.notes;
      specificValues = {
        warehouse: expandedProduct.warehouse,
        serialNumber: expandedProduct.serialNumbers,
        studentPrice: expandedProduct.studentPrice,
      };
    } else {
      lnLineNotes = expandedProduct.notes?.find(
        elem => elem.noteType === notesIds.LnLineNote
      )?.content;
      studentNotes = expandedProduct.notes?.find(
        elem => elem.noteType === notesIds.StudentNote
      );
      specificValues = {
        studentNotes: studentNotes ? studentNotes.content : null,
      };
    }
    initialValues.current = {
      quantity: expandedProduct.quantity?.toString(),
      lnLineNotes: lnLineNotes ? lnLineNotes : null,
      ...specificValues,
    };
  }
};

export const checkSaveOnBlur = ({
  isDirty,
  triggerSave,
  setExpanded,
  setExpandedProduct,
  editableQuantityRef,
}) => {
  if (isDirty) {
    triggerSave({});
  } else {
    checkCollapseRow({ editableQuantityRef, setExpanded, setExpandedProduct });
  }
};

export const checkCollapseRow = ({
  editableQuantityRef,
  setExpanded,
  setExpandedProduct,
}) => {
  const initialQtyRef = editableQuantityRef.current;
  // check if a new qty input is displayed after the timeout and if not (meaning a new row was not expanded), collapse the current row
  setTimeout(() => {
    if (
      initialQtyRef === editableQuantityRef.current ||
      !editableQuantityRef.current
    ) {
      setExpanded(null);
      setExpandedProduct(null);
    }
  }, 300);
};

export const resetFormOnExpandedChange = ({
  expandedProduct,
  setValue,
  getValues,
  reset,
  isOrder,
}) => {
  if (expandedProduct) {
    setValue(
      qtyInput.name + expandedProduct.recordId,
      expandedProduct.quantity
    );
    if (isOrder) {
      setValue(
        studentPriceInput.name + expandedProduct.recordId,
        expandedProduct.studentPrice
      );
    }
    setTimeout(() => {
      reset(getValues());
    });
  }
};

export const generateNotifications = (products, css) => {
  products.forEach(product => {
    let notifications = [];
    if (product.itemNotifications) {
      notifications = uniq(
        product.itemNotifications.map(notification =>
          notification.messageType.toLowerCase()
        )
      );
    }
    const auditIcon = getAuditIcon(product.userAudit, css);
    product.notifications = (
      <div
        key={`notifications${product.recordId}`}
        className={css.notificationsContainer}
      >
        {auditIcon}
        {notifications.length > notificationsLimit ? (
          <LayersRoundedIcon
            classes={{ root: css.layersIcon }}
            key="layersIcon"
          />
        ) : null}
        {notifications.includes(alertTypes.error) &&
        notifications.length <= notificationsLimit ? (
          <CustomIcon
            icon={icons.error1}
            customClasses={css.errorIcon}
            key="errorIcon"
          />
        ) : null}
        {notifications.includes(alertTypes.warning) &&
        notifications.length <= notificationsLimit ? (
          <ErrorOutlineIcon
            classes={{ root: css.warningIcon }}
            key="warningIcon"
          />
        ) : null}
        {notifications.includes(alertTypes.success) &&
        notifications.length <= notificationsLimit ? (
          <CheckCircleOutlineIcon
            classes={{ root: css.successIcon }}
            key="successIcon"
          />
        ) : null}
        {notifications.includes(alertTypes.info) &&
        notifications.length <= notificationsLimit ? (
          <InfoOutlinedIcon classes={{ root: css.infoIcon }} key="infoIcon" />
        ) : null}
        {product.warehouse &&
        JSON.stringify(product.warehouse) !==
          JSON.stringify(defaultWarehouse.value) ? (
          <span className={css.consignedContainer} key={'consignedContainer'}>
            <CustomIcon
              icon={icons.consigned}
              customClasses={css.consignedIcon}
              key="consignedIcon"
            />
            <Typography key={'consignedCode'}>
              {product.warehouse.code}
            </Typography>
          </span>
        ) : null}
      </div>
    );
  });
};

const getAuditIcon = (userAudit, css) => {
  if (!userAudit) {
    return null;
  }
  if (userAudit.updatedByRole && userAudit.updatedByRole !== REP) {
    return (
      <CustomIcon
        icon={
          userAudit.updatedByRole === ADMIN ? icons.admin : icons.studentHat
        }
        customClasses={`${css.auditIcon} ${css.updatedIcon}`}
        key="updatedIcon"
      />
    );
  }

  if (!userAudit.updatedByRole && userAudit.createdByRole !== REP) {
    return (
      <CustomIcon
        icon={
          userAudit.createdByRole === ADMIN ? icons.admin : icons.studentHat
        }
        customClasses={`${css.auditIcon} ${css.addedIcon}`}
        key="createdIcon"
      />
    );
  }
  return null;
};

export const saveIfValid = async (isDirty, expandedIndex, triggerSave) => {
  let isValid = true;
  if (isDirty && expandedIndex === null) {
    isValid = await triggerSave({});
  }
  return isValid;
};

export const removeIndexesFromLoadingRows = (setRowsLoading, itemIndexes) => {
  if (itemIndexes) {
    setRowsLoading(prevRows => {
      const arrays = [prevRows, itemIndexes];
      return arrays.reduce((source, toRemoveList) =>
        source.filter(sourceItem => !toRemoveList.includes(sourceItem))
      );
    });
  }
};

export const addLoadingForDeletingItems = ({
  deletingItems,
  displayedProducts,
  setRowsLoading,
  previousDeletingItemsIndexes,
}) => {
  const deletingIndexList = [];
  deletingItems.forEach(product => {
    const productIndex = findIndex(
      displayedProducts,
      item => item.recordId === product
    );
    if (productIndex !== -1) {
      deletingIndexList.push(productIndex);
    }
  });
  if (deletingIndexList.length) {
    previousDeletingItemsIndexes.current =
      [...(previousDeletingItemsIndexes.current ?? []), ...deletingIndexList];
    setRowsLoading(prevRows => [
      ...new Set([...prevRows, ...deletingIndexList]),
    ]);
  } else {
    removeIndexesFromLoadingRows(
      setRowsLoading,
      previousDeletingItemsIndexes.current
    );
    previousDeletingItemsIndexes.current = null;
  }
};

export const addLoadingForAddingItem = ({
  displayedProducts,
  addingProduct,
  previousAddingProductIndex,
  setRowsLoading,
}) => {
  const productIndex = getProductIndexFromList(
    displayedProducts,
    addingProduct
  );
  if (productIndex !== -1) {
    // remove loading from previous row, in case product was moved to another index due to notifications
    removeIndexesFromLoadingRows(setRowsLoading, [
      previousAddingProductIndex.current,
    ]);
    previousAddingProductIndex.current = productIndex;
    setRowsLoading(prevRows => [...new Set([...prevRows, productIndex])]);
  } else {
    removeIndexesFromLoadingRows(setRowsLoading, [
      previousAddingProductIndex.current,
    ]);
  }
};

const handleReplaceItem = (
  product,
  studentId,
  newItemDetails,
  isSavedItems
) => {
  const { cartId } = store.getState().cart.cartInfo.cartDetails;

  return removeItem(studentId, product, cartId).then(() => {
    const { cartDetails } = store.getState().cart.cartInfo;
    const addItemCall = cartId
      ? addToCart(studentId, newItemDetails, cartDetails, isSavedItems)
      : addToOrder(studentId, newItemDetails);

    return addItemCall.then(item => {
      if (item) {
        store.dispatch(addItem(item));
        const { cartItems } = store.getState().cart.cartInfo.cartDetails;

        const newCartItems = cloneDeep(cartItems);
        const itemIndex = newCartItems.findIndex(prod => {
          return prod.recordId === product.recordId;
        });
        newCartItems.splice(itemIndex, 1);
        store.dispatch(setItemsList(newCartItems));
      }
      return Promise.resolve();
    });
  });
};

const removeItem = (studentId, product, cartId) => {
  const itemToRemove = {
    studentId: studentId,
    cartId: cartId,
    cartItemIds: [product.recordId],
  };

  return deleteCartItems(itemToRemove);
};

export const createExpandedFormFields = ({
  bp,
  createDetailsView,
  createQtyFormInput,
  createStudentPriceFormInput,
  index,
  isOrder,
  productsToEdit,
  setValue,
  studentDetails,
}) => {
  const newProducts = cloneDeep(productsToEdit);
  newProducts[index].expandedContent = createDetailsView(newProducts[index]);
  if (studentDetails.active && productsToEdit[index].lineTotal !== '-') {
    newProducts[index].quantity = createQtyFormInput({
      ...qtyInput,
      name: qtyInput.name + newProducts[index].recordId,
      onChangeCallback: newQuantity => {
        handleProductsListQuantityChange(
          newQuantity,
          productsToEdit,
          index,
          bp,
          setValue,
          isOrder
        );
      },
    });
    if (isOrder) {
      newProducts[index].studentPrice = createStudentPriceFormInput({
        ...studentPriceInput,
        name: studentPriceInput.name + newProducts[index].recordId,
      });
    }
  }
  return newProducts;
};

export const getProductIndexFromList = (list, product) => {
  return findIndex(
    list,
    item =>
      item.productNumber.toLowerCase() ===
        product.productNumber.toLowerCase() &&
      JSON.stringify(item.warehouse) === JSON.stringify(product.warehouse) &&
      (item.recordId === product.recordId || !product.recordId)
  );
};

export const restorePrice = (studentId, itemId) => {
  return ApiService.put(urls.restorePrice, {
    studentId,
    itemId,
  });
};

export const handleRestorePrice = ({
  isDirty,
  id,
  product,
  setValue,
  reset,
  getValues,
  triggerSave,
}) => {
  return restorePrice(id, product.recordId).then(newPrices => {
    setValue(`editableStudentPrice${product.recordId}`, newPrices.netPrice);
    store.dispatch(
      updateItemPrice({
        product: {
          productNumber: product.productNumber,
          warehouse: product.warehouse,
        },
        newPrices,
      })
    );
    triggerSave({ restorePrices: newPrices });
    setTimeout(() => {
      reset(getValues(), {
        isDirty: isDirty,
        dirtyFields: isDirty,
      });
    });
  });
};

export const removeItemFromNotification = async ({
  product,
  isSavedItems,
  id,
  dispatch,
  removeLoadingRows,
}) => {
  const cartInfo = store.getState().cart.cartInfo;
  const cartItems = cloneDeep(cartInfo.cartDetails.cartItems);
  const itemIndex = cartItems.findIndex(item => {
    return item.recordId === product.recordId;
  });
  const oldItem = cartItems[itemIndex];
  cartItems.splice(itemIndex, 1);

  const itemToRemove = {
    studentId: id,
    cartId: cartInfo.cartDetails.cartId,
    cartItemIds: [product.recordId],
  };

  return deleteCartItems(itemToRemove)
    .then(() => {
      dispatch(setItemsList(cartItems));
      removeLoadingRows();
      if (isSavedItems) {
        getPurchasePower(id, purchaseTypes.saved);
      } else {
        updateTotals()
          .then(totals => {
            handleTotals(totals, dispatch);
          })
          .catch(() => {
            dispatch(displayError());
          });
      }
    })
    .catch(() => {
      dispatch(addItem(oldItem));
      dispatch(displayError());
    });
};

const handleTotals = (totals, dispatch) => {
  dispatch(setTotals(totals));
  if (totals.taxErrorMessage) {
    dispatch(displayError({ message: totals.taxErrorMessage }));
  }
};

export const checkProductsForErrors = ({
  products,
  isOrder,
  setSectionErrors,
}) => {
  if (isOrder) {
    if (
      products?.length > 0 &&
      products[0].itemNotifications?.length > 0 &&
      products[0].itemNotifications[0].messageType === 'Error'
    ) {
      setSectionErrors(prev => ({ ...prev, lineItems: true }));
    } else {
      setSectionErrors(prev => ({ ...prev, lineItems: false }));
    }
  }
};

export const handleProductsListQuantityChange = (
  newQuantity,
  productsToEdit,
  index,
  bp,
  setValue,
  isOrder
) => {
  const expandedProduct = productsToEdit[index];
  if (
    !expandedProduct.promoPrice &&
    newQuantity &&
    Number(newQuantity) !== expandedProduct.quantity &&
    newQuantity > 0
  ) {
    updateProductDetails(expandedProduct.productNumber, bp, {
      quantity: newQuantity,
      listPrice: expandedProduct.listPrice,
    }).then(response => {
      if (!isOrder) {
        setValue(
          `editableStudentPrice${expandedProduct.recordId}`,
          response.itemPriceDto.netPrice
        );
      }
    });
  }
};

export const formatNonLnProducts = products => {
  products.forEach(product => {
    if (!product.description && product.listPrice === 0) {
      product.description = '-';
      product.quantity = '-';
      product.listPrice = '-';
      product.studentPrice = '-';
      product.lineTotal = '-';
    }
  });
};

export const getProductImages = productNumber => {
  return ApiService.get(urls.getProductPictures(productNumber));
};
