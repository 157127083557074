import appStrings from '../../../core/strings/appStrings';

export const gridLayout = {
  xs: 12,
};

export const loginInputs = (disableSignIn, setDisableSignIn, getValues) => {
  const onChangeHandler = () => {
    const loginValues = getValues();

    if (
      (!loginValues.accountIdentifier.length || !loginValues.password.length) &&
      !disableSignIn
    ) {
      return setDisableSignIn(true);
    }

    if (
      loginValues.accountIdentifier.length &&
      loginValues.password.length &&
      disableSignIn
    ) {
      setDisableSignIn(false);
    }
  };

  return [
    {
      name: 'accountIdentifier',
      label: appStrings.login.studentEmailOrId,
      type: 'text',
      validations: {
        required: true,
        maxLength: 128,
      },
      gridLayout,
      onChangeCallback: onChangeHandler,
    },
    {
      name: 'password',
      label: appStrings.register.password,
      type: 'password',
      validations: {
        required: true,
      },
      gridLayout,
      autocomplete: 'on',
      onChangeCallback: onChangeHandler,
    },
  ];
};
