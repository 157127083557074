import {
  displayError,
  displaySuccess,
} from '../../../../core/redux/slices/notificationsSlice';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CartContext from '../context/CartContext';
import { Grid } from '@mui/material';
import NameCard from '../../NameCard/NameCard';
import appStrings from '../../../../core/strings/appStrings';
import {
  buttonColors,
  buttonTypes,
  connectionResponseState,
} from '../../../../core/strings/appConstants';
import { cartTypes, getCartType, menuItems } from '../cartConstants';
import {
  resetCartInfo,
  setN90Errors,
  updateUploadInProgress,
} from '../../../../core/redux/slices/cartSlice';
import { useNavigate } from 'react-router-dom';
import CustomDialog from '../../../shared/CustomDialog/CustomDialog';
import CustomIcon from '../../../shared/CustomIcon/CustomIcon';
import { icons } from '../../../../core/strings/icons';
import { deleteOrder } from '../cartService';
import { checkSaveAndCompleteOrder } from '../../OrderPayment/orderPaymentService';
import ErrorDialog from '../../ErrorDialog/ErrorDialog';

function CartHeaderSection() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartCtx = useContext(CartContext);
  const importRef = useRef(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [validationCallback, setValidationCallback] = useState();

  const {
    isInactiveStudent,
    isOrder,
    isSavedItems,
    id,
    bp,
    isPdfExportDisabled,
    cartResetDone,
    setCompleteOrderClicked,
    setExpandedSection,
    sectionErrors,
  } = cartCtx;
  const cartType = getCartType(isOrder, isSavedItems);
  const accountIdentifier = useSelector(
    state => state.login.userDetails.accountIdentifier
  );
  const {
    orderInfo,
    ecContract,
    uploadStatus,
    uploadErrors,
    isECOrder,
    downPaymentLoading,
    hasNoAvailableCredit,
    selectedPromotion,
    ecContractLoading,
  } = useSelector(state => state.cart);

  const toggleUploadInProgressState = state => {
    dispatch(updateUploadInProgress(state));
  };
  const callbackOnUploadStart = () => {
    toggleUploadInProgressState(true);
  };
  const callbackOnUploadError = err => {
    dispatch(displayError({ message: err }));
    toggleUploadInProgressState(false);
  };

  const handleDeleteConfirmation = () => {
    setDeleteOpen(false);
    deleteOrder(id, cartType)
      .then(res => {
        if (res) {
          dispatch(resetCartInfo());
          setExpandedSection('');
          cartResetDone.current = true;
        }
      })
      .catch(err => {
        dispatch(displayError({ message: err }));
      });
  };

  const handleClose = () => {
    setDeleteOpen(false);
  };

  const handleCompleteOrder = () => {
    checkSaveAndCompleteOrder({
      setCompleteOrderClicked,
      sectionErrors,
      dispatch,
      orderInfo,
      isECOrder,
      selectedPromotion,
      handleN90Errors,
    });
  };

  const handleN90Errors = (errors, callback) => {
    dispatch(setN90Errors(errors));
    setValidationCallback(() => () => callback());
    setShowErrorDialog(true);
  };

  const deleteButtons = [
    {
      btnType: buttonTypes.secondary,
      label: appStrings.common.cancel,
      onClick: handleClose,
    },
    {
      btnType: buttonTypes.primary,
      color: buttonColors.red,
      label: appStrings.common.delete,
      onClick: handleDeleteConfirmation,
    },
  ];

  const uploadDetails = {
    cartDetails: {
      studentId: id,
      businessPartner: bp,
      userId: accountIdentifier,
    },
    callbackOnUploadStart,
    callbackOnUploadError,
  };

  const downloadCompleteHandler = () => {
    dispatch(
      displaySuccess({ message: appStrings.cart.downloadedUploadTemplateMsg })
    );
  };

  const downloadErrorHandler = err => {
    dispatch(displayError({ message: err }));
  };

  const getDeleteTitle = () => {
    switch (cartType) {
      case cartTypes.order:
        return appStrings.order.sureDeleteOrder;
      case cartTypes.cart:
        return appStrings.order.sureDeleteCart;
      default:
        return appStrings.order.sureDeleteSaved;
    }
  };

  const downloadDetails = { downloadCompleteHandler, downloadErrorHandler };

  useEffect(() => {
    // upload successful
    if (
      uploadStatus?.message?.actionMessage &&
      uploadStatus?.state === connectionResponseState.done
    ) {
      dispatch(displaySuccess({ message: uploadStatus.message.actionMessage }));
      dispatch(updateUploadInProgress(false));
    }
  }, [dispatch, uploadStatus]);

  useEffect(() => {
    // upload errors
    if (uploadErrors?.length) {
      dispatch(displayError({ message: uploadErrors }));
      dispatch(updateUploadInProgress(false));
    }
  }, [dispatch, uploadErrors]);

  return (
    <Grid item xs={12}>
      <NameCard
        student={cartCtx.student}
        showMoreMenu={!cartCtx.isSavedItems}
        showDeleteButton
        showCompleteOrderButton={!cartCtx.isSavedItems && isOrder}
        completeOrderButtonDisabled={
          isECOrder && (downPaymentLoading || hasNoAvailableCredit)
        }
        completeOrderTooltip={
          isECOrder && hasNoAvailableCredit
            ? appStrings.order.noAvailableCredit
            : ''
        }
        deleteCallback={() => {
          setDeleteOpen(true);
        }}
        completeOrderCallback={handleCompleteOrder}
        disabled={
          cartCtx.rowsLoading.length > 0 ||
          cartCtx.completingOrder ||
          ecContractLoading
        }
        moreMenuItems={menuItems({
          isInactiveStudent,
          importRef,
          uploadCartDetails: uploadDetails,
          downloadHandlers: downloadDetails,
          studentId: id,
          isOrder,
          isECOrder,
          ecContract,
          isPdfExportDisabled,
          navigate,
          bp,
          id,
        })}
      />
      <CustomDialog
        open={deleteOpen}
        handleClose={handleClose}
        icon={<CustomIcon icon={icons.trash} />}
        title={getDeleteTitle()}
        buttons={deleteButtons}
      />
      {showErrorDialog && (
        <ErrorDialog
          callback={validationCallback}
          handleClose={() => setShowErrorDialog(false)}
        />
      )}
    </Grid>
  );
}

export default CartHeaderSection;
