import React from 'react';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import ItemsButtons from './ItemsButtons';
import { buttonTypes } from '../../../../core/strings/appConstants';
import appStrings from '../../../../core/strings/appStrings';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import useShoppingCartStyles from '../shoppingCartStyles';
import { useDispatch } from 'react-redux';
import { resetSelectedItems } from '../../../../core/redux/slices/guestCartSlice';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTheme } from '@emotion/react';

function FloatingActionBar({
  isSavedCart,
  selectedItems,
  handleMoveItems,
  handleDeleteItems,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isXsDevice = useMediaQuery(theme.breakpoints.only('xs'));
  const styleParams = {
    isSmallDevice: isXsDevice,
    isCartSummary: false,
  };
  const css = useShoppingCartStyles(styleParams).classes;

  const handleUnselectAll = () => {
    dispatch(resetSelectedItems());
  };

  return (
    <CustomBox
      customClass={css.floatingActions}
      data-test-id="floatingActionsCart"
    >
      <Grid container item xs={isXsDevice ? 12 : 5} sm={5} alignItems="center">
        <Grid item xs={12} alignItems="flex-start">
          <ItemsButtons
            source="actionBar"
            xs={6}
            isSavedCart={isSavedCart}
            handleDeleteFromCart={handleDeleteItems}
            handleMoveItems={handleMoveItems}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={isXsDevice ? 12 : 7}
        sm={7}
        alignItems="center"
        justifyContent="space-between"
        className={classNames({ [css.horizontalSeparator]: isXsDevice })}
      >
        <Grid item>
          <Typography
            className={isXsDevice ? css.itemsCountMobile : css.itemsCount}
          >
            {`${selectedItems?.length} ${appStrings.common.itemsSelected}`}
          </Typography>
        </Grid>

        <Grid item>
          <CustomButton
            customClass={classNames({ [css.unselectAll]: isXsDevice })}
            btnType={buttonTypes.tertiary}
            label={appStrings.cart.unselectAll}
            onClick={handleUnselectAll}
          />
        </Grid>
      </Grid>
    </CustomBox>
  );
}

FloatingActionBar.propTypes = {
  isSavedCart: PropTypes.bool,
  handleMoveItems: PropTypes.func,
  handleDeleteItems: PropTypes.func,
  selectedItems: PropTypes.array,
};

export default FloatingActionBar;
