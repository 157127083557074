import { Backdrop, Grid, Popover } from '@mui/material';
import {
  buttonColors,
  buttonTypes,
  studentsApprovalState,
} from '../../../../core/strings/appConstants';
import {
  columns,
  itemsPerPage,
  propTypes,
} from './filteredStudentsListConstants';
import {
  formatStudents,
  getCheckedStudentsStatuses,
  handleStatusUpdateForApproveReject,
} from './filteredStudentsListService';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomButton from '../../../shared/CustomButton/CustomButton';
import CustomPagination from '../../../shared/CustomPagination/CustomPagination';
import CustomTable from '../../../shared/CustomTable/CustomTable';
import SearchIcon from '@mui/icons-material/Search';
import appStrings from '../../../../core/strings/appStrings';
import { cloneDeep } from 'lodash';
import { getDataToDisplayFromTo } from '../../../shared/CustomPagination/customPaginationService';
import { selectedStudent } from '../../../../core/redux/slices/schoolsWithStudentsSlice';
import { updateCheckedSearchResults } from '../../../../core/redux/slices/studentSearchSlice';
import useFilteredStudentsListStyles from './useFilteredStudentsListStyles';
import FilteredStudentsListDialog from './FilteredStudentsListDialog';

function FilteredStudentsList({ students, approveReject = false }) {
  const css = useFilteredStudentsListStyles().classes;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { checkedSearchResults } = useSelector(state => state.studentSearch);
  const checkedStudent = useSelector(
    state => state.schoolsWithStudents.selectedStudent.student
  );
  const [checkedStudentsIndex, setCheckedStudentsIndex] = useState([]);
  const [allStudents, setAllStudents] = useState(
    formatStudents(students, css, approveReject)
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [displayedStudents, setDisplayedStudents] = useState(
    allStudents.slice(0, itemsPerPage)
  );
  const [paginationDetails, setPaginationDetails] = useState({
    from: 0,
    to: itemsPerPage,
    itemsPerPage: itemsPerPage,
    page: 1,
  });
  const myRef = useRef();
  const [dialogData, setDialogData] = useState({
    open: false,
    title: '',
    action: null,
    continueButtonLabel: '',
  });
  const [checkedStudentsStatuses, setCheckedStudentsStatuses] = useState(null);

  const scrollElementIntoView = () =>
    myRef.current?.scrollIntoView ? myRef.current.scrollIntoView() : null;

  const handlePageChange = useCallback(
    ({ from, to, page, itemsPerPage }) => {
      setPaginationDetails({ from, to, page, itemsPerPage });
      const newDisplayedItems = [
        ...getDataToDisplayFromTo(allStudents, from, to),
      ];
      newDisplayedItems.forEach((std, index) => {
        const student = { ...std };
        student.selected =
          checkedStudent?.id === student.id ||
          checkedSearchResults?.includes(student.id);
        newDisplayedItems[index] = student;
      });
      setDisplayedStudents(newDisplayedItems);
      scrollElementIntoView();
    },
    [allStudents, checkedSearchResults, checkedStudent?.id]
  );

  const handleClickStudent = index => {
    if (!approveReject) {
      dispatch(
        selectedStudent({
          bpNumber: displayedStudents[index].bpNumber,
          student: displayedStudents[index],
        })
      );
      const studentsCopy = cloneDeep(displayedStudents);
      const formattedStudents = studentsCopy.map(student => {
        student.selected = !approveReject
          ? student.id === displayedStudents[index].id
          : checkedSearchResults?.includes(student.id);

        return student;
      });
      setDisplayedStudents(formattedStudents);
    }
  };

  const handleShowTips = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseTips = () => {
    setAnchorEl(null);
  };

  const selectStudents = index => {
    if (approveReject) {
      dispatch(updateCheckedSearchResults({ id: allStudents[index].id }));
    }
  };

  const selectAllStudents = () => {
    if (allStudents.length === checkedSearchResults?.length) {
      dispatch(
        updateCheckedSearchResults({
          reset: true,
        })
      );
    } else {
      dispatch(
        updateCheckedSearchResults({
          checkedList: allStudents.map(std => std.id),
        })
      );
    }
  };

  const handleStatusUpdate = (status = studentsApprovalState.approved) => {
    setDialogData(prev => ({ ...prev, open: false }));
    handleStatusUpdateForApproveReject(
      checkedSearchResults,
      setIsLoading,
      status
    );
  };

  const handleOpenDialog = (rejectAll = false) => {
    if (!rejectAll) {
      setCheckedStudentsStatuses(
        getCheckedStudentsStatuses(allStudents, checkedSearchResults)
      );
      setDialogData({
        open: true,
        title: appStrings.approveReject.dialogMessage('approve'),
        action: () => handleStatusUpdate(),
        continueButtonLabel: appStrings.approveReject.approveAll,
      });
    } else {
      setCheckedStudentsStatuses(
        getCheckedStudentsStatuses(allStudents, checkedSearchResults)
      );
      setDialogData({
        open: true,
        title: appStrings.approveReject.dialogMessage('reject'),
        action: () => handleStatusUpdate(studentsApprovalState.rejected),
        continueButtonLabel: appStrings.approveReject.rejectAll,
      });
    }
  };

  useEffect(() => {
    const newStudentsList = formatStudents(students, css, approveReject);
    setAllStudents(newStudentsList);
    dispatch(updateCheckedSearchResults({ reset: true }));
    setDisplayedStudents(newStudentsList.slice(0, itemsPerPage));
  }, [approveReject, css, dispatch, students]);

  useEffect(() => {
    setDisplayedStudents(prev =>
      prev.map(std => {
        std.selected = checkedSearchResults?.includes(std.id);
        return std;
      })
    );
  }, [checkedSearchResults]);

  useEffect(() => {
    if (isLoading) {
      const newChecked = [];
      displayedStudents.forEach((student, index) => {
        if (checkedSearchResults?.includes(student.id)) {
          newChecked.push(index);
        }
      });
      setCheckedStudentsIndex(newChecked);
    } else {
      setCheckedStudentsIndex([]);
    }
  }, [checkedSearchResults, displayedStudents, isLoading]);

  return (
    <Grid container ref={myRef}>
      <FilteredStudentsListDialog
        dialogData={dialogData}
        setDialogData={setDialogData}
        checkedStudentsStatuses={checkedStudentsStatuses}
      />
      {approveReject ? (
        <Grid
          container
          item
          xs={12}
          justifyContent="flex-end"
          alignItems="center"
          className={css.actionButtonsContainer}
        >
          <Grid item>
            <CustomButton
              label={appStrings.approveReject.approveSelected}
              btnType={buttonTypes.secondary}
              id="approveSelected"
              onClick={() => handleOpenDialog()}
              disabled={!Boolean(checkedSearchResults?.length)}
              customClass={css.actionButtons}
            />
          </Grid>
          <Grid item>
            <CustomButton
              label={appStrings.approveReject.rejectSelected}
              color={buttonColors.red}
              btnType={buttonTypes.secondary}
              id="rejectSelected"
              onClick={() => handleOpenDialog(true)}
              disabled={!Boolean(checkedSearchResults?.length)}
            />
          </Grid>
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <CustomTable
          rowData={displayedStudents}
          rowCount={allStudents.length}
          columns={columns(approveReject)}
          itemsPerPage={itemsPerPage}
          currentPage={paginationDetails.page}
          onRowClick={handleClickStudent}
          useCheckbox={approveReject}
          onSelectRow={selectStudents}
          onSelectAll={selectAllStudents}
          selectedCount={checkedSearchResults?.length}
          rowsLoading={approveReject && isLoading ? checkedStudentsIndex : null}
          tableId={'filteredStudents'}
        />
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={6} className={css.paginationContainer}>
          <CustomPagination
            itemsLength={students.length}
            itemsPerPage={paginationDetails.itemsPerPage}
            onPageChangeCallback={handlePageChange}
          />
        </Grid>
        <Grid
          container
          item
          xs={6}
          className={css.tipsContainer}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid item>
            <SearchIcon className={css.searchIcon} />
          </Grid>
          <Grid item className={css.tipsBtnContainer}>
            <CustomButton
              btnType={buttonTypes.tertiary}
              label={appStrings.searchInputs.searchTips}
              onClick={handleShowTips}
              color={buttonColors.secondary}
            />
          </Grid>

          <Backdrop
            open={Boolean(anchorEl)}
            style={{ zIndex: Boolean(anchorEl) && '1' }}
          >
            <Popover
              id="helpPopover"
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleCloseTips}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              classes={{ paper: css.popOverRoot }}
            >
              <Grid data-test-id="helpPopoverChildren">
                <h2>{appStrings.searchInputs.searchTips}</h2>
                {appStrings.searchInputs.searchTipsList.map((message, i) => (
                  <p key={`searchTips-${i}`}>{message}</p>
                ))}
              </Grid>
            </Popover>
          </Backdrop>
        </Grid>
      </Grid>
    </Grid>
  );
}

FilteredStudentsList.propTypes = propTypes;

export default FilteredStudentsList;
