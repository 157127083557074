import { makeStyles } from 'tss-react/mui';

const useListSearchResultStyles = makeStyles()(theme => ({
  noDataContainer: {
    margin: `${theme.spacing(16)} ${theme.spacing(10)} 0`,
    background: theme.palette.common.neutral4,
    padding: `${theme.spacing(4)} ${theme.spacing(12)} ${theme.spacing(8)}`,
    '& h2': {
      margin: `${theme.spacing(6)} 0`,
    },
    '& > div > div:first-of-type span': {
      fontSize: '50px',
    },
  },
  tipsContainer: {
    fontSize: theme.typography.fontSize,
    '& .MuiTypography-body1': {
      fontSize: theme.typography.fontSize,
    },
  },
  tipsText: {
    marginLeft: theme.spacing(1),
    display: 'inline-block',
  },
  tipsList: {
    margin: `${theme.spacing(2)} 0 ${theme.spacing(2.5)} ${theme.spacing(1)}`,
  },
  blackText: {
    color: theme.palette.common.black,
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      opacity: 0.8,
      textDecoration: 'none',
    },
  },
  studentsTableContainer: {
    marginTop: theme.spacing(3),
  },
}));

export default useListSearchResultStyles;
