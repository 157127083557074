import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import appStrings from '../../../../core/strings/appStrings';
import {
  buttonTypes,
  dateFormat,
  historyTransactionType,
  orderStatusesByName,
} from '../../../../core/strings/appConstants';
import { useOrdersStyles } from './ordersStyles';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import { handleECAppPDFDownload } from '../../../industrial/History/historyService';
import moment from 'moment';
import { useTheme } from '@emotion/react';
import { formatNumberWithCurrency } from '../../../../core/services/utilsService';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { ACCOUNTORDERDETAILS } from '../../../../core/navigation/paths';

function OrderHeader({ data, trackBtn, index }) {
  const css = useOrdersStyles().classes;
  const theme = useTheme();
  const isXsDevice = useMediaQuery(theme.breakpoints.only('xs'));
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isOrderDetails = pathname.indexOf('orderDetails') !== -1;
  const { userId } = useSelector(state => state.login.userDetails);

  const detailsButton = (
    <CustomButton
      id={`studentOrders-detailsButton_${index}`}
      data-test-id="studentOrders-detailsTestId"
      label={
        data.type !== historyTransactionType.ecApplication
          ? appStrings.history.viewOrderDetails
          : appStrings.history.viewEcApp
      }
      btnType={buttonTypes.tertiary}
      customClass={css.viewDetailsStyle}
      onClick={() => {
        return data.type !== historyTransactionType.ecApplication
          ? navigate(ACCOUNTORDERDETAILS(data.number))
          : handleECAppPDFDownload(userId, data.number);
      }}
    />
  );

  return (
    <Grid
      container
      columnSpacing={2}
      paddingBottom={isOrderDetails ? 2 : 0}
      alignItems="center"
      id={`studentOrder-Header_${index}`}
      justifyContent="space-between"
      data-test-id="studentOrder-HeaderTestId"
      className={css.orderContainer}
    >
      <Grid item xs={'auto'} className={css.detailsStyle}>
        <div id={`orderNumber_${index}`}>
          <Typography fontSize="medium">
            {appStrings.orderDetailsHistory.noOrder}
            <strong>{data.number}</strong>
          </Typography>
        </div>
        {!isOrderDetails && (
          <div id={`orderPacedByAndDate_${index}`}>
            <Typography>
              {appStrings.orderDetailsHistory.placedBy}
              <strong>{data.placedBy}</strong>
              {appStrings.orderDetailsHistory.inDate}
              <strong>{moment(new Date(data.date)).format(dateFormat)}</strong>
            </Typography>
          </div>
        )}
      </Grid>
      {(!isXsDevice || isOrderDetails) && (
        <Grid
          item
          container
          spacing={2}
          xs="auto"
          justifyContent={isOrderDetails ? 'flex-end' : 'flex-start'}
        >
          <Grid item>{trackBtn}</Grid>
        </Grid>
      )}
      {!isOrderDetails && (
        <Grid item container justifyContent="flex-end" textAlign="end" xs>
          <Grid item data-test-id="orderHeader-totalTestId">
            <div id={`orderHeader-total_${index}`}>
              <Typography
                fontWeight="medium"
                fontSize="medium"
                className={css.currencyStyle}
              >
                {formatNumberWithCurrency(data.total)}
              </Typography>
            </div>
            {(data.status === orderStatusesByName.failed ||
              data.status === orderStatusesByName.pending) &&
            data.type === historyTransactionType.order ? (
              <Typography fontWeight="bold">
                {appStrings.history.orderPending}
              </Typography>
            ) : (
              detailsButton
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

OrderHeader.prototypes = {
  data: PropTypes.object,
  trackBtn: PropTypes.element,
  index: PropTypes.number,
};

export default OrderHeader;
