import { Grid, Typography } from '@mui/material';
import { currentPathOrder, menuItems } from './orderConfirmationConstants';

import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CustomBox from '../../shared/CustomBox/CustomBox';
import CustomBreadcrumbs from '../../shared/CustomBreadcrumbs/CustomBreadcrumbs';
import CustomButton from '../../shared/CustomButton/CustomButton';
import NameCard from '../NameCard/NameCard';
import { ORDER_HISTORY_DETAILS } from '../../../core/navigation/paths';
import appStrings from '../../../core/strings/appStrings';
import { listOfPathsOrder } from '../Cart/cartConstants';
import useOrderConfirmationStyles from './useOrderConfirmationStyles';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

function OrderConfirmation() {
  const css = useOrderConfirmationStyles().classes;
  const { bp, id, orderNumber } = useParams();
  const navigate = useNavigate();
  const student = useSelector(
    state => state.schoolsWithStudents.selectedStudent.student
  );
  const studentName = `${student?.firstName} ${student?.lastName}`;

  return (
    <Grid
      container
      id="order-confirmationPage"
      data-test-id="orderConfirmationPage"
      className={css.orderConfirmation}
    >
      <Grid item xs={12}>
        {student ? <NameCard
          student={student}
          showName={false}
          showMoreMenu={true}
          moreMenuItems={menuItems(id, orderNumber)}
        >
          <CustomBreadcrumbs
            listOfPaths={listOfPathsOrder(
              bp,
              id,
              studentName,
              'order-backToStudent'
            )}
            currentPath={currentPathOrder}
          />
        </NameCard> : null}
      </Grid>
      <Grid item xs={12}>
        <CustomBox customClass={css.orderConfirmationContainer}>
          <Grid container direction="column" alignItems="center" spacing={10} xs={12}>
            <Grid item id="order-successfullyMessage" className="message" xs={12}>
              <Typography variant="h2">
                <Grid container alignItems="center">
                  <CheckCircleOutlineRoundedIcon
                    data-test-id="checkIcon"
                    color="primary"
                  />
                  <Typography variant="h2" className={css.leftMargin}>
                    <strong>
                      {appStrings.order.orderPlacedFor(studentName)}
                    </strong>
                  </Typography>
                </Grid>
              </Typography>
            </Grid>
            <Grid container item direction="column" alignItems="center" xs={12}>
              <Grid item xs={12}>
                <Typography id="order-thxMessage" className="thanks-message">
                  {appStrings.order.thxForOrder} <strong>{orderNumber}</strong>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography id="order-processingMessage">
                  {appStrings.order.processingOrderFor(studentName)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item className="button-container" xs={12}>
              <CustomButton
                label={appStrings.history.viewOrderDetails}
                id="viewOrderDetails"
                onClick={() =>
                  navigate(ORDER_HISTORY_DETAILS(bp, id, orderNumber))
                }
                data-test-id="viewOrderDetails"
              />
            </Grid>
          </Grid>
        </CustomBox>
      </Grid>
    </Grid>
  );
}

export default OrderConfirmation;
