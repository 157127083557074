import {
  addressPattern,
  digitPattern,
  nameStringPattern,
} from '../../../../core/strings/regexValidations';
import {
  addressTypeLabels,
  addressTypes,
  addressTypesByLabel,
  gridLayout as gridLayoutImported,
  studentGridLayout,
} from './addressConstants';
import {
  calcMonthsYearsFromLength,
  getFormattedResidencyLength,
} from './addressesService';
import {
  getZipCodeInfo,
  isValidZipCode,
} from '../../../../core/services/zipCodeService';

import appStrings from '../../../../core/strings/appStrings';
import { dateFormat } from '../../../../core/strings/appConstants';
import moment from 'moment';
import { residenceTypeOptions } from '../../../../core/strings/options';
import setMultipleValues from '../../../../core/services/formService';
import store from '../../../../core/redux/store';

const previousYears = 100;

const onZipCodeChange = async ({
  addressType,
  index = null,
  getValues,
  setValue,
  formWasSubmitted,
  setDisabledStates,
}) => {
  const indexField = index || index === 0 ? `.${index}` : '';
  const newZipCode = getValues(`${addressType}${indexField}.zipCode`);
  const currentState = getValues(`${addressType}${indexField}.state`);
  const currentCity = getValues(`${addressType}${indexField}.city`);

  const hasData = currentState || currentCity;
  if (hasData) {
    setValue(`${addressType}${indexField}.city`, '', {
      shouldValidate: formWasSubmitted,
    });
    setValue(`${addressType}${indexField}.state`, '', {
      shouldValidate: formWasSubmitted,
    });
  }

  if (newZipCode && newZipCode.length === 5) {
    const suggestions = await getZipCodeInfo(newZipCode);
    const state = suggestions !== null ? suggestions.state : '';
    setValue(`${addressType}${indexField}.state`, state, {
      shouldValidate: formWasSubmitted,
    });

    // Enable State field if value is valid, disable if not
    setDisabledStates(prevStates => ({
      ...prevStates,
      ...{ [`${addressType}${indexField}`]: !state },
    }));
  } else {
    // Disable State field if zip is not valid
    setDisabledStates(prevStates => ({
      ...prevStates,
      ...{ [`${addressType}${indexField}`]: true },
    }));
  }
};

const onResidencyLengthChange = ({ fieldName, getValues, setValue }) => {
  const newValue = getValues(`${fieldName}.residencyLength`);
  if (!newValue.includes(appStrings.addresses.month)) {
    const valuesToUpdate = {};
    const { months, years } = calcMonthsYearsFromLength(newValue ?? 0);

    valuesToUpdate[`${fieldName}.month`] = months;
    valuesToUpdate[`${fieldName}.year`] = years;
    valuesToUpdate[`${fieldName}.residencyLength`] =
      getFormattedResidencyLength(months, years);
    setMultipleValues({ values: valuesToUpdate, setValue });
  }
};

const onTimeInputChange = ({ fieldName, getValues, setValue }) => {
  const formMonthValue = getValues(`${fieldName}.month`);
  const formYearValue = getValues(`${fieldName}.year`);

  setValue(
    `${fieldName}.residencyLength`,
    getFormattedResidencyLength(formMonthValue, formYearValue)
  );
};

const onMoveInDateChange = ({ fieldName, getValues, setValue }) => {
  const moveInDate = getValues(`${fieldName}.residencyStartDate`);
  const now = moment();
  const diffInMonths = now.diff(moveInDate, 'months');
  const { years, months } = calcMonthsYearsFromLength(diffInMonths ?? 0);

  setValue(
    `${fieldName}.residencyLength`,
    getFormattedResidencyLength(months, years)
  );
};

const createInputs = ({
  addressTypeCode,
  index = null,
  getValues,
  setValue,
  formWasSubmitted,
  disabledStates,
  setDisabledStates,
  otherGridLayout,
  isStudent,
}) => {
  const gridLayout = isStudent
    ? studentGridLayout
    : otherGridLayout ?? gridLayoutImported;
  const isPermanentAddress =
    addressTypeCode === addressTypesByLabel.permanentAddress;
  const addressInLabel = isPermanentAddress
    ? null
    : ` ${appStrings.register.address}`;
  const addressType = addressTypes[addressTypeCode];
  const getIndexedValues = index !== null ? `.${index}` : '';
  const typeLabel = addressTypeLabels[addressTypeCode];

  const getCitiesOptions = () => {
    const zipCodes = store.getState().zipCode.formattedCodeList;
    const code = getValues(`${addressType}${getIndexedValues}.zipCode`);
    if (zipCodes?.[code]?.cities?.length === 1) {
      // use setTimeout to allow Addresses component to render before City value is updated (prevent unit tests error)
      setTimeout(() => {
        setValue(
          `${addressType}${getIndexedValues}.city`,
          zipCodes[code].cities[0].value,
        );
      });
    }
    return zipCodes?.[code]?.cities || [];
  };

  const dateChangeCallback = () =>
    onTimeInputChange({
      fieldName: `${addressType}${getIndexedValues}`,
      getValues,
      setValue,
    });

  const moveInDateChangeCallback = () => {
    if (addressTypeCode === addressTypesByLabel.currentAddress) {
      return onMoveInDateChange({
        fieldName: `${addressType}${getIndexedValues}`,
        getValues,
        setValue,
      });
    }
  };

  return [
    {
      name: `${addressType}${getIndexedValues}.id`,
      type: 'hidden',
    },
    {
      name: `${addressType}${getIndexedValues}.addressLine1`,
      label: `${typeLabel} ${appStrings.register.address} #1`,
      type: 'text',
      validations: {
        maxLength: 50,
        pattern: addressPattern,
        required: isPermanentAddress,
      },
      gridLayout,
      helperText: appStrings.validationMessages.address,
    },
    {
      name: `${addressType}${getIndexedValues}.addressLine2`,
      label: `${typeLabel} ${appStrings.register.address} #2`,
      type: 'text',
      validations: {
        maxLength: 50,
        pattern: addressPattern,
        required: false,
      },
      gridLayout,
      helperText: appStrings.validationMessages.address,
    },
    {
      name: `${addressType}${getIndexedValues}.zipCode`,
      label: `${typeLabel}${addressInLabel ?? ''} ${appStrings.common.zipCode}`,
      type: 'text',
      validations: {
        minLength: {
          value: 5,
          message: appStrings.validationMessages.xDigitsOnly(5),
        },
        pattern: {
          value: digitPattern,
          message: appStrings.validationMessages.xDigitsOnly(5),
        },
        maxLength: 5,
        required: isPermanentAddress,
        validate: {
          invalidZipCode: value => isValidZipCode(value),
        },
      },
      gridLayout,
      onChangeCallback: () =>
        onZipCodeChange({
          addressType,
          index,
          getValues,
          setValue,
          formWasSubmitted,
          setDisabledStates,
        }),
    },
    {
      name: `${addressType}${getIndexedValues}.city`,
      label: `${typeLabel}${addressInLabel ?? ''} ${appStrings.common.city}`,
      type: 'select',
      options: getCitiesOptions(),
      validations: {
        required: isPermanentAddress,
      },
      gridLayout,
    },
    {
      name: `${addressType}${getIndexedValues}.state`,
      label: `${typeLabel}${addressInLabel ?? ''} ${appStrings.common.state}`,
      type: 'text',
      validations: {
        maxLength: 50,
        required: isPermanentAddress,
      },
      readOnly: true,
      disabled:
        disabledStates[`${addressType}${getIndexedValues}`] ??
        !getValues(`${addressType}${getIndexedValues}.state`),
      gridLayout,
    },
    {
      name: `${addressType}${getIndexedValues}.year`,
      label: appStrings.addresses.years,
      type: 'slider',
      validations: {
        max: 30,
        min: 0,
        required: false,
      },
      step: 1,
      gridLayout,
      hide: addressTypeCode !== addressTypesByLabel.previousAddresses,
      onChangeCallback: dateChangeCallback,
    },
    {
      name: `${addressType}${getIndexedValues}.month`,
      label: appStrings.addresses.months,
      type: 'slider',
      validations: {
        max: 11,
        min: 0,
        required: false,
      },
      step: 1,
      gridLayout,
      hide: addressTypeCode !== addressTypesByLabel.previousAddresses,
      onChangeCallback: dateChangeCallback,
    },
    {
      name: `${addressType}${getIndexedValues}.residenceType`,
      label: appStrings.addresses.typeOfResidence,
      type: 'select',
      validations: {},
      options: residenceTypeOptions,
      gridLayout,
      hide: !isPermanentAddress,
    },
    {
      name: `${addressType}${getIndexedValues}.residencyStartDate`,
      label: appStrings.addresses.moveInDate,
      type: 'date',
      format: dateFormat,
      validations: {},
      gridLayout,
      minDate: new Date().setFullYear(new Date().getFullYear() - previousYears),
      maxDate: new Date(),
      minDateValidationMessage: appStrings.validationMessages.minMoveInDate,
      maxDateValidationMessage: appStrings.validationMessages.maxMoveInDate,
      hide: addressTypeCode === addressTypesByLabel.previousAddresses,
      onChangeCallback: moveInDateChangeCallback,
    },
    {
      name: `${addressType}${getIndexedValues}.residencyLength`,
      label: appStrings.addresses.residencyLength,
      type: 'text',
      defaultValue: appStrings.studentProfile.yearsAndMonths(0, 0),
      validations: {},
      gridLayout,
      disabled: true,
      hide: addressTypeCode === addressTypesByLabel.permanentAddress,
      onChangeCallback: () =>
        onResidencyLengthChange({
          fieldName: `${addressType}${getIndexedValues}`,
          getValues,
          setValue,
        }),
    },
    {
      name: `${addressType}${getIndexedValues}.mortgageName`,
      label: appStrings.addresses.mortgageName,
      type: 'text',
      validations: {
        maxLength: 50,
        pattern: nameStringPattern,
      },
      gridLayout,
      helperText: appStrings.validationMessages.nameString,
      hide: !isPermanentAddress,
    },
  ];
};

export const permanentAddressInputs = ({ ...props }) =>
  createInputs({
    addressTypeCode: 0,
    ...props,
  });

export const currentAddressInputs = ({ ...props }) =>
  createInputs({
    addressTypeCode: 2,
    ...props,
  });

export const previousAddressesInputs = ({ ...props }) =>
  createInputs({
    addressTypeCode: 3,
    ...props,
  });
