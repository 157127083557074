import appStrings from '../../../core/strings/appStrings';

export const addressesTypes = {
  permanentHomeAddress: 'permanentHomeAddress',
  schoolAddress: 'schoolAddress',
  sameAsShippingAddress: 'sameAsShippingAddress',
  addNewAddress: 'addNewAddress',
};

export const checkoutAddressesSections = [
  {
    name: 'shippingAddress',
    text: `1. ${appStrings.addresses.shippingAddress}`,
  },
  {
    name: 'billingAddress',
    text: `2. ${appStrings.addresses.billingAddress}`,
  },
];

export const sectionsAddresses = {
  shippingAddress: [
    {
      name: addressesTypes.permanentHomeAddress,
      text: appStrings.order.useDefaultAddress,
    },
    {
      name: addressesTypes.schoolAddress,
      text: appStrings.order.useSchoolAddress,
    },
  ],
  billingAddress: [
    {
      name: addressesTypes.sameAsShippingAddress,
      text: appStrings.order.useSameAsShipping,
    },
    {
      name: addressesTypes.permanentHomeAddress,
      text: appStrings.order.useDefaultAddress,
    },
  ],
};

export const defaultSelectedAddress = {
  shippingAddress: addressesTypes.permanentHomeAddress,
  billingAddress: addressesTypes.sameAsShippingAddress,
};
