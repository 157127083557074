import urls from '../../../../core/strings/urls';
import ApiService from '../../../shared/Api/apiService';

import { Build } from '@mui/icons-material';

export const getCategories = () => {
  return ApiService.get(urls.getCategories);
};

export const menuCategoriesList =[
  {
    id: 1,
    name: 'BROWSE PRODUCTS',
    subCategories: null,
    icon: <Build />,
    nameClass: 'browse-products-text',
    containerClass: 'browse-products-container',
  },
  {
    id: 2,
    name: 'SNAP-ON ADVANTAGE',
    subCategories: null,
    path: '/snaponadvantage',
  },
  {
    id: 3,
    name: 'TOOLS FOR LIFE',
    subCategories: null,
    path: '/toolsforlife',
  },
  {
    id: 4,
    name: 'STUDENT PROGRAM',
    subCategories: null,
    path: '/studentprogram',
  },
  {
    id: 5,
    name: 'TECH ZONE',
    subCategories: null,
    path: '/techzone',
  },
];

export const clearEmptyCategories = category => {
  if (category.subCategories.length) {
    category.subCategories = category.subCategories.filter(clearEmptyCategories);
    return Boolean(category.subCategories.length);
  }
    
  return category.hasProduct;
};
