import { Divider, Grid, Typography } from '@mui/material';
import appStrings from '../../../core/strings/appStrings';

import { useEffect, useState } from 'react';
import FormInput from '../../shared/FormInput/FormInput';
import { useDispatch, useSelector } from 'react-redux';
import { displayError } from '../../../core/redux/slices/notificationsSlice';
import { useForm } from 'react-hook-form';
import {
  calculateRepaymentConversions,
  directDebitInputs,
  saveDirectDebit,
} from './DirectDebitService';
import useDirectDebitStyles from './useDirectDebitStyles';
import CustomDialog from '../../shared/CustomDialog/CustomDialog';
import setMultipleValues from '../../../core/services/formService';
import { getDisplayErrors } from '../../../core/services/utilsService';
import MandatoryFieldsMessage from '../../shared/MandatoryFieldsMessage/MandatoryFieldsMessage';

function DirectDebit({ orderId, handleClose }) {
  const css = useDirectDebitStyles().classes;
  const dispatch = useDispatch();
  const [directDebitOptions, setDirectDebitOptions] = useState([]);
  const [selectedDrawFrequencyInfo, setSelectedDrawFrequencyInfo] = useState(
    {}
  );
  const [isLoading, setIsLoading] = useState(true);
  const { orderInfo } = useSelector(state => state.cart);
  const {
    control,
    setValue,
    watch,
    formState: { errors },
    getValues,
    handleSubmit,
    clearErrors,
  } = useForm();

  const handleDrawFrequencyUpdate = value => {
    const selectedOption = directDebitOptions.find(item => {
      return item.drawOption === value;
    });

    setSelectedDrawFrequencyInfo(selectedOption);
    let newValues = {
      directDebitDate: selectedOption.drawDate,
      directDebitAmount: selectedOption.amount,
    };

    if (!selectedOption.amount) {
      newValues = {
        ...newValues,
        directDebitInstitution: '',
        directDebitRoutingNumber: '',
        directDebitAccountNumber: '',
      };
    }

    setMultipleValues({
      setValue,
      values: newValues,
    });

    clearErrors();
  };

  const inputs = directDebitInputs(
    directDebitOptions,
    handleDrawFrequencyUpdate,
    selectedDrawFrequencyInfo
  );

  const getDirectDebitData = data => {
    return {
      drawDate: data.directDebitDate === '' ? null : data.directDebitDate,
      amount:
        data.directDebitAmount === '' ? 0 : parseFloat(data.directDebitAmount),
      bankName: data.directDebitInstitution,
      bankRouteNo: data.directDebitRoutingNumber,
      bankAccNo: data.directDebitAccountNumber,
      drawFrequency: data.drawOption,
    };
  };

  const onSubmit = data => {
    saveDirectDebit(orderInfo.studentId, orderId, getDirectDebitData(data))
      .then(() => {
        handleClose();
      })
      .catch(e => {
        dispatch(displayError({ message: getDisplayErrors(e) }));
      });
  };

  const directDebitButtons = [
    {
      label: appStrings.common.ok,
      onClick: handleSubmit(onSubmit),
      className: css.button,
      disabled: isLoading,
    },
  ];

  useEffect(() => {
    calculateRepaymentConversions(orderInfo.studentId, orderId)
      .then(resp => {
        if (resp) {
          const mappedOptions = resp.map(item => {
            return {
              label: item.drawName,
              value: item.drawOption,
              ...item,
            };
          });
          setDirectDebitOptions(mappedOptions);
          setValue('drawOption', mappedOptions[0]?.value);
          setIsLoading(false);
        }
      })
      .catch(e => dispatch(displayError({ message: e })));
  }, [dispatch, orderInfo.studentId, orderId, setValue]);

  return (
    <CustomDialog
      id="direct-debit"
      customClass={css.directDebitContainer}
      open
      message={
        <Grid>
          <form>
            <Grid
              item
              xs={12}
              id="direct-debit-details-container"
              data-test-id="direct-debit-details-container"
              className={css.directDebitContainer}
            >
              <Grid container>
                <Typography
                  variant="h2"
                  className={css.title}
                  fontWeight="bold"
                  id="direct-debit-title"
                >
                  {appStrings.order.directDebit}
                </Typography>
              </Grid>
              <Grid item xs={12} container spacing={1}>
                {inputs.map((field, index) => (
                  <Grid
                    item
                    {...field.gridLayout}
                    className={css.input}
                    key={`${field.name}-${index}`}
                  >
                    <FormInput
                      field={field}
                      control={control}
                      setValue={setValue}
                      watch={watch}
                      getValues={getValues}
                      errors={errors}
                    />
                  </Grid>
                ))}
              </Grid>
              <Divider orientation="horizontal" />
            </Grid>
          </form>
          <Grid display="flex" justifyContent="flex-start">
            <MandatoryFieldsMessage />
          </Grid>
        </Grid>
      }
      buttons={directDebitButtons}
    />
  );
}

export default DirectDebit;
