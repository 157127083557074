import { Grid, useMediaQuery } from '@mui/material';
import AccountInfo from './Components/AccountInfo';
import PropTypes from 'prop-types';
import CustomBreadcrumbs from '../../shared/CustomBreadcrumbs/CustomBreadcrumbs';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { getBreadcrumbs } from './accountServices';
import { useAccountStyles } from './accountStyles';
import StudentPurchasePower from './StudentPurchasePower/StudentPurchasePower';
import StudentAchievements from './StudentAchievements/StudentAchievements';
import { EDITPROFILE } from '../../../core/navigation/paths';

function Account({ children, page }) {
  const css = useAccountStyles().classes;
  const isSmallDevice = useMediaQuery('(max-width:768px)');
  const { pathname } = useLocation();
  const isEditProfile = pathname.indexOf(EDITPROFILE('')) !== -1;
  const params = useParams();
  const [paths, setPaths] = useState([]);

  useEffect(() => {
    setPaths(getBreadcrumbs(pathname, params));
  }, [params, pathname]);

  return (
    <Grid container className={css.mainContainer}>
      <Grid item className={css.breadCrumbsStyle} xs={12}>
        <CustomBreadcrumbs
          listOfPaths={paths.segments}
          currentPath={paths.currentPath}
          isLoading={false}
          currentLinkColor={'red'}
          textDecoration="none"
        />
      </Grid>
      {page ? (
        <Grid item xs={12} paddingTop={isSmallDevice && 3}>
          {page}
        </Grid>
      ) : (
        <Grid item container spacing={isEditProfile && isSmallDevice ? 0 : 3}>
          <Grid item xs={12}>
            <AccountInfo />
          </Grid>
          <Grid item xs={12}>
            {children || (
              <Grid item container xs={12} spacing={3}>
                <Grid item xs={12} sm={isSmallDevice ? 12 : 6} md={6}>
                  <StudentPurchasePower />
                </Grid>
                <Grid item xs={12} sm={isSmallDevice ? 12 : 6} md={6}>
                  <StudentAchievements />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

Account.prototypes = {
  children: PropTypes.element,
  page: PropTypes.element,
};

export default Account;
