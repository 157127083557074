import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROOT } from './paths';

const HashRedirect = () => {
  const { hash } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (hash) {
      navigate(`${hash.includes('/') ? '' : '/'}${hash.replace('#', '')}`);
    } else {
      navigate(ROOT);
    }
  }, [hash, navigate]);
}

export default HashRedirect;