import React, { useEffect, useState } from 'react';
import {
  addCurrentAddress,
  clearEmptySections,
  formatReceivedData,
  getSpeedDialOptions,
  handleDeleteCurrentAddress,
  handleDeletePreviousAddresses,
  handleErrors,
} from './addressesService';
import {
  currentAddressInputs,
  permanentAddressInputs,
  previousAddressesInputs,
} from './addressesInputs';

import CustomSpeedDial from '../../CustomSpeedDial/CustomSpeedDial';
import FormInput from '../../FormInput/FormInput';
import InputsTemplates from './InputsTemplate';
import StandardFormSkeleton from '../../CustomSkeleton/StandardFormSkeleton';
import appStrings from '../../../../core/strings/appStrings';
import { getStudentAddressDetails } from '../studentProfileFormService';
import setMultipleValues from '../../../../core/services/formService';
import useStudentProfileFormStyles from '../useStudentProfileFormStyles';
import MandatoryFieldsMessage from '../../MandatoryFieldsMessage/MandatoryFieldsMessage';
import { Grid } from '@mui/material';
import { isEmptyObject } from '../../../industrial/StudentProfile/Services/clearEmptySectionsService';

const getConditionedValue = (condition, value1, value2) =>
  condition ? value1 : value2;

function Addresses({
  control,
  watch,
  errors,
  id,
  formWasSubmitted,
  emptySections,
  setValue,
  getValues,
  isStudent,
  reset,
}) {
  const [deletedAddresses, setDeletedAddresses] = useState({});
  const [disabledStates, setDisabledStates] = useState({});
  const [addressesState, setAddressesState] = useState({
    currentAddress: false,
    permanentAddress: true,
    previousAddresses: [],
    prevAddressesActualLength: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [addressesSavedData, setAddressesSavedData] = useState({
    formattedValues: {},
    codes: {},
  });
  const formStyle = useStudentProfileFormStyles().classes;
  const watchCurrentAddress = watch('currentAddress');

  useEffect(() => {
    if (id) {
      getStudentAddressDetails(id)
        .then(resp => {
          if (resp) {
            setAddressesState({
              currentAddress: Boolean(resp.currentAddress),
              permanentAddress: Boolean(resp.permanentAddress),
              previousAddresses: Array(
                resp.previousAddresses?.length ?? 0
              ).fill(''),
              prevAddressesActualLength: resp.previousAddresses?.length ?? 0,
            });
            formatReceivedData(resp).then(({ formattedValues, codes }) => {
              setAddressesSavedData({ formattedValues, codes });
            });
          }
        })
        .catch(error => {
          handleErrors(error);
          setIsLoading(false);
        });
    }
  }, [id]);

  useEffect(() => {
    if (addressesSavedData.formattedValues.permanentAddress && isLoading) {
      setMultipleValues({
        setValue,
        values: addressesSavedData.formattedValues,
      });
      // setTimeout is necessary to allow setMultipleValues to set values
      setTimeout(() => {
        setIsLoading(false);
      });
    }
  }, [addressesSavedData.formattedValues, isLoading, setValue]);

  useEffect(() => {
    setValue('deletedAddresses', JSON.stringify(deletedAddresses));
  }, [deletedAddresses, setValue]);

  useEffect(() => {
    clearEmptySections(
      emptySections,
      setAddressesSavedData,
      setAddressesState,
      addressesState,
      setValue,
      getValues
    );
  }, [addressesState, emptySections, getValues, setValue]);

  useEffect(() => {
    const currentAddress = getValues('currentAddress') || {};
    if (isStudent && !isEmptyObject(currentAddress) && watchCurrentAddress) {
      addCurrentAddress({ addressesState, setAddressesState });
    }
  }, [addressesState, getValues, isStudent, watchCurrentAddress]);

  const formProps = {
    control,
    setValue,
    watch,
    errors,
    getValues,
  };
  const display = getConditionedValue(isLoading, { display: 'none' }, null);

  const speedDialOptions = getSpeedDialOptions({
    addressesState,
    setAddressesState,
    getConditionedValue,
  });

  return (
    <>
      {isLoading ? <StandardFormSkeleton /> : null}
      <div style={display}>
        <InputsTemplates
          formProps={formProps}
          inputs={permanentAddressInputs({
            getValues,
            setValue,
            formWasSubmitted,
            disabledStates,
            setDisabledStates,
            isStudent: isStudent,
          })}
          title={`${appStrings.addresses.permanentHome} ${appStrings.register.address}`}
          isStudent={isStudent}
        />
        <Grid container className={formStyle.mandatoryMessageContainer}>
          <MandatoryFieldsMessage />
        </Grid>
        {addressesState.currentAddress ? (
          <InputsTemplates
            formProps={formProps}
            inputs={currentAddressInputs({
              getValues,
              setValue,
              disabledStates,
              setDisabledStates,
              isStudent: isStudent,
            })}
            title={appStrings.addresses.currentAddress}
            hasDelete
            deleteLabel={appStrings.addresses.deleteCurrentAddress}
            handleDelete={() =>
              handleDeleteCurrentAddress({
                addressId: getValues('currentAddress.id'),
                deletedAddresses,
                setDeletedAddresses,
                setValue,
                addressesState,
                setAddressesState,
                setAddressesSavedData,
                getValues,
                studentId: id,
                reset,
              })
            }
            deleteId={'deleteCurrentAddress'}
            isStudent={isStudent}
          />
        ) : null}

        <div className={formStyle.lineContainer}>
          {addressesState.prevAddressesActualLength !== 0
            ? addressesState.previousAddresses.map((_, i) => (
                <div
                  id={`previousAddressContainer-${i}`}
                  key={`previousAddresses${i}`}
                >
                  <InputsTemplates
                    formProps={formProps}
                    inputs={previousAddressesInputs({
                      getValues,
                      setValue,
                      index: i,
                      disabledStates,
                      setDisabledStates,
                      isStudent: isStudent,
                    })}
                    title={appStrings.addresses.previousAddress}
                    hasDelete
                    deleteLabel={appStrings.addresses.deletePreviousAddress}
                    handleDelete={() =>
                      handleDeletePreviousAddresses({
                        addressId: getValues(`previousAddresses.${i}.id`),
                        index: i,
                        setAddressesState,
                        setDeletedAddresses,
                        setValue,
                        getValues,
                        studentId: id,
                        reset,
                      })
                    }
                    deleteId={`deletePreviousAddress-${i}`}
                    hasCounting
                    id={`previousAddressSection-${i}`}
                    isStudent={isStudent}
                  />
                </div>
              ))
            : null}
        </div>
        <FormInput
          {...formProps}
          field={{ name: 'deletedAddresses', type: 'hidden' }}
          data-test-id="deletedAddresses"
        />
        <Grid
          container
          justifyContent={isStudent ? 'flex-start' : 'flex-end'}
          className={formStyle.hide}
        >
          <CustomSpeedDial
            options={speedDialOptions}
            show
            id="addresses"
            isStudent={isStudent}
            customClass={formStyle.speedDialAddresses}
          />
        </Grid>
      </div>
    </>
  );
}

export default Addresses;
