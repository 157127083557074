import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { useDispatch } from 'react-redux';
import appStrings from '../../../../core/strings/appStrings';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import videoPoster from '../../../../core/assets/img/videoPoster.png';
import { Grid, IconButton } from '@mui/material';
import { PlayCircleFilled } from '@mui/icons-material';
import useProductPageStyles from '../productPageStyles';

function ProductVideo({videoUrl}) {
  const dispatch = useDispatch();
  const css = useProductPageStyles().classes;

  const handleVideoStart = () => {
    const elem = document.getElementById('productVideoPlayer');
    if (!document.fullscreenElement && elem.requestFullscreen) {
      elem.requestFullscreen({ navigationUI: 'hide' });
    }
    elem.addEventListener('dblclick', e => {
      e.preventDefault();
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
    });
  }

  const handleVideoError = () => {
    dispatch(displayError({ message: appStrings.productPage.videoPlayerError }))
  }

  return (
    <Grid data-test-id="renderedVideo" id="productVideoPlayerContainer" item className={css.videoPlayer}>
      <ReactPlayer
        url={videoUrl}
        playIcon={
          <IconButton className={css.videoPlayerButton}>
            <PlayCircleFilled />
          </IconButton>
        }
        light={videoPoster}
        playing
        controls
        id="productVideoPlayer"
        height="290px"
        width="100%"
        onStart={handleVideoStart}
        onError={handleVideoError}
      />
    </Grid>
  )
}

ProductVideo.propTypes = {
  videoUrl: PropTypes.string
}

export default ProductVideo;