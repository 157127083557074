import React from 'react';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useDispatch, useSelector } from 'react-redux';
import { addProductToCart } from '../../ShoppingCart/ShoppingCartService';
import { cartTypes } from '../../../industrial/Cart/cartConstants';
import PropTypes from 'prop-types';

function SaveButton({ productDetails }) {
  const dispatch = useDispatch();
  const { isLoggedIn, userDetails } = useSelector(state => state.login);
  const loadingAddProduct = useSelector(
    state => state.guestCart.loadingAddProduct
  );
  const isLoading =
    loadingAddProduct.indexOf(productDetails.productNumber) !== -1;
  const isDisabled = (isLoggedIn && !userDetails?.isActive) || isLoading;

  const handleSaveItem = () => {
    addProductToCart(
      productDetails,
      dispatch,
      isLoggedIn,
      userDetails.userId,
      cartTypes.saved
    );
  };

  return (
    isLoggedIn && (
      <StarBorderIcon
        id="productContentFavorite-Button"
        data-test-id="StarBorderIcon"
        color={isDisabled ? 'disabled' : ''}
        htmlColor="black"
        onClick={event => {
          event.stopPropagation();
          if (!isDisabled) {
            handleSaveItem();
          }
        }}
      />
    )
  );
}

SaveButton.propTypes = {
  productDetails: PropTypes.object,
};

export default SaveButton;
