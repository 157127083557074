import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField, useTheme } from '@mui/material';

import {Controller} from 'react-hook-form';
import CustomIcon from '../../CustomIcon/CustomIcon';
import {icons} from '../../../../core/strings/icons';
import propTypes from '../propTypes';
import useFormInputStyles from '../useFormInputStyles';
import classNames from 'classnames';

function PasswordField({ field, fieldErrors, ...props }) {
  const css = useFormInputStyles().classes;
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const getHelperText = () => {
    if (!field.hideErrorMessage && fieldErrors && fieldErrors.type !== 'required') {
      return field.helperText ? field.helperText : fieldErrors.message || ' ';
    }
    return ' ';
  };

  const icon = !showPassword ? icons.show : icons.hide;
  const iconSize = !showPassword
      ? theme.typography.h3.fontSize
      : theme.typography.h6.fontSize;

  return (
      <>
        <Controller
            control={props.control}
            defaultValue={field.defaultValue ?? ''}
            name={field.name}
            rules={{...field.validations}}
            render={({field: {ref, onChange, onBlur, value}}) => (
                <TextField
                    label={field.label}
                    name={field.name}
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                    size={props.size ?? 'small'}
                    inputRef={ref}
                    value={value}
                    required={field.validations.required}
                    error={Boolean(fieldErrors)}
                    helperText={getHelperText()}
                    className={css.simpleInput}
                    data-test-id="password-input"
                    inputProps={{
                      ...props.inputProps,
                      ...field.validations,
                      readOnly: field.readOnly,
                      'data-test-id': `password-input-${field.name}`,
                    }}
                    autoComplete={field.autocomplete ?? 'new-password'}
                    InputProps={{
                      ...props.InputProps,
                      endAdornment: field.showVisibilityBtn ? (
                          <InputAdornment
                              position="end"
                              data-test-id="password-input-visibility"
                          >
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                size="large"
                            >
                              <CustomIcon icon={icon} size={iconSize}/>
                            </IconButton>
                          </InputAdornment>
                      ) : null,
                    }}
                    fullWidth={props.fullWidth}
                    onChange={e => {
                      onChange(e);
                      if (field.onChangeCallback) {
                        field.onChangeCallback();
                      }
                    }}
                    onBlur={e => {
                      onBlur(e);
                      if (field.onBlurCallback) {
                        field.onBlurCallback();
                      }
                    }}
                    id={field.name}
                />
            )}
        />
        {!field.hideErrorMessage && fieldErrors?.message &&
            <span id="errorMessage" className={classNames(css.errorMessage)}>{fieldErrors.message}</span>}
      </>
  );
}

PasswordField.prototype = propTypes;

export default PasswordField;
