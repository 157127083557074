import PropTypes from 'prop-types';

const propTypes = {
  field: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.any,
    type: PropTypes.string.isRequired,
    validations: PropTypes.shape({
      required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
      minLength: PropTypes.any,
      maxLength: PropTypes.any,
      pattern: PropTypes.any,
      validate: PropTypes.any,
    }),
    roles: PropTypes.arrayOf(PropTypes.string),
    gridLayout: PropTypes.shape({
      xs: PropTypes.number,
      sm: PropTypes.number,
      md: PropTypes.number,
      lg: PropTypes.number,
    }),
    disablePast: PropTypes.bool,
    disableFuture: PropTypes.bool,
    maxDate: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
    ]),
    minDate: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
    ]),
    initialFocusedDate: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
    ]),
    helperText: PropTypes.string,
    mask: PropTypes.array,
    maskFormat: PropTypes.string,
    size: PropTypes.string,
    key: PropTypes.any,
    onInputChange: PropTypes.func,
    onCallbackChange: PropTypes.func,
    getValues: PropTypes.object,
  }),
  size: PropTypes.string,
  control: PropTypes.object,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  errors: PropTypes.object,
  labelPlacement: PropTypes.string,
};

export default propTypes;
