export const ROOT = '/';
export const NOTFOUND = '/not-found';
export const LOGOUT = '/logout';

export const INDUSTRIAL = '/industrial';
export const CONSIGNED = '/consigned';

// Guests
export const REGISTER = '/register';
export const LOGIN = '/login';
export const ACCEPTTERMSANDCONDITIONS = '/acceptTermsAndConditions';
export const TERMSANDCONDITIONS = '/termsAndConditions';
export const FORGOT_PASSWORD = '/forgotPassword';
export const RESEND_LINK = '/resendLink';
export const RESET_LINK_EXPIRED = '/resetLinkExpired';
export const RESET_PASSWORD = (token = ':token') => `/resetPassword/${token}`;
export const CATEGORY_ID = (id = ':id') => `/category/${id}`;

const productNumberPath = ':productNumber';

// Students
export const ACCOUNT = '/account';
export const PRODUCT = (productNumber = productNumberPath) =>
  `/product/${productNumber}`;
export const SHOPPINGCART = '/shoppingCart';
export const PRODUCTSEARCHRESULTS = '/productSearchResults';
export const CHECKOUT = '/checkout';
export const PROFILE = `${ACCOUNT}/profile`;
export const MYSAVEDITEMS = `${ACCOUNT}/savedItems`;
export const EDITPROFILE = (id = ':id') => `${PROFILE}/editProfile/${id}`;
export const MYORDERS = `${ACCOUNT}/orders`;
export const ORDERDETAILS = (orderNo = ':orderNo') =>
  `/orderDetails/${orderNo}`;
export const ACCOUNTORDERDETAILS = (orderNo = ':orderNo') =>
  `${MYORDERS}${ORDERDETAILS(orderNo)}`;
export const PRODUCT_SETS = (productNumber = productNumberPath) =>
  `/product/${productNumber}/sets`;
export const PRODUCT_SET_MEMBERS = (productNumber = productNumberPath) =>
  `/product/${productNumber}/setmembers`;

// Industrial paths
export const APPROVE_REJECT = `${INDUSTRIAL}/approveReject`;
export const REGISTER_IND = `${INDUSTRIAL}${REGISTER}`;
export const REGISTER_IND_CONFIRMATION = `${INDUSTRIAL}${REGISTER}/confirmation`;
export const SEARCH = (approveReject = ':approveReject') =>
  `${INDUSTRIAL}/searchResult/${approveReject}`;
export const STUDENTS = (employeeId = ':employeeId') =>
  `${INDUSTRIAL}/${employeeId}`;
export const STUDENT_PROFILE = (bp = ':bp', id = ':id') =>
  `${INDUSTRIAL}/student/${bp}/${id}`;
export const CART = (bp = ':bp', id = ':id') =>
  `${INDUSTRIAL}/cart/${bp}/${id}`;
export const HISTORY = (bp = ':bp', id = ':id') =>
  `${INDUSTRIAL}/history/${bp}/${id}`;
export const SAVED = (bp = ':bp', id = ':id') =>
  `${INDUSTRIAL}/saved/${bp}/${id}`;
export const ORDER = (bp = ':bp', id = ':id') =>
  `${INDUSTRIAL}/order/${bp}/${id}`;
export const ORDER_HISTORY_DETAILS = (
  bp = ':bp',
  id = ':id',
  orderId = ':orderId'
) => `${INDUSTRIAL}/orderHistory/${bp}/${id}/${orderId}`;
export const EC_CONTRACT_SIGNING = (
  bp = ':bp',
  id = ':id',
  orderId = ':orderId'
) => `${INDUSTRIAL}/ecContractSigning/${bp}/${id}/${orderId}`;
export const RESTORE_PURCHASES = (bp = ':bp', id = ':id') =>
  `${INDUSTRIAL}/restorePurchases/${bp}/${id}`;
export const ORDER_CONSIGNED_PAGE = (bp = ':bp', id = ':id') =>
  `${INDUSTRIAL}/order${CONSIGNED}/${bp}/${id}`;
export const EC_APP_WIZARD = (bp = ':bp', id = ':id') =>
  `${INDUSTRIAL}/ecAppWizard/${bp}/${id}`;

export const SELF_EC_APP_WIZARD = (schoolId = ':bp', studentId = ':id') =>
  `/ecAppWizard/${schoolId}/${studentId}`;

export const ORDER_CONFIRMATION = (
  bp = ':bp',
  id = ':id',
  orderNumber = ':orderNumber'
) => `${INDUSTRIAL}/orderConfirmation/${bp}/${id}/${orderNumber}`;
