import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import useImageCarouselStyles from '../../../shared/ImageCarousel/useImageCarouselStyles';
import CustomDialog from '../../../shared/CustomDialog/CustomDialog';
import ImageCarouselSkeleton from '../../../shared/ImageCarousel/ImageCarouselSkeleton';
import ImageCarousel from '../../../shared/ImageCarousel/ImageCarousel';
import { getProductImages } from '../productsListService';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';

function ProductPictureModal({productNumber, handleClose}) {
  const [isLoading, setIsLoading] = useState(false);
  const [productImages, setProductImages] = useState([]);
  const css = useImageCarouselStyles().classes;
  const dispatch = useDispatch();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  const updateModalOnResize = () => {
    setIsDesktop(window.innerWidth > 1024);
  };

  useEffect(() => {
    window.addEventListener('resize', updateModalOnResize);
    return () => window.removeEventListener('resize', updateModalOnResize);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getProductImages(productNumber)
      .then(images => setProductImages(images))
      .catch((err) => dispatch(displayError({ message: err })))
      .finally(() => setIsLoading(false));
  }, [productNumber, dispatch]);

  return (
    <CustomDialog
      id="productPictureModal"
      fullScreen={!isDesktop}
      open
      handleClose={handleClose}
      customClass={css.imageDialog}
      showCloseButton
      message={
        isLoading || productImages.length === 0 ?
          <ImageCarouselSkeleton /> : <ImageCarousel images={productImages} initialSlide={0} zoomed />
      }
    />
  )
}

ProductPictureModal.propTypes = {
  productNumber: PropTypes.string,
  handleClose: PropTypes.func
}

export default ProductPictureModal;