import ApiService from '../../modules/shared/Api/apiService';
import urls from '../strings/urls';

const logError = async ({
  error,
  errorInfo = { componentStack: null },
  componentName = null,
}) => {
  const errorLog = {
    type: 'error',
    message: typeof error === 'string' ? error : error.message || null,
    stack: typeof error !== 'string' ? error.stack || null : null,
    component: componentName,
    componentStack: errorInfo.componentStack,
  };

  ApiService.post(urls.logger, errorLog);
};

export default logError;
