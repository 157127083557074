import { Grid } from '@mui/material';
import CustomSkeleton from '../../shared/CustomSkeleton/CustomSkeleton';
import useECContractStyles from './useECContractStyles';

function ECContractSkeleton() {
  const css = useECContractStyles().classes;
  const contractItems = { xs: 4, width: '100%', height: '75px' };
  let contractItemsLayout = Array(12).fill(contractItems);

  const formData = { xs: 12, width: '100%', height: '60px' };
  let formDataLayout = Array(3).fill(formData);

  const contractInfo = { xs: 12, width: '100%', height: '30px' };
  let contractInfoLayout = Array(12).fill(contractInfo);

  return (
    <Grid container data-test-id="ecContractSkeleton">
      <Grid item container xs={12} sm={6}>
        <Grid item container>
          <CustomSkeleton
            layout={contractItemsLayout}
            spacing={2}
            rowSpacing={1}
          />
        </Grid>
        <Grid item container>
          <CustomSkeleton layout={formDataLayout} spacing={2} rowSpacing={1} />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={6}
        justifyContent="flex-end"
        className={css.container}
      >
        <Grid item container xs={12} sm={9}>
          <CustomSkeleton
            layout={contractInfoLayout}
            spacing={2}
            rowSpacing={1}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ECContractSkeleton;
