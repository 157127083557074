import { Grid, Pagination, Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import appStrings from '../../../core/strings/appStrings';
import useCustomPaginationStyles from './useCustomPaginationStyles';

const propTypes = {
  itemsLength: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number,
  onPageChangeCallback: PropTypes.func,
};

// eslint-disable-next-line sonarjs/cognitive-complexity
function CustomPagination({
  itemsLength = 0,
  itemsPerPage = 10,
  onPageChangeCallback,
  keepPageOnLengthChange,
  isLoading = false,
  isStudent = false,
  scrollTop = false,
  ...props
}) {
  const css = useCustomPaginationStyles().classes;

  const count = Math.ceil(itemsLength / itemsPerPage);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const idCustomLabel = props.id ?? '';

  const [paginationDetails, setPaginationDetails] = useState({
    from: 1,
    to: itemsPerPage,
  });

  const handlePageChange = (event, value) => {
    setPage(value);
    if (scrollTop) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
    }
  };

  useEffect(() => {
    if (lastPage !== page) {
      const from = itemsPerPage * (page - 1);
      let to = from + itemsPerPage;

      if (to > itemsLength) {
        to = itemsLength;
      }

      setPaginationDetails({
        from: from + 1,
        to: to,
      });

      if (onPageChangeCallback) {
        onPageChangeCallback({ from, to, page, itemsPerPage });
      }
      setLastPage(page);
    }
  }, [itemsLength, itemsPerPage, lastPage, onPageChangeCallback, page]);

  useEffect(() => {
    if (keepPageOnLengthChange) {
      if (itemsLength <= itemsPerPage * (page - 1) && page > 1) {
        setPage(count);
      } else {
        setLastPage(prev => prev - 1);
      }
    }
  }, [count, itemsLength, itemsPerPage, keepPageOnLengthChange, page]);

  useEffect(() => {
    if (!keepPageOnLengthChange) {
      setPage(1);
      setLastPage(0);
    }
  }, [itemsLength, keepPageOnLengthChange]);

  return (
    <Grid
      container
      direction={isStudent ? { xs: 'column', sm: 'column', md: 'row' } : 'row'}
      alignItems="center"
      justifyContent={
        isStudent
          ? { xs: 'center', sm: 'center', md: 'space-between' }
          : 'center'
      }
      className={
        isStudent ? css.studentPaginationContainer : css.paginationContainer
      }
      id={`customPaginationContainer-${idCustomLabel}`}
    >
      {!isLoading ? (
        <>
          <p
            className={
              isStudent ? css.studentPaginationText : css.paginationText
            }
            id={`customPaginationPaginatorCount-${idCustomLabel}`}
          >
            {isStudent
              ? appStrings.pagination.outOf(
                  paginationDetails.from,
                  paginationDetails.to,
                  itemsLength
                )
              : appStrings.pagination.showingFromTo(
                  paginationDetails.from,
                  paginationDetails.to,
                  itemsLength
                )}
          </p>
          <Pagination
            count={count}
            page={page}
            onChange={handlePageChange}
            className={isStudent ? css.studentPagination : css.pagination}
            id={`customPagination${props.id ? '-' + props.id : ''}`}
            data-test-id="customPagination"
            shape={props.shape}
            variant={props.variant}
            showFirstButton={isStudent}
            showLastButton={isStudent}
            boundaryCount={!isNaN(props.boundaryCount) ? props.boundaryCount : 1}
          />
        </>
      ) : !isStudent ? (
        <Grid container>
          <Grid item xs={2}>
            <Skeleton variant="text" />
          </Grid>
          <Grid container item xs={8} justifyContent="center">
            <Grid item xs={6}>
              <Skeleton
                variant="text"
                data-test-id="customPagination-skeleton"
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item container xs={12} className={css.studentPaginationSkeleton}>
          <Grid item xs={1}>
            <Skeleton variant="text" />
          </Grid>
          <Grid item xs={4}>
            <Skeleton variant="text" />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

CustomPagination.prototype = propTypes;

export default CustomPagination;
