import React, { useCallback, useEffect, useState } from 'react';
import {
  formatCustomerInfo,
  resetPasswordEmail,
  updateDisableFields,
} from './customerInformationService';
import { getStudentDetails, handleErrors } from '../studentProfileFormService';

import CustomBox from '../../CustomBox/CustomBox';
import CustomButton from '../../CustomButton/CustomButton';
import FormInput from '../../FormInput/FormInput';
import { Grid } from '@mui/material';
import appStrings from '../../../../core/strings/appStrings';
import { buttonTypes } from '../../../../core/strings/appConstants';
import { customerInfoInputs } from './customerInfoInputs';
import { propTypes } from './customerInformationConstants';
import setMultipleValues from '../../../../core/services/formService';
import useCustomerInformationStyles from './useCustomerInformationStyles';
import { useSelector } from 'react-redux';
import {
  setEcAppBtnEnabled,
  setRefreshCustomerInfo,
} from '../../../../core/redux/slices/commonSlice';
import StandardFormSkeleton from '../../CustomSkeleton/StandardFormSkeleton';
import MandatoryFieldsMessage from '../../MandatoryFieldsMessage/MandatoryFieldsMessage';
import { selectedStudent } from '../../../../core/redux/slices/schoolsWithStudentsSlice';
import { useParams } from 'react-router-dom';

function CustomerInformation({
  control,
  setValue,
  getValues,
  watch,
  errors,
  id,
  setLoading,
  reset,
  dispatch,
  isStudent,
  loading,
  customerInfo,
  setCustomerInfo,
}) {
  const css = useCustomerInformationStyles().classes;
  const { bp } = useParams();
  const [disableDriverLicenseState, setDisableDriverLicenseState] =
    useState(false);
  const [disablePhoneType1, setDisablePhoneType1] = useState(false);
  const [disablePhoneType2, setDisablePhoneType2] = useState(false);
  const getPhoneNumber2Id = useSelector(
    state => state.common.refreshCustomerInfo
  );
  const [sendingResetPassword, setSendingResetPassword] = useState(false);

  const getStudentData = useCallback(() => {
    setLoading(true);
    getStudentDetails(id)
      .then(resp => {
        if (!resp.firstName) {
          handleErrors();
        } else {
          formatCustomerInfo(resp);
          setCustomerInfo(resp);

          updateDisableFields({
            setDisableDriverLicenseState,
            setDisablePhoneType1,
            setDisablePhoneType2,
            resp,
          });
          dispatch(
            selectedStudent({
              bpNumber: bp,
              student: {
                ...resp,
                active: resp.studentAccountStatus === 'Active',
              },
            })
          );
          dispatch(setEcAppBtnEnabled(resp.isCreditAppEnabled));
        }
      })
      .catch(error => {
        handleErrors(error);
      })
      .finally(() => setLoading(false));
  }, [bp, dispatch, id, setLoading]);

  useEffect(() => {
    if (id) {
      getStudentData();
    }
  }, [dispatch, getStudentData, id, setLoading, setValue]);

  useEffect(() => {
    if (getPhoneNumber2Id) {
      setLoading(true);
      dispatch(setRefreshCustomerInfo(false));
      getStudentData();
    }
  }, [dispatch, getPhoneNumber2Id, getStudentData, setLoading]);

  useEffect(() => {
    setMultipleValues({
      setValue,
      values: customerInfo,
    });
    // needed to set dirty false
    reset(getValues());
  }, [customerInfo, getValues, reset, setValue]);

  return !loading ? (
    <>
      <CustomBox fadeBorder>
        <Grid
          alignItems="flex-start"
          container
          data-test-id="customerInformation"
          direction="row"
          spacing={2}
        >
          {customerInfoInputs({
            getValues,
            setValue,
            disableDriverLicenseState,
            setDisableDriverLicenseState,
            disablePhoneType1,
            setDisablePhoneType1,
            disablePhoneType2,
            setDisablePhoneType2,
            isStudent,
          }).map(field => (
            <Grid
              item
              {...field.gridLayout}
              key={`${field.name}-container`}
              data-test-id={`${field.name}`}
              className={field.type === 'hidden' ? css.hideInput : ''}
            >
              <FormInput
                field={field}
                control={control}
                setValue={setValue}
                getValues={getValues}
                watch={watch}
                errors={errors}
                size={isStudent ? 'medium' : null}
              />
            </Grid>
          ))}
        </Grid>
      </CustomBox>
      <Grid container className={css.resetPassContainer}>
        <MandatoryFieldsMessage />
        <div className={css.resetPassBtn}>
          <CustomButton
            btnType={buttonTypes.secondary}
            label={appStrings.studentProfile.sendResetPass}
            id="sendEmailBtn"
            disabled={sendingResetPassword}
            onClick={() =>
              resetPasswordEmail({
                setSendingResetPassword,
                customerInfo,
                isStudent,
              })
            }
          />
        </div>
      </Grid>
    </>
  ) : (
    <StandardFormSkeleton />
  );
}

CustomerInformation.propTypes = propTypes;

export default CustomerInformation;
