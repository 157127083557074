import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import useProductPageStyles from '../productPageStyles';
import CustomDialog from '../../../shared/CustomDialog/CustomDialog';
import ImageZoomButton from '../../../shared/ImageZoomButton/ImageZoomButton';

function SpecificationsImage({imageUrl}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const css = useProductPageStyles().classes;
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  const updateModalOnResize = () => {
    setIsDesktop(window.innerWidth > 1024);
  };

  useEffect(() => {
    window.addEventListener('resize', updateModalOnResize);
    return () => window.removeEventListener('resize', updateModalOnResize);
  }, []);

  return (
    <>
      <CustomDialog
        id="specificationsImageZoomedModal"
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        customClass={css.imageDialog}
        showCloseButton
        message={
          <Grid container alignItems="center" justifyContent="center" className={css.specificationsImageZooomed}>
            <img
                id="productPageSpecImage"
                loading="lazy"
                src={imageUrl}
                alt=""
              />
          </Grid>
        }
        fullScreen={!isDesktop}
      />
      <Grid data-test-id="renderedSpecificationImage" container item xs={12} lg={6}>
        <Grid container item xs={12} justifyContent="center" alignItems="center" className={css.specificationsImage}>
          <img
            id="productPageSpecsImage"
            loading="lazy"
            src={imageUrl}
            alt=""
            onClick={() => setDialogOpen(true)}
          />
        </Grid>
        <Grid item container xs={12} className={css.zoomButtonContainer}>
          <ImageZoomButton clickHandler={() => setDialogOpen(true)} />
        </Grid>
      </Grid>
    </>
  )
}

SpecificationsImage.propTypes = {
  imageUrl: PropTypes.string,
};

export default SpecificationsImage;