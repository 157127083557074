/*
 * Copyright 2021 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { bannerXStyles } from './useBannerXStyles';

export default function BannerX(FullBannerProps) {
  const { component, page } = FullBannerProps;
  const css = bannerXStyles().classes;

  // Get component parameters
  const {
    backgroundbox,
    height,
    textalign,
    textcolor,
    textlocation,
  } = component.getParameters();

  let backgroundBoxClass;
  switch (backgroundbox) {
    case 'Transparent Black':
      backgroundBoxClass = 'blackBackground';
      break;
    case 'Transparent Grey':
      backgroundBoxClass = 'greyBackground';
      break;
    default:
      backgroundBoxClass = '';
  }

  let containerLocationClass;
  switch (textlocation) {
    case 'Right':
      containerLocationClass = 'rightContainer';
      break;
    case 'Center':
      containerLocationClass = 'centerContainer';
      break;
    default:
      containerLocationClass = 'leftContainer';
  }

  let textAlignClass;
  switch (textalign) {
    case 'Right':
      textAlignClass = 'textRight';
      break;
    case 'Center':
      textAlignClass = 'textCenter';
      break;
    default:
      textAlignClass = 'textLeft';
  }

  const textColorClass = textcolor === 'White' ? 'text-white' : '';

  // Get component content
  const contentRef = component.model.content;
  const documentObject = contentRef && page.getContent(contentRef);
  const {
    content,
    cta,
    ctaStyle,
    externalLink,
    image,
    link,
    listitems,
    subtitle,
    title,
  } = documentObject && documentObject.data;

  let linkStyle = 'red-on-white';
  if (ctaStyle) {
    linkStyle = ctaStyle.selectionValues[0].key;
  }

  /* eslint react/prop-types: 0 */
  // eslint-disable-next-line react/no-unstable-nested-components
  const BannerLink = ({ ...props }) => {
    const { bannerLink, bannerLinkType } = props;
    if (bannerLinkType === 'internal') {
      return <Link to={bannerLink} className={`${css[linkStyle]} hover:${css[textColorClass]} ${css[textColorClass]}`}>{cta}</Link>;
    }
    if (bannerLinkType === 'external') {
      return <a href={bannerLink} className={`${css[linkStyle]} hover:${css[textColorClass]} ${css[textColorClass]}`}>{cta}</a>;
    }

    return null;
  };

  /* eslint react/prop-types: 0 */
  // eslint-disable-next-line react/no-unstable-nested-components
  const CtaLink = ({ ...props }) => {
    const { ctaLink, linkType, text } = props;

    if (linkType === 'internal') {
      return <Link to={ctaLink} className={`${css[linkStyle]} hover:${css[textColorClass]} ${css[textColorClass]}`}>{text}</Link>;
    }

    if (linkType === 'external') {
      return <a href={ctaLink} className={`${css[linkStyle]} hover:${css[textColorClass]} ${css[textColorClass]}`}>{text}</a>;
    }

    return null;
  };

  const bannerLink = link ? page.getContent(link).getUrl() : externalLink;
  const bannerLinkType = link ? 'internal' : 'external';
  const imageObject = image && page.getContent(image).getOriginal();

  if (contentRef) {
    return (
      <section
      className={`${css.bannerX} ${css.sectionWrapper} ${css[height]} ${css[textColorClass]}`}
          style={{ backgroundImage: `url(${imageObject && imageObject.getUrl()})` }}>
        <div className={css.articleContainer}>
          <article className={`${css.sectionContent} ${css[backgroundBoxClass] ?? null} ${css[containerLocationClass]} ${css[textAlignClass]}`} >
            { title && <h3 className={`${css.brTag} text-white`} dangerouslySetInnerHTML={{ __html: title.value }}/> }
            { subtitle && <h1 className={css.brTitle} dangerouslySetInnerHTML={{ __html: subtitle.value }} /> }
            <p>
              <span dangerouslySetInnerHTML={{ __html: content.value }} />
            </p>

            {(listitems.length > 0 && (
              <div className={css.sectionInnerContent}>
                <div>
                  <div>
                    {listitems.map(item => {
                      const ctaLink = item.internalLink ? page.getContent(item.internalLink).getUrl() : item.externalLink;
                      const ctaLinkType = item.internalLink ? 'internal' : 'external';
                      return (
                        <article key={item.title.value}>
                          <h1 className="br-title font-bold force-left">
                            { item.title && <span dangerouslySetInnerHTML={{ __html: item.title.value }} /> }
                          </h1>
                          <p dangerouslySetInnerHTML={{ __html: item.text.value }} />
                          { item.cta && ctaLink && ctaLinkType && <CtaLink ctaLink={ctaLink} linkType={ctaLinkType} text={item.cta} /> }
                        </article>
                      );
                    })}
                  </div>
                </div>
              </div>
            ))}

            { cta && bannerLink && <BannerLink bannerLink={bannerLink} bannerLinkType={bannerLinkType} /> }
          </article>
        </div>
      </section>
    );
  }

  return (
    <div>No Banner on component</div>
  );
}
