import { makeStyles } from 'tss-react/mui';

export const useRegisterStyles = makeStyles()(theme => ({
  registerContainer: {
    '& .MuiAccordionSummary-root': {
      borderRadius: '4px 4px 0px 0px',
    },
    '& .MuiAccordion-root': {
      background: theme.palette.common.neutral1,
    },
    '& .MuiAccordionDetails-root': {
      padding: `${theme.spacing(4)} ${theme.spacing(12)} !important`,
    },
    '& .MuiBox-root:first-of-type .MuiAccordionDetails-root': {
      paddingBottom: `${theme.spacing(1.5)} !important`,
    },
  },

  sectionBox: {
    marginBottom: theme.spacing(2.5),
  },
  information: {
    padding: theme.spacing(1),
  },
  submitContainer: {
    textAlign: 'center',
    paddingTop: '0 !important',
  },
  guestSubmitContainer: {
    padding: `0 ${theme.spacing(2)}`,
    textAlign: 'center',
    '& .MuiButton-root': {
      width: '100%',
    },
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(3),
  },
  sectionTitle: {
    padding: `0 ${theme.spacing(2)}`,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  guestBox: {
    padding: `${theme.spacing(2)}`,
    border: 'none',
    '.Mui-checked': {
      color: `${theme.palette.common.red} !important`,
    },
  },
  hidden: {
    display: 'none',
  },
  paper: {
    [theme.breakpoints.up('md')]: { maxWidth: `${theme.spacing(80)}` },
    [theme.breakpoints.down('md')]: {
      padding: 0,
      margin: 0,
      border: 0,
    },
    border: `1px solid ${theme.palette.common.veryLightGrey}`,
    borderRadius: '5px',
    padding: `${theme.spacing(4)} ${theme.spacing(20)}`,
    alignSelf: 'center',

    boxShadow: 'none',
  },
  activeForm: {
    [theme.breakpoints.down('md')]: {
      padding: `0 ${theme.spacing(20)}`,
    },
    [theme.breakpoints.down('sm')]: {
      padding: `0 ${theme.spacing(1)}`,
    },
  },
  redInfo: {
    color: `${theme.palette.common.red}`,
  },
  info: {
    padding: theme.spacing(1),
    color: `${theme.palette.common.grey}`,
  },
  terms: {
    paddingLeft: theme.spacing(4),
    '& a': {
      cursor: 'pointer',
    },
  },
  spacedInput: {
    minHeight: `${theme.spacing(9)}`,
  },
  mandatoryMessageContainer: {
    margin: `0 0 ${theme.spacing(2)} ${theme.spacing(2)}`,
  },
}));
