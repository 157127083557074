export const getPageTitle = (title) => {
  const titles = {
    '/termsAndConditions': 'Terms And Conditions',
    '/account': 'Account',
    '/account/orders': 'My Orders',
    '/account/profile': 'My Profile',
    '/account/savedItems': 'My Saved Items',
    '/shoppingCart': 'Shopping Cart',
    '/productSearchResults': 'Product Search Results',
    '/checkout': 'Checkout',
    '/register': 'New User Registration',
    default: 'Student Excellence Program'
  }
  if (titles[title]) {
    return `${titles[title]} - ${titles.default}`
  }
  return titles.default;
}
