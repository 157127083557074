import {
  buttonColors,
  buttonTypes,
  studentsApprovalState,
  studentsApprovalStateByCode,
} from '../../../../core/strings/appConstants';
import {
  continueStatusUpdate,
  handleStatusUpdateHandler,
} from './listStudentsCardsService';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import ApproveRejectSkeleton from '../ApproveRejectSkeleton';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import CustomDialog from '../../../shared/CustomDialog/CustomDialog';
import CustomPagination from '../../../shared/CustomPagination/CustomPagination';
import { Grid } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import NoDataAvailable from '../../../shared/NoDataAvailable/NoDataAvailable';
import PropTypes from 'prop-types';
import StudentDetailsCard from '../StudentDetailsCard/StudentDetailsCard';
import appStrings from '../../../../core/strings/appStrings';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import { getDataToDisplayFromTo } from '../../../shared/CustomPagination/customPaginationService';
import { icons } from '../../../../core/strings/icons';
import useListStudentsCardsStyles from './useListStudentsCardsStyles';

const propTypes = {
  students: PropTypes.array,
  sectionType: PropTypes.number, // one of studentsApprovalState values
};

function ListStudentsCards({ students, sectionType }) {
  const studentsShown = 3;
  const dispatch = useDispatch();
  const css = useListStudentsCardsStyles().classes;

  const [isLoading, setIsLoading] = useState(false);
  const { checkedStudents } = useSelector(state => state.approveReject);
  const [paginationDetails, setPaginationDetails] = useState({
    from: 0,
    to: studentsShown,
    itemsPerPage: studentsShown,
    page: 0,
  });

  const [displayedStudents, setDisplayedStudents] = useState(
    students?.slice(0, paginationDetails.itemsPerPage)
  );

  useEffect(() => {
    if (students) {
      const newDisplayedStudents = getDataToDisplayFromTo(
        students,
        paginationDetails.from,
        paginationDetails.to
      );
      setDisplayedStudents(newDisplayedStudents);
    }
  }, [paginationDetails.from, paginationDetails.to, students]);

  const handlePageChange = ({ from, to, page, itemsPerPage }) => {
    setPaginationDetails({ from, to, page, itemsPerPage });
  };

  const [dialogData, setDialogData] = useState({
    isOpen: false,
    title: appStrings.approveReject.confirmationModalText('approve', 1),
    continueButtonLabel: appStrings.approveReject.approveAll,
    propsToContinue: null,
  });
  const handleStatusUpdate = (
    studentsToUpdate,
    status = studentsApprovalState.approved
  ) =>
    handleStatusUpdateHandler({
      studentsToUpdate,
      checkedStudents,
      setIsLoading,
      setDialogData,
      dispatch,
      status,
    });

  const dialogCloseHandler = () =>
    setDialogData(prev => {
      const next = cloneDeep(prev);
      next.isOpen = false;
      next.propsToContinue = null;
      return next;
    });

  const dialogContinueHandler = () => {
    if (dialogData.propsToContinue) {
      continueStatusUpdate(dialogData.propsToContinue);
    }
  };

  return (
    <>
      {isLoading ? (
        <Grid item xs={12}>
          <ApproveRejectSkeleton />
        </Grid>
      ) : null}

      <CustomDialog
        open={dialogData.isOpen}
        icon={<InfoOutlinedIcon className={css.infoIcon} />}
        title={dialogData.title}
        buttons={[
          {
            btnType: buttonTypes.secondary,
            label: appStrings.common.cancel,
            onClick: dialogCloseHandler,
          },
          {
            label: dialogData.continueButtonLabel,
            onClick: dialogContinueHandler,
          },
        ]}
      />

      {!displayedStudents.length && (
        <Grid item xs={12}>
          <CustomBox customClass={css.customBox}>
            <NoDataAvailable
              icon={icons.userWaiting}
              message={appStrings.approveReject.noStudentsAvailableText(
                studentsApprovalStateByCode[sectionType]
              )}
            />
          </CustomBox>
        </Grid>
      )}

      <Grid
        container
        className={classNames(css.cardsContainer, {
          [css.hideContainer]: isLoading || !displayedStudents.length,
        })}
        data-test-id={`listStudentsCard-${sectionType}`}
        id={`listStudentsCard-${sectionType}`}
      >
        {displayedStudents.map((student, index) => (
          <Grid item key={student.id} xs={12} id={`studentCard-${index}`}>
            <StudentDetailsCard
              student={student}
              showSelectAll={
                sectionType === studentsApprovalState.pending && index === 0
              }
              handleStatusUpdate={handleStatusUpdate}
              index={index}
            />
          </Grid>
        ))}

        <Grid
          item
          container
          className={css.paginationContainer}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={6} sm={8} md={9} lg={10}>
            {students.length ? (
              <CustomPagination
                itemsLength={students.length}
                itemsPerPage={paginationDetails.itemsPerPage}
                id={`listStudentsPagination-${sectionType}`}
                onPageChangeCallback={handlePageChange}
              />
            ) : null}
          </Grid>

          <Grid
            item
            container
            xs={6}
            sm={4}
            md={3}
            lg={2}
            spacing={2}
            justifyContent="center"
          >
            {sectionType === studentsApprovalState.pending ||
            sectionType === studentsApprovalState.rejected ? (
              <Grid item>
                <CustomButton
                  label={appStrings.approveReject.approveSelected}
                  color={buttonColors.green}
                  id={'approveSelected-' + sectionType}
                  onClick={() => handleStatusUpdate()}
                  disabled={!Boolean(checkedStudents.length)}
                  customClass={css.actionButtons}
                />
              </Grid>
            ) : null}
            {sectionType === studentsApprovalState.pending ||
            sectionType === studentsApprovalState.approved ? (
              <Grid item>
                <CustomButton
                  label={appStrings.approveReject.rejectSelected}
                  color={buttonColors.red}
                  customClass={css.actionButtons}
                  id={'rejectSelected-' + sectionType}
                  onClick={() =>
                    handleStatusUpdate(null, studentsApprovalState.rejected)
                  }
                  disabled={!Boolean(checkedStudents.length)}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

ListStudentsCards.propTypes = propTypes;

export default ListStudentsCards;
