import { makeStyles } from 'tss-react/mui';

export const bannerCarouselXStyles = makeStyles()(theme => ({
  hasEditButton: {
    position: 'relative',
  },
  customCarouselCaption: {
    whiteSpace: 'normal !important',
  },
  sliderContainer: {
    position: 'static !important',
  },
  wrapper: {
    '& .slider-container': {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: -1,

      '@media screen and (min-width: 768px)': {
        position: 'relative',
      },

      '@media screen and (max-width: 768px)': {
        position: 'absolute',
      },

      '& .slick-track': {
        height: '100%',
        '& div': {
          height: '100%',
        },
        '& .slide-content': {
          position: 'relative',
        },
      }
    },

    '& .home-slide': {
      '@media screen and (min-width: 1536px)': {
        height: '100vh',
      },
      '& .slick-list': {
        height: '100% !important',
        transform: 'translateZ(0)',
      },
    },

    '& .picture-holder': {
      width: '100%',
      height: '100%',
      position: 'absolute',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    },

    '& .custom-carousel-caption': {
      color: theme.palette.common.white,
      position: 'absolute',
      left: '8%',
      top: '8%',
      whiteSpace: 'pre-wrap',
    
      '& h3': {
        borderLeft: '3px solid red',
        padding: '2px 0 0 10px',
        fontSize: '2.3rem !important',
        fontWeight: 'bold',
        margin: '5px auto',
        '@media screen and (max-width: 600px)': {
          fontSize: '1.6rem !important',
        },
      },

      '@media screen and (max-width: 600px)': {
        '& p': {
          fontSize: '0.9rem !important',
        },
      }
    },

    '& .carousel-img': {
      maxWidth: '100%',
      height: 'auto',
      verticalAlign: 'middle',
    },
  },
  'red-on-white': {
    textDecoration: 'none',
    background: 'red',
    color: '#fff',
    display: 'inline-block',
    fontSize: '1rem',
    fontWeight: '700',
    marginTop: '24px',
    padding: '1em',
    textTransform: 'uppercase',
    '@media screen and (max-width: 600px)': {
      fontSize: '0.6rem',
      padding: '0.6em',
      marginTop: '6px',
    },
  },
  'black-on-white': {
    textDecoration: 'none',
    background: '#fff',
    border: '2px solid #333',
    color: '#333 !important',
    display: 'inline-block',
    fontSize: '1rem',
    fontWeight: '700',
    marginTop: '24px',
    padding: '1em',
    textTransform: 'uppercase',
    '@media screen and (max-width: 600px)': {
      fontSize: '0.6rem',
      padding: '0.6em',
      marginTop: '6px',
    },
  },
  'text-white': {
    color: 'white',
  },
}));