import {makeStyles} from 'tss-react/mui';

const inlineBlock = 'inline-block';
export const bannerXStyles = makeStyles()(theme => ({
  'full-height': {
    minHeight: 'calc(100vh - 103px) !important',
    '@media screen and (max-width: 767px)': {
      minHeight: '50vh !important'
    },
  },
  bannerX: {
    backgroundSize: 'cover',
    '&.half-height': {
      minHeight: '75vh !important',
      '@media screen and (max-width: 767px)': {
        minHeight: '50vh !important',
      },
    },
    // CTA style
    },
    'red-on-white': {
      textDecoration: 'none',
      background: 'red',
      color: '#fff',
      display: inlineBlock,
      fontSize: '1rem',
      fontWeight: '700',
      marginTop: '24px',
      padding: '1em',
      textTransform: 'uppercase'
    },
    'black-on-white': {
      textDecoration: 'none',
      background: '#fff',
      border: '2px solid #333',
      color: '#333 !important',
      display: inlineBlock,
      fontSize: '1rem',
      fontWeight: '700',
      marginTop: '24px',
      padding: '1em',
      textTransform: 'uppercase',
    },
    'text-white': {
      color: 'white',
    },
    brTag: {
      backgroundColor: 'red',
      display: inlineBlock,
      fontSize: 'x-large',
      fontStyle: 'oblique',
      padding: '0 7px',
      textTransform: 'uppercase',
      lineHeight: '32px',
      '@media screen and (max-width: 767px)': {
        fontSize: 'small'
      },
      margin: 0,
    },
  articleContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  sectionContent: {
    padding: '25px',
    maxWidth: '100%',
  },
  textLeft: {
    textAlign: 'left',
  },
  textCenter: {
    textAlign: 'center',
  },
  textRight: {
    textAlign: 'right',
  },
  textJustify: {
    textAlign: 'justify'
  },
  greyBackground: {
    backgroundColor: 'rgba(56, 56, 56, 0.8)',
  },
  blackBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  rightContainer: {
    alignSelf: 'flex-end',
  },
  centerContainer: {
    alignSelf: 'center',
  },
  leftContainer: {
    alignSelf: 'flex-start',
  },
  sectionInnerContent: {
    marginLeft: '65px',
    'force-left': {
      marginLeft: '-32px',
      '@media screen and (max-width: 767px)': {
          marginLeft: 0,
      }
    },
    'br-title': {
      p: {
        lineHeight: '1.2',
      }
    },
    article: {
      p: {
        marginBottom: '1.4em',
        '@media screen and (min-width: 1024px)': {
          marginBottom: '1.6em',
        },
      },
    }
  },
  sectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    padding: '0 5%',
  },
  brTitle: {
    display: 'block',
    padding: '1% 0',
    p: {
      display: 'inline',
      fontSize: '2.5rem',
    },
    em: {
      color: 'red',
      paddingRight: '10px',
    }
  },
}));