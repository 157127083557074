import { Grid, Typography } from '@mui/material';

import CustomAccordion from '../../../shared/CustomAccordion/CustomAccordion';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import appStrings from '../../../../core/strings/appStrings';
import { states } from '../../../../core/strings/states';
import useOrderHistoryDetailsStyles from '../useOrderHistoryDetailsStyles';

function OrderShippingAndBilling({ orderDetails }) {
  const css = useOrderHistoryDetailsStyles().classes;

  return !orderDetails ? null : (
    <Grid
      item
      xs={12}
      data-test-id="orderHistoryDetails-orderShippingAndBilling"
    >
      <CustomBox>
        <CustomAccordion
          title={appStrings.order.shippingAndBilling}
          customClassDescription={css.accordion}
          identifier="historyOrderDetails-orderShippingAndBilling"
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              container
              id="historyOrderDetails-shippingTo"
            >
              <Grid item xs={12}>
                <Typography variant="h3" className={css.sectionTitle}>
                  {`${appStrings.order.shippingTo}:`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {`${appStrings.order.customerName}: `}
                  <span className={css.highlight}>
                    {orderDetails.shippingAddress.recipient}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {`${appStrings.order.addressLine1}: `}
                  {orderDetails.shippingAddress.address.addressLine1}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {`${appStrings.order.addressLine2}: `}
                  {orderDetails.shippingAddress.address.addressLine2}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {`${appStrings.common.zipCode}: `}
                  {orderDetails.shippingAddress.address.zipCode}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {`${appStrings.common.city}: `}
                  {orderDetails.shippingAddress.address.city}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {`${appStrings.common.state}: `}
                  {states[orderDetails.shippingAddress.address.state]}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {`${appStrings.orderDetailsHistory.shippingAndHandling}: `}
                  {appStrings.orderDetailsHistory.freeShipping}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              container
              id="historyOrderDetails-billingTo"
            >
              <Grid item xs={12}>
                <Typography
                  variant="h3"
                  className={css.sectionTitle}
                >{`${appStrings.order.billingTo}:`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {`${appStrings.order.customerName}: `}
                  <span className={css.highlight}>
                    {orderDetails.billingAddress.recipient}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {`${appStrings.order.addressLine1}: `}
                  {orderDetails.billingAddress.address.addressLine1}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {`${appStrings.order.addressLine2}: `}
                  {orderDetails.billingAddress.address.addressLine2}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {`${appStrings.common.zipCode}: `}
                  {orderDetails.billingAddress.address.zipCode}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {`${appStrings.common.city}: `}
                  {orderDetails.billingAddress.address.city}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {`${appStrings.common.state}: `}
                  {states[orderDetails.billingAddress.address.state]}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CustomAccordion>
      </CustomBox>
    </Grid>
  );
}

export default OrderShippingAndBilling;
