import { createContext } from 'react';

const CartContext = createContext({
  addingProduct: {
    lnLineNotes: null,
    productNumber: '',
    quantity: '',
    serialNumber: null,
    serialNumberValue: null,
    studentNotes: null,
    warehouse: undefined,
  },
  bp: '',
  cartResetDone: {},
  deletingItems: {},
  expandedSection: '',
  handleTotals: totals => {},
  id: '',
  isInactiveStudent: false,
  isOrder: false,
  isPdfExportDisabled: false,
  isSavedItems: false,
  onlyConsignedSelected: false,
  orderStatus: { completed: false, orderNumber: null },
  rowsLoading: [],
  setAddingProduct: () => {},
  setDeletingItems: () => {},
  setExpandedSection: () => {},
  setIsPdfExportDisabled: () => {},
  setOnlyConsignedSelected: () => {},
  setOrderStatus: () => {},
  setRowsLoading: () => {},
  showLoading: true,
  student: {
    active: true,
    email: '',
    firstName: '',
    id: '',
    lastName: '',
  },
  completeOrderClicked: false,
  setCompleteOrderClicked: () => {},
  resetAddresses: false,
  setResetAddresses: () => {},
  completingOrder: false,
  setCompletingOrder: () => {},
  resetPaymentData: false,
  setResetPaymentData: () => {},
  deleteItems: () => {},
});

export default CartContext;
