import { Backdrop, Grid, useMediaQuery, useTheme } from '@mui/material';

import CategoriesMenu from '../../student/Category/CategoriesMenu/CategoriesMenu';
import Footer from '../Footer/Footer';
import Header from '../../student/Header/Header';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
import Menu from '../../industrial/Menu/Menu';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import useMainContainerStyles from './useMainContainerStyles';
import { useSelector } from 'react-redux';
import Notification from '../Notification/Notification';
import { notificationRole } from '../../../core/strings/appConstants';
import {
  getFullDevices,
  getWhiteBackground,
  isBRContent,
  isHomePage,
} from '../../../core/services/utilsService';
import { PRODUCTSEARCHRESULTS } from '../../../core/navigation/paths';
import InactiveNotification from '../InactiveNotification/InactiveNotification';

const propTypes = {
  setMenuToLeft: PropTypes.bool,
};

// eslint-disable-next-line sonarjs/cognitive-complexity
function MainContainer({ children, setMenuToLeft }) {
  const theme = useTheme();
  const css = useMainContainerStyles().classes;
  const { pathname } = useLocation();
  const {showLoading, loadingMessage} = useSelector(state => state.common);
  const isXsDevice = useMediaQuery(theme.breakpoints.only('xs'));
  const isSmallDevice = useMediaQuery('(max-width:768px)');
  const { isLoggedIn, userDetails } = useSelector(state => state.login);
  const isRepOrAdmin = ['Admin', 'Rep'].indexOf(userDetails.role) !== -1;
  const [isOnProductsSearchResults, setIsOnProductsSearchResults] =
    useState(false);
  const [isFullWidthScreen, setIsFullWidthScreen] = useState(false);

  const mainContainerClasses = classNames(css.mainContainer, {
    [css.leftMainContainer]: setMenuToLeft,
  });
  const childrenContainerClasses = classNames(
    setMenuToLeft ? css.childrenContainer : css.childrenContainerHeaderView,
    isRepOrAdmin ? css.repContainerView : css.studentContainerView,
    isBRContent(pathname) ? css.brContainerView : '',
    getFullDevices(pathname, isSmallDevice) || isHomePage(pathname)
      ? css.fullDevices
      : '',
    getWhiteBackground(pathname) ? css.whiteBackground : '',
    {
      [css.childrenForTopSideMenu]: !setMenuToLeft,
      [css.childrenForTopSideMenuMobile]: !setMenuToLeft,
      [css.childrenForTopSideMenuXs]: isSmallDevice,
    }
  );

  useEffect(() => {
    const isFullWidthScreenUrl =
      pathname.indexOf('ecAppWizard') !== -1 ||
      pathname.indexOf('ecContractSigning') !== -1;
    if (isLoggedIn && isFullWidthScreenUrl) {
      setIsFullWidthScreen(true);
    } else {
      setIsFullWidthScreen(false);
    }
    setIsOnProductsSearchResults(pathname.indexOf(PRODUCTSEARCHRESULTS) !== -1);
  }, [pathname, isLoggedIn, userDetails]);

  const withLeftMenuView = (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      wrap="nowrap"
      className={mainContainerClasses}
      data-test-id="mainContainerLeft"
    >
      <Menu />

      <Grid item className={childrenContainerClasses}>
        {children}
      </Grid>

      <Notification role={notificationRole.industrial} />

      <Backdrop open={showLoading} className={css.backdrop}>
        <LoadingOverlay customClass={css.loading} loadingMessage={loadingMessage} />
      </Backdrop>
    </Grid>
  );

  const withHeaderView = (
    <>
      <Grid className={css.navContainer}>
        {!isFullWidthScreen && <Header />}

        {!isSmallDevice && !isFullWidthScreen && <CategoriesMenu />}
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        className={mainContainerClasses}
        data-test-id="mainContainerTop"
      >

        {isXsDevice && <Notification role={notificationRole.student} />}


        <Grid
          container
          className={`${
            isFullWidthScreen && 'onStudentEcApp'
          } ${childrenContainerClasses}`}
          justifyContent={`${isOnProductsSearchResults ? 'left' : 'center'}`}
        >
          {!isXsDevice && <Notification role={notificationRole.student} />}
          <Grid
            item
            container
            direction="column"
            md={isHomePage(pathname) ? 12 : 10}
          >
            <Notification role={notificationRole.industrial} />
            {isLoggedIn && !userDetails.isActive && (
              <InactiveNotification onHomeScreen={isHomePage(pathname)} />
            )}
            {children}
          </Grid>
        </Grid>
        <Backdrop open={showLoading} className={css.backdrop}>
          <LoadingOverlay customClass={css.loading} loadingMessage={loadingMessage} />
        </Backdrop>
        <Footer />
      </Grid>
    </>
  );

  return !isFullWidthScreen ? (
    setMenuToLeft ? (
      withLeftMenuView
    ) : (
      withHeaderView
    )
  ) : (
    <Grid>
      <Notification
        role={
          setMenuToLeft ? notificationRole.industrial : notificationRole.student
        }
      />
      {children}
    </Grid>
  );
}

MainContainer.propTypes = propTypes;

export default MainContainer;
