import React, { Fragment } from 'react';
import {
  buttonColors,
  buttonTypes,
} from '../../../../core/strings/appConstants';

import CustomBox from '../../CustomBox/CustomBox';
import CustomButton from '../../CustomButton/CustomButton';
import CustomDialog from '../../CustomDialog/CustomDialog';
import CustomIcon from '../../CustomIcon/CustomIcon';
import FormInput from '../../FormInput/FormInput';
import { Grid } from '@mui/material';
import appStrings from '../../../../core/strings/appStrings';
import classNames from 'classnames';
import { icons } from '../../../../core/strings/icons';
import { useAddressesStyles } from './useAddressesStyles';
import useStudentProfileFormStyles from '../useStudentProfileFormStyles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MandatoryFieldsMessage from '../../MandatoryFieldsMessage/MandatoryFieldsMessage';

const getDeleteMessage = ({ hasDelete, deleteLabel }) => {
  if (hasDelete) {
    const deletedAddress = deleteLabel?.includes(
      appStrings.addresses.currentAddress
    )
      ? appStrings.addresses.currentAddress
      : appStrings.addresses.previousAddress;
    return appStrings.studentProfile.removeProfileInfo(deletedAddress);
  }
  return '';
};

function InputsTemplates({
  inputs,
  formProps,
  title,
  hasDelete = false,
  deleteLabel,
  handleDelete,
  deleteId,
  hasCounting = false,
  id,
  titleInsideTheBox = false,
  customContainerCss = false,
  isStudent,
  showMandatoryMessage = false,
}) {
  const formStyle = useStudentProfileFormStyles().classes;
  const css = useAddressesStyles().classes;

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteButtons = [
    {
      btnType: buttonTypes.secondary,
      label: appStrings.common.cancel,
      onClick: handleClose,
    },
    {
      btnType: buttonTypes.primary,
      color: buttonColors.red,
      label: appStrings.common.remove,
      onClick: handleDelete,
    },
  ];

  const getTitleComponent = () => (
    <Grid item container alignItems="flex-end">
      <Grid item xs>
        <h3
          data-test-id="inputGroupTitle"
          className={classNames(css.title, {
            [formStyle.line]: hasCounting,
            [css.titleInsideTheBox]: titleInsideTheBox,
          })}
          id={id + '-title'}
        >
          {`${title}${hasCounting ? ' #' : ''}`}
        </h3>
      </Grid>
      <Grid item className={css.title}>
        {isStudent && getDeleteButton()}
      </Grid>
    </Grid>
  );

  const getDeleteButton = () =>
    hasDelete && (
      <div
        className={
          !isStudent ? css.deleteContainer : css.studentDeleteContainer
        }
      >
        <CustomButton
          btnType={buttonTypes.secondary}
          color={!isStudent ? buttonColors.red : buttonColors.black}
          label={!isStudent ? deleteLabel : appStrings.common.delete}
          startIcon={isStudent && <DeleteOutlineIcon />}
          onClick={handleClickOpen}
          id={deleteId}
          data-test-id={deleteId}
          size={isStudent ? 'small' : ''}
        />

        <CustomDialog
          open={open}
          handleClose={handleClose}
          icon={
            <CustomIcon icon={icons.trash} customClasses={css.deleteIcon} />
          }
          title={getDeleteMessage({ hasDelete, deleteLabel })}
          buttons={deleteButtons}
        />
      </div>
    );

  return (
    <>
      {title && !titleInsideTheBox && getTitleComponent()}

      <CustomBox customClass={css.addressContainer} fadeBorder id={id}>
        <Grid
          container
          direction="row"
          spacing={2}
          className={customContainerCss ? customContainerCss : ''}
        >
          {title && titleInsideTheBox && getTitleComponent()}

          {inputs.map(field => {
            const inputField = (hideInput = false) =>
              hideInput ? null : (
                <FormInput
                  {...formProps}
                  field={field}
                  key={`${field.name}-inputs`}
                  size={isStudent ? 'medium' : null}
                />
              );
            const emptySpace = () =>
              field.label === appStrings.addresses.years ? (
                <Grid
                  item
                  {...field.gridLayout}
                  key={`${field.name}-emptySpace`}
                ></Grid>
              ) : null;

            return field.hide || field.type === 'hidden' ? (
              inputField(field.hide)
            ) : (
              <Fragment key={`${field.name}-container`}>
                {emptySpace()}
                <Grid item {...field.gridLayout} data-test-id={`${field.name}`}>
                  {inputField()}
                </Grid>
              </Fragment>
            );
          })}
          <Grid item>
            {showMandatoryMessage && <MandatoryFieldsMessage />}
          </Grid>
        </Grid>
      </CustomBox>

      {!isStudent && getDeleteButton()}
    </>
  );
}

export default InputsTemplates;
