import PropTypes from 'prop-types';

export const propTypes = {
  buttonType: PropTypes.string,
  menuId: PropTypes.string.isRequired,
  menuLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      handler: PropTypes.func,
      icon: PropTypes.string,
      id: PropTypes.string,
      isDisabled: PropTypes.bool,
      label: PropTypes.string,
      customElements: PropTypes.func,
    })
  ),
};
