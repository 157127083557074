import { makeStyles } from 'tss-react/mui';

const useCategoriesMenuStyles = makeStyles()(theme => ({
  mainContainer: {
    border: 'solid',
    borderWidth: theme.spacing(0, 0, 0.3, 0),
    borderColor: theme.palette.common.shadow,
    backgroundColor: theme.palette.common.white,

    '& a': {
      height: '100%',
      width: '100%',
      textDecoration: 'none',
      textAlign: 'center',
      color: theme.palette.text.secondary,
      borderBottom: `${theme.spacing(0.4)} solid transparent`,
      '&:hover': {
        borderBottomColor: 'red',
        fontWeight: 'bold',
      },
    },

    '#SepCatalogButton': {
      '& button': {
        fontWeight: 'bold',
        color: `${theme.palette.common.darkRed} !important`,
        borderWidth: `${theme.spacing(0.3)}`,
        borderColor: `${theme.palette.common.shadow} !important`,
      },
    },

    '& .browse-products-text': {
      marginTop: 'auto',
      marginBottom: 'auto',
    },

    '& .browse-products-container': {
      color: theme.palette.common.red,
      fontWeight: '700',
      flexWrap: 'nowrap',

      '& svg': {
        fontSize: '20px',
        color: theme.palette.common.red,
      },
    },
  },

  linkContainer: {
    alignContent: 'center',
    padding: theme.spacing(0, 0.5, 0, 0.5),
    height: theme.spacing(8),
  },

  isActive: {
    fontWeight: 'bold',
    borderBottom: `${theme.spacing(0.8)} solid`,
    borderBottomColor: 'red !important',
  },

  hoverContainer: {
    backgroundColor: '#f1f1f1',
    borderBottom: `${theme.spacing(0.3)} solid`,
    borderTop: `${theme.spacing(0.3)} solid`,
    borderColor: theme.palette.common.shadow,
    width: '100%',
    padding: theme.spacing(2, 0, 0, 0),
    textAlign: 'center',

    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.secondary,
      '&:hover p': {
        color: theme.palette.common.darkRed,
        fontWeight: 'bolder',
      },
    },

    '& p': {
      textDecoration: 'none',
      color: theme.palette.text.secondary,
    },

    '& img': {
      height: 'auto',
      width: '100%',
      maxHeight: theme.spacing(9),
      maxWidth: theme.spacing(16),
    },

    '& .categories': {
      flexWrap: 'nowrap',
    },

    '& .category-container': {
      background: theme.palette.common.white,
      borderRadius: '10%',
      marginBottom: theme.spacing(1),
      width: '160px',

      '&:hover': {
        boxShadow: '0px 2px 4px 0px #00000033',
      }
    }
  },

  hamburgerContainer: {
    backgroundColor: 'white',
    border: 'solid',
    borderWidth: theme.spacing(0.3, 0, 0.3, 0),
    borderColor: theme.palette.common.shadow,
    padding: theme.spacing(2),

    '& img': {
      padding: theme.spacing(1),
      border: `${theme.spacing(0.3)} solid`,
      borderColor: theme.palette.common.shadow,
      height: 'auto',
      width: '100%',
      maxHeight: theme.spacing(5),
      maxWidth: theme.spacing(5),
    },

    '& p': {
      marginLeft: theme.spacing(2),
    },

    '& a': {
      textDecoration: 'none',
      color: 'black',
      fontWeight: 'bold',
    },

    '#SepCatalogButton': {
      borderWidth: `${theme.spacing(0.3)} !important`,

      '& button': {
        fontWeight: 'bold',
        width: '100%',
        borderColor: `${theme.palette.common.shadow} !important`,
        color: `${theme.palette.common.darkRed} !important`,
      },
    },
  },

  hamburgerCategory: {
    marginBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.common.shadow}`,
    paddingBottom: theme.spacing(1),
    
    '& .category-link': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: theme.spacing(3),
    }
  },

  hamburgerCategoryActive: {
    borderLeft: `${theme.spacing(1)} solid`,
    borderLeftColor: 'red !important',
    paddingLeft: theme.spacing(1),
    borderRadius: '0.2rem',
  },

  catalogButton: {
    '@media screen and (max-width:900px)': {
      padding: '5px 15px !important',
    },
  },
}));

export default useCategoriesMenuStyles;
