import { makeStyles } from 'tss-react/mui';

const useRegisterConfirmationStyles = makeStyles()(theme => ({
  registerConfirmationContainer: {
    padding: theme.spacing(10),
    marginTop: theme.spacing(2),
  },
  leftMargin: {
    marginLeft: '5px',
  },
}));

export default useRegisterConfirmationStyles;
