import appStrings from '../../../../core/strings/appStrings';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import store from '../../../../core/redux/store';
import { useEffect } from 'react';

export const UseErrorSnackbars = ({ errors }) => {
  useEffect(() => {
    if (errors) {
      const hasEmptyField =
        Object.values(errors).filter(field => field.type === 'required')
          .length !== 0;
      const hasErrors =
        Object.values(errors).filter(field => field.type !== 'required')
          .length !== 0;

      if (hasErrors) {
        store.dispatch(
          displayError({ message: appStrings.validationMessages.correctErrors })
        );
      }

      if (hasEmptyField && !hasErrors) {
        store.dispatch(
          displayError({
            message: appStrings.validationMessages.requiredFields,
          })
        );
      }
    }
  }, [errors]);
};

export const UseUpdateErrors = ({ formFields, clearErrors }) => {
  useEffect(() => {
    if (formFields.city) {
      clearErrors('city');
    }
    if (formFields.state) {
      clearErrors('state');
    }
    if (formFields.schoolState) {
      clearErrors('schoolState');
    }
    if (formFields.schoolBpNumber) {
      clearErrors('schoolBpNumber');
    }
    if (formFields.educationAccountManagerName) {
      clearErrors('educationAccountManagerName');
    }
  }, [
    clearErrors,
    formFields.city,
    formFields.schoolState,
    formFields.schoolBpNumber,
    formFields.educationAccountManagerName,
    formFields.state,
  ]);
};
