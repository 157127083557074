import { Divider, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import appStrings from '../../../../../core/strings/appStrings';
import InputsTemplates from '../../../../shared/StudentProfileForm/Addresses/InputsTemplate';
import FormInput from '../../../../shared/FormInput/FormInput';
import {
  currentAddressInputs,
  permanentAddressInputs,
} from '../../../../shared/StudentProfileForm/Addresses/addressesInputs';
import { switchFields } from '../../../../shared/ECAppWizard/WizardPageContainer/CustomerInfo/customerInfoConstants';
import { generatePresentInputs } from '../../../../shared/StudentProfileForm/EmployerInformation/employerInfoInputs';
import {
  onEmploymentLengthChange,
  onZipCodeChangeHandler,
} from '../../../../shared/StudentProfileForm/EmployerInformation/employerInformationService';
import PropTypes from 'prop-types';
import MandatoryFieldsMessage from '../../../../shared/MandatoryFieldsMessage/MandatoryFieldsMessage';
import useEditProfileStyles from '../editProfileStyles';

function ApplicationFields({
  getValues,
  setValue,
  control,
  watch,
  errors,
  setIsCurrentAddressSameAsPermanent,
  isCurrentAddressSameAsPermanent,
}) {
  const [formWasSubmitted, setFormWasSubmitted] = useState(false);
  const [disabledStates, setDisabledStates] = useState({});
  const [disableUsesTools, setDisableUsesTools] = useState(false);
  const css = useEditProfileStyles().classes

  const changeActions = {
    onZipCodeChange: ({ section, index }) =>
      onZipCodeChangeHandler({
        section,
        index,
        getValues,
        setValue,
        setDisabledStates,
      }),
    onEmploymentLengthChange: ({ section, index }) =>
      onEmploymentLengthChange({ section, index, getValues, setValue }),
  };

  const formProps = {
    control,
    setValue,
    watch,
    errors,
    getValues,
  };

  return (
    <Grid container spacing={4}>
      <Grid item container>
        <Typography fontWeight="bold" fontSize="medium">
          {appStrings.studentProfile.addresses}
        </Typography>
        <InputsTemplates
          formProps={formProps}
          inputs={permanentAddressInputs({
            getValues,
            setValue,
            formWasSubmitted,
            disabledStates,
            setDisabledStates,
            otherGridLayout: { xs: 12, sm: 6 },
          })}
          title={`${appStrings.addresses.permanentHome} ${appStrings.register.address}`}
          titleInsideTheBox
        />
        <Grid container className={css.mandatoryMessageContainer}>
          <MandatoryFieldsMessage />
        </Grid>
        <FormInput
          {...formProps}
          field={{
            name: 'currentAddressSameAsPermanent',
            type: 'checkbox',
            label: appStrings.wizard.currentAddSameAsPermLabel,
            value: isCurrentAddressSameAsPermanent,
            onChangeCallback: v => setIsCurrentAddressSameAsPermanent(v),
          }}
        />
        <Grid item>
          {!isCurrentAddressSameAsPermanent ? (
            <InputsTemplates
              formProps={formProps}
              inputs={currentAddressInputs({
                getValues,
                setValue,
                disabledStates,
                setDisabledStates,
                otherGridLayout: { xs: 12, sm: 6 },
              })}
              title={appStrings.addresses.currentAddress}
              titleInsideTheBox
            />
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight="bold" fontSize="medium">
            {appStrings.studentProfile.employerInformation}
          </Typography>
        </Grid>
        {switchFields({
          getValues,
          setValue,
          disableUsesTools,
          setDisableUsesTools,
        }).map(field => (
          <Grid
            item
            {...field.gridLayout}
            key={`${field.name}-container`}
            data-test-id={`${field.name}`}
          >
            <FormInput
              field={field}
              control={control}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              errors={errors}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Typography fontWeight="bold">
            {appStrings.studentProfile.requiredForContract}
          </Typography>
          <InputsTemplates
            formProps={formProps}
            inputs={generatePresentInputs({
              changeActions,
              getValues,
              setValue,
              disabledStates,
              otherGridLayout: { xs: 12, sm: 6 },
            })}
            title={appStrings.studentProfile.presentEmployer}
            titleInsideTheBox
            id="presentEmployerApplication"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

ApplicationFields.propTypes = {
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  control: PropTypes.object,
  watch: PropTypes.func,
  errors: PropTypes.object,
  setIsCurrentAddressSameAsPermanent: PropTypes.func,
  isCurrentAddressSameAsPermanent: PropTypes.bool,
};

export default ApplicationFields;
