const CONSTANTS = {
  approveReject: 'approveReject',
  cart: 'cart',
  categories: 'categories',
  common: 'common',
  consignedInventory: 'consignedInventory',
  guestCart: 'guestCart',
  history: 'history',
  login: 'login',
  notifications: 'notifications',
  purchasePower: 'purchasePower',
  register: 'register',
  restorePurchases: 'restorePurchases',
  schoolsWithStudentsSlice: 'schoolsWithStudentsSlice',
  signalR: 'signalR',
  signing: 'signing',
  studentSearch: 'studentSearch',
  wizard: 'wizard',
  zipCode: 'zipCode',
};
export default CONSTANTS;
