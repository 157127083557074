import { makeStyles } from 'tss-react/mui';

export const useProductsSetsStyles = makeStyles()(theme => ({
  arrowButton: {
    color: theme.palette.common.red,
    '& svg': {
      fontSize: '32px'
    },
  },

  backLink: {
    cursor: "pointer",
  },

  titleText: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  setItemContainer: {
    borderTop: `1px solid ${theme.palette.common.neutral4}`,
    padding: `${theme.spacing(1)} 0`,
    height: theme.spacing(14),
    alignItems: 'center',
    flexWrap: 'nowrap',

    '@media screen and (max-width: 768px)': {
      height: 'unset',
      minHeight: theme.spacing(14),
    },

    '& .MuiGrid-item': {
      padding: '0 !important',
    },
    '& .product-title': {
      color: theme.palette.common.red,
      textDecoration: 'none',
      cursor: 'pointer',
    },
    '& .product-name': {
      '@media screen and (max-width: 768px)': {
        fontSize: '14px',
      },
    },
    '& .picture-link': {
      cursor: 'pointer',
      height: '100%',
      alignContent: 'center',
      justifyContent: 'center',
    },
    '& .disabled-link': {
      cursor: 'default',
    },
  },

  setItemImage: {
    maxWidth: '72px',
    maxHeight: theme.spacing(13),
  },

  itemContentContainer: {
    height: '100%',
  },

  itemPrice: {
    marginTop: 'auto',
  },

  cartButton: {
    borderRadius: '50%',
    border: `solid ${theme.spacing(0.2)}`,
    borderColor: `${theme.palette.common.secondary6} !important`,
    backgroundColor: theme.palette.background.default,
    padding: `${theme.spacing(1)} !important`,
    width: theme.spacing(5.5),
    color: 'black',
    cursor: 'pointer',

    '&.cart-button-disabled': {
      cursor: 'default',
    },

    '& .disabled': {
      color: `${theme.palette.common.grey2} !important`,
    },
  },

  setContainer: {
    maxWidth: 'inherit !important',
  },
}));
