import CONSTANTS from '../constants';
import { createSlice } from '@reduxjs/toolkit';

const signingSlice = createSlice({
  name: CONSTANTS.signing,
  initialState: { status: false },
  reducers: {
    setStatus: state => {
      state.status = true;
    },
  },
});

export const { setStatus } = signingSlice.actions;
export default signingSlice.reducer;
