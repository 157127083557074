import { Grid, Skeleton } from '@mui/material';
import React from 'react';

function CartTotalsDesktopSkeleton() {
  const textHeight = { height: 24 };
  const buttonHeight = { height: 34 };

  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={12}>
        <Skeleton width="30%" {...textHeight} />
        <Skeleton width="60%" {...textHeight} />
        <Skeleton width="40%" {...textHeight} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton height={34} {...buttonHeight} />
        <Skeleton height={34} {...buttonHeight} />
        <Skeleton height={34} {...buttonHeight} />
      </Grid>
    </Grid>
  );
}

export default CartTotalsDesktopSkeleton;
