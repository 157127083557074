import { CircularProgress, Grid, useMediaQuery, useTheme } from '@mui/material';
import { Fragment, useEffect, useRef, useState } from 'react';
import {
  LOGIN,
  RESEND_LINK,
  RESET_LINK_EXPIRED,
} from '../../../core/navigation/paths';
import { authenticate, handleAuthentication } from '../Login/loginService';
import { buttonColors, buttonTypes } from '../../../core/strings/appConstants';
import { gridLayout, resetPassInputs } from './resetPasswordConstants';

import CustomButton from '../CustomButton/CustomButton';
import FormInput from '../FormInput/FormInput';
import LoginErrors from '../Login/LoginErrors/LoginErrors';
import appStrings from '../../../core/strings/appStrings';
import { displaySuccess } from '../../../core/redux/slices/notificationsSlice';
import {
  submitResetPassword,
  validateResetPasswordLink,
} from './resetPasswordService';
import { useDispatch } from 'react-redux';
import useForgotPasswordStyles from '../ForgotPassword/useForgotPasswordStyles';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router';

function ResetPassword() {
  const { token } = useParams();
  const css = useForgotPasswordStyles().classes;
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const passInputRef = useRef();
  const theme = useTheme();
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm({ mode: 'onChange' });

  const [tokenExpired, setTokenExpired] = useState(
    location?.pathname === RESET_LINK_EXPIRED
  );
  const [receivedErrors, setReceivedErrors] = useState(
    tokenExpired ? appStrings.forgotPassword.expiredResetToken : null
  );
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const isXsDevice = useMediaQuery(theme.breakpoints.down('xs'));
  const isBigDevice = !isXsDevice && !isSmallDevice;
  const gridLayoutForBigAndSmallDevice = isBigDevice ? 4 : 8;

  useEffect(() => {
    validateResetPasswordLink(token)
      .then(res => setTokenExpired(!res))
      .catch(() => setTokenExpired(true));
  }, []);
  const handleErrors = error => {
    if (
      error
        ?.toLowerCase()
        .includes(appStrings.forgotPassword.resetMyPassword.toLowerCase())
    ) {
      setTokenExpired(true);
      return navigate(RESET_LINK_EXPIRED);
    }

    setReceivedErrors(error);
    setSubmitInProgress(false);
  };

  const onSubmit = data => {
    setSubmitInProgress(true);
    setReceivedErrors(null);
    const newPassword = data.password;
    const submitData = {
      token,
      newPassword,
    };
    submitResetPassword(submitData)
      .then(response => {
        dispatch(
          displaySuccess({
            message: appStrings.forgotPassword.resetPassConfirmation,
          })
        );
        const loginFormattedData = {
          accountIdentifier: atob(response),
          password: btoa(newPassword),
        };

        authenticate(loginFormattedData)
          .then(resp => handleAuthentication(resp, dispatch, navigate))
          .catch(e => {
            setReceivedErrors(e);
          })
          .finally(() => {
            setSubmitInProgress(false);
          });
      })
      .catch(handleErrors);
  };

  const content = (
    <Grid container>
      <Grid item {...gridLayout}>
        <h3>
          {tokenExpired
            ? appStrings.forgotPassword.passwordLinkExpired
            : appStrings.forgotPassword.resetPassword}
        </h3>
      </Grid>
      <Grid
        item
        {...gridLayout}
        className={isBigDevice ? css.desktopContainer : ''}
      >
        <Grid item {...gridLayout}>
          <LoginErrors error={receivedErrors} />
        </Grid>
        {!tokenExpired ? (
          <Grid item {...gridLayout}>
            <form
              name="resetPass"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              autoComplete="off"
              id="resetPasswordForm"
              data-test-id="resetPass"
            >
              {resetPassInputs(getValues, disableSubmit, setDisableSubmit).map(
                field => (
                  <Fragment key={`${field.name}-container`}>
                    <Grid
                      item
                      {...field.gridLayout}
                      mb={2}
                      data-test-id={field.name}
                    >
                      <FormInput
                        field={field}
                        control={control}
                        errors={errors}
                        fieldRef={field.name === 'password' && passInputRef}
                      />
                    </Grid>
                  </Fragment>
                )
              )}

              <Grid item {...gridLayout}>
                <CustomButton
                  color={buttonColors.red}
                  label={
                    <>
                      {appStrings.forgotPassword.resetMyPassword}
                      {submitInProgress && (
                        <CircularProgress size={14} className={css.loading} />
                      )}
                    </>
                  }
                  data-test-id="resetPassButton"
                  id="resetPasswordButton"
                  customClass={css.forgotPassBtn}
                  disabled={disableSubmit || submitInProgress}
                  type="submit"
                />
              </Grid>
            </form>
          </Grid>
        ) : (
          <Grid item {...gridLayout}>
            <CustomButton
              color={buttonColors.red}
              label={appStrings.forgotPassword.resetMyPassword}
              data-test-id="goToResendLinkBtn"
              id="goToResendLinkButton"
              customClass={css.forgotPassBtn}
              disabled={submitInProgress}
              onClick={() => navigate(RESEND_LINK)}
            />
          </Grid>
        )}

        <Grid
          item
          container
          {...gridLayout}
          justifyContent="flex-end"
          alignItems="center"
        >
          <CustomButton
            btnType={buttonTypes.tertiary}
            label={appStrings.common.cancel}
            data-test-id="cancelButton"
            id="cancelButton"
            customClass={css.cancelBtn}
            onClick={() => navigate(LOGIN)}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  return isXsDevice ? (
    content
  ) : (
    <Grid container justifyContent="center" alignItems="center">
      <Grid
        item
        xs={gridLayoutForBigAndSmallDevice}
        className={css.forgotMainContainer}
      >
        {content}
      </Grid>
    </Grid>
  );
}

export default ResetPassword;
