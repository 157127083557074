import { Grid, Tooltip } from '@mui/material';
import {
  buttonTypes,
  dateFormat,
  ecOrderStatusesByCode,
  ecOrderStatusesByLabel,
  historySortBy,
  historySortDirection,
  historyTransactionType,
  historyTransactionTypeByCode,
  orderStatusesByCode,
  orderStatusesByName,
} from '../../../../core/strings/appConstants';
import { useDispatch, useSelector } from 'react-redux';

import CustomBox from '../../../shared/CustomBox/CustomBox';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import CustomTable from '../../../shared/CustomTable/CustomTable';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import NoDataAvailable from '../../../shared/NoDataAvailable/NoDataAvailable';
import {
  EC_CONTRACT_SIGNING,
  ORDER_HISTORY_DETAILS,
} from '../../../../core/navigation/paths';
import appStrings from '../../../../core/strings/appStrings';
import { updateFilters } from '../../../../core/redux/slices/historySlice';
import useHistoryDetailsStyles from './useHistoryDetailsStyles';
import { useNavigate } from 'react-router';
import SendEmailDialog from '../../SendEmailDialog/SendEmailDialog';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import UpdateContractDialog from '../../UpdateContractDialog/UpdateContractDialog';
import moment from 'moment';
import { formatNumberWithCurrency } from '../../../../core/services/utilsService';
import ResendToExchange from '../../ResendToExchange/ResendToExchange';

function HistoryDetails({ bpId, studentId, onReload }) {
  const {
    rowsCount,
    details: historyDetails,
    filters,
  } = useSelector(state => state.history);
  const css = useHistoryDetailsStyles().classes;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const defaultEmailModalData = {
    open: false,
    isECOrder: false,
    isHistory: true,
    orderId: null,
    handleClose: () => setEmailModalData(defaultEmailModalData),
  };
  const defaultUpdateContractData = {
    open: false,
    studentId,
    handleClose: () => setUpdateContractData(defaultUpdateContractData),
  };
  const [emailModalData, setEmailModalData] = useState(defaultEmailModalData);
  const [updateContractData, setUpdateContractData] = useState(
    defaultUpdateContractData
  );

  const getStatusElement = elem => {
    // for failed sale order display status pending
    const orderStatus =
      elem.status === orderStatusesByName.failed &&
      elem.type === historyTransactionType.order
        ? orderStatusesByName.pending
        : elem.status;
    const statusText =
      elem.type === historyTransactionType.order
        ? orderStatusesByCode[orderStatus]
        : ecOrderStatusesByCode[orderStatus];
    const shouldShowMessage =
      elem.type === historyTransactionType.order
        ? true
        : Boolean(orderStatus !== ecOrderStatusesByLabel.approved);

    if (shouldShowMessage && elem.message?.length) {
      return (
        <Tooltip
          title={<strong>{elem.message}</strong>}
          classes={{ tooltip: css.htmlTooltip }}
          enterTouchDelay={0}
        >
          <strong className={css.statusHighlighted}>{statusText}</strong>
        </Tooltip>
      );
    }
    return statusText;
  };

  const getActionButton = elem => {
    if (
      (elem.status === orderStatusesByName.failed ||
        elem.status === orderStatusesByName.pending) &&
      elem.type === historyTransactionType.order
    ) {
      return (
        <p className={css.primaryActionText}>
          <strong>{appStrings.history.orderPending}</strong>
        </p>
      );
    }
    //TODO: onclick handler
    return (
      <Grid container justifyContent="flex-end" spacing={1}>
        <Grid item xs={9} container justifyContent="center">
          <CustomButton
            label={
              elem.type !== historyTransactionType.ecApplication
                ? appStrings.history.viewOrderDetails
                : appStrings.history.viewEcApp
            }
            btnType={buttonTypes.tertiary}
            customClass={css.actionDetails}
            onClick={() => {
              if (elem.type === historyTransactionType.ecApplication) {
                setEmailModalData(prevState => ({
                  ...prevState,
                  open: true,
                  orderId: elem.number,
                }));
              } else if (
                elem.type === historyTransactionType.ecOrder &&
                (elem.status === ecOrderStatusesByLabel.openWithDisclosure ||
                  elem.status === ecOrderStatusesByLabel.signing ||
                  elem.status === ecOrderStatusesByLabel.open)
              ) {
                return navigate(
                  EC_CONTRACT_SIGNING(bpId, studentId, elem.number)
                );
              } else if (
                elem.type === historyTransactionType.ecOrder &&
                (elem.status === ecOrderStatusesByLabel.submitted ||
                  elem.status === ecOrderStatusesByLabel.approved ||
                  elem.status === ecOrderStatusesByLabel.completed ||
                  elem.status === ecOrderStatusesByLabel.declined ||
                  elem.status === ecOrderStatusesByLabel.deleted)
              ) {
                setEmailModalData(prevState => ({
                  ...prevState,
                  open: true,
                  isECOrder: true,
                  orderId: elem.number,
                }));
              } else {
                return navigate(
                  ORDER_HISTORY_DETAILS(bpId, studentId, elem.number)
                );
              }
            }}
          />
        </Grid>
        <Grid item xs={3} container justifyContent="center">
          {elem.type === historyTransactionType.ecOrder &&
            elem.status === ecOrderStatusesByLabel.declined && (
              <CustomButton
                icon={<EditIcon />}
                btnType={buttonTypes.tertiary}
                customClass={css.updateButton}
                onClick={() =>
                  setUpdateContractData(prevState => ({
                    ...prevState,
                    open: true,
                    statusText: elem.message,
                    orderNumber: elem.number,
                  }))
                }
              />
            )}
          {elem.type !== historyTransactionType.ecApplication &&
            !elem.isSentToExchange && (
              <ResendToExchange
                orderNumber={elem.number}
                btnType={buttonTypes.tertiary}
                callback={onReload}
              />
            )}
        </Grid>
      </Grid>
    );
  };

  const getTotalElement = elem => {
    if (elem.type === historyTransactionType.ecApplication) {
      return '-';
    }
    return formatNumberWithCurrency(elem.total);
  };

  const rowData = historyDetails?.map(elem => ({
    ...elem,
    transactionType: historyTransactionTypeByCode[elem.type],
    transactionDate: moment(new Date(elem.date)).format(dateFormat),
    transactionStatus: getStatusElement(elem),
    transactionTotal: getTotalElement(elem),
    primaryAction: getActionButton(elem),
  }));

  const handleSortChanges = filedName => {
    const field = filedName.includes('transaction')
      ? filedName.toLowerCase().replace('transaction', '')
      : filedName;
    const sortBy = historySortBy[field] ?? historySortBy.none;

    const sortDirection =
      filters.sortBy === sortBy &&
      filters.sortDirection === historySortDirection.ascending
        ? historySortDirection.descending
        : historySortDirection.ascending;

    dispatch(
      updateFilters({
        ...filters,
        sortBy: sortBy,
        sortDirection: sortDirection,
      })
    );
  };

  const columns = [
    {
      isSortable: true,
      isSortedAsc: filters.sortDirection === historySortDirection.ascending,
      isSortedBy: filters.sortBy === historySortBy.type,
      label: appStrings.history.type,
      name: 'transactionType',
    },
    {
      isSortable: true,
      isSortedAsc: filters.sortDirection === historySortDirection.ascending,
      isSortedBy: filters.sortBy === historySortBy.date,
      label: appStrings.history.date,
      name: 'transactionDate',
    },
    {
      isSortable: true,
      isSortedAsc: filters.sortDirection === historySortDirection.ascending,
      isSortedBy: filters.sortBy === historySortBy.number,
      label: appStrings.history.transactionNo,
      name: 'number',
    },
    {
      isSortable: true,
      isSortedAsc: filters.sortDirection === historySortDirection.ascending,
      isSortedBy: filters.sortBy === historySortBy.status,
      label: appStrings.history.status,
      name: 'transactionStatus',
    },
    {
      isSortable: true,
      isSortedAsc: filters.sortDirection === historySortDirection.ascending,
      isSortedBy: filters.sortBy === historySortBy.total,
      label: appStrings.history.transactionTotal,
      name: 'transactionTotal',
      align: 'center',
    },
    {
      isSortable: true,
      isSortedAsc: filters.sortDirection === historySortDirection.ascending,
      isSortedBy: filters.sortBy === historySortBy.placedBy,
      label: appStrings.history.placedBy,
      name: 'placedBy',
      align: 'center',
    },
    { label: '', name: 'primaryAction', align: 'center' },
  ];

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="flex-start"
      data-test-id="historyDetailsContainer"
    >
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="flex-start"
      >
        {rowsCount ? (
          <CustomTable
            columns={columns}
            rowData={rowData}
            customClass={css.tableStyle}
            sortHandler={handleSortChanges}
            tableId={'historyDetails'}
          />
        ) : null}
      </Grid>

      {!rowsCount ? (
        <Grid item container justifyContent="center" alignItems="center">
          <CustomBox className={css.customBoxNoDataAvailable}>
            <NoDataAvailable
              message={appStrings.history.transactionNotFoundMessage}
              iconElement={<InfoOutlinedIcon />}
            />
          </CustomBox>
        </Grid>
      ) : null}

      {emailModalData.open && <SendEmailDialog {...emailModalData} />}
      {updateContractData.open && (
        <UpdateContractDialog {...updateContractData} />
      )}
    </Grid>
  );
}

export default HistoryDetails;
