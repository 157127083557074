import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import CartContext from '../../Cart/context/CartContext';
import ProductListContext from './ProductListContext';
import { checkExpand } from './productListProviderService';
import { removeIndexesFromLoadingRows } from '../productsListService';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

function ProductListContextProvider(props) {
  const cartCtx = useContext(CartContext);

  const cartInfo = useSelector(state => state.cart.cartInfo);
  const products = useSelector(
    state => state.cart.cartInfo.cartDetails?.cartItems
  );

  const { pathname } = useLocation();
  const { bp } = useParams();

  const { control, setValue, formState, reset, getValues, trigger } = useForm();
  const { errors, dirtyFields, isDirty } = formState;
  const formControl = useRef(control);

  const [expanded, setExpanded] = useState(null);
  const [expandedProduct, setExpandedProduct] = useState(null);
  const initialValues = useRef({});
  const addingProductValue = useRef(null);

  const [paginationDetails, setPaginationDetails] = useState({
    from: 0,
    to: 10,
    itemsPerPage: 10,
    page: 1,
  });
  const [displayedProducts, setDisplayedProducts] = useState(
    products?.slice(0, paginationDetails.itemsPerPage)
  );

  const removeLoadingRows = useCallback(() => {
    removeIndexesFromLoadingRows(cartCtx.setRowsLoading, [
      expanded + paginationDetails.from,
    ]);
  }, [expanded, paginationDetails.from, cartCtx.setRowsLoading]);

  useEffect(() => {
    removeLoadingRows();
    // needed to remove loading rows when location changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const addLoadingRows = useCallback(() => {
    cartCtx?.setRowsLoading(prevRows => [
      ...prevRows,
      expanded + paginationDetails.from,
    ]);
  }, [cartCtx, expanded, paginationDetails.from]);

  const value = {
    cartCtx,

    isSavedItems: cartCtx.isSavedItems,
    isOrder: cartCtx.isOrder,
    bp,
    paginationDetails,
    setPaginationDetails,
    displayedProducts,
    setDisplayedProducts,
    expanded,
    setExpanded,
    cartInfo,
    products,
    expandedProduct,
    setExpandedProduct,
    initialValues,
    addingProductValue,

    checkExpand,
    addLoadingRows,
    removeLoadingRows,

    // useForm Props
    control,
    setValue,
    formState,
    reset,
    getValues,
    trigger,
    errors,
    dirtyFields,
    isDirty,
    formControl,
  };

  return (
    <ProductListContext.Provider value={value}>
      {props.children}
    </ProductListContext.Provider>
  );
}
export default ProductListContextProvider;
