import { makeStyles } from 'tss-react/mui';

const useRepSearchStyles = makeStyles()(() => ({
  searchInput: {
    width: '100%',
    '& .MuiInputBase-root': {
      cursor: 'pointer',
    },
    '& input': {
      cursor: 'pointer',
    },
  },
}));

export default useRepSearchStyles;
