import { Grid, Skeleton } from '@mui/material';

import CustomBox from '../../CustomBox/CustomBox';
import useWizardFormStyles from './useWizardFormStyles';

function WizardFormSkeleton() {
  const css = useWizardFormStyles().classes;

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={3}
      id="customSkeletonForWizardSteps"
    >
      <Grid item>
        <CustomBox fadeBorder>
          <Grid
            alignItems="flex-start"
            container
            spacing={3}
            className={css.wizardSkeleton}
          >
            <Grid item xs={12} sm={12}>
              <Skeleton variant="rounded" width={'30%'} height={'34px'} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Skeleton variant="rounded" height={'34px'} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Skeleton variant="rounded" height={'34px'} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Skeleton variant="rounded" height={'34px'} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Skeleton variant="rounded" height={'34px'} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Skeleton variant="rounded" height={'34px'} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Skeleton variant="rounded" height={'34px'} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Skeleton variant="rounded" height={'34px'} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Skeleton variant="rounded" height={'34px'} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Skeleton variant="rounded" height={'34px'} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Skeleton variant="rounded" height={'34px'} />
            </Grid>
          </Grid>
        </CustomBox>
      </Grid>
      <Grid
        item
        container
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={3} sm={2}>
          <Skeleton variant="rounded" height={'34px'} />
        </Grid>
        <Grid item xs={3} sm={2}>
          <Skeleton variant="rounded" height={'34px'} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default WizardFormSkeleton;
