import CONSTANTS from '../constants';
import { createSlice } from '@reduxjs/toolkit';
import { dateFormat } from '../../strings/appConstants';
import moment from 'moment';
import { ADMIN } from '../../navigation/roles';

const consignedInventorySlice = createSlice({
  name: CONSTANTS.consignedInventory,
  initialState: {
    inventoryList: null,
    selectedEmployeeId: null,
    lastSelectedInventoryCode: null,
    selectedInventoryCode: null,
    selectedInventoryName: null,
    selectedInventoryList: null,
    isLoading: true,
    selectedItemsCounter: 0,
    addFromConsignedLoading: false,
  },
  reducers: {
    saveInventoryList: (state, { payload }) => {
      state.inventoryList = payload.list || [];
      state.selectedEmployeeId = payload.id || null;
      if (payload.role !== ADMIN) {
        state.selectedInventoryCode = payload.list[0]?.code || null;
        state.selectedInventoryName = payload.list[0]?.name || null;
      }
    },

    saveSelectedInventoryList: (state, { payload }) => {
      if (payload.list) {
        state.selectedInventoryList = payload.list.map(product => {
          product.itemDiscontinued = product.itemDiscontinued.toUpperCase();
          product.formattedLastDateReceived = moment(
            new Date(product.lastDateReceived)
          ).format(dateFormat);
          return product;
        });
      } else {
        state.selectedInventoryList = [];
      }
      state.lastSelectedInventoryCode = payload?.code || null;
      state.isLoading = false;
    },

    resetInventory: state => {
      state.inventoryList = null;
      state.selectedEmployeeId = null;
      state.selectedInventoryCode = null;
      state.selectedInventoryName = null;
      state.selectedInventoryList = null;
      state.isLoading = true;
      state.lastSelectedInventoryCode = null;
    },

    resetInventoryForAdmin: state => {
      state.selectedEmployeeId = null;
      state.selectedInventoryCode = null;
      state.selectedInventoryName = null;
      state.selectedInventoryList = null;
      state.isLoading = true;
      state.lastSelectedInventoryCode = null;
    },

    updateLoadingState: (state, { payload }) => {
      state.isLoading = payload;
    },

    updateSelectedCode: (state, { payload }) => {
      state.selectedInventoryCode = payload?.code || null;
      state.selectedInventoryName = payload?.name || null;
    },

    selectConsignedItem: (state, { payload }) => {
      const item = state.selectedInventoryList[payload.index];
      let itemIsSelected = item.selected;
      if (
        itemIsSelected !== payload.isSelected &&
        (itemIsSelected === !payload.isSelected ||
          (itemIsSelected === undefined && payload.isSelected))
      ) {
        state.selectedInventoryList[payload.index].selected =
          payload.isSelected;
        state.selectedInventoryList[payload.index].qtyToAdd = payload.qtyToAdd;
        state.selectedItemsCounter += payload.isSelected ? 1 : -1;
      } else if (item.qtyToAdd !== payload.qtyToAdd) {
        state.selectedInventoryList[payload.index].qtyToAdd = payload.qtyToAdd;
      }
    },

    selectAllConsignedItems: (state, { payload }) => {
      state.selectedInventoryList.forEach(item => {
        item.selected = payload.allSelected;
        item.qtyToAdd = payload.qtyToAdd;
      });
      state.selectedItemsCounter = payload.allSelected
        ? state.selectedInventoryList.length
        : 0;
    },

    addFromConsignedLoading: (state, { payload }) => {
      state.addFromConsignedLoading = payload;
    },

    resetSelectedConsignedItems: state => {
      state.selectedInventoryList?.forEach(item => {
        item.selected = undefined;
        item.qtyToAdd = null;
      });
      state.selectedItemsCounter = 0;
    },
  },
});

export const {
  addFromConsignedLoading,
  resetInventory,
  resetSelectedConsignedItems,
  saveInventoryList,
  resetInventoryForAdmin,
  saveSelectedInventoryList,
  updateLoadingState,
  updateSelectedCode,
  selectConsignedItem,
  selectAllConsignedItems,
} = consignedInventorySlice.actions;
export default consignedInventorySlice.reducer;
