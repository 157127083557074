import useBrRecommendationsWidgetStyles from './useBrRecommendationsWidgetStyles';
import ProductContent from '../../student/ProductContent/ProductContent';
import BrRecommendationsWidgetSkeleton from './BrRecommendationsWidgetSkeleton';
import classNames from 'classnames';
import { Grid, Typography } from '@mui/material';

function BrRecommendationsWidget ({
  label,
  recommendations,
  customClass,
  }) {
  const css = useBrRecommendationsWidgetStyles().classes;

  if (!recommendations || !recommendations?.length) {
    return <BrRecommendationsWidgetSkeleton />
  }
  return (
    <Grid container id="recoomendationsWidgetContainer">
      <Grid item paddingBottom={2} xs={12}>
        <Typography fontWeight="bold" id="title">
          {label}
        </Typography>
      </Grid>
      <Grid
        item
        container
        id="recommendationsWidget"
        className={classNames(
          css.recommendationsWidgetContainer,
          css[customClass]
        )}
      >
        <Grid item id="recommendationsList" className={css.recommendationsList}>
          {recommendations?.map((rec, index) => (
            <ProductContent
              id={`recommendation_${index}`}
              key={index}
              productNumber={rec.pid}
              productName={rec.title}
              image={rec.ImageWeb01}
              customClassName={css.withSpacing}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default BrRecommendationsWidget;