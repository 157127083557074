import React, { useCallback, useState } from 'react';
import BrSearchFacet from './BrSearchFacet';
import { v4 as uuidv4 } from 'uuid';
import useBrSearchFacetStyles from './useBrSearchFacetStyles';
import appStrings from '../../../core/strings/appStrings';
import { Divider, Grid } from '@mui/material';

function MobileBrSearchFacet({
  clearAllFiltersElement,
  facets,
  openState,
  onClose,
  onSelectedOptions,
  onUnselectedOptions,
  selectedFacets,
  searchProducts,
}) {
  const css = useBrSearchFacetStyles().classes;
  const [isOpen, filterOpen] = useState(openState);

  const handleModalClose = useCallback(() => {
    filterOpen(!isOpen);
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });
  const applyFilters = () => {
    searchProducts();
    handleModalClose();
  };
  const mobileHeader = (
    <Grid container direction={"row"} justifyContent="space-between" className={css.mobileHeader}>
      <div className={css.cancelBtnContainer}>
        <h4 id="cancelBtn" onClick={handleModalClose}>{appStrings.common.cancel}</h4>
      </div>
      <h3 id="filtersLabel">{appStrings.common.filters}</h3>
      <h4 id="applyBtn" onClick={applyFilters}>{appStrings.common.apply}</h4>
    </Grid>
  );

  const mobileContainer = (
    <div className="mobile-container-inner">
      {selectedFacets && selectedFacets?.length ?
        <Grid className={css.facetContainer}>
          {clearAllFiltersElement()}
        </Grid> : null
      }
      {facets.map(facet => (
        <BrSearchFacet
          key={uuidv4()}
          filterName={facet.name}
          filterOptions={facet.properties}
          onSelectedOptions={onSelectedOptions}
          onUnselectedOptions={onUnselectedOptions}
          selectedFacets={selectedFacets}
          isMobile
        />
      ))}
    </div>
  );

  return (
    <div className={css.facetMobileContainer}>
      {mobileHeader}
      <Divider />
      {mobileContainer}
    </div>
  );
}

export default MobileBrSearchFacet;