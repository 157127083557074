import { makeStyles } from 'tss-react/mui';

const useProductContentStyles = makeStyles()(theme => ({
  productContentSkeleton: {
    borderRadius: theme.spacing(1),
    width: '100%',

    '& .MuiSkeleton-root': {
      borderRadius: theme.spacing(2),
    },
  },

  contentContainer: {
    '& button': {
      width: '100%',
      padding: `${theme.spacing(2)} !important`,
      height: theme.spacing(50),
      backgroundColor: 'white',
      borderColor: `${theme.palette.common.secondary6} !important`,
      borderRadius: theme.spacing(1),

      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 1) !important',
        borderColor: 'black !important',

        '#productContentCart-Button': {
          color: 'red',
        },
      },
    },

    '& img': {
      height: 'auto',
      maxWidth: '100%',
      maxHeight: theme.spacing(22),
    },

    '& .MuiTypography-root': {
      color: 'black',
    },
  },

  textBold: {
    fontWeight: 'bold',
  },

  textOverline: {
    fontWeight: 'bold',
    textDecorationLine: 'line-through',
  },

  studentPriceStyle: {
    color: 'red !important',
  },

  cartButton: {
    borderRadius: '50%',
    border: `solid ${theme.spacing(0.2)}`,
    borderColor: `${theme.palette.common.secondary6} !important`,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    width: theme.spacing(5.5),
    color: 'black',

    '& .disabled': {
      color: `${theme.palette.common.grey2} !important`,
    },
  },

  cartButtonIcon: {
    transform: 'scaleX(-1)',
  },

  cartButtonLoading: {
    color: theme.palette.common.grey2,
  },

  imgContainer: {
    height: theme.spacing(22),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  productTitle: {
    wordBreak: 'break-all',
    textAlign: 'start',
    paddingTop: theme.spacing(2),

    '&:hover': {
      fontWeight: 'bold',
      textDecoration: 'underline',
    },
  },
}));

export default useProductContentStyles;
