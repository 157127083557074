import PropTypes from 'prop-types';
import appStrings from '../../../core/strings/appStrings';

export const propTypes = {
  student: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }),
  products: PropTypes.arrayOf(
    PropTypes.shape({
      recordId: PropTypes.number,
      changeTracker: PropTypes.shape({
        createdByUsername: PropTypes.string,
        createdByRole: PropTypes.string,
        updatedByUsername: PropTypes.string,
        updatedByRole: PropTypes.string,
      }),
      description: PropTypes.string,
      isDeleted: PropTypes.bool,
      lineTotal: PropTypes.number,
      listPrice: PropTypes.number,
      notes: PropTypes.array,
      productNumber: PropTypes.string,
      quantity: PropTypes.number,
    })
  ),
  orderView: PropTypes.bool,
  cartId: PropTypes.string,
};

export const qtyInput = {
  name: 'editableQuantity',
  type: 'number',
  validations: {
    maxLength: 4,
    validate: {
      minQty: value =>
        Number(value) > 0 || appStrings.validationMessages.minQuantity,
    },
  },
  removeStartZero: true,
};

export const studentPriceInput = {
  name: 'editableStudentPrice',
  type: 'currency',
  validations: {
    validate: {
      amountFormat: value =>
        value === '' || isNaN(value)
          ? appStrings.validationMessages.amountFormat
          : true,
      minValue: value =>
        !value ||
        Number(value) >= 0.01 ||
        appStrings.validationMessages.minAmount,
    },
  },
};

export const notificationsLimit = 3;

export const productsListColumns = [
  {
    label: appStrings.cart.partNumber,
    name: 'productNumber',
    bold: true,
  },
  {
    label: appStrings.cart.description,
    name: 'description',
  },
  {
    label: appStrings.cart.quantity,
    name: 'quantity',
    width: '65px', //  same value is the one for editableField in useProductsListStyles
  },
  {
    label: appStrings.cart.listPrice,
    name: 'listPrice',
    isCurrency: true,
  },
  {
    label: appStrings.cart.studentPrice,
    name: 'studentPrice',
    isCurrency: true,
    width: '125px', //  same value is the one for editableCurrencyField in useProductsListStyles
  },
  {
    label: appStrings.cart.lineTotal,
    name: 'lineTotal',
    bold: true,
    isCurrency: true,
  },
  { name: 'notifications' },
];
