import { FormControlLabel, Radio, RadioGroup, AlertTitle } from '@mui/material';
import {
  alertButtonColor,
  alertButtonLabels,
  alertIcons,
  alertTypes,
  standardAlertTypes,
} from './customAlertConstants';

import Alert from '@mui/material/Alert';
import CustomButton from '../CustomButton/CustomButton';
import { buttonTypes } from '../../../core/strings/appConstants';
import classNames from 'classnames';
import { indexOf } from 'lodash';
import useCustomAlertStyles from './useCustomAlertStyles';

function CustomAlert({
  messageType,
  messageTitle,
  messages,
  actionButtons,
  alertValue,
  handleAlertChange,
  handleAlertAction,
  alertIndex,
  customClass,
  id,
}) {
  const css = useCustomAlertStyles().classes;
  const alertType = messageType.toLowerCase();
  const getAlertButtons = () => {
    return actionButtons
      ? actionButtons.map((button, index) => {
          let buttonVariant =
            index > 0 ? buttonTypes.tertiary : buttonTypes.secondary;
          buttonVariant =
            (alertType === alertTypes.info ||
              alertType === alertTypes.success) &&
            actionButtons?.length === 1
              ? buttonTypes.tertiary
              : buttonVariant;
          let buttonColor = index > 0 ? '' : alertButtonColor[alertType];
          return (
            <CustomButton
              key={`button-${index}`}
              btnType={buttonVariant}
              label={alertButtonLabels[button]}
              color={buttonColor}
              customClass={css.alertButton}
              onClick={() => handleAlertAction(button, alertValue, alertIndex)}
              id={`${alertButtonLabels[button]}-${alertIndex}`}
            />
          );
        })
      : null;
  };

  const getMessages = () => {
    if (messages) {
      return messages?.length > 1 ? (
        <RadioGroup
          name="alert"
          value={alertValue}
          onChange={handleAlertChange}
        >
          {messages.map((message, index) => (
            <FormControlLabel
              key={`${message.value}-${index}`}
              value={message.value}
              control={<Radio />}
              label={message.text}
            />
          ))}
        </RadioGroup>
      ) : (
        messages[0].text
      );
    }
    return null;
  };

  const isStandardAlert = indexOf(standardAlertTypes, alertType) !== -1;

  return (
    <Alert
      severity={isStandardAlert ? alertType : alertTypes.info}
      icon={alertIcons[alertType]}
      className={classNames(
        css.alert,
        customClass ?? '',
        isStandardAlert ? '' : css.audit
      )}
      action={<>{getAlertButtons()}</>}
      id={id ?? ''}
    >
      <AlertTitle>{messageTitle}</AlertTitle>
      {getMessages()}
    </Alert>
  );
}

export default CustomAlert;
