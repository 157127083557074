import {useEffect, useState} from 'react';

import CustomAlert from '../../../CustomAlert/CustomAlert';
import CustomBox from '../../../CustomBox/CustomBox';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import { Grid } from '@mui/material';
import NoDataAvailable from '../../../NoDataAvailable/NoDataAvailable';
import WizardActionButtons from '../../WizardActionButtons/WizardActionButtons';
import WizardFormSkeleton from '../../WizardFormSkeleton/WizardFormSkeleton';
import { alertTypes } from '../../../CustomAlert/customAlertConstants';
import appStrings from '../../../../../core/strings/appStrings';
import { getPossibleStep } from './n97PossibleValidation';
import { getValidations } from './validationService';
import { handleErrors } from '../wizardPageContainerService';
import { setActiveStep, setPossibleFieldName } from '../../../../../core/redux/slices/wizardSlice';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useValidationStyles from './useValidationStyles';
import PropTypes from 'prop-types';

function Validations({ dirtyInfos }) {
  const css = useValidationStyles().classes;
  const dispatch = useDispatch();

  const {id} = useParams();
  const [isLoading, setLoading] = useState(true);
  const [validations, setValidation] = useState([]);
  const [hasError, setHasErrors] = useState(true);

  const getMessageContent = (val, i) => {
    return [
      {
        text: (
          <span
            className={css.message}
            id={`howToFixRedirect-${i}`}
            onClick={() => {
              const {possibleWizardStep, howToFix} = val;
              const {index, possibleFieldName} = possibleWizardStep;
              dispatch(setActiveStep(index));
              dispatch(setPossibleFieldName({
                fieldName: possibleFieldName,
                howToFix: howToFix
              }));
            }}
            >
            <span className={css.howToFix}>How to fix:</span>
            <span id={`howToFixMessage-${i}`}>{` ${val.howToFix}`}</span>
          </span>
        ),
      },
    ];
  };

  useEffect(() => {
    if (id && !dirtyInfos) {
      setLoading(true);
      getValidations(id)
        .then(resp => {
          let hasErr = false;
          resp.forEach(warning => {
            warning.possibleWizardStep = getPossibleStep(warning);
            if (warning.status === 1) {
              hasErr = true;
            }
          });
          setValidation(resp);
          setHasErrors(hasErr);
        })
        .catch(e => handleErrors(e))
        .finally(() => setLoading(false));
    } else {
      setLoading(true);
    }
  }, [dirtyInfos, id]);

  return (
    <Grid>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        display={isLoading ? 'none' : ''}
        spacing={3}
      >
        <Grid item>
          <CustomBox fadeBorder>
            <Grid
              alignItems="flex-start"
              className={css.formContainer}
              container
              direction="row"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <h3 id="validationSectionTitle">{appStrings.wizard.validation}</h3>
                {validations?.length ? (
                   <p id="validationSectionSubtitle">
                     {appStrings.wizard.validationMessage}
                   </p>
                  ) : null}
              </Grid>

              {validations?.map((val, i) => (
                <Grid item xs={12} key={i}>
                  <CustomAlert
                    messageType={val.status === 1 ? alertTypes.error : alertTypes.warning}
                     messageTitle={val.description}
                     messages={getMessageContent(val, i)}
                     customClass={css.customAlert}
                     id={`validationAlertsContainers-${i}`}
                  />
                </Grid>
              ))}
              {!validations?.length ? (
               <Grid item xs={12}>
                 <NoDataAvailable
                   message={
                     <Grid
                       container
                       direction="column"
                       alignItems="center"
                       className={css.noDataMessage}
                     >
                       <Grid item>{appStrings.wizard.noValidationMsg}</Grid>
                       <Grid item>{appStrings.wizard.youCanSignNow}</Grid>
                     </Grid>
                   }
                   iconElement={<FactCheckOutlinedIcon className={css.iconClasses}/>}
                 />
               </Grid>
              ) : null}
            </Grid>
          </CustomBox>
        </Grid>

        <Grid item>
          <WizardActionButtons disableSignNow={hasError}/>
        </Grid>
      </Grid>
      {isLoading && <WizardFormSkeleton/>}
    </Grid>
  );
}

Validations.prototypes = {
  dirtyInfos: PropTypes.bool,
};

export default Validations;
