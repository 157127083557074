import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CustomIcon from '../CustomIcon/CustomIcon';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import appStrings from '../../../core/strings/appStrings';
import { buttonColors } from '../../../core/strings/appConstants';
import { icons } from '../../../core/strings/icons';

export const alertTypes = {
  info: 'info',
  success: 'success',
  warning: 'warning',
  error: 'error',
  student: 'student',
  rep: 'rep',
  admin: 'admin',
};

export const auditAlertTypes = {
  // Added by Student
  1: 'student',
  // Added by Rep
  2: 'rep',
  // Added by Admin
  3: 'admin',
  // Updated by Admin
  4: 'admin',
  // Updated by Rep
  5: 'rep',
  // Student Notes updated by Student
  6: 'student',
};

export const isUpdatedByAuditAlert = alertType => {
  return alertType >= 4;
};

export const standardAlertTypes = [
  alertTypes.info,
  alertTypes.success,
  alertTypes.warning,
  alertTypes.error,
];

export const alertButtonIds = {
  none: 0,
  ok: 1,
  replace: 3,
  keep: 4,
  remove: 5,
  restore: 6,
};

export const alertButtonLabels = {
  1: appStrings.common.ok,
  2: appStrings.common.refresh,
  3: appStrings.common.replace,
  4: appStrings.common.keep,
  5: appStrings.common.remove,
  6: appStrings.common.restorePrice,
};

export const alertIcons = {
  warning: <ErrorOutlineIcon />,
  success: <CheckCircleOutlineIcon />,
  info: <InfoOutlinedIcon />,
  error: <CustomIcon icon={icons.error1} />,
  student: <CustomIcon icon={icons.studentHat} />,
  rep: <CustomIcon icon={icons.rep} />,
  admin: <CustomIcon icon={icons.admin} />,
};

export const alertButtonColor = {
  warning: buttonColors.yellow,
  error: buttonColors.red,
  info: '',
  success: '',
};
