import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useAccountStyles } from '../accountStyles';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import PropTypes from 'prop-types';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import { buttonTypes } from '../../../../core/strings/appConstants';
import classNames from 'classnames';

function SectionLink({ section, onClick, customClass }) {
  const css = useAccountStyles().classes;

  return (
    <Grid
      item
      xs={12}
      className={classNames(css.profileSectionLink, customClass)}
      id={section.id}
      data-test-id={section.dataTestId}
    >
      <CustomButton
        btnType={buttonTypes.tertiary}
        onClick={onClick}
        sx={{ width: '100%', padding: '0px !important' }}
        label={
          <Grid item container justifyContent="space-between">
            <Typography fontWeight="bold">
              {section.name || section.label}
            </Typography>
            <ChevronRightOutlinedIcon fontSize="medium" />
          </Grid>
        }
      ></CustomButton>
    </Grid>
  );
}

SectionLink.prototypes = {
  section: PropTypes.object,
  onClick: PropTypes.func,
};

export default SectionLink;
