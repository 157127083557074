/*
 * Copyright 2021 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { fullBannerWithEmbeddedImageXStyles } from './useFullBannerWithEmbeddedImageXStyles';
import classNames from 'classnames';
export default function FullBannerWithEmbeddedImageX(FullBannerProps) {
  const { component, page } = FullBannerProps;
  const {
    backgroundcolor,
    backgroundbox,
    textlocation,
    textalign,
    textcolor,
    height,
    width
  } = component.getParameters();

  const css = fullBannerWithEmbeddedImageXStyles().classes;

  const contentRef = component.model.content;
  const documentObject = contentRef && page.getContent(contentRef);
  const {
    cta,
    ctaStyle,
    externalLink,
    link,
    title,
    subtitle,
    content,
    image,
    listitems,
  } = documentObject && documentObject.data;

  const imageObject = image && page.getContent(image).getOriginal();
  const backgroundBoxClass = backgroundbox ? 'darkContainer' : '';
  const flexDirectionClass = textlocation === 'Right' ? 'flexDirectionReverse' : '';
  const textAlignClass = textalign === 'Right' ? 'textRight' : '';
  const textColorClass = textcolor === 'White' ? 'textWhite' : '';

  let linkStyle = 'red-on-white';
  if (ctaStyle) {
    linkStyle = ctaStyle.selectionValues[0].key;
  }

  /* eslint react/prop-types: 0 */
  // eslint-disable-next-line react/no-unstable-nested-components
  const BannerLink = ({ ...props }) => {
    const { bannerLink, bannerLinkType } = props;
    if (bannerLinkType === 'internal') {
      return <Link to={bannerLink} className={`${linkStyle} hover:${css[textColorClass]} ${css[textColorClass]}`}>{cta}</Link>;
    }
    if (bannerLinkType === 'external') {
      return <a href={bannerLink} className={`${linkStyle} hover:${css[textColorClass]} ${css[textColorClass]}`}>{cta}</a>;
    }

    return null;
  };

  /* eslint react/prop-types: 0 */
  // eslint-disable-next-line react/no-unstable-nested-components
  const CtaLink = ({ ...props }) => {
    const { ctaLink, linkType, text } = props;

    if (linkType === 'internal') {
      return <Link to={ctaLink} className={`${linkStyle} hover:${css[textColorClass]} ${css[textColorClass]}`}>{text}</Link>;
    }

    if (linkType === 'external') {
      return <a href={ctaLink} className={`${linkStyle} hover:${css[textColorClass]} ${css[textColorClass]}`}>{text}</a>;
    }

    return null;
  };

  const bannerLink = link ? page.getContent(link).getUrl() : externalLink;
  const bannerLinkType = link ? 'internal' : 'external';

  if (contentRef) {
    return (
      <section className={`${css.sectionWrapper} ${height} ${css[textColorClass]} ${backgroundcolor} ${css[textAlignClass]}`}>
        <div className="article-container">
          <div className={`${css.verticallyCenter} ${css[flexDirectionClass]}`}>
            <div className={classNames(`${css[backgroundBoxClass]}`, width === '60/40' ? css.width60 : css.width50)}>
              <article>
                { title && <h1 className={classNames(css.brTag, css.textWhite)} dangerouslySetInnerHTML={{ __html: title.value }} /> }
                { subtitle && <span className={css.brTitle} dangerouslySetInnerHTML={{ __html: subtitle.value }} /> }
                <p>
                  <span dangerouslySetInnerHTML={{ __html: content.value }} />
                </p>
                { cta && bannerLink && <BannerLink bannerLink={bannerLink} bannerLinkType={bannerLinkType} /> }
              </article>
              {(
                listitems
                && (
                  <div className={css.sectionInnerContent}>
                    <br />
                    <div>
                      <div>
                        {listitems.map(item => {
                          const ctaLink = item.internalLink ? page.getContent(item.internalLink).getUrl() : item.externalLink;
                          const ctaLinkType = item.internalLink ? 'internal' : 'external';
                          return (
                            <article>
                              <h1 className={classNames(css.brTitle, css.forceLeft)}>
                                { item.title && <span dangerouslySetInnerHTML={{ __html: item.title.value }} /> }
                              </h1>
                              <p dangerouslySetInnerHTML={{ __html: item.text.value }} />
                              { item.cta && ctaLink && ctaLinkType && <CtaLink ctaLink={ctaLink} linkType={ctaLinkType} text={item.cta} /> }
                            </article>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            <div className={classNames(css.centerContent, width === '60/40' ? css.width40 : css.width50)}>
              { imageObject && <img src={imageObject.getUrl()} alt="" height="100%" width="100%" /> }
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <div>No Banner selected on component</div>
  );
}
