import CartContext from '../context/CartContext';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import { Grid } from '@mui/material';
import PurchasePower from '../../PurchasePower/PurchasePower';
import Totals from '../../Totals/Totals';
import { purchaseTypes } from '../../PurchasePower/purchasePowerConstants';
import useCartStyles from '../useCartStyles';
import { useContext } from 'react';

function CartTotalSection() {
  const css = useCartStyles().classes;
  const cartCtx = useContext(CartContext);

  return (
    <Grid item xs={12} id="quoteTotalSection">
      {cartCtx.isSavedItems ? (
        <CustomBox customClass={css.purchasePowerContainer}>
          <PurchasePower purchaseType={purchaseTypes.saved} />
        </CustomBox>
      ) : (
        <Totals />
      )}
    </Grid>
  );
}

export default CartTotalSection;
