import React from 'react';
import useFormInputStyles, { helperTextStyle } from '../useFormInputStyles';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import propTypes from '../propTypes';

function NormalField({ field, fieldErrors, ...props }) {
  const helperCss = helperTextStyle().classes;
  const css = useFormInputStyles().classes;

  const getHelperText = () => {
    if (field.noHelperText) {
      return null;
    }

    if (fieldErrors && fieldErrors.type !== 'required') {
      return field.helperText ? field.helperText : fieldErrors.message || ' ';
    }
    return ' ';
  };

  const handleOnKeyPress = event => {
    if (event.key === 'Enter' && !event.shiftKey && field.onEnterCallback) {
      field.onEnterCallback();
    }
    if (event.key === 'Enter' && event.shiftKey && field.onShiftEnterCallback) {
      field.onShiftEnterCallback();
    }
  };

  return (
    <Controller
      control={props.control}
      defaultValue={field.defaultValue ?? ''}
      name={field.name}
      rules={{ ...field.validations }}
      render={({ field: { onChange, value, ref } }) => (
        <>
          <TextField
            autoFocus={field.autoFocus}
            value={value ?? ''}
            variant="outlined"
            label={field.label}
            type={field.type}
            inputRef={e => {
              ref.current = e;
              if (props?.fieldRef) {
                props.fieldRef.current = e;
              }
            }}
            required={field.validations?.required}
            size={props.size ?? 'small'}
            error={fieldErrors ? true : false}
            className={css.simpleInput}
            helperText={getHelperText()}
            disabled={field.disabled ?? false}
            inputProps={{
              ...props.inputProps,
              ...field.validations,
              readOnly: field.readOnly,
              'data-test-id': `normalInput-${field.name}`,
            }}
            autoComplete="off"
            InputProps={{ ...props.InputProps }}
            FormHelperTextProps={{
              classes: { root: helperCss.root },
              'data-test-id': 'validation-helperText',
            }}
            fullWidth={props.fullWidth || true}
            onChange={e => {
              onChange(e);
              field.onChangeCallback && field.onChangeCallback(e.target.value);
            }}
            onClick={e => {
              field.onClick && field.onClick(e);
            }}
            onKeyPress={handleOnKeyPress}
            id={field.name}
            multiline={field.multiline}
            minRows={field.rows}
            onFocus={field.onFocus}
          />
        </>
      )}
    />
  );
}

NormalField.propTypes = propTypes;
export default NormalField;
