import {
  ACCOUNT,
  LOGIN,
  PRODUCTSEARCHRESULTS,
  SELF_EC_APP_WIZARD,
} from '../../../core/navigation/paths';
import { useDispatch, useSelector } from 'react-redux';

import ErrorBoundary from '../../../core/errorBoundary/ErrorBoundary';
import { Grid, Stack, Tooltip } from '@mui/material';
import Logo from './Logo/Logo';
import NavTab from './NavTab/NavTab';
import React, { useEffect, useRef, useState } from 'react';
import appStrings from '../../../core/strings/appStrings';
import { fullIcons } from '../../../core/strings/icons';
import { logout } from '../../../core/redux/slices/loginSlice';
import useHeaderStyles from './useHeaderStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import HamburgerMenu from './HamburgerMenu/HamburgerMenu';
import { useNavigate } from 'react-router';
import ShoppingCartIcon from '../ShoppingCart/ShoppingCartIcon/ShoppingCartIcon';
import CustomButton from '../../shared/CustomButton/CustomButton';
import ApiService from '../../shared/Api/apiService';
import urls from '../../../core/strings/urls';
import { getStudentDetails } from '../../shared/StudentProfileForm/studentProfileFormService';
import BrSearchBar from '../../shared/BrSearchBar/BrSearchBar';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { setRefreshECAppBtn } from '../../../core/redux/slices/commonSlice';

function Header() {
  const stylesMaterial = useHeaderStyles().classes;
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallDevices = useMediaQuery('(max-width:768px)');
  const isXsDevices = useMediaQuery(theme.breakpoints.only('xs'));
  const { isLoggedIn, userDetails } = useSelector(state => state.login);
  const refreshEcAppBtn = useSelector(state => state.common.refreshECAppBtn);
  const dispatch = useDispatch();
  const hamburgerMenuRef = useRef(null);
  const [disableECAppBtn, setDisableECAppBtn] = useState(true);
  const [ECAppBtnHover, setECAppBtnHover] = useState({
    submittedECAppRes: null,
    isCreditAppEnabled: null,
    studentInactive: null,
  });

  const getEcAppTooltipMessage = () => {
    if (ECAppBtnHover.studentInactive) {
      return appStrings.validationMessages.inactiveStudentMessage;
    }

    if (ECAppBtnHover.submittedECAppRes) {
      return appStrings.validationMessages.ecAppAlreadySubmitted;
    }

    if (!ECAppBtnHover.isCreditAppEnabled) {
      return appStrings.validationMessages.ecAppIneligible;
    }

    return '';
  };

  useEffect(() => {
    if (isLoggedIn && refreshEcAppBtn) {
      dispatch(setRefreshECAppBtn(false));
      const { userId: studentId, isActive } = userDetails;
      ApiService.get(urls.checkForSubmittedECApp(studentId)).then(
        submittedECAppRes => {
          getStudentDetails(studentId).then(studentDetailsRes => {
            const { isCreditAppEnabled } = studentDetailsRes;
            setDisableECAppBtn(submittedECAppRes || !isCreditAppEnabled);
            setECAppBtnHover({
              submittedECAppRes: submittedECAppRes,
              isCreditAppEnabled: isCreditAppEnabled,
              studentInactive:
                !isActive ||
                studentDetailsRes.studentAccountStatus === 'Inactive',
            });
          });
        }
      );
    }
  }, [userDetails, isLoggedIn, refreshEcAppBtn, dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(setRefreshECAppBtn(true));
    }
  }, [dispatch, isLoggedIn]);

  const loggedInUserMenu = [
    {
      id: 'menu-account',
      label: appStrings.menu.account,
      onClick: () => navigate(ACCOUNT),
    },
    {
      id: 'menu-logout',
      label: appStrings.menu.logout,
      onClick: () => dispatch(logout()),
    },
  ];

  const userTab = (
    <NavTab
      label={
        isLoggedIn ? appStrings.common.myAccount : appStrings.common.signIn
      }
      navigateTo={LOGIN}
      isDropDown={isLoggedIn}
      dropDownData={loggedInUserMenu}
      hideLabel={isXsDevices}
      icon={fullIcons.user}
      showBadge={isLoggedIn}
      badgeColor="success"
      badgeVariant="dot"
      badgeAnchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    />
  );

  const handleECAppRedirect = () => {
    const { schoolId, userId: studentId } = userDetails;
    navigate(SELF_EC_APP_WIZARD(schoolId, studentId));
  };

  const applyForECButton = () =>
    isLoggedIn && (
      <div>
        <Tooltip
          title={getEcAppTooltipMessage()}
          placement="bottom"
          arrow
          enterTouchDelay={0}
        >
          <Grid item container>
            <CustomButton
              btnType="secondary"
              label={appStrings.common.applyForCredit}
              icon={!isXsDevices && <DescriptionOutlinedIcon />}
              onClick={handleECAppRedirect}
              customClass={stylesMaterial.applyForECBtn}
              id="redirectToStudentECApplication"
              disabled={disableECAppBtn}
            />
          </Grid>
        </Tooltip>
      </div>
    );

  const webView = (
    <>
      <Grid item container xs>
        <Grid item>
          <Logo />
        </Grid>
        <Grid item xs={5} paddingLeft={3} position="relative">
          <BrSearchBar
            search={data => {
              if (data) {
                navigate(PRODUCTSEARCHRESULTS, {
                  state: { value: data },
                });
              }
            }}
          />
        </Grid>
      </Grid>

      <Grid
        item
        container
        justifyContent="flex-end"
        alignItems="center"
        xs="auto"
      >
        <Grid item paddingX={2}>
          {applyForECButton()}
        </Grid>
        <Stack direction="row" spacing={2}>
          <Grid item id="accountButton">
            {userTab}
          </Grid>
          <Grid item id="StudentCartButton">
            <ShoppingCartIcon isXsDevices={isXsDevices} />
          </Grid>
        </Stack>
      </Grid>
    </>
  );

  const mobileView = (
    <>
      <div className={stylesMaterial.mobileViewTopContainer}>
        <Grid
          item
          container
          xs="auto"
          spacing={1}
          className={stylesMaterial.hamburgerAndLogo}
        >
          <Grid item>
            <HamburgerMenu hamburgerMenuRef={hamburgerMenuRef} />
          </Grid>
          <Grid item>
            <Logo />
          </Grid>
        </Grid>

        <Grid item container alignItems="center" justifyContent="flex-end" xs>
          <Grid item paddingX={2}>
            {applyForECButton()}
          </Grid>
          <Stack direction="row" spacing={1}>
            <Grid item id="accountButton">
              {userTab}
            </Grid>
            <Grid item id="StudentCartButton">
              <ShoppingCartIcon isXsDevices={isXsDevices} />
            </Grid>
          </Stack>
        </Grid>
      </div>

      <Grid
        item
        container
        height={theme.spacing(6)}
        paddingTop={1}
        justifyContent="center"
      >
        <Grid item xs={12} position="relative">
          <BrSearchBar
            search={data => {
              if (data) {
                navigate(PRODUCTSEARCHRESULTS, {
                  state: { value: data },
                });
              }
            }}
          />
        </Grid>
      </Grid>
    </>
  );

  return (
    <ErrorBoundary componentName="Header">
      <Grid
        ref={hamburgerMenuRef}
        container
        direction="row"
        className={stylesMaterial.header}
        data-test-id="header"
      >
        {isSmallDevices ? mobileView : webView}
      </Grid>
    </ErrorBoundary>
  );
}

export default Header;
