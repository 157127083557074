import React, { useState } from 'react';
import { ClickAwayListener, Fade, IconButton, Popper } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import HamburgerCategories from '../../Category/CategoriesMenu/HamburgerCategories';
import { Box } from '@mui/system';

function HamburgerMenu({ hamburgerMenuRef }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton
        onClick={() => setOpen(true)}
        disableRipple
        id="hamburgerMenu"
        data-test-id="hamburgerMenu-testId"
      >
        {open ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
      <Popper
        transition
        open={open}
        anchorEl={hamburgerMenuRef.current}
        onClick={() => setOpen(false)}
        sx={{ zIndex: 1 }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Fade {...TransitionProps} timeout={350}>
              <Box>
                <HamburgerCategories />
              </Box>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
}

export default HamburgerMenu;
