import { Controller } from 'react-hook-form';
import React, { useState } from 'react';
import appStrings from '../../../../core/strings/appStrings';
import classNames from 'classnames';
import { fullIcons } from '../../../../core/strings/icons';
import propTypes from '../propTypes';
import sepFonts from '../../../../core/assets/sep-industrial-font.module.css';
import { sepIndustrialFont } from '../../../../core/strings/appConstants';
import useDateTimeStyles from './useDateTimeStyles';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

function DateTimeField({ field, control, fieldErrors, ...props }) {
  const datetimeCss = useDateTimeStyles().classes;
  const [error, setError] = useState(null);

  const handleOnKeyPress = event => {
    if (event.key === 'Enter' && field.onCloseCallback) {
      field.onCloseCallback();
    }
  };

  const handleOnBlur = () => {
    if (!error && field.onCloseCallback) {
      field.onCloseCallback();
    }
  };

  const handleError = error => {
    if (error === 'minDate' || error === 'disablePast') {
      setError(
        field.minDateValidationMessage ?? appStrings.validationMessages.minDate
      );
    } else if (error === 'maxDate' || error === 'disableFuture') {
      setError(
        field.maxDateValidationMessage ?? appStrings.validationMessages.maxDate
      );
    } else if (error === 'invalidDate') {
      setError(appStrings.validationMessages.invalidDateFormat);
    } else {
      setError(null);
    }
  };

  const iconClasses = classNames(
    sepFonts[sepIndustrialFont],
    sepFonts[fullIcons.calendar],
    { [datetimeCss.smallIcon]: field.iconSize === 'small' }
  );

  const icon = <span className={iconClasses}></span>;

  return (
    <Controller
      control={control}
      defaultValue={field.defaultValue ?? ''}
      name={field.name}
      rules={{
        ...field.validations,
        validate: {
          checkError: () => (error ? false : true),
        },
      }}
      className={datetimeCss.container}
      render={({ field: { ref, onChange, value } }) => (
        <>
          <DesktopDatePicker
            InputAdornmentProps={{ position: 'end' }}
            autoOk
            format={field.format}
            inputRef={ref}
            inputVariant="outlined"
            label={field.label}
            onChange={date => {
              let newV = '';
              if (date !== null) {
                newV = date;
              }
              onChange(newV);
              if (field.onChangeCallback) {
                field.onChangeCallback(newV);
              }
            }}
            onClose={() => {
              if (field.onCloseCallback) {
                field.onCloseCallback();
              }
            }}
            onBlur={() => field.onCloseCallback()}
            onError={error => {
              handleError(error);
            }}
            onKeyPress={handleOnKeyPress}
            variant={field.variant ?? 'inline'}
            value={value || null}
            className={`${datetimeCss.root} ${
              fieldErrors ? datetimeCss.error : ''
            }`}
            views={['year', 'month', 'day']}
            keyboardIcon={icon}
            minDate={field.minDate}
            maxDate={field.maxDate}
            disabled={field.disabled ?? false}
            disablePast={field.disablePast}
            disableFuture={field.disableFuture}
            initialFocusedDate={field.initialFocusedDate}
            renderInput={params => (
              <TextField
                onBlur={handleOnBlur}
                {...params}
                id={field.name}
                size={props.size ? props.size : 'small'}
                helperText={error}
                error={params.error}
                required={field.validations?.required}
              />
            )}
          />
          {fieldErrors?.message && (
            <span
              id="errorMessage"
              className={classNames(datetimeCss.errorMessage)}
            >
              {fieldErrors.message}
            </span>
          )}
        </>
      )}
    />
  );
}

DateTimeField.propTypes = propTypes;

export default DateTimeField;
