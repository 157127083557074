import { Grid, Skeleton, Typography } from '@mui/material';
import {
  getDownPayment,
  getGrandTotal,
  getSubtotal,
  getTax,
} from './totalsService';

import CustomBox from '../../shared/CustomBox/CustomBox';
import PurchasePower from '../PurchasePower/PurchasePower';
import appStrings from '../../../core/strings/appStrings';
import { purchaseTypes } from '../PurchasePower/purchasePowerConstants';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useTotalsStyles from './useTotalsStyles';

function Totals() {
  const css = useTotalsStyles().classes;
  const { pathname } = useLocation();
  const isOrder = pathname.indexOf('order') !== -1;
  const { totals, totalsLoading, isECOrder, downPaymentLoading } = useSelector(
    state => state.cart
  );

  return (
    <CustomBox customClass={css.totalsContainer}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        className={css.gridContainer}
      >
        <Grid item xs={6} className={css.purchasePowerContainer}>
          <PurchasePower
            purchaseType={isOrder ? purchaseTypes.order : purchaseTypes.cart}
          />
        </Grid>
        <Grid
          item
          container
          xs={6}
          spacing={1}
          className={css.totalsSection}
          direction="column"
        >
          <Grid item xs={6} className={css.totalsTitle}>
            <Typography
              variant="h2"
              id="quoteTotalsLabel"
              fontWeight="bold"
              className={css.quoteTotalsLabel}
            >
              {`${
                isOrder
                  ? appStrings.cart.orderTotals
                  : appStrings.cart.studentCartTotals
              }:`}
            </Typography>
          </Grid>
          <Grid item md={6} lg={4} id="quoteSubtotalValue">
            <Typography variant="h3">
              {totalsLoading ? (
                <Skeleton
                  data-test-id="subtotalSkeleton"
                  width={'100%'}
                  position={'absolute'}
                  id="quoteSubtotalValueLoading"
                />
              ) : (
                getSubtotal(totals)
              )}
            </Typography>
          </Grid>
          <Grid item md={6} lg={4} id="quoteTaxValue">
            <Typography variant="h3">
              {totalsLoading ? (
                <Skeleton
                  data-test-id="taxSkeleton"
                  width={'100%'}
                  position={'absolute'}
                  id="quoteTaxValueLoading"
                />
              ) : (
                getTax(totals)
              )}
            </Typography>
          </Grid>
          <Grid item md={6} lg={4} id="quoteDownPaymentValue">
            <Typography variant="h2">
              {totalsLoading || downPaymentLoading ? (
                <Skeleton
                  data-test-id="downPaymentSkeleton"
                  width={'100%'}
                  position={'absolute'}
                  id="quoteDownPaymentValueLoading"
                />
              ) : (
                isECOrder && getDownPayment(totals)
              )}
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            lg={4}
            id="quoteGrandTotalValue"
            className={css.grandTotal}
          >
            <Typography variant="h3">
              {totalsLoading ? (
                <Skeleton
                  data-test-id="grandTotalSkeleton"
                  width={'100%'}
                  position={'absolute'}
                  id="quoteGrandTotalValueLoading"
                />
              ) : (
                <strong>{getGrandTotal(totals)}</strong>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </CustomBox>
  );
}

export default Totals;
