import FormInput from '../../../shared/FormInput/FormInput';
import ProductListContext from '../context/ProductListContext';
import { useContext } from 'react';
import useProductsListStyles from '../useProductsListStyles';

function CreateQtyFormInput({ qtyInputInfo, editableQuantityRef }) {
  const css = useProductsListStyles().classes;

  const productListCtx = useContext(ProductListContext);
  const { formControl, errors } = productListCtx;

  return (
    <FormInput
      field={qtyInputInfo}
      fieldRef={editableQuantityRef}
      control={formControl.current}
      errors={errors}
      InputProps={{ className: css.editableField }}
    />
  );
}

export default CreateQtyFormInput;
