import { makeStyles } from 'tss-react/mui';

const inlineBlock = 'inline-block';

export const fullBannerWithEmbeddedImageXStyles =  makeStyles()(theme => ({
  sectionWrapper: {
    overflow: 'hidden',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 5%',

    '&.grey-background': {
      backgroundColor: 'rgb(56, 56, 56)',
    },

    '&.whitegrey-background': {
      backgroundColor: 'rgb(168, 164, 164)',
    },
    
    '&.white-background': {
      backgroundColor: 'rgb(255, 255, 255)',
    },
    
    '&.black-background': {
      backgroundColor: 'black',
    },
    
    '&.darkwhite-background': {
      backgroundColor: '#D5D5D5',
    },

    '& .black-on-white': {
      background: '#fff',
      border: '2px solid #333',
      color: '#333 !important',
      display: inlineBlock,
      fontSize: '1rem',
      fontWeight: '700',
      marginTop: '24px',
      padding: '1em',
      textTransform: 'uppercase',
    },

    '& .red-on-white': {
      background: 'red',
      color: '#fff',
      display: inlineBlock,
      fontSize: '1rem',
      fontWeight: '700',
      marginTop: '24px',
      padding: '1em',
      textTransform: 'uppercase',
    },
  },
  darkContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)'
  },
  flexDirectionReverse: {
    flexDirection: 'row-reverse',
  },
  textRight: {
    textAlign: 'right',
  },
  textWhite: {
    color: 'white',
  },
  redOnWhite: {
    background: 'red',
    color: '#fff',
    display: inlineBlock,
    fontSize: '1rem',
    fontWeight: '700',
    marginTop: '24px',
    padding: '1em',
    textTransform: 'uppercase',
  },
  verticallyCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  brTag: {
    backgroundColor: 'red',
    display: inlineBlock,
    fontStyle: 'oblique',
    padding: '0 7px',
    textTransform: 'uppercase',
    fontSize: '24px !important',
  },
  brTitle: {
    display: 'block',
    padding: '1% 0',
    '& p': {
      display: 'inline',
      fontSize: '2.5rem !important',
    },
    '& em': {
      color: 'red',
      paddingRight: '10px',
    },
  },
  sectionInnerContent: {
    marginLeft: '65px',
    '& img': {
      width: '100%',
    },
    '& .redOnWhite,.blackOnWhite': {
      marginTop: '-1rem',
    },
  },
  forceLeft: {
   marginLeft: '-32px',
  },
  centerContent: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
  },
  hidden: {
    display: 'none',
  },
  width60: {
    flex: 5,
  },
  width50: {
    flex: 1,
  },
  width40: {
    flex: 4,
  },
}));