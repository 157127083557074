import { makeStyles } from 'tss-react/mui';

const useCustomStepperStyles = makeStyles()(theme => ({
  customStepper: {
    [theme.breakpoints.down('md')]: {
      backgroundColor: `${theme.palette.grey[50]}`,
      padding: `${theme.spacing(2)}`,
      borderTop: `1px solid ${theme.palette.common.shadow}`,
      borderBottom: `1px solid ${theme.palette.common.shadow}`,
    },
    marginBottom: theme.spacing(3),
    '& .MuiSvgIcon-fontSizeMedium': {
      height: '0.8em !important',
      width: '0.8em !important',
    },
    '& .Mui-completed .MuiStepLabel-label': {
      fontWeight: 'bold',
    },
    '& .Mui-completed .MuiSvgIcon-root': {
      color: `${theme.palette.common.red} !important`,
      fontWeight: 'bold',
    },
    '& .MuiSvgIcon-root.Mui-active': {
      border: `2px solid ${theme.palette.common.red}`,
      color: `${theme.palette.common.white} !important`,

      '& .MuiStepIcon-text': {
        fill: `${theme.palette.common.red}`,
        fontSize: '1rem',
      },
    },
    '& .MuiSvgIcon-root.Mui-completed': {
      border: `2px solid ${theme.palette.common.red}`,
    },
    '& .MuiSvgIcon-root': {
      borderRadius: '50%',
      border: `2px solid ${theme.palette.common.grey}`,
      color: `${theme.palette.common.white} !important`,

      '& .MuiStepIcon-text': {
        fill: `${theme.palette.common.grey}`,
        fontSize: '1rem',
      },
    },
    '& .MuiStepConnector-line': {
      borderColor: `${theme.palette.common.red}`,
    },
    '& .Mui-disabled .MuiStepConnector-line': {
      borderColor: `${theme.palette.common.grey}`,
    },
    '& .MuiStepLabel-label': {
      color: `${theme.palette.common.grey} !important`,
    },
    '& .MuiStepLabel-label.Mui-active': {
      color: `${theme.palette.common.black} !important`,
      fontWeight: 'bold',
    },

    '& .MuiStepLabel-iconContainer': {
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
  },
  hideLabel: {
    '& .MuiStepLabel-labelContainer': {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
}));

export default useCustomStepperStyles;
