import { Controller } from 'react-hook-form';
import { Input } from '@mui/material';
import propTypes from '../propTypes';
import useFormInputStyles from '../useFormInputStyles';

function HiddenField({ field, ...props }) {
  const css = useFormInputStyles().classes;
  return (
    <Controller
      control={props.control}
      defaultValue={field.defaultValue ?? ''}
      name={field.name}
      rules={{ ...field.validations }}
      render={({ field: { onChange, value } }) => (
        <Input
          className={css.hideInput}
          type={field.type}
          name={field.name}
          id={field.name}
          value={value ?? ''}
          onChange={onChange}
          size={props.size ? props.size : 'small'}
          inputProps={{ 'data-test-id': props['data-test-id'] ?? field.name }}
        />
      )}
    />
  );
}

HiddenField.propTypes = propTypes;
export default HiddenField;
