import CONSTANTS from '../constants';
import { createSlice } from '@reduxjs/toolkit';

const zipCodeSlice = createSlice({
  name: CONSTANTS.zipCode,
  initialState: {
    formattedCodeList: {},
  },
  reducers: {
    /**
     * @param {object} state
     * @param {object} action = {payload: {
     *  code: 'string',
     *  value: {object - received from api}
     *  formattedValue: {object}
     * }}
     */
    saveZipCode: (state, action) => {
      state.formattedCodeList[action.payload.code] =
        action.payload.formattedValue;
    },
  },
});

export const { saveZipCode } = zipCodeSlice.actions;
export default zipCodeSlice.reducer;
