import { FormControl, FormControlLabel, useTheme } from '@mui/material';
import { useBtnStyles, useSwitchLabelStyles } from './useSwitchFieldStyles';

import { Controller } from 'react-hook-form';
import CustomButton from '../../CustomButton/CustomButton';
import React from 'react';
import appStrings from '../../../../core/strings/appStrings';
import { buttonTypes } from '../../../../core/strings/appConstants';
import { makeStyles } from 'tss-react/mui';
import propTypes from '../propTypes';
import classNames from 'classnames';

function SwitchField({ field, setValue, control, errors }) {
  const switchStyles = {
    label: useSwitchLabelStyles().classes,
    btn: useBtnStyles().classes,
  };
  const theme = useTheme();

  const toggleCheckbox = (isChecked, event, isBtnClick) => {
    if (isBtnClick) {
      event.preventDefault();
    }
    setValue(field.name, !isChecked, { shouldDirty: true });
    if (field.onChangeCallback) {
      field.onChangeCallback(!isChecked);
    }
  };

  const verticalPlacement = ['start', 'end'];
  const verticalPlacementLabelStyle = makeStyles()({
    switchBtn: {
      alignItems: 'center !important',
      textTransform: 'capitalize',

      '& > span': {
        marginTop: theme.spacing(1),
        [`margin${field.labelPlacement !== 'start' ? 'Left' : 'Right'}`]:
          theme.spacing(2),
      },
    },
  });

  return (
    <FormControl
      variant="outlined"
      size={field.size ? field.size : 'small'}
      data-test-id={`switch-field-${field.name}`}
    >
      <Controller
        control={control}
        defaultValue={field.defaultValue ?? false}
        name={field.name}
        rules={{ ...field.validations }}
        render={({ field: { value } }) => (
          <FormControlLabel
            classes={{
              root: switchStyles.label.root,
              label: switchStyles.label.label,
            }}
            className={
              verticalPlacement.includes(field.labelPlacement)
                ? verticalPlacementLabelStyle().classes.switchBtn
                : ''
            }
            data-test-id={`switchInput-${field.name}-label`}
            label={
              <span id={`switchInput-${field.name}-label`}>{field.label}</span>
            }
            labelPlacement={field.labelPlacement || 'top'}
            error={
              errors?.[field.name] && errors[field.name].ref.value === false
            }
            control={
              <>
                <input
                  type="checkbox"
                  name={field.name}
                  id={field.name}
                  data-test-id={`switchInput-${field.name}`}
                  color="primary"
                  disabled={field.disabled ?? false}
                  hidden
                  onChange={event => toggleCheckbox(value, event)}
                  checked={value}
                />
                <CustomButton
                  btnType={value ? buttonTypes.primary : buttonTypes.default}
                  label={
                    value ? (
                      <>
                        <div className={switchStyles.btn.text}>
                          {appStrings.register.switchInputYes}
                        </div>
                        <div>
                          <span
                            className={`${switchStyles.btn.whiteBtn} ${switchStyles.btn.moveRight}`}
                          ></span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <span className={switchStyles.btn.whiteBtn}></span>
                        </div>
                        <div className={switchStyles.btn.text}>
                          {appStrings.register.switchInputNo}
                        </div>
                      </>
                    )
                  }
                  onClick={event => toggleCheckbox(value, event, true)}
                  customClass={classNames(
                    switchStyles.btn.root,
                    value ? '' : switchStyles.btn.default
                  )}
                  data-test-id={`${field.name}Switch`}
                  id={`${field.name}SwitchBtn`}
                  disabled={field.disabled ?? false}
                ></CustomButton>
              </>
            }
          />
        )}
      />
    </FormControl>
  );
}

SwitchField.propTypes = propTypes;

export default SwitchField;
