import { makeStyles } from 'tss-react/mui';

const useUpdateContractDialogStyles = makeStyles()(theme => ({
  dialog: {
    padding: theme.spacing(3),
    '.MuiDialogActions-root': {
      padding: theme.spacing(1, 4),
      '.MuiButtonBase-root': {
        textTransform: 'none',
        minWidth: '100px',
      },
    },
    '.MuiDialogContent-root': {
      padding: theme.spacing(3),
    },
  },
}));

export default useUpdateContractDialogStyles;
