import { SpeedDial, SpeedDialAction } from '@mui/material';

import { Backdrop } from '@mui/material';
import PropTypes from 'prop-types';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import useCustomSpeedDialStyles from './useCustomSpeedDialStyles';
import { useState } from 'react';
import classNames from 'classnames';

const propTypes = {
  options: PropTypes.array, // array of objects like { text: 'Delete stuff', icon: <CustomIcon />, onClick: () => {}, color: 'red', disabled: true, testId: 'theId'}
  show: PropTypes.bool,
  id: PropTypes.string,
  isStudent: PropTypes.bool,
};

function CustomSpeedDial({
  options,
  show,
  id,
  isStudent,
  isCart,
  customClass,
}) {
  const css = useCustomSpeedDialStyles().classes;
  const btnClasses = classNames(customClass ?? '', {
    [css.floatingButton]: !isStudent,
    [css.floatingButtonStudent]: isStudent,
    [css.floatingButtonCart]: isCart,
  });
  const [floatingOpen, setFloatingOpen] = useState(false);

  const handleFloatingClick = () => {
    setFloatingOpen(prevValue => !prevValue);
  };

  const handleCloseFloating = event => {
    setFloatingOpen(false);
    event.stopPropagation();
  };

  const handleClickOption = (event, option) => {
    if (!option.disabled) {
      option.onClick();
      handleCloseFloating(event);
    } else {
      event.stopPropagation();
    }
  };

  return (
    <>
      <Backdrop
        open={floatingOpen}
        className={css.backdrop}
        onClick={handleCloseFloating}
      />
      <SpeedDial
        ariaLabel="floatingButton"
        id={`${id}-floatingBtn`}
        className={btnClasses}
        hidden={!show}
        icon={
          !isStudent ? (
            <SpeedDialIcon />
          ) : (
            `Add ${id.charAt(0).toUpperCase() + id.slice(1)}`
          )
        }
        open={floatingOpen}
        onClick={handleFloatingClick}
        direction="up"
        data-test-id="floatingButton"
        FabProps={{ id: `${id}-button` }}
      >
        {options.map(option => (
          <SpeedDialAction
            FabProps={{ disableRipple: true }}
            key={option.text}
            icon={option.icon}
            tooltipTitle={option.text}
            tooltipOpen
            tooltipPlacement={isStudent && 'right'}
            onClick={event => handleClickOption(event, option)}
            classes={{
              staticTooltip: `${classNames(css.option, {
                [css.optionStudent]: isStudent,
              })} ${option.color ? css[option.color] : ''} ${
                option.disabled ? css.disabled : ''
              }`,
              staticTooltipLabel: `${classNames(css.floatingLabel, {
                [css.floatingLabelStudent]: isStudent,
              })}`,
            }}
            data-test-id={option.testId}
          />
        ))}
      </SpeedDial>
    </>
  );
}

CustomSpeedDial.propTypes = propTypes;

export default CustomSpeedDial;
