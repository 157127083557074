import { makeStyles } from 'tss-react/mui';

const useForgotPasswordStyles = makeStyles()(theme => ({
  forgotPassContainer: {
    background: `${theme.palette.background.paper} !important`,
    '& .MuiInputLabel-root': {
      color: `${theme.palette.text.primary} !important`,
    },
  },
  desktopContainer: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.common.grey4}`,
    borderRadius: theme.spacing(0.5),
  },
  smallDeviceContainer: {
    padding: theme.spacing(2, 1.5),
  },
  titleSection: {
    paddingBottom: theme.spacing(3),
  },
  title: {
    fontWeight: 'bold',
    fontSize: theme.spacing(2.5),
  },
  loading: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
  },
  forgotPassBtn: {
    width: '100%',
    fontWeight: 'bold',
  },
  cancelBtnContainer: {
    paddingTop: theme.spacing(3),
  },
  cancelBtn: {
    padding: theme.spacing(0, 0.5),
    textDecorationColor: theme.palette.common.black,
    color: theme.palette.common.blue1,
    fontWeight: 'bold',
  },
}));

export default useForgotPasswordStyles;
