import {
  handleSaveOrderSuccess,
  handleUploadErrors,
  reloadCart,
  reloadOrderItems,
  setMoveItemsStatus,
  setRefreshTax,
} from '../../redux/slices/cartSlice';

import {
  addFromConsignedLoading,
  updateLoadingState,
} from '../../redux/slices/consignedInventorySlice';
import { closeSignalRConnection } from './signalRService';
import { connectionResponseState } from '../../strings/appConstants';
import { displayError } from '../../redux/slices/notificationsSlice';
import { signalRStrings } from '../../strings/signalRStrings';
import store from '../../redux/store';
import { hideLoading } from '../../redux/slices/commonSlice';
import { setShouldGetCart } from '../../redux/slices/guestCartSlice';

const signalRListeners = connection => {
  connection.on(signalRStrings.uploadedItemsToCart, payload =>
    uploadedItemsToCart(payload, connection)
  );
  connection.on(signalRStrings.checkedSaveOrder, payload =>
    checkedSaveOrder(payload, connection)
  );
  connection.on(signalRStrings.addFromConsignedToOrder, payload => {
    addItemsFromConsignedToOrder(payload, connection);
  });
  connection.on(signalRStrings.moveCartItems, payload => {
    moveItems(payload, connection);
  });
  connection.on(signalRStrings.moveOrderItems, payload => {
    moveItems(payload, connection);
  });
};

const uploadedItemsToCart = (payload, connection) => {
  if (payload.state === connectionResponseState.error) {
    store.dispatch(handleUploadErrors(payload));
  } else {
    store.dispatch(reloadCart(payload));
  }
  closeSignalRConnection(connection);
};

const checkedSaveOrder = (payload, connection) => {
  if (payload.state === connectionResponseState.error) {
    store.dispatch(reloadOrderItems(payload));
    store.dispatch(setShouldGetCart(true));
    store.dispatch(setRefreshTax(true));
    store.dispatch(hideLoading());
    store.dispatch(displayError({ message: payload.message, permanent: true }));
  } else {
    const message = JSON.parse(payload.message.actionMessage);
    const saveData = {
      state: payload.state,
      orderNumber: message.OrderNumber,
    };
    store.dispatch(handleSaveOrderSuccess(saveData));
  }
  closeSignalRConnection(connection);
};

const addItemsFromConsignedToOrder = (payload, connection) => {
  if (payload.state === connectionResponseState.error) {
    store.dispatch(displayError());
    store.dispatch(addFromConsignedLoading(false));
    store.dispatch(updateLoadingState(false));
  } else {
    store.dispatch(updateLoadingState(false));
    store.dispatch(addFromConsignedLoading(connectionResponseState.done));
  }
  closeSignalRConnection(connection);
};

const moveItems = (payload, connection) => {
  if (payload.state === connectionResponseState.error) {
    store.dispatch(displayError());
    store.dispatch(hideLoading());
  } else {
    store.dispatch(hideLoading());
    const message = JSON.parse(payload.message.actionMessage);
    store.dispatch(setMoveItemsStatus({ message }));
  }
  closeSignalRConnection(connection);
};

export const listeners = {
  uploadedItemsToCart,
};

export default signalRListeners;
