import { createContext } from 'react';

const ProductListContext = createContext({
  isSavedItems: false,
  isOrder: false,
  bp: '',
  paginationDetails: {
    from: 0,
    to: 10,
    itemsPerPage: 10,
    page: 1,
  },
  setPaginationDetails: () => {},

  displayedProducts: [],
  setDisplayedProducts: () => {},

  expanded: null,
  setExpanded: () => {},

  cartInfo: {},
  products: [],

  expandedProduct: null,
  setExpandedProduct: () => {},

  initialValues: {},
  addingProductValue: {},
  checkExpand: () => {},
  addLoadingRows: () => {},
  removeLoadingRows: () => {},

  //useForm Props
  control: {},
  formState: {},
  setValue: () => {},
  reset: () => {},
  getValues: () => {},
  trigger: () => {},

  errors: {},
  dirtyFields: {},
  isDirty: false,
  formControl: undefined,
  cartCtx: {},
});

export default ProductListContext;
