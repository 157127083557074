import CONSTANTS from '../constants';
import { createSlice } from '@reduxjs/toolkit';

const initialSearchValues = {
  bpNumber: '',
  email: '',
  firstName: '',
  lastName: '',
  schoolName: '',
  studentStatus: '',

  // ApproveReject search inputs
  graduationEndDate: '',
  graduationStartDate: '',
  instructorName: '',
  phoneNumber: '',
  registrationEndDate: '',
  registrationStartDate: '',
  studentApprovedStatus: {
    approved: true,
    rejected: true,
    pending: true,
  },
};

const studentSearch = createSlice({
  name: CONSTANTS.studentSearch,
  initialState: {
    searchInputs: { ...initialSearchValues },
    checkedSearchResults: [],
    schoolsList: [],
  },
  reducers: {
    updateSearchInputs: (state, action) => {
      state.searchInputs = action.payload;
    },
    resetSearchInputs: state => {
      state.searchInputs = initialSearchValues;
    },
    // reset - to reset checked list
    // checkedList - array - list with all selected elements
    // id - string - toggle selected on an element
    updateCheckedSearchResults: (state, { payload }) => {
      if (payload.reset) {
        state.checkedSearchResults = [];
      }
      if (payload.checkedList) {
        state.checkedSearchResults = payload.checkedList;
      }

      if (payload.id) {
        const checkedIndex = state.checkedSearchResults?.findIndex(
          std => std === payload.id
        );
        if (checkedIndex === -1) {
          state.checkedSearchResults.push(payload.id);
        } else {
          state.checkedSearchResults.splice(checkedIndex, 1);
        }
      }
    },
    setSchoolsList: (state, { payload }) => {
      state.schoolsList = payload;
    },
  },
});

export const {
  updateSearchInputs,
  resetSearchInputs,
  updateCheckedSearchResults,
  setSchoolsList,
} = studentSearch.actions;

export default studentSearch.reducer;
