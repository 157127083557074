import { makeStyles } from 'tss-react/mui';

export const useOrderDetailsStyles = makeStyles()(theme => ({
  boxContainer: {
    '& .MuiBox-root': {
      borderColor: theme.palette.common.grey4,
      padding: '0px !important',
      '@media screen and (max-width:768px)': {
        border: 0,
        borderTop: `1px solid ${theme.palette.common.grey4}`,
        borderBottom: `1px solid ${theme.palette.common.grey4}`,
        borderRadius: 0,
      },
    },

    '& .MuiSkeleton-root': {
      borderRadius: theme.spacing(2),
    },
  },

  paginationContainer: {
    '& .MuiGrid-root': { border: 0, backgroundColor: 'transparent' },
  },

  title: {
    borderBottom: '1px solid',
    borderColor: theme.palette.common.fadeGrey,
  },

  content: {
    borderBottom: '1px solid',
    borderColor: theme.palette.common.fadeGrey,
    padding: theme.spacing(1, 2),
  },

  orderSummaryText: {
    '& .MuiTypography-root': {
      fontSize: '16px',
    },
  },

  orderTotal: {
    '& .MuiTypography-root': {
      fontSize: '18px',
      fontWeight: 'bold',
    },
  },

  containerPadding: {
    '& .MuiBox-root': {
      padding: `${theme.spacing(3, 2)} !important`,
    },
  },

  trackingNumbersStyle: {
    padding: `${theme.spacing(0, 2, 2, 2)} !important`,

    '& .MuiTableHead-root': {
      '& .MuiTypography-root': {
        fontWeight: 'bold',
      },
    },

    '& .MuiTableCell-root': {
      textAlign: 'center',
    },
  },

  tableBody: {
    '& .MuiTableCell-root': {
      border: 'none',
      padding: 0,
      wordBreak: 'break-word',
    },
    '& .MuiTableRow-root:first-of-type': {
      '& .MuiTableCell-root': {
        paddingTop: theme.spacing(2),
      },
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.common.black,
    },
  },

  tableBodySkeleton: {
    '& .MuiTableCell-root': {
      border: 'none',
      textAlign: 'center',
    },
    '& .MuiSkeleton-root': {
      width: '60px',
    },
  },

  dialogStyle: {
    padding: theme.spacing(2, 0),
    '& .MuiDialogContent-root': {
      margin: 0,
      textAlign: 'start',
    },
  },
}));
