import { makeStyles } from 'tss-react/mui';

const useAccordionTemplateStyles = makeStyles()(theme => ({
  bolded: {
    fontWeight: theme.typography.fontWeightBold,
  },
  accordion: {
    boxShadow: `0px 2px 4px 0px ${theme.palette.common.fadeGrey}, 0px 2px 4px 0px ${theme.palette.common.fadeGrey}`,
    margin: `${theme.spacing(1)} 0px`,
    '&:before': {
      content: 'none',
    },
  },
  accordionDetails: {
    paddingTop: 0,
  },
  checkbox: {
    '&.MuiCheckbox-indeterminate span': {
      color: theme.palette.common.primary,
    },
  },
  sectionTitle: {
    fontSize: theme.typography.h2.fontSize,
    margin: theme.spacing(0.5),
  },
  expandIcon: {
    color: theme.palette.common.darkGrey,
  },
  expanded: {
    '&.Mui-expanded': {
      '& svg': {
        color: theme.palette.common.primary,
      },
    },
  },
  invalid: {
    color: theme.palette.common.red,
  },
  invalidBorder: {
    boxShadow: `0px 2px 4px 0px red`,
  },
  normalCursor: {
    cursor: 'default !important',
  },
  detailsContainer: {
    marginRight: '45px',
    position: 'relative',
  },
}));

export default useAccordionTemplateStyles;
