import { makeStyles } from 'tss-react/mui';

const useStudentProfileStyles = makeStyles()(theme => ({
  container: {
    paddingBottom: theme.spacing(2),
  },
  saveProfile: {
    marginLeft: theme.spacing(2),
  },
  name: {
    textTransform: 'capitalize',
  },
  studentProfileLoader: {
    position: 'relative',
    height: '80vh',
    background: theme.palette.common.neutral2,
  },
  purchasePowerContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  profileContainer: {
    background: theme.palette.common.neutral2,
    '& .MuiBox-root': {
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(1),
    },
  },
  leaveIcon: {
    color: theme.palette.common.primary,
  },
  marginLeft2: {
    marginLeft: theme.spacing(2),
  },
  marginLeft4: {
    marginLeft: theme.spacing(4),
  },
  customBox: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

export default useStudentProfileStyles;
