import { makeStyles } from 'tss-react/mui';

const useHeaderStyles = makeStyles()(theme => ({
  header: {
    width: '100%',
    textAlign: 'center',
    backgroundColor: theme.palette.common.neutral1,
    padding: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    borderTop: `${theme.spacing(0.5)} solid`,
    borderTopColor: 'red !important',
    borderBottom: `${theme.spacing(0.3)} solid`,
    borderColor: theme.palette.common.shadow,

    '& .MuiButtonBase-root': {
      padding: 0,
    },

    '& .MuiDivider-root': {
      margin: theme.spacing(1, 1),
    },
  },

  mobileViewTopContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  hamburgerAndLogo: {
    minWidth: 'fit-content',
  },
  applyForECBtn: {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: `5px 5px !important`,

    '& .MuiButton-endIcon': {
      marginLeft: 0,
      marginRight: '8px',
    },

    '@media screen and (max-width:768px)': {
      fontSize: '12px !important',
      padding: `2px 3px !important`,
    },
  },
}));

export default useHeaderStyles;
