import {
  closeWizard,
  setActiveStep,
  setTransactionNumber,
  submittedECApp,
} from '../../../../core/redux/slices/wizardSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import ApiService from '../../Api/apiService';
import CustomBackdrop from '../../CustomBackdrop/CustomBackdrop';
import CustomButton from '../../CustomButton/CustomButton';
import { Grid } from '@mui/material';
import { STUDENT_PROFILE } from '../../../../core/navigation/paths';
import SignComponent from '../../SignComponent/SignComponent';
import appStrings from '../../../../core/strings/appStrings';
import { buttonTypes } from '../../../../core/strings/appConstants';
import { getECAppStatus } from './wizardActionButtonsService';
import urls from '../../../../core/strings/urls';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { wizardSteps } from '../wizardConstants';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import { getDisplayErrors } from '../../../../core/services/utilsService';

function WizardActionButtons({ disableSignNow, handleSubmit }) {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { id, bp } = useParams();
  const [isLoading, setLoading] = useState(false);
  const applicationData = useRef();
  const [isStudent, setIsStudent] = useState(false);

  const activeStep = useSelector(state => state.wizard.activeStep);
  const lastStep = wizardSteps.length - 1;
  const { isLoggedIn, userDetails } = useSelector(state => state.login);
  const isRepOrAdmin = ['Admin', 'Rep'].indexOf(userDetails.role) !== -1;

  useEffect(() => {
    setIsStudent(isLoggedIn && !isRepOrAdmin);
  }, [isLoggedIn, isRepOrAdmin]);

  const generateLink = () => {
    return ApiService.get(urls.generateECApplicationLink(id))
      .then(resp => {
        dispatch(setTransactionNumber(resp.ecAppIdentifier));
        applicationData.current = {
          ...resp,
          creditAppId: resp.ecAppIdentifier,
        };
        return { ...resp, creditAppId: resp.ecAppIdentifier };
      })
      .catch(e => {
        dispatch(displayError({ message: getDisplayErrors(e) }));
      });
  };

  const closeSigning = () => {
    if (isStudent) {
      navigation(-1);
    } else {
      navigation(STUDENT_PROFILE(bp, id));
    }
    dispatch(closeWizard());
  };

  const successCallback = () => {
    setLoading(false);

    dispatch(submittedECApp());
  };

  const errorCallback = () => {
    setLoading(false);

    closeSigning();
  };

  const getFileStatusCallback = () => {
    console.log('file getFileStatusCallback ');
    setLoading(true);
    return getECAppStatus(id, applicationData.current.creditAppId);
  };

  const handleSetLastStep = () => dispatch(setActiveStep(lastStep));

  return (
    <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
      <Grid item>
        {activeStep === lastStep ? (
          <CustomButton
            label={appStrings.wizard.signLater}
            btnType={buttonTypes.secondary}
            onClick={() => closeSigning()}
            id="wizardSaveLaterBtn"
          />
        ) : (
          <CustomButton
            label={appStrings.wizard.signNow}
            btnType={buttonTypes.secondary}
            onClick={handleSubmit(handleSetLastStep)}
            id="wizardGoToSignNowBtn"
          />
        )}
      </Grid>

      <Grid item>
        {activeStep === lastStep ? (
          <SignComponent
            label={appStrings.wizard.signNow}
            id="wizardSignNowBtn"
            disableSignNow={disableSignNow}
            studentId={id}
            generateLink={generateLink}
            successCallback={successCallback}
            errorCallback={errorCallback}
            getFileStatusCallback={getFileStatusCallback}
            isStudent={isStudent}
          />
        ) : (
          <CustomButton
            label={appStrings.wizard.saveAndContinue}
            type="submit"
            id="wizardSaveAndContinueBtn"
          />
        )}
      </Grid>

      <CustomBackdrop open={isLoading} />
    </Grid>
  );
}

export default WizardActionButtons;
