import { createSlice } from '@reduxjs/toolkit';
import CONSTANTS from '../constants';

const categoriesSlice = createSlice({
  name: CONSTANTS.categories,
  initialState: {
    listOfPaths: [],
    categoriesData: null,
  },
  reducers: {
    setPaths: (state, { payload }) => {
      const {
        paths,
        currentCategory,
        callback
      } = payload;
      state.listOfPaths = paths;
      let breadcrumbs = state.listOfPaths?.map(path => path.label).join('|');
      breadcrumbs += `|${currentCategory?.name}`;
      if (callback) {
        callback(breadcrumbs, currentCategory?.id);
      }
    },

    setCategories: (state, { payload }) => {
      state.categoriesData = payload;
    },
  },
});

export const { setPaths, setCategories } = categoriesSlice.actions;

export default categoriesSlice.reducer;
