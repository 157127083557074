import React, { useEffect, useState } from 'react';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import OrderHeader from './OrderHeader';
import { Grid, Stack } from '@mui/material';
import CustomPagination from '../../../shared/CustomPagination/CustomPagination';
import { useDispatch, useSelector } from 'react-redux';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import { getOrders } from './orderServices';
import { saveHistoryDetails } from '../../../../core/redux/slices/historySlice';
import OrderHeaderSkeleton from './OrderHeaderSkeleton';
import { trackGTMEvent } from '../../../shared/Analytics/analyticsService';

function Orders() {
  const dispatch = useDispatch();
  const { userId } = useSelector(state => state.login.userDetails);
  const [loading, setLoading] = useState(false);
  const { rowsCount, details } = useSelector(state => state.history);
  const [paginationDetails, setPaginationDetails] = useState({
    from: 0,
    to: 5,
    page: 1,
    itemsPerPage: 5,
  });

  const handlePageChange = ({ from, to, page, itemsPerPage }) => {
    setPaginationDetails({ from, to, page, itemsPerPage });
  };

  useEffect(() => {
    if (userId) {
      setLoading(true);
      getOrders({
        studentId: userId,
        page: paginationDetails.page,
        itemsPerPage: paginationDetails.itemsPerPage,
      })
        .then(res => {
          dispatch(
            saveHistoryDetails({
              details: res.transactions,
              rowsCount: res.count,
            })
          );
        })
        .catch(e => {
          dispatch(displayError({ message: e }));
        })
        .finally(() => {
          setLoading(false);
        });
      trackGTMEvent('other-pageview', {
        page_type: 'other',
        page_title: document.title,
        orig_ref_url: document.location.href
      })
    }
  }, [
    dispatch,
    paginationDetails.itemsPerPage,
    paginationDetails.page,
    userId,
  ]);

  return (
    <Stack spacing={2}>
      <Grid item container spacing={2}>
        {(details || Array(5).fill(true)).map((order, index) => (
          <Grid item xs={12} key={index} id={`orderRow_${index}`}>
            <CustomBox>
              {!loading ? (
                <OrderHeader data={order} index={index} />
              ) : (
                <OrderHeaderSkeleton />
              )}
            </CustomBox>
          </Grid>
        ))}
      </Grid>
      {details && (
        <Grid item xs={12}>
          <CustomPagination
            itemsLength={rowsCount}
            itemsPerPage={paginationDetails.itemsPerPage}
            onPageChangeCallback={handlePageChange}
            isStudent={true}
            isLoading={loading}
            shape={'rounded'}
            variant={'outlined'}
          />
        </Grid>
      )}
    </Stack>
  );
}

export default Orders;
