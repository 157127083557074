import {
  displayError,
  displaySuccess,
} from '../../../../core/redux/slices/notificationsSlice';
import store from '../../../../core/redux/store';
import { notificationRole } from '../../../../core/strings/appConstants';
import appStrings from '../../../../core/strings/appStrings';
import { submitForgotPassword } from '../../ForgotPassword/forgotPasswordService';

export const updateDisableFields = ({
  setDisableDriverLicenseState,
  setDisablePhoneType1,
  setDisablePhoneType2,
  resp,
}) => {
  setDisableDriverLicenseState(
    Boolean(resp?.driverLicenseNumber?.trim()) ? false : true
  );
  setDisablePhoneType1(Boolean(resp?.phone1?.phoneNumber) ? false : true);
  setDisablePhoneType2(Boolean(resp?.phone2?.phoneNumber) ? false : true);
};

export const formatCustomerInfo = resp => {
  resp.maritalStatus = resp.maritalStatus === 0 ? '' : resp.maritalStatus;
  if (resp.phone1) {
    resp.phone1.phoneType =
      resp.phone1.phoneType === 0 ? '' : resp.phone1.phoneType;
  }
  if (resp.phone2) {
    resp.phone2.phoneType =
      resp.phone2.phoneType === 0 ? '' : resp.phone2.phoneType;
  }
};

export const resetPasswordEmail = ({
  setSendingResetPassword,
  customerInfo,
  isStudent,
}) => {
  setSendingResetPassword(true);
  submitForgotPassword(customerInfo)
    .then(() =>
      store.dispatch(
        displaySuccess({
          role: isStudent
            ? notificationRole.student
            : notificationRole.industrial,
          message: appStrings.forgotPassword.submittedMessage,
        })
      )
    )
    .catch(error =>
      store.dispatch(
        displayError({ role: notificationRole.student, message: error })
      )
    )
    .finally(() => setSendingResetPassword(false));
};
