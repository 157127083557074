import PropTypes from 'prop-types';
import React from 'react';
import appStrings from '../strings/appStrings';
import logError from '../errorLogging/errorLogging';

const propTypes = {
  componentName: PropTypes.string,
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    logError({
      error,
      errorInfo,
      componentName: this.props.componentName,
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <p>{appStrings.errors.somethingWentWrong}</p>;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = propTypes;

export default ErrorBoundary;
