import { makeStyles } from 'tss-react/mui';

const useSendEmailModalDialogStyles = makeStyles()(theme => ({
  container: {
    marginTop: theme.spacing(3),
  },
  emailDialog: {
    padding: theme.spacing(3, 1),
    width: '500px',
    '.MuiDialogContent-root': {
      paddingBottom: 0,
    },
    '.MuiDialogActions-root': {
      paddingTop: 0,
      '.MuiButtonBase-root': {
        width: '100%',
        textTransform: 'none',
        margin: theme.spacing(0, 2),
      },
    },
  },
  emailInput: {
    width: '90%',
    marginTop: theme.spacing(2),
  },
  emailCheckbox: {
    marginTop: '12px',
  },
  tertiaryButton: {
    textDecoration: 'none',
    marginTop: theme.spacing(2),
  },
  buttonContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    padding: theme.spacing(4, 0),
  },
}));

export default useSendEmailModalDialogStyles;
