export const passwordHashSalt = 'HjhZh+==JNJhj6az@';

export const gridLayout = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 4,
};

export const steps = [
  {
    index: 0,
    label: 'Contact Information',
    id: 'registerStep-ContactInformation',
  },
  {
    index: 1,
    label: 'School Information',
    id: 'registerStep-SchoolInformation',
  },
  { index: 2, label: 'Password', id: 'registerStep-Password' },
];
