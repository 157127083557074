import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
} from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { getOtherRecipients, sendEmailInputs } from './sendEmailConstants';

import CloseIcon from '@mui/icons-material/Close';
import CustomButton from '../CustomButton/CustomButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FormInput from '../FormInput/FormInput';
import appStrings from '../../../core/strings/appStrings';
import { buttonTypes } from '../../../core/strings/appConstants';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

function SendEmailModal({ label, btnType, id, title, onSubmitCallback }) {
  const [open, setOpen] = useState(false);
  const [shouldSendToRep, setShouldSendToRep] = useState(false);
  const [shouldSendToStudent, setShouldSendStudent] = useState(true);
  const [additionalEmails, setAdditionalEmails] = useState([true]);
  const student = useSelector(
    state => state.schoolsWithStudents.selectedStudent.student
  );

  const userDetails = useSelector(state => state.login.userDetails);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    formState,
    reset,
    setError,
  } = useForm();

  const { isDirty, errors } = formState;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setOpen(false);
    setShouldSendStudent(true);
    setShouldSendToRep(false);
    setAdditionalEmails([true]);
  };

  const handleSubmitEvent = data => {
    // TODO: SEPTU-373
    const emails = [];
    if (shouldSendToStudent) {
      emails.push(data.studentEmail);
    }
    if (shouldSendToRep) {
      emails.push(data.repEmail);
    }

    data.otherEmails.forEach(email => {
      if (email.length) {
        emails.push(email);
      }
    });

    if (onSubmitCallback) {
      onSubmitCallback(emails);
    }

    handleClose();
  };

  useEffect(() => {
    if (student) {
      setValue('studentEmail', student.email, { shouldDirty: false });
    }
  }, [setValue, student]);

  useEffect(() => {
    //TODO: update for admin
    if (userDetails) {
      setValue('repEmail', userDetails.email, { shouldDirty: false });
    }
  }, [setValue, userDetails]);

  return (
    <>
      <CustomButton
        label={label ?? appStrings.wizard.emailECApp}
        btnType={btnType ?? buttonTypes.secondary}
        id={id ?? 'sendEmailOpenBtn'}
        onClick={handleClickOpen}
      />

      <Container>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle textAlign={'center'}>
            {title ?? appStrings.wizard.emailFiles}
          </DialogTitle>

          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          <form onSubmit={handleSubmit(handleSubmitEvent)}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h4>{appStrings.common.emailTo}</h4>
                </Grid>
                <Grid item xs={12} container>
                  {sendEmailInputs({
                    shouldSendToStudent,
                    setShouldSendStudent,
                    shouldSendToRep,
                    setShouldSendToRep,
                  }).map(field => (
                    <Grid
                      item
                      {...field.gridLayout}
                      key={`${field.name}-container`}
                      data-test-id={`${field.name}`}
                    >
                      <FormInput
                        field={field}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        watch={watch}
                        errors={errors}
                      />
                    </Grid>
                  ))}
                </Grid>

                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12}>
                    <h4>{appStrings.wizard.additionalRecipients}</h4>
                  </Grid>
                  {additionalEmails.map((v, i) => {
                    if (v) {
                      const field = getOtherRecipients(i);
                      return (
                        <Fragment key={`${field.name}-container`}>
                          <Grid
                            item
                            {...field.gridLayout}
                            data-test-id={`${field.name}`}
                          >
                            <FormInput
                              field={field}
                              control={control}
                              setValue={setValue}
                              getValues={getValues}
                              watch={watch}
                              errors={errors}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CustomButton
                              startIcon={<DeleteOutlineOutlinedIcon />}
                              label={appStrings.common.delete}
                              btnType={buttonTypes.tertiary}
                              onClick={() => {
                                setAdditionalEmails(prev => {
                                  const next = [...prev];
                                  next[i] = null;
                                  return next;
                                });
                                setValue(`otherEmails.${i}`, '');
                              }}
                            />
                          </Grid>
                        </Fragment>
                      );
                    }
                  })}

                  <Grid item xs={12}>
                    <CustomButton
                      label={appStrings.wizard.addNewEmail}
                      btnType={buttonTypes.secondary}
                      onClick={() =>
                        setAdditionalEmails(prev => {
                          const next = [...prev];
                          next.push(true);
                          return next;
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                padding={'0 15px 15px'}
                spacing={2}
              >
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs>
                  <CustomButton
                    sx={{ width: '100%' }}
                    onClick={handleClose}
                    label={appStrings.common.cancel}
                    btnType={buttonTypes.secondary}
                  />
                </Grid>
                <Grid item xs>
                  <CustomButton
                    sx={{ width: '100%' }}
                    label={appStrings.wizard.sendEmail}
                    type="submit"
                    disabled={!shouldSendToRep && !shouldSendToStudent}
                  />
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </Dialog>
      </Container>
    </>
  );
}

export default SendEmailModal;
