import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import {
  buttonColors,
  buttonTypes,
} from '../../../../core/strings/appConstants';
import appStrings from '../../../../core/strings/appStrings';

function EditAddressButtons({
  openEdit,
  setOpenEdit,
  section,
  handleSaveEditedAddress,
  handleCancelEditedAddress,
  isValid,
}) {
  const handleOpenEdit = () =>
    setOpenEdit(prev => ({
      ...prev,
      [section]: openEdit[section] ? false : true,
    }));

  const openCloseButton = label => (
    <CustomButton
      id={`${section}_${label}`}
      size="small"
      color={buttonColors.black}
      label={label}
      btnType={buttonTypes.secondary}
      onClick={() => {
        if (openEdit[section]) {
          handleCancelEditedAddress(section);
        }
        handleOpenEdit();
      }}
    />
  );

  return openEdit[section] ? (
    <Grid
      item
      container
      spacing={2}
      justifyContent="flex-end"
      alignContent="center"
    >
      {/* Cancel button */}
      <Grid item>{openCloseButton(appStrings.common.cancel)}</Grid>
      {/* Save button */}
      <Grid item>
        <CustomButton
          id={`${section}_save`}
          size="small"
          color={buttonColors.red}
          label={appStrings.common.save}
          onClick={() => {
            handleSaveEditedAddress(section);
            if (isValid) {
              handleOpenEdit();
            }
          }}
        />
      </Grid>
    </Grid>
  ) : (
    /* Edit button */
    <Grid item>{openCloseButton(appStrings.common.edit)}</Grid>
  );
}

EditAddressButtons.propTypes = {
  openEdit: PropTypes.object,
  setOpenEdit: PropTypes.func,
  section: PropTypes.string,
  handleSaveEditedAddress: PropTypes.func,
  handleCancelEditedAddress: PropTypes.func,
  isValid: PropTypes.bool,
};

export default EditAddressButtons;
