import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { addProductToCart } from '../../ShoppingCart/ShoppingCartService';
import CartButtonLoading from './CartButtonLoading';
import { cartTypes } from '../../../../core/strings/appConstants';

function CartButton({ productDetails }) {
  const dispatch = useDispatch();
  const { isLoggedIn, userDetails } = useSelector(state => state.login);
  const loadingAddProduct = useSelector(
    state => state.guestCart.loadingAddProduct
  );

  const isLoading =
    loadingAddProduct.indexOf(productDetails.productNumber) !== -1;
  const isDisabled = isLoggedIn && !userDetails?.isActive;

  const handleAddToCart = () => {
    addProductToCart(
      productDetails,
      dispatch,
      isLoggedIn,
      userDetails.userId,
      cartTypes.cart
    );
  };

  return !isLoading ? (
    <ShoppingCartOutlinedIcon
      id="productContentCart-Button"
      data-test-id="ShoppingCartOutlinedIcon"
      fontSize="medium"
      className={isDisabled ? 'disabled' : ''}
      onClick={event => {
        event.stopPropagation();
        if (!isDisabled) {
          handleAddToCart();
        }
      }}
    />
  ) : (
    <CartButtonLoading />
  );
}

CartButton.propTypes = {
  productDetails: PropTypes.object,
};

export default CartButton;
