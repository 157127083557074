import CustomAccordionSkeleton from '../../shared/CustomSkeleton/CustomAccordionSkeleton';
import CustomSkeleton from '../../shared/CustomSkeleton/CustomSkeleton';

function ApproveRejectSkeleton() {
  const titleLayout = [
    { xs: 6, width: '11rem' },
    {
      xs: 6,
      width: '1.7rem',
      height: '1.7rem',
      variant: 'circular',
      container: true,
      justifyContent: 'flex-end',
    },
  ];
  const titleSkeleton = <CustomSkeleton layout={titleLayout} />;

  return (
    <CustomAccordionSkeleton
      data-test-id="approveRejectSkeleton"
      numberOfSections={3}
      titleSkeleton={titleSkeleton}
    />
  );
}

export default ApproveRejectSkeleton;
