import { makeStyles } from 'tss-react/mui';

const shadowStyle = '0 1px 1px';

const getPrimaryStyle = ({ baseColor, darkColor }) => {
  return {
    backgroundColor: `${baseColor} !important`,
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: `${darkColor} !important`,
    },
    '&:active': {
      backgroundColor: `${baseColor} !important`,
      borderColor: `${darkColor} !important`,
      boxShadow: `${shadowStyle} ${darkColor} !important`,
    },
  };
};

const getSecondaryStyle = ({ accentColor, hoverColor }) => {
  return {
    borderColor: `${accentColor} !important`,
    color: `${accentColor} !important`,
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: `${hoverColor} !important`,
    },
    '&:active': {
      backgroundColor: `transparent !important`,
      boxShadow: `${shadowStyle} ${accentColor} !important`,
    },
  };
};

const getTertiaryStyle = ({ color }) => {
  return {
    color: `${color} !important`,
    '&:hover, &.Mui-focusVisible': {
      opacity: 0.8,
      textDecoration: 'none',
    },
    '&:active': {
      borderColor: 'transparent',
      opacity: 1,
      fontWeight: 'bold',
      textDecoration: 'underline',
    },
  };
};

const useCustomButtonStyles = makeStyles()(theme => ({
  button: {
    border: `1px solid transparent`,
    textTransform: 'none',
    wordBreak: 'keep-all',
    fontWeight: theme.typography.fontWeightMedium,
    '&:disabled': {
      opacity: '0.5',
    },
    '&:not(.MuiButton-containedSizeSmall)': {
      '&:not(.MuiButton-containedSizeLarge)': {
        padding: `5px 25px`,
        fontSize: theme.typography.body2.fontSize,
      },
    },
  },

  // Primary should only set the base look (shadow/border etc), no important
  // primary should set the primary color
  primary: {
    color: `${theme.palette.common.white} !important`,
    ...getPrimaryStyle({
      baseColor: theme.palette.common.primary,
      darkColor: theme.palette.common.primary5,
    }),
    '&.green': getPrimaryStyle({
      baseColor: theme.palette.common.green,
      darkColor: theme.palette.common.darkGreen,
    }),
    '&.yellow': getPrimaryStyle({
      baseColor: theme.palette.common.yellow,
      darkColor: theme.palette.common.darkYellow,
    }),
    '&.red': getPrimaryStyle({
      baseColor: theme.palette.common.red,
      darkColor: theme.palette.common.darkRed,
    }),
    '&.secondary': getPrimaryStyle({
      baseColor: theme.palette.common.secondary,
      darkColor: theme.palette.common.secondary7,
    }),
    '&.black': getPrimaryStyle({
      baseColor: 'black',
      darkColor: theme.palette.common.shadow,
    }),
    '&.eucalyptus': getPrimaryStyle({
      baseColor: theme.palette.common.secondary4,
      darkColor: theme.palette.common.primary,
    }),
  },

  secondary: {
    backgroundColor: 'rgba(255, 255, 255, 1) !important',
    ...getSecondaryStyle({
      accentColor: theme.palette.common.secondary,
      hoverColor: theme.palette.common.secondary6,
    }),
    '&.green': getSecondaryStyle({
      accentColor: theme.palette.common.green,
      hoverColor: theme.palette.common.fadeGreen,
    }),
    '&.yellow': getSecondaryStyle({
      accentColor: theme.palette.common.yellow,
      hoverColor: theme.palette.common.fadeYellow,
    }),
    '&.red': getSecondaryStyle({
      accentColor: theme.palette.common.red,
      hoverColor: theme.palette.common.fadeRed,
    }),
    '&.black': getSecondaryStyle({
      accentColor: 'black',
      hoverColor: theme.palette.common.shadow,
    }),
    '&.eucalyptus': getSecondaryStyle({
      accentColor: theme.palette.common.secondary4,
      hoverColor: theme.palette.common.primary,
    }),
  },

  tertiary: {
    backgroundColor: 'transparent !important',
    textDecoration: 'underline',
    ...getTertiaryStyle({
      color: theme.palette.common.secondary,
    }),
    '&.green': getTertiaryStyle({
      color: theme.palette.common.green,
    }),
    '&.yellow': getTertiaryStyle({
      color: theme.palette.common.yellow,
    }),
    '&.red': getTertiaryStyle({
      color: theme.palette.common.red,
    }),
    '&.black': getPrimaryStyle({
      color: 'black',
    }),
    '&.eucalyptus': getPrimaryStyle({
      color: theme.palette.common.secondary4,
    }),
  },
}));

export default useCustomButtonStyles;
