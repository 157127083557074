import { Grid, InputAdornment } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { approveRejectSearchInputs, schoolSearchInputs } from './searchInputs';
import { buttonTypes, dateFormat } from '../../../../core/strings/appConstants';
import {
  formatSchoolNamesOptions,
  getAllSchools,
  getValidations,
  updateDisabledStateCheckboxes,
} from '../studentSearchService';

import ClearIcon from '@mui/icons-material/Clear';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import FormInput from '../../../shared/FormInput/FormInput';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import appStrings from '../../../../core/strings/appStrings';
import classNames from 'classnames';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import useStudentSearchStyles from '../useStudentSearchStyles';
import { getHasSearchData } from '../searchUtils';
import { ADMIN } from '../../../../core/navigation/roles';
import { setSchoolsList } from '../../../../core/redux/slices/studentSearchSlice';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

function StudentSearchForm({ onSubmit, approveReject = false }) {
  const css = useStudentSearchStyles().classes;
  const dispatch = useDispatch();
  const searchInputsValue = useSelector(
    state => state.studentSearch.searchInputs
  );
  const schoolsWithStudents = useSelector(state => state.schoolsWithStudents);
  const schoolsListSearch = useSelector(
    state => state.studentSearch.schoolsList
  );
  const schoolListSelectedRep = schoolsWithStudents.schoolListSelectedRep;

  const { role } = useSelector(state => state.login.userDetails);
  const isAdmin = role === ADMIN;
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const watchForm = watch();

  const [validations, setValidations] = useState(getValidations(getValues));
  const [checkboxesDisabled, setCheckboxesDisabled] = useState({
    approved: false,
    rejected: false,
    pending: false,
  });

  const onDatesChangeCallback = () =>
    setValidations(() => getValidations(getValues));
  const onChangeCallbackCheckboxes = () =>
    updateDisabledStateCheckboxes(getValues, setCheckboxesDisabled);

  const searchInputs = !approveReject
    ? schoolSearchInputs({
        schoolListSelectedRep,
        validations,
        onDatesChangeCallback,
      })
    : approveRejectSearchInputs(
        validations,
        onDatesChangeCallback,
        onChangeCallbackCheckboxes,
        checkboxesDisabled
      );

  const [isClearDisabled, setIsClearDisabled] = useState();
  const [isSearchDisabled, setIsSearchDisabled] = useState();

  const clearSearchInputs = () => {
    reset();
    updateDisabledStateCheckboxes(getValues, setCheckboxesDisabled);
    onDatesChangeCallback();
  };

  const toggleButtons = useCallback(() => {
    const allFields = getValues();

    let fieldsChanged = false;
    for (const field in allFields) {
      if (typeof allFields[field] !== 'string') {
        if (
          field.includes('Date') &&
          moment(allFields[field]).format(dateFormat).length
        ) {
          fieldsChanged = true;
        }
      } else if (field !== 'studentStatus' && allFields[field].trim().length) {
        fieldsChanged = true;
      }
    }

    let isSearchDisabled = !fieldsChanged;
    if (isSearchDisabled) {
      const isStudentStatusAll =
        !approveReject &&
        isAdmin &&
        schoolsWithStudents.schoolListSelectedRep ===
          appStrings.searchInputs.allReps &&
        allFields['studentStatus'] !== 'all';
      const noStatusSelected =
        approveReject &&
        !Object.values(allFields['studentApprovedStatus']).includes(true);

      isSearchDisabled =
        !getHasSearchData(allFields).hasSearchData &&
        (isStudentStatusAll || noStatusSelected);
    }

    setIsClearDisabled(!fieldsChanged);
    setIsSearchDisabled(isSearchDisabled);
  }, [
    getValues,
    approveReject,
    isAdmin,
    schoolsWithStudents.schoolListSelectedRep,
  ]);

  const buttons = [
    {
      btnType: buttonTypes.secondary,
      icon: <ClearIcon className={css.buttonIcon} />,
      label: appStrings.searchInputs.clearAll,
      onClick: clearSearchInputs,
      dataTestId: 'searchClearAllBtn',
      disabled: isClearDisabled,
    },
    {
      icon: <SearchIcon className={css.buttonIcon} />,
      label: appStrings.searchInputs.search,
      type: 'submit',
      dataTestId: 'searchSubmitBtn',
      disabled: isSearchDisabled,
    },
  ];

  const createButton = btn => {
    return (
      <CustomButton
        btnType={btn.btnType}
        color={btn.color}
        onClick={btn.onClick}
        label={btn.label}
        icon={btn.icon}
        disabled={btn.disabled}
        data-test-id={btn.dataTestId}
        id={btn.dataTestId}
        type={btn.type}
      />
    );
  };

  useEffect(() => {
    const getStatusValue = (fieldValue, fieldName) => {
      if (fieldName.includes('studentApprovedStatus')) {
        const names = fieldName.split('.');
        const resp = searchInputsValue[names[0]][names[1]];
        return resp === 'true' || resp === true;
      }
      return fieldValue?.length ? fieldValue : 'all';
    };

    searchInputs.forEach(field => {
      setValue(
        field.name,
        field.name !== 'studentStatus' &&
          !field.name.includes('studentApprovedStatus')
          ? searchInputsValue[field.name]
          : getStatusValue(searchInputsValue[field.name], field.name)
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputsValue, setValue]);

  useEffect(() => {
    toggleButtons();
  }, [toggleButtons, watchForm]);

  useEffect(() => {
    updateDisabledStateCheckboxes(getValues, setCheckboxesDisabled);
  }, [getValues]);

  useEffect(() => {
    if (isAdmin && !schoolsListSearch.length) {
      getAllSchools()
        .then(resp => dispatch(setSchoolsList(formatSchoolNamesOptions(resp))))
        .catch(() => dispatch(displayError()));
    }
  }, [dispatch, isAdmin, schoolsListSearch.length]);

  return (
    <form
      name="studentSearch"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
      data-test-id="searchStudentsForm"
    >
      <Grid
        container
        alignItems="stretch"
        className={classNames(
          css.formContainer,
          !approveReject ? css.studentSearchFormContainer : ''
        )}
        spacing={2}
      >
        {searchInputs.map(field => (
          <Grid
            item
            {...field.gridLayout}
            key={`${field.name}-container`}
            data-test-id={`${field.name}`}
          >
            {field.noInput ? (
              <p style={field.elemStyle ?? null}>{field.label}</p>
            ) : (
              <FormInput
                field={{
                  ...field,
                  onChangeCallback: values => {
                    if (field.onChangeCallback) {
                      field.onChangeCallback(values);
                    }
                  },
                }}
                control={control}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
                fullWidth
                InputProps={{
                  endAdornment: getValues([field.name]) ? (
                    <InputAdornment position="end">
                      <ClearIcon
                        id="clearInputValue"
                        data-test-id={`${field.name}-clearInputValueBtn`}
                        onClick={() => {
                          setValue(field.name, '', {
                            shouldValidate: true,
                          });
                          toggleButtons();
                        }}
                        className={classNames(css.clearInputIcon, {
                          [css.invalidClearIcon]: errors[field.name],
                        })}
                      />
                    </InputAdornment>
                  ) : null,
                }}
              />
            )}
          </Grid>
        ))}
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            className={css.buttonsContainer}
          >
            {buttons.map(btn => (
              <Grid item key={`search-${btn.label}`}>
                {createButton(btn)}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
StudentSearchForm.propTypes = propTypes;

export default StudentSearchForm;
