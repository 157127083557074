import React, { Fragment, useEffect, useState } from 'react';
import {
  ClickAwayListener,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { IconButton } from '@mui/material';
import { Search } from '@mui/icons-material';
import appStrings from '../../../core/strings/appStrings';
import { v4 as uuidv4 } from 'uuid';
import { trackGTMEvent } from '../Analytics/analyticsService';
import { PRODUCT } from '../../../core/navigation/paths';
import { useNavigate } from 'react-router-dom';
import { field } from './customSearchBarInput';
import useBrSearchBarStyles from './useBrSearchBarStyles';
import {
  bolder,
  getBloomreachSuggestions,
  getBrUID,
  getHighlightedText,
  parseBloomreachResult,
  sanitizeSearchTerm,
} from './brSearchBarService';
function BrSearchBar({ search }) {
  const css = useBrSearchBarStyles().classes;
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [open, setOpen] = useState(false);
  const [bloomreachResults, setBloomreachResults] = useState(null);

  useEffect(() => {
    bolder(document.getElementById('search-products'), searchValue);
  }, [searchValue, open]);
  const handleSearch = searchTerm => {
    if (searchTerm.length === 0) {
      setBloomreachResults(null);
      return;
    }
    const sanitizedSearchTerm = sanitizeSearchTerm(searchTerm);
    setSearchValue(sanitizedSearchTerm);
    if (sanitizedSearchTerm.length >= 3) {
      setOpen(true);
      if (sanitizedSearchTerm) {
        const brUID2 = getBrUID();
        const uuid = uuidv4().replaceAll('-', '');
        const requestId = uuid.substring(0, 13).toString();
        getBloomreachSuggestions(sanitizedSearchTerm, brUID2, requestId).then(
          bloomreachResult => {
            parseBloomreachResult(bloomreachResult, setBloomreachResults);
            if (sanitizedSearchTerm.trim().length > 0) {
              trackGTMEvent('submit', {
                page_type: 'search',
                search_term: sanitizedSearchTerm,
                orig_ref_url: document.location.href,
              });
            }
          }
        );
      }
    } else {
      setBloomreachResults(null);
    }
  };

  const hasProductsOrSuggestions = Boolean(
    bloomreachResults &&
      (bloomreachResults.products?.length ||
        bloomreachResults.suggestions?.length)
  );

  const handleSuggestionClick = (e, displayText) => {
    e.stopPropagation();
    handleSearch(displayText);
  };

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue]);

  const handleProductClick = item => {
    const { pid, title } = item;
    trackGTMEvent('click', {
      selected_term: title,
      search_term: searchValue,
      orig_ref_url: document.location.href,
    });
    navigate(PRODUCT(pid));
  };

  return (
    <Grid
      container
      alignContent="center"
      justifyContent="flex-end"
      data-test-id="SearchBar"
      className={css.field}
    >
      <ClickAwayListener onClickAway={() => setOpen(false)} touchEvent={false}>
        <TextField
          {...field}
          id="productSearchBar"
          variant="standard"
          size="small"
          value={searchValue}
          onClick={() => setOpen(true)}
          onChange={event => setSearchValue(event.currentTarget.value)}
          InputProps={{
            endAdornment: (
              <IconButton
                id="productSearchButton"
                data-test-id="SearchIcon"
                disableRipple
                onClick={e => {
                  e.stopPropagation();
                  search(searchValue);
                  setOpen(false);
                }}
              >
                <Search id="SearchIcon" />
              </IconButton>
            ),
            disableUnderline: true,
          }}
          inputProps={{ 'data-test-id': 'SearchBar-input' }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              search(searchValue);
              setOpen(false);
            }
          }}
        />
      </ClickAwayListener>
      {Boolean(searchValue.length > 0 && open && hasProductsOrSuggestions) && (
        <>
          <Divider />
          <Grid item className={css.list}>
            {Boolean(
              bloomreachResults.products && bloomreachResults.products.length
            ) && (
              <>
                <Typography>{appStrings.searchInputs.products}</Typography>
                <List id="search-products" data-test-id="products-list">
                  {bloomreachResults?.products?.map((item, index) => (
                    <ListItem
                      disablePadding
                      key={uuidv4()}
                      data-test-id={`product-${index}`}
                    >
                      <ListItemButton
                        disableRipple
                        onClick={() => handleProductClick(item)}
                      >
                        <ListItemText id={`product-${index}`}>
                          {getHighlightedText(
                            decodeURI(`${item.pid} - ${item.title}`),
                            searchValue
                          )}
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </>
            )}

            {Boolean(
              bloomreachResults.suggestions &&
                bloomreachResults.suggestions.length
            ) && (
              <>
                <Typography>{appStrings.searchInputs.suggestions}</Typography>
                <List id="search-suggestions" data-test-id="suggestions-list">
                  {bloomreachResults?.suggestions?.map((item, index) => (
                    <ListItem
                      disablePadding
                      key={uuidv4()}
                      data-test-id={`suggestion-${index}`}
                    >
                      <ListItemButton
                        onClick={e =>
                          handleSuggestionClick(e, item.displayText)
                        }
                      >
                        <ListItemText id={`suggestion-${index}`}>
                          {getHighlightedText(
                            decodeURI(item.displayText),
                            searchValue
                          )}
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default BrSearchBar;
