import {
  closeSignalRConnection,
  startSignalRConnection,
} from '../../../core/services/signalR/signalRService';
import {
  connectionResponseState,
  paymentMethod,
  paymentMethodLabel,
  paymentType,
} from '../../../core/strings/appConstants';
import {
  displayLoading,
  hideLoading,
} from '../../../core/redux/slices/commonSlice';

import ApiService from '../../shared/Api/apiService';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CustomIcon from '../../shared/CustomIcon/CustomIcon';
import FormInput from '../../shared/FormInput/FormInput';
import { Grid } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import appStrings from '../../../core/strings/appStrings';
import classNames from 'classnames';
import { displayError } from '../../../core/redux/slices/notificationsSlice';
import { icons } from '../../../core/strings/icons';
import { orderAndPaymentStatus } from '../../shared/PaymentDialog/paymentDialogConstants';
import { shippingAndBillingRequiredFields } from '../ShippingAndBilling/shippingAndBillingConstants';
import store from '../../../core/redux/store';
import { trackGAEvent } from '../../shared/Analytics/analyticsService';
import urls from '../../../core/strings/urls';
import { getDisplayErrors } from '../../../core/services/utilsService';

const gridLayout = { xs: 12 };
const paymentOptionsInfo = ({
  css,
  getValues,
  setTotal,
  paymentMethodIds,
}) => ({
  creditCard: {
    id: paymentMethod.card,
    icon: <CreditCardIcon />,
    title: appStrings.order.creditDebitCard,
    fields: [
      {
        name: `${paymentMethod.card}.amount`,
        label: appStrings.common.amount,
        type: 'currency',
        onChangeCallback: () => updateTotal(getValues, setTotal),
        validations: {
          validate: {
            minValue: value => minValue(value),
          },
        },
        gridLayout,
      },
    ],
  },
  voucher: {
    id: paymentMethod.voucher,
    icon: <LocalOfferIcon />,
    title: appStrings.order.voucher,
    fields: [
      {
        name: `${paymentMethod.voucher}.paymentDetails`,
        label: appStrings.order.voucherNumber,
        type: 'text',
        validations: {
          maxLength: 11,
          required: paymentMethodIds.includes(1),
        },
        gridLayout,
      },
      {
        name: `${paymentMethod.voucher}.amount`,
        label: appStrings.order.voucherAmount,
        type: 'currency',
        onChangeCallback: () => updateTotal(getValues, setTotal),
        validations: {
          validate: {
            minValue: value => minValue(value),
          },
        },
        gridLayout,
      },
    ],
  },
  otherPayments: {
    id: paymentMethod.otherPayments,
    icon: (
      <CustomIcon
        icon={icons.otherPayments}
        customClasses={css.otherPaymentsIcon}
      />
    ),
    title: appStrings.order.otherPayments,
    containerClass: css.otherFieldsContainer,
    fields: [
      {
        name: `${paymentMethod.otherPayments}.paymentType`,
        label: appStrings.order.paymentType,
        type: 'select',
        options: [
          { label: appStrings.order.giftCard, value: paymentType.giftCard },
          { label: appStrings.order.check, value: paymentType.check },
          { label: appStrings.order.moneyOrder, value: paymentType.moneyOrder },
        ],
        validations: {
          required: paymentMethodIds.includes(2),
        },
        gridLayout,
      },
      {
        name: `${paymentMethod.otherPayments}.paymentDetails`,
        label: appStrings.order.paymentNumber,
        type: 'text',
        validations: {
          maxLength: 30,
          required: paymentMethodIds.includes(2),
        },
        gridLayout,
      },
      {
        name: `${paymentMethod.otherPayments}.amount`,
        label: appStrings.order.paymentAmount,
        type: 'currency',
        onChangeCallback: () => updateTotal(getValues, setTotal),
        validations: {
          validate: {
            minValue: value => minValue(value),
          },
        },
        gridLayout,
      },
    ],
  },
});

const minValue = value => {
  return (
    !value || Number(value) >= 0.01 || appStrings.validationMessages.minAmount
  );
};

export const updateTotal = (getValues, setTotal) => {
  const values = getValues();
  let total = 0;
  if (values) {
    Object.values(values).forEach(value => {
      if (!isNaN(value?.amount)) {
        total = total + Number(value.amount);
      }
    });
  }
  setTotal(Number(total.toFixed(2)));
};

export const paymentOptions = ({
  control,
  setValue,
  getValues,
  errors,
  css,
  setTotal,
  paymentMethodIds,
}) => {
  const options = paymentOptionsInfo({
    css,
    getValues,
    setTotal,
    paymentMethodIds,
  });
  return Object.keys(options).map(key => ({
    id: options[key].id,
    icon: options[key].icon,
    title: options[key].title,
    content: (
      <Grid
        container
        className={classNames(
          css.fieldsContainer,
          options[key].containerClass ?? ''
        )}
      >
        {options[key].fields.map(field => (
          <Grid item key={field.name} {...field.gridLayout}>
            <FormInput
              field={field}
              control={control}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              inputProps={{
                className: css.contentInput,
              }}
            />
          </Grid>
        ))}
      </Grid>
    ),
  }));
};

export const savePayment = (studentId, data, isECOrder, downPayment) => {
  let payments = generatePaymentInfo(data);

  if (isECOrder && downPayment === 0) {
    payments.push({
      amount: 0,
      type: paymentMethod.ecOrder,
      paymentType: 0,
      paymentDetails: null,
    });
  }

  const payload = { studentId, payments };
  return ApiService.put(urls.savePayments, payload);
};

export const generatePaymentInfo = values => {
  let payments = [];
  for (const [key, value] of Object.entries(values)) {
    if (
      value.amount ||
      value.paymentDetails?.trim().length > 0 ||
      value.paymentType
    ) {
      payments.push({
        amount:
          value.amount === null || value.amount.length < 1
            ? null
            : value.amount,
        type: Number(key),
        paymentType: Number.isInteger(value.paymentType)
          ? value.paymentType
          : 0,
        paymentDetails:
          value.paymentDetails?.trim().length > 0 ? value.paymentDetails : null,
      });
    }
  }
  return payments;
};

export const isCorrectAmount = ({
  totalsInfo,
  total,
  correctAmount,
  setSectionErrors,
  isECOrder,
}) => {
  const expectedAmount = isECOrder
    ? totalsInfo.downPayment
    : totalsInfo.grandTotal;
  if (
    (expectedAmount || expectedAmount === 0) &&
    !totalsInfo.taxErrorMessage &&
    expectedAmount === total
  ) {
    correctAmount.current = true;
    setSectionErrors(prev => ({ ...prev, payment: false }));
  } else {
    correctAmount.current = false;
    setSectionErrors(prev => ({ ...prev, payment: true }));
  }
};

export const getTotalClassName = ({ correctAmount, css }) => {
  return correctAmount.current ? css.greenText : css.redText;
};

export const updatePaymentMethods = ({
  methodId,
  paymentMethodIds,
  setPaymentMethodIds,
  setValue,
  getValues,
  setTotal,
}) => {
  const methodIndex = paymentMethodIds.indexOf(methodId);
  if (methodIndex === -1) {
    setPaymentMethodIds(prev => [...prev, methodId]);
  } else {
    const newMethods = [...paymentMethodIds];
    newMethods.splice(methodIndex, 1);
    clearSection(methodId, getValues, setValue);
    setPaymentMethodIds(newMethods);
    setTimeout(() => {
      updateTotal(getValues, setTotal);
    });
  }
};

const clearSection = (sectionId, getValues, setValue) => {
  const paymentValues = getValues();
  Object.keys(paymentValues[sectionId]).forEach(key => {
    setValue(`${sectionId}.${key}`, '');
  });
};

export const checkCompleteOrder = ({
  setCompleteOrderClicked,
  sectionErrors,
  dispatch,
  orderInfo,
  paymentMethodIds,
  setCompletingOrder = () => {},
  isECOrder,
  selectedPromotion,
  handleN90Errors,
}) => {
  const continueComplete = () => {
    store.dispatch(displayLoading(appStrings.order.validatingOrder));
    startSignalRConnection(
      store.getState().login.userDetails.accountIdentifier
    ).then(() => {
      saveOrder(orderInfo.studentId).catch(() => {
        closeSignalRConnection();
        store.dispatch(hideLoading());
      });
      setCompletingOrder(false);
      paymentMethodIds?.forEach(id => {
        trackGAEvent(
          `Complete Order using ${paymentMethodLabel[id]}`,
          'Order and Checkout',
          orderInfo.studentId
        );
      });
    })
    .catch(() => {
      store.dispatch(hideLoading());
    });
  };

  setCompleteOrderClicked(true);
  setCompletingOrder(true);
  const hasErrors = handleErrors({
    sectionErrors,
    dispatch,
    orderInfo,
  });
  if (!hasErrors) {
    if (isECOrder) {
      store.dispatch(displayLoading(appStrings.order.validatingOrder));
      getN90Errors(orderInfo.studentId, selectedPromotion)
        .then(resp => {
          if (
            resp?.ecValidation?.length > 0 ||
            resp?.profileValidation?.length > 0
          ) {
            handleN90Errors(resp, continueComplete);
            setCompletingOrder(false);
            store.dispatch(hideLoading());
          } else {
            continueComplete();
          }
        })
        .catch(e => {
          setCompletingOrder(false);
          store.dispatch(hideLoading());
          store.dispatch(displayError({ message: getDisplayErrors(e) }));
        });
    } else {
      continueComplete();
    }
  } else {
    setCompletingOrder(false);
  }
};

const saveOrder = studentId => {
  return ApiService.post(urls.saveOrder, {
    studentId,
    userId: store.getState().login.userDetails.accountIdentifier,
    callbackMethodName: 'checkedSaveOrder',
  });
};

export const orderError = ({
  setCompleteOrderClicked,
  sectionErrors,
  dispatch,
  orderInfo,
}) => {
  setCompleteOrderClicked(true);
  handleErrors({
    sectionErrors,
    dispatch,
    orderInfo,
  });
};

const handleErrors = ({ sectionErrors, dispatch, orderInfo }) => {
  const { shippingAddress, billingAddress } = orderInfo;

  const shippingAndBillingErrors = handleShippingAndBillingErrors({
    shippingAddress,
    billingAddress,
    dispatch,
    sectionErrors,
  });

  const lineItemsErrors = handleLineItemsErrors({
    dispatch,
    sectionErrors,
  });

  const ecContractErrors = handleECContractErrors({
    dispatch,
    sectionErrors,
  });

  const orderTypeErrors = handleOrderTypeErrors({ dispatch });

  return (
    orderTypeErrors ||
    shippingAndBillingErrors ||
    lineItemsErrors ||
    ecContractErrors ||
    sectionErrors.payment
  );
};

const handleShippingAndBillingErrors = ({
  shippingAddress,
  billingAddress,
  dispatch,
  sectionErrors,
}) => {
  let hasErrors = false;
  if (sectionErrors['shippingAndBilling']) {
    hasErrors = true;
  }
  if (!hasErrors && shippingAddress) {
    hasErrors = checkRequiredFields(shippingAddress);
  }
  if (!hasErrors && billingAddress) {
    hasErrors = checkRequiredFields(billingAddress);
  }
  if (hasErrors) {
    dispatch(
      displayError({ message: appStrings.validationMessages.mandatoryFields })
    );
  }
  return hasErrors;
};

const handleECContractErrors = ({ dispatch, sectionErrors }) => {
  let hasErrors = sectionErrors.ecContract;
  if (hasErrors) {
    dispatch(
      displayError({ message: appStrings.validationMessages.ecContractErrors })
    );
  }
  return hasErrors;
};

const handleOrderTypeErrors = ({ dispatch }) => {
  const { isECOrder, selectedAccount, selectedPromotion } =
    store.getState().cart;
  const hasErrors = isECOrder && (!selectedAccount || !selectedPromotion);
  if (hasErrors) {
    dispatch(
      displayError({ message: appStrings.validationMessages.orderTypeErrors })
    );
  }
  return hasErrors;
};

const checkRequiredFields = address => {
  let missingRequiredFields = false;
  if (Object.keys(address).length < 1) {
    return true;
  }
  Object.keys(address).forEach(key => {
    if (
      address[shippingAndBillingRequiredFields[key]] === null ||
      address[shippingAndBillingRequiredFields[key]] === ''
    ) {
      missingRequiredFields = true;
    }
  });
  return missingRequiredFields;
};

const handleLineItemsErrors = ({ dispatch, sectionErrors }) => {
  let hasErrors = sectionErrors.lineItems;
  if (hasErrors) {
    dispatch(
      displayError({ message: appStrings.validationMessages.lineItemsErrors })
    );
  }
  return hasErrors;
};

export const cannotCompleteOrder = ({
  correctAmount,
  completeOrderClicked,
}) => {
  return !correctAmount.current && completeOrderClicked;
};

export const checkSaveAndCompleteOrder = ({
  isDirty = false,
  handleSubmit = () => {},
  savePaymentAndCompleteOrder = () => {},
  setCompleteOrderClicked,
  sectionErrors,
  dispatch,
  orderInfo,
  isECOrder,
  selectedPromotion,
  handleN90Errors,
}) => {
  if (isDirty) {
    handleSubmit(savePaymentAndCompleteOrder)();
  } else {
    checkCompleteOrder({
      setCompleteOrderClicked,
      sectionErrors,
      dispatch,
      orderInfo,
      paymentMethodIds: [],
      setCompletingOrder: () => {},
      isECOrder,
      selectedPromotion,
      handleN90Errors,
    });
  }
};

export const saveOrderCallback = ({
  saveOrderStatus,
  getValues,
  setShowPaymentDialog,
  setShowDirectDebitDialog,
  setOrderStatus,
  studentId,
  isECOrder,
}) => {
  if (saveOrderStatus?.state === connectionResponseState.done) {
    if (getValues(`${paymentMethod.card}.amount`) > 0) {
      store.dispatch(hideLoading());
      setShowPaymentDialog(true);
    } else {
      completeOrder(studentId)
        .then(() => {
          if (isECOrder) {
            setShowDirectDebitDialog(true);
          } else {
            setOrderStatus({
              completed: true,
              orderNumber: saveOrderStatus.orderNumber,
            });
          }
        })
        .catch(() => {
          store.dispatch(displayError());
        })
        .finally(() => {
          store.dispatch(hideLoading());
        });
    }
  }
};

export const completeOrder = (studentId, chaseResp, uid) => {
  const saveOrderStatus = store.getState().cart.saveOrderStatus;
  return ApiService.post(urls.completeOrder, {
    studentId,
    orderNumber: saveOrderStatus.orderNumber,
    uid: chaseResp?.uID || uid || null,
    status:
      !chaseResp || chaseResp.code
        ? orderAndPaymentStatus.completed
        : orderAndPaymentStatus.rejected,
    errorCode: chaseResp?.code ? null : chaseResp,
  });
};

export const cancelCompleteOrder = studentId => {
  const saveOrderStatus = store.getState().cart.saveOrderStatus;
  return ApiService.post(urls.cancelOrder, {
    studentId,
    orderNumber: saveOrderStatus.orderNumber,
  });
};

const getN90Errors = (studentId, promoId) => {
  return ApiService.get(
    urls.validateECCredit(studentId, promoId, new Date().getTimezoneOffset())
  );
};

export const checkOtherPaymentData = ({
  setPaymentNumberEmpty,
  setPaymentTypeEmpty,
  setVoucherNumberEmpty,
  paymentMethodIds,
  errors,
}) => {
  setPaymentNumberEmpty(
    Boolean(paymentMethodIds.includes(2) && errors[2]?.paymentDetails)
  );
  setPaymentTypeEmpty(
    Boolean(paymentMethodIds.includes(2) && errors[2]?.paymentType)
  );
  setVoucherNumberEmpty(
    Boolean(paymentMethodIds.includes(1) && errors[1]?.paymentDetails)
  );
};

export const clearPaymentSections = formValues => {
  Object.keys(formValues).forEach(paymentKey => {
    const paymentSection = formValues[paymentKey];
    Object.keys(paymentSection).forEach(key => {
      paymentSection[key] = '';
    });
  });
};
