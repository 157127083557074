import { makeStyles } from 'tss-react/mui';

const full = '100% !important';

const useImageCarouselStyles = makeStyles()(theme => ({
  imageCarouselContainer: {
    width: '646px',
    height: '402px',
    margin: 'auto',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',

    '@media screen and (min-width: 768px)': {
      '& .slick-track': {
        height: '330px',
      },

      '& .slick-list': {
        height: full,
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto'
      },

      '& .slick-slider': {
        maxWidth: '90% !important'
      },

      '& .slick-dots': {
        width: '86%',
        left: '35px',
        transform: 'translate3d(0px, 23px, 0px)',
      }
    },

    '@media screen and (max-width: 768px)': {
      height: 'unset',
      maxHeight: '402px !important',
      '& .slick-slider': {
        width: '60%',
      },

      '& img': {
        maxHeight: '296px !important',
      },

      '& .slick-dots': {
        width: '74%',
        transform: 'translateX(36px)',
      },
    },

    '& button': {
      width: 'unset',
    },

    '& img': {
      maxHeight: '330px',
      width: 'auto',
      height: 'auto',
      maxWidth: '100%',
    },

    '& .slick-slide img': {
      margin: 'auto',
    },

    '& .slick-initialized .slick-slide': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },

    '& .slick-next:before, .slick-prev:before': {
      display: 'none'
    },

    '& .slick-dots button': {
      backgroundColor: '#A1A1A180',
      color: 'transparent',
      width: '24px',
      borderRadius: '20px',
      height: '7px'
    },

    '& .slick-dots li button': {
      padding: '0'
    },

    '& .slick-dots li button:before': {
      color: 'transparent !important',
      padding: '0'
    },

    '& .slick-dots li.slick-active button:before': {
      color: 'transparent',
      width: '24px',
      backgroundColor: '#A1A1A180',
      borderRadius: '20px',
      height: '7px',
    },

  },

  arrowButton: {
    color: theme.palette.common.red,
    '& svg': {
      fontSize: '32px'
    },
  },

  imageDialog: {
    '@media screen and (max-width: 768px)': {
      height: '100%',
      margin: 0,
      padding: 0,
      width: '100%',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '100%',
      overflowX: 'hidden',
      overflowY: 'hidden',
      position: 'absolute',
      top: 0,
      justifyContent: 'center',

      '& #productImageZoomedModal-content': {
        flex: 'unset',
        overflowY: 'unset',
        padding: 0,
        marginBottom: 0,
        height: '90%',
        display: 'flex',
        flexDirection: 'column', 
        justifyContent: 'center',
      },

      '& .product-image-container':{
        height: 'unset !important',
      },
    },
    '@media screen and (min-width: 768px)': {
      height: '650px',
      minWidth: theme.spacing(120),

      '& #productImageZoomedModal-content': {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      },

      '& .product-image-container':{
        height: '100%',
        alignItems: 'center',
      },
    },
    '@media screen and (max-width: 960px)': {
      minWidth: '50vw',
      height: '100%',
      '& .product-image-container':{
        height: '100%',
      },
    },
    padding: 0,
    backgroundColor: theme.palette.common.white,

    '& .product-image': {
      maxHeight: '100%',
      maxWidth: '95%',
      
      '@media screen and (max-width: 960px)': {
        maxWidth: '60%'
      },
      '@media screen and (max-width: 768px)': {
        maxWidth: '100%'
      },
    },
  },

  carouselZoomedContainer: {
    '@media screen and (min-width: 769px)': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '98%',
      marginLeft: 'auto',
      marginRight: 'auto',

      '& img': {
        maxHeight: '565px',

        '@media screen and (max-width: 960px)': {
          maxHeight: '330px',
        },
      },

      '& .slick-list': {
        height: full,
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
      },

      '& .slick-track': {
        height: full,
      },

      '& .slick-slider.slick-initialized': {
        height: '83% !important',
        marginBottom: '20px !important',
        maxWidth: full,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
      },

      '& .slick-next': {
        right: '0 !important',
        marginRight: '20px',
      },

      '& .slick-prev': {
        left: '0 !important'
      },

      '& .slick-dots': {
        width: full,
        left: '0',
      },
    },

    '@media screen and (max-width: 768px)': {
      height: '100%',
      width: 'fit-content',
      '& .slick-slider.slick-initialized': {
        width: '100vw !important',
        height: '100% !important'
      },

      '& .slick-list, .slick-track': {
        height: '100%',
      },

      '& .slick-dots': {
        width: full,
        transform: 'none !important',
      },

      '& .slick-list': {
        height: full,
      },
    },
  },

  productImage: {
    '& img': {
      maxHeight: '330px',
      height: 'auto',
      maxWidth: '60%',
      '@media screen and (max-width: 768px)': {
        maxWidth: '100%',
      }
    }
  },
}));

export default useImageCarouselStyles;