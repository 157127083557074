import { Checkbox, Tooltip, Typography } from '@mui/material';
import {
  cancelCompleteOrder,
  cannotCompleteOrder,
  checkCompleteOrder,
  checkOtherPaymentData,
  checkSaveAndCompleteOrder,
  clearPaymentSections,
  completeOrder,
  getTotalClassName,
  isCorrectAmount,
  orderError,
  paymentOptions,
  saveOrderCallback,
  savePayment,
  updatePaymentMethods,
  updateTotal,
} from './orderPaymentService';
import {
  displayLoading,
  hideLoading,
} from '../../../core/redux/slices/commonSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useRef, useState } from 'react';

import CustomBox from '../../shared/CustomBox/CustomBox';
import CustomButton from '../../shared/CustomButton/CustomButton';
import OrderLeaveDialog from '../../shared/OrderLeaveDialog/OrderLeaveDialog';
import PaymentDialog from '../../shared/PaymentDialog/PaymentDialog';
import appStrings from '../../../core/strings/appStrings';
import classNames from 'classnames';
import { displayError } from '../../../core/redux/slices/notificationsSlice';
import { formatNumberWithCurrency } from '../../../core/services/utilsService';
import { getDownPayment, getGrandTotal } from '../Totals/totalsService';
import setMultipleValues from '../../../core/services/formService';
import { useForm } from 'react-hook-form';
import useOrderPaymentStyles from './useOrderPaymentStyles';
import DirectDebit from '../DirectDebit/DirectDebit';
import CartContext from '../Cart/context/CartContext';
import { setN90Errors } from '../../../core/redux/slices/cartSlice';
import { getPaymentIdentifier } from '../../shared/PaymentDialog/paymentDialogService';
import ErrorDialog from '../ErrorDialog/ErrorDialog';
import MandatoryFieldsMessage from '../../shared/MandatoryFieldsMessage/MandatoryFieldsMessage';
import { paymentMethod } from '../../../core/strings/appConstants';

function OrderPayment({
  shouldSave,
  setShouldSave,
  isExpanded,
  sectionErrors,
  setSectionErrors,
  completeOrderClicked,
  setCompleteOrderClicked,
  setOrderStatus,
  studentId,
}) {
  const css = useOrderPaymentStyles().classes;
  const [paymentMethodIds, setPaymentMethodIds] = useState([]);
  const {
    control,
    setValue,
    getValues,
    reset,
    handleSubmit,
    formState,
    trigger,
  } = useForm();
  const { isDirty, errors } = formState;
  const dispatch = useDispatch();
  const {
    orderInfo,
    isECOrder,
    hasNoAvailableCredit,
    downPaymentLoading,
    selectedPromotion,
  } = useSelector(state => state.cart);
  const [paymentRetrieved, setPaymentRetrieved] = useState(false);
  const [total, setTotal] = useState(0);
  const totalsInfo = useSelector(state => state.cart.totals);
  const correctAmount = useRef(false);
  const saveOrderStatus = useSelector(state => state.cart.saveOrderStatus);
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [showDirectDebitDialog, setShowDirectDebitDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const { completingOrder, setCompletingOrder, resetPaymentData, setResetPaymentData } = useContext(CartContext);
  const [paymentNumberEmpty, setPaymentNumberEmpty] = useState(false);
  const [paymentTypeEmpty, setPaymentTypeEmpty] = useState(false);
  const [voucherNumberEmpty, setVoucherNumberEmpty] = useState(false);
  const [validationCallback, setValidationCallback] = useState();
  

  const savePaymentAndCompleteOrder = data => {
    setCompletingOrder(true);
    savePayment(orderInfo.studentId, data, isECOrder, totalsInfo.downPayment)
      .then(() => {
        reset(getValues());
        checkCompleteOrder({
          setCompleteOrderClicked,
          sectionErrors,
          dispatch,
          orderInfo,
          paymentMethodIds,
          setCompletingOrder,
          isECOrder,
          selectedPromotion,
          handleN90Errors,
        });
      })
      .catch(() => dispatch(displayError()));
  };

  const handleN90Errors = (errors, callback) => {
    dispatch(setN90Errors(errors));
    setValidationCallback(() => () => callback());
    setShowErrorDialog(true);
  };

  const onCompleteOrder = () => {
    checkSaveAndCompleteOrder({
      isDirty,
      handleSubmit,
      savePaymentAndCompleteOrder,
      setCompleteOrderClicked,
      sectionErrors,
      dispatch,
      orderInfo,
      isECOrder,
      selectedPromotion,
      handleN90Errors,
    });
    checkOtherPaymentData({
      setPaymentNumberEmpty,
      setPaymentTypeEmpty,
      setVoucherNumberEmpty,
      paymentMethodIds,
      errors,
    });
  };

  const onOrderError = () => {
    setCompletingOrder(false);
    orderError({
      setCompleteOrderClicked,
      sectionErrors,
      dispatch,
      orderInfo,
    });
    checkOtherPaymentData({
      setPaymentNumberEmpty,
      setPaymentTypeEmpty,
      setVoucherNumberEmpty,
      paymentMethodIds,
      errors,
    });
  };

  const handleUpdatePaymentMethods = methodId => {
    updatePaymentMethods({
      methodId,
      paymentMethodIds,
      setPaymentMethodIds,
      setValue,
      getValues,
      setTotal,
    });
  };

  const onPaymentComplete = chaseResp => {
    setShowPaymentDialog(false);
    dispatch(displayLoading());
    completeOrder(orderInfo.studentId, chaseResp)
      .then(() => {
        if (isECOrder) {
          setShowDirectDebitDialog(true);
        } else {
          setOrderStatus({
            completed: true,
            orderNumber: saveOrderStatus.orderNumber,
          });
        }
      })
      .catch(() => dispatch(displayError()))
      .finally(() => dispatch(hideLoading()));
  };

  const onPaymentError = chaseErrors => {
    getPaymentIdentifier().then(uid => {
      completeOrder(orderInfo.studentId, chaseErrors, uid).catch(() =>
        dispatch(displayError())
      );
    });
  };

  const onPaymentCancel = () => {
    setCompletingOrder(false);
    setShowPaymentDialog(false);
    dispatch(displayLoading());
    cancelCompleteOrder(orderInfo.studentId)
      .catch(() => dispatch(displayError()))
      .finally(() => dispatch(hideLoading()));
  };

  const handleDirectDebitClose = () => {
    setShowDirectDebitDialog(false);
    setOrderStatus({
      completed: true,
      orderNumber: saveOrderStatus.orderNumber,
    });
  };

  const PaymentTotal = () => {
    return isECOrder ? getDownPayment(totalsInfo) : getGrandTotal(totalsInfo);
  };

  const isCompleteOrderDisabled = () =>
    completingOrder ||
    (isECOrder && (downPaymentLoading || hasNoAvailableCredit));

  useEffect(() => {
    isCorrectAmount({
      totalsInfo,
      total,
      correctAmount,
      setSectionErrors,
      isECOrder,
    });
  }, [isECOrder, setSectionErrors, total, totalsInfo]);

  useEffect(() => {
    if (!paymentRetrieved && paymentMethodIds.length) {
      orderInfo.payments.forEach(payment => {
        setMultipleValues({ setValue, values: { [payment.type]: payment } });
      });
      setPaymentRetrieved(true);
      reset(getValues());
      setTimeout(() => {
        updateTotal(getValues, setTotal);
      });
    }
  }, [
    setValue,
    orderInfo.payments,
    paymentRetrieved,
    paymentMethodIds,
    getValues,
    reset,
  ]);

  useEffect(() => {
    const paymentMethods = orderInfo.payments?.map(payment => {
      return payment.type;
    });
    setPaymentMethodIds(paymentMethods);
  }, [setValue, orderInfo.payments]);

  useEffect(() => {
    const onSubmit = data => {
      savePayment(orderInfo.studentId, data, isECOrder, totalsInfo.downPayment)
        .then(() => {
          setShouldSave(false);
          reset(getValues());
        })
        .catch(() => dispatch(displayError()));
    };

    const onError = () => {
      setShouldSave(false);
    };

    if (shouldSave && isDirty) {
      handleSubmit(onSubmit, onError)();
    } else {
      setShouldSave(false);
    }
  }, [
    dispatch,
    getValues,
    handleSubmit,
    isDirty,
    isECOrder,
    orderInfo.studentId,
    reset,
    setShouldSave,
    shouldSave,
    totalsInfo.downPayment,
  ]);

  useEffect(() => {
    // save order completed
    saveOrderCallback({
      saveOrderStatus,
      getValues,
      setShowPaymentDialog,
      setShowDirectDebitDialog,
      setOrderStatus,
      studentId: orderInfo.studentId,
      isECOrder,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveOrderStatus]);

  useEffect(() => {
    if (resetPaymentData) {
      const values = getValues();
      clearPaymentSections(values);
      reset(values);
      checkOtherPaymentData({
        setPaymentNumberEmpty,
        setPaymentTypeEmpty,
        setVoucherNumberEmpty,
        paymentMethodIds,
        errors,
      });
      setResetPaymentData(false);
    }
  }, [errors, getValues, paymentMethodIds, reset, resetPaymentData, setResetPaymentData]);

  return (
    <>
      <form className={css.paymentContainer}>
        <Typography
          variant="h2"
          className={css.paymentTitle}
          id="selectPaymentMethodLabel"
        >
          {appStrings.order.selectPaymentMethod}:
        </Typography>
        {paymentOptions({
          control,
          setValue,
          getValues,
          errors,
          css,
          setTotal,
          paymentMethodIds,
        }).map(option => {
          const methodSelected = paymentMethodIds.indexOf(option.id) !== -1;
          return (
            <CustomBox
              key={option.title}
              customClass={css.sectionContainer}
              id={option.id + 'Container'}
            >
              <div
                className={classNames(css.sectionHeader, {
                  [css.headerExpanded]: methodSelected,
                })}
              >
                <div
                  className={css.headerLeftContainer}
                  id={option.id + 'Label'}
                >
                  <span className={css.methodIcon}>{option.icon}</span>
                  <Typography variant="h2" className={css.sectionTitle}>
                    {methodSelected ? (
                      <strong>{option.title}</strong>
                    ) : (
                      option.title
                    )}
                  </Typography>
                </div>
                <Checkbox
                  className={css.checkbox}
                  checked={methodSelected}
                  onClick={() => handleUpdatePaymentMethods(option.id)}
                  id={option.id + 'Checkbox'}
                />
              </div>
              <div
                className={classNames(css.contentContainer, {
                  [css.hidden]: !methodSelected,
                })}
              >
                {option.content}
              </div>
              {
                (option.id === paymentMethod.voucher || option.id === paymentMethod.otherPayments) &&
                methodSelected &&
                <MandatoryFieldsMessage />
              }
            </CustomBox>
          );
        })}
        <Typography id="paymentTotalAmountEntered">
          {appStrings.order.totalAmountEntered}{' '}
          <span className={getTotalClassName({ correctAmount, css })}>
            <strong>{formatNumberWithCurrency(total)}</strong>
          </span>
        </Typography>
        <Typography id="paymentOrderStatusMessages">
          <strong>
            {correctAmount.current
              ? appStrings.order.canCompleteOrder
              : appStrings.order.cannotCompleteOrder}
          </strong>
        </Typography>
        <div className={css.completeOrderContainer}>
          <div id="paymentGrandTotalAmount" className={css.paymentTotal}>
            <PaymentTotal />
          </div>
          <Tooltip
            placement="left"
            arrow
            title={
              isECOrder && hasNoAvailableCredit
                ? appStrings.order.noAvailableCredit
                : ''
            }
            enterTouchDelay={0}
          >
            <div>
              <CustomButton
                disabled={isCompleteOrderDisabled()}
                label={appStrings.order.completeOrder}
                id="completeOrderBtn"
                onClick={handleSubmit(onCompleteOrder, onOrderError)}
              ></CustomButton>
            </div>
          </Tooltip>
        </div>
        {cannotCompleteOrder({
          correctAmount,
          completeOrderClicked,
        }) && (
          <span className={css.redText}>
            <strong>
              {appStrings.validationMessages.paymentAmountIncorrect(isECOrder)}
            </strong>
          </span>
        )}
        <br />
        {paymentNumberEmpty && (
          <div className={css.redText}>
            <strong>
              {appStrings.validationMessages.paymentNumberRequired}
            </strong>
          </div>
        )}
        {paymentTypeEmpty && (
          <div className={css.redText}>
            <strong>{appStrings.validationMessages.paymentTypeRequired}</strong>
          </div>
        )}
        {voucherNumberEmpty && (
          <div className={css.redText}>
            <strong>{appStrings.validationMessages.voucherNumberRequired}</strong>
          </div>
        )}
      </form>
      {isExpanded && <OrderLeaveDialog isDirty={isDirty} trigger={trigger} />}
      {showPaymentDialog && (
        <PaymentDialog
          onCancel={onPaymentCancel}
          onPaymentComplete={onPaymentComplete}
          onError={onPaymentError}
        />
      )}
      {showDirectDebitDialog && (
        <DirectDebit
          orderId={saveOrderStatus.orderNumber}
          handleClose={handleDirectDebitClose}
        />
      )}
      {showErrorDialog && (
        <ErrorDialog
          callback={validationCallback}
          handleClose={() => setShowErrorDialog(false)}
        />
      )}
    </>
  );
}

export default OrderPayment;
