import { Grid } from '@mui/material';
import React from 'react';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import appStrings from '../../../../core/strings/appStrings';
import useProductPageStyles from '../productPageStyles';
import CustomRotateLoading from '../../../shared/CustomRotateLoading/CustomRotateLoading';

function AddSaveButtonsLoading() {
  const css = useProductPageStyles().classes;

  return (
    <Grid
      item
      container
      rowGap={2}
      className={css.addSaveButtonsLoading}
      id="ProductPageAddSaveButtons-loading"
    >
      <Grid item xs={12}>
        <CustomButton
          data-test-id="addToCartButtonLoading"
          id="productPage-addToCartLoading"
          size="large"
          label={
            <CustomRotateLoading
              id="productPage-addToCartLoadingIcon"
              dataTestId="productPage-addToCartLoadingIconTestId"
              customClass={css.loadingIcon}
            />
          }
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <CustomButton
          data-test-id="savedItemButtonLoading"
          id="productPage-saveItemLoading"
          size="large"
          label={appStrings.cart.addToSavedItems}
          disabled
        />
      </Grid>
    </Grid>
  );
}

export default AddSaveButtonsLoading;
