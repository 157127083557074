import { Divider, Grid, Typography } from '@mui/material';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  resetSchoolFilters,
  resetSelectedStudent,
  setSchoolListRep,
  updateSchoolList,
} from '../../../core/redux/slices/schoolsWithStudentsSlice';
import { useDispatch, useSelector } from 'react-redux';

import CustomAccordion from '../../shared/CustomAccordion/CustomAccordion';
import CustomAccordionContainer from '../../shared/CustomAccordionContainer/CustomAccordionContainer';
import CustomBox from '../../shared/CustomBox/CustomBox';
import SchoolsListSkeleton from './SchoolsListSkeleton';
import StudentSearch from '../StudentSearch/StudentSearch';
import StudentsList from './StudentsList/StudentsList';
import appStrings from '../../../core/strings/appStrings';
import { displayError } from '../../../core/redux/slices/notificationsSlice';
import { getSchools, getSchoolsLength } from './Services/studentListService';
import { useParams } from 'react-router-dom';
import useSchoolListStyles from './useSchoolListStyles';
import { updateNewStatusStudents } from '../../../core/redux/slices/approveRejectSlice';
import { ADMIN } from '../../../core/navigation/roles';
import RepSearch from '../RepSearch/RepSearch';
import CustomPagination from '../../shared/CustomPagination/CustomPagination';
import { schoolsShown } from './schoolsListConstants';

function SchoolsList() {
  const css = useSchoolListStyles().classes;
  const dispatch = useDispatch();
  const { employeeId } = useParams();
  const { userDetails } = useSelector(state => state.login);
  const isAdmin = userDetails.role === ADMIN;
  const [expandedSchool, setExpandedSchool] = useState(null);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const schoolsWithStudentsState = useSelector(
    state => state.schoolsWithStudents
  );
  const [paginationDetails, setPaginationDetails] = useState({
    from: 0,
    to: schoolsShown,
    itemsPerPage: schoolsShown,
    page: 1,
  });
  const [lastPage, setLastPage] = useState(1);
  const [schoolsLength, setSchoolsLength] = useState(null);
  const schoolsFilters = useSelector(
    state => state.schoolsWithStudents.schoolsFilters
  );
  const newStatusStudents = useSelector(
    state => state.approveReject.newStatusStudents
  );
  const selectedRepValue = useSelector(
    state => state.schoolsWithStudents.schoolListSelectedRep
  );
  const [isLoading, setLoading] = useState(
    Boolean(!schoolsWithStudentsState.schools)
  );
  const AdminSchools =
    selectedRepValue === appStrings.searchInputs.allReps && isAdmin;

  const getSchoolInfo = school => {
    const currentDisplayStatus = schoolsFilters[school.bpNumber] || false;
    const allStudentsFiltered = currentDisplayStatus
      ? school.students
      : school.students?.filter(student => student.active === true) || [];
    return (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        id={`schoolEntry-${school.bpNumber}`}
        className={css.greyText}
      >
        <Grid item xs={12} sm={6} lg={7}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              <strong>{school.schoolName}</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={css.schoolAddress} variant="body2">
              {school.address}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <Typography>{`${appStrings.common.bpNumber}: ${school.bpNumber}`}</Typography>
        </Grid>
        <Grid item xs={12} sm={2} lg={2}>
          <Typography
            className={
              allStudentsFiltered?.length === 0 ? css.disabledCounter : ''
            }
          >{`${allStudentsFiltered?.length} ${
            allStudentsFiltered?.length === 1
              ? appStrings.common.student
              : appStrings.common.students
          }`}</Typography>
        </Grid>
      </Grid>
    );
  };

  const expand = bpNumber => {
    setExpandedSchool(bpNumber);
    setSelectedStudentId(null);
  };

  const handlePageChange = ({ from, to, page, itemsPerPage }) => {
    setPaginationDetails({ from, to, page, itemsPerPage });
  };

  const handleGetSchools = useCallback(
    id =>
      getSchools({
        id,
        AdminSchools,
        startIndex: paginationDetails.page,
        pageSize: paginationDetails.itemsPerPage,
      })
        .then(res => {
          dispatch(updateSchoolList({ res, employeeId: id }));
          dispatch(updateNewStatusStudents({ approvedRejected: false }));
        })
        .catch(() => {
          dispatch(displayError());
        }),
    [
      AdminSchools,
      dispatch,
      paginationDetails.itemsPerPage,
      paginationDetails.page,
    ]
  );

  useEffect(() => {
    if (!schoolsLength && AdminSchools) {
      getSchoolsLength()
        .then(resp => {
          setSchoolsLength(resp);
        })
        .catch(() => {
          dispatch(displayError());
        });
    }
  }, [AdminSchools, dispatch, schoolsLength]);

  useEffect(() => {
    const id = AdminSchools || !isAdmin ? employeeId : selectedRepValue;
    if (
      id?.length &&
      (id !== schoolsWithStudentsState.employeeIdSearched ||
        !schoolsWithStudentsState.schools ||
        newStatusStudents ||
        (AdminSchools && lastPage !== paginationDetails.page))
    ) {
      setLoading(true);
      setSelectedStudentId(null);

      if (AdminSchools && lastPage !== paginationDetails.page) {
        setLastPage(paginationDetails.page);
      }

      handleGetSchools(id).finally(() => setLoading(false));
    }
  }, [
    AdminSchools,
    employeeId,
    handleGetSchools,
    isAdmin,
    lastPage,
    newStatusStudents,
    paginationDetails.page,
    schoolsWithStudentsState.employeeIdSearched,
    schoolsWithStudentsState.schools,
    selectedRepValue,
  ]);

  useEffect(() => {
    return () => {
      const id = AdminSchools || !isAdmin ? employeeId : selectedRepValue;
      dispatch(resetSchoolFilters());
      if (id) {
        handleGetSchools(id);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedStudentId) {
      dispatch(resetSelectedStudent());
    }
  }, [selectedStudentId, dispatch]);

  return (
    <Grid container>
      <Grid
        container
        item
        justifyContent="space-between"
        alignItems="center"
        xs={12}
        className={css.topContainer}
        spacing={2}
      >
        <Grid item>
          <Typography variant="h2" id="studentsPageTitle">
            <strong>{appStrings.common.students}</strong>
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={2} justifyContent="flex-end">
            {isAdmin && (
              <Grid item xs={6}>
                <RepSearch
                  isLoading={isLoading}
                  selectedRep={selectedRepValue}
                  setSelectedRep={setSchoolListRep}
                  setLoading={setLoading}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <StudentSearch />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isLoading && (
        <Grid item xs={12}>
          <SchoolsListSkeleton />
        </Grid>
      )}
      {!isLoading && (
        <Grid
          item
          xs={12}
          id="renderedSchools"
          data-test-id="renderedSchools"
          className={css.schoolsContainer}
        >
          <CustomBox>
            <CustomAccordionContainer>
              {schoolsWithStudentsState.schools?.map((school, index) => (
                <Fragment key={school.bpNumber}>
                  <CustomAccordion
                    identifier={index}
                    id={school.bpNumber}
                    title={getSchoolInfo(school)}
                    expanded={expandedSchool}
                    onExpandChange={expand}
                  >
                    <StudentsList
                      school={school}
                      selectedStudentId={selectedStudentId}
                      setSelectedStudentId={setSelectedStudentId}
                    />
                  </CustomAccordion>
                  {index < schoolsWithStudentsState.schools?.length - 1 ? (
                    <Divider />
                  ) : null}
                </Fragment>
              ))}
            </CustomAccordionContainer>
          </CustomBox>
        </Grid>
      )}
      <Grid item xs={12} sm={12}>
        {AdminSchools && (
          <CustomPagination
            itemsLength={
              AdminSchools
                ? schoolsLength
                : schoolsWithStudentsState.schools?.length
            }
            itemsPerPage={paginationDetails.itemsPerPage}
            onPageChangeCallback={handlePageChange}
            isLoading={isLoading && AdminSchools}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default SchoolsList;
