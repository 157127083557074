import { makeStyles } from 'tss-react/mui';

const useErrorDialogClasses = makeStyles()(theme => ({
  errorContainer: {
    padding: theme.spacing(5, 1),
    '.MuiDialogTitle-root': {
      textAlign: 'left',
      marginLeft: theme.spacing(6),
      paddingBottom: theme.spacing(3),
    },
  },
  divider: {
    paddingTop: theme.spacing(2),
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
  },
}));

export default useErrorDialogClasses;
