import { Grid, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import appStrings from '../../../core/strings/appStrings';
import useCheckoutStyles from './checkoutStyles';
import CheckoutAddresses from './Components/CheckoutAddresses';
import { getStudentAddressDetails } from '../../shared/StudentProfileForm/studentProfileFormService';
import { useDispatch, useSelector } from 'react-redux';
import { displayError } from '../../../core/redux/slices/notificationsSlice';
import { notificationRole } from '../../../core/strings/appConstants';
import { addressesTypes, defaultSelectedAddress } from './checkoutConstants';
import {
  checkCompleteOrderStudent,
  getSchoolAddress,
  saveOrderCallbackStudent,
  shouldDisablePlaceOrderBtn,
} from './checkoutService';
import CartTotals from '../ShoppingCart/Components/CartTotals';
import CartItems from '../ShoppingCart/Components/CartItems';
import PaymentDialog from '../../shared/PaymentDialog/PaymentDialog';
import {
  displayLoading,
  hideLoading,
} from '../../../core/redux/slices/commonSlice';
import {
  cancelCompleteOrder,
  completeOrder,
} from '../../industrial/OrderPayment/orderPaymentService';
import {
  buildBasket,
  trackGTMEvent,
} from '../../shared/Analytics/analyticsService';
import StudentOrderConfirmationPage from '../StudentOrderConfirmationPage/StudentOrderConfirmationPage';
import { updateStudentSummary } from '../ShoppingCart/ShoppingCartService';
import { setShouldGetCartSummary } from '../../../core/redux/slices/guestCartSlice';
import { getPaymentIdentifier } from '../../shared/PaymentDialog/paymentDialogService';
import { getStateCodeByName } from '../../../core/strings/states';
import { getSchoolInformation } from '../../shared/StudentProfileForm/SchoolInformation/schoolInformationService';

function Checkout() {
  const isSmallDevice = useMediaQuery('(max-width:768px)');
  const css = useCheckoutStyles(isSmallDevice).classes;
  const dispatch = useDispatch();
  const { userId, schoolId } = useSelector(state => state.login.userDetails);
  const totalsInfo = useSelector(state => state.cart.totals);
  const cartItems = useSelector(state => state.guestCart.cartSummaryItems);
  const saveOrderStatus = useSelector(state => state.cart.saveOrderStatus);
  const [studentAddresses, setStudentAddresses] = useState({
    permanentHomeAddress: null,
    schoolAddress: null,
  });
  const [selectedAddresses, setSelectedAddresses] = useState({
    shippingAddress: {},
    billingAddress: {},
  });
  const [loading, setLoading] = useState({
    shippingAddress: false,
    billingAddress: false,
  });
  const [showCardDialog, setShowCardDialog] = useState(false);
  const [orderStatus, setOrderStatus] = useState({
    completed: false,
    orderNumber: null,
  });
  const [editingAddresses, setEditingAddresses] = useState({
    shippingAddress: false,
    billingAddress: false,
  });

  const onPaymentComplete = chaseResp => {
    setShowCardDialog(false);
    dispatch(displayLoading());
    completeOrder(userId, chaseResp)
      .then(res => {
        trackGTMEvent('pageview', {
          is_conversion: 1,
          basket_value: totalsInfo?.paymentTotal,
          order_ID: saveOrderStatus.orderNumber,
          basket: buildBasket(cartItems),
          currency: 'USD',
          orig_ref_url: document.location.href,
        });
        setOrderStatus({
          completed: true,
          orderNumber: saveOrderStatus.orderNumber,
        });
        updateStudentSummary(userId).then(() =>
          dispatch(setShouldGetCartSummary(true))
        );
      })
      .catch(() => dispatch(displayError()))
      .finally(() => dispatch(hideLoading()));
  };

  const onPaymentCancel = () => {
    setShowCardDialog(false);
    dispatch(displayLoading());
    cancelCompleteOrder(userId)
      .catch(() => dispatch(displayError()))
      .finally(() => dispatch(hideLoading()));
  };

  const onPaymentError = chaseErrors => {
    getPaymentIdentifier().then(uid => {
      completeOrder(userId, chaseErrors, uid).catch(() =>
        dispatch(displayError())
      );
    });
  };

  const handlePlaceOrder = () => {
    if (selectedAddresses.shippingAddress.state) {
      selectedAddresses.shippingAddress.state = getStateCodeByName(
        selectedAddresses.shippingAddress.state
      );
    }
    if (selectedAddresses.billingAddress.state) {
      selectedAddresses.billingAddress.state = getStateCodeByName(
        selectedAddresses.billingAddress.state
      );
    }
    checkCompleteOrderStudent({
      studentId: userId,
      amount: totalsInfo.paymentTotal,
      shippingAddress:
        selectedAddresses.shippingAddress.checkboxName !==
        addressesTypes.permanentHomeAddress
          ? selectedAddresses.shippingAddress
          : null,
      billingAddress:
        (selectedAddresses.billingAddress.checkboxName !==
          addressesTypes.permanentHomeAddress &&
          selectedAddresses.billingAddress.checkboxName !==
            addressesTypes.sameAsShippingAddress) ||
        selectedAddresses.shippingAddress.checkboxName !==
          addressesTypes.permanentHomeAddress
          ? selectedAddresses.billingAddress
          : null,
    });
  };

  useEffect(() => {
    setLoading(prev => ({ ...prev, shippingAddress: true }));
    getStudentAddressDetails(userId)
      .then(resp =>
        setStudentAddresses(prev => ({
          ...prev,
          permanentHomeAddress: resp.permanentAddress,
        }))
      )
      .catch(() => dispatch(displayError({ role: notificationRole.student })))
      .finally(() => setLoading(prev => ({ ...prev, shippingAddress: false })));
  }, [dispatch, setStudentAddresses, userId]);

  useEffect(() => {
    setLoading(prev => ({ ...prev, billingAddress: true }));
    getSchoolInformation(userId).then(resp => {
      getSchoolAddress(resp.schoolBp)
        .then(resp =>
          setStudentAddresses(prev => ({
            ...prev,
            schoolAddress: {
              schoolBpNumber: resp.schoolBpNumber,
              schoolName: resp.schoolName,
              ...resp.location,
            },
          }))
        )
        .finally(() =>
          setLoading(prev => ({ ...prev, billingAddress: false }))
        );
    });
  }, [schoolId, setStudentAddresses, userId]);

  useEffect(() => {
    if (
      studentAddresses.permanentHomeAddress &&
      studentAddresses.schoolAddress
    ) {
      setSelectedAddresses({
        shippingAddress: {
          ...studentAddresses[defaultSelectedAddress.shippingAddress],
          checkboxName: defaultSelectedAddress.shippingAddress,
        },
        billingAddress: {
          ...studentAddresses[defaultSelectedAddress.shippingAddress],
          checkboxName: defaultSelectedAddress.billingAddress,
        },
      });
    }
  }, [studentAddresses]);

  useEffect(() => {
    saveOrderCallbackStudent({
      saveOrderStatus,
      setShowCardDialog,
    });
  }, [saveOrderStatus]);

  if (orderStatus.completed) {
    return (
      <StudentOrderConfirmationPage orderNumber={orderStatus.orderNumber} />
    );
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        paddingBottom={2}
        id="checkoutPage"
        data-test-id="checkoutPage-testId"
      >
        {/* Title */}
        <Grid item xs={12} className={css.pageTitle}>
          {appStrings.common.checkout}
        </Grid>

        {/* Addresses & Review */}
        <Grid
          item
          container
          spacing={2}
          xs={12}
          sm={isSmallDevice ? 12 : 8}
          md={8}
          lg={9}
          className={css.containerStyle}
        >
          <Grid item xs={12}>
            <CheckoutAddresses
              setSelectedAddresses={setSelectedAddresses}
              selectedAddresses={selectedAddresses}
              studentAddresses={studentAddresses}
              loading={loading}
              setLoading={setLoading}
              setEditingAddresses={setEditingAddresses}
            />
          </Grid>
          <Grid item xs={12} className={css.containerStyle}>
            <CartItems />
          </Grid>
        </Grid>

        {/* Totals section */}
        <Grid item xs={12} sm md className={css.containerStyle}>
          <CartTotals
            disablePlaceOrder={shouldDisablePlaceOrderBtn({
              editingAddresses,
              totalsInfo,
              loading,
            })}
            handlePlaceOrder={handlePlaceOrder}
          />
        </Grid>
      </Grid>
      {showCardDialog ? (
        <PaymentDialog
          onCancel={onPaymentCancel}
          onPaymentComplete={onPaymentComplete}
          onError={onPaymentError}
        />
      ) : null}
    </>
  );
}

export default Checkout;
