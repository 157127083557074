import {
  ROOT,
  STUDENTS,
  ACCEPTTERMSANDCONDITIONS,
} from '../../../core/navigation/paths';

import ApiService from '../Api/apiService';
import appStrings from '../../../core/strings/appStrings';
import { displaySuccess } from '../../../core/redux/slices/notificationsSlice';
import { industrialRoleList } from '../../../core/navigation/roles';
import { login } from '../../../core/redux/slices/loginSlice';
import { resetInventory } from '../../../core/redux/slices/consignedInventorySlice';
import urls from '../../../core/strings/urls';
import { trackGAEvent, trackGTMEvent } from '../Analytics/analyticsService';

export const authenticate = data => {
  return ApiService.post(urls.authenticate, data);
};

const processLoginInformation = ({ resp, dispatch, navigate }) => {
  const { token } = resp;
  const isIndustrial = industrialRoleList.includes(resp.role);

  dispatch(
    login({
      token,
      userDetails: {
        ...resp,
        isIndustrial,
      },
    })
  );

  // to be called after user is logged in
  setTimeout(() => {
    if (isIndustrial) {
      dispatch(resetInventory());
      navigate(STUDENTS(resp.employeeId));
    } else {
      if (!resp.hasAcceptedTermsAndConditions) {
        navigate(ACCEPTTERMSANDCONDITIONS);
      } else {
        navigate(ROOT);
      }
    }
  });
};

export const handleAuthentication = (resp, dispatch, navigate) => {
  if (resp) {
    dispatch(displaySuccess({ message: appStrings.login.loggedInMsg }));
    processLoginInformation({ resp, dispatch, navigate });
    trackGAEvent('Successful Login', 'Login', resp.employeeId);
    trackGTMEvent('login', { role: resp?.role });
  }
};
