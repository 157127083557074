import { Step, StepLabel, Stepper } from '@mui/material';
import classNames from 'classnames';
import useCustomStepperStyles from './useCustomStepperStyles';
import PropTypes from 'prop-types';

const propTypes = {
  steps: PropTypes.array, // array of objects like { index: 0, label: 'First Step' }
  activeStep: PropTypes.number,
  setActiveStep: PropTypes.func,
  customClass: PropTypes.string,
};

function CustomStepper({
  steps,
  activeStep,
  setActiveStep,
  customClass,
  ...props
}) {
  const css = useCustomStepperStyles().classes;
  return (
    <Stepper
      activeStep={activeStep}
      {...props}
      className={classNames(css.customStepper, customClass ?? '')}
      data-test-id="stepper"
    >
      {steps.map(step => (
        <Step
          classes={step}
          key={step.index}
          data-test-id={
            activeStep === step.index
              ? 'active-step-' + step.index
              : 'step-' + step.index
          }
          id={step.id}
          onClick={() => {
            if (step.index < activeStep) {
              setActiveStep(step.index);
            }
          }}
        >
          <StepLabel className={css.hideLabel}>{step.label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

CustomStepper.propTypes = propTypes;

export default CustomStepper;
