import { memo, useEffect } from 'react';
import { useStudentOrderConfirmationPageStyles } from './useStudentOrderConfirmationPageStyles';
import { useNavigate } from 'react-router-dom';
import {Grid, Typography} from '@mui/material';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CustomButton from '../../shared/CustomButton/CustomButton';
import appStrings from '../../../core/strings/appStrings';
import {ORDERDETAILS, ROOT} from '../../../core/navigation/paths';
import CustomBox from '../../shared/CustomBox/CustomBox';
import {buttonColors, buttonTypes} from '../../../core/strings/appConstants';

function StudentOrderConfirmationPage({ orderNumber }) {
  const css = useStudentOrderConfirmationPageStyles().classes;
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid
      container
      id="student-order-confirmationPage"
      data-test-id="studentOrderConfirmationPage"
      className={css.orderConfirmation}
    >
      <CustomBox customClass={css.orderConfirmationContainer}>
        <Grid item xs={12} className={css.container}>
          <CheckCircleOutlineRoundedIcon
            data-test-id="checkIcon"
            className={css.checkIcon}
          />
          <Grid item>
            <Typography variant="h2" className={css.label}>
              <strong>{appStrings.order.orderPlaced}</strong>
            </Typography>
            <Grid item>
              <Typography variant="h3">
                {appStrings.history.orderConfirmationMessage}
              </Typography>
              <Typography variant="h3">
                {appStrings.history.thankYou}
              </Typography>
              <Grid container justifyContent="space-between" alignItems="center" direction="column" className={css.orderConfirmationDetails}>
                <Typography variant="h3">
                  {`${appStrings.history.orderConfirmationNumber}:`}
                </Typography>
                <Typography variant="h3" color={buttonColors.red} fontWeight="bold">
                  {orderNumber}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="space-around" className={css.buttonsContainer}>
              <CustomButton
                label={appStrings.cart.continueShopping}
                id="continueShopping"
                onClick={() => navigate(ROOT)}
                data-test-id="viewOrderDetails"
                customClass={css.goBackBtn}
                btnType={buttonTypes.secondary}
              />
              <CustomButton
                label={appStrings.history.viewOrderDetails}
                id="viewOrderDetails"
                onClick={() => navigate(ORDERDETAILS(orderNumber))}
                data-test-id="viewOrderDetails"
                customClass={css.goBackBtn}
                color={buttonColors.red}
              />
            </Grid>
          </Grid>
        </Grid>
      </CustomBox>
    </Grid>
  );
}

export default memo(StudentOrderConfirmationPage);