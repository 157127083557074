import { Divider, Grid, Tooltip, Typography } from '@mui/material';
import appStrings from '../../../../core/strings/appStrings';
import useECContractSigningStyles from '../useECContractSigningStyles';
import { states } from '../../../../core/strings/states';
import SignComponent from '../../../shared/SignComponent/SignComponent';
import { useParams } from 'react-router-dom';
import {
  getDisclosureSigningStatus,
  getLinkToSignDisclosure,
  getPromo,
  getSignStatus,
  getSignURL,
  updateSignProgress,
} from '../ECContractSigningService';
import {
  creditTypes,
  disclosureStatuses,
} from '../../../../core/strings/ecFileOptions';
import { useDispatch } from 'react-redux';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import { useEffect, useState } from 'react';
import CustomBackdrop from '../../../shared/CustomBackdrop/CustomBackdrop';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import { alphaNumeric } from '../../../../core/strings/regexValidations';
import { getDisplayErrors } from '../../../../core/services/utilsService';

function Contracts({
  loadDisclosures,
  disclosures,
  currentEnabledDisclosure,
  onOrderSubmitted,
}) {
  const css = useECContractSigningStyles().classes;
  const { id, orderId } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [contractSignatureReqId, setContractSignatureReqId] = useState();
  let currentDisclosure = disclosures?.files.find(
    (disc, index) => index === currentEnabledDisclosure
  );

  const getIsContractSigningDisabled = () => {
    return disclosures?.files.some(disc => {
      return disc.status !== disclosureStatuses.signed;
    });
  };

  const getDisclosureLink = resourceTemplateId => {
    return getLinkToSignDisclosure(id, orderId, resourceTemplateId)
      .then(res => {
        currentDisclosure.signatureRequestId = res.signatureRequestId;
        return {
          ...res,
          creditAppId: resourceTemplateId,
        };
      })
      .catch(err => {
        dispatch(displayError({ message: getDisplayErrors(err) }));
      });
  };

  const getContractLink = () => {
    return getPromo(orderId).then(res => {
      const promoName = res.replace(alphaNumeric, '');
      return getSignURL(id, orderId, promoName)
        .then(signUrlData => {
          setContractSignatureReqId(signUrlData.signatureRequestId);
          return signUrlData;
        })
        .catch(err => {
          dispatch(displayError({ message: getDisplayErrors(err) }));
        });
    });
  };

  const getSignStatusCallback = () => {
    setIsLoading(true);
    return getSignStatus(orderId);
  };

  const getDisclosureStatusCallback = async () => {
    setIsLoading(true);
    try {
      return await getDisclosureSigningStatus(
        currentDisclosure.signatureRequestId
      );
    } catch (err) {
      dispatch(displayError({ message: err }));
    }
  };

  const disclosureCallback = () => {
    loadDisclosures().then(() => setIsLoading(false));
  };

  const contractSuccessCallback = () => {
    onOrderSubmitted();
  };

  const errorCallback = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (orderId && contractSignatureReqId) {
      updateSignProgress(orderId, contractSignatureReqId);
    }
  }, [contractSignatureReqId, orderId]);

  return (
    <>
      {disclosures?.files.length > 0 && (
        <>
          <Grid item container>
            <Grid item xs={12} className={css.sectionTitle}>
              <Typography fontWeight="bold">
                {appStrings.ecContractSigning.disclosure(
                  states[disclosures.state]
                )}
              </Typography>
            </Grid>
            {disclosures.files.map((disclosure, index) => {
              return (
                <Grid
                  item
                  container
                  xs={12}
                  className={css.sectionItem}
                  key={disclosure.resourceTemplateId}
                  alignItems="center"
                >
                  <Grid item xs={10}>
                    <Typography>{`${index + 1}. ${
                      disclosure.label
                    }`}</Typography>
                  </Grid>
                  <Grid item container xs={2} justifyContent="flex-end">
                    {(disclosure.status === disclosureStatuses.none ||
                      disclosure.status === disclosureStatuses.open) && (
                      <Tooltip
                        title={
                          index > currentEnabledDisclosure
                            ? appStrings.ecContractSigning
                                .pleaseSignFirstContract
                            : ''
                        }
                        placement="left"
                        arrow
                        enterTouchDelay={0}
                      >
                        <div>
                          <SignComponent
                            label={appStrings.ecContractSigning.sign}
                            id={`sign-disclosure-${index}`}
                            disableSignNow={index !== currentEnabledDisclosure}
                            generateLink={() =>
                              getDisclosureLink(disclosure.resourceTemplateId)
                            }
                            successCallback={disclosureCallback}
                            errorCallback={disclosureCallback}
                            getFileStatusCallback={getDisclosureStatusCallback}
                            creditType={creditTypes.disclosure}
                            isStudent={false}
                          />
                        </div>
                      </Tooltip>
                    )}
                    {disclosure.status === disclosureStatuses.signed && (
                      <CustomButton disabled label="Signed" />
                    )}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Divider className={css.divider} />
        </>
      )}
      <Grid item container>
        <Grid item xs={12} className={css.sectionTitle}>
          <Typography fontWeight="bold">
            {appStrings.ecContractSigning.askSign}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          className={css.sectionItem}
          alignItems="center"
        >
          <Grid item xs={10}>
            <Typography>
              {appStrings.ecContractSigning.creditSaleContract}
            </Typography>
          </Grid>
          <Grid item container xs={2} justifyContent="flex-end">
            <Tooltip
              title={
                getIsContractSigningDisabled()
                  ? appStrings.ecContractSigning.pleaseSignFirstContract
                  : ''
              }
              placement="left"
              arrow
              enterTouchDelay={0}
            >
              <div>
                <SignComponent
                  label={appStrings.ecContractSigning.sign}
                  id="sign-contract"
                  disableSignNow={getIsContractSigningDisabled()}
                  generateLink={getContractLink}
                  successCallback={contractSuccessCallback}
                  errorCallback={errorCallback}
                  getFileStatusCallback={getSignStatusCallback}
                  creditType={creditTypes.ecOrder}
                  isStudent={false}
                />
              </div>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <CustomBackdrop open={isLoading} />
    </>
  );
}

export default Contracts;
