import {
  addressPattern,
  digitPattern,
  nameStringPattern,
} from '../../../core/strings/regexValidations';
import { getStateCodeByName, states } from '../../../core/strings/states';
import {
  getZipCodeInfo,
  isValidZipCode,
} from '../../../core/services/zipCodeService';
import {
  setShippingAndBilling,
  setTotals,
} from '../../../core/redux/slices/cartSlice';

import ApiService from '../../shared/Api/apiService';
import appStrings from '../../../core/strings/appStrings';
import { cloneDeep } from 'lodash';
import { displayError } from '../../../core/redux/slices/notificationsSlice';
import setMultipleValues from '../../../core/services/formService';
import store from '../../../core/redux/store';
import urls from '../../../core/strings/urls';
import { updateTotals } from '../Cart/cartService';

const gridLayout = { xs: 6 };
const sameAsShippingPath = 'billingAddress.isSameAsShipping';
const billingAddressZipCodePath = 'billingAddress.address.zipCode';
const billingAddressCityPath = 'billingAddress.address.city';
const billingAddressStatePath = 'billingAddress.address.state';

export const getCities = ({ section, getValues, setValue, trigger }) => {
  const zipCodesOptions = store.getState().zipCode.formattedCodeList;
  let code = getValues(`${section}.address.zipCode`);
  const options = zipCodesOptions?.[code]?.cities || [];
  if (options.length === 1) {
    // use setTimeout to allow component to render before City value is updated (prevent unit tests error)
    setTimeout(() => {
      setValue(`${section}.address.city`, options[0].label);
      trigger(`${section}.address.city`);
    }, 300);
  }
  return options;
};

const onZipCodeChange = async ({
  section,
  getValues,
  setValue,
  setDisabledStates,
  clearErrors,
}) => {
  const newZipCode = getValues(`${section}.address.zipCode`);
  const currentState = getValues(`${section}.address.state`);
  const currentCity = getValues(`${section}.address.city`);
  const sameAsShipping = getValues(sameAsShippingPath);

  const hasData = currentState || currentCity;
  if (hasData) {
    setValue(`${section}.address.city`, null);
    setValue(`${section}.address.state`, null);
  }

  if (newZipCode && newZipCode.length === 5) {
    const suggestions = await getZipCodeInfo(newZipCode);
    if (suggestions) {
      setValue(`${section}.address.state`, suggestions?.state);
      clearErrors(`${section}.address.state`);
      clearErrors(`${section}.address.zipCode`);
    }
    // Enable State field
    setDisabledStates(prevStates => ({
      ...prevStates,
      ...{ [section]: false },
    }));
    if (
      section === 'shippingAddress' &&
      getValues(billingAddressZipCodePath) !== newZipCode &&
      sameAsShipping
    ) {
      setValue(billingAddressZipCodePath, newZipCode);
      clearErrors(billingAddressZipCodePath);
      clearErrors(billingAddressStatePath);
      await onZipCodeChange({
        section: 'billingAddress',
        getValues,
        setValue,
        setDisabledStates,
        clearErrors,
      });
    }
  } else {
    if (sameAsShipping) {
      setValue(billingAddressZipCodePath, null);
      setValue(billingAddressCityPath, null);
      setValue(billingAddressStatePath, null);
    }
    // Disable State field
    setDisabledStates(prevStates => ({
      ...prevStates,
      ...{ [section]: true },
    }));
  }
};

const studentFullName = () => {
  const selectedStudent =
    store.getState().schoolsWithStudents.selectedStudent.student;
  return `${selectedStudent.firstName} ${selectedStudent.lastName}`;
};

const generateValues = ({ section, permanentAddress }) => {
  const studentName = studentFullName();
  const newValues = {
    address: {
      addressLine1: permanentAddress.addressLine1,
      addressLine2: permanentAddress.addressLine2,
      zipCode: permanentAddress.zipCode,
      city: permanentAddress.city?.toUpperCase(),
      state: states[permanentAddress.state],
    },
    recipient: studentName,
  };
  if (section === 'shippingAddress') {
    newValues.isSchoolAddress = false;
  } else {
    newValues.isSameAsShipping = false;
  }

  return newValues;
};

const onUsePermanentAddressChange = ({
  value,
  setValue,
  section,
  getValues,
  setDisabledStates,
  setDisabledSections,
  setIsLoading,
  clearErrors,
}) => {
  if (value) {
    const studentId = store.getState().cart.orderInfo.studentId;

    setIsLoading(prev => ({ ...prev, [section]: true }));
    ApiService.get(urls.getAddresses(studentId)).then(async addresses => {
      if (addresses?.permanentAddress) {
        const permanentAddress = addresses.permanentAddress;
        const newValues = generateValues({ section, permanentAddress });

        await updateSection({
          values: newValues,
          setValue,
          getValues,
          section,
          setDisabledStates,
          clearErrors,
        });
        setDisabledSections(prev => ({ ...prev, [section]: true }));

        updateBillingSection({
          section,
          getValues,
          setValue,
          setDisabledStates,
          setDisabledSections,
          setIsLoading,
          clearErrors,
        });
        setIsLoading(prev => ({ ...prev, [section]: false }));
      }
    });
  } else {
    const newValues = clearFields(getValues()[section]);
    updateSection({
      values: newValues,
      setValue,
      getValues,
      section,
      setDisabledStates,
      clearErrors,
    });
    updateBillingSection({
      section,
      getValues,
      setValue,
      setDisabledStates,
      setDisabledSections,
      setIsLoading,
      clearErrors,
    });
    setDisabledSections(prev => ({ ...prev, [section]: false }));
  }
};

const updateBillingSection = ({
  section,
  getValues,
  setValue,
  setDisabledStates,
  setDisabledSections,
  setIsLoading,
  clearErrors,
}) => {
  if (section === 'shippingAddress') {
    const sameAsShipping = getValues(sameAsShippingPath);
    if (sameAsShipping) {
      onSameAsShippingChange({
        value: sameAsShipping,
        setValue,
        getValues,
        section: 'billingAddress',
        setDisabledStates,
        setDisabledSections,
        setIsLoading,
        clearErrors,
      });
    }
  }
};

const onSameAsShippingChange = async ({
  value,
  setValue,
  getValues,
  section,
  setDisabledStates,
  setDisabledSections,
  setIsLoading,
  errors,
  clearErrors,
}) => {
  if (value) {
    if (errors?.billingAddress) {
      clearErrors('billingAddress');
    }
    setIsLoading(prev => ({ ...prev, [section]: true }));
    const { shippingAddress } = getValues();
    const shippingAddressClone = cloneDeep(shippingAddress);
    delete shippingAddressClone.isSchoolAddress;
    shippingAddressClone.isDefault = false;
    setDisabledSections(prev => ({ ...prev, [section]: true }));
    await updateSection({
      values: shippingAddressClone,
      setValue,
      getValues,
      section,
      setDisabledStates,
      clearErrors,
    });
    setIsLoading(prev => ({ ...prev, [section]: false }));
  } else {
    setDisabledSections(prev => ({ ...prev, [section]: false }));
  }
};

const updateSection = async ({
  values,
  setValue,
  getValues,
  section,
  setDisabledStates,
  clearErrors,
}) => {
  const newValues = {
    [section]: {
      ...values,
    },
  };
  setMultipleValues({ setValue, values: newValues });

  if (newValues[section].address?.zipCode) {
    await onZipCodeChange({
      section,
      getValues,
      setValue,
      setDisabledStates,
      clearErrors,
    });
  }
  setTimeout(() => {
    setValue(`${section}.address.city`, newValues[section].address?.city);
  }, 500);
};

const permanentHomeAddressSwitch = ({
  setValue,
  section,
  getValues,
  setDisabledStates,
  setDisabledSections,
  isLoading,
  setIsLoading,
  clearErrors,
}) => {
  return {
    name: `${section}.isDefault`,
    label: `${appStrings.order.usePermanentHomeAddress}:`,
    type: 'switch',
    labelPlacement: 'start',
    gridLayout: { xs: 12 },
    disabled: isLoading[section],
    onChangeCallback: value => {
      onUsePermanentAddressChange({
        value,
        setValue,
        section,
        getValues,
        setDisabledStates,
        setDisabledSections,
        setIsLoading,
        clearErrors,
      });
    },
  };
};

const onUseSchoolAddressChange = ({
  value,
  setValue,
  section,
  getValues,
  setDisabledStates,
  setDisabledSections,
  bp,
  setIsLoading,
  clearErrors,
}) => {
  if (value) {
    setIsLoading(prev => ({ ...prev, [section]: true }));
    ApiService.get(urls.getSchoolAddress(bp)).then(async address => {
      if (address) {
        const location = address.location;
        const recipient = studentFullName();
        await updateSection({
          values: { address: location, recipient, isDefault: false },
          setValue,
          getValues,
          section,
          setDisabledStates,
          clearErrors,
        });
        setDisabledSections(prev => ({ ...prev, [section]: true }));
        updateBillingSection({
          section,
          getValues,
          setValue,
          setDisabledStates,
          setDisabledSections,
          setIsLoading,
          clearErrors,
        });
        setIsLoading(prev => ({ ...prev, [section]: false }));
      }
    });
  } else {
    setDisabledSections(prev => ({ ...prev, [section]: false }));
  }
};

const onInputChange = ({
  value,
  name,
  setValue,
  getValues,
  section,
  formWasSubmitted,
  trigger,
}) => {
  if (section === 'shippingAddress') {
    const sameAsShipping = getValues(sameAsShippingPath);
    const { billingAddress } = getValues();
    if (sameAsShipping && billingAddress && billingAddress[name] !== value) {
      setValue(`billingAddress.${name}`, value);
    }
    if (sameAsShipping && formWasSubmitted) {
      trigger(`billingAddress.${name}`);
    }
  }
};

export const inputFields = ({
  section,
  getValues,
  setValue,
  disabledStates,
  setDisabledStates,
  disabledSections,
  setDisabledSections,
  bp,
  formWasSubmitted,
  trigger,
  isLoading,
  setIsLoading,
  errors,
  clearErrors,
}) => {
  const specificFields =
    section === 'shippingAddress'
      ? [
          permanentHomeAddressSwitch({
            setValue,
            section,
            getValues,
            setDisabledStates,
            setDisabledSections,
            isLoading,
            setIsLoading,
            clearErrors,
          }),
          {
            name: `${section}.isSchoolAddress`,
            label: `${appStrings.order.useSchoolAddress}:`,
            type: 'switch',
            labelPlacement: 'start',
            gridLayout: { xs: 12 },
            disabled: isLoading[section],
            onChangeCallback: value => {
              onUseSchoolAddressChange({
                value,
                setValue,
                section,
                getValues,
                setDisabledStates,
                setDisabledSections,
                bp,
                setIsLoading,
                clearErrors,
              });
            },
          },
        ]
      : [
          {
            name: `${section}.isSameAsShipping`,
            label: `${appStrings.order.sameAsShipping}:`,
            type: 'switch',
            labelPlacement: 'start',
            gridLayout: { xs: 12 },
            disabled: isLoading[section],
            onChangeCallback: value => {
              onSameAsShippingChange({
                value,
                setValue,
                getValues,
                section,
                setDisabledStates,
                setDisabledSections,
                setIsLoading,
                errors,
                clearErrors,
              });
            },
          },
          permanentHomeAddressSwitch({
            setValue,
            section,
            getValues,
            setDisabledStates,
            setDisabledSections,
            isLoading,
            setIsLoading,
            clearErrors,
          }),
        ];
  return [
    ...specificFields,
    {
      name: `${section}.recipient`,
      label: appStrings.order.customerName,
      type: 'text',
      validations: {
        required: true,
        pattern: {
          value: nameStringPattern,
          message: appStrings.validationMessages.nameString,
        },
        maxLength: 51,
      },
      gridLayout,
      disabled: disabledSections[section],
      onChangeCallback: value =>
        onInputChange({
          value,
          name: 'recipient',
          setValue,
          getValues,
          section,
          formWasSubmitted,
          trigger,
        }),
    },
    {
      name: `${section}.address.addressLine1`,
      label: appStrings.order.addressLine1,
      type: 'text',
      validations: {
        required: true,
        pattern: {
          value: addressPattern,
          message: appStrings.validationMessages.address,
        },
        maxLength: 50,
      },
      gridLayout,
      disabled: disabledSections[section],
      onChangeCallback: value =>
        onInputChange({
          value,
          name: 'address.addressLine1',
          setValue,
          getValues,
          section,
          formWasSubmitted,
          trigger,
        }),
    },
    {
      name: `${section}.address.addressLine2`,
      label: appStrings.order.addressLine2,
      type: 'text',
      validations: {
        pattern: {
          value: addressPattern,
          message: appStrings.validationMessages.address,
        },
        maxLength: 50,
      },
      gridLayout,
      disabled: disabledSections[section],
      onChangeCallback: value =>
        onInputChange({
          value,
          name: 'address.addressLine2',
          setValue,
          getValues,
          section,
          formWasSubmitted,
          trigger,
        }),
    },
    {
      name: `${section}.address.zipCode`,
      label: appStrings.common.zipCode,
      type: 'text',
      validations: {
        required: true,
        minLength: {
          value: 5,
          message: appStrings.validationMessages.xDigitsOnly(5),
        },
        pattern: {
          value: digitPattern,
          message: appStrings.validationMessages.xDigitsOnly(5),
        },
        maxLength: 5,
        validate: {
          invalidZipCode: value => isValidZipCode(value),
        },
      },
      onChangeCallback: () => {
        onZipCodeChange({
          section,
          getValues,
          setValue,
          setDisabledStates,
          clearErrors,
        });
      },
      gridLayout,
      disabled: disabledSections[section],
    },
    {
      name: `${section}.address.city`,
      label: appStrings.common.city,
      type: 'select',
      validations: {
        required: true,
      },
      options: getCities({ section, getValues, setValue, trigger }),
      gridLayout,
      disabled: disabledStates[section] || disabledSections[section],
      onChangeCallback: value =>
        onInputChange({
          value,
          name: 'address.city',
          setValue,
          getValues,
          section,
          formWasSubmitted,
          trigger,
        }),
    },
    {
      name: `${section}.address.state`,
      label: appStrings.common.state,
      type: 'text',
      validations: {
        required: true,
      },
      gridLayout,
      readOnly: true,
      disabled:
        disabledStates[section] || disabledSections[section]
          ? true
          : !getValues(`${section}.address.state`),
    },
  ];
};

export const checkZipCodes = orderInfo => {
  return Promise.all([
    orderInfo.shippingAddress.address.zipCode
      ? getZipCodeInfo(orderInfo.shippingAddress.address.zipCode)
      : Promise.resolve(),
    orderInfo.billingAddress.address.zipCode
      ? getZipCodeInfo(orderInfo.billingAddress.address.zipCode)
      : Promise.resolve(),
  ]);
};

export const formatShippingAndBilling = orderInfo => {
  const { shippingAddress, billingAddress } = cloneDeep(orderInfo);
  shippingAddress.address.state = states[shippingAddress.address.state] ?? null;
  billingAddress.address.state = states[billingAddress.address.state] ?? null;
  return {
    shippingAddress,
    billingAddress,
  };
};

export const saveShippingAndBilling = (studentId, data) => {
  const { shippingAddress, billingAddress } = getShippingAndBillingState();

  const info = {
    studentId,
    ...data,
  };
  info.billingAddress.address.id = billingAddress.address?.id;
  info.shippingAddress.address.id = shippingAddress.address?.id;
  info.billingAddress.address.state = getStateCodeByName(
    info.billingAddress.address.state
  );
  info.shippingAddress.address.state = getStateCodeByName(
    info.shippingAddress.address.state
  );

  ApiService.put(urls.saveOrderAddresses, info)
    .then(totals => {
      store.dispatch(setShippingAndBilling(info));
      store.dispatch(setTotals(totals));
      if (totals.taxErrorMessage) {
        store.dispatch(displayError({ message: totals.taxErrorMessage }));
      }
      updateTotals();
    })
    .catch(() => store.dispatch(displayError()));
};

const getShippingAndBillingState = () => {
  const orderInfo = store.getState().cart.orderInfo;
  const { shippingAddress, billingAddress } = cloneDeep(orderInfo);
  return { shippingAddress, billingAddress };
};

export const checkEmptyAddress = data => {
  if (data?.address && data?.recipient) {
    const errorsLength =
      Object.keys(data.address).length === 4 && data.recipient ? true : false;

    const errorsMessagesEmpty =
      Object.values(data.address).findIndex(
        element => element.message !== ''
      ) === -1 && data.recipient?.message === ''
        ? true
        : false;

    return errorsLength && errorsMessagesEmpty;
  }
};

const clearFields = fields => {
  Object.keys(fields.address).forEach(key => {
    fields.address[key] = '';
  });
  fields.recipient = '';

  return fields;
};

export const resetSections = ({
  setValue,
  getValues,
  setDisabledStates,
  setDisabledSections,
  clearErrors,
  setSectionErrors
}) => {
  const sections = ['shippingAddress', 'billingAddress'];
  for (const section of sections) {
    const newValues = clearFields(getValues()[section]);
    updateSection({
      values: newValues,
      setValue,
      getValues,
      section,
      setDisabledStates,
      clearErrors,
    });
  }
  setValue('shippingAddress.isDefault', false);
  setValue('shippingAddress.isSchoolAddress', false);
  setValue(sameAsShippingPath, false);
  setValue('billingAddress.isDefault', false);
  setDisabledSections(prev => ({ ...prev, shippingAddress: false, billingAddress: false, }));
  setSectionErrors(prev => ({ ...prev, shippingAndBilling: true }));
}