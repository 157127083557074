import { makeStyles } from 'tss-react/mui';

const useDirectDebitStyles = makeStyles()(theme => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  sectionTitle: {
    color: theme.palette.common.darkGrey,
  },
  titleContainer: {
    padding: theme.spacing(1, 2),
  },
  input: {
    minHeight: theme.spacing(8),
    '.MuiFormControl-root': {
      width: '100%',
    },
  },
  directDebitContainer: {
    maxWidth: '400px',
    padding: theme.spacing(2, 1),
    '.MuiDialogContent-root': {
      paddingBottom: 0,
      marginBottom: 0,
    },
  },
  button: {
    width: '200px',
  },
}));

export default useDirectDebitStyles;
