export const formatProductName = productName =>
  productName
    .replace(/&nbsp;/g, ' ')
    .replace(/&amp;/gi, '&')
    .replace(/&deg;/gi, '°')
    .replace(/&mdash;/gi, '–');

export const sanitizeString = stringToClean => {
  const doc = new DOMParser().parseFromString(stringToClean, 'text/html');
  return doc.body.textContent || '';
};
