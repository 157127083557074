import {
  Checkbox,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import appStrings from '../../../../core/strings/appStrings';
import ItemsButtons from './ItemsButtons';
import PropTypes from 'prop-types';
import ItemPrice from './ItemPrice';
import useShoppingCartStyles from '../shoppingCartStyles';
import { useLocation, useNavigate } from 'react-router-dom';
import { CHECKOUT, PRODUCT } from '../../../../core/navigation/paths';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedItems } from '../../../../core/redux/slices/guestCartSlice';
import StudentNotes from './StudentNotes';
import classNames from 'classnames';
import CustomAlert from '../../../shared/CustomAlert/CustomAlert';
import {
  auditAlertTypes,
  isUpdatedByAuditAlert,
} from '../../../shared/CustomAlert/customAlertConstants';

function ItemsContent({
  isSavedCart,
  item,
  index,
  handleDeleteFromCart,
  handleMoveItems,
  isCartSummary,
  onItemUpdate,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { selectedCartItems, selectedSavedCartItems } = useSelector(
    state => state.guestCart.selectedItems
  );
  const [selectedItemsList, setSelectedItemsList] = useState([]);
  const { pathname } = useLocation();
  const isXSDevice = useMediaQuery(theme.breakpoints.only('xs'));
  const isSmallDevice = useMediaQuery('(max-width:768px)');
  const css = useShoppingCartStyles({ isSmallDevice, isCartSummary }).classes;
  const isOrderDetails = pathname.indexOf('orderDetails') !== -1;
  const isShoppingCartPage =
    !isCartSummary && pathname.indexOf(CHECKOUT) === -1 && !isOrderDetails;
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedItemsList(
      isSavedCart ? selectedSavedCartItems : selectedCartItems
    );
  }, [isSavedCart, selectedSavedCartItems, selectedCartItems]);

  const handleCheckboxChange = e => {
    if (e.target.checked) {
      dispatch(
        setSelectedItems({
          isSavedCart,
          items: [...selectedItemsList, item.recordId ?? index],
        })
      );
    } else {
      dispatch(
        setSelectedItems({
          isSavedCart,
          items: selectedItemsList.filter(s => s !== (item.recordId ?? index)),
        })
      );
    }
  };

  const getAuditAlert = () => {
    const userAudit = item.itemAuditNotification;
    if (userAudit?.messageType) {
      return (
        <CustomAlert
          id={`itemAlert_${index}`}
          messageType={auditAlertTypes[userAudit.messageType]}
          messageTitle={userAudit.message}
          customClass={classNames({
            [css.auditUpdate]: isUpdatedByAuditAlert(userAudit.messageType),
          })}
        />
      );
    }
  };

  const goToProductDetails = () => {
    navigate(PRODUCT(item?.productNumber));
  };

  return (
    <Grid
      container
      alignItems="center"
      className={css.cartItems}
      id="ShoppingCart-itemsContent"
    >
      {isShoppingCartPage && (
        <Grid item alignSelf="flex-start">
          <Checkbox
            className={css.itemCheckbox}
            checked={selectedItemsList?.includes(item.recordId ?? index)}
            disableRipple
            onChange={handleCheckboxChange}
            data-test-id={`cartItemCheckbox${index}`}
            id={`cartItemCheckbox${index}`}
          />
        </Grid>
      )}
      {!isOrderDetails && (
        <Grid item alignSelf="flex-start">
          <img className={css.goToProductDetailsElement} src={item.image} alt="" onClick={goToProductDetails}/>
        </Grid>
      )}
      <Grid item xs>
        <Grid item container justifyContent="space-between" className={css.itemDetailsContainer}>
          <Grid
            id={`itemName_${index}`}
            item
            xs={12}
            sm={isCartSummary ? 8 : 12}
          >
            <Typography fontWeight="bold">
              {item.name || item.description}
            </Typography>
          </Grid>
          {isCartSummary && (
            <ItemPrice
              item={item}
              isCartSummary={isCartSummary}
              index={index}
            />
          )}
          <Grid id={`itemSku_${index}`} item xs={12}>
            <Typography>
              {appStrings.common.sku}
              <span onClick={goToProductDetails} className={css.goToProductDetailsElement}>
                {` ${item.productNumber}`}
              </span>
            </Typography>
          </Grid>
          <Grid
            item
            container
            alignItems="flex-end"
            sm={12}
            md="auto"
            id={`itemQty_${index}`}
          >
            <Typography fontWeight="700">Qty: {item.quantity ?? 1}</Typography>
          </Grid>
          {!isCartSummary && (
            <Grid item container sm={12} md justifyContent="flex-end">
              <ItemPrice item={item} index={index} showPerItem />
            </Grid>
          )}
        </Grid>
        {isShoppingCartPage && !isXSDevice && (
          <>
            <ItemsButtons
              source={isSavedCart ? 'saved' : 'cart'}
              sm={isSmallDevice && 4}
              indexItem={index}
              isSavedCart={isSavedCart}
              handleDeleteFromCart={handleDeleteFromCart}
              handleMoveItems={handleMoveItems}
            />
            <StudentNotes index={index} item={item} onItemUpdate={onItemUpdate} />
          </>
        )}
      </Grid>
      {isShoppingCartPage && isXSDevice && (
        <>
          <ItemsButtons
            source={isSavedCart ? 'saved' : 'cart'}
            xs={6}
            indexItem={index}
            isSavedCart={isSavedCart}
            handleDeleteFromCart={handleDeleteFromCart}
            handleMoveItems={handleMoveItems}
          />
          <StudentNotes index={index} item={item} onItemUpdate={onItemUpdate} />
        </>
      )}
      {getAuditAlert()}
    </Grid>
  );
}

ItemsContent.propTypes = {
  isSavedCart: PropTypes.bool,
  item: PropTypes.object,
  index: PropTypes.number,
  handleDeleteFromCart: PropTypes.func,
  handleMoveItems: PropTypes.func,
  isCartSummary: PropTypes.bool,
  onItemUpdate: PropTypes.func,
};

export default ItemsContent;
