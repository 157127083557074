import React from 'react';
import useProductContentStyles from '../productContentStyles';
import CustomRotateLoading from '../../../shared/CustomRotateLoading/CustomRotateLoading';

function CartButtonLoading() {
  const css = useProductContentStyles().classes;

  return (
    <CustomRotateLoading
      id="productContentCart-ButtonLoading"
      dataTestId="productContentCart-ButtonLoadingTestId"
      customClass={css.cartButtonLoading}
    />
  );
}

export default CartButtonLoading;
