const brUrlParam = `&url=${process.env.REACT_APP_BLOOMREACH_URL}`;
const urls = {
  addConsignedItems: 'order/addConsignedItems',
  addItem: 'quoteItemList/addItem',
  addItemToOrder: 'order/addItem',
  authenticate: 'auth',
  bloomreachSearchSuggestionProducts: (searchTerm, brUID2, requestId) =>
    `${process.env.REACT_APP_BLOOMREACH_SEARCH_SUGGESTION_URL}?account_id=${process.env.REACT_APP_BLOOMREACH_SEARCH_SUGGESTION_ACCOUNT_ID}` +
    `&domain_key=${process.env.REACT_APP_BLOOMREACH_DOMAIN_KEY}` +
    `&request_id=${requestId}` +
    `&_br_uid_2=${brUID2}` +
    brUrlParam +
    `&ref_url=${process.env.REACT_APP_BLOOMREACH_URL}` +
    `&q=${encodeURIComponent(searchTerm.trim())}` +
    `&ref_url=${process.env.REACT_APP_BLOOMREACH_URL}` +
    '&request_type=suggest' +
    `&catalog_views=${process.env.REACT_APP_BLOOMREACH_DOMAIN_KEY}` +
    '&search_type=keyword',
  bloomreachItemRecommendationProductsUrl: (brUID2, itemsIDs) =>
    `${process.env.REACT_APP_BLOOMREACH_ITEM_RECOMMENDATION_PRODUCTS}/${process.env.REACT_APP_BLOOMREACH_ITEM_WIDGET_ID}` +
    `?account_id=${process.env.REACT_APP_BLOOMREACH_SEARCH_SUGGESTION_ACCOUNT_ID}` +
    `&domain_key=${process.env.REACT_APP_BLOOMREACH_DOMAIN_KEY}` +
    `&_br_uid_2=${brUID2}` +
    brUrlParam +
    `&item_ids=${itemsIDs}` +
    '&fields=pid%2Ctitle%2CImageWeb01%2Cdescription%2CProductId',
  bloomreachGlobalRecommendationProductsUrl: (brUID2, itemsIDs) =>
    `${process.env.REACT_APP_BLOOMREACH_GLOBAL_RECOMMENDATION_PRODUCTS}/${process.env.REACT_APP_BLOOMREACH_GLOBAL_WIDGET_ID}` +
    `?account_id=${process.env.REACT_APP_BLOOMREACH_SEARCH_SUGGESTION_ACCOUNT_ID}` +
    `&domain_key=${process.env.REACT_APP_BLOOMREACH_DOMAIN_KEY}` +
    `&_br_uid_2=${brUID2}` +
    brUrlParam +
    `&item_ids=${itemsIDs}` +
    '&fields=pid%2Ctitle%2CImageWeb01%2Cdescription%2CProductId',
  cancelOrder: 'order/cancel',
  cartDetails: (studentId, cartType) =>
    `quoteItemList/byStudentId/${studentId}/${cartType}`,
  checkForSubmittedECApp: studentId =>
    `ecapplication/hassubmittedapp/${studentId}`,
  completeOrder: 'order/complete',
  customerInformation: 'studentProfile/contact/save',
  deleteItems: 'quoteItemList/deleteItem',
  deleteNotification: 'quoteItemList/deleteNotification',
  deleteOrderItems: 'order/deleteItem',
  deleteStudentProfilePicture: 'studentProfile/deletePicture',
  downloadConsignedInventoryAsExcel: 'warehouse/consignedInventory/excelExport',
  downloadConsignedInventoryAsPdf: 'warehouse/consignedInventory/pdfExport',
  downloadUploadTemplate: 'upload/template',
  editItem: 'quoteItemList/editItem',
  editOrderItem: 'order/editItem',
  exportCartAsExcel: 'repCart/excelExport',
  exportCartAsPdf: 'repCart/pdfExport',
  exportOrderAsExcel: 'order/excelExport',
  exportOrderAsPdf: 'order/pdfExport',
  exportECOrderAsExcel: 'ecOrder/exportReceiptExcel',
  exportECOrderAsPdf: 'ecOrder/exportReceipt',
  exportCompletedOrderAsExcel: 'order/excelExport/completedOrder',
  exportCompletedOrderAsPdf: 'order/pdfExport/completedOrder',
  exportEcApplicationPDF: (studentId, applicationId) =>
    `ecapplication/exportpdf/${studentId}/${applicationId}`,
  forgotPassword: 'auth/forgot',
  getAddresses: id => `StudentProfile/addresses/${id}`,
  getAllSchools: 'schoolSearch/list',
  getCategories: 'product/categories',
  getConsignedInventoriesForEmployer: employeeId =>
    `warehouse/byEmployee/${employeeId}`,
  getAllConsignedInventories: 'warehouse/list',
  getConsignedInventoryDetailsByCode: code =>
    `warehouse/consignedInventory/byWarehouse/${code}`,
  getConsignedInventoryDetailsByProduct: (product, warehouseCode) =>
    `warehouse/consignedInventory/byProduct/${product}/${warehouseCode}`,
  getECApplicationValidations: studentId =>
    `ecApplication/validate/${studentId}`,
  generateECApplicationLink: studentId => `ecApplication/link/${studentId}`,
  getECAppsStatusLink: (studentId, applicationId) =>
    `ecApplication/status/${applicationId}/${studentId}`,
  getEmployersInfo: 'studentProfile/employers',
  getHistoryDetails: 'studentPurchasing/history',
  getItemAvailability: 'Product/atp/all',
  getItemDetails: (product, bp, quantity) =>
    `product/details/${product}/${bp}/${quantity}`,
  getOrderDetailsHistory: (orderNumber, studentId) =>
    `order/historyDetails/${orderNumber}/${studentId}`,
  getStudentPicture: 'studentProfile/studentPicture',
  getOrderLineItems: studentId => `order/lineItems/byStudentId/${studentId}`,
  getOrderInfo: studentId => `order/info/byStudentId/${studentId}`,
  getOrderPayments: studentId => `order/payments/byStudentId/${studentId}`,
  getPurchasePower: (studentId, purchaseType) =>
    `studentPurchasing/byStudent/${studentId}/${purchaseType}`,
  getReferences: 'studentProfile/references',
  getRestoreItemsList: 'studentPurchasing/saleItems',
  getSchoolInfo: 'studentProfile/schoolInformation',
  getSchoolAddress: bp => `schoolSearch/byBpNumber/${bp}`,
  getSchoolsByEmployee: 'schoolSearch/byEmployee/',
  getSchoolsPagedByState: (startIndex, pageSize) =>
    `schoolSearch/pagedByState/${startIndex}/${pageSize}`,
  getSchoolsByStateForGuest: state => `schoolSearch/byStateForGuest/${state}`,
  getSchoolsByState: state => `schoolSearch/byState/${state}`,
  getSchoolsByStateAdmin: state => `schoolSearch/byStateForAdmin/${state}`,
  getSchoolsByStateAndRep: (state, employeeId) =>
    `schoolSearch/byStateAndRep/${state}/${employeeId}`,
  getSchoolStatesByRep: employeeId => `schoolSearch/byStateRep/${employeeId}`,
  getStatesCitiesByZipCode: zipCode => `address/suggestions/${zipCode}`,
  getSearchedStudents: 'schoolsearch/bystudent',
  getStudentNameSuggestions: value =>
    `schoolsearch/getStudentNameSuggestions/${value}`,
  getStudentProfile: id => `StudentProfile/byStudentId/${id}`,
  getStudentProfileInfos: id => `studentProfile/info/${id}`,
  getStudentAchievements: id => `studentProfile/achievements/${id}`,
  getStudentsForApproveReject: employeeId =>
    `registration/byEmployee/${employeeId}`,
  getApproveRejectStudentsBySchool: bpNumber =>
    `registration/bySchool/${bpNumber}`,
  getProducts: categoryId => `product/category/${categoryId}`,
  getProductDetails: productNumber => `product/details/${productNumber}`,
  getSearchedProduct: (productNumber, itemsCount, pageNumber) =>
    `product/list/${productNumber}/${itemsCount}/${pageNumber}`,
  getStudentShoppingCart: (studentId, cartType) =>
    `quoteItemList/byStudentId/${studentId}/${cartType}`,
  getStudentSummaryByCartId: cartId => `quoteItemList/summary/${cartId}`,
  getSchoolsLength: 'schoolSearch/totalSchoolsCount',
  getAllReps: 'default/LN/Reps',
  logger: 'logger/clientLog',
  mergeItem: 'quoteItemList/mergeItem',
  mergeItemToOrder: 'order/mergeItem',
  mergeCartToStudent: 'quoteItemList/addItems',
  moveItems: 'quoteItemList/moveItems',
  moveItemsToOrder: 'order/moveItems',
  moveItemsSignalr: 'quoteItemList/moveCartItems',
  moveItemsToOrderSignalr: 'order/moveOrderItems',
  paymentIdentifier: (studentId, orderNumber) =>
    `payment/token/${studentId}/${orderNumber}`,
  paymentUrl: `${process.env.REACT_APP_PAYMENT_URL}?uID=`,
  postStudentRegistration: 'registration/student',
  resetPassword: 'auth/reset',
  restorePrice: 'order/restorePrice',
  restorePurchasePower: 'studentPurchasing/returnItems',
  restorePurchasePowerForAllItems: 'studentPurchasing/returnAllItems',
  saveAddresses: 'studentProfile/addresses/save',
  saveEmployers: 'studentProfile/employers/save',
  saveOrderAddresses: 'order/saveAddresses',
  saveOrder: 'order/save/rep',
  saveOrderStudent: 'order/save/student',
  savePayments: 'order/savePayments',
  saveReferences: 'studentProfile/references/save',
  schoolInformation: 'studentProfile/schoolInformation/save',
  searchProducts: 'product/list',
  sendECApplicationEmails: 'ecapplication/email',
  updateDetails: (product, bp) => `product/updateDetails/${product}/${bp}`,
  updateTotals: 'repCart/updateSummary',
  updateStudentsStatus: 'registration/approval/update',
  updateOrderSummary: 'order/updateSummary',
  updateStudentSummary: 'quoteItemList/updateSummary',
  uploadCartProducts: 'upload/cart/products',
  uploadOrderProducts: 'upload/order/products',
  updateStudentPicture: 'studentProfile/updatePicture',
  acceptTerms: userId => `auth/acceptTermsAndConditions?userId=${userId}`,
  validateResetPasswordLink: 'auth/validateToken',
  getContractData: (studentId, offset) =>
    `credit/savedOrderContract/get/${studentId}/${offset}`,
  updateContractData: 'credit/savedOrderContract/update',
  getECOrderAccounts: studentId => `ecorder/accounts/${studentId}`,
  getECOrderPromos: studentId => `ecorder/promos/${studentId}`,
  getTrackingNumbers: lnNumber => `default/orderStatus/${lnNumber}`,
  deleteOrder: 'order/delete',
  saveInfo: 'order/saveInfo',
  getProductPictures: productNumber => `product/images/${productNumber}`,
  getTax: 'order/gettax',
  calculateRepaymentConversions: (studentId, orderId) =>
    `ecorder/calculateRepaymentConversions/${studentId}/${orderId}`,
  getDirectDebit: (studentId, orderId) =>
    `ecorder/getdirectdebit?studentId=${studentId}&orderId=${orderId}`,
  saveDirectDebit: (studentId, orderId) =>
    `ecorder/savedirectdebit?studentId=${studentId}&orderId=${orderId}`,
  validateECCredit: (studentId, promoId, offset) =>
    `credit/validate/${studentId}/${promoId}/${offset}`,
  disclosure: (studentId, orderId) =>
    `credit/allDisclosures/${studentId}/${orderId}`,
  getAllDisclosures: (studentId, orderNumber, offset) =>
    `credit/allDisclosures/${studentId}/${orderNumber}/${offset}`,
  getLinkToSignDisclosure: (studentId, orderNumber, resourceTemplateId) =>
    `credit/signDisclosure/${studentId}/${orderNumber}/${resourceTemplateId}`,
  getDisclosureSigningStatus: signatureRequestId =>
    `credit/disclosureStatus/${signatureRequestId}/`,
  getSignURL: (studentId, orderNumber, promoId, offset) =>
    `credit/link/${studentId}/${orderNumber}/${promoId}/${offset}`,
  getSignStatus: orderNumber => `credit/status/${orderNumber}`,
  saveSignProgress: 'credit/signProgress',
  sendECOrderEmail: 'ecOrder/email',
  getPromo: orderId => `ecOrder/promoName?orderId=${orderId}`,
  getEmails: studentId => `ecApplication/emails/${studentId}`,
  exportEcContract: (studentId, orderNumber) =>
    `ecOrder/exportContract/${studentId}/${orderNumber}`,
  exportDisclosure: 'credit/disclosurepdf',
  updateSignProgress: 'credit/signProgress',
  updateContract: (studentId, orderNumber) =>
    `credit/reopen/${studentId}/${orderNumber}`,
  deleteContract: (studentId, orderNumber) =>
    `credit/cancel/${studentId}/${orderNumber}`,
  accountInfo: (studentId, ssn) =>
    `credit/account-info/${studentId}?ssn=${ssn}`,
  getRepByStudentId: 'default/getRepByStudent',
  getProductSets: productNumber => `product/sets/${productNumber}`,
  getProductSetMembers: productNumber => `product/setmembers/${productNumber}`,
  getProductPageAvailability: productNumber =>
    `product/availability/${productNumber}`,
  sendToExchange: `order/sendToExchange`,
};

export const timeTokenRequiredUrls = [
  urls.authenticate,
  urls.forgotPassword,
  urls.resetPassword,
];

export const websiteUrls = {
  termsAndConditions: 'termsAndConditions',
};

export default urls;
