import {
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FORGOT_PASSWORD } from '../../../core/navigation/paths';
import { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { authenticate, handleAuthentication } from './loginService';
import { buttonColors } from '../../../core/strings/appConstants';
import { gridLayout, loginInputs } from './loginConstants';

import CreateAccount from './CreateAccount';
import CustomButton from '../CustomButton/CustomButton';
import FormInput from '../FormInput/FormInput';
import LoginErrors from './LoginErrors/LoginErrors';
import appStrings from '../../../core/strings/appStrings';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import useLoginStyles from './useLoginStyles';
import classNames from 'classnames';

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const css = useLoginStyles().classes;
  const isSmallDevice = useMediaQuery('(max-width:768px)');
  const loginContainerClasses = classNames(css.loginContainer, {
    [css.desktopContainer]: !isSmallDevice,
    [css.smallDeviceContainer]: isSmallDevice,
  });
  const [receivedErrors, setReceivedErrors] = useState(null);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [isLoginDisabled, setIsLoginDisabled] = useState(false);
  const [disableSignIn, setDisableSignIn] = useState(true);

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm();

  const onSubmit = data => {
    const formattedData = { accountIdentifier: data.accountIdentifier.toLowerCase(), password: btoa(data.password) };

    setSubmitInProgress(true);
    setReceivedErrors(null);

    authenticate(formattedData)
      .then(resp => handleAuthentication(resp, dispatch, navigate))
      .catch(e => {
        setReceivedErrors(e);
      })
      .finally(() => {
        setSubmitInProgress(false);
      });
  };

  const handleOnCountDownUpdates = ({ countDownInProgress }) => {
    setIsLoginDisabled(countDownInProgress);
  };

  return (
    <Grid container justifyContent="center" data-test-id="login-container">
      <Grid
        item
        container
        xs={12}
        sm={7}
        md={6}
        lg={5}
        className={loginContainerClasses}
      >
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <Typography className={css.sectionTitle}>
            {appStrings.login.signIn}
          </Typography>
        </Grid>

        <Grid item container>
          <Grid item xs={12}>
            <form
              name="login"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              data-test-id="loginForm"
            >
              <Grid item {...gridLayout}>
                <LoginErrors
                  error={receivedErrors}
                  handlerOnCountDown={handleOnCountDownUpdates}
                />
              </Grid>
              {loginInputs(disableSignIn, setDisableSignIn, getValues).map(
                field => (
                  <Fragment key={`${field.name}-container`}>
                    <Grid
                      item
                      {...field.gridLayout}
                      data-test-id={`${field.name}`}
                    >
                      <FormInput
                        field={field}
                        control={control}
                        errors={errors}
                        size="large"
                      />
                    </Grid>
                  </Fragment>
                )
              )}
              <Grid item container xs={12} alignItems="center">
                <Grid item container xs={6}></Grid>
                <Grid item container xs={6} justifyContent="flex-end">
                  <Grid item>
                    <Link
                      data-test-id="forgotPassword-button"
                      id="forgotPassword-button"
                      className={css.forgotPassLink}
                      to={FORGOT_PASSWORD}
                    >
                      {appStrings.common.forgotPassword}?
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CustomButton
                  color={buttonColors.red}
                  size="large"
                  label={
                    <>
                      {appStrings.common.signIn}
                      {submitInProgress && (
                        <CircularProgress size={14} className={css.loading} />
                      )}
                    </>
                  }
                  data-test-id="signIn-button"
                  id="signIn-button"
                  customClass={css.logInButton}
                  disabled={
                    disableSignIn || submitInProgress || isLoginDisabled
                  }
                  type="submit"
                />
              </Grid>
            </form>
          </Grid>
        </Grid>
        <Grid item>
          <CreateAccount />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Login;
