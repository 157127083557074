import {
  getCurrentCartColorClass,
  getFormattedValue,
  getPercentageOfTotal,
  getPurchasePower,
} from './purchasePowerService';
import { purchaseTypeLabels, purchaseTypes } from './purchasePowerConstants';
import { useDispatch, useSelector } from 'react-redux';

import {Grid, Typography} from '@mui/material';
import PurchasePowerSkeleton from './PurchasePowerSkeleton';
import appStrings from '../../../core/strings/appStrings';
import { clamp } from 'lodash';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import usePurchasePowerStyles from './usePurchasePowerStyles';
import { setShouldGetPurchase } from '../../../core/redux/slices/purchasePowerSlice';

function PurchasePower({ purchaseType, returnListTotal }) {
  const css = usePurchasePowerStyles().classes;
  const dispatch = useDispatch();
  const { id } = useParams();
  const purchasePower = useSelector(state => state.purchasePower);
  const shouldGetPurchase = useSelector(
    state => state.purchasePower.shouldGetPurchase
  );
  const purchasePowerLoading = useSelector(
    state => state.purchasePower.isLoading
  );
  const totalsInfo = useSelector(state => state.cart.totals);
  const [getCurrentPurchasePower, setGetCurrentPurchasePower] = useState(false);

  let currentVal = purchasePower.currentPurchaseValue || 0;
  let purchasedVal = purchasePower.purchasedValue || 0;
  let remainingVal = purchasePower.remainingValue || 0;

  let total = currentVal + purchasedVal + remainingVal;

  if (purchaseType === purchaseTypes.restore) {
    currentVal = returnListTotal;
    purchasedVal = purchasedVal - currentVal;
    remainingVal = remainingVal + returnListTotal;
    total = purchasedVal + remainingVal;
  }

  const purchasedWidth = clamp(getPercentageOfTotal(purchasedVal, total), 100);
  const currentCartWidth = clamp(
    getPercentageOfTotal(currentVal, total) + purchasedWidth,
    100
  );
  const remainingWidth = clamp(
    getPercentageOfTotal(remainingVal, total) + currentCartWidth,
    100
  );

  useEffect(() => {
    if (shouldGetPurchase && !purchasePowerLoading) {
      setGetCurrentPurchasePower(prev => !prev);
      dispatch(setShouldGetPurchase(false));
    }
  }, [dispatch, purchasePowerLoading, shouldGetPurchase]);

  useEffect(() => {
    getPurchasePower(id, purchaseType);
  }, [id, purchaseType, totalsInfo, getCurrentPurchasePower]);

  const currentCartColorClass = getCurrentCartColorClass(
    currentVal,
    remainingVal,
    css
  );

  const withMarginTopStyle = purchaseType === purchaseTypes.order || purchaseType === purchaseTypes.cart;

  return (
    <Grid
      container
      className={css.mainContainer}
      data-test-id="purchasePowerContainer"
      id="purchasePowerContainer"
    >
      {purchasePower.isLoading && (
        <PurchasePowerSkeleton
          standard={purchaseType === purchaseTypes.none}
          positionAbsolute={purchasePower.isLoading}
        />
      )}
      {purchaseType === purchaseTypes.none ? null : (
        <>
          <Typography variant="h2" fontWeight="bold" className={css.title}>{`${appStrings.order.purchaseLimit}:`}</Typography>
          <Grid item xs={12} className={withMarginTopStyle && css.withMarginTop} id="ppCurrentValueContainer">
            <span className={css.floatRight} id="ppCurrentValue">
              <strong>{purchaseTypeLabels[purchaseType]}</strong>
              <span className={classNames(
                css.valueContainer,
                currentCartColorClass,
                css.bold,
                { [css.grey]: purchaseType === purchaseTypes.restore }
              )}
              >
                {getFormattedValue(currentVal)}
              </span>
            </span>
          </Grid>
        </>
      )}
      <Grid item xs={12} className={css.progressContainer}>
        {remainingVal < 0 ? (
          <div
            className={classNames(css.progressLine, css.redLine)}
            style={{ width: `${currentCartWidth}%` }}
          ></div>
        ) : null}
        {remainingVal > 0 ? (
          <div
            className={classNames(css.progressLine, css.greenLine)}
            style={{ width: `${remainingWidth}%` }}
          ></div>
        ) : null}
        {remainingVal >= 0 ? (
          <div
            className={classNames(css.progressLine, css.yellowGreyLine, {
              [css.greenGreyLine]: purchaseType === purchaseTypes.restore,
            })}
            style={{ width: `${currentCartWidth}%` }}
          ></div>
        ) : null}
        {purchasedVal > 0 && (
          <div
            className={classNames(
              css.progressLine,
              purchasedVal >= total ? css.redLine : css.greyLine
            )}
            style={{ width: `${purchasedWidth}%` }}
          ></div>
        )}
      </Grid>
      <Grid item xs={12} id="ppPurchasedValueContainer">
        <span id="ppPurchasedValue" className={css.labelContainer}>
          <strong>{appStrings.cart.purchasedValue}</strong>
          <span className={classNames(css.valueContainer, css.bold)}>
            {getFormattedValue(purchasedVal)}
          </span>
        </span>

        <span className={classNames(css.floatRight, css.labelContainer)} id="ppRemainingValue">
          <strong>{appStrings.cart.remainingValue}</strong>
          <span
            className={classNames(
              css.valueContainer,
              remainingVal > 0 ? css.green : css.red,
              css.bold
            )}
          >
            {getFormattedValue(remainingVal)}
          </span>
        </span>
      </Grid>
    </Grid>
  );
}

export default PurchasePower;
