import { menuCategoriesList } from '../../modules/student/Category/CategoriesMenu/categoriesMenuService';
import {
  ACCOUNT,
  CHECKOUT,
  FORGOT_PASSWORD,
  LOGIN,
  REGISTER,
  ROOT,
  SHOPPINGCART,
} from '../navigation/paths';

import appStrings from '../strings/appStrings';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatNumberWithCurrency = value => {
  return value
    ? formatter.format(value)
    : `${appStrings.common.currencySign}0.00`;
};

export const formatNumberWithPercentage = value => {
  return `${value?.toFixed(2) || '0.00'}${appStrings.common.percentageSign}`;
};

export const formatPhoneNumberUS = phoneNumberString => {
  const addPlusSign = ('' + phoneNumberString).trim()[0] === '+' ? '+' : '';
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{0,5})(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const prefix = match[1] ? `${addPlusSign}${match[1]}-` : '';

    return [prefix, match[2], '-', match[3], '-', match[4]].join('');
  }

  return null;
};

export const xYearsFromNow = x => {
  const yearsFromNow = new Date();
  return yearsFromNow.setFullYear(yearsFromNow.getFullYear() + x);
};

export const xYearsInPast = x => {
  const today = new Date();
  today.setMonth(0);
  today.setDate(1);
  return today.setFullYear(today.getFullYear() - x);
};

export const sortObjectsByProp = (a, b, prop) => {
  var first = a[prop].toUpperCase();
  var second = b[prop].toUpperCase();
  if (first < second) {
    return -1;
  }
  if (first > second) {
    return 1;
  }
  return 0;
};

export const openExportDocument = blob => {
  const file = new Blob([blob], { type: 'application/pdf' });
  const fileURL = window.URL.createObjectURL(file);

  window.open(fileURL);

  window.URL.revokeObjectURL(file);
};

export const isHomePage = pathname => {
  return pathname === ROOT;
};

export const isBRContent = pathname => {
  const BRPathNames = menuCategoriesList.map(o => o['path']);
  return pathname && BRPathNames.includes(pathname);
};

export const getFullDevices = (pathname, isSmallDevice) =>
  (pathname === REGISTER ||
    pathname === SHOPPINGCART ||
    pathname === LOGIN ||
    pathname === FORGOT_PASSWORD ||
    pathname.indexOf(ACCOUNT) !== -1 ||
    pathname === CHECKOUT ||
    pathname.indexOf('/orderDetails') !== -1) &&
  isSmallDevice;

export const getWhiteBackground = pathname =>
  pathname.indexOf('/product/') !== -1;

export const getDisplayErrors = response => {
  let errorString = '';
  if (response.errors) {
    if (response.title) {
      errorString += response.title + '\n';
    }
    Object.values(response.errors).forEach(error => {
      // multiple error text shown for a single issue, only display the last one
      errorString += error[error.length - 1] + '\n';
    });
  } else if (typeof response === 'string') {
    errorString += response;
  }
  return errorString;
};

export const getDateWithoutOffset = date => {
  if (date.utc) {
    const convertedDate = new Date(date);
    return new Date(
      convertedDate.getTime() - convertedDate.getTimezoneOffset() * 60 * 1000
    );
  }

  return date;
};
