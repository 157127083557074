import { makeStyles } from 'tss-react/mui';

const useFilteredStudentsListStyles = makeStyles()(theme => ({
  paginationContainer: {
    '& >:first-of-type': { justifyContent: 'end' },
  },
  tipsContainer: {
    marginTop: theme.spacing(2.5),
    textAlign: 'right',
    '& svg': {
      fontSize: theme.typography.h2.fontSize,
      display: 'block',
    },
  },
  searchIcon: {
    color: theme.palette.common.secondary,
  },
  tipsBtnContainer: {
    '& button': {
      padding: '0 !important',
      paddingRight: `${theme.spacing(1)} !important`,
    },
  },
  popOverRoot: {
    maxWidth: '350px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  redHighlight: {
    color: theme.palette.common.red,
  },
  greenHighlight: {
    color: theme.palette.common.green,
  },
  actionButtonsContainer: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  actionButtons: {
    marginRight: theme.spacing(1),
  },
  loader: {
    position: 'relative',
    height: '60vh',
    background: theme.palette.common.neutral2,
  },
  dialog: {
    '#approvedSquare': { color: theme.palette.common.green },
    '#rejectedSquare': { color: theme.palette.common.red },
    '#pendingSquare': { color: theme.palette.common.veryLightGrey },

    p: {
      padding: theme.spacing(0, 2, 0, 0),
    },

    '.MuiSvgIcon-root': {
      padding: theme.spacing(0, 2, 0, 2),
    },
  },
}));

export default useFilteredStudentsListStyles;
