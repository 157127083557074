import appStrings from '../../../core/strings/appStrings';
import urls from '../../../core/strings/urls';
import ApiService from '../../shared/Api/apiService';

export const orderInfoDetails = [
  { name: 'type', label: appStrings.history.type },
  { name: 'date', label: appStrings.history.date },
  { name: 'transactionNo', label: appStrings.history.transactionNo },
  { name: 'status', label: appStrings.history.status },
  { name: 'transactionTotal', label: appStrings.history.transactionTotal },
  { name: 'placedBy', label: appStrings.history.placedBy },
];

export const getSignURL = (studentId, orderNumber, promoId) => {
  return ApiService.get(
    urls.getSignURL(
      studentId,
      orderNumber,
      promoId,
      new Date().getTimezoneOffset()
    )
  );
};

export const getSignStatus = orderNumber => {
  return ApiService.get(urls.getSignStatus(orderNumber));
};

export const saveSignProgress = (orderNumber, signatureRequestId) => {
  return ApiService.post(urls.saveSignProgress, {
    orderId: orderNumber,
    signatureRequestId,
  });
};

export const getAllDisclosures = (studentId, orderNumber) => {
  return ApiService.get(
    urls.getAllDisclosures(
      studentId,
      orderNumber,
      new Date().getTimezoneOffset()
    )
  );
};

export const getLinkToSignDisclosure = (
  studentId,
  orderNumber,
  resourceTemplateId
) => {
  return ApiService.get(
    urls.getLinkToSignDisclosure(studentId, orderNumber, resourceTemplateId)
  );
};

export const getDisclosureSigningStatus = signatureRequestId => {
  return ApiService.get(urls.getDisclosureSigningStatus(signatureRequestId));
};

export const hasDisclosuresToSign = (studentId, orderId) => {
  return ApiService.get(urls.hasDisclosuresToSign(studentId, orderId));
};

export const getPromo = orderId => {
  return ApiService.get(urls.getPromo(orderId));
};

export const exportOrderReceipt = (studentId, orderNumber) => {
  const payload = {
    studentId,
    orderNumber,
    clientDate: new Date(),
  };

  return ApiService.post(urls.exportECOrderAsPdf, payload);
};

export const exportEcContract = (studentId, orderNumber) => {
  return ApiService.get(urls.exportEcContract(studentId, orderNumber));
};

export const exportDisclosure = (signatureRequestId, packageId) => {
  const payload = {
    signatureRequestId,
    packageId: packageId?.trim() ? packageId : null,
  };

  return ApiService.post(urls.exportDisclosure, payload);
};

export const updateSignProgress = (orderId, signatureRequestId) => {
  return ApiService.post(urls.updateSignProgress, {
    orderId,
    signatureRequestId,
  });
};
