import { Divider, Grid } from '@mui/material';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { propTypes, studentsShown } from './studentsListConstants';
import { useDispatch, useSelector } from 'react-redux';

import CustomPagination from '../../../shared/CustomPagination/CustomPagination';
import FormInput from '../../../shared/FormInput/FormInput';
import NoDataAvailable from '../../../shared/NoDataAvailable/NoDataAvailable';
import Student from '../Student/Student';
import appStrings from '../../../../core/strings/appStrings';
import { getDataToDisplayFromTo } from '../../../shared/CustomPagination/customPaginationService';
import { icons } from '../../../../core/strings/icons';
import { updateSchoolFilter } from '../../../../core/redux/slices/schoolsWithStudentsSlice';
import { useForm } from 'react-hook-form';
import useStudentsListStyles from './useStudentsListStyles';

function StudentsList({ school, selectedStudentId, setSelectedStudentId }) {
  const css = useStudentsListStyles().classes;
  const dispatch = useDispatch();
  const myRef = useRef();
  const { control, setValue } = useForm();
  const schoolsFilters = useSelector(
    state => state.schoolsWithStudents.schoolsFilters
  );
  const [allStudentsFiltered, setAllStudentsFiltered] = useState([]);
  const [displayedStudents, setDisplayedStudents] = useState(
    allStudentsFiltered.slice(0, studentsShown)
  );
  const [paginationDetails, setPaginationDetails] = useState({
    from: 0,
    to: studentsShown,
    itemsPerPage: studentsShown,
    page: 1,
  });
  const currentDisplayStatus = schoolsFilters[school.bpNumber] || false;

  const scrollElementIntoView = () =>
    myRef.current?.offsetParent?.scrollIntoView
      ? myRef.current.offsetParent.scrollIntoView()
      : null;

  const handlePageChange = ({ from, to, page, itemsPerPage }) => {
    setPaginationDetails({ from, to, page, itemsPerPage });
    const newDisplayedItems = getDataToDisplayFromTo(
      allStudentsFiltered,
      paginationDetails.from,
      paginationDetails.to
    );
    setDisplayedStudents(newDisplayedItems);
    setSelectedStudentId(null);
    scrollElementIntoView();
  };

  const updateFilters = () => {
    dispatch(
      updateSchoolFilter({
        bpNumber: school.bpNumber,
        showInactive: !currentDisplayStatus,
      })
    );
  };

  useEffect(() => {
    if (allStudentsFiltered) {
      const newDisplayedItems = getDataToDisplayFromTo(
        allStudentsFiltered,
        paginationDetails.from,
        paginationDetails.to
      );
      setDisplayedStudents(newDisplayedItems);
      setSelectedStudentId(null);
    }
  }, [
    paginationDetails.from,
    paginationDetails.to,
    allStudentsFiltered,
    setSelectedStudentId,
  ]);

  useEffect(() => {
    const studentsFiltered = currentDisplayStatus
      ? school.students
      : school.students?.filter(student => student.active === true) || [];
    setAllStudentsFiltered(studentsFiltered);
  }, [schoolsFilters, school.bpNumber, school.students, currentDisplayStatus]);

  return (
    <Fragment>
      {displayedStudents.length ? (
        displayedStudents.map((student, index) => {
          return (
            <Fragment key={student.id}>
              <Student
                index={index}
                bpNumber={school.bpNumber}
                student={student}
                selectedStudentId={selectedStudentId}
                setSelectedStudentId={setSelectedStudentId}
                firstRef={index === 0 ? myRef : null}
              />
              <Divider className={css.studentDivider} />
            </Fragment>
          );
        })
      ) : (
        <div className={css.noDataContainer}>
          <NoDataAvailable
            message={appStrings.studentsPage.noStudentsMsg}
            icon={icons.student1}
          />
        </div>
      )}

      <Grid container alignItems="flex-end" className={css.bottomContainer}>
        <Grid item xs={12} sm={6} className={css.paginationContainer}>
          {allStudentsFiltered.length ? (
            <CustomPagination
              itemsLength={allStudentsFiltered.length}
              itemsPerPage={paginationDetails.itemsPerPage}
              id={`schoolEntryPagination-${school.bpNumber}`}
              onPageChangeCallback={handlePageChange}
            />
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6} className={css.inactiveFlagContainer}>
          <FormInput
            control={control}
            setValue={setValue}
            field={{
              defaultValue: currentDisplayStatus,
              label: `${appStrings.studentsPage.showInactive}: `,
              name: 'toggleInactiveFilter',
              size: 'small',
              type: 'switch',
              onChangeCallback: updateFilters,
              labelPlacement: 'start',
            }}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}

StudentsList.propTypes = propTypes;

export default StudentsList;
