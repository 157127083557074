import { makeStyles } from 'tss-react/mui';

const useMainContainerStyles = makeStyles()(theme => ({
  mainContainer: {
    backgroundColor: theme.palette.common.neutral1,
    minWidth: '320px',
    overflow: 'auto',
  },
  leftMainContainer: {
    backgroundColor: theme.palette.common.neutral2,
  },
  childrenContainer: {
    minHeight: '100vh',
    height: '100vh',
    maxHeight: '100vh',
    overflow: 'auto',
    flex: 'auto',
  },
  studentContainerView: {
    [theme.breakpoints.up('md')]: {
      padding: '25px 10px',
      backgroundColor: theme.palette.common.neutral2,
    },
    '&:has(.br-container)': {
      padding: `0 !important`,
      overflow: 'visible',
      '.MuiGrid-root': {
        WebkitFlexBasis: '100%',
        msFlexPreferredSize: '100%',
        flexBasis: '100%',
        maxWidth: '100%',
        backgroundColor: 'white',
        flexWrap: 'nowrap',
      },
    }
  },
  repContainerView: {
    scrollbarGutter: 'stable both-edges',
    padding: '25px 10px',
    backgroundColor: theme.palette.common.neutral2,
    [theme.breakpoints.down('md')]: {
      padding: '10px',
      backgroundColor: theme.palette.common.neutral2,
      scrollbarGutter: 'unset',
    },
  },
  brContainerView: {
    padding: `0 !important`,
    overflow: 'visible',
    '.MuiGrid-root': {
      WebkitFlexBasis: '100%',
      msFlexPreferredSize: '100%',
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
  childrenContainerHeaderView: {
    padding: '25px 10px 25px 10px',
    minHeight: '100vh',
    overflow: 'auto',
    flex: 'auto',
    backgroundColor: theme.palette.common.neutral2,
  },
  backdrop: {
    zIndex: 1050,
  },
  loading: {
    backgroundColor: 'transparent',
  },
  childrenForTopSideMenuXs: {
    backgroundColor: theme.palette.common.neutral2,
  },
  notificationsContainer: {
    width: '100%',
    position: 'fixed',
    zIndex: 9,
    '& .MuiSnackbar-root:not(:last-of-type)': {
      marginBottom: theme.spacing(2),
    },
    '& .MuiSnackbar-root': {
      position: 'relative',
    },
  },
  notificationsContainerIndustrial: {
    bottom: theme.spacing(3),
    '& .MuiSnackbar-root': {
      bottom: 'initial',
    },
  },
  notificationsContainerStudent: {
    justifyContent: 'flex-end',
    '& .MuiSnackbar-root': {
      top: 'initial',
    },
  },
  notificationsContainerXs: {
    justifyContent: 'center',
    paddingTop: theme.spacing(3),
  },
  fullDevices: {
    padding: '0px !important',
  },
  whiteBackground: {
    backgroundColor: `${theme.palette.background.paper} !important`,
  },
  navContainer: {
    position: 'sticky',
    top: 0,
    zIndex: 8,

    '&:before': {
      content: '""',
      position: 'fixed',
      zIndex: 10000,
      top: '-100px',
      height: '100px',
      right: 0,
      left: 0,
      backgroundColor: '#fff',
    }
  },
}));

export default useMainContainerStyles;
