import ApiService from '../Api/apiService';
import appStrings from '../../../core/strings/appStrings';
import { displayError } from '../../../core/redux/slices/notificationsSlice';
import store from '../../../core/redux/store';
import urls from '../../../core/strings/urls';

export const getStudentDetails = id => {
  return ApiService.get(urls.getStudentProfile(id));
};

export const getStudentAddressDetails = id => {
  return ApiService.get(urls.getAddresses(id));
};

export const handleErrors = error => {
  store.dispatch(
    displayError({
      message: error || appStrings.errors.somethingWentWrong,
    })
  );
};
