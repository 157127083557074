import { FormLabel, Grid } from '@mui/material';
import {
  updateLoadingState,
  updateSelectedCode,
  resetInventoryForAdmin,
  saveInventoryList,
} from '../../../../core/redux/slices/consignedInventorySlice';
import { useDispatch, useSelector } from 'react-redux';

import CustomBox from '../../../shared/CustomBox/CustomBox';
import FormInput from '../../../shared/FormInput/FormInput';
import appStrings from '../../../../core/strings/appStrings';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import { getConsignedInventoriesForEmployer } from '../consignedInventoryService';
import useConsignedSourceStyle from './useConsignedFilterStyle';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ADMIN } from '../../../../core/navigation/roles';

function ConsignedFilter() {
  const css = useConsignedSourceStyle().classes;
  const dispatch = useDispatch();
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useForm();
  const fieldName = 'filterWarehouse';
  const watchSelectedInventory = watch(fieldName);

  const { employeeId, role } = useSelector(state => state.login.userDetails);
  const isAdmin = role === ADMIN;
  const { inventoryList } = useSelector(state => state.consignedInventory);

  const getLabel = item => `${item.name} (${item.code})`;

  const inventoryOptions =
    inventoryList?.map(elem => ({
      label: getLabel(elem),
      value: elem.code,
    })) || [];

  if (!inventoryOptions.length) {
    inventoryOptions.push({
      label: 'No warehouses available',
      value: '',
    });
  }

  const autocompleteField = {
    name: fieldName,
    type: 'autocomplete',
    options: inventoryOptions || [],
    gridLayout: { xs: 12, sm: 6, md: 4, lg: 4 },
    select: true,
    validations: { required: false, minLength: 3 },
  };

  useEffect(() => {
    if (employeeId) {
      dispatch(updateLoadingState(true));

      getConsignedInventoriesForEmployer({
        empId: employeeId,
        isOrder: false,
        isAdmin: isAdmin,
      })
        .then(res => {
          if (res.length) {
            dispatch(
              saveInventoryList({ list: res, id: employeeId, role: role })
            );
          }
        })
        .catch(e => {
          dispatch(displayError({ message: e }));
        })
        .finally(() => {
          dispatch(updateLoadingState(false));
        });
    }
  }, [dispatch, employeeId, isAdmin, role]);

  useEffect(() => {
    if (inventoryList?.length) {
      const newValue =
        inventoryList.filter(elem => elem.code === watchSelectedInventory) ||
        [];
      if (newValue.length) {
        dispatch(
          updateSelectedCode({
            code: newValue[0]?.code,
            name: newValue[0]?.name,
          })
        );
      } else {
        dispatch(resetInventoryForAdmin());
        dispatch(updateLoadingState(false));
      }
    }
  }, [dispatch, inventoryList, watchSelectedInventory]);

  return (
    <CustomBox customClass={css.customBox}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        className={css.container}
        data-test-id="consignedFilter"
      >
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormLabel className={css.label}>
            {appStrings.consigned.selectInventory}
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} className={css.selectInventory}>
          <form name="filter">
            <FormInput
              field={autocompleteField}
              control={control}
              setValue={setValue}
              errors={errors}
              getValues={getValues}
            />
          </form>
        </Grid>
      </Grid>
    </CustomBox>
  );
}

export default ConsignedFilter;
