import { formatNumberWithCurrency } from '../../../core/services/utilsService';
import appStrings from '../../../core/strings/appStrings';
import urls from '../../../core/strings/urls';
import ApiService from '../../shared/Api/apiService';

export const getTrackingNumbers = lnOrderNumber =>
  ApiService.get(urls.getTrackingNumbers(lnOrderNumber));

const calculateSavings = (items, subtotal) => {
  let price = 0;
  items.forEach(item => price += item.listPrice * item.quantity);

  return price - subtotal;
};

export const setOrderDetails = ({
  setHeaderData,
  setAddresses,
  setOrderItems,
  setOrderTotals,
  response,
}) => {
  setHeaderData({
    number: response.orderNumber,
    status: response.orderStatus,
    type: response.type,
    lnOrderNumber: response.lnOrderNumber,
  });
  setAddresses({
    shippingAddress: response.shippingAddress,
    billingAddress: response.billingAddress,
  });
  setOrderItems(response.lines);
  setOrderTotals({
    subtotal: formatNumberWithCurrency(response.subtotal),
    tax: formatNumberWithCurrency(response.tax),
    shippingHandling: appStrings.common.free,
    sepSavings: formatNumberWithCurrency(calculateSavings(response.lines, response.subtotal)),
    total: formatNumberWithCurrency(response.total),
  });
};
