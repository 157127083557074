import {
  Autocomplete,
  Box,
  createFilterOptions,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import React, { useState } from 'react';
import propTypes from '../propTypes';
import useSelectFieldStyles from '../SelectField/useSelectFieldStyles';
import appStrings from '../../../../core/strings/appStrings';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

function AutoComplete({ field, fieldErrors, control, errors, ...props }) {
  const [isMinLength, setIsMinLength] = useState(!field.validations.minLength);
  const selectStyles = useSelectFieldStyles().classes;
  const navigate = useNavigate();

  const getOptionByValue = value => {
    if (value && value.length) {
      const selectedOption = field.options?.find(elem => elem.value === value);
      if (selectedOption) {
        return selectedOption;
      } else if (field.isPartialSearchEnabled) {
        return value;
      }
    }
    return null;
  };

  const getOptionLabel = option => {
    if (!option.label) {
      return field.isPartialSearchEnabled ? option : '';
    }
    return option.label;
  };

  const filterOptions = createFilterOptions({
    limit: 300,
    stringify: option => option.label + option.description,
  });

  const isDisabled = () => {
    if (field.hasOwnProperty('disabled')) {
      return field.disabled;
    } else {
      return field.options.length === 0;
    }
  };

  const onInputChange = value => {
    setIsMinLength(value && value.length >= field.validations.minLength);
  };

  const handleOnBlur = event => {
    if (field.isPartialSearchEnabled) {
      props.setValue(field.name, event.target.value);
    }
  };

  const handleOnClick = (event, url) => {
    event.preventDefault();
    navigate(url);
  };

  return (
    <>
      <Controller
        control={control}
        defaultValue={field.defaultValue ?? ''}
        name={field.name}
        rules={{ ...field.validations }}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            loading={field.loading}
            freeSolo={field.isPartialSearchEnabled}
            data-test-id={`autocompleteField-${field.name}`}
            id={field.name}
            disabled={isDisabled()}
            filterOptions={filterOptions}
            options={isMinLength ? field.options : []}
            noOptionsText={
              isMinLength
                ? appStrings.infoMessages.noOptions
                : appStrings.infoMessages.minCharAutocomplete(
                    field.validations.minLength
                  )
            }
            key={field.key}
            classes={{
              root: errors[field.name] && selectStyles.disabledAutoComplete,
            }}
            onChange={(event, selectedValue) => {
              onChange(selectedValue ? selectedValue.value : '');
              field.onChangeCallback && field.onChangeCallback();
            }}
            disableClearable={field.disableClearable}
            onInputChange={(event, value) => {
              !isMinLength && onInputChange(value);
              field.onInputChange && field.onInputChange(event, value);
            }}
            getOptionLabel={getOptionLabel}
            renderOption={(props, option) =>
              option.description ? (
                <Box {...props} key={props.id}>
                  <Grid container>
                    <Grid item xs={12}>
                      {option.label}
                    </Grid>
                    <Grid item xs={12}>
                      <span className={selectStyles.optionDescription}>
                        {option.description}
                      </span>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Box
                  {...props}
                  key={props.id}
                  data-test-id={`autocomplete-option-${field.name}`}
                >
                  <Grid container justifyContent="space-between">
                    <Grid xs={field.linkText ? 9 : 12}>{option.label}</Grid>
                    {field.linkText && (
                      <Grid xs={3} className={selectStyles.optionLink}>
                        <Link
                          onClick={event => handleOnClick(event, option.link)}
                        >
                          <Typography>{field.linkText}</Typography>
                          <KeyboardArrowRightIcon />
                        </Link>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )
            }
            value={getOptionByValue(value)}
            renderInput={params => (
              <TextField
                //add dynamic name to prevent chrome autofill
                name={`${field.label}${Date()}`}
                {...params}
                required={field.validations.required}
                variant="outlined"
                label={field.label}
                onBlur={handleOnBlur}
              />
            )}
            size={props.size ? props.size : 'small'}
          />
        )}
      />
      {fieldErrors?.message && (
        <span
          id="errorMessage"
          className={classNames(selectStyles.errorMessage)}
        >
          {fieldErrors.message}
        </span>
      )}
    </>
  );
}

AutoComplete.propTypes = propTypes;

export default AutoComplete;
