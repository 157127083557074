import { Divider, Grid, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useForm } from 'react-hook-form';
import CustomButton from '../../shared/CustomButton/CustomButton';
import useECContractStyles from './useECContractStyles';
import {
  contractData,
  getInputFields,
  updateContractData,
  validateDate,
} from './ecContractService';
import { Fragment, useEffect, useState } from 'react';
import FormInput from '../../shared/FormInput/FormInput';
import { buttonColors } from '../../../core/strings/appConstants';
import { useDispatch, useSelector } from 'react-redux';
import { displayError } from '../../../core/redux/slices/notificationsSlice';
import setMultipleValues from '../../../core/services/formService';
import ECContractSkeleton from './ECContractSkeleton';
import appStrings from '../../../core/strings/appStrings';
import CustomBox from '../../shared/CustomBox/CustomBox';
import NoDataAvailable from '../../shared/NoDataAvailable/NoDataAvailable';
import {
  setContractDetails,
  setDownPayment,
  setECContractLoading,
} from '../../../core/redux/slices/cartSlice';

function ECContract({ setSectionErrors }) {
  const css = useECContractStyles().classes;
  const {
    selectedPromotion,
    selectedAccount,
    orderInfo,
    ecContract,
    ecContractLoading,
  } = useSelector(state => state.cart);
  const dispatch = useDispatch();
  const [selectedContract, setSelectedContract] = useState(
    ecContract?.contract?.paymentsNo
  );
  const {
    control,
    setValue,
    watch,
    formState: { errors },
    getValues,
    trigger,
  } = useForm();

  const inputFields = getInputFields(() => {
    dispatch(setECContractLoading(true));
    updateContract(selectedContract);
  });

  const getLabel = item => {
    return (
      <Grid container rowSpacing={1}>
        <Grid item xs={12}>
          <Typography>{`${item.months} ${appStrings.studentProfile.months}`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight="bold">$ {item.payment}</Typography>
        </Grid>
      </Grid>
    );
  };

  const shouldDisplayDivider = (index, data) => {
    return index !== data.length - 1;
  };

  const getButtonColor = selectedMonths => {
    return selectedMonths === selectedContract ? '' : buttonColors.eucalyptus;
  };

  const getDisplayValue = name => {
    const value = ecContract.contract?.[name];
    if (name === 'interestRate') {
      return `${value}%`;
    }

    const displayValue = Math.abs(value)?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
    });
    return value < 0 ? `($${displayValue})` : `$${displayValue}`;
  };

  const updateContract = months => {
    trigger();
    const firstPaymentDate = getValues('firstInstallmentDueDate');
    const isFirstPaymentDateValid = validateDate(firstPaymentDate);

    if (!isFirstPaymentDateValid) {
      setSectionErrors(prev => ({ ...prev, ecContract: true }));
      dispatch(setECContractLoading(false));
      setSelectedContract(months);
    } else {
      setSectionErrors(prev => ({ ...prev, ecContract: false }));
      const downPayment = getValues('downPayment');
      const adminFee = getValues('administrationFee');

      updateContractData({
        studentId: orderInfo.studentId,
        paymentsNo: months,
        downPayment: downPayment === '' ? 0 : parseFloat(downPayment),
        administrationFee: adminFee === '' ? 0 : parseFloat(adminFee),
        firstPaymentDate,
        offset: new Date().getTimezoneOffset(),
        fpdManual: getIsManualValue(
          ecContract.contract?.fpdManual,
          firstPaymentDate,
          ecContract.contract?.firstPaymentDate
        ),
        adminFeeManual: getIsManualValue(
          ecContract.contract?.adminFeeManual,
          adminFee,
          ecContract.contract?.administrationFee
        ),
      })
        .then(resp => {
          if (resp) {
            setSelectedContract(resp.contract?.paymentsNo);
            dispatch(setContractDetails(resp));
            dispatch(
              setDownPayment({ downPayment: resp.contract?.downPayment })
            );
          }
        })
        .catch(err => {
          dispatch(displayError({ message: err }));
        })
        .finally(() => {
          dispatch(setECContractLoading(false));
        });
    }
  };

  const onClickHandler = months => {
    if (months !== selectedContract) {
      dispatch(setECContractLoading(true));
      updateContract(months);
    }
  };

  const getIsManualValue = (isManual, currentValue, previousValue) => {
    return isManual ? true : currentValue !== previousValue;
  };

  useEffect(() => {
    setSectionErrors(prev => ({ ...prev, ecContract: false }));
    setSelectedContract(ecContract.contract?.paymentsNo);
    setMultipleValues({
      setValue,
      values: {
        downPayment: ecContract.contract?.downPayment,
        administrationFee: ecContract.contract?.administrationFee,
        firstInstallmentDueDate: ecContract.contract?.firstPaymentDate,
      },
    });
  }, [ecContract, setSectionErrors, setValue]);

  if (!selectedPromotion || !selectedAccount) {
    return (
      <CustomBox customClass={css.noDataAvailable}>
        <NoDataAvailable
          className={css.noDataAvailable}
          iconElement={<InfoOutlinedIcon />}
          message={appStrings.order.pleaseSelectPromotionAndAccount}
        />
      </CustomBox>
    );
  } else {
    return !ecContractLoading ? (
      <Grid container direction="row">
        <Grid item container xs={12} sm={6}>
          <form>
            <Grid
              item
              container
              rowSpacing={2}
              spacing={2}
              xs={12}
              data-test-id="credit-options-container"
            >
              {ecContract &&
                ecContract.contractTerms?.map(item => {
                  return (
                    <Grid item xs={4} key={item.months}>
                      <CustomButton
                        color={getButtonColor(item.months)}
                        customClass={css.optionButton}
                        label={getLabel(item)}
                        data-test-id={`value-option-${item.months}`}
                        id={`value-option-${item.months}`}
                        onClick={() => onClickHandler(item.months)}
                      />
                    </Grid>
                  );
                })}
            </Grid>
            <Grid item container xs={12} className={css.container}>
              {inputFields.map(field => (
                <Fragment key={field.name}>
                  <Grid
                    item
                    container
                    xs={5}
                    flexDirection="column"
                    justifyContent="flex-start"
                    className={css.inputLabel}
                  >
                    <Typography id={`contract-${field.name}-label`}>
                      {field.label}:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormInput
                      field={field}
                      control={control}
                      setValue={setValue}
                      watch={watch}
                      getValues={getValues}
                      errors={errors}
                    />
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </form>
        </Grid>
        <Grid item container xs={12} sm={6}>
          {contractData.map((field, index) => (
            <Fragment key={`${field.name}-${index}`}>
              <Grid
                item
                container
                justifyContent="flex-end"
                className={css.dataLabel}
              >
                <Grid item container xs={6} sm={5} justifyContent="flex-start">
                  <Typography id={`contract-${field.name}-label`}>
                    {field.label}
                  </Typography>
                </Grid>
                <Grid item container xs={6} sm={4} justifyContent="flex-end">
                  <Typography fontWeight="bold">
                    {`${getDisplayValue(field.name)}`}
                  </Typography>
                </Grid>
                {shouldDisplayDivider(index, contractData) && (
                  <Grid item xs={12} sm={9}>
                    <Divider />
                  </Grid>
                )}
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </Grid>
    ) : (
      <ECContractSkeleton />
    );
  }
}

export default ECContract;
