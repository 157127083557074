import { useNavigate } from 'react-router-dom';
import { LOGIN, ROOT } from '../../../core/navigation/paths';
import TermsAndConditions from './TermsAndConditions';
import { useDispatch, useSelector } from 'react-redux';
import {
  acceptTermsAndConditions,
  logout,
} from '../../../core/redux/slices/loginSlice';
import { acceptTerms } from './termsAndConditionsService';
import { displayError } from '../../../core/redux/slices/notificationsSlice';
import { notificationRole } from '../../../core/strings/appConstants';

function AcceptTermsAndConditionsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoggedIn, userDetails } = useSelector(state => state.login);

  const onAgree = () => {
    acceptTerms(userDetails.userId)
      .then(() => {
        dispatch(acceptTermsAndConditions());
        navigate(ROOT);
      })
      .catch(() => dispatch(displayError({ role: notificationRole.student })));
  };

  const onGoBack = () => {
    if (isLoggedIn) {
      dispatch(logout());
      navigate(LOGIN);
    }
  };

  return <TermsAndConditions handleAgree={onAgree} handleGoBack={onGoBack} />;
}

export default AcceptTermsAndConditionsPage;
