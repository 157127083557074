import CustomAccordion from '../../../shared/CustomAccordion/CustomAccordion';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import { Grid } from '@mui/material';
import { RESTORE_PURCHASES } from '../../../../core/navigation/paths';
import appStrings from '../../../../core/strings/appStrings';
import { updateFiltersRestore } from '../../../../core/redux/slices/restorePurchasesSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import useOrderHistoryDetailsStyles from '../useOrderHistoryDetailsStyles';

function OrderRestore({ bp, id, orderNumber }) {
  const css = useOrderHistoryDetailsStyles().classes;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onClickHandler = () => {
    dispatch(
      updateFiltersRestore({
        orderNumber: orderNumber,
        reset: true,
      })
    );

    navigate(RESTORE_PURCHASES(bp, id));
  };

  return (
    <Grid
      item
      xs={12}
      data-test-id="orderHistoryDetails-orderRestore"
      id="historyOrderDetailsRestoreContainer"
    >
      <CustomBox>
        <CustomAccordion
          title={appStrings.orderDetailsHistory.restorePurchaseItems}
          customClassDescription={css.accordion}
          identifier="historyOrderDetails-orderRestore"
        >
          <p id="historyOrderDetails-restoreMessage">
            {appStrings.orderDetailsHistory.restorePurchaseMsg}
            <span
              onClick={onClickHandler}
              className={css.link}
              id="historyOrderDetails-redirectToRestorePageClickable"
            >
              {appStrings.orderDetailsHistory.here}
            </span>
          </p>
        </CustomAccordion>
      </CustomBox>
    </Grid>
  );
}

export default OrderRestore;
