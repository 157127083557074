import { Box, CircularProgress, Grid } from '@mui/material';
import React from 'react';
import useShoppingCartIconStyles from '../ShoppingCartIcon/shoppingCartIconStyles';
import appStrings from '../../../../core/strings/appStrings';
import PropTypes from 'prop-types';

function CartIconLoading({ isXsDevices }) {
  const css = useShoppingCartIconStyles().classes;

  return (
    <Grid
      container
      spacing={1}
      alignItems="center"
      id="StudentCart-loading"
      data-test-id="StudentCart-loadingTestId"
    >
      <Grid item className={css.labelStyle}>
        {!isXsDevices && appStrings.menu.cart}
      </Grid>
      <Grid item>
        <Box sx={{ position: 'relative' }}>
          <CircularProgress
            variant="determinate"
            size={22}
            thickness={5}
            value={100}
            className={css.loadingRing}
          />
          <CircularProgress
            variant="indeterminate"
            disableShrink
            size={22}
            thickness={5}
            className={css.loadingRingIndeterminate}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

CartIconLoading.propTypes = {
  isXSDevice: PropTypes.bool,
};

export default CartIconLoading;
