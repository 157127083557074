import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import appStrings from '../../../../core/strings/appStrings';
import { cloneDeep } from 'lodash';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import { getZipCodeInfo } from '../../../../core/services/zipCodeService';
import { maxLengthOfPreviousAddresses } from './addressConstants';
import store from '../../../../core/redux/store';
import moment from 'moment';
import { saveAddressInformation } from '../../../industrial/StudentProfile/Services/studentProfileService';

export const handleErrors = error => {
  store.dispatch(
    displayError({
      message: error,
    })
  );
};

export const addPreviousAddress = ({ addressesState, setAddressesState }) => {
  if (addressesState.prevAddressesActualLength < maxLengthOfPreviousAddresses) {
    setAddressesState(addresses => {
      const outputState = addresses.previousAddresses?.slice(0);
      outputState.push('');
      return {
        ...addresses,
        previousAddresses: outputState,
        prevAddressesActualLength: addresses.prevAddressesActualLength + 1,
      };
    });
  }
};

export const addCurrentAddress = ({ addressesState, setAddressesState }) => {
  if (!addressesState.currentAddress) {
    setAddressesState({
      ...addressesState,
      currentAddress: true,
    });
  }
};

export const calcResidencyLength = (months = 0, years = 0) => {
  return parseInt(months) + parseInt(years) * 12;
};

export const calcMonthsYearsFromLength = length => {
  const years = Math.floor(length / 12);
  const months = length - years * 12;

  return { years, months };
};

const getPlural = (value, string) => {
  return appStrings.addresses[`${string}${value !== 1 ? 's' : ''}`];
};

export const getFormattedResidencyLength = (month = 0, year = 0) =>
  `${year} ${getPlural(
    year,
    appStrings.addresses.year
  )} and ${month} ${getPlural(month, appStrings.addresses.month)}`;

export const formatReceivedData = async values => {
  const formattedValues = cloneDeep(values);
  const zipCodesResp = {};
  const codes = {};
  zipCodesResp.permanentAddress = await getZipCodeInfo(
    values.permanentAddress.zipCode
  );
  formattedValues.permanentAddress.state = zipCodesResp.permanentAddress?.state;
  formattedValues.permanentAddress.city =
    formattedValues.permanentAddress.city?.toUpperCase();
  codes[values.permanentAddress.zipCode] = zipCodesResp.permanentAddress;

  if (formattedValues.currentAddress) {
    zipCodesResp.currentAddress = await getZipCodeInfo(
      values.currentAddress?.zipCode
    );
    formattedValues.currentAddress.state = zipCodesResp.currentAddress?.state;
    codes[values.currentAddress.zipCode] = zipCodesResp.currentAddress;

    // calculate the residency length of currentAddress
    const now = moment();
    const diffInMonths = now.diff(
      formattedValues.currentAddress.residencyStartDate,
      'months'
    );
    const { years, months } = calcMonthsYearsFromLength(diffInMonths ?? 0);

    formattedValues.currentAddress.residencyLength =
      getFormattedResidencyLength(months, years);
  }

  zipCodesResp.previousAddresses = [];

  if (formattedValues.previousAddresses) {
    let i = 0;
    for (const prevAddress of formattedValues.previousAddresses) {
      const { months, years } = calcMonthsYearsFromLength(
        prevAddress.residencyLength ?? 0
      );
      prevAddress.month = months;
      prevAddress.year = years;

      prevAddress.residencyLength = getFormattedResidencyLength(months, years);

      const prevZipResp = await getZipCodeInfo(prevAddress.zipCode);
      zipCodesResp.previousAddresses.push(prevZipResp);

      prevAddress.state = zipCodesResp.previousAddresses[i]?.state;

      codes[values.previousAddresses[i].zipCode] = prevZipResp;
      i++;
    }
  }
  return { formattedValues, codes };
};

export const handleDeleteCurrentAddress = ({
  addressId,
  deletedAddresses,
  setDeletedAddresses,
  addressesState,
  setAddressesState,
  setAddressesSavedData,
  setValue,
  getValues,
  studentId,
  reset
}) => {
  if (addressId) {
    setDeletedAddresses({
      ...deletedAddresses,
      currentAddress: { id: addressId, isDeleted: true },
    });
  }

  const currentAddress = getValues().currentAddress;
  Object.keys(currentAddress).forEach(field => {
    setValue(`currentAddress.${field}`, null, { shouldDirty: true });
  });

  updateCurrentAddress(
    setAddressesSavedData,
    setAddressesState,
    addressesState
  );

  if (addressId) {
    setTimeout(() => {
      saveDeletedAddresses({studentId, data: getValues(), setValue, reset});
    });
  }
};

const updateCurrentAddress = (
  setAddressesSavedData,
  setAddressesState,
  addressesState
) => {
  setAddressesSavedData(prev => ({
    ...prev,
    formattedValues: {
      ...prev.formattedValues,
      currentAddress: null,
    },
  }));

  setAddressesState({
    ...addressesState,
    currentAddress: false,
  });
};

export const handleDeletePreviousAddresses = ({
  addressId,
  index,
  setAddressesState,
  setDeletedAddresses,
  setValue,
  getValues,
  studentId,
  reset
}) => {
  setAddressesState(addresses => {
    const outputState = addresses.previousAddresses.slice(0);
    delete outputState[index];

    return {
      ...addresses,
      previousAddresses: outputState,
      prevAddressesActualLength: addresses.prevAddressesActualLength - 1,
    };
  });

  if (addressId) {
    setDeletedAddresses(addresses => {
      const outputState = addresses.previousAddresses
        ? cloneDeep(addresses.previousAddresses)
        : [];
      outputState.push({ id: addressId, isDeleted: true });

      return {
        ...addresses,
        previousAddresses: outputState,
      };
    });

    setValue('', null, { shouldDirty: true });
    setTimeout(() => {
      saveDeletedAddresses({studentId, data: getValues(), setValue, reset});
    });
  } else {
    getValues().previousAddresses.splice(index, 1);
  }
};

export const getSpeedDialOptions = ({
  addressesState,
  setAddressesState,
  getConditionedValue,
}) => {
  return [
    {
      text: getConditionedValue(
        !addressesState.currentAddress,
        appStrings.addresses.addCurrentAddress,
        appStrings.addresses.currentAddressAdded
      ),
      icon: Boolean(addressesState.currentAddress) ? (
        <CheckIcon />
      ) : (
        <AddIcon />
      ),
      onClick: () => addCurrentAddress({ addressesState, setAddressesState }),
      testId: 'addCurrentAddressBtn',
      disabled: Boolean(addressesState.currentAddress),
    },
    {
      text: getConditionedValue(
        addressesState.prevAddressesActualLength ===
          maxLengthOfPreviousAddresses,
        appStrings.addresses.previousAddressAdded,
        appStrings.addresses.addPreviousAddress
      ),
      icon: Boolean(
        addressesState.prevAddressesActualLength ===
          maxLengthOfPreviousAddresses
      ) ? (
        <CheckIcon />
      ) : (
        <AddIcon />
      ),
      onClick: () => addPreviousAddress({ addressesState, setAddressesState }),
      testId: 'addPreviousAddressBtn',
      disabled: Boolean(
        addressesState.prevAddressesActualLength ===
          maxLengthOfPreviousAddresses
      ),
    },
  ];
};

export const clearEmptySections = (
  emptySections,
  setAddressesSavedData,
  setAddressesState,
  addressesState,
  setValue,
  getValues
) => {
  if (emptySections?.currentAddress) {
    updateCurrentAddress(
      setAddressesSavedData,
      setAddressesState,
      addressesState
    );
  }

  if (emptySections?.previousAddresses?.length) {
    // sort indexes in descending order to not mess up deleting
    emptySections.previousAddresses
      ?.sort((a, b) => b - a)
      .forEach(index =>
        handleDeletePreviousAddresses({
          addressId: null,
          index,
          setAddressesState,
          setDeletedAddresses: null,
          setValue,
          getValues,
        })
      );
  }
};

export const saveDeletedAddresses = ({studentId, data, setValue, reset}) => {
  saveAddressInformation({id : studentId, data, setValue})
    .then(() =>{
      reset({}, {keepValues: true});
    });
}
