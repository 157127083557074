import { Divider, Grid, Skeleton, Stack } from '@mui/material';
import React from 'react';

function OrderInfoSkeleton() {
  return (
    <Stack paddingTop={2}>
      <Divider />
      <Grid
        item
        container
        spacing={2}
        paddingTop={2}
        justifyContent="space-between"
      >
        {Array(2)
          .fill(true)
          .map((_, key) => (
            <Grid item xs={12} sm={5} md={4} lg={3} key={key}>
              <Stack width="80%" spacing={1}>
                <Skeleton variant="rounded" height="24px" width="80%" />
                <Skeleton variant="rounded" height="16px" />
                <Skeleton variant="rounded" height="16px" width="40%" />
                <Skeleton variant="rounded" height="16px" width="60%" />
              </Stack>
            </Grid>
          ))}
      </Grid>
    </Stack>
  );
}

export default OrderInfoSkeleton;
