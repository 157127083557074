import { makeStyles } from 'tss-react/mui';

const useStudentStyles = makeStyles()(theme => ({
  studentContainer: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(
      1
    )} ${theme.spacing(2.5)}`,
  },
  emailContainer: {
    textAlign: 'center',
    color: theme.palette.common.black,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  phoneNoContainer: {
    textAlign: 'center',
    color: theme.palette.common.black,
  },
  buttonsContainer: {
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(3),
    },
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
    wordBreak: 'break-all',
    color: theme.palette.common.primary,
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      color: theme.palette.common.primary5,
      textDecoration: 'none',
    },
  },
  inactive: {
    color: theme.palette.common.inactive,
  },
  inactiveHover: {
    '&:hover': {
      color: theme.palette.common.inactiveHover,
    },
  },
  selected: {
    background: theme.palette.common.neutral6,
  },
}));

export default useStudentStyles;
