import { makeStyles } from 'tss-react/mui';

const useCheckoutStyles = makeStyles()((theme, isSmallDevice) => ({
  pageTitle: {
    fontWeight: 'bold',
    fontSize: theme.spacing(2.5),
  },

  boxStyle: {
    padding: theme.spacing(2),
  },

  boxTitle: {
    fontWeight: 'bold',
    fontSize: theme.spacing(2),
  },

  boxSubtitle: {
    fontSize: theme.spacing(1.5),
    paddingLeft: `${theme.spacing(3.1)} !important`,
  },

  checkboxAddressContainer: {
    '&:has(.Mui-checked)': {
      borderColor: theme.palette.common.red,
      '& .Mui-checked': {
        color: theme.palette.common.red,
      },
    },
    padding: theme.spacing(2),
    border: '1px solid',
    borderRadius: theme.spacing(1),
    color: theme.palette.common.grey2,
  },

  newAddressBtn: {
    fontWeight: 'bold',
    border: '0px !important',
  },

  containerStyle: {
    '& .MuiBox-root': isSmallDevice && {
      borderRadius: theme.spacing(1),
      borderWidth: '1px',
    },
  },
}));

export default useCheckoutStyles;
