import { makeStyles } from 'tss-react/mui';

const useLogoStyles = makeStyles()(theme => ({
  logo: {
    fontSize: theme.typography.logo.fontSize,
    color: theme.palette.common.black,
    maxWidth: '178px',
  },
  darkMode: {
    color: theme.palette.common.white,
  },
  mobileLogo: {
    height: theme.spacing(4),
  },
}));

export default useLogoStyles;
