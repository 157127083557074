import FormInput from '../../../shared/FormInput/FormInput';
import ProductListContext from '../context/ProductListContext';
import { useContext } from 'react';
import useProductsListStyles from '../useProductsListStyles';

function CreateStudentPriceFormInput({
  studentPriceInputInfo,
  editableStudentPriceRef,
}) {
  const css = useProductsListStyles().classes;

  const productListCtx = useContext(ProductListContext);
  const { formControl, errors } = productListCtx;

  return (
    <FormInput
      field={studentPriceInputInfo}
      fieldRef={editableStudentPriceRef}
      control={formControl.current}
      errors={errors}
      InputProps={{ className: css.editableCurrencyField }}
    />
  );
}
export default CreateStudentPriceFormInput;
