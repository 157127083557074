import { makeStyles } from 'tss-react/mui';

const useBrRecommendationsWidgetStyles = makeStyles()(theme => ({
  recommendationsWidgetContainer: {
    width: '100%',
    maxWidth: '88vw',
    whiteSpace: 'nowrap',
    position: 'relative',
    overflowX: 'scroll',
    overflowY: 'hidden',
    background: 'white',
    marginBottom: '16px',
    borderRadius: '8px',
    WebkitOverflowScrolling: 'touch',
    '&::-webkit-scrollbar': {
      height: '14px !important',
    },
  },
  recommendationsList: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    paddingBottom: '12px',
  },
  '&::-webkit-scrollbar': {
    height: '12px !important',
  },
  withSpacing: {
    paddingLeft: '12px !important',
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    flexBasis: '100% !important',
    maxWidth: '100% !important',
    '& button': {
      width: '260px'
    }
  },
  recommendationsWidgetSkeletonContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    maxWidth: '1200px',
    whiteSpace: 'nowrap',
    position: 'relative',
    overflowX: 'scroll',
    overflowY: 'hidden',
    WebkitOverflowScrolling: 'touch',
    '&::-webkit-scrollbar': {
      height: '14px !important',
    },
  },
  skeletonItemContainer: {
    marginRight: '12px',
    width: '320px !important',
    backgroundColor: 'white !important',
  }
}));

export default useBrRecommendationsWidgetStyles;