import React, { useEffect, useRef, useState } from 'react';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import { Alert, Divider, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { useStudentPurchaseLimitStyles } from './studentPurchasePowerStyles';
import { getStudentPurchasePower } from '../accountServices';
import {
  formatPurchaseValues,
  getProgressLabelPaddingLeft,
  getProgressLinePercentage,
} from './studentPurchasePowerServices';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import { notificationRole } from '../../../../core/strings/appConstants';
import appStrings from '../../../../core/strings/appStrings';
import CustomInfoBox from '../Components/CustomInfoBox';
import StudentPurchasePowerSkeleton from './StudentPurchasePowerSkeleton';

function StudentPurchasePower() {
  const css = useStudentPurchaseLimitStyles().classes;
  const dispatch = useDispatch();
  const exactValues = {
    minValue: 0,
    maxValue: 12000,
    minPercentage: 0,
    maxPercentage: 100,
  };
  const { userId } = useSelector(state => state.login.userDetails);
  const [loading, setLoading] = useState(false);
  const [purchasePower, setPurchasePower] = useState({
    purchasedValue: '-',
    remainingValue: '-',
    currentPurchaseValue: '-',
    maximumValue: '-',
  });
  const [progressPercentage, setProgressPercentage] = useState({
    purchasedValue: exactValues.minPercentage,
    currentValue: exactValues.minPercentage,
    remainingValue: exactValues.maxPercentage,
  });
  const [amountExceed, setAmountExceed] = useState(false);
  const [widthProgressLine, setWidthProgressLine] = useState();
  const [widthProgressLabel, setWidthProgressLabel] = useState();
  const observedProgressLine = useRef(null);
  const observedProgressLabel = useRef(null);

  useEffect(() => {
    if (userId) {
      setLoading(true);
      getStudentPurchasePower(userId)
        .then(resp => {
          setPurchasePower(formatPurchaseValues(resp));
          setProgressPercentage(prev => ({
            ...prev,
            purchasedValue: getProgressLinePercentage(
              resp.purchasedValue,
              exactValues.maxValue
            ),
            currentValue: getProgressLinePercentage(
              resp.currentPurchaseValue,
              exactValues.maxValue
            ),
            remainingValue: getProgressLinePercentage(
              resp.remainingValue,
              exactValues.maxValue
            ),
          }));
          setAmountExceed(resp.remainingValue < exactValues.minPercentage);
        })
        .catch(() => dispatch(displayError({ role: notificationRole.student })))
        .finally(() => setLoading(false));
    }
  }, [dispatch, exactValues.maxValue, exactValues.minPercentage, userId]);

  useEffect(() => {
    if (
      progressPercentage.currentValue + progressPercentage.purchasedValue >
      exactValues.maxPercentage
    ) {
      setProgressPercentage(prev => ({
        ...prev,
        currentValue: Math.abs(
          progressPercentage.purchasedValue - exactValues.maxPercentage
        ),
      }));
    }
  }, [
    exactValues.maxPercentage,
    progressPercentage.currentValue,
    progressPercentage.purchasedValue,
  ]);

  useEffect(
    () => {
      if (!observedProgressLine.current) {
        return;
      }

      const resizeObserver = new ResizeObserver(() => {
        if (observedProgressLine.current?.offsetWidth !== widthProgressLine) {
          setWidthProgressLine(observedProgressLine.current?.offsetWidth);
        }
      });

      resizeObserver.observe(observedProgressLine.current);

      return function cleanup() {
        resizeObserver.disconnect();
      };
    },
    // only update the effect if the ref element changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [observedProgressLine.current]
  );

  useEffect(
    () => {
      if (!observedProgressLabel.current) {
        return;
      }

      const resizeObserver = new ResizeObserver(() => {
        if (observedProgressLabel.current?.offsetWidth !== widthProgressLabel) {
          setWidthProgressLabel(observedProgressLabel.current?.offsetWidth);
        }
      });

      resizeObserver.observe(observedProgressLabel.current);

      return function cleanup() {
        resizeObserver.disconnect();
      };
    },
    // only update the effect if the ref element changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [observedProgressLabel.current]
  );

  const progressLineLabel = (
    <Grid
      id="studentPurchasePower-progressLabel"
      data-test-id="studentPurchasePowerProgressLabel-testId"
      container
      justifyContent="flex-start"
      paddingLeft={getProgressLabelPaddingLeft(
        progressPercentage,
        widthProgressLine,
        widthProgressLabel
      )}
      paddingBottom={2}
    >
      <Grid
        item
        container
        xs="auto"
        direction="column"
        ref={observedProgressLabel}
      >
        <Grid
          item
          container
          xs="auto"
          justifyContent="center"
          id="studentPurchasePower-CurrentValueLabel"
        >
          <Typography className={css.labelStyle}>
            {appStrings.cart.currentCartValue}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs="auto"
          justifyContent="center"
          id="studentPurchasePower-CurrentValue"
        >
          {amountExceed && (
            <WarningAmberOutlinedIcon
              id="studentPurchasePower-amountAlertLabelIcon"
              data-test-id="studentPurchasePowerAmountAlertLabelIcon-testId"
              color="error"
              fontSize="small"
            />
          )}
          <Typography fontWeight="bold">
            {purchasePower.currentPurchaseValue}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  const content = (
    <Grid
      container
      id="studentPurchasePower-content"
      data-test-id="studentPurchasePowerContent-testId"
    >
      <Grid
        item
        container
        xs={12}
        className={css.progressContainer}
        ref={observedProgressLine}
      >
        <Grid
          item
          xs={12}
          className={css.alertStyle}
          id="studentPurchasePower-amountAlert"
        >
          {amountExceed && (
            <Alert
              data-test-id="studentPurchasePowerAmountAlert-testId"
              severity="error"
              icon={<WarningAmberOutlinedIcon />}
            >
              {appStrings.errors.amountExceed}
            </Alert>
          )}
        </Grid>
        {progressLineLabel}
        {progressPercentage.purchasedValue > exactValues.minPercentage && (
          <Divider
            id="studentPurchasePower-purchasedLine"
            data-test-id="studentPurchasePowerPurchasedLine-testId"
            className={classNames(css.progressLine, css.purchasedLine)}
            width={`${progressPercentage.purchasedValue}%`}
          />
        )}
        {progressPercentage.currentValue > exactValues.minPercentage && (
          <Divider
            id="studentPurchasePower-currentLine"
            data-test-id="studentPurchasePowerCurrentLine-testId"
            className={classNames(css.progressLine, css.currentLine)}
            width={`${progressPercentage.currentValue}%`}
          />
        )}
        {progressPercentage.remainingValue > exactValues.minPercentage && (
          <Divider
            id="studentPurchasePower-remainingLine"
            data-test-id="studentPurchasePowerRemainingLine-testId"
            className={classNames(css.progressLine, css.remainingLine)}
            width={`${progressPercentage.remainingValue}%`}
          />
        )}
      </Grid>
      <Grid item container justifyContent="space-between">
        <Grid item>
          <div id="studentPurchasePower-purchasedLabel">
            <Typography className={css.labelStyle}>
              {appStrings.cart.purchasedValue}
            </Typography>
          </div>
          <div id="studentPurchasePower-purchasedValue">
            <Typography fontWeight="bold">
              {purchasePower.purchasedValue}
            </Typography>
          </div>
        </Grid>
        <Grid item textAlign="end">
          <div id="studentPurchasePower-remainingLabel">
            <Typography className={css.labelStyle}>
              {appStrings.cart.remainingValue}
            </Typography>
          </div>
          <div id="studentPurchasePower-remainingValue">
            <Typography fontWeight="bold">
              {purchasePower.remainingValue}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <CustomInfoBox
      title={
        <Typography
          fontWeight="bold"
          fontSize="medium"
          id="studentPurchasePower-title"
          data-test-id="studentPurchasePowerTitle-testId"
        >
          {appStrings.studentProfile.myPurchaseLimit}
        </Typography>
      }
      content={loading ? <StudentPurchasePowerSkeleton /> : content}
      icon={<ProductionQuantityLimitsIcon />}
      loading={loading}
    />
  );
}

export default StudentPurchasePower;
