import { makeStyles } from 'tss-react/mui';

const useCustomBoxStyles = makeStyles()(theme => ({
  customBox: {
    background: theme.palette.common.neutral1,
    border: `1px solid ${theme.palette.common.lightGrey}`,
    borderRadius: '4px',
  },
  fadeBorder: {
    border: `1px solid ${theme.palette.common.fadeGrey}`,
  },
}));

export default useCustomBoxStyles;
