import { useNavigate } from 'react-router-dom';

import CustomDialog from '../CustomDialog/CustomDialog';
import CustomIcon from '../CustomIcon/CustomIcon';
import appStrings from '../../../core/strings/appStrings';
import { buttonTypes } from '../../../core/strings/appConstants';
import { icons } from '../../../core/strings/icons';
import { useState } from 'react';
import useStudentProfileStyles from '../../industrial/StudentProfile/useStudentProfileStyles';
import CustomPrompt from '../../../core/navigation/CustomPrompt';

function OrderLeaveDialog({ isDirty, trigger }) {
  const css = useStudentProfileStyles().classes;
  const [allowPrompt, setAllowPrompt] = useState(false);
  const [isLeavePageActive, setIsLeavePageActive] = useState({
    nextLocation: null,
    isActive: false,
  });
  const navigate = useNavigate();

  const handleOnPageLeave = nextLocation => {
    setIsLeavePageActive({
      nextLocation: nextLocation,
      isActive: isLeavePageActive.isActive,
    });
    if (!allowPrompt) {
      isValidForm(nextLocation);
    }
    return allowPrompt;
  };

  const isValidForm = async path => {
    if (isDirty) {
      const isValid = await trigger();
      if (isValid) {
        setAllowPrompt(true);
        setTimeout(() => {
          navigate(`${path}`);
        });
      } else {
        setAllowPrompt(false);
        setIsLeavePageActive({
          nextLocation: isLeavePageActive.nextLocation || path,
          isActive: true,
        });
      }
    } else {
      setAllowPrompt(true);
      setTimeout(() => {
        navigate(`${path}`);
      });
    }
  };

  const onPageStay = () => {
    setIsLeavePageActive({
      nextLocation: null,
      isActive: false,
    });
  };

  const onPageLeave = () => {
    setAllowPrompt(true);
    setIsLeavePageActive({
      nextLocation: isLeavePageActive.nextLocation,
      isActive: false,
    });
    setTimeout(() => {
      navigate(isLeavePageActive.nextLocation);
    });
  };

  const getButtons = () => {
    return [
      {
        label: appStrings.common.cancel,
        btnType: buttonTypes.secondary,
        onClick: onPageStay,
      },
      {
        label: appStrings.modal.btnDiscard,
        btnType: buttonTypes.secondary,
        onClick: onPageLeave,
      },
    ];
  };

  return (
    <>
      <CustomPrompt
        handleDialog={handleOnPageLeave}
        useDialog={isLeavePageActive}
        blockNavigation={isDirty}
      />
      <CustomDialog
        title={appStrings.modal.order.title}
        message={appStrings.modal.order.description}
        icon={<CustomIcon icon={icons.order} customClasses={css.leaveIcon} />}
        open={isLeavePageActive.isActive}
        buttons={getButtons()}
      />
    </>
  );
}

export default OrderLeaveDialog;
