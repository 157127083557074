import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from 'react';
import classNames from 'classnames';
import { fullIcons } from '../../../core/strings/icons';
import { propTypes } from './accordionTemplateConstants';
import useAccordionTemplateStyles from './useAccordionTemplateStyles';

function AccordionTemplate({
  customSummary,
  customClasses,
  details,
  expand,
  expanded,
  expandByIcon,
  id,
}) {
  const css = useAccordionTemplateStyles().classes;

  const handleExpansionChange = newId => (event, isExpanded) => {
    const classText = event.target.className.animVal ?? event.target.className;
    if (
      expand &&
      (!expandByIcon ||
        (expandByIcon &&
          (classText.indexOf(fullIcons.expandMore) !== -1 ||
            classText.indexOf('expandIcon') !== -1 ||
            classText.indexOf('MuiSvgIcon-root') !== -1)))
    ) {
      expand(isExpanded ? newId : null);
    }
  };

  return (
    <Accordion
      classes={
        customClasses?.accordion ?? {
          root: css.accordion,
        }
      }
      onChange={handleExpansionChange(id)}
      expanded={expanded === id}
      id={`accordion-${id}`}
      data-test-id="accordionTemplate"
      TransitionProps={{ mountOnEnter: true }}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowDownIcon className={css.expandIcon} />}
        aria-controls={`accordion-${id}`}
        classes={
          customClasses?.accordionSummary ?? {
            expandIcon: css.expanded,
          }
        }
        className={classNames({ [css.normalCursor]: expandByIcon })}
        data-test-id="accordionTemplateSummary"
      >
        {customSummary}
      </AccordionSummary>

      <AccordionDetails
        classes={{
          root: css.accordionDetails,
        }}
      >
        <Grid container className={css.detailsContainer}>
          {details}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

AccordionTemplate.propTypes = propTypes;

export default AccordionTemplate;
