import approveRejectReducer from './slices/approveRejectSlice';
import cartReducer from './slices/cartSlice';
import categoriesReducer from './slices/categoriesSlice';
import { combineReducers } from 'redux';
import commonReducer from './slices/commonSlice';
import consignedInventoryReducer from './slices/consignedInventorySlice';
import guestCartReducer from './slices/guestCartSlice';
import historyReducer from './slices/historySlice';
import loginReducer from './slices/loginSlice';
import notificationsReducer from './slices/notificationsSlice';
import purchasePowerReducer from './slices/purchasePowerSlice';
import registerReducer from './slices/registerSlice';
import restorePurchasesSlice from './slices/restorePurchasesSlice';
import schoolsWithStudentsReducer from './slices/schoolsWithStudentsSlice';
import signalRReducer from './slices/signalRSlice';
import signingReducer from './slices/signingSlice';
import studentSearchReduce from './slices/studentSearchSlice';
import wizardReducer from './slices/wizardSlice';
import zipCodeReducer from './slices/zipCodeSlice';

export default combineReducers({
  approveReject: approveRejectReducer,
  cart: cartReducer,
  categories: categoriesReducer,
  common: commonReducer,
  consignedInventory: consignedInventoryReducer,
  guestCart: guestCartReducer,
  history: historyReducer,
  login: loginReducer,
  notifications: notificationsReducer,
  purchasePower: purchasePowerReducer,
  register: registerReducer,
  restorePurchases: restorePurchasesSlice,
  schoolsWithStudents: schoolsWithStudentsReducer,
  signalR: signalRReducer,
  signing: signingReducer,
  studentSearch: studentSearchReduce,
  wizard: wizardReducer,
  zipCode: zipCodeReducer,
});
