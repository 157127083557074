import { Container, Grid } from '@mui/material';
import {
  receiveNotificationFromRepMsg,
  receiveNotificationFromSOCMsg,
  submittedMsgForIndustrial,
  submittedMsgForStudent,
  useContactOptions,
} from '../../../../core/strings/ecFileOptions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CustomBox from '../../CustomBox/CustomBox';
import CustomButton from '../../CustomButton/CustomButton';
import NoDataAvailable from '../../NoDataAvailable/NoDataAvailable';
import { ROOT, STUDENT_PROFILE } from '../../../../core/navigation/paths';
import SendEmailModal from '../../SendEmailModal/SendEmailModal';
import appStrings from '../../../../core/strings/appStrings';
import { buttonTypes } from '../../../../core/strings/appConstants';
import { closeWizard } from '../../../../core/redux/slices/wizardSlice';
import {
  displayError,
  displayInfo,
  displaySuccess,
} from '../../../../core/redux/slices/notificationsSlice';
import { openExportDocument } from '../../../../core/services/utilsService';
import ApiService from '../../Api/apiService';
import urls from '../../../../core/strings/urls';

function WizardSubmitted({ isStudent }) {
  const { id, bp } = useParams();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { userDetails } = useSelector(state => state.login);

  const student = useSelector(
    state => state.schoolsWithStudents.selectedStudent.student
  );
  const transactionNumber = useSelector(
    state => state.wizard.transactionNumber
  );

  const studentName = student
    ? `${student?.firstName || ''} ${student?.lastName || ''}`
    : '';

  const viewApplication = () => {
    let studentId;
    if (isStudent) {
      studentId = userDetails.userId;
    } else {
      studentId = student.id;
    }
    dispatch(displayInfo({ message: appStrings.export.exportInProgress }));
    ApiService.get(urls.exportEcApplicationPDF(studentId, transactionNumber))
      .then(res => openExportDocument(res))
      .catch(e => dispatch(displayError({ message: e })))
      .finally(() =>
        dispatch(displaySuccess({ message: appStrings.export.reportExported }))
      );
  };

  const openEmailModal = emails => {
    if (emails?.length) {
      const payload = {
        applicationId: transactionNumber,
        studentId: isStudent ? userDetails.userId : student.id,
        recipients: emails.map(email => ({
          emailAddress: email,
          sendAttachment: true,
        })),
      };

      ApiService.post(urls.sendECApplicationEmails, payload)
        .then(() =>
          dispatch(displaySuccess({ message: appStrings.wizard.emailsSent }))
        )
        .catch(e => dispatch(displayError({ message: e })));
    }
  };

  return (
    <Container maxWidth="sm">
      <Grid paddingTop="60px">
        <CustomBox fadeBorder>
          <NoDataAvailable
            message={
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                paddingX={2}
              >
                <Grid item textAlign="center">
                  {isStudent
                    ? submittedMsgForStudent
                    : submittedMsgForIndustrial(studentName)}
                </Grid>
                <Grid item textAlign="center">
                  {isStudent
                    ? receiveNotificationFromRepMsg
                    : receiveNotificationFromSOCMsg}
                </Grid>

                {!isStudent && (
                  <Grid item paddingTop={'30px'} textAlign="center">
                    {useContactOptions}
                  </Grid>
                )}
              </Grid>
            }
            iconElement={<CheckCircleOutlineOutlinedIcon />}
          />
        </CustomBox>
      </Grid>

      <Grid
        container
        alignItems="center"
        spacing={2}
        marginBottom={'20px'}
        paddingTop={'20px'}
      >
        <Grid item xs>
          <CustomButton
            sx={{ width: '100%' }}
            label={appStrings.common.close}
            onClick={() => {
              if (isStudent) {
                navigation(ROOT);
              } else {
                navigation(STUDENT_PROFILE(bp, id));
              }
              dispatch(closeWizard());
            }}
            id="wizardCloseBtn"
          />
        </Grid>

        {!isStudent && (
          <Grid item xs="auto">
            <SendEmailModal
              label={appStrings.wizard.emailECApp}
              id="wizardEmailECAppBtn"
              title={appStrings.wizard.emailECApp}
              btnType={buttonTypes.secondary}
              onSubmitCallback={openEmailModal}
            />
          </Grid>
        )}

        <Grid item xs>
          <CustomButton
            sx={{ width: '100%' }}
            label={appStrings.wizard.viewApplication}
            id="wizardViewAppBtn"
            btnType={buttonTypes.secondary}
            onClick={() => viewApplication()}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default WizardSubmitted;
