import {
  closeSignalRConnection,
  startSignalRConnection,
} from '../../../core/services/signalR/signalRService';
import {
  displayError,
  displayInfo,
  displaySuccess,
  hideNotification,
} from '../../../core/redux/slices/notificationsSlice';
import {
  downloadCartTemplate,
  exportCartByType,
  uploadCartData,
} from './cartService';

import { ORDER, ORDER_CONSIGNED_PAGE } from '../../../core/navigation/paths';
import appStrings from '../../../core/strings/appStrings';
import { downloadFileFromBlob } from '../../../core/services/downloadService';
import { icons } from '../../../core/strings/icons';
import { signalRStrings } from '../../../core/strings/signalRStrings';
import store from '../../../core/redux/store';
import { xmlTypes } from '../../../core/strings/mimeTypes';

const exportCart = (
  studentId,
  isOrder,
  isECOrder = false,
  ecContract = {},
  isExcel = true
) => {
  const notificationId = `info${new Date().getTime()}`;
  store.dispatch(
    displayInfo({
      message: appStrings.export.exportInProgress,
      permanent: true,
      id: notificationId,
    })
  );

  const fileName = `${
    isOrder ? appStrings.order.studentOrder : appStrings.cart.shoppingCart
  }.${isExcel ? 'xlsx' : 'pdf'}`;

  exportCartByType(studentId, isOrder, isECOrder, ecContract, isExcel)
    .then(resp => {
      downloadFileFromBlob(resp, fileName);

      store.dispatch(
        displaySuccess({
          message: isOrder
            ? appStrings.export.orderExported
            : appStrings.export.cartExported,
        })
      );
    })
    .catch(e => store.dispatch(displayError({ message: e })))
    .finally(() => store.dispatch(hideNotification({ id: notificationId })));
};

export const getExportLabels = isOrder => {
  let exportToPdfLabel, exportToXlsLabel;
  if (isOrder) {
    exportToPdfLabel = appStrings.order.exportOrderToPdf;
    exportToXlsLabel = appStrings.order.exportOrderToXls;
  } else {
    exportToPdfLabel = appStrings.cart.exportCartToPdf;
    exportToXlsLabel = appStrings.cart.exportCartToXls;
  }
  return { exportToPdfLabel: exportToPdfLabel, exportToXlsLabel };
};

export const menuItems = ({
  isInactiveStudent,
  importRef,
  uploadCartDetails,
  downloadHandlers,
  studentId,
  isOrder,
  isECOrder,
  ecContract,
  isPdfExportDisabled,
  navigate,
  bp,
  id,
}) => {
  const openFileSelector = () => {
    importRef.current.click();
  };

  const handleUploadFile = e => {
    startSignalRConnection(uploadCartDetails.cartDetails.userId);
    const fileInput = e.target.files[0];

    const uploadData = {
      ...uploadCartDetails.cartDetails,
      callbackMethodName: signalRStrings.uploadedItemsToCart,
      uploadTemplate: fileInput,
    };

    if (uploadCartDetails.callbackOnUploadStart) {
      uploadCartDetails.callbackOnUploadStart();
    }

    uploadCartData(uploadData, isOrder)
      .then(resp => {
        if (uploadCartDetails.callbackOnUploadResponse) {
          uploadCartDetails.callbackOnUploadResponse(resp);
        }
      })
      .catch(err => {
        if (uploadCartDetails.callbackOnUploadError) {
          uploadCartDetails.callbackOnUploadError(err);
        }
        closeSignalRConnection();
      });
  };

  const downloadUploadTemplateHandler = () =>
    downloadCartTemplate()
      .then(resp => {
        downloadFileFromBlob(resp, appStrings.cart.templateName);
        if (downloadHandlers?.downloadCompleteHandler) {
          downloadHandlers.downloadCompleteHandler();
        }
      })
      .catch(err => {
        if (downloadHandlers?.downloadErrorHandler) {
          downloadHandlers.downloadErrorHandler(err);
        }
      });

  const { exportToPdfLabel, exportToXlsLabel } = getExportLabels(isOrder);
  const menu = [
    {
      label: exportToPdfLabel,
      id: 'exportCartToPdf',
      handler: () =>
        exportCart(studentId, isOrder, isECOrder, ecContract, false),
      isDisabled: isPdfExportDisabled,
      icon: icons.exportPdf,
    },
    {
      label: exportToXlsLabel,
      id: 'exportCartToXls',
      handler: () => exportCart(studentId, isOrder, isECOrder, ecContract),
      isDisabled: false,
      icon: icons.exportXls,
    },
    {
      label: appStrings.cart.menuDownloadTemplate,
      id: 'downloadTemplate',
      handler: downloadUploadTemplateHandler,
      isDisabled: false,
      icon: icons.download,
    },
    {
      label: appStrings.cart.menuImportExcel,
      id: 'importItemsFromExcel',
      handler: openFileSelector,
      isDisabled: isInactiveStudent,
      icon: icons.importExcel,
      customElements: () => (
        <input
          type="file"
          id="importFileFromExcelInput"
          accept={xmlTypes}
          style={{ display: 'none' }}
          ref={importRef}
          onInput={handleUploadFile}
        />
      ),
    },
  ];

  if (isOrder) {
    menu.push({
      label: appStrings.order.addItemsFromConsigned,
      id: 'addItemsFromConsigned',
      handler: () => {
        navigate(ORDER_CONSIGNED_PAGE(bp, id));
      },
      isDisabled: false,
      icon: icons.consigned,
    });
  }

  return menu;
};

export const cartTypes = {
  cart: 1,
  order: 2,
  saved: 3,
};

export const ecErrorTypes = {
  warning: 0,
  error: 1,
};

export const moveDialogInfo = {
  savedToCart: {
    title: appStrings.modal.moveSavedToCart.title,
    icon: icons.moveToCart,
  },
  cartToSaved: {
    title: appStrings.modal.moveCartToSaved.title,
    icon: icons.moveToSavedItems,
  },
  orderToSaved: {
    title: appStrings.modal.moveOrderToSaved.title,
    icon: icons.moveToSavedItems,
  },
  orderToCart: {
    title: appStrings.modal.moveOrderToCart.title,
    icon: icons.moveToCart,
  },
  savedToOrder: {
    title: appStrings.modal.moveSavedToOrder.title,
    icon: icons.moveToOrder,
  },
  cartToOrder: {
    title: appStrings.modal.moveCartToOrder.title,
    icon: icons.moveToOrder,
  },
};

export const moveFlowOptions = {
  savedToCart: 'savedToCart',
  cartToSaved: 'cartToSaved',
  orderToCart: 'orderToCart',
  orderToSaved: 'orderToSaved',
  savedToOrder: 'savedToOrder',
  cartToOrder: 'cartToOrder',
};

export const moveActionOptions = {
  [moveFlowOptions.savedToCart]: {
    from: cartTypes.saved,
    to: cartTypes.cart,
    successMessage: appStrings.cart.moveToCartSuccess,
  },
  [moveFlowOptions.cartToSaved]: {
    from: cartTypes.cart,
    to: cartTypes.saved,
    successMessage: appStrings.cart.moveToSavedSuccess,
  },
  [moveFlowOptions.orderToCart]: {
    from: cartTypes.order,
    to: cartTypes.cart,
    successMessage: appStrings.cart.moveToCartSuccess,
  },
  [moveFlowOptions.orderToSaved]: {
    from: cartTypes.order,
    to: cartTypes.saved,
    successMessage: appStrings.cart.moveToSavedSuccess,
  },
  [moveFlowOptions.savedToOrder]: {
    from: cartTypes.saved,
    to: cartTypes.order,
    successMessage: appStrings.cart.moveToOrderSuccess,
  },
  [moveFlowOptions.cartToOrder]: {
    from: cartTypes.cart,
    to: cartTypes.order,
    successMessage: appStrings.cart.moveToOrderSuccess,
  },
};

export const listOfPathsOrder = (bp, id, studentName, elementId) => [
  {
    link: ORDER(bp, id),
    label: `${appStrings.order.backTo} ${studentName} ${appStrings.common.order}`,
    elementId: elementId,
  },
];

export const getOrderTypeInputs = (
  onChange,
  accounts,
  promotions,
  setShowECAppModal
) => {
  return {
    ecOrder: {
      name: 'ecOrder',
      label: appStrings.order.selectDebtorNo,
      focusedLabel: appStrings.order.debtorNo,
      options: accounts,
      onChangeCallback: value => {
        if (value && !accounts.find(acc => acc.value === value)?.isOpenToBuy) {
          setShowECAppModal(true);
        }
        onChange(value);
      },
      type: 'select',
      gridLayout: { xs: 3 },
      validations: {
        required: true,
      },
    },
    promotion: {
      name: 'promotion',
      label: appStrings.order.selectPromotion,
      focusedLabel: appStrings.order.promotion,
      options: promotions,
      onChangeCallback: onChange,
      type: 'select',
      gridLayout: { xs: 3 },
      validations: {
        required: true,
      },
    },
  };
};

export const getCartType = (isOrder, isSavedItems) => {
  if (isOrder) {
    return cartTypes.order;
  }
  if (isSavedItems) {
    return cartTypes.saved;
  }
  return cartTypes.cart;
};
