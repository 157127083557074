import { Grid, Popper, useTheme } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { CATEGORY_ID } from '../../../../core/navigation/paths';
import noProductImg from '../../../../core/assets/img/noProduct-img.png';

function HoverMenu({
  hoverMenuRef,
  handleOpen,
  handleClose,
  open,
  subcategories,
  css,
}) {
  const theme = useTheme();

  const getProductImage = event => (event.target.src = noProductImg);

  return (
    <Popper
      id="hoverMenu"
      data-test-id="hoverMenu-testId"
      open={open}
      anchorEl={hoverMenuRef.current}
      className={css.hoverContainer}
      onMouseLeave={handleClose}
      onMouseEnter={handleOpen}
      sx={{ zIndex: 1 }}
    >
      <Grid container flexDirection="row" className="categories">
        {subcategories?.map((elem, index) => (
          <Grid
            className="category-container"
            item
            key={index}
            marginLeft={theme.spacing(2)}
          >
            <NavLink
              style={{ height: '100%' }}
              id={elem.name}
              to={CATEGORY_ID(elem.id)}
              onClick={handleClose}
            >
              <Grid
                item
                container
                height={theme.spacing(20)}
                direction="column"
                justifyContent="space-around"
                alignItems="center"
                paddingTop={theme.spacing(1)}
              >
                <Grid item>
                  <img src={elem.image} alt="" onError={getProductImage} />
                </Grid>
                <Grid item>
                  <p>{elem.name}</p>
                </Grid>
              </Grid>
            </NavLink>
          </Grid>
        ))}
      </Grid>
    </Popper>
  );
}

export default HoverMenu;
