import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CustomAlert from '../CustomAlert/CustomAlert';
import { alertTypes } from '../CustomAlert/customAlertConstants';
import appStrings from '../../../core/strings/appStrings';
import useInactiveNotificationStyles from './useInactiveNotificationStyles';

function InactiveNotification({ onHomeScreen }) {
  const css = useInactiveNotificationStyles().classes;

  return <CustomAlert
    messageType={alertTypes.error}
    messageTitle={appStrings.validationMessages.inactiveStudentMessage}
    customClass={classNames(css.inactiveAlert, onHomeScreen ? css.homeScreenNotification : '')}
  />
}

InactiveNotification.propTypes = {
  onHomeScreen: PropTypes.bool,
};

export default InactiveNotification;
