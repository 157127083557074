import { makeStyles } from 'tss-react/mui';

export const useStudentAchievementsStyles = makeStyles()(theme => ({
  imagesBox: {
    textAlign: 'center',
    paddingTop: theme.spacing(3),

    '& .MuiBox-root': {
      border: '1px solid',
      borderColor: theme.palette.common.grey4,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2, 2),
    },

    '& img': {
      height: '64px',
      width: '64px',
    },

    '& .MuiTypography-root': {
      paddingTop: theme.spacing(0.5),
    },
  },

  imageStyle: {
    padding: theme.spacing(0, 2, 2, 2),
    borderBottom: '1px solid',
    borderColor: theme.palette.common.red,
  },

  purchaseContent: {
    justifyContent: 'center',

    '& .MuiButtonBase-root': {
      border: 0,
      padding: 0,
      paddingTop: theme.spacing(2),
      color: theme.palette.text.primary,

      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    },

    '& .MuiSvgIcon-root': {
      transform: 'scaleX(-1)',
    },
  },

  disabledFilter: {
    filter: 'grayscale(100%)',
    opacity: '16%',
  },

  checkIcon: {
    color: theme.palette.common.red,
  },

  lockIcon: {
    backgroundColor: theme.palette.common.grey5,
    borderRadius: theme.spacing(2),
    padding: '2px',

    '& path': {
      color: theme.palette.common.grey1,
    },
  },
}));
