import { Fragment, useState } from 'react';
import {
  buttonColors,
  buttonTypes,
} from '../../../../core/strings/appConstants';

import CustomBox from '../../CustomBox/CustomBox';
import FormInput from '../../FormInput/FormInput';
import { Grid } from '@mui/material';
import appStrings from '../../../../core/strings/appStrings';
import classNames from 'classnames';
import useEmployerStyles from './useEmployerInformationStyles';
import DeleteButton from './DeleteButton';

function InputsTemplate({
  title,
  inputs,
  formProps,
  handleDelete,
  deleteLabel,
  containerIndex,
  id,
  isStudent,
}) {
  const css = useEmployerStyles().classes;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteButtons = [
    {
      btnType: buttonTypes.secondary,
      label: appStrings.common.cancel,
      onClick: handleClose,
    },
    {
      btnType: buttonTypes.primary,
      color: buttonColors.red,
      label: appStrings.common.remove,
      onClick: handleDelete,
    },
  ];

  const isPreviousTitle =
    title === appStrings.studentProfile.previousEmployerNumber;
  const idHelper =
    deleteLabel.replaceAll(' ', '') +
    (containerIndex >= 0 ? containerIndex : '');

  const deleteBtnProps = {
    deleteLabel,
    handleClickOpen,
    idHelper,
    open,
    handleClose,
    deleteButtons,
    isStudent,
  };

  return inputs?.length ? (
    <>
      <Grid item container alignItems="flex-end">
        <Grid item xs>
          <h3
            data-test-id="employersInputSectionTitle"
            className={classNames(css.title, {
              [css.previousAddressTitle]: isPreviousTitle,
            })}
            id={id + '-title'}
          >
            {title}
          </h3>
        </Grid>
        <Grid item className={css.title}>
          {isStudent && <DeleteButton {...deleteBtnProps} />}
        </Grid>
      </Grid>

      <CustomBox customClass={css.employerContainer} fadeBorder id={id}>
        <Grid container direction="row" spacing={2}>
          {inputs.map((field, index) => (
            <Fragment key={`employerField-${index}`}>
              {field.label === appStrings.studentProfile.years && !isStudent ? (
                <Grid
                  item
                  {...field.gridLayout}
                  key={`${field.name}-emptyContainer`}
                ></Grid>
              ) : null}
              {field.type === 'hidden' ? (
                <FormInput
                  field={field}
                  {...formProps}
                  size={isStudent && 'medium'}
                />
              ) : (
                <Grid
                  item
                  {...field.gridLayout}
                  key={`${field.name}-container`}
                  data-test-id={field.name}
                >
                  <FormInput
                    field={field}
                    {...formProps}
                    size={isStudent && 'medium'}
                  />
                </Grid>
              )}
            </Fragment>
          ))}
        </Grid>
      </CustomBox>

      {!isStudent && <DeleteButton {...deleteBtnProps} />}
    </>
  ) : null;
}

export default InputsTemplate;
