import ApiService from '../../shared/Api/apiService';
import urls from '../../../core/strings/urls';

export const getItemAvailability = (bpNumber, productNumber, quantity) => {
  return ApiService.post(urls.getItemAvailability, {
    productNumber,
    quantity: Number(quantity),
    bpNumber,
  });
};
