const primaryColor = 'rgba(99, 117, 140, 1)',
  secondaryColor = 'rgba(93, 121, 128, 1)';

const lightTheme = {
  palette: {
    common: {
      primary: primaryColor,
      primary1: 'rgba(175, 184, 195, 1)',
      primary2: 'rgba(159, 170, 184, 1)',
      primary3: 'rgba(144, 157, 173, 1)',
      primary4: 'rgba(129, 144, 162, 1)',
      primary5: 'rgba(75, 93, 115, 1)',

      secondary: secondaryColor,
      secondary1: 'rgba(172, 186, 189, 1)',
      secondary2: 'rgba(156, 173, 177, 1)',
      secondary3: 'rgba(140, 160, 165, 1)',
      secondary4: 'rgba(124, 147, 152, 1)',
      secondary5: 'rgba(234, 237, 238, 1)',
      secondary6: 'rgba(219, 224, 226, 1)',
      secondary7: 'rgba(65, 97, 105, 1)',

      yellow: 'rgba(212, 176, 17, 1)',
      darkYellow: 'rgba(172, 143, 14, 1)',
      red: 'rgba(255, 0, 0, 1)',
      darkRed: 'rgba(189, 20, 39, 1)', // not part of style guide, to be removed when all buttons will be replaced with new style
      green: 'rgba(2, 134, 8, 1)',
      darkGreen: 'rgba(2, 95, 7, 1)',
      blue: 'rgba(52, 85, 203, 1)',
      darkBlue: 'rgba(50, 50, 190, 1)',
      orange: 'rgba(206, 77, 4, 1)',

      fadeYellow: 'rgba(212, 176, 17, 0.2)',
      fadeDarkYellow: 'rgba(172, 143, 14, 0.5)',
      fadeRed: 'rgba(218, 16, 11, 0.2)',
      fadeRed1: 'rgba(218, 16, 11, 0.5)',
      fadeRed2: 'rgba(241, 82, 67, 1)',
      fadeGreen: 'rgba(2, 134, 8, 0.2)',
      fadeGreen1: 'rgba(2, 134, 8, 0.5)',
      fadeBlue: 'rgba(52, 85, 203, 0.2)',
      fadeDarkBlue: 'rgba(50, 50, 190, 0.5)',
      blue1: 'rgba(0, 62, 99, 1)',

      neutral1: 'rgba(255, 255, 255, 1)',
      neutral2: 'rgba(250, 250, 250, 1)',
      neutral3: 'rgba(241, 242, 244, 1)',
      neutral4: 'rgba(241, 241, 241, 1)',
      neutral5: 'rgba(231, 235, 240, 1)',
      neutral6: 'rgba(224, 232, 242, 1)',
      neutral7: 'rgba(197, 220, 250, 0.4)',
      neutral8: 'rgba(12, 53, 107, 0.1)',
      neutralRed: 'rgba(247, 227, 226, 1)',
      neutralRed1: 'rgba(227, 186, 185, 1)',
      neutralGreen: 'rgba(225, 238, 226, 1)',
      neutralYellow: 'rgba(242, 239, 226, 1)',
      neutralBlue: 'rgba(207, 226, 243, 1)',

      shadow: 'rgba(221, 221, 221, 0.4)',
      veryLightGrey: 'rgba(199, 199, 199, 1)',
      lightGrey: 'rgba(192, 192, 192, 1)',
      grey: 'rgba(161, 161, 161, 1)',
      fadeGrey: 'rgba(161, 161, 161, 0.4)',
      darkGrey: 'rgba(65, 65, 65, 1)',
      grey1: 'rgba(97, 97, 97, 1)',
      grey2: 'rgba(146, 148, 151, 1)',
      grey4: 'rgba(229, 229, 229, 1)',
      grey5: 'rgba(238, 238, 238, 1)',
      grey6: 'rgba(250, 250, 250, 1)',
      inactive: 'rgb(228, 53, 29)',
      inactiveHover: 'rgb(228, 53, 29)',
    },
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    type: 'light',
    background: {
      default: 'rgba(246, 246, 246, 1)',
      paper: 'rgba(255, 255, 255, 1)',
    },
    text: {
      primary: 'rgba(0, 0, 0, 1)',
      secondary: 'rgba(97, 97, 97, 1)',
    },
    shadows: {
      activeButton: '0px 1px 1px 0px',
    },
  },
  typography: {
    fontFamily: 'Roboto',
    fontSize: 14,
    htmlFontSize: 14,
    logo: {
      fontSize: 40,
    },
    h1: {
      fontSize: '24px',
      lineHeight: '36px',
    },
    h2: {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: 400,
    },
    h3: {
      fontSize: '16px',
      lineHeight: '22px',
    },
    body1: {
      fontSize: '14px',
    },
  },
};

export default lightTheme;
