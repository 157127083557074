import { Chip, Divider, IconButton, InputAdornment } from '@mui/material';
import {
  defaultFilters,
  updateFiltersRestore,
  updateSelectedItemsRestore,
} from '../../../../core/redux/slices/restorePurchasesSlice';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import appStrings from '../../../../core/strings/appStrings';
import { buttonTypes } from '../../../../core/strings/appConstants';
import { restoreFiltersOptions } from '../restorePurchasesConstants';
import useRestorePurchaseStyles from '../useRestorePurchaseStyles';

function RestoreFilters() {
  const dispatch = useDispatch();
  const css = useRestorePurchaseStyles().classes;
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [shouldUpdateOptions, setShouldUpdateOptions] = useState(false);
  const [isFilterDisabled, setIsFilterDisabled] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState(false);
  const { filters } = useSelector(state => state.restorePurchases);
  const [hasFiltersOptions, setHasFiltersOptions] = useState(false);
  const [selectedFilterOption, setSelectedFilterOption] = useState(
    restoreFiltersOptions(filters)[0]
  );

  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectNextAvailableOption = useCallback(() => {
    let nextAvailableIndex = null;

    restoreFiltersOptions(filters).forEach((option, index) => {
      if (nextAvailableIndex === null && !option.disabled) {
        nextAvailableIndex = index;
      }
    });

    setIsFilterDisabled(nextAvailableIndex === null);

    if (nextAvailableIndex !== selectedIndex) {
      setSelectedIndex(nextAvailableIndex || 0);
    }

    setShouldUpdateOptions(false);
  }, [filters, selectedIndex]);

  const isFieldValid = useCallback(() => {
    const currentFieldValidation = selectedFilterOption.fieldValidation;
    let isValid = true;

    if (inputValue?.trim().length) {
      if (
        currentFieldValidation.pattern &&
        !currentFieldValidation.pattern.test(inputValue)
      ) {
        isValid = false;
      }

      if (
        currentFieldValidation.maxLength &&
        inputValue.length > currentFieldValidation.maxLength
      ) {
        isValid = false;
      }

      if (
        currentFieldValidation.minLength &&
        inputValue.length < currentFieldValidation.minLength
      ) {
        isValid = false;
      }
    }

    setInputError(isValid === false);
    return isValid;
  }, [inputValue, selectedFilterOption.fieldValidation]);

  const handleSearch = () => {
    if (inputValue?.trim().length && isFieldValid()) {
      dispatch(
        updateFiltersRestore({
          ...filters,
          [selectedFilterOption.value]: inputValue.trim(),
          page: {
            ...filters.page,
            pageNumber: 0,
          },
        })
      );

      setShouldUpdateOptions(true);
    }
  };

  const handleMenuItemClick = index => {
    setSelectedIndex(index);
    setOpen(false);

    if (
      !inputValue.length ||
      inputValue === restoreFiltersOptions(filters)[2].defaultValue
    ) {
      setInputValue(selectedFilterOption.defaultValue);
    }
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleDeleteChip = option => {
    dispatch(updateFiltersRestore({ ...filters, [option.value]: null }));
    setShouldUpdateOptions(true);
  };

  const deleteAllFilters = () => {
    dispatch(
      updateFiltersRestore({
        ...defaultFilters,
      })
    );
    setShouldUpdateOptions(true);
  };

  useEffect(() => {
    setHasFiltersOptions(() => {
      let nextValue = false;

      Object.keys(filters).forEach(key => {
        if (typeof filters[key] === 'string' && filters[key].length) {
          nextValue = true;
        }
      });

      return nextValue;
    });
  }, [filters]);

  useEffect(() => {
    if (shouldUpdateOptions) {
      selectNextAvailableOption();
      dispatch(updateSelectedItemsRestore({ reset: true }));
    }
  }, [dispatch, filters, selectNextAvailableOption, shouldUpdateOptions]);

  useEffect(() => {
    const nextValue = restoreFiltersOptions(filters)[selectedIndex];
    setSelectedFilterOption(nextValue);
    setInputValue(nextValue.defaultValue);
  }, [filters, selectedIndex]);

  useEffect(() => {
    isFieldValid();
  }, [inputValue, isFieldValid]);

  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      spacing={2}
      data-test-id="restorePurchasesFilters"
    >
      <Grid item xs={12}>
        <Paper
          ref={anchorRef}
          style={{ pointerEvents: isFilterDisabled ? 'none' : 'auto' }}
          className={css.restoreFilterContainer}
        >
          {/* Dropdown */}
          <Grid container alignItems="center">
            <Grid item>
              <Button
                color="primary"
                size="small"
                aria-controls={open ? 'filterTypeMenu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="menu"
                onClick={handleToggle}
                id="restoreFiltersDropdown"
                className={css.restoreFilterDropdown}
                disabled={isFilterDisabled}
              >
                <Typography>{selectedFilterOption.label}</Typography>
                <ExpandMoreIcon />
              </Button>
            </Grid>

            {/* Input */}
            <Divider
              orientation="vertical"
              flexItem
              className={css.filtersDivider}
            />
            <Grid item>
              <InputBase
                className={css.filterInput}
                disabled={isFilterDisabled}
                id="restoreFiltersInput"
                name="restoreFiltersInput"
                placeholder={`${appStrings.searchInputs.search}...`}
                error={inputError}
                inputProps={{
                  'aria-label': 'search value',
                  ...selectedFilterOption.fieldValidation,
                  'data-test-id': 'restoreFiltersInput',
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
                onChange={e => setInputValue(e.target.value)}
                value={inputValue}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear search value"
                      className={css.filterClearIcon}
                      disabled={!inputValue.length}
                      onClick={() =>
                        inputValue.length &&
                        setInputValue(selectedFilterOption.defaultValue)
                      }
                      size="large"
                    >
                      {inputValue.length ? <ClearIcon /> : null}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
            {/* Search icon */}
            <Divider
              orientation="vertical"
              flexItem
              className={css.filtersDivider}
            />
            <Grid item>
              <Button
                onClick={handleSearch}
                disabled={inputError}
                className={css.filterSearchBtn}
                data-test-id="searchRestoreFilter"
                id="searchRestoreFilter"
              >
                <SearchIcon />
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {hasFiltersOptions ? (
        <Grid item xs={12} container spacing={2}>
          {restoreFiltersOptions(filters).map(option =>
            filters[option.value]?.length ? (
              <Grid item key={option.value + 'Chips'}>
                <Chip
                  onDelete={() => handleDeleteChip(option)}
                  label={`${option.label}: ${filters[option.value]}`}
                  id={option.value + 'Chips'}
                  date-test-id={option.value + 'Chips'}
                  deleteIcon={<ClearIcon />}
                  className={css.filterChips}
                />
              </Grid>
            ) : null
          )}

          <Grid item>
            <CustomButton
              label={appStrings.searchInputs.clearAll}
              btnType={buttonTypes.tertiary}
              onClick={deleteAllFilters}
              customClass={css.clearAllBtn}
            />
          </Grid>
        </Grid>
      ) : null}

      {/* dropdown options */}

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        style={{ zIndex: 1 }}
        placement="bottom-start"
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList id="filterTypeMenu">
              {restoreFiltersOptions(filters).map((option, index) => (
                <MenuItem
                  key={option.value}
                  selected={index === selectedIndex}
                  onClick={() => handleMenuItemClick(index)}
                  disabled={option.disabled}
                  id={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </Grid>
  );
}

export default RestoreFilters;
