import { APPROVE_REJECT, STUDENTS } from '../../../../core/navigation/paths';
import { Grid, Link, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  createSearchInputValue,
  studentsSearch,
  getSearchedStudents,
} from './../studentSearchService';
import {
  getHasSearchData,
  getListOfAllStudents,
  sortByName,
} from '../searchUtils';
import {
  resetSelectedStudent,
  setSchoolListRep,
  updateSchoolList,
} from '../../../../core/redux/slices/schoolsWithStudentsSlice';
import { saveStudentsListApproveReject } from '../../../../core/redux/slices/approveRejectSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ADMIN } from '../../../../core/navigation/roles';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import CustomIcon from '../../../shared/CustomIcon/CustomIcon';
import CustomTableSkeleton from '../../../shared/CustomSkeleton/CustomTableSkeleton';
import FilteredStudentsList from '../FilteredStudentsList/FilteredStudentsList';
import NoDataAvailable from '../../../shared/NoDataAvailable/NoDataAvailable';
import RepSearch from '../../../industrial/RepSearch/RepSearch';
import StudentSearch from '../StudentSearch';
import appStrings from '../../../../core/strings/appStrings';
import { columns } from './../FilteredStudentsList/filteredStudentsListConstants';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import { getSchools } from '../../SchoolsList/Services/studentListService';
import { getStudentsList } from '../../ApproveReject/approveRejectService';
import { icons } from '../../../../core/strings/icons';
import { resetSearchInputs } from '../../../../core/redux/slices/studentSearchSlice';
import { shouldGetStudents } from './listSearchResultService';
import useListSearchResultStyles from './useListSearchResultStyles';
import Filters from '../../ApproveReject/Filters/Filters';

function ListSearchResult() {
  let { approveReject } = useParams();
  approveReject = approveReject === 'true';
  const css = useListSearchResultStyles().classes;
  const [isLoading, setLoading] = useState(true);
  const [isSearchApiInProgress, setIsSearchApiInProgress] = useState(false);
  const [students, setStudents] = useState([]);
  const dispatch = useDispatch();
  const searchInputs = useSelector(state => state.studentSearch.searchInputs);
  const listToSearchIn = useSelector(state =>
    approveReject
      ? state.approveReject.studentsList
      : state.schoolsWithStudents.schools
  );
  const { employeeId, role } = useSelector(state => state.login.userDetails);
  const isAdmin = role === ADMIN;

  const navigate = useNavigate();

  const approveRejectRep = useSelector(
    state => state.approveReject.approveRejectRep
  );
  const schoolListSelectedRep = useSelector(
    state => state.schoolsWithStudents.schoolListSelectedRep
  );
  const schoolsWithStudentsState = useSelector(
    state => state.schoolsWithStudents
  );
  const approveRejectState = useSelector(state => state.approveReject);
  const AllReps = appStrings.searchInputs.allReps;
  const selectedRepValue = approveReject
    ? approveRejectRep
    : schoolListSelectedRep;
  const finalId =
    isAdmin && selectedRepValue !== AllReps ? selectedRepValue : employeeId;

  const handleGoBack = () => {
    dispatch(resetSearchInputs());
    navigate(approveReject ? APPROVE_REJECT : STUDENTS(employeeId));
  };

  useEffect(() => {
    if (
      finalId &&
      (!listToSearchIn ||
        shouldGetStudents(
          finalId,
          isAdmin,
          approveReject,
          schoolsWithStudentsState.employeeIdSearched,
          approveRejectState.employeeIdSearched
        ))
    ) {
      if (!approveReject) {
        setLoading(true);
        getSchools({
          id: finalId,
          AdminSchools: isAdmin && selectedRepValue === AllReps,
        })
          .then(res => {
            dispatch(updateSchoolList({ res: res, employeeId: finalId }));
          })
          .catch(e => dispatch(displayError({ message: e })))
          .finally(() => setLoading(false));
      } else {
        setLoading(true);
        getStudentsList(finalId)
          .then(resp => {
            dispatch(
              saveStudentsListApproveReject({
                resp: resp,
                employeeIdSearched: finalId,
              })
            );
          })
          .catch(e => dispatch(displayError({ message: e })))
          .finally(() => setLoading(false));
      }
      dispatch(resetSelectedStudent());
    }
  }, [
    AllReps,
    approveReject,
    approveRejectState.employeeIdSearched,
    dispatch,
    finalId,
    isAdmin,
    listToSearchIn,
    schoolsWithStudentsState.employeeIdSearched,
    selectedRepValue,
  ]);

  useEffect(() => {
    const searchData = getHasSearchData(searchInputs);
    if (
      !(isAdmin && selectedRepValue === AllReps) &&
      listToSearchIn &&
      searchInputs &&
      (getHasSearchData(searchInputs).hasSearchData ||
        searchData.studentApprovedStatus ||
        searchInputs.studentStatus)
    ) {
      setStudents(studentsSearch(listToSearchIn, searchInputs, approveReject));
      setLoading(false);
    }
  }, [
    AllReps,
    approveReject,
    isAdmin,
    listToSearchIn,
    searchInputs,
    selectedRepValue,
  ]);

  useEffect(() => {
    if (!searchInputs) {
      return;
    }

    if (
      isAdmin &&
      selectedRepValue === AllReps &&
      !isSearchApiInProgress &&
      getHasSearchData(searchInputs).hasSearchData
    ) {
      setIsSearchApiInProgress(true);
      setLoading(true);

      getSearchedStudents(searchInputs, approveReject, setLoading)
        .then(resp => {
          const result = getListOfAllStudents(resp);
          setStudents(result.sort(sortByName));
        })
        .catch(e => dispatch(displayError({ message: e })))
        .finally(() => {
          setIsSearchApiInProgress(false);
          setLoading(false);
        });
    }

    if (
      isAdmin &&
      selectedRepValue === AllReps &&
      !getHasSearchData(searchInputs).hasSearchData &&
      searchInputs.studentStatus
    ) {
      dispatch(resetSearchInputs());
      navigate(STUDENTS(employeeId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputs, selectedRepValue]);

  useEffect(() => {
    return () => {
      dispatch(resetSearchInputs());
    };
  }, [dispatch]);

  return (
    <Grid container direction="column">
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Typography variant="h2">
            <strong>{appStrings.searchInputs.searchResult}</strong>
          </Typography>
        </Grid>
        <Grid item xs={9}>
          {!approveReject && (
            <Grid container spacing={2} justifyContent="flex-end">
              {isAdmin && (
                <Grid item xs={6}>
                  <RepSearch
                    isLoading={isLoading}
                    selectedRep={schoolListSelectedRep}
                    setSelectedRep={setSchoolListRep}
                    setLoading={setLoading}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <StudentSearch approveReject={approveReject} />
              </Grid>
            </Grid>
          )}
          {approveReject && (
            <Filters
              isAdmin={isAdmin}
              setIsLoading={setLoading}
              isLoading={isLoading}
            />
          )}
        </Grid>
      </Grid>
      {isLoading && (
        <Grid item xs={12} className={css.studentsTableContainer}>
          <CustomTableSkeleton
            columns={columns(approveReject)}
            columnsNumber={4}
            columnXs={3}
          />
        </Grid>
      )}

      {!isLoading && students?.length ? (
        <Grid
          item
          xs={12}
          data-test-id="searchResultListed"
          className={css.studentsTableContainer}
        >
          <FilteredStudentsList
            students={students}
            approveReject={approveReject}
          />
        </Grid>
      ) : null}

      {!isLoading && !students?.length ? (
        <Grid item xs={12}>
          <CustomBox customClass={css.noDataContainer}>
            <NoDataAvailable
              icon={icons.noResults}
              message={appStrings.searchInputs.getNoSearchResultMessage(
                createSearchInputValue(searchInputs)
              )}
              helperText={
                <Grid container className={css.tipsContainer}>
                  <Grid item xs={12} className={css.blackText}>
                    <CustomIcon icon="searchTips" />
                    <Typography className={css.tipsText}>
                      <strong>{appStrings.searchInputs.searchTips}:</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={css.tipsList}>
                    {appStrings.searchInputs.searchTipsList.map(
                      (message, i) => (
                        <li key={`searchTips-${i}`}>{message}</li>
                      )
                    )}
                  </Grid>
                  <Grid item xs={12} className={css.blackText}>
                    <Typography>
                      <strong>
                        {appStrings.searchInputs.or}{' '}
                        <Link onClick={handleGoBack} className={css.link}>
                          {appStrings.searchInputs.goBack}
                        </Link>
                      </strong>
                    </Typography>
                  </Grid>
                </Grid>
              }
            />
          </CustomBox>
        </Grid>
      ) : null}
    </Grid>
  );
}

export default ListSearchResult;
