import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import NotificationSnackbar from './NotificationSnackbar';
import { notificationRole } from '../../../../core/strings/appConstants';
import useMainContainerStyles from '../../MainContainer/useMainContainerStyles';

function NotificationsViewIndustrial() {
  const css = useMainContainerStyles().classes;
  const industrialNotifications = useSelector(
    state => state.notifications.industrial
  );

  return industrialNotifications.length ? (
    <div
      className={classNames(
        css.notificationsContainer,
        css.notificationsContainerIndustrial
      )}
    >
      {industrialNotifications.map((item, index) => (
        <NotificationSnackbar
          {...item}
          role={notificationRole.industrial}
          key={`notification${index}`}
          index={index}
        />
      ))}
    </div>
  ) : null;
}

export default NotificationsViewIndustrial;
