import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import appStrings from '../../../core/strings/appStrings';
import { formatAddressAsText } from '../Checkout/checkoutService';
import { useOrderDetailsStyles } from './orderDetailsStyles';
import { setOrderDetails } from './orderDetailsServices';
import { getOrderDetailsHistory } from '../../industrial/OrderHistoryDetails/orderDetailsHistoryService';
import { displayError } from '../../../core/redux/slices/notificationsSlice';
import { notificationRole } from '../../../core/strings/appConstants';
import classNames from 'classnames';
import OrderItems from './Components/OrderItems';
import OrderSummary from './Components/OrderSummary';
import OrderContent from './Components/OrderContent';
import OrderDetailsSkeleton from './Skeletons/OrderDetailsSkeleton';

function OrderDetails() {
  const css = useOrderDetailsStyles().classes;
  const dispatch = useDispatch();
  const isSmallDevice = useMediaQuery('(max-width:768px)');
  const { userId } = useSelector(state => state.login.userDetails);
  const { orderNo } = useParams();
  const [loading, setLoading] = useState(false);
  const [headerData, setHeaderData] = useState({
    number: '',
    status: '',
    type: '',
    lnOrderNumber: null,
  });
  const [addresses, setAddresses] = useState({
    shippingAddress: {},
    billingAddress: {},
  });
  const [orderItems, setOrderItems] = useState([]);
  const [orderTotals, setOrderTotals] = useState({
    subtotal: '',
    tax: '',
    shippingHandling: '',
    sepSavings: '',
    total: '',
  });

  const addressSection = addressName => (
    <Grid
      item
      xs={12}
      sm={5}
      md={4}
      lg={3}
      id={`OrderDetails-${addressName}`}
      data-test-id={`OrderDetails-${addressName}TestId`}
    >
      <Stack width="80%" spacing={1}>
        <Typography>
          <strong>{appStrings.addresses[addressName]}</strong>
        </Typography>
        <Typography>
          {formatAddressAsText({
            address: addresses[addressName]?.address || {},
          })}
        </Typography>
        <Typography fontWeight="medium">{`${addresses[addressName]?.phone}, ${addresses[addressName]?.email}`}</Typography>
      </Stack>
    </Grid>
  );

  useEffect(() => {
    if (userId && orderNo) {
      setLoading(true);
      getOrderDetailsHistory(orderNo, userId)
        .then(resp =>
          setOrderDetails({
            setHeaderData,
            setAddresses,
            setOrderItems,
            setOrderTotals,
            response: resp,
          })
        )
        .catch(() => dispatch(displayError({ role: notificationRole.student })))
        .finally(() => setLoading(false));
    }
  }, [dispatch, orderNo, userId]);

  return loading ? (
    <OrderDetailsSkeleton />
  ) : (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        className={classNames(css.boxContainer, css.containerPadding)}
      >
        <OrderContent
          orderData={headerData}
          content={
            <Grid
              item
              container
              spacing={2}
              paddingTop={2}
              justifyContent="space-between"
            >
              {addressSection('shippingAddress')}
              {addressSection('billingAddress')}
            </Grid>
          }
        />
      </Grid>
      <Grid item container spacing={2} className={css.boxContainer}>
        <Grid item xs={12} sm={isSmallDevice ? 12 : 7} md={7}>
          <OrderItems items={orderItems} />
        </Grid>
        <Grid item xs={12} sm={isSmallDevice ? 12 : 5} md={5}>
          <OrderSummary totals={orderTotals} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default OrderDetails;
