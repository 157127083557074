import { Collapse, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import useLoginStyles from '../useLoginStyles';

const propTypes = {
  error: PropTypes.string,
  handlerOnCountDown: PropTypes.func,
};

function LoginErrors({ error, handlerOnCountDown }) {
  const [intervalId, setIntervalId] = useState(null);
  const [receivedError, setReceivedError] = useState(null);
  const css = useLoginStyles().classes;
  const counterString = '{counter}';
  const [isCounterOn, setIsCounterOn] = useState(false);
  const counterMaxValue = 21;

  const counter = useRef(0);

  useEffect(() => {
    if (error && error !== null) {
      if (error?.includes(counterString)) {
        counter.current = counterMaxValue;
        setIsCounterOn(true);
      } else {
        setReceivedError(error);
      }
    }
  }, [error]);

  useEffect(() => {
    if (error && isCounterOn) {
      const newIntervalId = setInterval(() => {
        counter.current = counter.current - 1;

        if (counter.current === 0) {
          setIntervalId(prevIntervalId => {
            clearInterval(prevIntervalId);
            setIsCounterOn(false);
            return null;
          });
        }

        setReceivedError(error.replace(counterString, counter.current));
      }, 1000);

      setIntervalId(newIntervalId);
    }
  }, [error, isCounterOn]);

  useEffect(() => {
    if (handlerOnCountDown) {
      handlerOnCountDown({
        countDownInProgress: intervalId !== null,
      });
    }
  }, [handlerOnCountDown, intervalId]);

  return (
    <Collapse in={Boolean(error)} timeout={2000}>
      <Typography
        variant="subtitle2"
        display="block"
        gutterBottom
        className={css.errorMessage}
      >
        {receivedError}
      </Typography>
    </Collapse>
  );
}

LoginErrors.propTypes = propTypes;

export default LoginErrors;
