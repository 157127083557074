import { Grid, Typography } from '@mui/material';

import CustomBreadcrumbs from '../../../shared/CustomBreadcrumbs/CustomBreadcrumbs';
import CustomDropDownMenu from '../../../shared/CustomDropDownMenu/CustomDropDownMenu';
import appStrings from '../../../../core/strings/appStrings';
import { exportInventory } from '../consignedInventoryService';
import { icons } from '../../../../core/strings/icons';
import { listOfPathsOrder } from '../../Cart/cartConstants';
import useConsignedInventoryStyles from '../useConsignedInventoryStyles';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ConsignedInventoryHeader({ isOrderPage }) {
  const css = useConsignedInventoryStyles().classes;
  const { selectedInventoryCode, selectedInventoryName } = useSelector(
    state => state.consignedInventory
  );
  const { bp, id } = useParams();
  const student = useSelector(
    state => state.schoolsWithStudents.selectedStudent.student
  );
  const studentName = `${student?.firstName} ${student?.lastName}`;

  const exportSelectedInventory = (isExcel = true) => {
    exportInventory(selectedInventoryCode, selectedInventoryName, isExcel);
  };

  const exportOptions = [
    {
      icon: icons.exportPdf,
      id: 'exportCartToPdf',
      isDisabled: false,
      label: appStrings.consigned.exportReportToPdf,
      handler: () => exportSelectedInventory(false),
    },
    {
      icon: icons.exportXls,
      id: 'exportCartToXlsx',
      isDisabled: false,
      label: appStrings.consigned.exportReportToXlsx,
      handler: () => exportSelectedInventory(),
    },
  ];

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      data-test-id="consignedHeaderComponent"
    >
      <Grid item>
        {isOrderPage ? (
          <CustomBreadcrumbs
            listOfPaths={listOfPathsOrder(bp, id, studentName)}
            currentPath={{
              label: appStrings.consigned.addFromWarehouseToOrder,
            }}
          />
        ) : (
          <Typography variant="h2" className={css.title}>
            {appStrings.consigned.consignedInventoryReport}
          </Typography>
        )}
      </Grid>
      <Grid item className={css.exportBtnContainer}>
        <CustomDropDownMenu
          menuId="exportConsignedInventoryDropDown"
          menuItems={exportOptions}
          menuLabel={appStrings.common.export}
          menuIcon={icons.export}
        />
      </Grid>
    </Grid>
  );
}

export default ConsignedInventoryHeader;
