import { Grid, Typography } from '@mui/material';
import React from 'react';
import appStrings from '../../../../core/strings/appStrings';
import { cartTotalsPrices } from '../shoppingCartConstants';
import PropTypes from 'prop-types';

function CartTotalsMobile({ totalsButtons, isLoggedIn, totals }) {
  return (
    <Grid container spacing={1} id="ShoppingCart-cartTotalsMobile">
      <Grid item container xs={12}>
        <Grid item id={!isLoggedIn ? 'subtotalCart' : 'orderTotalCart'}>
          {!isLoggedIn ? (
            <Typography data-test-id="subtotalCart">
              {appStrings.cart.subtotal}
            </Typography>
          ) : (
            <Typography fontWeight="bold" data-test-id="orderTotalCart">
              {cartTotalsPrices.sectionTwo[1].label}
            </Typography>
          )}
        </Grid>
        <Grid item id="grandTotalCart">
          <Typography fontWeight={isLoggedIn && 'bold'}>
            {totals.grandTotal}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {totalsButtons()}
      </Grid>
    </Grid>
  );
}

CartTotalsMobile.propTypes = {
  totalsButtons: PropTypes.elementType,
  isLoggedIn: PropTypes.bool,
  totals: PropTypes.object,
};

export default CartTotalsMobile;
