import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Link } from '@mui/material';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import appStrings from '../../../../core/strings/appStrings';
import { Description } from '@mui/icons-material';

function UserManual({manualUrl}) {
  return (
    <Grid data-test-id="renderedManual" id="productPageManualContainer" container>
      <Link id="userManualLink" href={manualUrl} target="_blank" rel="noopener noreferrer">
        <CustomButton
          btnType="secondary"
          startIcon={<Description />}
          label={appStrings.productPage.downloadManual}
        />
      </Link>
    </Grid>
  );
}

UserManual.propTypes = {
  manualUrl: PropTypes.string
}

export default UserManual;