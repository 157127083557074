import {
  dateFormat,
  phoneMask,
  snaponDomain,
} from '../../../../core/strings/appConstants';
import {
  digitPattern,
  emailPattern,
  nameStringPattern,
} from '../../../../core/strings/regexValidations';
import {
  maritalStatusOptions,
  phoneTypeOptions,
} from '../../../../core/strings/options';

import appStrings from '../../../../core/strings/appStrings';
import { gridLayout, studentGridLayout } from './customerInformationConstants';
import { statesOptionsSortedByLabel } from '../../../../core/strings/states';
import { xYearsInPast } from '../../../../core/services/utilsService';

const onDriverLicenseEmpty = ({
  getValues,
  setValue,
  setDisableDriverLicenseState,
}) => {
  const isDriverLicenseNrEmpty = Boolean(
    getValues('driverLicenseNumber').trim()
  );
  if (isDriverLicenseNrEmpty) {
    setDisableDriverLicenseState(false);
  } else {
    setValue('driverLicenseState', '', { shouldDirty: true });
    setDisableDriverLicenseState(true);
  }
};

const onPhoneNumberEmpty = ({ index, setDisable, setValue, getValues }) => {
  const hasPhoneNumber = Boolean(getValues(`phone${index}.phoneNumber`));
  if (hasPhoneNumber) {
    setDisable(false);
  } else {
    if (index === 2) {
      setValue(`phone2.phoneType`, '', { shouldDirty: true });
    }
    setDisable(true);
  }
};

const phoneInputs = (
  index,
  disable,
  setDisable,
  setValue,
  getValues,
  isStudent
) => [
  {
    name: `phone${index}.id`,
    label: `${appStrings.studentProfile.phoneNumber} ${index} id`,
    type: 'hidden',
    gridLayout: isStudent ? studentGridLayout : gridLayout,
  },
  {
    name: `phone${index}.phoneNumber`,
    label: `${appStrings.studentProfile.phoneNumber} ${index}`,
    type: 'tel',
    validations: {
      maxLength: 10,
      minLength: 10,
      required: index === 1,
      pattern: digitPattern,
    },
    gridLayout: isStudent ? studentGridLayout : gridLayout,
    helperText: appStrings.validationMessages.xDigitsOnly(10),
    maskFormat: phoneMask,
    onChangeCallback: () =>
      onPhoneNumberEmpty({ index, setDisable, setValue, getValues }),
  },
  {
    name: `phone${index}.phoneType`,
    label: appStrings.studentProfile.phoneType(index),
    type: 'select',
    validations: {
      required: index === 1,
    },
    options: phoneTypeOptions,
    gridLayout: isStudent ? studentGridLayout : gridLayout,
    disabled: disable,
  },
  {
    name: `phone${index}.isDeleted`,
    label: `phone${index} isDeleted`,
    type: 'hidden',
    gridLayout: isStudent ? studentGridLayout : gridLayout,
    defaultValue: false,
  },
];

// TODO still renders multiple times
export const customerInfoInputs = data => {
  const {
    getValues,
    setValue,
    disableDriverLicenseState,
    setDisableDriverLicenseState,
    disablePhoneType1,
    setDisablePhoneType1,
    disablePhoneType2,
    setDisablePhoneType2,
    isStudent,
  } = data;

  return [
    {
      name: 'firstName',
      label: appStrings.common.firstName,
      type: 'text',
      validations: {
        maxLength: 25,
        pattern: nameStringPattern,
        required: true,
      },
      gridLayout: isStudent ? studentGridLayout : gridLayout,
      helperText: appStrings.validationMessages.nameString,
    },
    {
      name: 'middleName',
      label: appStrings.studentProfile.middleName,
      type: 'text',
      key: 'registerForm-firstName',
      validations: {
        maxLength: 25,
        pattern: nameStringPattern,
      },
      gridLayout: isStudent ? studentGridLayout : gridLayout,
      helperText: appStrings.validationMessages.nameString,
    },
    {
      name: 'lastName',
      label: appStrings.common.lastName,
      type: 'text',
      validations: {
        maxLength: 25,
        pattern: nameStringPattern,
        required: true,
      },
      gridLayout: isStudent ? studentGridLayout : gridLayout,
      helperText: appStrings.validationMessages.nameString,
    },
    {
      name: 'suffix',
      label: appStrings.studentProfile.suffix,
      type: 'text',
      validations: {
        maxLength: 1,
      },
      gridLayout: isStudent ? studentGridLayout : gridLayout,
    },
    {
      name: 'email',
      label: appStrings.studentProfile.emailAddress,
      type: 'text',
      validations: {
        required: true,
        validate: {
          emailDomain: value =>
            !value.includes(snaponDomain) ||
            appStrings.validationMessages.emailBadDomain,
          emailPattern: value =>
            emailPattern.test(value) ||
            appStrings.validationMessages.emailFormat,
        },
        maxLength: 100,
      },
      gridLayout: isStudent ? studentGridLayout : gridLayout,
    },
    ...phoneInputs(
      1,
      disablePhoneType1,
      setDisablePhoneType1,
      setValue,
      getValues,
      isStudent
    ),
    {
      name: 'socialSecurityNumber',
      label: appStrings.studentProfile.socialSecurityNumber,
      type: 'ssn',
      validations: {
        maxLength: 9,
        pattern: digitPattern,
      },
      gridLayout: isStudent ? studentGridLayout : gridLayout,
      showVisibilityBtn: true,
    },
    {
      name: 'birthdate',
      label: appStrings.studentProfile.birthDate,
      type: 'date',
      format: dateFormat,
      maxDateValidationMessage: appStrings.validationMessages.maxBirthdate,
      minDateValidationMessage: appStrings.validationMessages.minBirthdate,
      validations: {},
      disableFuture: true,
      gridLayout: isStudent ? studentGridLayout : gridLayout,
      initialFocusedDate: xYearsInPast(20),
    },
    {
      name: 'driverLicenseNumber',
      label: appStrings.studentProfile.driverLicenseNumber,
      type: 'text',
      validations: {
        maxLength: 50,
      },
      onChangeCallback: () =>
        onDriverLicenseEmpty({
          getValues,
          setValue,
          setDisableDriverLicenseState,
        }),
      gridLayout: isStudent ? studentGridLayout : gridLayout,
    },
    {
      name: 'driverLicenseState',
      label: appStrings.studentProfile.driverLicenseState,
      type: 'autocomplete',
      validations: {},
      options: statesOptionsSortedByLabel,
      gridLayout: isStudent ? studentGridLayout : gridLayout,
      disabled: disableDriverLicenseState,
    },
    ...phoneInputs(
      2,
      disablePhoneType2,
      setDisablePhoneType2,
      setValue,
      getValues,
      isStudent
    ),
    {
      name: 'cellPhoneCompany',
      label: appStrings.studentProfile.cellPhoneCompany,
      type: 'text',
      validations: {
        maxLength: 25,
      },
      gridLayout: isStudent ? studentGridLayout : gridLayout,
    },
    {
      name: 'maritalStatus',
      label: appStrings.studentProfile.maritalStatus,
      type: 'select',
      validations: {},
      options: maritalStatusOptions,
      gridLayout: isStudent ? studentGridLayout : gridLayout,
    },
  ];
};
