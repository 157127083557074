import React from 'react';
import { Grid, Typography } from '@mui/material';
import useProductPageStyles from '../productPageStyles';
import appStrings from '../../../../core/strings/appStrings';
import moment from 'moment';
import { dateFormat } from '../../../../core/strings/appConstants';

function AvailabilityMessage({
  isAvailable,
  quantity,
  receiptDate,
  error,
}) {
  const css = useProductPageStyles().classes;
  return (
    <Grid container className={css.availabilityContainer}>
      {isAvailable &&
        <Typography className="in-stock">{appStrings.productPage.itemInStock}</Typography>}
      {(!isAvailable && quantity !== 0 && !Boolean(error)) &&
        <Typography className="out-stock">
          {`${appStrings.productPage.requestedQtyIs} 1. ${appStrings.productPage.remainingQtyShipped}`}
        </Typography>}
      {(isAvailable === false && quantity === 0 && !Boolean(error)) &&
        <Typography className="out-stock">
          {`${appStrings.productPage.itemInBackorder} ${moment(receiptDate).format(dateFormat)}`}
        </Typography>}
      {(isAvailable === null || Boolean(error)) &&
        <Typography className="out-stock">
          {appStrings.productPage.productAvailability}
        </Typography>}
    </Grid>
  );
}

export default AvailabilityMessage;