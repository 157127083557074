import urls from '../../../core/strings/urls';
import ApiService from '../../shared/Api/apiService';

export const getStudentsList = employeeId => {
  return ApiService.get(urls.getStudentsForApproveReject(employeeId));
};

export const updateStudentsStatus = details => {
  return ApiService.post(urls.updateStudentsStatus, details);
};

export const checkNoResult = sortedList => {
  let noResult = true;
  Object.values(sortedList).forEach(list => {
    if (list.length) {
      noResult = false;
    }
  });

  return noResult;
};
