import { makeStyles } from 'tss-react/mui';

export const useItemsButtonsStyle = makeStyles()(thene => ({
  saveItemBtnContainer: {
    button: {
      color: 'grey !important',
      textDecoration: 'none',
    },
  },
}));
