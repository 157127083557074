import {
  displayError,
  displayInfo,
  displaySuccess,
  hideNotification,
} from '../../../core/redux/slices/notificationsSlice';

import { HISTORY } from '../../../core/navigation/paths';
import appStrings from '../../../core/strings/appStrings';
import { downloadFileFromBlob } from '../../../core/services/downloadService';
import { exportCompletedOrderByType } from './orderDetailsHistoryService';
import { icons } from '../../../core/strings/icons';
import store from '../../../core/redux/store';

export const listOfPathsOrderHistory = (bp, id, student) => [
  {
    link: HISTORY(bp, id),
    label: `${student?.firstName} ${student?.lastName} ${appStrings.menu.history}`,
  },
];
export const currentPathOrderHistory = {
  label: appStrings.history.orderDetails,
};

export const exportCompletedOrder = (studentId, orderId, isExcel = true) => {
  if (!studentId && !orderId) {
    return;
  }

  const notificationId = `info${new Date().getTime()}`;

  store.dispatch(
    displayInfo({
      message: appStrings.export.exportInProgress,
      permanent: true,
      id: notificationId,
    })
  );

  const fileName = `${orderId}.${isExcel ? 'xlsx' : 'pdf'}`;

  exportCompletedOrderByType(
    {
      studentId,
      orderNumber: orderId,
      clientDate: new Date(),
    },
    isExcel
  )
    .then(resp => {
      downloadFileFromBlob(resp, fileName);

      store.dispatch(
        displaySuccess({
          message: appStrings.export.orderExported,
        })
      );
    })
    .catch(e => store.dispatch(displayError({ message: e })))
    .finally(() => store.dispatch(hideNotification({ id: notificationId })));
};

export const menuItemsOrderHistory = (studentId, orderId, printHandler) => [
  {
    label: appStrings.order.exportOrderToPdf,
    id: 'exportOrderToPdf',
    handler: () => {
      exportCompletedOrder(studentId, orderId, false);
    },
    isDisabled: false,
    icon: icons.exportPdf,
  },
  {
    label: appStrings.order.exportOrderToXls,
    id: 'exportOrderToXls',
    handler: () => {
      exportCompletedOrder(studentId, orderId);
    },
    isDisabled: false,
    icon: icons.exportXls,
  },
  {
    label: appStrings.order.printOrder,
    id: 'printOrder',
    handler: printHandler,
    isDisabled: false,
    icon: icons.exportXls,
  },
];

export const lineItemsTableColumns = [
  {
    label: appStrings.cart.partNumber,
    name: 'productNumber',
  },
  {
    label: appStrings.cart.description,
    name: 'description',
  },
  {
    label: appStrings.cart.quantity,
    name: 'quantity',
  },
  {
    label: appStrings.cart.listPrice,
    name: 'listPrice',
    isCurrency: true,
  },
  {
    label: appStrings.cart.studentPrice,
    name: 'studentPrice',
    isCurrency: true,
  },
  {
    label: appStrings.cart.lineTotal,
    name: 'lineTotal',
    isCurrency: true,
  },
  {
    label: appStrings.orderDetailsHistory.lineType,
    name: 'lineType',
  },
  {
    label: appStrings.orderDetailsHistory.inventorySource,
    name: 'inventorySource',
  },
];

export const promoPriceTableColumn = {
  name: 'promoPrice',
  assignedColumn: 'studentPrice',
};
