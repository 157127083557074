import { LinearProgress, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import useLoadingOverlayStyles from './useLoadingOverlayStyles';

const propTypes = {
  show: PropTypes.bool,
  customClass: PropTypes.string, // a custom class name to add to loading container
};

function LoadingOverlay({ show = true, customClass, loadingMessage = 'LOADING...' }) {
  const css = useLoadingOverlayStyles().classes;
  return show ? (
    <div
      className={classNames(css.container, customClass)}
      data-test-id="loadingOverlay"
      id="loadingOverlayContainer"
    >
      <div className={css.loadingContent}>
        <Typography className={css.text} variant="h1">
          {loadingMessage}
        </Typography>
        <LinearProgress color="primary" />
      </div>
    </div>
  ) : null;
}

LoadingOverlay.propTypes = propTypes;

export default LoadingOverlay;
