import { Grid, Typography } from '@mui/material';
import {
  displayError,
  displayWarning,
} from '../../../../core/redux/slices/notificationsSlice';
import {
  addFromConsignedLoading,
  saveSelectedInventoryList,
  selectAllConsignedItems,
  selectConsignedItem,
  updateLoadingState,
} from '../../../../core/redux/slices/consignedInventorySlice';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import CustomBox from '../../../shared/CustomBox/CustomBox';
import CustomPagination from '../../../shared/CustomPagination/CustomPagination';
import CustomTable from '../../../shared/CustomTable/CustomTable';
import FormInput from '../../../shared/FormInput/FormInput';
import NoDataAvailable from '../../../shared/NoDataAvailable/NoDataAvailable';
import SelectedConsignedItems from '../SelectedConsignedItems/SelectedConsignedItems';
import { addConsignedItemsToOrder } from './OrderConsignedInventoryDetailsService';
import appStrings from '../../../../core/strings/appStrings';
import { cloneDeep } from 'lodash';
import { getConsignedInventoryDetailsByCode } from '../consignedInventoryService';
import { getDataToDisplayFromTo } from '../../../shared/CustomPagination/customPaginationService';
import { icons } from '../../../../core/strings/icons';
import { orderConsignedInventoryColumns } from './orderConsignedInventoryDetailsConstants';
import useConsignedInventoryDetailsStyle from '../ConsignedInventoryDetails/useConsignedInventoryDetailsStyles';
import { useForm } from 'react-hook-form';
import { ORDER } from '../../../../core/navigation/paths';
import { connectionResponseState } from '../../../../core/strings/appConstants';

function OrderConsignedInventoryDetails() {
  const css = useConsignedInventoryDetailsStyle().classes;
  const { id, bp } = useParams();
  const { control, setValue, reset, getValues } = useForm();
  const navigate = useNavigate();
  const formControl = useRef(control);
  const qtyToAddRef = useRef();
  const dispatch = useDispatch();
  const {
    selectedInventoryCode,
    selectedInventoryName,
    selectedInventoryList,
    lastSelectedInventoryCode,
    selectedItemsCounter,
  } = useSelector(state => state.consignedInventory);
  const consignedLoading = useSelector(
    state => state.consignedInventory.addFromConsignedLoading
  );

  const [paginationDetails, setPaginationDetails] = useState({
    from: 0,
    to: 15,
    itemsPerPage: 15,
    page: 0,
  });
  const [displayedProducts, setDisplayedProducts] = useState(
    selectedInventoryList?.slice(0, paginationDetails.itemsPerPage) || []
  );
  const noDataAvailableMessage = !selectedInventoryCode
    ? appStrings.consigned.noInventoryAvailableMsg
    : appStrings.consigned.noItemsAvailableMsg;

  useEffect(() => {
    if (
      selectedInventoryCode &&
      lastSelectedInventoryCode !== selectedInventoryCode
    ) {
      dispatch(updateLoadingState(true));
      reset();
      getConsignedInventoryDetailsByCode(selectedInventoryCode)
        .then(res => {
          dispatch(
            saveSelectedInventoryList({
              list: res,
              code: selectedInventoryCode,
            })
          );
        })
        .catch(e => {
          dispatch(displayError({ message: JSON.stringify(e) }));
          dispatch(updateLoadingState(false));
        });
    }
  }, [dispatch, lastSelectedInventoryCode, reset, selectedInventoryCode]);

  useEffect(() => {
    if (consignedLoading === connectionResponseState.done) {
      dispatch(addFromConsignedLoading(false));
      navigate(ORDER(bp, id));
    }
  }, [bp, consignedLoading, dispatch, id, navigate]);

  const createQtyToAddFormInput = useCallback(
    qtyInputInfo => (
      <FormInput
        field={qtyInputInfo}
        fieldRef={qtyToAddRef}
        control={formControl.current}
        InputProps={{ className: css.editableField }}
      />
    ),
    [css.editableField]
  );

  const addQtyToInventoryList = useCallback(
    inventoryList => {
      const handleItemQtyChange = (value, productNumber) => {
        const index = selectedInventoryList.findIndex(
          item => item.productNumber === productNumber
        );
        const availableQty = selectedInventoryList[index].quantity;
        const isSelected = !value || value === '0' ? false : true;
        let qtyToAdd;
        if (value > availableQty) {
          qtyToAdd = availableQty;
          dispatch(
            displayWarning({
              message: appStrings.consigned.qtyUnavailable(value, availableQty),
            })
          );
        } else {
          qtyToAdd = value;
        }
        setValue(`qtyToAdd${productNumber}`, qtyToAdd);
        dispatch(selectConsignedItem({ index, isSelected, qtyToAdd }));
      };

      const newInventoryList = cloneDeep(inventoryList);
      newInventoryList.forEach(item => {
        item.qtyToAdd = createQtyToAddFormInput({
          name: `qtyToAdd${item.productNumber}`,
          type: 'number',
          defaultValue: 0,
          onChangeCallback: value =>
            handleItemQtyChange(value, item.productNumber),
          onFocus: () => {
            if (getValues(`qtyToAdd${item.productNumber}`) === 0) {
              setValue(`qtyToAdd${item.productNumber}`, '');
            }
          },
          validations: {
            maxLength: 4,
          },
          removeStartZero: true,
        });
      });
      return newInventoryList;
    },
    [
      selectedInventoryList,
      setValue,
      dispatch,
      createQtyToAddFormInput,
      getValues,
    ]
  );

  useEffect(() => {
    if (selectedInventoryList) {
      setTimeout(() => {
        const newInventoryList = addQtyToInventoryList(selectedInventoryList);
        const newDisplayedProds = getDataToDisplayFromTo(
          newInventoryList,
          paginationDetails.from,
          paginationDetails.to
        );
        setDisplayedProducts(newDisplayedProds);
      });
    }
  }, [
    addQtyToInventoryList,
    paginationDetails.from,
    paginationDetails.to,
    reset,
    selectedInventoryList,
  ]);

  const handleSelectItem = (index, isSelected) => {
    const qtyToAdd = isSelected ? 1 : 0;
    dispatch(selectConsignedItem({ index, isSelected, qtyToAdd }));
    setValue(`qtyToAdd${selectedInventoryList[index].productNumber}`, qtyToAdd);
  };

  const handleSelectAll = allSelected => {
    const qtyToAdd = allSelected ? 1 : 0;
    dispatch(selectAllConsignedItems({ allSelected, qtyToAdd }));
    selectedInventoryList.forEach(item => {
      setValue(`qtyToAdd${item.productNumber}`, qtyToAdd);
    });
  };

  const addToOrder = () => {
    addConsignedItemsToOrder({
      selectedInventoryList,
      warehouse: { name: selectedInventoryName, code: selectedInventoryCode },
      bp,
      studentId: id,
      dispatch,
      setValue,
    });
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={css.container}
      data-test-id="orderConsignedInventoryDetails"
    >
      {selectedInventoryList?.length ? (
        <>
          <Grid item>
            <Typography className={css.marginBottom1}>
              <strong>{appStrings.consigned.addFromConsignedGetStarted}</strong>
            </Typography>
          </Grid>
          <Grid item>
            <CustomTable
              useCheckbox
              useIndex
              columns={orderConsignedInventoryColumns}
              rowData={displayedProducts}
              selectedCount={selectedItemsCounter}
              rowCount={selectedInventoryList?.length}
              onSelectRow={handleSelectItem}
              onSelectAll={handleSelectAll}
              currentPage={paginationDetails.page}
              itemsPerPage={paginationDetails.itemsPerPage}
              tableId={'quoteConsignedInventory'}
            />
          </Grid>
          <Grid item xs={12} className={css.pagination}>
            <CustomPagination
              itemsLength={selectedInventoryList.length}
              itemsPerPage={paginationDetails.itemsPerPage}
              id={'inventoryProductsList'}
              onPageChangeCallback={({ from, to, page, itemsPerPage }) => {
                const newDisplayedProds = getDataToDisplayFromTo(
                  selectedInventoryList,
                  from,
                  to
                );
                setDisplayedProducts(newDisplayedProds);
                setPaginationDetails({ from, to, page, itemsPerPage });
                selectedInventoryList.forEach(item => {
                  if (getValues(`qtyToAdd${item.productNumber}`) === '') {
                    setValue(`qtyToAdd${item.productNumber}`, 0);
                  }
                });
              }}
            />
          </Grid>
          <SelectedConsignedItems addToOrder={addToOrder} />
        </>
      ) : null}
      {!selectedInventoryList?.length || !selectedInventoryCode ? (
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          data-test-id="noDataAvailableContainer"
        >
          <Grid item>
            <CustomBox customClass={css.customBox}>
              <NoDataAvailable
                message={noDataAvailableMessage}
                icon={icons.warehouse1}
              />
            </CustomBox>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
}

export default OrderConsignedInventoryDetails;
