import appStrings from '../../../core/strings/appStrings';
import { exportCompletedOrder } from '../OrderHistoryDetails/orderHistoryDetailsConstants';
import { getExportLabels } from '../Cart/cartConstants';
import { icons } from '../../../core/strings/icons';

export const currentPathOrder = {
  label: appStrings.order.orderConfirmationPage,
  id: 'orderConfirmationPage-path',
};

const { exportToPdfLabel, exportToXlsLabel } = getExportLabels(true);

export const menuItems = (studentId, orderId) => [
  {
    label: exportToPdfLabel,
    id: 'exportCartToPdf',
    handler: () => {
      exportCompletedOrder(studentId, orderId, false);
    },
    icon: icons.exportPdf,
  },
  {
    label: exportToXlsLabel,
    id: 'exportCartToXls',
    handler: () => {
      exportCompletedOrder(studentId, orderId);
    },
    icon: icons.exportXls,
  },
];

export const initialStateMock = {
  schoolsWithStudents: {
    selectedStudent: { student: { firstName: 'John', lastName: 'Doe' } },
  },
};

export const studentNameMock = 'John Doe';
