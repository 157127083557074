import { Grid } from '@mui/material';
import { Skeleton } from '@mui/material';

function CustomSkeleton({ layout, spacing, rowSpacing, containerClass }) {
  return (
    <Grid
      container
      spacing={spacing}
      rowSpacing={rowSpacing}
      id="customSkeletonContainer"
      data-test-id="customSkeleton"
      className={containerClass}
    >
      {layout.map((skeleton, index) => {
        return (
          <Grid item {...skeleton} key={index}>
            <Skeleton
              width={skeleton.width}
              height={skeleton.height}
              variant={skeleton.variant}
              id="customSkeleton"
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default CustomSkeleton;
