import { Grid, Typography } from '@mui/material';

import CustomTableSkeleton from '../../../shared/CustomSkeleton/CustomTableSkeleton';
import { Skeleton } from '@mui/material';
import appStrings from '../../../../core/strings/appStrings';
import { columns } from './consignedInventoryDetailsConstants';
import useConsignedInventoryDetailsStyle from './useConsignedInventoryDetailsStyles';

function ConsignedInventoryDetailsSkeleton() {
  const css = useConsignedInventoryDetailsStyle().classes;
  return (
    <Grid
      container
      direction="column"
      className={css.container}
      data-test-id="consignedInventoryDetails"
    >
      <Skeleton>
        <Typography variant="h3" className={css.subTitle}>
          {`${appStrings.consigned.consignedInventoryReport} for number firstName, lastName`}
        </Typography>
      </Skeleton>
      <Grid item className={css.marginTop2}>
        <CustomTableSkeleton columns={columns} />
      </Grid>
    </Grid>
  );
}

export default ConsignedInventoryDetailsSkeleton;
