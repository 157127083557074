import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import ProductVideo from './ProductVideo';
import appStrings from '../../../../core/strings/appStrings';

function ProductOverview({description, videoUrl}) {

  return (
    <Grid container spacing={1} data-test-id="renderedOverview">
      <Grid item xs={12} sm={12} md={videoUrl ? 6 : 12}>
        {description ? (
          <Typography
            id="productPage-innerHtml"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        ) : (
          <Typography data-test-id="renderedOverviewMessage">
            {appStrings.productPage.noSpecsAvailable}
          </Typography>
        )}
      </Grid>
      {videoUrl ? (
        <Grid item xs={12} sm={12} md={6}>
          <ProductVideo videoUrl={videoUrl} />
        </Grid>
      ) : null}
    </Grid>
  );
}

ProductOverview.propTypes = {
  description: PropTypes.string,
  videoUrl: PropTypes.string,
};

export default ProductOverview;