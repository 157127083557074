import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackGTMEvent } from '../Analytics/analyticsService';

function GTMPageView({ children }) {
  const location = useLocation();

  useEffect(() => {
    trackGTMEvent('', { path: location.pathname, orig_ref_url: document.location.href });
  }, [location.pathname]);

  return children;
}
export default GTMPageView;
