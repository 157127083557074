import { makeStyles } from 'tss-react/mui';

const useCartStyles = makeStyles()(theme => ({
  name: {
    textTransform: 'capitalize',
  },
  purchasePowerContainer: {
    padding: '14px 14px 10px 20px',
    borderRadius: '4px 4px 0px 0px',
  },
  noBottomPaddingPanel: {
    '& >div': {
      paddingBottom: '0px !important',
    },
  },
  deleteIcon: {
    color: theme.palette.common.red,
  },
  secondaryIcon: {
    color: theme.palette.common.secondary,
    border: `1px solid ${theme.palette.common.secondary} !important`,
    background: theme.palette.common.neutral1,
    padding: theme.spacing(1),
    paddingRight: '12px',
    borderRadius: '50%',
    '&::before': {
      marginLeft: '-4px',
    },
  },
  sectionsContainer: {
    background: theme.palette.common.neutral2,
    '& .MuiAccordionDetails-root': {
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(3),
    },
  },
  hasNoItems: {
    '& .MuiAccordion-root:first-of-type': {
      '& .MuiAccordionDetails-root': {
        background: theme.palette.common.neutral1,
        padding: '40px 100px 60px',

        '@media screen and (max-width:800px)': {
          padding: '40px 40px 60px',
        },
      },
    },
  },
  orderIcon: {
    '&::before': {
      fontSize: '36px',
    },
  },
  orderTypeContainer: {
    maxHeight: '130px',
  },
}));

export default useCartStyles;
