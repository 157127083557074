import {
  buttonColors,
  buttonTypes,
  speedDialColors,
} from '../../../../core/strings/appConstants';
import {
  getMoveOptions,
  getSelectedItems,
  handleTrackGAEvent,
  moveActionWithSignalr,
} from '../cartService';
import {
  moveActionOptions,
  moveDialogInfo,
  moveFlowOptions,
} from '../cartConstants';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CartContext from '../context/CartContext';
import CustomDialog from '../../../shared/CustomDialog/CustomDialog';
import CustomIcon from '../../../shared/CustomIcon/CustomIcon';
import CustomSpeedDial from '../../../shared/CustomSpeedDial/CustomSpeedDial';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import appStrings from '../../../../core/strings/appStrings';
import classNames from 'classnames';
import {
  displaySuccess,
  displayWarning,
} from '../../../../core/redux/slices/notificationsSlice';
import { icons } from '../../../../core/strings/icons';
import {
  setItemsList,
  setMoveItemsStatus,
} from '../../../../core/redux/slices/cartSlice';
import useCartStyles from '../useCartStyles';
import { CART, ORDER, SAVED } from '../../../../core/navigation/paths';
import { useNavigate } from 'react-router';

function CartMenu() {
  const cartCtx = useContext(CartContext);
  const dispatch = useDispatch();
  const css = useCartStyles().classes;
  const navigate = useNavigate();

  const {
    id,
    isSavedItems,
    isOrder,
    rowsLoading,
    onlyConsignedSelected,
    expandedSection,
    bp,
    deleteItems
  } = cartCtx;

  const cartInfo = useSelector(state => state.cart.cartInfo);
  const moveItemsStatus = useSelector(state => state.cart.moveItemsStatus);

  const [deleteOpen, setDeleteOpen] = useState(false);

  const [moveDialogOpen, setMoveDialogOpen] = useState(false);
  const [moveFlow, setMoveFlow] = useState(moveFlowOptions.cartToSaved);

  const handleDeleteItems = () => {
    setDeleteOpen(true);
  };
  const handleMove = option => {
    setMoveFlow(option);
    setMoveDialogOpen(true);
  };

  const handleCloseDelete = () => {
    setDeleteOpen(false);
  };

  const handleCloseMoveDialog = () => {
    setMoveDialogOpen(false);
  };

  const handleMoveWithoutValidation = () => {
    setMoveDialogOpen(false);
    moveActionWithSignalr(id, moveFlow);
  };

  const handleMoveWithValidation = () => {
    setMoveDialogOpen(false);
    moveActionWithSignalr(id, moveFlow, true);
  };

  useEffect(() => {
    const navigateAfterMove = moveOptions => {
      let path = null;
  
      if (moveOptions.isToOrder) {
        path = ORDER;
      } else if (moveOptions.isToCart) {
        path = CART;
      } else if (moveOptions.isToSaved) {
        path = SAVED;
      }
  
      if (path) {
        navigate(path(bp, id));
      }
    }
    
    if (moveItemsStatus) {
      const moveOptions = getMoveOptions(moveFlow);
      const { selectedItems, selectedItemsDefaultWarehouse, unselectedItems } =
        getSelectedItems(moveOptions.isFromOrder);

      if (
        moveOptions.isFromOrder &&
        selectedItems.length !== selectedItemsDefaultWarehouse.length
      ) {
        dispatch(
          displaySuccess({ message: appStrings.order.moveFromOrderSuccess })
        );
        dispatch(
          displayWarning({ message: appStrings.order.moveFromOrderWarning })
        );
      } else {
        dispatch(
          displaySuccess({
            message: moveActionOptions[moveFlow].successMessage,
          })
        );
      }
      dispatch(setItemsList(unselectedItems));
      dispatch(setMoveItemsStatus(null));
      handleTrackGAEvent(moveFlow, id);
      navigateAfterMove(moveOptions);
    }
  }, [
    moveItemsStatus,
    dispatch,
    moveFlow,
    id,
    bp,
    navigate,
  ]);

  const handleDeleteConfirmation = () => {
    setDeleteOpen(false);
    deleteItems();
  };

  const floatingActions = [
    {
      icon: <DeleteOutlineIcon />,
      text: appStrings.cart.deleteSelectedItems,
      color: speedDialColors.red,
      onClick: handleDeleteItems,
      testId: 'floatingDelete',
    },
    isSavedItems
      ? {
          icon: <CustomIcon icon={icons.moveToCart}></CustomIcon>,
          text: appStrings.cart.moveToCart,
          onClick: () => handleMove(moveFlowOptions.savedToCart),
          testId: 'floatingMoveToCart',
        }
      : {
          icon: <CustomIcon icon={icons.moveToSavedItems}></CustomIcon>,
          text: appStrings.cart.moveToSavedItems,
          onClick: () =>
            handleMove(
              isOrder
                ? moveFlowOptions.orderToSaved
                : moveFlowOptions.cartToSaved
            ),
          testId: 'floatingMoveToSaved',
          disabled: isOrder && onlyConsignedSelected,
        },
    isOrder
      ? {
          icon: <CustomIcon icon={icons.moveToCart}></CustomIcon>,
          text: appStrings.cart.moveToCart,
          onClick: () => handleMove(moveFlowOptions.orderToCart),
          testId: 'floatingMoveToCart',
          disabled: onlyConsignedSelected,
        }
      : {
          icon: <CustomIcon icon={icons.moveToOrder}></CustomIcon>,
          text: appStrings.cart.moveToOrder,
          onClick: () =>
            handleMove(
              isSavedItems
                ? moveFlowOptions.savedToOrder
                : moveFlowOptions.cartToOrder
            ),
          testId: 'floatingMoveToOrder',
        },
  ];

  const deleteButtons = [
    {
      btnType: buttonTypes.secondary,
      label: appStrings.common.cancel,
      onClick: handleCloseDelete,
    },
    {
      btnType: buttonTypes.primary,
      color: buttonColors.red,
      label: appStrings.common.remove,
      onClick: handleDeleteConfirmation,
    },
  ];

  const moveDialogButtons = [
    {
      btnType: buttonTypes.secondary,
      color: buttonColors.secondary,
      label: appStrings.common.cancel,
      onClick: handleCloseMoveDialog,
    },
    {
      btnType: buttonTypes.secondary,
      color: buttonColors.secondary,
      label: appStrings.cart.moveWithoutValidation,
      onClick: handleMoveWithoutValidation,
    },
    {
      color: buttonColors.secondary,
      label: appStrings.cart.moveWithValidation,
      onClick: handleMoveWithValidation,
    },
  ];
  return (
    <>
      <CustomSpeedDial
        show={
          cartInfo.selectedCounter > 0 &&
          !rowsLoading.length &&
          (!isOrder || (isOrder && expandedSection === 'lineItems'))
        }
        options={floatingActions}
        id="cartMenu"
        isCart
      />
      <CustomDialog
        open={deleteOpen}
        handleClose={handleCloseDelete}
        icon={<CustomIcon icon={icons.trash} customClasses={css.deleteIcon} />}
        title={appStrings.cart.removeSelectedItems}
        buttons={deleteButtons}
      />
      <CustomDialog
        title={moveDialogInfo[moveFlow].title}
        message={appStrings.modal.moveDescription}
        icon={
          <CustomIcon
            icon={moveDialogInfo[moveFlow].icon}
            customClasses={classNames(css.secondaryIcon, {
              [css.orderIcon]: !isOrder,
            })}
          ></CustomIcon>
        }
        open={moveDialogOpen}
        buttons={moveDialogButtons}
      />
    </>
  );
}

export default CartMenu;
