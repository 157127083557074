import CustomBox from '../../../shared/CustomBox/CustomBox';
import { Grid } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import NoDataAvailable from '../../../shared/NoDataAvailable/NoDataAvailable';
import appStrings from '../../../../core/strings/appStrings';
import { icons } from '../../../../core/strings/icons';
import useProductsListStyles from '../useProductsListStyles';

function NoDataContentProductList({ isOrder, isSavedItems }) {
  const css = useProductsListStyles().classes;
  let noDataIcon, noDataTitle, noDataHelper;
  if (isSavedItems) {
    noDataIcon = icons.savedItems;
    noDataTitle = appStrings.cart.emptySavedItems;
    noDataHelper = appStrings.cart.emptyCartHelperText;
  } else {
    noDataIcon = icons.cart;
    noDataTitle = appStrings.cart.emptyCartLabel;
    noDataHelper = appStrings.cart.emptyCartHelperText;
  }

  return isOrder ? (
    <CustomBox customClass={css.noOrderItemsBox}>
      <NoDataAvailable
        iconElement={<InfoOutlinedIcon />}
        message={appStrings.order.noItems}
        helperText={appStrings.order.noItemsHelperText}
      />
    </CustomBox>
  ) : (
    <Grid className={css.noDataContainer}>
      <NoDataAvailable
        icon={noDataIcon}
        message={noDataTitle}
        size="large"
        helperText={noDataHelper}
        color="blue"
      />
    </Grid>
  );
}

export default NoDataContentProductList;
