import {
  saveCustomerInformation,
  saveSchoolInformation,
  validateDatePickers,
} from '../../../../industrial/StudentProfile/Services/studentProfileService';

import { cloneDeep } from 'lodash';
import { isSignNowBtnClicked } from '../wizardPageContainerService';

export const saveInformation = (
  data,
  reset,
  id,
  onSuccessCallback,
  onErrorCallback
) => {
  const initialData = cloneDeep(data);

  if (validateDatePickers().valid) {
    Promise.all([
      saveSchoolInformation({ id, data }),
      saveCustomerInformation({ id, data }),
    ])
      .then(() => {
        if (onSuccessCallback) {
          onSuccessCallback(true, isSignNowBtnClicked());
        }
      })
      .catch(err => {
        if (onErrorCallback) {
          onErrorCallback(err);
        }
      });

    reset(initialData);
  }
};
