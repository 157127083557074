import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import ItemsTitle from '../../ShoppingCart/Components/ItemsTitle';
import { Divider, Grid, Typography } from '@mui/material';
import { getDataToDisplayFromTo } from '../../../shared/CustomPagination/customPaginationService';
import CustomPagination from '../../../shared/CustomPagination/CustomPagination';
import { useOrderDetailsStyles } from '../orderDetailsStyles';
import appStrings from '../../../../core/strings/appStrings';
import ItemPrice from '../../ShoppingCart/Components/ItemPrice';

function OrderItems({ items }) {
  const css = useOrderDetailsStyles().classes;
  const [displayedItems, setDisplayedItems] = useState([]);
  const [paginationDetails, setPaginationDetails] = useState({
    from: 0,
    to: 10,
    itemsPerPage: 10,
    page: 1,
  });

  const handlePaginationChange = ({ from, to, page, itemsPerPage }) => {
    const newDisplayedItems = getDataToDisplayFromTo(items, from, to);
    setDisplayedItems(newDisplayedItems);
    setPaginationDetails({ from, to, page, itemsPerPage });
  };

  useEffect(() => {
    setDisplayedItems(items?.slice(0, paginationDetails.itemsPerPage) || []);
  }, [items, paginationDetails.itemsPerPage]);

  return (
    <CustomBox id="OrderDetails-items" data-test-id="OrderDetails-itemsTestId">
      <Grid item className={css.title}>
        <ItemsTitle isOrderList />
      </Grid>
      <Grid item xs={12}>
        {displayedItems.map((item, index) => (
          <Grid
            item
            container
            alignItems="center"
            key={index}
            className={css.content}
            id={`itemContainer-${index}`}
          >
            <Grid item container xs spacing={1}>
              <Grid item>
                <Typography id={`itemDescription-${index}`} fontWeight="bold">
                  {item.name || item.description}
                </Typography>
              </Grid>
              <Grid item container>
                <Typography id={`itemSku-${index}`}>
                  {appStrings.common.sku} {item.productNumber}
                </Typography>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ marginX: '5px' }}
                />
                <Typography id={`itemQuantity-${index}`}>
                  Qty: {item.quantity ?? 1}
                </Typography>
              </Grid>
              {item.notes && (
                <Grid item>
                  <Typography
                    id={`itemNotes-${index}`}
                  >{`${appStrings.cart.studentNotes}: ${item.notes}`}</Typography>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <ItemPrice item={item} />
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} className={css.paginationContainer}>
        <CustomPagination
          onPageChangeCallback={handlePaginationChange}
          itemsLength={items?.length}
          itemsPerPage={paginationDetails.itemsPerPage}
          isStudent={true}
          shape={'rounded'}
          variant={'outlined'}
          keepPageOnLengthChange
        />
      </Grid>
    </CustomBox>
  );
}

OrderItems.propTypes = {
  items: PropTypes.array,
};

export default OrderItems;
