import React from 'react';
import { Grid, Skeleton, useMediaQuery, useTheme } from '@mui/material';

function CartTotalsMobileSkeleton() {
  const theme = useTheme();
  const isXSDevice = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <Grid container alignItems="center" spacing={5} padding={2}>
      {!isXSDevice && (
        <Grid item sm={6}>
          <Grid item container>
            <Grid item xs={6}>
              <Skeleton width="50%" />
              <Skeleton width="40%" />
              <Skeleton width="60%" />
            </Grid>
            <Grid item container xs direction="column" alignItems="flex-end">
              <Skeleton width="40%" />
              <Skeleton width="40%" />
              <Skeleton width="30%" />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <Skeleton height={20} />
        <Skeleton height={40} />
        <Skeleton height={40} />
      </Grid>
    </Grid>
  );
}

export default CartTotalsMobileSkeleton;
