import { Grid } from '@mui/material';
import React from 'react';
import OrderHeaderSkeleton from '../../Account/Orders/OrderHeaderSkeleton';
import { useOrderDetailsStyles } from '../orderDetailsStyles';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import classNames from 'classnames';
import OrderInfoSkeleton from './OrderInfoSkeleton';
import OrderItemsSkeleton from './OrderItemsSkeleton';
import OrderSummarySkeleton from './OrderSummarySkeleton';

function OrderDetailsSkeleton() {
  const css = useOrderDetailsStyles().classes;

  return (
    <Grid
      container
      spacing={2}
      id="OrderDetails-skeleton"
      data-test-id="OrderDetails-skeletonTestId"
    >
      <Grid item xs>
        <CustomBox
          customClass={classNames(css.boxContainer, css.containerPadding)}
        >
          <OrderHeaderSkeleton />
          <OrderInfoSkeleton />
        </CustomBox>
      </Grid>
      <Grid item container spacing={2} className={css.boxContainer}>
        <OrderItemsSkeleton />
        <OrderSummarySkeleton />
      </Grid>
    </Grid>
  );
}

export default OrderDetailsSkeleton;
