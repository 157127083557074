export const fileStatuses = {
  canceled: 'Canceled',
  closed: 'Closed',
  declined: 'Declined',
  error: 'Error',
  none: 'None',
  notSigned: 'NotSigned',
  open: 'Open',
  signError: 'SignError',
  signed: 'Signed',
  signing: 'Signing',
};

export const appStatuses = {
  Submitted: 3,
  NotSigned: 23,
};

export const creditTypes = {
  creditApp: 'Credit App',
  disclosure: 'Disclosure',
  ecOrder: 'EC Order',
};

export const disclosureStatuses = {
  error: 'Error',
  none: 'None',
  notSigned: 'NotSigned',
  open: 'Open',
  signError: 'SignError',
  signed: 'Signed',
  signing: 'Signing',
};

export const generalErrorMsg =
  'There was an error processing the Signing Request. Please try again later.';
export const errorMsgRetries =
  'The documents were not processed yet, once the documents are processed, they will be available in History.';
export const errorMsgRetriesStudent =
  'The documents were not processed yet, once the documents are processed, you will receive an email with the documents.';
export const documentNotAvailableErrorMsg =
  "We're sorry, these documents are no longer available for electronic signature.";
export const consentNotReceivedErrorMsg =
  'Consent not received, you can re-sign from Credit Summary.';
export const submittedMsgForIndustrial = studentName =>
  `Your EC Application for ${studentName} was submitted.`;

export const submittedMsgForStudent = 'Your EC Application was submitted.';
export const receiveNotificationFromSOCMsg = `You will receive a notification soon from Snap-on Credit.`;
export const receiveNotificationFromRepMsg = `You will receive a notification from your Industrial Representative.`;
export const useContactOptions =
  'Use one of the below options to communicate the Application to your Student.';
