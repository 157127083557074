import { makeStyles } from 'tss-react/mui';

const useProductsListStyles = makeStyles()(theme => ({
  bolded: {
    fontWeight: theme.typography.fontWeightBold,
  },
  accordion: {
    boxShadow: `0px 2px 4px 0px ${theme.palette.common.fadeGrey}, 0px 2px 4px 0px ${theme.palette.common.fadeGrey}`,
    margin: '1px 0 !important',
    '& .MuiAccordionSummary-root': {
      backgroundColor: 'transparent',
    },
  },
  accordionSummary: {
    margin: 0,
  },
  accordionSummaryContent: {
    margin: 0,
  },
  expanded: {
    '&.Mui-expanded': {
      '& span': {
        color: theme.palette.common.primary,
      },
    },
  },
  pagination: {
    '& .Mui-selected': {
      backgroundColor: theme.palette.common.secondary1,
      color: theme.palette.common.black,
    },
  },
  noDataContainer: {
    margin: `${theme.spacing(10)} 0`,
  },
  editableField: {
    width: '65px',
  },
  editableCurrencyField: {
    width: '125px',
  },
  form: {
    position: 'relative',
    '& .MuiFormHelperText-root': {
      display: 'none',
    },
  },
  errorIcon: {
    color: theme.palette.common.red,
    float: 'left',
    fontSize: theme.typography.h2.fontSize,
    marginTop: '3px',
  },
  warningIcon: {
    color: theme.palette.common.yellow,
  },
  successIcon: {
    color: theme.palette.common.green,
  },
  infoIcon: {
    color: theme.palette.common.blue,
  },
  layersIcon: {
    color: theme.palette.common.orange,
  },
  auditIcon: {
    fontSize: '21px',
    display: 'block',
    float: 'left',
    marginTop: '1px',
  },
  updatedIcon: {
    color: theme.palette.common.secondary,
  },
  addedIcon: {
    color: theme.palette.common.primary,
  },
  noOrderItemsBox: {
    background: theme.palette.common.neutral4,
    paddingTop: '100px',
    paddingBottom: '120px',
  },
  consignedIcon: {
    marginRight: '2px',
  },
  consignedContainer: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  notificationsContainer: {
    display: 'flex',
    alignItems: 'center',
    '& >span,svg': {
      marginRight: theme.spacing(0.5),
    },
  },
  selectedItemsCounterContainer: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '12px',
  },
  plusSymbol: {
    fontSize: '16px',
  },
  menuContainer: {
    height: theme.spacing(5),
  },
}));

export default useProductsListStyles;
