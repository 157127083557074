import ApiService from '../../modules/shared/Api/apiService';
import appStrings from '../strings/appStrings';
import { saveZipCode } from '../redux/slices/zipCodeSlice';
import { states } from '../strings/states';
import store from '../redux/store';
import urls from '../strings/urls';

const maxLengthOfZipCode = 5;

export const getAddressSuggestionByZipCode = zipCode => {
  return ApiService.get(urls.getStatesCitiesByZipCode(zipCode));
};

/**
 * Return the formatted zipCode response
 * @param {string} code
 * @returns {Promise}
 */
export const getZipCodeInfo = async code => {
  const zipCodes = store.getState().zipCode.formattedCodeList;
  if (code && code.length === maxLengthOfZipCode && !zipCodes[code]) {
    const newCode = await getAddressSuggestionByZipCode(code);
    const formattedCode = formatZipCodeResp(newCode);

    store.dispatch(
      saveZipCode({ code, value: newCode, formattedValue: formattedCode })
    );

    return Promise.resolve(formattedCode);
  }

  return Promise.resolve(zipCodes[code]);
};

export const formatZipCodeResp = resp => {
  if (!resp || !resp.length) {
    return null;
  }
  const response = resp[0];
  const hasValidState = Object.keys(states).includes(response?.state);

  const cityOptions = response?.cities?.map(item => {
    return { label: item, value: item };
  });

  return hasValidState
    ? { state: states[response?.state], cities: cityOptions }
    : null;
};

export const isValidZipCode = code => {
  const zipCodes = store.getState().zipCode.formattedCodeList;

  return zipCodes[code] || !code
    ? true
    : appStrings.validationMessages.invalidZipCode;
};
