import { makeStyles } from 'tss-react/mui';

export const useProfileStyles = makeStyles()(theme => ({
  boxStyle: {
    padding: `${theme.spacing(3, 0)} !important`,
  },

  mainContainer: {
    [theme.breakpoints.up('md')]: {
      '& .MuiGrid-container:last-child': {
        '& .MuiGrid-item': {
          borderBottom: 0,
        },
      },
    },
    [theme.breakpoints.only('sm')]: {
      '& .MuiGrid-container:nth-last-of-type(-n + 2)': {
        '& .MuiGrid-item': {
          borderBottom: 0,
        },
      },
    },

    [theme.breakpoints.only('xs')]: {
      '& .MuiGrid-container:last-child': {
        '& .MuiGrid-item': {
          borderBottom: 0,
        },
      },
    },
  },

  infoContainerParent: {
    padding: theme.spacing(1, 3),
  },

  infoContainer: {
    borderBottom: `1px solid`,
    borderColor: theme.palette.common.grey4,
    overflowWrap: 'break-word',
    alignItems: 'center',
    padding: theme.spacing(0, 0, 2, 2),
  },

  infoValueStyle: {
    textAlign: 'start',
    color: theme.palette.common.grey1,
    [theme.breakpoints.only('xs')]: {
      textAlign: 'end',
    },
  },
}));
