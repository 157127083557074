import {
  displayLoading,
  hideLoading,
} from '../../../core/redux/slices/commonSlice';
import { clearNotifications } from '../../../core/redux/slices/notificationsSlice';
import store from '../../../core/redux/store';
import {
  closeSignalRConnection,
  startSignalRConnection,
} from '../../../core/services/signalR/signalRService';
import { getZipCodeInfo } from '../../../core/services/zipCodeService';
import {
  connectionResponseState,
  paymentMethodLabel,
  phoneMask,
  snaponDomain,
} from '../../../core/strings/appConstants';
import appStrings from '../../../core/strings/appStrings';
import {
  addressPattern,
  digitPattern,
  emailPattern,
  nameStringPattern,
  toUpperCaseEachWord,
} from '../../../core/strings/regexValidations';
import urls from '../../../core/strings/urls';
import {
  trackGAEvent,
  trackGTMEvent,
} from '../../shared/Analytics/analyticsService';
import ApiService from '../../shared/Api/apiService';
import { addressesTypes, sectionsAddresses } from './checkoutConstants';

export const formatAddressAsText = ({ address }) => {
  if (Object.keys(address).length) {
    const formatAddress = {
      addressName: (address?.city || address?.schoolName) ?? '',
      addressLine1: address?.addressLine1 ?? '',
      addressLine2: address?.addressLine2 ?? '',
      addressState: address?.state?.toUpperCase() ?? '',
      zipCode: address?.zipCode ?? '',
    };

    return `${formatAddress.addressName}, ${formatAddress.addressLine1}, ${formatAddress.addressLine2}, ${formatAddress.addressState} ${formatAddress.zipCode}`.replace(
      toUpperCaseEachWord,
      letter => letter.toUpperCase()
    );
  }
};

export const getSchoolAddress = bp => ApiService.get(urls.getSchoolAddress(bp));

export const isSameAsShippingAddress = addressName =>
  addressName === sectionsAddresses.billingAddress[0].name;

const onZipCodeChange = ({ value, section, setValue, trigger }) => {
  if (value.length === 5) {
    getZipCodeInfo(value).then(resp => {
      if (resp) {
        setValue(`${section}.city`, resp?.cities[0].label);
        setValue(`${section}.state`, resp?.state);
        trigger(`${section}.city`);
        trigger(`${section}.state`);
      }
    });
  } else {
    setValue(`${section}.city`, null);
    setValue(`${section}.state`, null);
  }
};

const getCities = ({ section, getValues, setValue, trigger }) => {
  const zipCodesOptions = store.getState().zipCode.formattedCodeList;
  let code = getValues(`${section}.zipCode`);
  const options = zipCodesOptions?.[code]?.cities || [];
  if (options.length === 1) {
    // use setTimeout to allow component to render before City value is updated (prevent unit tests error)
    setTimeout(() => {
      setValue(`${section}.city`, options[0].label);
      trigger(`${section}.city`);
    }, 300);
  }
  return options;
};

export const createNewAddressFields = ({
  section,
  setValue,
  getValues,
  trigger,
}) => {
  return [
    {
      name: `${section}.firstName`,
      label: appStrings.common.firstName,
      type: 'text',
      validations: {
        required: true,
        pattern: {
          value: nameStringPattern,
          message: appStrings.validationMessages.nameString,
        },
        maxLength: 50,
      },
      gridLayout: { xs: 6 },
    },
    {
      name: `${section}.lastName`,
      label: appStrings.common.lastName,
      type: 'text',
      validations: {
        required: true,
        pattern: {
          value: nameStringPattern,
          message: appStrings.validationMessages.nameString,
        },
        maxLength: 50,
      },
      gridLayout: { xs: 6 },
    },
    {
      name: `${section}.email`,
      label: appStrings.studentProfile.emailAddress,
      type: 'text',
      validations: {
        required: true,
        validate: {
          emailDomain: value =>
            !value.includes(snaponDomain) ||
            appStrings.validationMessages.emailBadDomain,
          emailPattern: value =>
            emailPattern.test(value) ||
            appStrings.validationMessages.emailFormat,
        },
        maxLength: 100,
      },
      gridLayout: { xs: 6 },
    },
    {
      name: `${section}.phoneNumber`,
      label: appStrings.studentProfile.phoneNumber,
      type: 'tel',
      validations: {
        required: true,
        minLength: 10,
        maxLength: 10,
      },
      gridLayout: { xs: 6 },
      helperText: appStrings.validationMessages.xDigitsOnly(10),
      maskFormat: phoneMask,
    },
    {
      name: `${section}.addressLine1`,
      label: appStrings.order.addressLine1,
      type: 'text',
      validations: {
        required: true,
        pattern: {
          value: addressPattern,
          message: appStrings.validationMessages.address,
        },
        maxLength: 50,
      },
      gridLayout: { xs: 8 },
    },
    {
      name: `${section}.addressLine2`,
      label: appStrings.order.addressLine2,
      type: 'text',
      validations: {
        pattern: {
          value: addressPattern,
          message: appStrings.validationMessages.address,
        },
        maxLength: 50,
      },
      gridLayout: { xs: 4 },
    },
    {
      name: `${section}.zipCode`,
      label: appStrings.common.zipCode,
      type: 'text',
      validations: {
        required: true,
        minLength: {
          value: 5,
          message: appStrings.validationMessages.xDigitsOnly(5),
        },
        pattern: {
          value: digitPattern,
          message: appStrings.validationMessages.xDigitsOnly(5),
        },
        maxLength: 5,
      },
      onChangeCallback: value =>
        onZipCodeChange({ value, section, setValue, trigger }),
      gridLayout: { xs: 6 },
    },
    {
      name: `${section}.city`,
      label: appStrings.common.city,
      type: 'select',
      validations: {
        required: true,
      },
      options: getCities({ section, getValues, setValue, trigger }),
      gridLayout: { xs: 6 },
    },
    {
      name: `${section}.state`,
      label: appStrings.common.state,
      type: 'text',
      validations: {
        required: true,
      },
      gridLayout: { xs: 6 },
      readOnly: true,
    },
  ];
};

export const saveShippingAndBilling = (studentId, data) => {
  const info = {
    studentId,
    shippingAddress: {
      address: { ...data.shippingAddress },
      isDefault:
        data.shippingAddress.checkboxName ===
        addressesTypes.permanentHomeAddress,
      isSchoolAddress:
        data.shippingAddress.checkboxName === addressesTypes.schoolAddress,
    },
    billingAddress: {
      address: { ...data.billingAddress },
      isDefault:
        data.billingAddress.checkboxName ===
        addressesTypes.permanentHomeAddress,
      isSameAsShipping:
        data.billingAddress.checkboxName ===
        addressesTypes.sameAsShippingAddress,
    },
  };

  return ApiService.put(urls.saveOrderAddresses, info);
};

export const checkCompleteOrderStudent = ({
  studentId,
  shippingAddress,
  billingAddress,
  amount,
}) => {
  store.dispatch(displayLoading(appStrings.order.validatingOrder));
  store.dispatch(clearNotifications());
  startSignalRConnection(store.getState().login.userDetails.accountIdentifier)
    .then(() => {
      saveOrder({ studentId, shippingAddress, billingAddress, amount }).catch(
        () => {
          closeSignalRConnection();
          store.dispatch(hideLoading());
        }
      );
      trackGAEvent(
        `Complete Order using ${paymentMethodLabel[0]}`,
        'Order and Checkout',
        studentId
      );
      trackGTMEvent('checkout-click');
    })
    .catch(() => {
      store.dispatch(hideLoading());
    });
};

const saveOrder = ({ studentId, shippingAddress, billingAddress, amount }) => {
  return ApiService.post(urls.saveOrderStudent, {
    studentId,
    payment: {
      type: 0,
      amount,
    },
    userId: store.getState().login.userDetails.accountIdentifier,
    callbackMethodName: 'checkedSaveOrder',
    shippingAddress,
    billingAddress,
  });
};

export const saveOrderCallbackStudent = ({
  saveOrderStatus,
  setShowCardDialog,
}) => {
  if (saveOrderStatus?.state === connectionResponseState.done) {
    store.dispatch(hideLoading());
    setShowCardDialog(true);
  }
};

export const getTax = (studentId, shippingAddress, lineItems) => {
  const info = {
    studentId,
    shippingAddress,
    lineItems,
  };

  return ApiService.post(urls.getTax, info);
};

export const shouldDisablePlaceOrderBtn = ({
  editingAddresses,
  totalsInfo,
  loading,
}) =>
  editingAddresses.shippingAddress ||
  editingAddresses.billingAddress ||
  totalsInfo.taxErrorMessage ||
  loading.shippingAddress ||
  loading.billingAddress;
