export const profileInfosConstants = [
  {
    label: 'Purchasing Limit: ',
    name: 'purchasingLimit',
  },
  {
    label: 'Email Address:',
    name: 'email',
  },
  { label: 'Student Phone Number: ', name: 'phoneNumber' },
  { label: 'Student Address: ', name: 'address' },
  {
    label: 'School Name:',
    name: 'school',
  },
  {
    label: 'Graduation Date:',
    name: 'graduationDate',
  },
  {
    label: 'Instructor Name:',
    name: 'instructorName',
  },
  {
    label: 'Account Manager Name:',
    name: 'accountManagerName',
  },
  {
    label: 'Account Manager Email:',
    name: 'accountManagerEmail',
  },
  {
    label: 'Account Manager Phone Number:',
    name: 'accountManagerPhoneNumber',
  },
];
