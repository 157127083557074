import {
  EC_APP_WIZARD,
  STUDENTS,
  STUDENT_PROFILE,
} from '../../../core/navigation/paths';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  addressesErrorFields,
  customerInfoErrorFields,
  employerInfoErrorFields,
  referencesErrorFields,
  schoolInfoErrorFields,
} from './studentProfileConstants';
import {
  buttonTypes,
  loadingStatuses,
  studentsApprovalState,
} from '../../../core/strings/appConstants';
import {
  getECAppBtnHoverMessage,
  onFormError,
  onFormSubmit,
  validateDatePickers,
} from './Services/studentProfileService';
import {
  selectedStudent,
  updateEditedStudent,
} from '../../../core/redux/slices/schoolsWithStudentsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import CustomBox from '../../shared/CustomBox/CustomBox';
import CustomButton from '../../shared/CustomButton/CustomButton';
import CustomIcon from '../../shared/CustomIcon/CustomIcon';
import { Grid, Tooltip } from '@mui/material';
import LeaveDialog from '../../shared/LeaveDialog/LeaveDialog';
import NameCard from '../NameCard/NameCard';
import PurchasePower from '../PurchasePower/PurchasePower';
import StudentProfileForm from '../../shared/StudentProfileForm/StudentProfileForm';
import StudentProfileSkeleton from './StudentProfileSkeleton';
import appStrings from '../../../core/strings/appStrings';
import { clearEmptySections } from './Services/clearEmptySectionsService';
import { dialogBtnActions } from '../../shared/LeaveDialog/leaveDialogConstants';
import { icons } from '../../../core/strings/icons';
import { purchaseTypes } from '../PurchasePower/purchasePowerConstants';
import { useForm } from 'react-hook-form';
import useStudentProfileStyles from './useStudentProfileStyles';
import { getStudentDetails } from '../../shared/StudentProfileForm/studentProfileFormService';
import { setEcAppBtnEnabled } from '../../../core/redux/slices/commonSlice';
import { updateTotals } from '../Cart/cartService';

function StudentProfile() {
  const { id, bp } = useParams();
  const navigate = useNavigate();
  const isComponentMount = useRef(false);
  const { employeeId } = useSelector(state => state.login.userDetails);
  const initialNavigateLength = navigate.length;
  const [isLoading, setIsLoading] = useState(true);
  const [sectionErrors, setSectionErrors] = useState({
    customerInfo: false,
    schoolInfo: false,
    employerInfo: false,
    addresses: false,
    references: false,
  });
  const [oldErrorsCount, setOldErrorsCount] = useState(0);
  const [formWasSubmitted, setFormWasSubmitted] = useState(false);
  const [emptySections, setEmptySections] = useState({
    currentAddress: false,
    previousAddresses: null,
    presentEmployer: false,
    futureEmployer: false,
    previousEmployers: null,
    emptyRefs: null,
  });
  const isECAppBtnEnabled = useSelector(
    state => state.common.isEcAppBtnEnabled
  );

  const student = useSelector(
    state => state.schoolsWithStudents.selectedStudent.student
  );
  const schoolsLoading = useSelector(
    state => state.schoolsWithStudents.loading
  );
  const css = useStudentProfileStyles().classes;
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    formState,
    reset,
    setError,
  } = useForm();
  const { isDirty, errors } = formState;

  const isActiveFieldName = 'isActive';
  const isActiveToggleWatch = watch(isActiveFieldName);
  const [studentData, setStudentData] = useState(null);
  const [customerInfo, setCustomerInfo] = useState({});

  const navigateToSchoolsPage = approvalStatus => {
    if (
      approvalStatus !== undefined &&
      approvalStatus !== studentsApprovalState.approved
    ) {
      navigate(STUDENTS(employeeId));
    }
  };

  useEffect(() => {
    if (studentData) {
      if (isActiveToggleWatch === undefined) {
        dispatch(setEcAppBtnEnabled(studentData?.isCreditAppEnabled));
      } else {
        dispatch(
          setEcAppBtnEnabled(
            isActiveToggleWatch && studentData?.isCreditAppEnabled
          )
        );
      }
    }
  }, [
    dispatch,
    isActiveToggleWatch,
    studentData,
    studentData?.isCreditAppEnabled,
  ]);

  useEffect(() => {
    if (isComponentMount.current) {
      getStudentDetails(id).then(studentDetailsRes => {
        setStudentData(studentDetailsRes);
        dispatch(
          selectedStudent({
            bpNumber: bp,
            student: {
              ...studentDetailsRes,
              active: studentDetailsRes.studentAccountStatus === 'Active',
            },
          })
        );
      });
    }
  }, [dispatch, bp, id]);

  useEffect(() => {
    isComponentMount.current = true;
  }, []);

  const onSubmit = (data, pageLeaveAndSaveTimeout = false) => {
    if (isDirty) {
      dispatch(setEcAppBtnEnabled(false));
      clearEmptySections(data, setEmptySections);
      onFormSubmit({
        data,
        pageLeaveAndSaveTimeout,
        id,
        setValue,
        setFormWasSubmitted,
        setSectionErrors,
        refreshSectionErrors,
        setError,
        reset,
        saveCallback,
        dispatch,
        navigateToSchoolsPage,
      });
    }
  };

  const onError = errorList => {
    onFormError({
      setFormWasSubmitted,
      refreshSectionErrors,
      errorList,
    });
  };

  const saveCallback = (data, pageLeaveAndSaveTimeout) => {
    updateTotals(id);
    dispatch(
      updateEditedStudent({
        id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        schoolBp: data.schoolBp,
        isActive: data.isActive,
      })
    );
    dispatch(
      selectedStudent({
        bpNumber: bp,
        student: data,
      })
    );

    // update url with the bp of the new school
    if (
      data.schoolBp &&
      navigate.length === initialNavigateLength &&
      bp !== data.schoolBp &&
      !pageLeaveAndSaveTimeout
    ) {
      navigate(STUDENT_PROFILE(data.schoolBp, id), { replace: true });
    }

    getStudentDetails(id).then(studentDetailsRes =>
      setStudentData(studentDetailsRes)
    );
  };

  const callDataOnSubmit = data => {
    onSubmit(data);
  };

  const refreshSectionErrors = useCallback(
    errorList => {
      const finalErrors = errorList ? errorList : errors;
      const newSectionErrors = {
        customerInfo: false,
        schoolInfo: false,
        employerInfo: false,
        addresses: false,
        references: false,
      };
      Object.keys(finalErrors).forEach(prop => {
        newSectionErrors.customerInfo =
          newSectionErrors.customerInfo ||
          customerInfoErrorFields.indexOf(prop) !== -1;
        newSectionErrors.schoolInfo =
          newSectionErrors.schoolInfo ||
          schoolInfoErrorFields.indexOf(prop) !== -1;
        newSectionErrors.employerInfo =
          newSectionErrors.employerInfo ||
          employerInfoErrorFields.indexOf(prop) !== -1;
        newSectionErrors.addresses =
          newSectionErrors.addresses ||
          addressesErrorFields.indexOf(prop) !== -1;
        newSectionErrors.references =
          newSectionErrors.references ||
          referencesErrorFields.indexOf(prop) !== -1;
      });
      const dateValidations = validateDatePickers();
      Object.keys(newSectionErrors).forEach(prop => {
        newSectionErrors[prop] =
          newSectionErrors[prop] || dateValidations.errors[prop];
      });
      setSectionErrors({
        customerInfo: newSectionErrors.customerInfo,
        schoolInfo: newSectionErrors.schoolInfo,
        employerInfo: newSectionErrors.employerInfo,
        addresses: newSectionErrors.addresses,
        references: newSectionErrors.references,
      });
      return dateValidations.valid;
    },
    [errors]
  );

  const dialogData = {
    title: appStrings.modal.editProfile.title,
    description: appStrings.modal.editProfile.description,
    icon: <CustomIcon icon={icons.editProfile} customClasses={css.leaveIcon} />,
    isDirty,
    reset,
    watch,
    onSubmit,
    handleSubmit,
    buttons: [
      dialogBtnActions.stay,
      dialogBtnActions.leaveNoSave,
      dialogBtnActions.leaveAndSave,
    ],
    errors,
    dispatch,
    id: 'unsavedChangesDialog',
  };

  const showLoading = isLoading || schoolsLoading === loadingStatuses.pending;
  const redirectToECAppWizard = () => {
    navigate(EC_APP_WIZARD(bp, id));
  };

  useEffect(() => {
    if (Object.keys(formState.errors).length !== oldErrorsCount) {
      refreshSectionErrors();
      setOldErrorsCount(Object.keys(formState.errors).length);
    }
  }, [formState, oldErrorsCount, refreshSectionErrors]);

  useEffect(() => {
    setCustomerInfo(prev => ({ ...prev, ...student }));
  }, [student]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={css.container}
    >
      {showLoading ? <StudentProfileSkeleton /> : null}
      <form
        name="studentProfile"
        onSubmit={handleSubmit(callDataOnSubmit, onError)}
        noValidate
        autoComplete="off"
        style={{
          display: showLoading ? 'none' : 'block',
        }}
      >
        <NameCard student={student}>
          <Grid item container xs justifyContent="flex-end">
            <Tooltip
              title={getECAppBtnHoverMessage(!isECAppBtnEnabled, isDirty)}
              placement="left"
              arrow
              enterTouchDelay={0}
            >
              <div>
                <CustomButton
                  btnType={buttonTypes.secondary}
                  label={appStrings.studentProfile.ECApp}
                  onClick={redirectToECAppWizard}
                  id="redirectToEcAppBtn"
                  disabled={!isECAppBtnEnabled || isDirty}
                />
              </div>
            </Tooltip>

            <CustomButton
              id="saveProfileInformationBtn"
              label={appStrings.studentProfile.saveProfile}
              data-test-id="saveProfileInformationBtn"
              onClick={handleSubmit(callDataOnSubmit, onError)}
              disabled={!isDirty}
              customClass={css.saveProfile}
            />
          </Grid>
        </NameCard>

        <CustomBox customClass={css.purchasePowerContainer}>
          <PurchasePower purchaseType={purchaseTypes.none} />
        </CustomBox>

        <CustomBox customClass={css.profileContainer}>
          <StudentProfileForm
            formResources={{
              handleSubmit,
              control,
              setValue,
              getValues,
              watch,
              isDirty,
              errors,
              reset,
              dispatch,
              onSubmit: callDataOnSubmit,
            }}
            id={id}
            setLoading={setIsLoading}
            sectionErrors={sectionErrors}
            refreshSectionErrors={refreshSectionErrors}
            formWasSubmitted={formWasSubmitted}
            emptySections={emptySections}
            customerInfo={customerInfo}
            setCustomerInfo={setCustomerInfo}
          />
        </CustomBox>
      </form>
      <LeaveDialog {...dialogData} />
    </Grid>
  );
}

export default StudentProfile;
