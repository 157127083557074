import { useEffect, useState } from 'react';

import CustomAccordion from '../../../shared/CustomAccordion/CustomAccordion';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import CustomPagination from '../../../shared/CustomPagination/CustomPagination';
import CustomTable from '../../../shared/CustomTable/CustomTable';
import { Grid } from '@mui/material';
import appStrings from '../../../../core/strings/appStrings';
import { getDataToDisplayFromTo } from '../../../shared/CustomPagination/customPaginationService';
import { lineItemsTableColumns } from '../orderHistoryDetailsConstants';
import useOrderHistoryDetailsStyles from '../useOrderHistoryDetailsStyles';

function OrderedItems({ orderDetails }) {
  const css = useOrderHistoryDetailsStyles().classes;
  const [rowData] = useState((orderDetails?.lines ?? []).map(lineData => {
    return {
      ...lineData,
      inventorySource: lineData.warehouse?.name,
    };
  }));

  const [paginationDetails, setPaginationDetails] = useState({
    from: 0,
    to: 10,
    itemsPerPage: 10,
    page: 0,
  });

  const [displayedProducts, setDisplayedProducts] = useState(
    rowData?.slice(0, paginationDetails.itemsPerPage) || []
  );

  useEffect(() => {
    if (rowData) {
      const newDisplayedProds = getDataToDisplayFromTo(
        rowData,
        paginationDetails.from,
        paginationDetails.to
      );
      setDisplayedProducts(newDisplayedProds);
    }
  }, [
    paginationDetails.from,
    paginationDetails.to,
    orderDetails?.lines,
    rowData,
  ]);

  return !orderDetails ? null : (
    <Grid item xs={12} data-test-id="orderHistoryDetails-orderedItems">
      <CustomBox>
        <CustomAccordion
          title={appStrings.orderDetailsHistory.orderedItems}
          customClassDescription={css.accordion}
          identifier="historyOrderDetails-orderedItems"
        >
          {rowData?.length && (
            <Grid container>
              <Grid item xs={12}>
                <CustomTable
                  columns={lineItemsTableColumns}
                  rowData={displayedProducts}
                  useIndex
                  currentPage={paginationDetails.page}
                  itemsPerPage={paginationDetails.itemsPerPage}
                  rowCount={rowData?.length}
                  tableId={'orderedItems'}
                  customClass={css.orderLinesTable}
                />
              </Grid>

              <Grid item xs={12}>
                <CustomPagination
                  itemsLength={rowData.length}
                  itemsPerPage={paginationDetails.itemsPerPage}
                  id={'orderDetailsHistoryLineItemsPagination'}
                  onPageChangeCallback={({ from, to, page, itemsPerPage }) => {
                    const newDisplayedProds = getDataToDisplayFromTo(
                      rowData,
                      from,
                      to
                    );
                    setDisplayedProducts(newDisplayedProds);
                    setPaginationDetails({ from, to, page, itemsPerPage });
                  }}
                />
              </Grid>
            </Grid>
          )}
        </CustomAccordion>
      </CustomBox>
    </Grid>
  );
}

export default OrderedItems;
