import { Grid } from '@mui/material';
import React from 'react';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import appStrings from '../../../../core/strings/appStrings';
import PropTypes from 'prop-types';
import {
  buttonColors,
  buttonTypes,
  cartTypes,
} from '../../../../core/strings/appConstants';
import { useSelector } from 'react-redux';
import AddSaveButtonsLoading from './AddSaveButtonsLoading';
import useProductPageStyles from '../productPageStyles';
import { addProductToCart } from '../../ShoppingCart/ShoppingCartService';

function AddSaveButtons({ productDetails, isLoggedIn, userDetails, dispatch }) {
  const css = useProductPageStyles().classes;
  const loadingAddProduct = useSelector(
    state => state.guestCart.loadingAddProduct
  );
  const isLoading =
    loadingAddProduct.indexOf(productDetails.productNumber) !== -1;

  const handleAddToCart = ({ cartType }) =>
    addProductToCart(
      productDetails,
      dispatch,
      isLoggedIn,
      userDetails.userId,
      cartType
    );

  return !isLoading ? (
    <Grid
      item
      container
      rowGap={2}
      className={css.addSaveButtons}
      id="ProductPageAddSaveButtons"
    >
      <Grid item xs={12}>
        <CustomButton
          color={buttonColors.red}
          data-test-id="addToCartButton"
          id="productPage-addToCart"
          size="large"
          label={appStrings.cart.addToCart}
          onClick={() => handleAddToCart({ cartType: cartTypes.cart })}
          disabled={isLoggedIn && !userDetails.isActive}
        />
      </Grid>
      <Grid item xs={12}>
        {isLoggedIn && (
          <CustomButton
            data-test-id="savedItemButton"
            id="productPage-saveItem"
            size="large"
            btnType={buttonTypes.secondary}
            label={appStrings.cart.addToSavedItems}
            onClick={() => handleAddToCart({ cartType: cartTypes.saved })}
            disabled={!userDetails.isActive}
          />
        )}
      </Grid>
    </Grid>
  ) : (
    <AddSaveButtonsLoading />
  );
}

AddSaveButtons.propTypes = {
  productDetails: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  userDetails: PropTypes.object,
  dispatch: PropTypes.func,
};

export default AddSaveButtons;
