import { Grid, Skeleton } from '@mui/material';
import React from 'react';

function FiltersSkeleton() {
  return (
    <Grid item id="approveRejectFiltersAndSearchContainerSkeleton">
      <Grid container spacing={2} justifyContent="flex-end">
        {Array(3)
          .fill(true)
          .map((_, index) => (
            <Grid item key={index} xs={4}>
              <Skeleton variant="rounded" height={37} />
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
}

export default FiltersSkeleton;
