import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { setSchoolListRep } from '../redux/slices/schoolsWithStudentsSlice';
import {
  saveStudentsListApproveReject,
  setApproveRejectRep,
} from '../redux/slices/approveRejectSlice';
import { clearEmptyCategories, getCategories } from '../../modules/student/Category/CategoriesMenu/categoriesMenuService';
import { setCategories } from '../redux/slices/categoriesSlice';
import { displayError } from '../redux/slices/notificationsSlice';

function GuestRoutesServices() {
  const schoolListSelectedRep = useSelector(
    state => state.schoolsWithStudents.schoolListSelectedRep
  );
  const approveRejectRep = useSelector(
    state => state.approveReject.approveRejectRep
  );
  const categories = useSelector(state => state.categories.categoriesData);
  const studentsListApproveReject = useSelector(
    state => state.approveReject.studentsList
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (schoolListSelectedRep) {
      dispatch(setSchoolListRep(null));
    }
    if (approveRejectRep) {
      dispatch(setApproveRejectRep(null));
    }
    if (studentsListApproveReject) {
      dispatch(saveStudentsListApproveReject({ resp: null }));
    }
  }, [
    approveRejectRep,
    dispatch,
    schoolListSelectedRep,
    studentsListApproveReject,
  ]);

  useEffect(() => {
    if (!categories) {
      getCategories()
        .then(resp => {
          if (resp[0]) {
            resp[0].subCategories = resp[0].subCategories.filter(clearEmptyCategories);
            dispatch(setCategories(resp[0]));
          }
        })
        .catch(() => dispatch(displayError()));
    }
  }, [categories, dispatch]);

  return <Outlet />;
}

export default GuestRoutesServices;
