import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import RepSearch from '../../RepSearch/RepSearch';
import StudentSearch from '../../StudentSearch/StudentSearch';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import FormInput from '../../../shared/FormInput/FormInput';
import {
  saveStudentsListApproveReject,
  setApproveRejectRep,
  setSchoolsList,
  setSelectedSchool,
} from '../../../../core/redux/slices/approveRejectSlice';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import {
  formatSchoolsList,
  getAllSchools,
  getStudentsBySchool,
} from './filtersService';
import PropTypes from 'prop-types';
import FiltersSkeleton from './FiltersSkeleton';
import appStrings from '../../../../core/strings/appStrings';

function Filters({ isAdmin, setIsLoading, isLoading }) {
  const dispatch = useDispatch();
  const schoolsList = useSelector(state => state.approveReject.schoolsList);
  const selectedRepValue = useSelector(
    state => state.approveReject.approveRejectRep
  );
  const selectedSchool = useSelector(
    state => state.approveReject.selectedSchool
  );
  const [schoolsListLoading, setSchoolsListLoading] = useState(false);
  const [repListLoading, setRepListLoading] = useState(false);

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const fieldName = 'schoolFilter';
  const watchSchoolFilter = watch(fieldName);

  const schoolFilterField = {
    name: fieldName,
    label: 'Select a school',
    type: 'autocomplete',
    defaultValue: selectedSchool,
    disableClearable: true,
    options: schoolsList || [],
    validations: { required: false },
  };

  useEffect(() => {
    //get schools list
    if (!schoolsList && isAdmin) {
      setSchoolsListLoading(true);
      getAllSchools()
        .then(resp => {
          const formattedSchoolsList = formatSchoolsList(resp);
          dispatch(setSchoolsList(formattedSchoolsList));
        })
        .catch(() => dispatch(displayError()))
        .finally(() => setSchoolsListLoading(false));
    }
  }, [dispatch, isAdmin, schoolsList, setSchoolsListLoading]);

  useEffect(() => {
    // get students by school
    if (watchSchoolFilter && selectedSchool !== watchSchoolFilter) {
      setIsLoading(true);
      dispatch(setSelectedSchool(watchSchoolFilter));
      getStudentsBySchool(watchSchoolFilter)
        .then(resp => {
          dispatch(
            saveStudentsListApproveReject({
              resp: resp,
            })
          );
        })
        .catch(() => {
          dispatch(displayError());
        })
        .finally(() => setIsLoading(false));
    }
  }, [dispatch, selectedSchool, setIsLoading, watchSchoolFilter]);

  // reset school filter when use rep filter
  useEffect(() => {
    if (selectedRepValue) {
      setValue(fieldName, null);
      dispatch(setSelectedSchool(null));
    }
  }, [dispatch, selectedRepValue, setValue]);

  return (schoolsListLoading || repListLoading || isLoading) && isAdmin ? (
    <FiltersSkeleton />
  ) : (
    <Grid item xs={12} id="approveRejectFiltersAndSearchContainer">
      <Grid
        container
        spacing={2}
        justifyContent="flex-end"
        id="approveRejectFilters"
      >
        {isAdmin && (
          <>
            <Grid item xs>
              <RepSearch
                selectedRep={selectedRepValue}
                setSelectedRep={setApproveRejectRep}
                setLoading={setRepListLoading}
                approveReject={true}
              />
            </Grid>
            <Grid
              item
              xs={0.5}
              container
              alignContent="center"
              justifyContent="center"
            >
              {appStrings.common.or}
            </Grid>
            <Grid item xs>
              <form>
                <FormInput
                  field={schoolFilterField}
                  control={control}
                  errors={errors}
                />
              </form>
            </Grid>
          </>
        )}
        <Grid item xs id="approveRejectSearch">
          <StudentSearch approveReject />
        </Grid>
      </Grid>
    </Grid>
  );
}

Filters.propTypes = {
  isAdmin: PropTypes.bool,
  setIsLoading: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default Filters;
