import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import useProductPageStyles from '../productPageStyles';

function ProductWarnings({warningIconsUrl, warningIcons}) {
  const css = useProductPageStyles().classes;

  return (
    <Grid data-test-id="renderedWarnings" id="productPageWarningsContainer" container className={css.warningImages}>
      {warningIcons.map(warning =>
        <img
          id={`productPageWarning-${warning}`}
          loading="lazy"
          src={`${warningIconsUrl}${warning}?auto=format`}
          key={warning}
          alt={warning}
          title={warning}
        />)}
    </Grid>
  );
}

ProductWarnings.propTypes = {
  warningIconsUrl: PropTypes.string,
  warningIcons: PropTypes.arrayOf(PropTypes.string)
}

export default ProductWarnings;