import { Grid, Typography } from '@mui/material';
import {
  saveSelectedInventoryList,
  updateLoadingState,
} from '../../../../core/redux/slices/consignedInventorySlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { ADMIN } from '../../../../core/navigation/roles';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import CustomPagination from '../../../shared/CustomPagination/CustomPagination';
import CustomTable from '../../../shared/CustomTable/CustomTable';
import NoDataAvailable from '../../../shared/NoDataAvailable/NoDataAvailable';
import appStrings from '../../../../core/strings/appStrings';
import { columns } from './consignedInventoryDetailsConstants';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import { getConsignedInventoryDetailsByCode } from '../consignedInventoryService';
import { getDataToDisplayFromTo } from '../../../shared/CustomPagination/customPaginationService';
import { icons } from '../../../../core/strings/icons';
import useConsignedInventoryDetailsStyle from './useConsignedInventoryDetailsStyles';

function ConsignedInventoryDetails() {
  const dispatch = useDispatch();
  const css = useConsignedInventoryDetailsStyle().classes;

  const {
    selectedInventoryCode,
    selectedInventoryList,
    selectedInventoryName,
    lastSelectedInventoryCode,
  } = useSelector(state => state.consignedInventory);

  const isAdmin = useSelector(state => state.login.userDetails.role === ADMIN);

  const [paginationDetails, setPaginationDetails] = useState({
    from: 0,
    to: 10,
    itemsPerPage: 10,
    page: 0,
  });
  const [displayedProducts, setDisplayedProducts] = useState(
    selectedInventoryList?.slice(0, paginationDetails.itemsPerPage) || []
  );
  const getNoDataAvailableMessage = () => {
    if (isAdmin && !selectedInventoryCode) {
      return appStrings.consigned.pleaseSelectWarehouse;
    }
    if (!selectedInventoryCode) {
      return appStrings.consigned.noInventoryAvailableMsg;
    }
    return appStrings.consigned.noItemsAvailableMsg;
  };

  useEffect(() => {
    if (
      selectedInventoryCode &&
      lastSelectedInventoryCode !== selectedInventoryCode
    ) {
      dispatch(updateLoadingState(true));
      getConsignedInventoryDetailsByCode(selectedInventoryCode)
        .then(res => {
          dispatch(
            saveSelectedInventoryList({
              list: res,
              code: selectedInventoryCode,
            })
          );
        })
        .catch(e => {
          dispatch(displayError({ message: JSON.stringify(e) }));
          dispatch(updateLoadingState(false));
        });
    }
  }, [dispatch, lastSelectedInventoryCode, selectedInventoryCode]);

  useEffect(() => {
    if (selectedInventoryList) {
      const newDisplayedProds = getDataToDisplayFromTo(
        selectedInventoryList,
        paginationDetails.from,
        paginationDetails.to
      );
      setDisplayedProducts(newDisplayedProds);
    } else {
      setDisplayedProducts([]);
    }
  }, [paginationDetails.from, paginationDetails.to, selectedInventoryList]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={css.container}
      data-test-id="consignedInventoryDetails"
    >
      {selectedInventoryList?.length ? (
        <Grid item>
          <Typography variant="h3" className={css.subTitle}>
            {`${appStrings.consigned.consignedInventoryReport} for ${selectedInventoryCode} ${selectedInventoryName}`}
          </Typography>
        </Grid>
      ) : null}

      {displayedProducts?.length ? (
        <Grid item>
          <CustomTable
            columns={columns}
            rowData={displayedProducts}
            currentPage={paginationDetails.page}
            itemsPerPage={paginationDetails.itemsPerPage}
            rowCount={selectedInventoryList?.length}
            tableId={'consignedInventory'}
          />
        </Grid>
      ) : null}

      {selectedInventoryList?.length ? (
        <Grid item xs={12} className={css.pagination}>
          <CustomPagination
            itemsLength={selectedInventoryList.length}
            itemsPerPage={paginationDetails.itemsPerPage}
            id={'inventoryProductsList'}
            onPageChangeCallback={({ from, to, page, itemsPerPage }) => {
              const newDisplayedProds = getDataToDisplayFromTo(
                selectedInventoryList,
                from,
                to
              );
              setDisplayedProducts(newDisplayedProds);
              setPaginationDetails({ from, to, page, itemsPerPage });
            }}
          />
        </Grid>
      ) : null}

      {!selectedInventoryList?.length || !selectedInventoryCode ? (
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          data-test-id="noDataAvailableContainer"
        >
          <Grid item>
            <CustomBox customClass={css.customBox}>
              <NoDataAvailable
                message={getNoDataAvailableMessage()}
                icon={icons.warehouse1}
              />
            </CustomBox>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
}

export default ConsignedInventoryDetails;
