import React, { useEffect, useState } from 'react';
import useProductStyles from './productPageStyles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';
import CustomBreadcrumbs from '../../shared/CustomBreadcrumbs/CustomBreadcrumbs';
import { CATEGORY_ID } from '../../../core/navigation/paths';
import appStrings from '../../../core/strings/appStrings';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  productAccordionConstants,
  productOverviewIndex,
} from './productPageConstants';
import { Link, useParams } from 'react-router-dom';
import {
  getProductAvailability,
  getProductDetails,
  shouldDisplayAccordion,
} from './productPageService';
import { displayError } from '../../../core/redux/slices/notificationsSlice';
import ProductPageSkeleton from './ProductPageSkeleton';
import AddSaveButtons from './Components/AddSaveButtons';
import ImageCarousel from '../../shared/ImageCarousel/ImageCarousel';
import ImageZoomButton from '../../shared/ImageZoomButton/ImageZoomButton';
import ImageCarouselModal from '../../shared/ImageCarousel/ImageCarouselModal';
import BrRecommendationsWidget from '../../shared/BrRecommendationsWidget/BrRecommendationsWidget';
import {
  getBrItemRecommendationProducts,
  parseBrRecommendations,
} from '../ProductsSearchResults/productSearchResultsService';
import { trackGTMEvent } from '../../shared/Analytics/analyticsService';
import { getPageTitle } from '../../../../pageTitleValues';
import CustomButton from '../../shared/CustomButton/CustomButton';
import AvailabilityMessage from './Components/AvailabilityMessage';
import CustomRotateLoading from '../../shared/CustomRotateLoading/CustomRotateLoading';

// eslint-disable-next-line sonarjs/cognitive-complexity
function ProductPage() {
  const css = useProductStyles().classes;
  const dispatch = useDispatch();
  const { productNumber } = useParams();
  const { isLoggedIn, userDetails } = useSelector(state => state.login);
  const [expanded, setExpanded] = useState(productOverviewIndex);
  const [productDetails, setProductDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [recommendationsList, setRecommendationsList] = useState([]);
  const [currentCarouselImageIndex, setCurrentCarouselImageIndex] = useState(0);
  const [availabilityLoading, setAvailabilityLoading] = useState(false);
  const [availabilityData, setAvailabilityData] = useState(null);

  const handleExpand = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const paths = productDetails?.breadcrumb?.map(elem => ({
    link: CATEGORY_ID(elem.id),
    label: elem.name,
    id: elem.id,
  }));

  useEffect(() => {
    setIsLoading(true);
    getProductDetails(productNumber)
      .then(resp => {
        if (resp.warningIcons && resp.warningIcons.length) {
          resp.warningIcons = resp.warningIcons.filter(icon =>
            Boolean(icon.length)
          );
        }
        setProductDetails(resp);
        if (!resp.productNumber) {
          resp.productNumber = productNumber;
        }
        const { productNumber: product, name } = resp;
        setAvailabilityLoading(true);
        getProductAvailability(product)
          .then(resp => setAvailabilityData(resp))
          .catch(error => setAvailabilityData({ error }))
          .finally(() => setAvailabilityLoading(false));
        document.title = `${name || product} - ${getPageTitle()}`;
        setRecommendationsList([]);
        getBrItemRecommendationProducts(product).then(res =>
          setRecommendationsList(parseBrRecommendations(res))
        );
        trackGTMEvent('product-pageview', {
          page_type: 'product',
          product_name: name,
          product_sku: product,
          product_id: product,
          orig_ref_url: document.location.href,
        });
      })
      .catch(() => dispatch(displayError()))
      .finally(() => setIsLoading(false));
  }, [dispatch, productNumber]);

  const addSaveButtonsProps = {
    productDetails,
    isLoggedIn,
    userDetails,
    dispatch,
  };

  return productDetails && !isLoading ? (
    <Grid
      container
      spacing={3}
      className={css.productContainer}
      id="productPage"
      data-test-id="productPage-testId"
    >
      <Grid item xs={12}>
        <CustomBreadcrumbs
          listOfPaths={paths}
          currentPath={{ label: productDetails?.name }}
          isLoading={false}
          currentLinkColor={'red'}
          textDecoration="none"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography fontWeight="bold" variant="h2" id="productPage-title">
          {productDetails.name}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={9} lg={8}>
        <ImageCarouselModal
          images={
            productDetails?.additionalImagesList?.length
              ? productDetails.additionalImagesList
              : [productDetails.largeImage]
          }
          initialSlide={currentCarouselImageIndex}
          dialogOpen={dialogOpen}
          handleClose={() => setDialogOpen(false)}
        />
        <Grid
          item
          container
          justifyContent="center"
          id="productPage-image"
          className={css.productImageContainer}
        >
          <ImageCarousel
            images={
              productDetails?.additionalImagesList?.length
                ? productDetails.additionalImagesList
                : [productDetails.largeImage]
            }
            initialSlide={currentCarouselImageIndex}
            setCurrentCarouselImageIndex={setCurrentCarouselImageIndex}
          />
          {productDetails?.additionalImagesList?.length ||
          productDetails.image ? (
            <Grid container item>
              <ImageZoomButton clickHandler={() => setDialogOpen(true)} />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={3} lg={4}>
        <Grid item container spacing={3}>
          <Grid item container rowGap={1}>
            <Grid item xs={12}>
              <Typography id="productPage-productNumber">
                {appStrings.common.sku}
                <span>{` ${productDetails.productNumber}`}</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography id="productPage-listPrice">
                {appStrings.cart.listPrice}
                <span>{`: $${productDetails.listPrice}`}</span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography fontWeight="bold" id="productPage-studentPrice">
                {isLoggedIn
                  ? appStrings.common.sepDiscountPrice(
                      productDetails.studentPrice
                    )
                  : appStrings.common.signInOrRegister}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            {availabilityLoading ? (
              <Grid item container gap={1}>
                <CustomRotateLoading />
                <Typography>
                  {appStrings.productPage.loadingAvailability}
                </Typography>
              </Grid>
            ) : (
              <AvailabilityMessage {...availabilityData} />
            )}
          </Grid>
          <AddSaveButtons {...addSaveButtonsProps} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {productAccordionConstants.map(
          (elem, index) =>
            shouldDisplayAccordion(productDetails, elem.data) && (
              <Accordion
                key={index}
                disableGutters
                elevation={0}
                square
                expanded={expanded === index}
                onChange={handleExpand(index)}
                id="productPage-accordion"
              >
                <AccordionSummary
                  id="productPageAccordion-summary"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography fontWeight="bold">{elem.title}</Typography>
                </AccordionSummary>
                <AccordionDetails id="productPageAccordion-details">
                  {elem.component(productDetails)}
                </AccordionDetails>
              </Accordion>
            )
        )}
      </Grid>
      {(productDetails.isSet || productDetails.isPartOfSet) && (
        <Grid item container className={css.setsButtonsContainer} gap={2}>
          {productDetails.isSet && (
            <Link to="setmembers">
              <CustomButton
                btnType="secondary"
                label={appStrings.productPage.setMembers}
                customClass={css.setsButton}
                onClick={() => window.scrollTo(0, 0)}
              />
            </Link>
          )}
          {productDetails.isPartOfSet && (
            <Link to="sets">
              <CustomButton
                btnType="secondary"
                label={appStrings.productPage.sets}
                customClass={css.setsButton}
                onClick={() => window.scrollTo(0, 0)}
              />
            </Link>
          )}
        </Grid>
      )}
      <Grid item xs>
        <BrRecommendationsWidget
          label={appStrings.cart.frequentlyBoughtTogether}
          recommendations={recommendationsList}
        />
      </Grid>
    </Grid>
  ) : (
    <ProductPageSkeleton css={css} />
  );
}

export default ProductPage;
