import { Grid, Stack, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import EditProfileSections from './Components/EditProfileSections';
import EditContent from './Components/EditContent';
import ApplicationContractButtons from './Components/ApplicationContractButtons';

function EditProfile() {
  const isSmallDevice = useMediaQuery('(max-width:768px)');
  const [formIndex, setFormIndex] = useState(0);
  const [sectionErrors, setSectionErrors] = useState({
    customerInfo: false,
    schoolInfo: false,
    employerInfo: false,
    addresses: false,
    references: false,
  });

  const editContentProps = {
    formIndex,
    setSectionErrors,
    sectionErrors,
  };

  return (
    <Grid container spacing={isSmallDevice ? 0 : 2}>
      {!isSmallDevice ? (
        <Grid item sm={!isSmallDevice && 5} md={5} lg={4}>
          <Stack spacing={2}>
            <ApplicationContractButtons setFormIndex={setFormIndex} />
            <EditProfileSections
              setFormIndex={setFormIndex}
              sectionErrors={sectionErrors}
              formIndex={formIndex}
            />
          </Stack>
        </Grid>
      ) : (
        <Grid item xs>
          <ApplicationContractButtons setFormIndex={setFormIndex} />
          <EditProfileSections
            setFormIndex={setFormIndex}
            sectionErrors={sectionErrors}
            formIndex={formIndex}
          />
        </Grid>
      )}
      <Grid item xs>
        <EditContent {...editContentProps} />
      </Grid>
    </Grid>
  );
}

export default EditProfile;
