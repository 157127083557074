import moment from 'moment';
import { xYearsFromNow } from '../../../core/services/utilsService';

export const getHasSearchData = searchInputs => {
  const hasValue = { hasSearchData: false };

  Object.keys(searchInputs).forEach(field => {
    let fieldLength;

    if (field === 'studentStatus') {
      fieldLength = searchInputs[field] !== 'all';
    } else if (typeof searchInputs[field] === 'string') {
      fieldLength = searchInputs[field].trim().length;
    } else if (field === 'studentApprovedStatus') {
      if (Object.values(searchInputs[field]).includes(false)) {
        fieldLength = true;
      }
    } else if (typeof searchInputs[field] === 'object') {
      fieldLength = true;
    }

    if (
      field !== 'studentStatus' &&
      field !== 'studentApprovedStatus' &&
      fieldLength
    ) {
      hasValue.hasSearchData = true;
    }
    hasValue[field] = Boolean(fieldLength);
  });
  return hasValue;
};

export const sortByFirstName = (a, b) => {
  const nameA = a.firstName.toUpperCase();
  const nameB = b.firstName.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export const sortByName = (a, b) => {
  const nameA = (a.firstName + a.lastName).replace(/ /g, '').toUpperCase();
  const nameB = (b.firstName + b.lastName).replace(/ /g, '').toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export const sortByScore = (a, b) => {
  if (a.score < b.score) {
    return 1;
  }
  if (a.score > b.score) {
    return -1;
  }
  return 0;
};

export const formatStudentsList = list => {
  const result = [];

  list.forEach(student => {
    result.push({
      ...student,
      email: student.email ?? (student.emailAddress || ''),
      // Student phone number from the DB has an inconsistent format, remove dashes and whitespace
      phoneNumber: student.phoneNumber
        ? student.phoneNumber.replace(/[\s-]/g, '')
        : '',
    });
  });

  return result;
};

export const getListOfAllStudents = schools => {
  const result = [];
  schools?.forEach(school => {
    school?.students.forEach(student => {
      result.push({
        ...student,
        bpNumber: school.bpNumber,
        schoolName: school.schoolName,
        email: student.email ?? (student.emailAddress || ''),
      });
    });
  });
  return result;
};

/**
 * Compare if date to check is between the 2 values received
 * All dates should be formatted as 'MM/DD/yyyy' - dateFormat in appConstants.js
 * @param {string} dateCheck - iso format
 * @param {string} dateFrom
 * @param {string} dateTo
 */
export const isDateBetween = (dateCheck, dateFrom = '', dateTo = '') => {
  const startDate = moment(
      dateFrom ? new Date(dateFrom) : new Date('1/1/1900')
    ).startOf('day'),
    endDate = moment(
      dateTo ? new Date(dateTo) : new Date(xYearsFromNow(10))
    ).endOf('day'),
    date = moment(new Date(dateCheck));

  return moment(date).isBetween(startDate, endDate, undefined, '[]');
};
