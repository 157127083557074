import CustomAccordionSkeleton from '../../shared/CustomSkeleton/CustomAccordionSkeleton';
import CustomBox from '../../shared/CustomBox/CustomBox';
import CustomSkeleton from '../../shared/CustomSkeleton/CustomSkeleton';
import { Grid } from '@mui/material';
import PurchasePowerSkeleton from '../PurchasePower/PurchasePowerSkeleton';
import useStudentProfileStyles from './useStudentProfileStyles';

function StudentProfileSkeleton() {
  const css = useStudentProfileStyles().classes;
  const topSection = [
    {
      xs: 10,
      width: '15%',
      variant: 'rect',
      height: '100%',
    },
    {
      xs: 1,
      width: '95%',
      variant: 'rect',
      justifyContent: 'flex-end',
      container: true,
    },
    {
      xs: 1,
      width: '95%',
      variant: 'rect',
      justifyContent: 'flex-end',
      container: true,
    },
  ];

  const accordionLayout = [
    {
      xs: 11,
      width: '20%',
      height: '100%',
      variant: 'text',
    },
    {
      xs: 1,
      width: '1.7rem',
      height: '1.7rem',
      variant: 'circular',
      container: true,
      justifyContent: 'flex-end',
    },
  ];

  const titleSkeleton = <CustomSkeleton layout={accordionLayout} />;

  return (
    <Grid data-test-id="studentProfileSkeleton">
      <CustomSkeleton layout={topSection}></CustomSkeleton>
      <CustomBox customClass={css.purchasePowerContainer}>
        <PurchasePowerSkeleton standard={true} />
      </CustomBox>
      <CustomAccordionSkeleton
        numberOfSections={10}
        titleSkeleton={titleSkeleton}
      />
    </Grid>
  );
}

export default StudentProfileSkeleton;
