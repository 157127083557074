import CustomBox from '../../shared/CustomBox/CustomBox';
import CustomSkeleton from '../../shared/CustomSkeleton/CustomSkeleton';
import { Grid } from '@mui/material';
import PurchasePowerSkeleton from '../PurchasePower/PurchasePowerSkeleton';
import { Skeleton } from '@mui/material';
import useStudentProfileStyles from '../StudentProfile/useStudentProfileStyles';

function HistorySkeleton() {
  const css = useStudentProfileStyles().classes;
  const topLayout = [
    { xs: 12, width: '17rem', height: '1.7rem', variant: 'rect' },
  ];

  return (
    <Grid data-test-id="historySkeleton">
      <CustomSkeleton layout={topLayout} />
      <CustomBox customClass={css.customBox}>
        <PurchasePowerSkeleton standard={true} />
      </CustomBox>
      <CustomBox customClass={css.customBox}>
        <Grid container>
          <Grid item xs={6} container>
            <Skeleton width={'20%'} />
            <Skeleton
              width={'20%'}
              variant="rect"
              className={css.marginLeft2}
            />
            <Skeleton
              width={'20%'}
              variant="rect"
              className={css.marginLeft4}
            />
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            <Skeleton width={'20%'} />
            <Skeleton
              width={'20%'}
              variant="rect"
              className={css.marginLeft2}
            />
          </Grid>
        </Grid>
      </CustomBox>
    </Grid>
  );
}

export default HistorySkeleton;
