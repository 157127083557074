import {
  displayError,
  displaySuccess,
} from '../../../../core/redux/slices/notificationsSlice';
import {
  studentsApprovalState,
  studentsApprovalStateByCode,
} from '../../../../core/strings/appConstants';

import appStrings from '../../../../core/strings/appStrings';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import store from '../../../../core/redux/store';
import { updateCheckedSearchResults } from '../../../../core/redux/slices/studentSearchSlice';
import { updateStudentsStatus } from '../../ApproveReject/approveRejectService';
import { updateStudentsWithNewStatus } from '../../../../core/redux/slices/approveRejectSlice';

export const formatStudents = (students, css, approveReject = false) =>
  students.map(student => {
    const formatted = cloneDeep(student);
    formatted.studentName = `${formatted.firstName} ${formatted.lastName}`;
    if (!approveReject) {
      formatted.disabled = !student.active;
    } else {
      formatted.formattedStatus = (
        <span
          key={`status-${formatted.id}`}
          id={`status-${formatted.id}`}
          className={classNames(css.capitalize, {
            [css.redHighlight]:
              formatted.approval === studentsApprovalState.rejected,
            [css.greenHighlight]:
              formatted.approval === studentsApprovalState.approved,
          })}
        >
          {studentsApprovalStateByCode[formatted.approval]}
        </span>
      );
    }
    return formatted;
  });

export const handleStatusUpdateForApproveReject = (
  studentsToUpdate,
  setIsLoading,
  status = studentsApprovalState.approved
) => {
  const studentsSelected = studentsToUpdate?.map(elem => ({
    studentId: elem,
  }));

  setIsLoading(true);

  updateStudentsStatus({ students: studentsSelected, approval: status })
    .then(resp => {
      if (resp) {
        store.dispatch(
          updateStudentsWithNewStatus({
            changedStudents: studentsSelected,
            newStatus: status,
          })
        );
        store.dispatch(
          updateCheckedSearchResults({
            reset: true,
          })
        );

        store.dispatch(
          displaySuccess({
            message: appStrings.searchInputs.successMessageUpdateStatus(
              (studentsApprovalState.rejected === status
                ? appStrings.searchInputs.rejected
                : appStrings.searchInputs.approved
              ).toLowerCase()
            ),
          })
        );
        return;
      }
      store.dispatch(displayError());
    })
    .catch(e => store.dispatch(displayError({ message: e })))
    .finally(() => {
      setIsLoading(false);
    });
};

export const getCheckedStudentsStatuses = (students, checkedSearchResults) => {
  const statuses = { approved: 0, rejected: 0, pending: 0 };
  students.forEach((student, index) => {
    if (checkedSearchResults?.includes(student.id)) {
      statuses[studentsApprovalStateByCode[student.approval]]++;
    }
  });
  return statuses;
};
