import {
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import {
  connectionClosed,
  saveConnection,
} from '../../redux/slices/signalRSlice';

import { connectionHubName } from '../../strings/signalRStrings';
import { connectionStatus } from '../../strings/appConstants';
import { getAuthToken } from '../../../modules/shared/Authentication/authenticationService';
import signalRListeners from './signalRListeners';
import store from '../../redux/store';

export const startSignalRConnection = userId => {
  const hubConnected = store.getState().signalR.hubConnected;

  if (!hubConnected) {
    const connection = new HubConnectionBuilder()
      .withUrl(connectionHubName(userId), {
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => getAuthToken(),
      })
      .configureLogging(LogLevel.Warning)
      .withAutomaticReconnect()
      .build();

    store.dispatch(
      saveConnection({
        connection,
        hubConnected: true,
        userId,
        connectionStatus: connectionStatus.connecting,
      })
    );

    addListeners(connection);
    return start(connection, userId);
  }

  return Promise.resolve();
};

const addListeners = connection => {
  connection.onclose(async () => {
    store.dispatch(connectionClosed());
  });

  signalRListeners(connection);
};

const start = async (connection, userId) => {
  try {
    await connection.start();

    store.dispatch(
      saveConnection({
        connection,
        hubConnected: true,
        userId,
        connectionStatus: connectionStatus.started,
      })
    );
  } catch (err) {
    store.dispatch(
      saveConnection({
        connection,
        hubConnected: false,
        userId,
        connectionStatus: connectionStatus.error,
        errorMessage: err,
      })
    );
    setTimeout(() => start(connection, userId), 5000);
  }
};

export const closeSignalRConnection = (connection = null) => {
  if (!connection) {
    const stopConnectionInterval = setInterval(() => {
      const signalRStored = store.getState().signalR;

      if (signalRStored.hubConnected) {
        signalRStored.connection?.stop();
        clearInterval(stopConnectionInterval);
      }
    }, 200);
    return;
  }
  connection.stop();
};
