import { makeStyles } from 'tss-react/mui';

const useCustomDropDownMenuStyles = makeStyles()(theme => ({
  menu: {
    borderRadius: '4px',
    background: theme.palette.common.neutral2,
    border: `1px solid ${theme.palette.common.lightGrey}`,
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
    color: theme.palette.common.darkGrey,
    width: '260px',
  },
  menuItem: {
    fontSize: theme.typography.h3.fontSize,
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    border: '1px solid transparent',
    '&:hover': {
      fontWeight: theme.typography.fontWeightBold,
      borderRadius: '4px',
      background: theme.palette.common.neutral3,
      border: `1px solid ${theme.palette.common.secondary6}`,
      '& span': {
        color: theme.palette.common.darkGrey,
      },
      '&+li': {
        borderTop: '1px solid transparent',
      },
    },
    '&:not(:first-of-type)': {
      borderTop: `1px solid ${theme.palette.common.secondary6}`,
    },
  },
  menuIcon: {
    marginLeft: theme.spacing(3),
    color: theme.palette.common.primary,
    fontSize: theme.typography.h1.fontSize,
  },
}));

export default useCustomDropDownMenuStyles;
