import {
  APPROVE_REJECT,
  CART,
  CONSIGNED,
  HISTORY,
  LOGIN,
  ORDER,
  REGISTER_IND,
  SAVED,
  STUDENTS,
  STUDENT_PROFILE,
} from '../../../core/navigation/paths';

import appStrings from '../../../core/strings/appStrings';
import { icons } from '../../../core/strings/icons';
import { logout } from '../../../core/redux/slices/loginSlice';

/**
 *
 * @param {string} employeeId
 * @param {object} selectedStudentDetails
 * @shape
 *  {
 *    student: {id:''},
 *    bpNumber: ''
 *  }
 * @returns {Array}
 */
const menuItemsConstants = (employeeId, selectedStudentDetails, dispatch) => {
  const industrialMenuItems = {
    title: appStrings.menu.administration,
    items: [
      {
        icon: icons.student,
        name: appStrings.common.students,
        redirectTo: STUDENTS(employeeId),
      },
      {
        icon: icons.register,
        name: appStrings.menu.registerStudent,
        redirectTo: REGISTER_IND,
      },
      {
        icon: icons.like,
        name: appStrings.menu.approveRejectStudent,
        redirectTo: APPROVE_REJECT,
      },
      {
        icon: icons.consigned,
        name: appStrings.menu.consignedInventory,
        redirectTo: CONSIGNED,
      },
    ],
  };
  const studentRelatedMenuItems = () =>
    (selectedStudentDetails?.student?.id || selectedStudentDetails?.id) && {
      studentMenu: true,
      title: appStrings.common.student,
      items: [
        {
          icon: icons.cart,
          name: appStrings.common.studentCart,
          redirectTo: CART(
            selectedStudentDetails.bpNumber,
            selectedStudentDetails.student?.id ?? selectedStudentDetails.id
          ),
        },
        {
          icon: icons.order,
          name: appStrings.common.order,
          redirectTo: ORDER(
            selectedStudentDetails.bpNumber,
            selectedStudentDetails.student?.id ?? selectedStudentDetails.id
          ),
        },
        {
          icon: icons.savedItems,
          name: appStrings.menu.savedItems,
          redirectTo: SAVED(
            selectedStudentDetails.bpNumber,
            selectedStudentDetails.student?.id ?? selectedStudentDetails.id
          ),
        },
        {
          icon: icons.history,
          name: appStrings.menu.history,
          redirectTo: HISTORY(
            selectedStudentDetails.bpNumber,
            selectedStudentDetails.student?.id ?? selectedStudentDetails.id
          ),
        },
        {
          icon: icons.edit,
          name: appStrings.menu.editStudentProfile,
          redirectTo: STUDENT_PROFILE(
            selectedStudentDetails.bpNumber,
            selectedStudentDetails.student?.id ?? selectedStudentDetails.id
          ),
        },
      ],
    };

  const profileMenuItems = {
    items: [
      {
        icon: icons.logout,
        name: appStrings.menu.logout,
        redirectTo: LOGIN,
        className: 'primaryLink',
        onClick: () => {
          setTimeout(() => {
            if (!document.getElementsByClassName('MuiDialog-paper').length) {
              dispatch(logout());
            }
          }, 500); //this delay is necessary to avoid race conditions with other setTimeouts
        },
      },
    ],
  };

  return [industrialMenuItems, studentRelatedMenuItems(), profileMenuItems];
};

export default menuItemsConstants;
