import { Grid, Skeleton } from '@mui/material';
import React from 'react';

function StudentAchievementsSkeleton() {
  return (
    <Grid
      container
      paddingTop={5}
      id="StudentAchievements-Skeleton"
      data-test-id="StudentAchievements-SkeletonTestId"
    >
      <Grid item container xs={9} sm={8} md={8} spacing={4}>
        {Array(2)
          .fill(true)
          .map((_, index) => (
            <Grid item xs={6} key={index}>
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <Skeleton variant="rounded" height="124px" />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton height="16px" variant="rounded" />
                </Grid>
              </Grid>
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
}

export default StudentAchievementsSkeleton;
