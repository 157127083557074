import CartContext from '../context/CartContext';
import { Grid } from '@mui/material';
import OrderType from '../../OrderType/OrderType';
import { useContext } from 'react';
import useCartStyles from '../useCartStyles';

function CartOrderTypeSection() {
  const css = useCartStyles().classes;
  const cartCtx = useContext(CartContext);

  return cartCtx.isOrder && !cartCtx.isInactiveStudent ? (
    <Grid item xs={12} className={css.orderTypeContainer}>
      <OrderType />
    </Grid>
  ) : null;
}

export default CartOrderTypeSection;
