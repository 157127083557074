import { useEffect, useState } from 'react';

const useKeyLock = key => {
  const [toggled, setToggled] = useState(false);

  useEffect(() => {
    const onKeyDown = event =>
      setToggled(pToggled => event.getModifierState?.(key) ?? pToggled);

    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  }, [key]);

  return toggled;
};
export default useKeyLock;
