import React from 'react';
import classNames from 'classnames';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Grid, Link, SvgIcon, Typography } from '@mui/material';
import useFooterStyles from './useFooterStyles';
import appStrings from '../../../core/strings/appStrings';
import { footerSnaponLinks, footerSocialMedia } from './footerConstants';

function Footer() {
  const css = useFooterStyles().classes;
  const footerClasses = classNames(css.footer);
  const currentYear = new Date().getFullYear();

  return (
    <Grid container className={footerClasses} data-test-id={'footer'}>
      <Grid item container data-test-id={'footer-snapon-links'}>
        {footerSnaponLinks.map((elem, index) => (
          <Link key={index} href={elem.to} target="_blank">
            {elem.name}
          </Link>
        ))}
      </Grid>
      <Grid item data-test-id={'footer-hardcodedText'}>
        <Typography>{appStrings.footer.firstParagraph}</Typography>
        <Typography>{appStrings.footer.secondParagraph}</Typography>
        <Typography>{appStrings.footer.thirdParagraph(currentYear)}</Typography>
      </Grid>
      <Grid item container data-test-id={'footer-socialMedia-links'}>
        <Link href={footerSocialMedia.facebookLink} target="_blank">
          <SvgIcon component={FacebookIcon} data-test-id={'FacebookIcon'} />
        </Link>
        <Link href={footerSocialMedia.instagramLink} target="_blank">
          <SvgIcon component={InstagramIcon} data-test-id={'InstagramIcon'} />
        </Link>
        <Typography
          id="ot-sdk-btn"
          className="cookies-settings ot-sdk-show-settings"
          onClick={() => {
            if (window.OneTrust) {
              window.OneTrust.ToggleInfoDisplay();
            }
          }}
        >
          {appStrings.cookiesModal.cookiesSettings}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Footer;
