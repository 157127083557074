import { Menu, MenuItem } from '@mui/material';

import CustomButton from '../CustomButton/CustomButton';
import CustomIcon from '../CustomIcon/CustomIcon';
import { buttonTypes } from '../../../core/strings/appConstants';
import { propTypes } from './customDropdownMenuConstants';
import useCustomDropDownMenuStyles from './useCustomDropDownMenuStyles';
import { useState } from 'react';

function CustomDropDownMenu({
  buttonType = buttonTypes.primary,
  menuId,
  menuItems,
  menuLabel,
  menuIcon,
  disabled,
  customClass,
}) {
  const css = useCustomDropDownMenuStyles().classes;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickAction = handler => {
    handleClose();
    if (handler) {
      handler();
    }
  };

  return (
    <>
      <CustomButton
        customClass={customClass}
        btnType={buttonType}
        label={menuLabel}
        onClick={handleClick}
        data-test-id="dropDownMenuButton"
        icon={menuIcon ? menuIcon : null}
        disabled={disabled}
        id={menuId}
      />
      <Menu
        id={menuId + 'MenuContainer'}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: css.menu }}
        data-test-id="dropDownMenu"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {menuItems &&
          menuItems.map((item, index) => (
            <MenuItem
              onClick={() => handleClickAction(item.handler)}
              key={`dropDownMenuItem${index}`}
              classes={{ root: css.menuItem }}
              disabled={item.isDisabled}
              data-test-id="dropDownMenuItem"
              id={menuId + 'Option' + index}
            >
              {item.label}
              {item.icon && (
                <CustomIcon icon={item.icon} customClasses={css.menuIcon} />
              )}
              {item.customElements && item.customElements()}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}

CustomDropDownMenu.propTypes = propTypes;

export default CustomDropDownMenu;
