import CONSTANTS from '../constants';
import { createSlice } from '@reduxjs/toolkit';
import studentProfileAvatar from '../../../core/assets/img/studentProfileAvatar.png';

const commonSlice = createSlice({
  name: CONSTANTS.common,
  initialState: {
    showLoading: false,
    refreshCustomerInfo: false,
    studentProfilePicture: studentProfileAvatar,
    refreshECAppBtn: false,
    loadingMessage: '',
    isEcAppBtnEnabled: false,
    refreshReferences: false,
  },
  reducers: {
    displayLoading: (state, { payload }) => {
      state.showLoading = true;
      state.loadingMessage = payload;
    },
    hideLoading: state => {
      state.showLoading = false;
      state.loadingMessage = '';
    },
    setRefreshCustomerInfo: (state, { payload }) => {
      state.refreshCustomerInfo = payload;
    },
    setStudentProfilePicture: (state, { payload }) => {
      state.studentProfilePicture = payload;
    },
    deleteStudentProfilePicture: state => {
      state.studentProfilePicture = studentProfileAvatar;
    },
    setRefreshECAppBtn: (state, action) => {
      state.refreshECAppBtn = action.payload;
    },
    setEcAppBtnEnabled: (state, action) => {
      state.isEcAppBtnEnabled = action.payload;
    },
    setRefreshReferences: (state, action) => {
      state.refreshReferences = action.payload;
    },
  },
});

export const {
  displayLoading,
  hideLoading,
  setRefreshCustomerInfo,
  setStudentProfilePicture,
  deleteStudentProfilePicture,
  setRefreshECAppBtn,
  setEcAppBtnEnabled,
  setRefreshReferences,
} = commonSlice.actions;
export default commonSlice.reducer;
