import {
  getAuthToken,
  getIdentity,
  logoutUser,
  setAuthToken,
  setDuration,
  setIdentity,
} from '../../../modules/shared/Authentication/authenticationService';

import CONSTANTS from '../constants';
import { PUBLIC } from '../../navigation/roles';
import { createSlice } from '@reduxjs/toolkit';

const cookiesUserData = getIdentity();
const cookiesToken = getAuthToken();
const defaultUserDetails = { role: PUBLIC };

const loginSlice = createSlice({
  name: CONSTANTS.login,
  initialState: {
    isLoggedIn: cookiesToken ? true : false,
    userDetails: cookiesUserData ? cookiesUserData : defaultUserDetails,
  },
  reducers: {
    login: (state, action) => {
      state.userDetails = action.payload.userDetails;
      state.isLoggedIn = true;

      setIdentity(action.payload.userDetails);
      setAuthToken(action.payload.token);

      setDuration(action.payload.userDetails.isIndustrial);
    },
    logout: state => {
      state.isLoggedIn = false;
      state.userDetails = defaultUserDetails;

      logoutUser();
    },
    acceptTermsAndConditions: state => {
      state.userDetails.hasAcceptedTermsAndConditions = true;
    },
  },
});

export const { login, logout, acceptTermsAndConditions } = loginSlice.actions;
export default loginSlice.reducer;
