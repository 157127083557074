import React, { useEffect, useRef, useState } from 'react';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import { Checkbox, Grid, Typography, useMediaQuery } from '@mui/material';
import CustomPagination from '../../../shared/CustomPagination/CustomPagination';
import {
  calculateCartLength,
  checkIfAllItemsAreSelected,
  deleteProductFromCart,
  getCartItemsId,
  getDataToDisplayFromTo,
  getItemIndex,
  getMultipleItemsProductNames,
  getMultipleItemsProductNumbers,
  getSavedCart,
  getStudentCart,
  handleSelectAllCheckbox,
  moveItemsBetweenCartsSignalr,
  scrollToSectionTop,
  setStudentCartDetails,
} from '../ShoppingCartService';
import ItemsTitle from './ItemsTitle';
import ItemsContent from './ItemsContent';
import CartItemsSkeleton from '../Skeletons/CartItemsSkeleton';
import useShoppingCartStyles from '../shoppingCartStyles';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  resetSelectedItems,
  setAllItemsSelected,
  setIsShoppingCartLoading,
  setShouldGetCart,
} from '../../../../core/redux/slices/guestCartSlice';
import { useLocation } from 'react-router-dom';
import { ACCOUNT, CHECKOUT } from '../../../../core/navigation/paths';
import FloatingActionBar from './FloatingActionBar';
import classNames from 'classnames';
import ShowMoreButton from './ShowMoreButton';
import { resetReloadOrderItems } from '../../../../core/redux/slices/cartSlice';
import appStrings from '../../../../core/strings/appStrings';

// eslint-disable-next-line sonarjs/cognitive-complexity
function CartItems({
  isSavedCart,
  selectedItems,
  getProducts,
  setCartItems,
  setMoveFlow,
}) {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const { pathname } = useLocation();
  const isShoppingCartPage = pathname.indexOf(CHECKOUT) === -1;
  const isAccountPage = pathname.indexOf(ACCOUNT) !== -1;
  const isSmallDevice = useMediaQuery('(max-width:768px)');
  const isMediumDevice = useMediaQuery(
    'only screen and (min-width : 769px) and (max-width : 1024px)'
  );
  const css = useShoppingCartStyles({
    isSmallDevice,
    isCartSummary: false,
  }).classes;
  const { userDetails, isLoggedIn } = useSelector(state => state.login);
  const { isActionBarOpen, allCartItemsSelected, allSavedCartItemsSelected } =
    useSelector(state => state.guestCart.selectedItems);
  const { isShoppingCartLoading, shouldGetCart } = useSelector(
    state => state.guestCart
  );
  const [paginationDetails, setPaginationDetails] = useState({
    from: 0,
    to: 10,
    itemsPerPage: 10,
    page: 1,
  });
  const [displayedItems, setDisplayedItems] = useState([]);
  const [guestItemsCart, setGuestItemsCart] = useState([]);
  const [showSavedItems, setShowSavedItems] = useState(true);
  const [totalItems, setTotalItems] = useState(0);

  const onItemUpdate = updatedItem => {
    let itemIndex = guestItemsCart.findIndex(
      item => updatedItem.recordId === item.recordId
    );

    if (itemIndex !== -1) {
      guestItemsCart[itemIndex] = updatedItem;
    }
  };

  const handlePaginationChange = ({ from, to, page, itemsPerPage }) => {
    const newDisplayedItems = getDataToDisplayFromTo(guestItemsCart, from, to);
    setDisplayedItems(newDisplayedItems);
    setPaginationDetails({ from, to, page, itemsPerPage });
    scrollToSectionTop(containerRef.current);
  };

  const handleDeleteFromCart = itemIndex => {
    deleteProductFromCart({
      setGuestItemsCart,
      setDisplayedItems,
      itemsPerPage: paginationDetails.itemsPerPage,
      itemIndex,
      dispatch,
      isLoggedIn,
      studentId: userDetails.userId,
      guestItemsCart,
    });
    if (!isLoggedIn) {
      setTotalItems(
        calculateCartLength(
          JSON.parse(localStorage.getItem('guestItemsCart'))?.value || []
        )
      );
    }
  };

  const renderItems = () => {
    if (isShoppingCartPage && !displayedItems?.length) {
      return (
        <Grid container className={css.emptyCartMessage}>
          <Typography>{appStrings.cart.noItemsMessage(isSavedCart)}</Typography>
        </Grid>
      );
    }

    return displayedItems.map((item, index) => (
      <div key={`item-${index}`} id={`itemRow_${index}`}>
        <ItemsContent
          isSavedCart={isSavedCart}
          item={item}
          index={getItemIndex(index, paginationDetails)}
          handleDeleteFromCart={handleDeleteFromCart}
          handleMoveItems={handleMoveItems}
          onItemUpdate={onItemUpdate}
        />
      </div>
    ));
  };

  useEffect(() => {
    if (
      !isLoggedIn &&
      guestItemsCart?.length &&
      setCartItems &&
      shouldGetCart
    ) {
      setCartItems(guestItemsCart);
      dispatch(setShouldGetCart(false));
    }
  }, [guestItemsCart, shouldGetCart]);

  const handleDeleteMultipleItemsFromCart = () => {
    deleteProductFromCart({
      setGuestItemsCart,
      setDisplayedItems,
      itemsPerPage: paginationDetails.itemsPerPage,
      recordIds: selectedItems,
      dispatch,
      isLoggedIn,
      studentId: userDetails.userId,
      guestItemsCart,
    });
    dispatch(resetSelectedItems());

    if (!isLoggedIn) {
      setTotalItems(
        calculateCartLength(
          JSON.parse(localStorage.getItem('guestItemsCart'))?.value || []
        )
      );
    }
  };

  const handleMoveItems = (target, itemIndex) => {
    setMoveFlow(target);
    moveItemsBetweenCartsSignalr({
      studentId: userDetails.userId,
      recordIds: [guestItemsCart[itemIndex]?.recordId],
      productNumbers: [guestItemsCart[itemIndex]?.productNumber],
      productNames: [guestItemsCart[itemIndex]?.description],
      dispatch,
      target,
    });
  };

  const handleMoveMultipleItems = target => {
    setMoveFlow(target);
    moveItemsBetweenCartsSignalr({
      studentId: userDetails.userId,
      recordIds: selectedItems,
      productNumbers: getMultipleItemsProductNumbers(
        guestItemsCart,
        selectedItems
      ),
      productNames: getMultipleItemsProductNames(guestItemsCart, selectedItems),
      dispatch,
      target,
    });
    dispatch(resetSelectedItems());
  };

  useEffect(() => {
    if (isLoggedIn && shouldGetCart) {
      dispatch(setIsShoppingCartLoading(true));
      dispatch(resetReloadOrderItems());
      if (isSavedCart) {
        getSavedCart(userDetails.userId)
          .then(resp => {
            setGuestItemsCart(resp.cartDetails.cartItems);
            setTotalItems(calculateCartLength(resp.cartDetails.cartItems));
            if (getProducts) {
              getProducts(resp.cartDetails.cartItems);
            }
          })
          .finally(() => {
            dispatch(setShouldGetCart(false));
            dispatch(setIsShoppingCartLoading(false));
          });
      } else {
        getStudentCart(userDetails.userId)
          .then(resp => {
            setStudentCartDetails(resp.cartDetails, dispatch);
            setGuestItemsCart(resp.cartDetails.cartItems);
            setTotalItems(calculateCartLength(resp.cartDetails.cartItems));
            if (getProducts) {
              getProducts(resp.cartDetails.cartItems);
            }
          })
          .finally(() => {
            dispatch(setShouldGetCart(false));
            dispatch(setIsShoppingCartLoading(false));
          });
      }
    } else if (!isLoggedIn) {
      const guestCart =
        JSON.parse(localStorage.getItem('guestItemsCart'))?.value || [];
      setGuestItemsCart(guestCart);
      setTotalItems(calculateCartLength(guestCart));
    }
  }, [dispatch, isLoggedIn, isSavedCart, shouldGetCart, userDetails.userId]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(setShouldGetCart(true));
    }
  }, [dispatch, isLoggedIn]);

  useEffect(() => {
    setDisplayedItems(
      guestItemsCart?.slice(0, paginationDetails.itemsPerPage) || []
    );
  }, [guestItemsCart, paginationDetails.itemsPerPage]);

  useEffect(() => {
    checkIfAllItemsAreSelected({
      guestItemsCart,
      selectedItems,
      isSavedCart,
      setAllItemsSelected,
      dispatch,
    });
  }, [dispatch, guestItemsCart, isSavedCart, selectedItems]);

  const handleSelectAll = e => {
    handleSelectAllCheckbox({ e, dispatch, isSavedCart, guestItemsCart });
  };

  return (
    <CustomBox
      fadeBorder
      customClass={isSmallDevice && css.containerXs}
      id={getCartItemsId(isSavedCart)}
      data-test-id={getCartItemsId(isSavedCart)}
    >
      {isShoppingCartLoading ? (
        <CartItemsSkeleton />
      ) : (
        <>
          <Grid ref={containerRef} container justifyContent="center">
            <Grid container alignItems="center" className={css.titleContainer}>
              <Grid container item xs={10} alignItems="center">
                {isShoppingCartPage && guestItemsCart?.length > 0 && (
                  <Grid item>
                    <Checkbox
                      className={css.selectAll}
                      checked={
                        isSavedCart
                          ? allSavedCartItemsSelected
                          : allCartItemsSelected
                      }
                      disableRipple
                      onChange={handleSelectAll}
                      data-test-id={'selectAllCheckbox'}
                      id={'selectAllCheckbox'}
                    />
                  </Grid>
                )}
                <Grid item xs>
                  <ItemsTitle
                    isSavedCart={isSavedCart}
                    cartLength={totalItems}
                  />
                </Grid>
              </Grid>
              {!isAccountPage && (
                <ShowMoreButton
                  isSavedCart={isSavedCart}
                  setShowSavedItems={setShowSavedItems}
                  showSavedItems={showSavedItems}
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              className={classNames({ [css.hide]: !showSavedItems })}
            >
              {renderItems()}
            </Grid>
          </Grid>
          {isActionBarOpen && selectedItems?.length > 0 && (
            <FloatingActionBar
              isSavedCart={isSavedCart}
              selectedItems={selectedItems}
              handleMoveItems={handleMoveMultipleItems}
              handleDeleteItems={handleDeleteMultipleItemsFromCart}
            />
          )}
        </>
      )}
      {guestItemsCart.length > 0 && (
        <Grid
          item
          xs={12}
          className={classNames(css.paginationStyle, {
            [css.hide]: !showSavedItems,
          })}
        >
          <CustomPagination
            onPageChangeCallback={handlePaginationChange}
            itemsLength={guestItemsCart.length}
            itemsPerPage={paginationDetails.itemsPerPage}
            isStudent={true}
            shape={'rounded'}
            variant={'outlined'}
            keepPageOnLengthChange
            boundaryCount={isMediumDevice ? 0 : 1}
          />
        </Grid>
      )}
    </CustomBox>
  );
}

CartItems.prototypes = {
  getProducts: PropTypes.func,
  isSavedCart: PropTypes.bool,
  selectedItems: PropTypes.array,
  setCartItems: PropTypes.func,
  setMoveFlow: PropTypes.func,
};

export default CartItems;
