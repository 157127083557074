import { Button, useTheme } from '@mui/material';

import CustomIcon from '../CustomIcon/CustomIcon';
import PropTypes from 'prop-types';
import { buttonTypes } from '../../../core/strings/appConstants';
import classNames from 'classnames';
import useCustomButtonStyles from './useCustomButtonStyles';

const propTypes = {
  btnType: PropTypes.string, // 'primary' | 'secondary' | 'tertiary'
  color: PropTypes.string, // use any color class from useCustomButtonStyles, leave blank to use default color
  label: PropTypes.any,
  icon: PropTypes.any, // icon name without 'icon-', like 'search' or a jsx component
  iconSize: PropTypes.string, // icon size in string, like '1rem'
  customClass: PropTypes.string, // a custom class to be added to the button
};

function CustomButton({
  btnType = buttonTypes.primary,
  color,
  label,
  icon,
  startIcon,
  iconSize,
  customClass,
  ...props
}) {
  const css = useCustomButtonStyles().classes;
  const theme = useTheme();

  const getIcon = () => {
    if (icon) {
      return typeof icon === 'string' ? (
        <CustomIcon
          icon={icon}
          size={iconSize ?? theme.typography.subtitle2.fontSize}
        />
      ) : (
        icon
      );
    }
    return null;
  };

  const getStartIcon = () => {
    if (startIcon) {
      return typeof startIcon === 'string' ? (
        <CustomIcon
          icon={startIcon}
          size={iconSize ?? theme.typography.subtitle2.fontSize}
        />
      ) : (
        startIcon
      );
    }
    return null;
  };

  return (
    <Button
      variant="contained"
      disableElevation
      disableRipple
      className={classNames(css.button, css[btnType], color, customClass ?? '')}
      endIcon={getIcon()}
      startIcon={getStartIcon()}
      data-test-id={props[`data-test-id`] ?? 'customButton'}
      {...props}
      id={props.id ?? null}
    >
      {label ? label : null}
    </Button>
  );
}

CustomButton.propTypes = propTypes;

export default CustomButton;
