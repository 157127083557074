import { Grid, Typography } from '@mui/material';
import {
  checkEmptyAddress,
  checkZipCodes,
  formatShippingAndBilling,
  inputFields,
  resetSections,
  saveShippingAndBilling,
} from './shippingAndBillingService';
import { useCallback, useContext, useEffect, useState } from 'react';

import CustomBox from '../../shared/CustomBox/CustomBox';
import FormInput from '../../shared/FormInput/FormInput';
import OrderLeaveDialog from '../../shared/OrderLeaveDialog/OrderLeaveDialog';
import appStrings from '../../../core/strings/appStrings';
import setMultipleValues from '../../../core/services/formService';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useShippingAndBillingStyles from './useShippingAndBillingStyles';
import CartContext from '../Cart/context/CartContext';
import MandatoryFieldsMessage from '../../shared/MandatoryFieldsMessage/MandatoryFieldsMessage';

function ShippingAndBilling({
  shouldSave,
  setShouldSave,
  isExpanded,
  setSectionErrors,
}) {
  const css = useShippingAndBillingStyles().classes;
  const [disabledStates, setDisabledStates] = useState({});
  const [formattedValues, setFormattedValues] = useState(null);
  const [disabledSections, setDisabledSections] = useState({});
  const [formWasSubmitted, setFormWasSubmitted] = useState(false);
  const cartCtx = useContext(CartContext);

  const { resetAddresses, setResetAddresses} = cartCtx;

  const [isLoading, setIsLoading] = useState({
    shippingAddress: false,
    billingAddress: false,
  });
  const {
    control,
    setValue,
    clearErrors,
    getValues,
    formState,
    handleSubmit,
    reset,
    trigger,
  } = useForm();
  const { isDirty, errors } = formState;
  const orderInfo = useSelector(state => state.cart.orderInfo);
  const { bp } = useParams();

  const disableSections = useCallback(() => {
    if (
      formattedValues.shippingAddress.isDefault ||
      formattedValues.shippingAddress.isSchoolAddress
    ) {
      setDisabledSections(prev => ({ ...prev, shippingAddress: true }));
    }
    if (
      formattedValues.billingAddress.isDefault ||
      formattedValues.billingAddress.isSameAsShipping
    ) {
      setDisabledSections(prev => ({ ...prev, billingAddress: true }));
    }
  }, [formattedValues]);

  useEffect(() => {
    if (orderInfo.shippingAddress.address) {
      if (!formattedValues) {
        checkZipCodes(orderInfo).then(() => {
          const newValues = formatShippingAndBilling(orderInfo);
          setMultipleValues({
            setValue,
            values: newValues,
          });
          setFormattedValues(newValues);
        });
      } else {
        setValue(
          'shippingAddress.address.city',
          formattedValues.shippingAddress?.address.city?.toUpperCase()
        );
        setValue(
          'billingAddress.address.city',
          formattedValues.billingAddress?.address.city?.toUpperCase()
        );
        disableSections();
      }
    }
  }, [setValue, orderInfo, formattedValues, getValues, disableSections]);

  useEffect(() => {
    if (resetAddresses) {
      resetSections({
        setValue,
        getValues,
        setDisabledStates,
        setDisabledSections,
        clearErrors,
        setSectionErrors,
      })
      setResetAddresses(false);
    }
  }, [resetAddresses, setValue, getValues, setResetAddresses, clearErrors, setSectionErrors])

  useEffect(() => {
    const onSubmit = data => {
      saveShippingAndBilling(orderInfo.studentId, data);
      setShouldSave(false);
      setSectionErrors(prev => ({ ...prev, shippingAndBilling: false }));
      reset(getValues());
      setFormWasSubmitted(false);
    };

    const onError = errors => {
      setFormWasSubmitted(true);
      setSectionErrors(prev => ({ ...prev, shippingAndBilling: true }));
      setShouldSave(false);
      if (
        checkEmptyAddress(errors.shippingAddress) ||
        checkEmptyAddress(errors.billingAddress)
      ) {
        onSubmit(getValues());
      }
    };

    if (shouldSave && isDirty) {
      handleSubmit(onSubmit, onError)();
    } else {
      setShouldSave(false);
    }
  }, [
    shouldSave,
    orderInfo.studentId,
    setShouldSave,
    isDirty,
    handleSubmit,
    reset,
    getValues,
    setSectionErrors,
  ]);

  const createPanel = (section, title) => (
    <Grid item xs={6} id={section + 'Container'}>
      <Typography variant="h2" className={css.sectionTitle}>
        <strong>{title}:</strong>
      </Typography>
      <CustomBox customClass={css.sectionBox}>
        <Grid container spacing={1} className={css.sectionFormContainer}>
          {inputFields({
            section,
            getValues,
            setValue,
            disabledStates,
            setDisabledStates,
            disabledSections,
            setDisabledSections,
            bp,
            formWasSubmitted,
            trigger,
            isLoading,
            setIsLoading,
            errors,
            clearErrors,
          }).map(field => (
            <Grid item {...field.gridLayout} key={field.name}>
              <FormInput
                field={field}
                control={control}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
              />
            </Grid>
          ))}
        </Grid>
      </CustomBox>
    </Grid>
  );

  return (
    <>
      <form>
        <Grid container spacing={4}>
          {createPanel('shippingAddress', appStrings.order.shippingTo)}
          {createPanel('billingAddress', appStrings.order.billingTo)}
        </Grid>
        <MandatoryFieldsMessage />
      </form>

      {isExpanded && <OrderLeaveDialog isDirty={isDirty} trigger={trigger} />}
    </>
  );
}

export default ShippingAndBilling;
