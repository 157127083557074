import React, { useEffect } from 'react';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { totalsButtonsConstants } from '../shoppingCartConstants';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import CartTotalsMobile from '../Views/CartTotalsMobile';
import CartTotalsTabletDesktop from '../Views/CartTotalsTabletDesktop';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CartTotalsSkeleton from '../Skeletons/CartTotalsSkeleton';
import useShoppingCartStyles from '../shoppingCartStyles';
import { useDispatch, useSelector } from 'react-redux';
import appStrings from '../../../../core/strings/appStrings';
import {
  calculateProductsTotal,
  formatPrice,
  getStudentSummary,
} from '../ShoppingCartService';
import {
  setCartSummaryOrderTotal,
  setRefreshSummary,
  setShouldGetCart,
} from '../../../../core/redux/slices/guestCartSlice';
import { notificationRole, sepLinks } from '../../../../core/strings/appConstants';
import { CHECKOUT } from '../../../../core/navigation/paths';
import PropTypes from 'prop-types';
import {
  setTotals,
  setTotalsLoading,
} from '../../../../core/redux/slices/cartSlice';
import { websiteUrls } from '../../../../core/strings/urls';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';

// eslint-disable-next-line sonarjs/cognitive-complexity
function CartTotals({ handlePlaceOrder, disablePlaceOrder }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isCheckOut = pathname.indexOf(CHECKOUT) !== -1;
  const { isLoggedIn } = useSelector(state => state.login);
  const { cartLength, studentCartId, refreshSummary } = useSelector(
    state => state.guestCart
  );
  const cartTotalsWithoutDiscount = useSelector(
    state => state.guestCart.cartTotalsWithoutDiscount
  );
  const loading = useSelector(state => state.cart.totalsLoading);
  const isXSDevice = useMediaQuery(theme.breakpoints.only('xs'));
  const isSmallDevice = useMediaQuery('(max-width:768px)');
  const styleParams = {
    isSmallDevice,
    isCartSummary: false,
  };
  const css = useShoppingCartStyles(styleParams).classes;
  const dispatch = useDispatch();
  const totals = useSelector(state => state.cart.totals);

  useEffect(() => {
    if (isLoggedIn || refreshSummary) {
      dispatch(setTotalsLoading(true));
      dispatch(setRefreshSummary(false));
      getStudentSummary(studentCartId)
        .then(resp => {
          dispatch(
            setTotals({
              subtotal: formatPrice(resp.subtotal),
              tax: resp.taxErrorMessage ?
                appStrings.cart.unableToCalculateTaxStudent :
                formatPrice(resp.tax),
              grandTotal: formatPrice(resp.grandTotal),
              shippingAndHandling: appStrings.cart.freeShipping,
              sepSavings: formatPrice(
                cartTotalsWithoutDiscount - resp.subtotal
              ),
              paymentTotal: resp.grandTotal,
              taxErrorMessage: resp.taxErrorMessage,
            })
          );
          if (resp.taxErrorMessage) {
            dispatch(
              displayError({
                role: notificationRole.student,
                message: resp.taxErrorMessage,
              })
            );
          }
          dispatch(setCartSummaryOrderTotal(resp.grandTotal));
        })
        .finally(() => dispatch(setTotalsLoading(false)));
    } else if (!isLoggedIn) {
      dispatch(
        setTotals({
          subtotal: formatPrice(
            calculateProductsTotal(
              JSON.parse(localStorage.getItem('guestItemsCart'))?.value || []
            )
          ),
        })
      );
    }
  }, [
    cartTotalsWithoutDiscount,
    dispatch,
    isLoggedIn,
    refreshSummary,
    studentCartId,
  ]);

  const totalsButtons = () => (
    <Grid container spacing={1}>
      {totalsButtonsConstants(isLoggedIn).map(
        button =>
          button.displayButton({
            isLoggedIn,
            isCheckOut,
          }) && (
            <Grid item xs={12} key={button.id}>
              <CustomButton
                disabled={button.disableButton({
                  cartLength,
                  disablePlaceOrder,
                })}
                color={button.color}
                id={button.id}
                btnType={button.btnType}
                label={button.label}
                data-test-id={button.testId}
                onClick={() => {
                  if (button.label === appStrings.common.refresh) {
                    dispatch(setShouldGetCart(true));
                    dispatch(setRefreshSummary(true));
                    return;
                  }
                  isCheckOut ? handlePlaceOrder() : navigate(button.to);
                }}
              />
            </Grid>
          )
      )}
    </Grid>
  );

  const getDesktopView = () =>
    isCheckOut ? (
      <CartTotalsTabletDesktop
        totalsButtons={totalsButtons}
        isLoggedIn={isLoggedIn}
        isSmallDevice={false}
        totals={totals}
        css={css}
        isCheckOut={isCheckOut}
      />
    ) : (
      <CartTotalsMobile
        totalsButtons={totalsButtons}
        isLoggedIn={isLoggedIn}
        totals={totals}
        isCheckOut={isCheckOut}
      />
    );

  return loading ? (
    <CartTotalsSkeleton />
  ) : (
    <CustomBox
      fadeBorder
      customClass={isSmallDevice && css.containerXs}
      id="ShoppingCart-cartTotals"
      data-test-id="cartTotals"
    >
      <Grid container spacing={1} className={css.cartTotals}>
        <Grid item container>
          {isXSDevice ? (
            getDesktopView()
          ) : (
            <CartTotalsTabletDesktop
              totalsButtons={totalsButtons}
              isLoggedIn={isLoggedIn}
              isSmallDevice={isSmallDevice}
              totals={totals}
              css={css}
            />
          )}
        </Grid>
        {isCheckOut && (
          <Grid item>
            <Typography>
              {appStrings.order.privacyPolicy}
              <Link to={sepLinks.privacyPolicy} target="_blank">
                {appStrings.common.privacyPolicy}
              </Link>
              {' and '}
              <Link
                to={`${process.env.REACT_APP_WEB_ROOT}/${websiteUrls.termsAndConditions}`}
                target="_blank"
              >
                {appStrings.common.termsAndConditions}
              </Link>
            </Typography>
          </Grid>
        )}
      </Grid>
    </CustomBox>
  );
}

CartTotals.propTypes = {
  handlePlaceOrder: PropTypes.func,
  disablePlaceOrder: PropTypes.bool,
};

export default CartTotals;
