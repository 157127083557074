export const customerInfoErrorFields = [
  'firstName',
  'middleName',
  'lastName',
  'email',
  'phone1',
  'phone2',
  'birthdate',
];
export const schoolInfoErrorFields = [
  'schoolState',
  'schoolBp',
  'graduationDate',
  'currentInstructorName',
];
export const employerInfoErrorFields = [
  'presentEmployer',
  'futureEmployer',
  'previousEmployers',
];
export const addressesErrorFields = [
  'permanentAddress',
  'currentAddress',
  'previousAddresses',
];
export const referencesErrorFields = ['references'];
