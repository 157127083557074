import appStrings from '../../../core/strings/appStrings';

export const footerSnaponLinks = [
  {
    name: appStrings.footer.snaponCorporate,
    to: 'https://www.snapon.com/',
  },
  {
    name: appStrings.footer.customerService,
    to: 'https://www.snapon.com/Industrial-Education/OffNav/Contact-Us.htm',
  },
  {
    name: appStrings.footer.privacyPolicy,
    to: 'https://www.snapon.com/en/us-can/privacypolicy/industrial',
  },
];

export const footerSocialMedia = {
  facebookLink: 'https://www.facebook.com/Snapon.ToolsforLife/',
  instagramLink: 'https://www.instagram.com/snapon_toolsforlife/',
};
