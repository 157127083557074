import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import React from 'react';
import { TextField } from '@mui/material';
import useNumberFieldStyles from './useNumberFieldStyles';
import classNames from 'classnames';

function NumberField({ field, fieldErrors, control, ...props }) {
  const numberStyles = useNumberFieldStyles().classes;

  const getHelperText = () => {
    if (fieldErrors && fieldErrors.type !== 'required') {
      return field.helperText ? field.helperText : fieldErrors.message || ' ';
    }
    return ' ';
  };

  const getFormat = () => {
    if (field.validations.maxLength) {
      return '#'.repeat(field.validations.maxLength);
    }
    return null;
  };

  const handleOnKeyPress = event => {
    const clipboardData = event.clipboardData?.getData('Text');
    if (
      event.key === '0' &&
      event.target.selectionStart === 0 &&
      field.removeStartZero
    ) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    if (
      event.type === 'paste' &&
      clipboardData.charAt(0) === '0' &&
      field.removeStartZero
    ) {
      props.fieldRef.current.value = parseInt(clipboardData);
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    if (event.key === 'Enter' && !event.shiftKey && field.onEnterCallback) {
      field.onEnterCallback();
    }
    if (event.key === 'Enter' && event.shiftKey && field.onShiftEnterCallback) {
      field.onShiftEnterCallback();
    }
  };

  return (
    <>
      <Controller
        control={control}
        defaultValue={field.defaultValue ?? ''}
        name={field.name}
        rules={{ ...field.validations }}
        render={({ field: { ref, onChange, value, ...prop } }) => (
          <NumberFormat
            customInput={TextField}
            format={getFormat()}
            inputRef={e => {
              ref.current = e;
              if (props?.fieldRef) {
                props.fieldRef.current = e;
              }
            }}
            onValueChange={v => {
              onChange(v.value);
              field.onChangeCallback && field.onChangeCallback(v.value);
            }}
            onKeyPress={handleOnKeyPress}
            onPaste={handleOnKeyPress}
            onFocus={field.onFocus}
            onBlur={field.onBlur}
            variant="outlined"
            {...prop}
            fullWidth={props.fullWidth}
            FormHelperTextProps={{
              classes: { root: numberStyles.helperText },
            }}
            label={field.label}
            name={field.name}
            value={value}
            disabled={field.disabled ?? false}
            size={props.size ?? 'small'}
            allowNegative={field.allowNegative || false}
            className={
              fieldErrors ? `${fieldErrors && numberStyles.errors}` : null
            }
            InputLabelProps={{
              classes: { root: fieldErrors && numberStyles.errors },
            }}
            inputProps={{
              ...props.inputProps,
              'data-test-id': `numberInput-${field.name}`,
            }}
            InputProps={{ ...props.InputProps }}
            helperText={getHelperText()}
            required={field.validations.required}
            id={field.name}
          />
        )}
      />
      {fieldErrors?.message && (
        <span
          id="errorMessage"
          className={classNames(numberStyles.errorMessage)}
        >
          {fieldErrors.message}
        </span>
      )}
    </>
  );
}

export default NumberField;
