import {
  addressPattern,
  digitPattern,
  nameStringPattern,
} from '../../../../core/strings/regexValidations';
import { dateFormat, phoneMask } from '../../../../core/strings/appConstants';

import appStrings from '../../../../core/strings/appStrings';
import { isValidZipCode } from '../../../../core/services/zipCodeService';
import { positionOptions } from '../../../../core/strings/options';
import store from '../../../../core/redux/store';

const defaultGridLayout = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 4,
};

export const studentGridLayout = {
  xs: 12,
  sm: 6,
  md: 6,
  lg: 6,
};

const createNewEmployer = ({
  section,
  labelBase,
  changeActions,
  index,
  getValues,
  setValue,
  disabledStates,
  otherGridLayout,
  isStudent,
}) => {
  const indexedValue = index >= 0 ? `.${index}` : '';
  const sectionAndIndex = `${section}${indexedValue}`;
  const gridLayout = isStudent
    ? studentGridLayout
    : otherGridLayout ?? defaultGridLayout;

  const getCities = () => {
    const zipCodesOptions = store.getState().zipCode.formattedCodeList;
    let code = getValues(`${sectionAndIndex}.zipCode`);
    const options = zipCodesOptions?.[code]?.cities || [];
    if (options.length === 1) {
      // use setTimeout to allow Employers component to render before City value is updated (prevent unit tests error)
      setTimeout(() => {
        setValue(`${sectionAndIndex}.city`, options[0].label);
      });
    }
    return options;
  };

  return [
    {
      name: `${sectionAndIndex}.id`,
      type: 'hidden',
    },
    {
      name: `${sectionAndIndex}.addressId`,
      type: 'hidden',
    },
    {
      name: `${sectionAndIndex}.name`,
      label: appStrings.studentProfile[labelBase],
      type: 'text',
      validations: { maxLength: 50, pattern: nameStringPattern },
      gridLayout,
      helperText: appStrings.validationMessages.nameString,
    },
    {
      name: `${sectionAndIndex}.addressLine1`,
      label: appStrings.studentProfile[`${labelBase}Address1`],
      type: 'text',
      validations: { maxLength: 50, pattern: addressPattern },
      gridLayout,
      helperText: appStrings.validationMessages.address,
    },
    {
      name: `${sectionAndIndex}.addressLine2`,
      label: appStrings.studentProfile[`${labelBase}Address2`],
      type: 'text',
      validations: { maxLength: 50, pattern: addressPattern },
      gridLayout,
      helperText: appStrings.validationMessages.address,
    },
    {
      name: `${sectionAndIndex}.zipCode`,
      label: appStrings.studentProfile[`${labelBase}Zip`],
      type: 'text',
      validations: {
        minLength: {
          value: 5,
          message: appStrings.validationMessages.xDigitsOnly(5),
        },
        pattern: {
          value: digitPattern,
          message: appStrings.validationMessages.xDigitsOnly(5),
        },
        maxLength: 5,
        validate: {
          invalidZipCode: value => isValidZipCode(value),
        },
      },
      onChangeCallback: () => {
        changeActions.onZipCodeChange({ section, index });
      },
      gridLayout,
    },
    {
      name: `${sectionAndIndex}.city`,
      label: appStrings.studentProfile[`${labelBase}City`],
      type: 'select',
      options: getCities(),
      gridLayout,
    },
    {
      name: `${sectionAndIndex}.state`,
      label: appStrings.studentProfile[`${labelBase}State`],
      type: 'text',
      gridLayout,
      readOnly: true,
      disabled:
        disabledStates[`${sectionAndIndex}`] ??
        !getValues(`${sectionAndIndex}.state`),
    },
    {
      name: `${sectionAndIndex}.phoneNumber`,
      label: appStrings.studentProfile[`${labelBase}Phone`],
      type: 'tel',
      validations: {
        minLength: 10,
        maxLength: 10,
      },
      gridLayout,
      helperText: appStrings.validationMessages.xDigitsOnly(10),
      maskFormat: phoneMask,
    },
    {
      name: `${sectionAndIndex}.positionId`,
      label: appStrings.studentProfile[`${labelBase}Position`],
      type: 'select',
      options: positionOptions,
      gridLayout,
    },
    {
      name: `${sectionAndIndex}.startDate`,
      label: appStrings.studentProfile[`${labelBase}StartDate`],
      type: 'date',
      format: dateFormat,
      gridLayout,
      onChangeCallback: () => {
        changeActions.onEmploymentLengthChange({ section, index });
      },
    },
    {
      name: `${sectionAndIndex}.years`,
      label: appStrings.studentProfile.years,
      type: 'slider',
      validations: { min: 0, max: 30 },
      onChangeCallback: () => {
        changeActions.onEmploymentLengthChange({ section, index });
      },
      gridLayout,
    },
    {
      name: `${sectionAndIndex}.months`,
      label: appStrings.studentProfile.months,
      type: 'slider',
      validations: { min: 0, max: 11 },
      onChangeCallback: () => {
        changeActions.onEmploymentLengthChange({ section, index });
      },
      gridLayout,
    },
    {
      name: `${sectionAndIndex}.lengthOfEmployment`,
      label: appStrings.studentProfile[`${labelBase}LengthOfEmployment`],
      type: 'text',
      defaultValue: appStrings.studentProfile.yearsAndMonths(0, 0),
      gridLayout,
      disabled: true,
    },
  ];
};

export const generatePresentInputs = ({
  changeActions,
  getValues,
  setValue,
  disabledStates,
  otherGridLayout,
  isStudent,
}) => {
  const inputs = createNewEmployer({
    section: 'presentEmployer',
    labelBase: 'presentEmployer',
    changeActions,
    getValues,
    setValue,
    disabledStates,
    otherGridLayout,
    isStudent,
  });
  // Remove Years and Months sliders for Present Employer
  inputs.splice(inputs.length - 3, 2);
  // Set date to only allow dates in the last 100 years
  const currentDate = new Date();
  let startDate = inputs[inputs.length - 2];
  startDate.minDate = new Date().setFullYear(currentDate.getFullYear() - 100);
  startDate.minDateValidationMessage =
    appStrings.validationMessages.presentEmployerMinDate;

  startDate.maxDate = currentDate;
  startDate.maxDateValidationMessage =
    appStrings.validationMessages.presentEmployerMaxDate;

  return inputs;
};

export const generateFutureInputs = ({
  changeActions,
  getValues,
  setValue,
  disabledStates,
  otherGridLayout,
  isStudent,
}) => {
  const inputs = createNewEmployer({
    section: 'futureEmployer',
    labelBase: 'futureEmployer',
    changeActions,
    getValues,
    setValue,
    disabledStates,
    otherGridLayout,
    isStudent,
  });
  // Remove Years, Months and Length of Employment for Future Employer
  inputs.splice(inputs.length - 3, 3);
  // Set date to only allow future days
  const currentDate = new Date();
  let startDate = inputs[inputs.length - 1];
  startDate.minDate = new Date().setDate(currentDate.getDate() + 1);
  startDate.minDateValidationMessage =
    appStrings.validationMessages.futureEmployerMinDate;

  startDate.maxDate = new Date().setFullYear(currentDate.getFullYear() + 10);
  startDate.maxDateValidationMessage =
    appStrings.validationMessages.futureEmployerMaxDate;

  return inputs;
};

export const generatePreviousInputs = ({
  changeActions,
  index,
  getValues,
  setValue,
  disabledStates,
  otherGridLayout,
  isStudent,
}) => {
  const inputs = createNewEmployer({
    section: 'previousEmployers',
    labelBase: 'previousEmployer',
    changeActions,
    index,
    getValues,
    setValue,
    disabledStates,
    otherGridLayout,
    isStudent,
  });

  // Remove Start Date for Previous Employer
  inputs.splice(inputs.length - 4, 1);
  return inputs;
};
