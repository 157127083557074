import { makeStyles } from 'tss-react/mui';

const useFormInputStyles = makeStyles()(theme => ({
  simpleInput: {
    width: '100%',
  },
  hideInput: {
    display: 'none',
  },
  radio: {
    color: theme.palette.common.darkGrey,
    '&.MuiRadio-colorSecondary.Mui-checked': {
      color: theme.palette.common.darkGrey,
    },
  },
  errorMessage: {
    fontSize: '0.8rem',
    color: theme.palette.common.red,
    marginTop: '6px',
  },
}));

export default useFormInputStyles;

export const helperTextStyle = makeStyles()({
  root: {
    margin: 0,
  },
});
