import { makeStyles } from 'tss-react/mui';

const useSerialNumbersDialogStyles = makeStyles()(theme => ({
  serialNumberDialog: {
    padding: '0 0 50px !important',
    minWidth: '500px',
    '& .MuiDialogTitle-root': {
      background: theme.palette.common.secondary6,
      padding: '20px',
      alignItems: 'center',
      display: 'flex',
      '& h2': {
        fontSize: theme.typography.body1.fontSize,
      },
    },
    '& .MuiDialogContent-root': {
      paddingBottom: 0,
    },
  },
  inputsContainer: {
    paddingLeft: '50px',
    paddingRight: '50px',
    '& .MuiFormHelperText-root': {
      display: 'none',
    },
    '& >div:not(:last-of-type):not(:nth-last-of-type(2))': {
      marginBottom: '14px',
    },
  },
  pagination: {
    '& p': {
      display: 'none',
    },
  },
  hide: {
    display: 'none',
  },
  limit: {
    color: theme.palette.common.red,
    marginBottom: `${theme.spacing(3)} !important`,
  },
}));

export default useSerialNumbersDialogStyles;
