/* eslint react/prop-types: 0 */
import React, { useEffect } from 'react';
import { BrComponent, BrPage } from '@bloomreach/react-sdk';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { trackGTMEvent } from './app/modules/shared/Analytics/analyticsService';
import { ErrorCode, ErrorContext } from './ErrorContext';
import BannerCarouselX from './app/modules/shared/BRXM Components/BannerCarouselX';
import BannerX from './app/modules/shared/BRXM Components/BannerX';
import VideoSingleImageBannerX from './app/modules/shared/BRXM Components/VideoSingleImageBannerX';
import RawHTML from './app/modules/shared/BRXM Components/RawHTML';
import FullBannerWithEmbeddedImageX from './app/modules/shared/BRXM Components/FullBannerWithEmbeddedImageX';
import useBrPageComponentStyles from './useBrPageComponentStyles';

export const ERROR_PAGE_PATH_MAP = {
  [ErrorCode.NOT_FOUND]: '/404',
  [ErrorCode.INTERNAL_SERVER_ERROR]: '/500',
  [ErrorCode.GENERAL_ERROR]: '/_error',
};

const MAPPING = {
  BannerCarouselX,
  BannerX,
  VideoSingleImageBannerX,
  RawHTML,
  FullBannerWithEmbeddedImageX,
};

function HomePageView({ children }) {
  const location = useLocation();

  useEffect(() => {
    trackGTMEvent('homepage-pageview', { page_type: 'homepage', orig_ref_url: document.location.href });
  }, [location.pathname]);

  return children;
}
const BrPageComponent = () => {
  const { errorCode, requestURL } = React.useContext(ErrorContext);
  const css = useBrPageComponentStyles().classes;

  const location = useLocation();
  const endpointQueryParameter = 'endpoint';
  if (errorCode && requestURL) {
    const { pathname } = new URL(requestURL);
    if (pathname.endsWith(ERROR_PAGE_PATH_MAP[errorCode])) {
      // To avoid infinite loop
      return <>Error Page</>;
    }
  }

  // Set the path. Set it to error page path if there is an error code
  let path = `${location.pathname}${location.search}`;
  if (errorCode) {
    path = ERROR_PAGE_PATH_MAP[errorCode] + location.search;
  }

  const configuration = {
    debug: false,
    endpointQueryParameter,
    httpClient: axios,
    origin: process.env.REACT_APP_BRXM_ORIGIN,
    endpoint: process.env.REACT_APP_BRXM_ENDPOINT,
    path,
  };

  return (
    <BrPage configuration={configuration} mapping={MAPPING}>
      <HomePageView>
        <BrComponent path="top">
          <div className={`section ${css.brContainer}`}>
            <BrComponent />
          </div>
        </BrComponent>
        {/* eslint-disable-next-line react/no-unknown-property */}
        <div as="section" className={`${css.brContainer} br-container`}>
          <div className="flex-lg-nowrap">
            <BrComponent path="main">
              {/* eslint-disable-next-line react/no-unknown-property */}
              <div xs="auto" className={`${css.brContainer} br-container`}>
                <BrComponent />
              </div>
            </BrComponent>
            <BrComponent path="right">
              {/* eslint-disable-next-line react/no-unknown-property */}
              <div lg="3" className={`${css.brContainer} br-container`}>
                <BrComponent />
              </div>
            </BrComponent>
          </div>
        </div>
        <BrComponent path="bottom">
          {/* eslint-disable-next-line react/no-unknown-property */}
          <div as="section" className={`${css.brContainer} br-container`}>
            <BrComponent />
          </div>
        </BrComponent>
      </HomePageView>
    </BrPage>
  )
};

export default React.memo(BrPageComponent);
