import ApiService from '../../shared/Api/apiService';
import CustomAlert from '../../shared/CustomAlert/CustomAlert';
import { Grid } from '@mui/material';
import appStrings from '../../../core/strings/appStrings';
import { cloneDeep } from 'lodash';
import urls from '../../../core/strings/urls';

export const removeRefreshAlertValues = (
  alertIndex,
  alertValues,
  setAlertValues
) => {
  const newAlertValues = {};
  const remainingAlertValues = cloneDeep(alertValues);
  delete remainingAlertValues[alertIndex];
  Object.keys(remainingAlertValues).forEach(alertKey => {
    newAlertValues[alertKey > alertIndex ? alertKey - 1 : alertKey] =
      alertValues[alertKey];
  });
  setAlertValues(newAlertValues);
};

export const getContent = (fieldName, itemDetails) => {
  // if product is not found in LN display all fields as '-'
  if (!itemDetails.description && itemDetails.quantity === 0) {
    return '-';
  }

  return itemDetails[fieldName] !== ''
    ? itemDetails[fieldName]
    : appStrings.common.na;
};

export const getPopulatedFields = data => {
  const populatedFields = [];
  Object.keys(data).forEach(field => {
    const fieldValue = data[field].trim();
    if (fieldValue) {
      populatedFields.push(fieldValue);
    }
  });
  return populatedFields;
};

export const getItemNotifications = ({
  itemDetails,
  alertValues,
  handleAlertChange,
  handleAlertAction,
  css,
}) => {
  return itemDetails.itemNotifications?.length ? (
    <Grid item xs={12}>
      {itemDetails.itemNotifications?.map((notification, index) => (
        <CustomAlert
          {...notification}
          alertValue={
            alertValues[index]
              ? alertValues[index]
              : notification.messages[0].value
          }
          handleAlertChange={event => handleAlertChange(event, index)}
          handleAlertAction={handleAlertAction}
          key={notification.id}
          alertIndex={index}
          customClass={css.detailsAlert}
        />
      ))}
    </Grid>
  ) : null;
};

export const updateProductDetails = (productNumber, bp, inventoryInfo) => {
  return ApiService.post(urls.updateDetails(productNumber, bp), inventoryInfo);
};
