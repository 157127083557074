import { useDispatch, useSelector } from 'react-redux';

import CustomBox from '../../../shared/CustomBox/CustomBox';
import FormInput from '../../../shared/FormInput/FormInput';
import { Grid } from '@mui/material';
import appStrings from '../../../../core/strings/appStrings';
import { dateFormat } from '../../../../core/strings/appConstants';
import { getTransactionTypeOptions } from '../../../../core/strings/options';
import moment from 'moment';
import { updateFilters } from '../../../../core/redux/slices/historySlice';
import { useForm } from 'react-hook-form';
import useHistoryFiltersStyles from './useHistoryFiltersStyles';

function HistoryFilters({ filtersLoading }) {
  const css = useHistoryFiltersStyles().classes;
  const dispatch = useDispatch();
  const maxTimeValidation = moment().startOf('day');

  const { filters } = useSelector(state => state.history);
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  const formatDateValues = (fieldName, values, isEndDate) => {
    return isEndDate
      ? moment(values[[fieldName]]).endOf('day').toISOString()
      : moment(values[[fieldName]]).startOf('day').toISOString();
  };

  const changeHandler = (fieldName, isDate = false) => {
    const values = getValues();
    const newValue = !isDate
      ? values[fieldName]
      : formatDateValues(fieldName, values, fieldName === 'endDate');

    if (newValue !== filters[fieldName]) {
      dispatch(
        updateFilters({
          ...filters,
          page: { pageNumber: 0 },
          [fieldName]: newValue,
        })
      );
    }
  };

  const filtersFields = {
    startDate: {
      name: 'startDate',
      label: 'Start Date',
      type: 'date',
      format: dateFormat,
      key: 'startDateHistory',
      defaultValue: filters?.startDate,
      onCloseCallback: () => changeHandler('startDate', true),
      maxDate: maxTimeValidation,
      iconSize: 'small',
    },
    endDate: {
      name: 'endDate',
      label: 'End Date',
      type: 'date',
      format: dateFormat,
      key: 'endDateHistory',
      defaultValue: filters?.endDate,
      onCloseCallback: () => changeHandler('endDate', true),
      maxDate: maxTimeValidation,
      iconSize: 'small',
    },
    transactionType: {
      name: 'transactionType',
      label: 'Transaction type',
      type: 'select',
      key: 'transactionTypeHistory',
      options: getTransactionTypeOptions(),
      select: true,
      defaultValue: filters?.transactionType,
      onChangeCallback: () => changeHandler('transactionType'),
    },
  };

  // needed to render the fields after reset filters
  return !filtersLoading ? (
    <CustomBox customClass={css.customBox}>
      <form name="historyFilters">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          data-test-id="historyFiltersContainer"
        >
          <Grid
            item
            container
            xs={8}
            alignItems="center"
            spacing={1}
            rowSpacing={1}
          >
            <Grid item xs={12} lg="auto" className={css.label}>
              <strong>{appStrings.history.selectDateRange}</strong>
            </Grid>
            <Grid item xs={5} lg={3}>
              <FormInput
                field={filtersFields.startDate}
                control={control}
                setValue={setValue}
                errors={errors}
                getValues={getValues}
              />
            </Grid>
            <Grid item container xs={1} justifyContent="center">
              <strong>{appStrings.common.to}</strong>
            </Grid>
            <Grid item xs={5} lg={3}>
              <FormInput
                field={filtersFields.endDate}
                control={control}
                setValue={setValue}
                errors={errors}
                getValues={getValues}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={4}
            alignItems="center"
            justifyContent="flex-end"
            spacing={1}
            rowSpacing={1}
          >
            <Grid item className={css.label}>
              <strong>{appStrings.history.showTransactionTypeFor}</strong>
            </Grid>
            <Grid item xs={6}>
              <FormInput
                field={filtersFields.transactionType}
                control={control}
                setValue={setValue}
                errors={errors}
                getValues={getValues}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </CustomBox>
  ) : null;
}

export default HistoryFilters;
