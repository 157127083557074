import React from 'react';
import SnapOnLogo from '../../../../core/assets/img/SnapOnLogoRep.png';
import classNames from 'classnames';
import { useMediaQuery, useTheme } from '@mui/material';
import useLogoStyles from './useLogoStyles';
import { Link } from 'react-router-dom';
import { ROOT } from '../../../../core/navigation/paths';

function Logo({ customClass }) {
  const theme = useTheme();
  const isSmallDevices = useMediaQuery('(max-width:768px)');
  const isDarkMode = theme.palette.mode === 'dark';
  const css = useLogoStyles().classes;
  const classes = classNames(css.logo, {
    [css.darkMode]: isDarkMode,
    [css.mobileLogo]: isSmallDevices,
  });

  return (
    <Link to={ROOT} className={customClass ?? ''}>
      <img src={SnapOnLogo} alt="" data-test-id="logo" className={classes} />
    </Link>
  );
}

export default Logo;
