import { makeStyles } from 'tss-react/mui';

const usePurchasePowerStyles = makeStyles()(theme => ({
  mainContainer: {
    fontSize: theme.typography.body2.fontSize,
    position: 'relative',
    alignItems: 'center',
  },
  skeletonMainContainer: {
    zIndex: '1',
    background: 'white',
    height: '100%',
  },
  floatRight: {
    float: 'right',
  },
  valueContainer: {
    marginLeft: theme.spacing(0.5),
  },
  yellow: {
    color: theme.palette.common.yellow,
  },
  red: {
    color: theme.palette.common.red,
  },
  green: {
    color: theme.palette.common.darkGreen,
  },
  grey: {
    color: theme.palette.common.darkGrey,
  },
  progressContainer: {
    position: 'relative',
    height: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '& div:not(:last-of-type)': {
      marginLeft: '1px',
    },
    borderRadius: theme.spacing(6),
    overflow: 'hidden',
  },
  progressLine: {
    height: theme.spacing(1),
    borderRadius: theme.spacing(6),
    position: 'absolute',
  },
  greyLine: {
    background: theme.palette.common.veryLightGrey,
  },
  yellowGreyLine: {
    background: `repeating-linear-gradient(
      -45deg,
      ${theme.palette.common.veryLightGrey},
      ${theme.palette.common.veryLightGrey} 4px,
      ${theme.palette.common.yellow} 4px,
      ${theme.palette.common.yellow} 8px
    )`,
  },
  greenGreyLine: {
    background: `repeating-linear-gradient(
      -45deg,
      ${theme.palette.common.veryLightGrey},
      ${theme.palette.common.veryLightGrey} 4px,
      ${theme.palette.common.darkGreen} 4px,
      ${theme.palette.common.darkGreen} 8px
    )`,
  },
  greenLine: {
    background: theme.palette.common.darkGreen,
  },
  redLine: {
    background: theme.palette.common.red,
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  title: {
    marginTop: '8px',
  },
  withMarginTop: {
    marginTop: '45px',
  },
  labelContainer: {
    '@media screen and (max-width:800px)': {
      display: 'inline-flex',
      flexDirection: 'column',
    },
  },
}));

export default usePurchasePowerStyles;
