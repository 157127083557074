import React, { useEffect, useState } from 'react';
import CartItems from './Components/CartItems';
import { Grid, useMediaQuery } from '@mui/material';
import useShoppingCartStyles from './shoppingCartStyles';
import CartTotals from './Components/CartTotals';
import { useDispatch, useSelector } from 'react-redux';
import { setIsActionBarOpen, setIsShoppingCartLoading, setShouldGetCart, setShouldGetCartSummary } from '../../../core/redux/slices/guestCartSlice';
import BrRecommendationsWidget from '../../shared/BrRecommendationsWidget/BrRecommendationsWidget';
import appStrings from '../../../core/strings/appStrings';
import {
  getBrItemRecommendationProducts,
  parseBrRecommendations
} from '../ProductsSearchResults/productSearchResultsService';
import { trackGTMEvent } from '../../shared/Analytics/analyticsService';
import { displayError, displaySuccess } from '../../../core/redux/slices/notificationsSlice';
import { cartTypes, notificationRole } from '../../../core/strings/appConstants';
import { setMoveItemsStatus, setTotalsLoading } from '../../../core/redux/slices/cartSlice';
import { updateStudentSummary } from './ShoppingCartService';

function ShoppingCart() {
  const dispatch = useDispatch();
  const isSmallDevice = useMediaQuery('(max-width:768px)');
  const styleParams = {
    isSmallDevice,
    isCartSummary: false,
  };
  const css = useShoppingCartStyles(styleParams).classes;
  const {
    selectedCartItems,
    selectedSavedCartItems } = useSelector(state => state.guestCart.selectedItems);
  const { isLoggedIn, userDetails } = useSelector(state => state.login);
  const [recommendationsList, setRecommendationsList] = useState([]);
  const moveItemsStatus = useSelector(state => state.cart.moveItemsStatus);
  const [moveFlow, setMoveFlow] = useState(null);

  useEffect(() => {
    if (moveItemsStatus) {
      if (moveItemsStatus.message.length) {
        dispatch(
          displayError({
            message: moveItemsStatus.message[0],
            role: notificationRole.student,
          })
        );
      } else {
        dispatch(
          displaySuccess({
            message: moveFlow === cartTypes.cart ?
              appStrings.cart.movedToCart :
              appStrings.cart.movedToSaved
            })
        );
      }
      dispatch(setIsShoppingCartLoading(true));
      dispatch(setTotalsLoading(true));
      updateStudentSummary(userDetails.userId).then(() => {
        dispatch(setShouldGetCartSummary(true));
        dispatch(setShouldGetCart(true));
        dispatch(setIsShoppingCartLoading(false));
        dispatch(setTotalsLoading(false));
      });
      dispatch(setMoveItemsStatus(null));
    }
  }, [dispatch, moveFlow, moveItemsStatus, userDetails.userId]);

  useEffect(() => {
    trackGTMEvent('pageview', { page_type: 'other', orig_ref_url: document.location.href });
  }, []);

  useEffect(() => {
    if (selectedCartItems?.length || selectedSavedCartItems?.length) {
      dispatch(setIsActionBarOpen(true));
    } else {
      dispatch(setIsActionBarOpen(false));
    }
  }, [selectedCartItems, selectedSavedCartItems, dispatch]);

  const setProducts = (products) => {
    if (products && products.length) {
      const productNumbers = products.map(product => product.productNumber);
      getBrItemRecommendationProducts(productNumbers).then(res => 
        setRecommendationsList(parseBrRecommendations(res))
      );
    }
  }

  return (
    <Grid
      container
      direction="row-reverse"
      spacing={2}
      paddingBottom={2}
      id="shoppingCart"
      data-test-id="shoppingCart-testId"
      className={css.mainContainer}
    >
      <Grid item xs={12} sm md className={css.stickyPos}>
        <CartTotals />
      </Grid>
      <Grid item xs={12} sm={isSmallDevice ? 12 : 8} md={8} lg={9}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12} sm={isSmallDevice ? 12 : 8} md={8} lg={9}>
            <CartItems
              isSavedCart={false}
              selectedItems={selectedCartItems}
              getProducts={setProducts}
              setCartItems={setProducts}
              setMoveFlow={setMoveFlow}
            />
          </Grid>
          {isLoggedIn && (
            <Grid item xs={12} sm={isSmallDevice ? 12 : 8} md={8} lg={9}>
              <CartItems isSavedCart={true} selectedItems={selectedSavedCartItems} setMoveFlow={setMoveFlow} />
            </Grid>
          )}
        </Grid>
      </Grid>
      {recommendationsList?.length ? (
        <Grid item xs marginX={isSmallDevice && 2}>
          <BrRecommendationsWidget
            recommendations={recommendationsList}
            label={appStrings.cart.frequentlyBoughtTogether}
          />
        </Grid>
      ) : null}
    </Grid>
  );
}

export default ShoppingCart;
