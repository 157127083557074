import { Grid, Typography } from '@mui/material';
import {
  paymentMethod,
  paymentMethodLabel,
  paymentTypeLabel,
} from '../../../../core/strings/appConstants';
import { useEffect, useState } from 'react';

import CustomAccordion from '../../../shared/CustomAccordion/CustomAccordion';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import appStrings from '../../../../core/strings/appStrings';
import { formatNumberWithCurrency } from '../../../../core/services/utilsService';
import useOrderHistoryDetailsStyles from '../useOrderHistoryDetailsStyles';

function OrderPaymentHistory({ orderDetails }) {
  const [paymentMethodsUsed, setPaymentMethodsUsed] = useState('');
  const css = useOrderHistoryDetailsStyles().classes;

  const paymentTypeViews = {
    [paymentMethod.voucher]: data => (
      <Grid item container key="voucherPayment">
        <Grid item xs={12}>
          <Typography>
            {`${appStrings.order.voucherNumber}: ${data.paymentDetails}`}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography>
            {`${appStrings.order.voucherAmount}: ${formatNumberWithCurrency(
              data.amount
            )}`}
          </Typography>
        </Grid>
      </Grid>
    ),
    [paymentMethod.otherPayments]: data => (
      <Grid item container key="otherPayment">
        <Grid item xs={12}>
          <Typography>
            {`${appStrings.orderDetailsHistory.otherPaymentNumber}: ${data.paymentDetails}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            {`${appStrings.orderDetailsHistory.otherPaymentType}: ${
              paymentTypeLabel[data.paymentType]
            }`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            {`${
              appStrings.orderDetailsHistory.otherPaymentAmount
            }: ${formatNumberWithCurrency(data.amount)}`}
          </Typography>
        </Grid>
      </Grid>
    ),
    [paymentMethod.card]: data => (
      <Grid item container key="creditCardPayment">
        <Grid item xs={12}>
          <Typography>
            {`${appStrings.orderDetailsHistory.authorizationNumber}: ${data.paymentIdentifier}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            {`${
              appStrings.orderDetailsHistory.creditCardAmount
            }: ${formatNumberWithCurrency(data.amount)}`}
          </Typography>
        </Grid>
      </Grid>
    ),
  };

  useEffect(() => {
    setPaymentMethodsUsed(() => {
      let newPayment = '';
      orderDetails?.payments?.forEach((element, index) => {
        newPayment += `${index !== 0 ? ' + ' : ''}${
          paymentMethodLabel[element.type]
        }`;
      });

      return newPayment;
    });
  }, [orderDetails?.payments]);

  return !orderDetails?.payments ? null : (
    <Grid
      item
      xs={12}
      data-test-id="orderHistoryDetails-orderPayment"
      id="historyOrderDetailsPaymentContainer"
    >
      <CustomBox>
        <CustomAccordion
          title={appStrings.order.payment}
          customClassDescription={css.accordion}
          identifier="historyOrderDetails-orderPayment"
        >
          <Grid container spacing={1} id="historyOrderDetails-paymentDetails">
            <Grid item xs={12}>
              <Typography>
                <span
                  className={css.sectionTitle}
                >{`${appStrings.orderDetailsHistory.paymentMethod}: `}</span>
                {paymentMethodsUsed}
              </Typography>
            </Grid>
            {orderDetails.payments.map(payment =>
              paymentTypeViews[payment.type](payment)
            )}
          </Grid>
        </CustomAccordion>
      </CustomBox>
    </Grid>
  );
}

export default OrderPaymentHistory;
