import React, { useEffect, useState } from 'react';
import CustomInfoBox from '../Components/CustomInfoBox';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { Grid, Typography } from '@mui/material';
import appStrings from '../../../../core/strings/appStrings';
import diagnosticTools from '../../../../core/assets/img/diagnostic-tools.svg';
import toolStorage from '../../../../core/assets/img/tool-storage.svg';
import { useStudentAchievementsStyles } from './studentAchievementsStyles';
import StudentAchievementsSkeleton from './StudentAchievementsSkeleton';
import AchievementContent from './AchievementContent';
import { useDispatch, useSelector } from 'react-redux';
import { getAchievements } from './studentAchievementsServices';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import { notificationRole } from '../../../../core/strings/appConstants';

function StudentAchievements() {
  const css = useStudentAchievementsStyles().classes;
  const dispatch = useDispatch();
  const { userId } = useSelector(state => state.login.userDetails);
  const diagnosticLink = '/category/700040';
  const toolStorageLink = '/category/700030';
  const [isLoading, setIsLoading] = useState(true);
  const [hasPurchased, setHasPurchased] = useState({
    hasPurchasedDiagnostic: false,
    hasPurchasedToolbox: false,
  });

  useEffect(() => {
    if (userId) {
      getAchievements(userId)
        .then(resp => setHasPurchased(resp))
        .catch(() => dispatch(displayError({ role: notificationRole.student })))
        .finally(() => setIsLoading(false));
    }
  }, [dispatch, userId]);

  return (
    <CustomInfoBox
      title={
        <Typography
          fontWeight="bold"
          id="StudentAchievements-title"
          data-test-id="StudentAchievementsTitle-testId"
        >
          {appStrings.studentProfile.myAchievements}
        </Typography>
      }
      content={
        !isLoading ? (
          <Grid
            container
            spacing={4}
            className={css.imagesBox}
            id="StudentAchievements"
            data-test-id="StudentAchievements-contentTestId"
          >
            <AchievementContent
              image={diagnosticTools}
              text={appStrings.common.diagnosticTools}
              goTo={diagnosticLink}
              isDisabled={!hasPurchased.hasPurchasedDiagnostic}
              section={'DiagnosticTools'}
            />
            <AchievementContent
              image={toolStorage}
              text={appStrings.common.toolStorage}
              goTo={toolStorageLink}
              isDisabled={!hasPurchased.hasPurchasedToolbox}
              section={'ToolStorage'}
            />
          </Grid>
        ) : (
          <StudentAchievementsSkeleton />
        )
      }
      icon={<LocalOfferIcon />}
      loading={isLoading}
    />
  );
}

export default StudentAchievements;
