import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import CustomBreadcrumbs from '../../../shared/CustomBreadcrumbs/CustomBreadcrumbs';
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  findCategory,
  getCategory,
  getPaths,
  getProducts,
} from './categoriesContainerServices';
import useCategoriesContainerStyles from '../CategoriesContainer/categoriesContainerStyles';
import CustomPagination from '../../../shared/CustomPagination/CustomPagination';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import CategoryContent from '../CategoryContent/CategoryContent';
import ProductContent from '../../ProductContent/ProductContent';
import ProductContentSkeleton from '../../ProductContent/ProductContentSkeleton';
import { getPageTitle } from '../../../../../pageTitleValues';

function CategoriesContainer() {
  const css = useCategoriesContainerStyles().classes;
  const { id } = useParams();
  const dispatch = useDispatch();
  const listOfPaths = useSelector(state => state.categories.listOfPaths);
  const categories = useSelector(state => state.categories.categoriesData);
  const [currentCategory, setCurrentCategory] = useState(categories);
  const hasProduct = currentCategory?.hasProduct;
  const [paginationDetails, setPaginationDetails] = useState({
    from: 0,
    to: 15,
    page: 1,
    itemsPerPage: 15,
  });
  const [displayedCategories, setDisplayedCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentCategory) {
      const newDisplayedCategories = currentCategory.subCategories.slice(
        paginationDetails.from,
        paginationDetails.to
      );
      setDisplayedCategories(newDisplayedCategories);
    }
    document.title = `${currentCategory?.name} - ${getPageTitle()}`;
  }, [
    currentCategory,
    paginationDetails.from,
    paginationDetails.to,
    listOfPaths,
  ]);

  useEffect(() => {
    if (products.length && hasProduct) {
      const newDisplayedItems = products.slice(
        paginationDetails.from,
        paginationDetails.to
      );
      setDisplayedProducts(newDisplayedItems);
    }
  }, [hasProduct, paginationDetails.from, paginationDetails.to, products]);

  useEffect(() => {
    if (currentCategory?.name && currentCategory?.name !== 'Tools') {
      getPaths(currentCategory?.breadcrumb, dispatch, currentCategory);
    }
  }, [currentCategory, dispatch]);

  useEffect(() => {
    const newCategory = findCategory(currentCategory, id);
    if (newCategory) {
      setCurrentCategory(newCategory);
    } else if (currentCategory?.id !== Number(id)) {
      getCategory(id, setCurrentCategory, categories);
      setProducts([]);
      setDisplayedProducts([]);
    }
  }, [categories, currentCategory, id]);

  useEffect(() => {
    if (hasProduct && !products.length) {
      setLoading(true);
      getProducts(currentCategory?.id)
        .then(resp => setProducts(resp))
        .catch(() => dispatch(displayError()))
        .finally(() => setLoading(false));
    } else if (!hasProduct && products.length) {
      setProducts([]);
      setDisplayedProducts([]);
    }
  }, [currentCategory, dispatch, hasProduct, products.length]);

  const handlePageChange = ({ from, to, page, itemsPerPage }) => {
    setPaginationDetails({ from, to, page, itemsPerPage });
  };

  const handleDisplayedProducts = () =>
    !loading ? (
      displayedProducts.map((product, index) => (
        <ProductContent
          id={`product_${index}`}
          key={index}
          productNumber={product.productNumber}
          image={product.image}
          productName={product.name}
          listPrice={product.listPrice}
          studentPrice={product.studentPrice}
        />
      ))
    ) : (
      <ProductContentSkeleton />
    );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className={css.breadCrumbs}>
        <CustomBreadcrumbs
          listOfPaths={listOfPaths}
          currentPath={{ label: currentCategory?.name }}
          isLoading={false}
          currentLinkColor={'red'}
          textDecoration="none"
        />
      </Grid>
      <Grid item container spacing={1} alignItems="center" xs={12}>
        <Grid item>
          <Typography variant="h1" className={css.title}>
            {currentCategory?.name}
          </Typography>
        </Grid>
        <Grid item>{`(${
          !hasProduct
            ? `${displayedCategories.length}`
            : `${displayedProducts.length}`
        } items)`}</Grid>
      </Grid>
      <Grid item container spacing={2} className={css.categoriesContainer}>
        {!hasProduct
          ? displayedCategories.map((elem, index) => (
              <Grid
                item
                xs={6}
                sm={4}
                md={4}
                xl={2}
                key={index}
                className={css.contentButton}
              >
                <CategoryContent
                  id={elem.id}
                  name={elem.name}
                  image={elem.image}
                  css={css}
                />
              </Grid>
            ))
          : handleDisplayedProducts()}
        {(currentCategory?.subCategories.length >
          paginationDetails.itemsPerPage ||
          (products.length > paginationDetails.itemsPerPage && hasProduct)) && (
          <Grid item xs={12}>
            <CustomPagination
              itemsLength={
                !hasProduct
                  ? currentCategory?.subCategories.length
                  : products.length
              }
              itemsPerPage={paginationDetails.itemsPerPage}
              onPageChangeCallback={handlePageChange}
              shape={'rounded'}
              variant={'outlined'}
              isStudent={true}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default CategoriesContainer;
