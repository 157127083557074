import { Checkbox, Grid, Typography } from '@mui/material';
import React from 'react';
import useCheckoutStyles from '../checkoutStyles';
import PropTypes from 'prop-types';
import { addressesTypes, sectionsAddresses } from '../checkoutConstants';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  createNewAddressFields,
  formatAddressAsText,
  isSameAsShippingAddress,
} from '../checkoutService';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import appStrings from '../../../../core/strings/appStrings';
import {
  buttonColors,
  buttonTypes,
} from '../../../../core/strings/appConstants';
import AddIcon from '@mui/icons-material/Add';
import FormInput from '../../../shared/FormInput/FormInput';
import MandatoryFieldsMessage from '../../../shared/MandatoryFieldsMessage/MandatoryFieldsMessage';

function SelectAddress({
  section,
  selectedCheckbox,
  setSelectedCheckbox,
  studentAddresses,
  selectedAddresses,
  formProps,
}) {
  const css = useCheckoutStyles().classes;
  const { control, setValue, errors, trigger, getValues, reset } = formProps;

  return (
    <Grid container rowGap={1} className={css.boxStyle}>
      {sectionsAddresses[section].map((address, index) => (
        <Grid
          item
          container
          xs={12}
          className={css.checkboxAddressContainer}
          key={index}
          onClick={() => {
            setSelectedCheckbox(prev => ({
              ...prev,
              [section]: address.name,
            }));
            reset({});
          }}
          id={`${section}_${address.name}`}
        >
          <Checkbox
            checked={selectedCheckbox[section] === address.name}
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<RadioButtonCheckedIcon />}
            id={`${section}_${address.name}_btn`}
          />

          <Grid item container xs spacing={1}>
            <Grid item xs={12}>
              {address.text}
            </Grid>
            <Grid item>
              <Typography color="black" fontSize="12px">
                {formatAddressAsText({
                  address: isSameAsShippingAddress(address.name)
                    ? selectedAddresses.shippingAddress
                    : studentAddresses[address.name],
                })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ))}
      {selectedCheckbox[section] !== addressesTypes.addNewAddress ? (
        <CustomButton
          id={`${section}_newAddress`}
          color={buttonColors.black}
          btnType={buttonTypes.secondary}
          startIcon={<AddIcon />}
          label={appStrings.common.newAddress}
          customClass={css.newAddressBtn}
          onClick={() =>
            setSelectedCheckbox(prev => ({
              ...prev,
              [section]: addressesTypes.addNewAddress,
            }))
          }
        />
      ) : (
        <Grid container spacing={2} paddingTop={2}>
          {createNewAddressFields({
            section,
            setValue,
            trigger,
            getValues,
          }).map(field => (
            <Grid item {...field.gridLayout} key={field.name}>
              <FormInput
                field={field}
                control={control}
                setValue={setValue}
                errors={errors}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {selectedCheckbox[section] === addressesTypes.addNewAddress && <MandatoryFieldsMessage />}
    </Grid>
  );
}

SelectAddress.prototypes = {
  section: PropTypes.string,
  selectedCheckbox: PropTypes.string,
  setSelectedCheckbox: PropTypes.func,
  studentAddresses: PropTypes.object,
  selectedAddresses: PropTypes.object,
  formProps: PropTypes.object,
};

export default SelectAddress;
