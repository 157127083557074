import {
  setCartLength,
  setCartSummaryItems,
  setCartTotalsWithoutDiscount,
  setIsShoppingCartLoading,
  setLoadingAddProduct,
  setSelectedItems,
  setShouldGetCart,
  setShouldGetCartSummary,
  setStudentCartId,
} from '../../../core/redux/slices/guestCartSlice';
import {
  displayError,
  displaySuccess,
} from '../../../core/redux/slices/notificationsSlice';
import appStrings from '../../../core/strings/appStrings';
import {
  cartTypes,
  notificationRole,
} from '../../../core/strings/appConstants';
import ApiService from '../../shared/Api/apiService';
import urls from '../../../core/strings/urls';
import moment from 'moment';
import {
  formatNumberWithCurrency,
  getDisplayErrors,
} from '../../../core/services/utilsService';
import {
  setTotals,
  setTotalsLoading,
} from '../../../core/redux/slices/cartSlice';
import { trackGTMEvent } from '../../shared/Analytics/analyticsService';
import store from '../../../core/redux/store';
import { getProductDetails } from '../ProductPage/productPageService';
import {
  closeSignalRConnection,
  startSignalRConnection,
} from '../../../core/services/signalR/signalRService';
import { signalRStrings } from '../../../core/strings/signalRStrings';
import {
  displayLoading,
  hideLoading,
} from '../../../core/redux/slices/commonSlice';

export const getDataToDisplayFromTo = (products, from, to) => {
  return products?.length ? products.slice(from, to) : [];
};

export const getItemIndex = (index, paginationDetails) =>
  index + (paginationDetails.page - 1) * paginationDetails.itemsPerPage;

export const calculateProductsTotal = products => {
  let price = 0;
  products?.map(
    product => (price += product.listPrice * (product.quantity ?? 1))
  );
  return price;
};

export const formatPrice = price => formatNumberWithCurrency(price);

export const scrollToSectionTop = (section, offset = 200) => {
  setTimeout(() => {
    window.scrollTo(0, section.offsetTop - offset);
  }, 0);
};

export const calculateCartLength = items => {
  if (!items?.length) {
    return 0;
  }

  let sum = 0;
  items.forEach(item => (sum += item.quantity ?? 1));
  return sum;
};

export const deleteProductFromCart = ({
  setGuestItemsCart,
  setDisplayedItems,
  itemsPerPage,
  itemIndex,
  recordIds,
  dispatch,
  isLoggedIn,
  studentId,
  guestItemsCart,
}) => {
  if (isLoggedIn) {
    // STUDENT
    dispatch(setIsShoppingCartLoading(true));
    dispatch(setTotalsLoading(true));
    ApiService.put(urls.deleteItems, {
      studentId: studentId,
      cartId: guestItemsCart[0].shoppingCartId,
      cartItemIds: recordIds ?? [guestItemsCart[itemIndex].recordId],
    })
      .then(() => {
        ApiService.put(urls.updateStudentSummary, {
          studentId: studentId,
        }).then(() => {
          dispatch(setShouldGetCartSummary(true));
        });
      })
      .catch(e => {
        dispatch(setIsShoppingCartLoading(false));
        dispatch(
          displayError({
            message: e,
            role: notificationRole.student,
          })
        );
      })
      .finally(() => {
        dispatch(setShouldGetCart(true));
        dispatch(setTotalsLoading(false));
      });
  } else {
    // GUEST
    const now = moment();
    let guestItemsCart =
      JSON.parse(localStorage.getItem('guestItemsCart'))?.value || [];
    guestItemsCart = guestItemsCart.filter((_, index) =>
      itemIndex >= 0 ? index !== itemIndex : !recordIds.includes(index)
    );
    localStorage.setItem(
      'guestItemsCart',
      JSON.stringify({
        date: now.toJSON(),
        value: guestItemsCart,
      })
    );
    setGuestItemsCart(guestItemsCart);
    setDisplayedItems(guestItemsCart.slice(0, itemsPerPage) || []);
    dispatch(setCartLength(calculateCartLength(guestItemsCart)));
    dispatch(
      setTotals({
        subtotal: formatPrice(calculateProductsTotal(guestItemsCart)),
      })
    );
  }
};

const addProductForGuest = (productDetails, dispatch) => {
  // If the product is missing price, fetch it from the BE before adding to cart
  const promise = productDetails.listPrice
    ? Promise.resolve()
    : getProductDetails(productDetails.productNumber);
  if (!productDetails.listPrice) {
    dispatch(
      setLoadingAddProduct([
        ...store.getState().guestCart.loadingAddProduct,
        productDetails.productNumber,
      ])
    );
  }
  promise
    .then(res => {
      if (res) {
        productDetails.listPrice = res.listPrice;
      }
      const now = moment();
      const guestItemsCart = localStorage.getItem('guestItemsCart');
      const itemsCart = JSON.parse(guestItemsCart)?.value || [];
      itemsCart.push(productDetails);
      trackGTMEvent('click-add', {
        product_id: productDetails.productNumber,
        product_sku: productDetails.productNumber,
        product_name: productDetails.name,
        orig_ref_url: document.location.href,
      });
      localStorage.setItem(
        'guestItemsCart',
        JSON.stringify({
          date: now.toJSON(),
          value: itemsCart,
        })
      );
      dispatch(setCartLength(calculateCartLength(itemsCart)));
      dispatch(
        displaySuccess({
          message: appStrings.cart.addedToCart,
          role: notificationRole.student,
        })
      );
    })
    .catch(e => {
      dispatch(
        displayError({
          message: getDisplayErrors(e),
          role: notificationRole.student,
        })
      );
    })
    .finally(() => {
      const loadingAddProducts = [
        ...store.getState().guestCart.loadingAddProduct,
      ];
      const indexOfProduct = loadingAddProducts.indexOf(
        productDetails.productNumber
      );
      loadingAddProducts.splice(indexOfProduct, 1);
      dispatch(setLoadingAddProduct(loadingAddProducts));
      dispatch(setShouldGetCart(true));
    });
};

export const addProductToCart = (
  productDetails,
  dispatch,
  isLoggedIn,
  studentId,
  cartType
) => {
  if (isLoggedIn) {
    // STUDENT
    dispatch(
      setLoadingAddProduct([
        ...store.getState().guestCart.loadingAddProduct,
        productDetails.productNumber,
      ])
    );
    ApiService.post(urls.addItem, {
      studentId,
      productNumber: productDetails.productNumber,
      quantity: 1,
      cartType: cartType,
      notes: [],
    })
      .then(resp => {
        if (resp) {
          dispatch(
            displaySuccess({
              message:
                cartType === cartTypes.cart
                  ? appStrings.cart.addedToCart
                  : appStrings.cart.savedForLater,
              role: notificationRole.student,
            })
          );
          if (cartType === cartTypes.cart) {
            updateStudentSummary(studentId).then(() =>
              dispatch(setShouldGetCartSummary(true))
            );
            trackGTMEvent('click-add', {
              product_id: productDetails.productNumber,
              product_sku: productDetails.productNumber,
              product_name: productDetails.name,
              orig_ref_url: document.location.href,
            });
          }
        }
      })
      .catch(e => {
        dispatch(
          displayError({
            message: getDisplayErrors(e),
            role: notificationRole.student,
          })
        );
      })
      .finally(() => {
        const loadingAddProducts = [
          ...store.getState().guestCart.loadingAddProduct,
        ];
        const indexOfProduct = loadingAddProducts.indexOf(
          productDetails.productNumber
        );
        loadingAddProducts.splice(indexOfProduct, 1);
        dispatch(setLoadingAddProduct(loadingAddProducts));
        dispatch(setShouldGetCart(true));
      });
  } else {
    // GUEST
    addProductForGuest(productDetails, dispatch);
  }
};

export const moveItemsBetweenCarts = ({
  studentId,
  recordIds,
  productNumbers,
  productNames,
  dispatch,
  target,
}) => {
  dispatch(setIsShoppingCartLoading(true));
  dispatch(setTotalsLoading(true));
  const source = target === cartTypes.cart ? cartTypes.saved : cartTypes.cart;
  ApiService.post(urls.moveItems, {
    studentId: studentId,
    itemsIds: recordIds,
    runValidations: true,
    moveFrom: source,
    moveTo: target,
  })
    .then(resp => {
      if (resp.length) {
        dispatch(
          displayError({
            message: resp[0],
            role: notificationRole.student,
          })
        );
      } else {
        updateStudentSummary(studentId).then(() => {
          dispatch(setShouldGetCartSummary(true));
        });
      }
      if (source === cartTypes.saved) {
        trackGTMEvent('click-add', {
          product_id: productNumbers.join(', '),
          product_sku: productNumbers.join(', '),
          product_name: productNames.join(', '),
          orig_ref_url: document.location.href,
        });
      }
    })
    .catch(() => {
      dispatch(displayError({ role: notificationRole.student }));
    })
    .finally(() => {
      dispatch(setShouldGetCart(true));
      dispatch(setIsShoppingCartLoading(false));
      dispatch(setTotalsLoading(false));
    });
};

export const moveItemsBetweenCartsSignalr = ({
  studentId,
  recordIds,
  productNumbers,
  productNames,
  dispatch,
  target,
}) => {
  dispatch(displayLoading());
  const userId = store.getState().login.userDetails.accountIdentifier;
  const source = target === cartTypes.cart ? cartTypes.saved : cartTypes.cart;
  startSignalRConnection(userId)
    .then(() => {
      ApiService.post(urls.moveItemsSignalr, {
        userId,
        studentId: studentId,
        itemsIds: recordIds,
        runValidations: true,
        moveFrom: source,
        moveTo: target,
        callbackMethodName: signalRStrings.moveCartItems,
      })
        .then(() => {
          if (source === cartTypes.saved) {
            trackGTMEvent('click-add', {
              product_id: productNumbers.join(', '),
              product_sku: productNumbers.join(', '),
              product_name: productNames.join(', '),
              orig_ref_url: document.location.href,
            });
          }
        })
        .catch(() => {
          dispatch(displayError({ role: notificationRole.student }));
          dispatch(hideLoading());
          closeSignalRConnection();
        });
    })
    .catch(() => {
      dispatch(displayError({ role: notificationRole.student }));
      dispatch(hideLoading());
    });
};

export const getStudentCart = userId =>
  ApiService.get(urls.getStudentShoppingCart(userId, cartTypes.cart));

export const getStudentSummary = cartId =>
  ApiService.get(urls.getStudentSummaryByCartId(cartId));

export const updateStudentSummary = studentId =>
  ApiService.put(urls.updateStudentSummary, { studentId: studentId });

export const setStudentCartDetails = (cartDetails, dispatch) => {
  dispatch(setStudentCartId({ studentCartId: cartDetails.cartId }));
  dispatch(setCartSummaryItems({ cartItems: cartDetails.cartItems }));
  dispatch(setCartLength(calculateCartLength(cartDetails.cartItems)));
  dispatch(
    setCartTotalsWithoutDiscount(calculateProductsTotal(cartDetails.cartItems))
  );
};

export const getSavedCart = studentId =>
  ApiService.get(urls.cartDetails(studentId, cartTypes.saved));

export const handleSelectAllCheckbox = ({
  e,
  dispatch,
  isSavedCart,
  guestItemsCart,
}) => {
  if (e.target.checked) {
    dispatch(
      setSelectedItems({
        isSavedCart,
        items: guestItemsCart.map((item, index) => item.recordId ?? index),
      })
    );
  } else {
    dispatch(setSelectedItems({ isSavedCart, items: [] }));
  }
};

export const checkIfAllItemsAreSelected = ({
  guestItemsCart,
  selectedItems,
  isSavedCart,
  setAllItemsSelected,
  dispatch,
}) => {
  if (
    guestItemsCart?.length &&
    selectedItems?.length === guestItemsCart?.length
  ) {
    dispatch(setAllItemsSelected({ isSavedCart, checked: true }));
  } else {
    dispatch(setAllItemsSelected({ isSavedCart, checked: false }));
  }
};

export const getCartItemsId = isSavedCart =>
  `cartItems-${isSavedCart ? 'saved' : 'shopping'}`;

export const saveItem = (studentId, item) => {
  const itemInfo = {
    studentId,
    cartId: item.shoppingCartId,
    cartItemId: item.recordId,
    quantity: item.quantity,
    previousQuantity: item.previousQuantity,
    notes: item.notes,
    studentNotes: item.studentNotes ?? null,
    productNumber: item.productNumber,
  };

  return ApiService.put(urls.editItem, itemInfo);
};

export const getMultipleItemsProductNumbers = (
  guestItemsCart,
  selectedItems
) => {
  const toReturn = [];
  guestItemsCart.map(guestItem => {
    if (selectedItems.indexOf(guestItem.recordId) !== -1) {
      toReturn.push(guestItem.productNumber);
    }
  });
  return toReturn;
};

export const getMultipleItemsProductNames = (guestItemsCart, selectedItems) =>
  guestItemsCart
    .filter(guestItem => selectedItems.indexOf(guestItem.recordId) !== -1)
    .map(item => item.description);
