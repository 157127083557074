import {
  displayError,
  displaySuccess,
} from '../../../../core/redux/slices/notificationsSlice';

import ApiService from '../../../shared/Api/apiService';
import appStrings from '../../../../core/strings/appStrings';
import { cloneDeep } from 'lodash';
import { getStateCodeByName } from '../../../../core/strings/states';
import {
  setRefreshCustomerInfo,
  setRefreshECAppBtn,
} from '../../../../core/redux/slices/commonSlice';
import store from '../../../../core/redux/store';
import urls from '../../../../core/strings/urls';
import { Typography } from '@mui/material';
import { getDateWithoutOffset } from '../../../../core/services/utilsService';
import { isEmptyObject } from './clearEmptySectionsService';
import { updateStudentSummary } from '../../../student/ShoppingCart/ShoppingCartService';
import {
  setIsShoppingCartLoading,
  setShouldGetCartSummary,
} from '../../../../core/redux/slices/guestCartSlice';

const formatDate = date => {
  return new Date(date);
};

export const handleErrors = ({ error }) => {
  const errMsg = typeof error === 'object' ? error?.title : error;
  store.dispatch(
    displayError({
      message: errMsg || appStrings.errors.somethingWentWrong,
    })
  );
};

export const handleSave = () => {
  store.dispatch(
    displaySuccess({
      message: appStrings.studentProfile.studentSuccessfulSave,
    })
  );
};

export const saveSchoolInformation = ({ id, data }) => {
  return saveData(
    urls.schoolInformation,
    formatSchoolInformation({ id, data })
  );
};

export const saveAddressInformation = ({ id, data, setValue }) => {
  return saveData(urls.saveAddresses, formatAddressData({ id, data })).then(
    response => {
      setValue('deletedAddresses', null);
      if (response?.currentAddress) {
        setValue('currentAddress.id', response.currentAddress);
      }
      if (response?.previousAddresses?.length) {
        Object.keys(data.previousAddresses).forEach((key, index) => {
          setValue(
            `previousAddresses.${key}.id`,
            response.previousAddresses[index]
          );
        });
      }
    }
  );
};

export const saveEmployerInformation = ({ id, data, setValue }) => {
  return saveData(urls.saveEmployers, formatEmployerData({ id, data })).then(
    response => {
      setValue('deletedEmployers', null);
      if (response?.presentEmployer) {
        setValue('presentEmployer.id', response.presentEmployer.id);
        setValue(
          'presentEmployer.addressId',
          response.presentEmployer.addressId
        );
      }
      if (response?.futureEmployer) {
        setValue('futureEmployer.id', response.futureEmployer.id);
        setValue('futureEmployer.addressId', response.futureEmployer.addressId);
      }
      if (response?.previousEmployers?.length) {
        Object.keys(response.previousEmployers).forEach((key, index) => {
          setValue(
            `previousEmployers.${key}.id`,
            response.previousEmployers[index].id
          );
          setValue(
            `previousEmployers.${key}.addressId`,
            response.previousEmployers[index].addressId
          );
        });
      }
    }
  );
};

export const saveCustomerInformation = ({ id, data }) => {
  return saveData(
    urls.customerInformation,
    formatCustomerInformation({ id, data })
  );
};

export const saveReferencesInformation = ({ id, data, setValue }) => {
  return saveData(
    urls.saveReferences,
    formatReferencesInformation({ id, data })
  ).then(response => {
    setValue('deletedRefs', null);
    if (response?.references?.length) {
      Object.keys(response.references).forEach((key, index) => {
        setValue(`references.${key}.id`, response.references[index].id);
        setValue(
          `references.${key}.addressId`,
          response.references[index].addressId
        );
      });
    }
  });
};

const saveData = (url, data) => {
  if (data) {
    return ApiService.post(url, data);
  } else {
    return Promise.resolve();
  }
};

const formatSchoolInformation = ({ id, data }) => {
  if (!data.schoolState || !data.schoolBp) {
    return null;
  }

  const {
    schoolState,
    schoolBp,
    graduationDate,
    currentInstructorName,
    studentId = null,
    schoolStudentId,
    approvalStatus,
    isActive,
    isEmployed,
    usesTools,
    receivePromotionalEmails,
    receivePromotionalMessages,
  } = data;

  return {
    id,
    schoolState: schoolState?.trim(),
    schoolBp: schoolBp?.trim(),
    graduationDate: getDateWithoutOffset(graduationDate),
    currentInstructorName: currentInstructorName?.trim(),
    studentId,
    schoolStudentId,
    approvalStatus,
    isActive,
    isEmployed,
    usesTools,
    receivePromotionalEmails,
    receivePromotionalMessages,
  };
};

const formatAddressData = ({ id, data }) => {
  let {
    permanentAddress,
    currentAddress,
    previousAddresses,
    deletedAddresses,
  } = data;
  deletedAddresses = deletedAddresses?.length && JSON.parse(deletedAddresses);

  if (!permanentAddress) {
    return null;
  }

  permanentAddress = formatPermanentAddress(permanentAddress);
  currentAddress = formatCurrentAddress({
    currentAddress,
    deletedAddresses,
  });
  previousAddresses = formatPreviousAddresses(previousAddresses);
  previousAddresses = previousAddresses.concat(
    handleDeletedPreviousAddresses(deletedAddresses)
  );

  return { id, permanentAddress, currentAddress, previousAddresses };
};

const formatPermanentAddress = permanentAddress => {
  const newPermAddress = cloneDeep(permanentAddress);
  newPermAddress.residencyStartDate = formatDate(
    newPermAddress.residencyStartDate
  );

  newPermAddress.residenceType =
    newPermAddress.residenceType === '' ? null : newPermAddress.residenceType;
  newPermAddress.state = getStateCodeByName(newPermAddress.state);
  newPermAddress.addressLine1 = newPermAddress.addressLine1?.trim();
  newPermAddress.addressLine2 = newPermAddress.addressLine2?.trim();
  return newPermAddress;
};

const formatCurrentAddress = ({ currentAddress, deletedAddresses }) => {
  let newCurrentAddress = null;
  if (
    !isEmptyObject({ ...currentAddress, addressType: null, isDeleted: null })
  ) {
    newCurrentAddress = cloneDeep(currentAddress);
    newCurrentAddress.residencyStartDate = formatDate(
      newCurrentAddress.residencyStartDate
    );
    newCurrentAddress.state = getStateCodeByName(newCurrentAddress.state);
    newCurrentAddress.addressLine1 = newCurrentAddress.addressLine1?.trim();
    newCurrentAddress.addressLine2 = newCurrentAddress.addressLine2?.trim();
    if (!newCurrentAddress.id) {
      if (deletedAddresses?.currentAddress?.id) {
        newCurrentAddress.id = deletedAddresses.currentAddress.id;
      } else {
        delete newCurrentAddress.id;
      }
    }
    delete newCurrentAddress.isDeleted;
  } else if (deletedAddresses?.currentAddress?.id) {
    newCurrentAddress = { ...deletedAddresses?.currentAddress };
  }

  return newCurrentAddress;
};

const formatPreviousAddresses = previousAddresses => {
  const filteredPreviousAddresses =
    previousAddresses?.filter(
      elem => !isEmptyObject({ ...elem, addressType: null, isDeleted: null })
    ) || [];

  if (filteredPreviousAddresses?.length) {
    filteredPreviousAddresses.forEach(prevAddress => {
      prevAddress.residencyLength = 12 * prevAddress.year + prevAddress.month;
      prevAddress.state = getStateCodeByName(prevAddress.state);
      prevAddress.addressLine1 = prevAddress.addressLine1?.trim();
      prevAddress.addressLine2 = prevAddress.addressLine2?.trim();
      if (!prevAddress?.id) {
        delete prevAddress.id;
      }
    });
  }
  return filteredPreviousAddresses;
};

const handleDeletedPreviousAddresses = deletedAddresses => {
  const newPrev = [];
  if (deletedAddresses?.previousAddresses?.length) {
    deletedAddresses.previousAddresses.forEach(address => {
      if (address.id) {
        newPrev.push(address);
      }
    });
  }
  return newPrev;
};

const formatCustomerInformation = ({ id, data }) => {
  let {
    firstName,
    middleName,
    lastName,
    suffix,
    email,
    phone1,
    birthdate,
    socialSecurityNumber,
    driverLicenseNumber,
    driverLicenseState,
    phone2,
    cellPhoneCompany,
    maritalStatus,
  } = data;

  if (!firstName) {
    return null;
  }

  if (phone2) {
    const { id: phoneId, phoneNumber, phoneType, isDeleted } = phone2;

    phone2.phoneType = phoneType || 0;

    if (phoneId && !phoneNumber) {
      phone2 = { id: phoneId, isDeleted: true };
    }

    if (!phoneId && phoneNumber) {
      phone2 = {
        phoneNumber,
        phoneType: phoneType || 0,
        isDeleted: isDeleted ?? false,
      };
    }

    if (!phoneId && !phoneNumber && !phoneType) {
      phone2 = null;
    }
  }

  return nullifyData({
    id,
    firstName: firstName?.trim(),
    lastName: lastName?.trim(),
    middleName: middleName?.trim(),
    email: email?.trim(),
    suffix: suffix?.trim(),
    phone1,
    phone2,
    socialSecurityNumber: socialSecurityNumber?.trim(),
    birthdate: getDateWithoutOffset(birthdate),
    driverLicenseNumber: driverLicenseNumber?.trim(),
    driverLicenseState,
    cellPhoneCompany,
    maritalStatus: maritalStatus === '' ? 0 : maritalStatus,
  });
};

const nullifyData = data => {
  if (data) {
    Object.keys(data).forEach(field => {
      if (data[field] === '') {
        data[field] = null;
      }
    });
  }
  return data;
};

const formatEmployerData = ({ id, data }) => {
  let { presentEmployer, futureEmployer, previousEmployers, deletedEmployers } =
    data;
  deletedEmployers = deletedEmployers?.length && JSON.parse(deletedEmployers);

  if (
    !presentEmployer &&
    !futureEmployer &&
    !previousEmployers &&
    !deletedEmployers
  ) {
    return null;
  }
  return {
    studentId: id,
    presentEmployer: formatPresentEmployer(presentEmployer, deletedEmployers),
    futureEmployer: formatFutureEmployer(futureEmployer, deletedEmployers),
    previousEmployers: formatPreviousEmployers(
      previousEmployers,
      deletedEmployers
    ),
  };
};

const formatPresentEmployer = (presentEmployer, deletedEmployers) => {
  let finalEmployer = {};
  if (
    !isEmptyObject({ ...presentEmployer, employerType: null, isDeleted: null })
  ) {
    if (!presentEmployer.id && deletedEmployers?.presentEmployer) {
      presentEmployer.id = deletedEmployers.presentEmployer.id;
      presentEmployer.addressId = deletedEmployers.presentEmployer.addressId;
      delete deletedEmployers.presentEmployer;
    }
    presentEmployer.employerType = 1;
    presentEmployer.positionId = presentEmployer.positionId
      ? presentEmployer.positionId
      : 0;
    delete presentEmployer.lengthOfEmployment;
    presentEmployer.state = getStateCodeByName(presentEmployer.state);
    if (!presentEmployer.addressId) {
      delete presentEmployer.addressId;
    }
    presentEmployer.addressLine1 = presentEmployer.addressLine1?.trim();
    presentEmployer.addressLine2 = presentEmployer.addressLine2?.trim();
    presentEmployer.startDate = getDateWithoutOffset(presentEmployer.startDate);
    finalEmployer = presentEmployer;
  } else {
    finalEmployer = deletedEmployers?.presentEmployer
      ? deletedEmployers.presentEmployer
      : null;
  }
  return nullifyData(finalEmployer);
};

const formatFutureEmployer = (futureEmployer, deletedEmployers) => {
  let finalEmployer = {};
  if (
    !isEmptyObject({ ...futureEmployer, employerType: null, isDeleted: null })
  ) {
    if (!futureEmployer.id && deletedEmployers?.futureEmployer) {
      futureEmployer.id = deletedEmployers.futureEmployer.id;
      futureEmployer.addressId = deletedEmployers.futureEmployer.addressId;
      delete deletedEmployers.futureEmployer;
    }
    futureEmployer.employerType = 3;
    futureEmployer.positionId = futureEmployer.positionId
      ? futureEmployer.positionId
      : 0;
    futureEmployer.state = getStateCodeByName(futureEmployer.state);
    if (!futureEmployer.addressId) {
      delete futureEmployer.addressId;
    }
    futureEmployer.addressLine1 = futureEmployer.addressLine1?.trim();
    futureEmployer.addressLine2 = futureEmployer.addressLine2?.trim();
    futureEmployer.startDate = getDateWithoutOffset(futureEmployer.startDate);
    finalEmployer = futureEmployer;
  } else {
    finalEmployer = deletedEmployers?.futureEmployer
      ? deletedEmployers.futureEmployer
      : null;
  }
  return nullifyData(finalEmployer);
};

const formatPreviousEmployers = (previousEmployers, deletedEmployers) => {
  let finalPreviousEmployers = [];
  const filteredPreviousEmployers = previousEmployers?.filter(
    elem => !isEmptyObject({ ...elem, employerType: null, isDeleted: null })
  );
  if (filteredPreviousEmployers) {
    filteredPreviousEmployers.forEach(employer => {
      employer.employerType = 2;
      employer.positionId =
        employer.positionId === '' ? 0 : employer.positionId;
      employer.employmentLength = employer.years * 12 + employer.months || 0;
      employer.state = getStateCodeByName(employer.state);
      delete employer.lengthOfEmployment;
      if (!employer.addressId) {
        delete employer.addressId;
      }
      employer.addressLine1 = employer.addressLine1?.trim();
      employer.addressLine2 = employer.addressLine2?.trim();
    });
    finalPreviousEmployers.push(...filteredPreviousEmployers);
  }
  if (deletedEmployers?.previousEmployers) {
    finalPreviousEmployers.push(
      ...deletedEmployers.previousEmployers.filter(
        deletedEmployer => deletedEmployer.id
      )
    );
  }
  finalPreviousEmployers = finalPreviousEmployers.map(employer => {
    return nullifyData(employer);
  });
  return finalPreviousEmployers.length ? finalPreviousEmployers : null;
};

export const validateDatePickers = () => {
  const datePickerNames = {
    customerInfo: ['birthdate'],
    schoolInfo: ['graduationDate'],
    employerInfo: {
      presentEmployer: ['startDate'],
      futureEmployer: ['startDate'],
    },
    permanentAddress: ['residencyStartDate'],
    currentAddress: ['residencyStartDate'],
  };
  const finalErrors = {
    customerInfo: false,
    schoolInfo: false,
  };
  let valid = true;
  Object.keys(datePickerNames).forEach(prop => {
    const finalPickers = datePickerNames[prop].length
      ? datePickerNames[prop]
      : generateDateFields(datePickerNames[prop]);
    finalPickers.forEach(picker => {
      const datePickerLabel = document.getElementById(`${picker}-label`);
      if (datePickerLabel && datePickerLabel.classList.contains('Mui-error')) {
        finalErrors[prop] = true;
        valid = false;
      }
    });
  });
  return { valid, errors: finalErrors };
};

const generateDateFields = parentInfo => {
  const finalPickers = [];
  Object.keys(parentInfo).forEach(prop => {
    parentInfo[prop].forEach(picker => {
      finalPickers.push(`${prop}.${picker}`);
    });
  });
  return finalPickers;
};

export const onFormSubmit = ({
  data,
  pageLeaveAndSaveTimeout,
  id,
  setValue,
  setFormWasSubmitted,
  setSectionErrors,
  refreshSectionErrors,
  setError,
  reset,
  saveCallback,
  dispatch,
  isStudent,
  navigateToSchoolsPage,
}) => {
  const initialData = cloneDeep(data);
  setFormWasSubmitted(true);
  if (validateDatePickers().valid) {
    setSectionErrors({ customerInfo: false, schoolInfo: false });
    Promise.all([
      saveSchoolInformation({ id, data }),
      saveAddressInformation({ id, data, setValue }),
      saveEmployerInformation({ id, data, setValue }),
      saveCustomerInformation({ id, data }),
      saveReferencesInformation({ id, data, setValue }),
    ])
      .then(() => {
        handleSave();
        saveCallback(data, pageLeaveAndSaveTimeout);
        if (pageLeaveAndSaveTimeout) {
          pageLeaveAndSaveTimeout();
        }
        if (!data.phone2.id && data.phone2.phoneNumber) {
          dispatch(setRefreshCustomerInfo(true));
        }
        if (isStudent) {
          dispatch(setRefreshECAppBtn(true));
          updateStudentSummary(id).then(() => {
            dispatch(setShouldGetCartSummary(true));
            dispatch(setIsShoppingCartLoading(false));
          });
        }
        if (navigateToSchoolsPage) {
          navigateToSchoolsPage(data.approvalStatus);
        }
      })
      .catch(result => {
        handleSaveErrors(result, setError);
      });
    reset(initialData);
  } else {
    handleErrors({
      error: appStrings.validationMessages.correctErrors,
    });
    refreshSectionErrors();
  }
};

export const handleSaveErrors = (result, setError) => {
  if (
    result.errors &&
    Object.keys(result.errors).length === 1 &&
    result.errors?.Email
  ) {
    handleErrors({
      error: appStrings.validationMessages.existingEmail,
    });
    setError('email', {
      type: 'manual',
      message: appStrings.validationMessages.existingEmailHelperText,
    });
  } else {
    handleErrors({
      error: appStrings.validationMessages.correctErrors,
    });
    if (result.errors) {
      Object.keys(result.errors).forEach(field => {
        setError(convertNameToCamelCase(field), {
          type: 'manual',
          message: result.errors[field][0],
        });
      });
    }
  }
};

const convertNameToCamelCase = name => {
  const firstChar = name.substring(0, 1);
  const newName = name.replace(firstChar, firstChar.toLowerCase());
  return newName.replace(/([._][A-Z])/gi, match => {
    return match.toLowerCase();
  });
};

export const onFormError = ({
  setFormWasSubmitted,
  refreshSectionErrors,
  errorList,
}) => {
  setFormWasSubmitted(true);
  const validDates = refreshSectionErrors(errorList);
  const requiredCounter =
    JSON.stringify(errorList).match(/"type":"required"/g)?.length;
  const errorsCounter = JSON.stringify(errorList).match(
    /"type":".+?","message"/g
  )?.length;
  if (errorsCounter === requiredCounter && validDates) {
    handleErrors({
      error: appStrings.validationMessages.requiredFields,
    });
  } else {
    handleErrors({
      error: appStrings.validationMessages.correctErrors,
    });
  }
};

const formatReferencesInformation = ({ id, data }) => {
  let { references, deletedRefs } = data;
  const newRefs =
    references?.filter(
      elem => Object.values(elem).some(val => val !== '') ?? false
    ) || [];
  if (newRefs?.length) {
    newRefs.forEach(ref => {
      ref.state = getStateCodeByName(ref.state);
      if (!ref?.id) {
        delete ref.id;
      }
      if (!ref?.addressId) {
        delete ref.addressId;
      }
      if (!ref?.relationship) {
        ref.relationship = null;
      }
      ref.addressLine1 = ref.addressLine1?.trim();
      ref.addressLine2 = ref.addressLine2?.trim();
    });
  }
  deletedRefs = deletedRefs?.length && JSON.parse(deletedRefs);
  if (deletedRefs) {
    newRefs.push(...deletedRefs.filter(ref => ref.id));
  }

  return newRefs.length ? { studentId: id, references: newRefs } : null;
};

export const objectNotEmpty = object => {
  return Object.values(object ?? {}).some(
    val =>
      val !== undefined &&
      val !== null &&
      val !== 0 &&
      String(val).trim().length > 0 &&
      val !== appStrings.studentProfile.yearsAndMonths(0, 0)
  );
};

export const getECAppBtnHoverMessage = (isBtnDisabled, isDirty) =>
  isBtnDisabled && !isDirty ? (
    <Typography>{appStrings.validationMessages.ecAppBtnDisabled}</Typography>
  ) : isDirty ? (
    <Typography>{appStrings.validationMessages.saveChangesToEnable}</Typography>
  ) : (
    ''
  );
