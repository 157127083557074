import { dateFormat, phoneMask } from '../../../../core/strings/appConstants';
import {
  digitPattern,
  nameStringPattern,
  noSpacePattern,
} from '../../../../core/strings/regexValidations';

import appStrings from '../../../../core/strings/appStrings';
import { xYearsFromNow } from '../../../../core/services/utilsService';
import TypeAheadField from './TypeAheadField';
import {
  getSchoolNamesTypeAhead,
  getStudentNamesTypeAhead,
} from '../studentSearchService';

const gridLayout = {
  xs: 6,
};
const gridLayoutRangedInputs = {
  xs: 5,
};
const gridLayoutFullLine = {
  xs: 12,
};
const gridLayoutConnectingText = {
  xs: 1,
};

export const schoolSearchInputs = ({
  schoolListSelectedRep,
  validations,
  onDatesChangeCallback,
}) => {
  return [
    {
      ...TypeAheadField({
        field: {
          name: 'name',
          label: appStrings.searchInputs.studentName,
          type: 'autocomplete',
          validations: {
            maxLength: 51,
            pattern: nameStringPattern,
            minLength: 3,
          },
          gridLayout: gridLayoutFullLine,
          helperText: appStrings.validationMessages.nameString,
          autoFocus: true,
          isPartialSearchEnabled: true,
          disabled: false,
          linkText: appStrings.searchInputs.goToStudent,
        },
        getFieldOptions: getStudentNamesTypeAhead,
        debounced: schoolListSelectedRep === appStrings.searchInputs.allReps,
      }),
    },
    {
      ...TypeAheadField({
        field: {
          name: 'schoolName',
          label: appStrings.searchInputs.schoolName,
          type: 'autocomplete',
          validations: {
            maxLength: 50,
            minLength: 3,
          },
          gridLayout,
          isPartialSearchEnabled: true,
          disabled: false,
        },
        getFieldOptions: getSchoolNamesTypeAhead,
      }),
    },
    {
      name: 'email',
      label: appStrings.searchInputs.email,
      type: 'text',
      validations: {
        maxLength: 50,
        pattern: noSpacePattern,
      },
      gridLayout,
      helperText: appStrings.validationMessages.noSpaces,
    },
    {
      name: 'bpNumber',
      label: appStrings.searchInputs.bpNumber,
      type: 'text',
      validations: {
        maxLength: 9,
        pattern: digitPattern,
      },
      gridLayout,
      helperText: appStrings.validationMessages.digitsOnly,
    },
    {
      name: 'phoneNumber',
      label: appStrings.searchInputs.phoneNumber,
      type: 'tel',
      validations: {
        maxLength: 10,
      },
      gridLayout,
      helperText: appStrings.validationMessages.xDigitsOnly(10),
      maskFormat: phoneMask,
    },
    {
      name: 'graduationStartDate',
      label: appStrings.searchInputs.graduationStartDate,
      type: 'date',
      format: dateFormat,
      gridLayout,
      maxDate: validations.maxGradSD ?? undefined,
      maxDateValidationMessage: appStrings.validationMessages.maxDate,
      minDateValidationMessage: appStrings.validationMessages.minDate,
      onChangeCallback: onDatesChangeCallback,
    },
    {
      name: 'graduationEndDate',
      label: appStrings.searchInputs.graduationEndDate,
      type: 'date',
      format: dateFormat,
      gridLayout,
      minDate: validations.minGradED ?? undefined,
      maxDateValidationMessage: appStrings.validationMessages.graduationDateMax,
      onChangeCallback: onDatesChangeCallback,
    },
    {
      name: 'studentStatus',
      type: 'radio',
      validations: {},
      gridLayout: gridLayoutFullLine,
      defaultValue: 'all',
      labelPlacement: 'start',
      options: [
        {
          label: appStrings.searchInputs.allStudents,
          value: 'all',
        },
        {
          label: appStrings.searchInputs.activeStudents,
          value: 'active',
        },
        {
          label: appStrings.searchInputs.inactiveStudents,
          value: 'inactive',
        },
      ],
    },
  ];
};

export const approveRejectSearchInputs = (
  validations,
  onDatesChangeCallback,
  onChangeCallbackCheckboxes,
  checkboxesDisabled
) => [
  {
    name: 'name',
    label: appStrings.searchInputs.studentName,
    type: 'text',
    validations: {
      maxLength: 51,
      pattern: nameStringPattern,
    },
    gridLayout,
    helperText: appStrings.validationMessages.nameString,
    autoFocus: true,
  },
  {
    name: 'instructorName',
    label: appStrings.register.instructorName,
    type: 'text',
    validations: {
      maxLength: 50,
      pattern: nameStringPattern,
    },
    gridLayout,
    helperText: appStrings.validationMessages.nameString,
  },
  {
    name: 'schoolName',
    label: appStrings.searchInputs.schoolName,
    type: 'text',
    validations: {
      maxLength: 50,
    },
    gridLayout,
  },
  {
    name: 'phoneNumber',
    label: appStrings.searchInputs.phoneNumber,
    type: 'tel',
    validations: {
      maxLength: 10,
    },
    gridLayout,
    helperText: appStrings.validationMessages.xDigitsOnly(10),
    maskFormat: phoneMask,
  },
  {
    name: 'emailAddress',
    label: appStrings.searchInputs.email,
    type: 'text',
    validations: {
      maxLength: 50,
      pattern: noSpacePattern,
    },
    gridLayout,
    helperText: appStrings.validationMessages.noSpaces,
  },
  {
    noInput: true,
    label: appStrings.searchInputs.registrationDateRange,
    gridLayout: gridLayoutFullLine,
    name: 'registerRange',
  },
  {
    name: 'registrationStartDate',
    label: appStrings.searchInputs.startDate,
    type: 'date',
    format: dateFormat,
    gridLayout: gridLayoutRangedInputs,
    maxDate: validations.maxRegSD ?? xYearsFromNow(0),
    maxDateValidationMessage: appStrings.validationMessages.maxDate,
    minDateValidationMessage: appStrings.validationMessages.minDate,
    onChangeCallback: onDatesChangeCallback,
  },
  {
    noInput: true,
    label: appStrings.common.to,
    gridLayout: gridLayoutConnectingText,
    name: 'toRegister',
    elemStyle: { textAlign: 'center' },
  },
  {
    name: 'registrationEndDate',
    label: appStrings.searchInputs.endDate,
    type: 'date',
    format: dateFormat,
    gridLayout: gridLayoutRangedInputs,
    minDate: validations.minRegED ?? undefined,
    maxDate: xYearsFromNow(0),
    maxDateValidationMessage: appStrings.validationMessages.maxDate,
    minDateValidationMessage: appStrings.validationMessages.minDate,
    onChangeCallback: onDatesChangeCallback,
  },
  {
    noInput: true,
    label: appStrings.searchInputs.graduationDateRange,
    gridLayout: gridLayoutFullLine,
    name: 'graduationRange',
  },
  {
    name: 'graduationStartDate',
    label: appStrings.searchInputs.startDate,
    type: 'date',
    format: dateFormat,
    gridLayout: gridLayoutRangedInputs,
    maxDate: validations.maxGradSD ?? xYearsFromNow(10),
    maxDateValidationMessage: appStrings.validationMessages.maxDate,
    minDateValidationMessage: appStrings.validationMessages.minDate,
    onChangeCallback: onDatesChangeCallback,
  },
  {
    noInput: true,
    label: appStrings.common.to,
    gridLayout: gridLayoutConnectingText,
    name: 'toGraduation',
    elemStyle: { textAlign: 'center' },
  },
  {
    name: 'graduationEndDate',
    label: appStrings.searchInputs.endDate,
    type: 'date',
    format: dateFormat,
    gridLayout: gridLayoutRangedInputs,
    minDate: validations.minGradED ?? undefined,
    maxDate: xYearsFromNow(10),
    maxDateValidationMessage: appStrings.validationMessages.graduationDateMax,
    onChangeCallback: onDatesChangeCallback,
  },
  {
    noInput: true,
    label: appStrings.searchInputs.selectStudentsApprovalStatus,
    gridLayout: gridLayoutFullLine,
    name: 'statusCheckboxes',
  },
  {
    name: 'studentApprovedStatus.approved',
    type: 'checkbox',
    validations: {},
    gridLayout: gridLayoutFullLine,
    labelPlacement: 'end',
    label: appStrings.searchInputs.approved,
    defaultValue: true,
    onChangeCallback: onChangeCallbackCheckboxes,
    disabled: checkboxesDisabled.approved,
  },
  {
    name: 'studentApprovedStatus.rejected',
    type: 'checkbox',
    validations: {},
    gridLayout: gridLayoutFullLine,
    labelPlacement: 'end',
    label: appStrings.searchInputs.rejected,
    defaultValue: true,
    onChangeCallback: onChangeCallbackCheckboxes,
    disabled: checkboxesDisabled.rejected,
  },
  {
    name: 'studentApprovedStatus.pending',
    type: 'checkbox',
    validations: {},
    gridLayout: gridLayoutFullLine,
    labelPlacement: 'end',
    label: appStrings.searchInputs.pending,
    defaultValue: true,
    onChangeCallback: onChangeCallbackCheckboxes,
    disabled: checkboxesDisabled.pending,
  },
];
