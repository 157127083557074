import { Grid, IconButton, Link } from '@mui/material';
import useCategoriesMenuStyles from './categoriesMenuStyles';
import { NavLink, useLocation } from 'react-router-dom';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import appStrings from '../../../../core/strings/appStrings';
import { snaponCatalogLink } from './categoriesMenuConstants';
import { CATEGORY_ID } from '../../../../core/navigation/paths';
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import HoverMenu from './HoverMenu';
import { setPaths } from '../../../../core/redux/slices/categoriesSlice';
import { menuCategoriesList } from './categoriesMenuService';

function CategoriesMenu() {
  const css = useCategoriesMenuStyles().classes;
  const { pathname } = useLocation();
  const hoverMenuRef = useRef(null);
  const isActivePath =
    pathname.indexOf('category/') !== -1 || pathname.indexOf('product/') !== -1;
  const listOfPaths = useSelector(state => state.categories.listOfPaths);
  const [hoverMenu, setHoverMenu] = useState({
    open: false,
    overHoverMenu: false,
    parentId: null,
  });
  const categories = useSelector(
    state => state.categories.categoriesData?.subCategories
  );
  const [subcategories, setSubcategories] = useState([]);
  const [menuCategories, setMenuCategories] = useState(menuCategoriesList);
  const setTopDepartmentData = res => {
    setMenuCategories(
      menuCategories.map(menuItem => {
        if (menuItem.name === menuCategoriesList[0].name) {
          return {
            ...menuItem,
            subCategories: [...res],
          };
        }
        return menuItem;
      })
    );
  };

  useEffect(() => {
    if (categories) {
      setTopDepartmentData(categories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  const handleOpen = event => {
    if (event.currentTarget.id === 'hoverMenu' && subcategories?.length) {
      setHoverMenu(prev => ({
        ...prev,
        overHoverMenu: true,
        parentId: subcategories[0]?.parentId,
      }));
    } else {
      setHoverMenu(prev => ({
        ...prev,
        open: true,
      }));
      setSubcategories(
        menuCategories[
          menuCategories.findIndex(item => item.name === event.currentTarget.id)
        ]?.subCategories
      );
    }
  };

  const handleClose = event => {
    if (event.target.innerText === menuCategoriesList[0].name) {
      event.preventDefault();
    }
    if (event.currentTarget.id === 'hoverMenu' || event.type === 'click') {
      setHoverMenu({
        open: false,
        overHoverMenu: false,
        parentId: null,
      });
    } else {
      setHoverMenu(prev => ({ ...prev, open: false }));
    }
  };

  const handleOnClick = e => {
    if (e.target.innerText === menuCategoriesList[0].name) {
      e.preventDefault();
      hoverMenu.open ? handleClose(e) : handleOpen(e);
    }
  };

  useEffect(() => {
    if (hoverMenu.overHoverMenu && !hoverMenu.open) {
      setHoverMenu(prev => ({
        ...prev,
        open: true,
      }));
    }
  }, [hoverMenu.open, hoverMenu.overHoverMenu]);

  useEffect(() => {
    if (!isActivePath) {
      setPaths(null);
    }
  }, [isActivePath]);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        className={css.mainContainer}
        id="categories-menu"
        data-test-id="categories-menu-testId"
      >
        <Grid item container sm={10} lg={8} xl={6}>
          {menuCategories?.map((elem, index) => (
            <Grid
              item
              container
              className={css.linkContainer}
              sm
              md
              xl
              key={index}
            >
              <NavLink
                ref={hoverMenuRef}
                to={elem.path ?? CATEGORY_ID(elem.id)}
                id={elem.name}
                data-test-id={`${elem.name}-testId`}
                onMouseEnter={handleOpen}
                onMouseLeave={handleClose}
                onClick={handleOnClick}
                className={({ isActive }) =>
                  isActive ||
                  Boolean(
                    listOfPaths?.find(path => path.id === elem.id) &&
                      isActivePath
                  ) ||
                  hoverMenu.parentId === elem.id
                    ? css.isActive
                    : null
                }
              >
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignContent="center"
                  height={'100%'}
                  className={elem.containerClass ?? ''}
                >
                  {elem.icon ? (
                    <IconButton disabled>{elem.icon}</IconButton>
                  ) : null}
                  <Grid item className={elem.nameClass ?? ''}>
                    {elem.name}
                  </Grid>
                </Grid>
              </NavLink>
            </Grid>
          ))}
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignContent="center"
          sm={2}
          md={2}
          xl={2}
        >
          <Grid item>
            <Link
              href={snaponCatalogLink}
              target="_blank"
              id={'SepCatalogButton'}
              data-test-id={'SepCatalog'}
            >
              <CustomButton
                btnType="secondary"
                label={appStrings.menu.sepCatalog}
                customClass={css.catalogButton}
              ></CustomButton>
            </Link>
          </Grid>
        </Grid>
      </Grid>
      {Boolean(subcategories?.length) && (
        <HoverMenu
          hoverMenuRef={hoverMenuRef}
          handleOpen={handleOpen}
          handleClose={handleClose}
          open={hoverMenu.open}
          subcategories={subcategories}
          css={css}
        />
      )}
    </>
  );
}

export default CategoriesMenu;
