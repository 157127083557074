import {
  CATEGORY_ID,
  FORGOT_PASSWORD,
  LOGIN,
  REGISTER,
  PRODUCT,
  PRODUCTSEARCHRESULTS,
  RESEND_LINK,
  RESET_LINK_EXPIRED,
  RESET_PASSWORD,
  SHOPPINGCART,
  PRODUCT_SETS,
  PRODUCT_SET_MEMBERS,
  ACCOUNT,
  CHECKOUT,
  ACCOUNTORDERDETAILS,
  ORDERDETAILS,
  ACCEPTTERMSANDCONDITIONS,
  TERMSANDCONDITIONS,
  CONSIGNED,
} from '../paths';

import ForgotPassword from '../../../modules/shared/ForgotPassword/ForgotPassword';
import Login from '../../../modules/shared/Login/Login';
import ResetPassword from '../../../modules/shared/ResetPassword/ResetPassword';
import RegisterGuest from '../../../modules/shared/Register/RegisterGuest';
import CategoriesContainer from '../../../modules/student/Category/CategoriesContainer/CategoriesContainer';
import ShoppingCart from '../../../modules/student/ShoppingCart/ShoppingCart';
import ProductSearchResults from '../../../modules/student/ProductsSearchResults/ProductSearchResults';
import ProductPage from '../../../modules/student/ProductPage/ProductPage';
import React from 'react';
import ProductSets from '../../../modules/student/ProductSets/ProductSets';
import HashRedirect from '../HashRedirect';

export const guestRoutes = [
  {
    component: <Login />,
    exact: true,
    path: LOGIN,
  },
  {
    component: <ForgotPassword />,
    exact: true,
    path: FORGOT_PASSWORD,
  },
  {
    component: <ForgotPassword />,
    exact: true,
    path: RESEND_LINK,
  },
  {
    component: <ResetPassword />,
    exact: true,
    path: RESET_PASSWORD(),
  },
  {
    component: <ResetPassword />,
    exact: true,
    path: RESET_LINK_EXPIRED,
  },
  {
    component: <RegisterGuest />,
    exact: true,
    path: REGISTER,
  },
  {
    component: <CategoriesContainer />,
    exact: true,
    path: CATEGORY_ID(),
  },
  {
    component: <ProductPage />,
    exact: true,
    path: PRODUCT(),
  },
  {
    component: <ShoppingCart />,
    exact: true,
    path: SHOPPINGCART,
  },
  {
    component: <ProductSearchResults />,
    exact: true,
    path: PRODUCTSEARCHRESULTS,
  },
  {
    component: <ProductSets />,
    exact: true,
    path: PRODUCT_SETS(),
  },
  {
    component: <ProductSets />,
    exact: true,
    path: PRODUCT_SET_MEMBERS(),
  },
  {
    component: <Login />,
    exact: true,
    path: ACCOUNT,
  },
  {
    component: <Login />,
    exact: true,
    path: CHECKOUT,
  },
  {
    component: <Login />,
    exact: true,
    path: ACCOUNTORDERDETAILS(),
  },
  {
    component: <Login />,
    exact: true,
    path: ORDERDETAILS(),
  },
  {
    component: <Login />,
    exact: true,
    path: ACCEPTTERMSANDCONDITIONS,
  },
  {
    component: <Login />,
    exact: true,
    path: TERMSANDCONDITIONS,
  },
  {
    component: <Login />,
    exact: true,
    path: CONSIGNED,
  },
  {
    component: <Login />,
    exact: true,
    path: '/industrial/*',
  },
  {
    component: <HashRedirect />,
    exact: true,
    path: '/webclient/index.html/*',
  },
];

export default guestRoutes;
