import { makeStyles } from 'tss-react/mui';

const useImageZoomButtonStyles = makeStyles()(theme => ({
  zoomButton: {
    color: theme.palette.common.red,
    width: 'unset !important',
    border: '1px solid #A5A5A559',
    borderRadius: '30px',
    boxShadow: '0px 4px 8px 0px #A5A5A552',

    '&:hover': {
      backgroundColor: theme.palette.common.red,
      outlineColor: theme.palette.common.white,
      '& svg': {
        color: theme.palette.common.white
      }
    },
  },
}));

export default useImageZoomButtonStyles;