import {
  addLoadingForAddingItem,
  addLoadingForDeletingItems,
  removeIndexesFromLoadingRows,
  resetFormOnExpandedChange,
  saveInitialValues,
} from '../productsListService';
import { useContext, useEffect, useRef } from 'react';

import NoDataContentProductList from './NoDataContentProductList';
import ProductListContext from '../context/ProductListContext';
import ProductsListPage from './ProductListPage';
import { propTypes } from '../productsListConstants';

function ProductsListIndex({
  activePrompt,
  addingProduct,
  cartId,
  deletingItems,
  setSectionErrors,
  setRowsLoading,
}) {
  const productListCtx = useContext(ProductListContext);
  const {
    addingProductValue,
    displayedProducts,
    expandedProduct,
    getValues,
    initialValues,
    isOrder,
    isSavedItems,
    products,
    reset,
    setValue,
  } = productListCtx;

  const previousAddingProductIndex = useRef(null);
  const previousDeletingItemsIndexes = useRef(null);

  useEffect(() => {
    resetFormOnExpandedChange({
      expandedProduct,
      getValues,
      isOrder,
      reset,
      setValue,
    });
  }, [setValue, reset, getValues, expandedProduct, isOrder]);

  useEffect(() => {
    saveInitialValues(expandedProduct, initialValues, isOrder);
  }, [expandedProduct, initialValues, isOrder]);

  useEffect(() => {
    addingProductValue.current = addingProduct;
  }, [addingProduct, addingProductValue]);

  useEffect(() => {
    if (deletingItems) {
      addLoadingForDeletingItems({
        deletingItems,
        displayedProducts,
        previousDeletingItemsIndexes,
        setRowsLoading,
      });
      return;
    }
    removeIndexesFromLoadingRows(
      setRowsLoading,
      previousDeletingItemsIndexes.current
    );
    previousDeletingItemsIndexes.current = null;
  }, [
    deletingItems,
    displayedProducts,
    previousDeletingItemsIndexes,
    setRowsLoading,
  ]);

  useEffect(() => {
    if (addingProduct) {
      addLoadingForAddingItem({
        addingProduct,
        displayedProducts,
        previousAddingProductIndex,
        setRowsLoading,
      });
      return;
    }
    removeIndexesFromLoadingRows(setRowsLoading, [
      previousAddingProductIndex.current,
    ]);
    previousAddingProductIndex.current = null;
  }, [
    addingProduct,
    displayedProducts,
    previousAddingProductIndex,
    setRowsLoading,
  ]);

  return (
    <>
      {products?.length ? (
        <ProductsListPage
          activePrompt={activePrompt}
          cartId={cartId}
          setSectionErrors={setSectionErrors}
        />
      ) : (
        <NoDataContentProductList
          cartId={cartId}
          isOrder={isOrder}
          isSavedItems={isSavedItems}
        />
      )}
    </>
  );
}

ProductsListIndex.propTypes = propTypes;

export default ProductsListIndex;
