import { makeStyles } from 'tss-react/mui';

const useCustomAccordionStyles = makeStyles()(theme => ({
  accordion: {
    boxShadow: 'none',
    margin: 0,
    background: theme.palette.common.neutral2,
    '&.MuiAccordion-root:before': {
      height: 0,
    },
    '&.Mui-expanded': {
      margin: 0,
    },
    '& .MuiDivider-root': {
      margin: '0px 1px 1px 1px',
      backgroundColor: theme.palette.common.fadeGrey,
    },
    '& .MuiAccordionDetails-root': {
      padding: `${theme.spacing(2)} ${theme.spacing(10)}`,
      display: 'block',
    },
  },
  summary: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.neutral1,
    margin: '1px',
    '& .MuiAccordionSummary-expandIcon': {
      color: theme.palette.common.black,
    },
    '&.MuiAccordionSummary-root.Mui-expanded': { minHeight: '54px' },
    '& .MuiAccordionSummary-content.Mui-expanded': { margin: '15px 0' },
  },
  expanded: {
    background: theme.palette.common.neutral6,
  },
  notExpandable: {
    '&.MuiAccordionSummary-root:hover': {
      cursor: 'default',
    },
  },
  invalid: {
    background: theme.palette.common.neutralRed1,
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    width: '100%',
  },
}));

export default useCustomAccordionStyles;
