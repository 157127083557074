import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import CustomButton from '../CustomButton/CustomButton';
import classNames from 'classnames';
import useCustomDialogStyles from './useCustomDialogStyles';

function CustomDialog({
  open,
  handleClose,
  icon,
  title,
  message,
  buttons,
  customClass,
  showCloseButton,
  id,
  fullScreen,
  buttonContainerClass,
}) {
  const css = useCustomDialogStyles().classes;
  return (
    <Dialog
      id={`${id}-dialog`}
      open={open}
      onClose={handleClose}
      classes={{
        paper: classNames(css.dialogContainer, {
          [customClass]: customClass,
        }),
      }}
      fullScreen={fullScreen}
    >
      {icon ? <div className={css.iconContainer}>{icon}</div> : null}
      {(title || showCloseButton) && (
        <DialogTitle id={`${id}-title`} classes={{ root: css.dialogTitle }}>
          {title}
          {showCloseButton ? (
            <IconButton
              className={css.closeButton}
              onClick={handleClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
      )}

      {message ? (
        <DialogContent
          id={`${id}-content`}
          classes={{ root: css.messageContainer }}
        >
          {message}
        </DialogContent>
      ) : null}

      {buttons ? (
        <DialogActions classes={{ root: classNames(css.buttonContainer, buttonContainerClass ? buttonContainerClass : '') }}>
          {buttons.map(button => (
            <CustomButton
              {...button}
              key={button.label}
              id={`${id}-${button.label.replaceAll(' ', '')}`}
            />
          ))}
        </DialogActions>
      ) : null}
    </Dialog>
  );
}

export default CustomDialog;
