import { emailPattern } from '../../../core/strings/regexValidations';
import urls from '../../../core/strings/urls';
import ApiService from '../../shared/Api/apiService';

export const documentTypes = {
  receipt: {
    label: 'Order Receipt',
    fileType: 'receipt',
  },
  contract: {
    label: 'Credit Sale Contract',
    fileType: 'contract',
  },
  ecApp: {
    label: 'Credit Sales Application',
    fileType: 'ecApplication',
  },
};

export const isInvalidEmail = recipient => {
  return recipient ? !emailPattern.test(recipient) : false;
};

export const sendEmail = (data, isECOrder) => {
  const payload = isECOrder
    ? data
    : {
        ...data,
        recipients: data.recipients.map(email => ({
          emailAddress: email,
          sendAttachment: true,
        })),
      };
  const url = isECOrder ? urls.sendECOrderEmail : urls.sendECApplicationEmails;

  return ApiService.post(url, payload);
};

export const exportECApp = (studentId, orderNumber) => {
  return ApiService.get(urls.exportEcApplicationPDF(studentId, orderNumber));
};
