import React, { useState } from 'react';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import { Divider } from '@mui/material';
import PropTypes from 'prop-types';
import OrderHeader from '../../Account/Orders/OrderHeader';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import appStrings from '../../../../core/strings/appStrings';
import {
  buttonColors,
  buttonTypes,
} from '../../../../core/strings/appConstants';
import CustomDialog from '../../../shared/CustomDialog/CustomDialog';
import TrackingNumbers from './TrackingNumbers';
import { useOrderDetailsStyles } from '../orderDetailsStyles';

function OrderContent({ orderData, content }) {
  const css = useOrderDetailsStyles().classes;
  const [dialogOpen, setDialogOpen] = useState(false);

  const trackBtn = (
    <CustomButton
      id="openDialogTrackingNumbers"
      data-test-id="openDialogTrackingNumbersTestId"
      btnType={buttonTypes.secondary}
      color={buttonColors.black}
      label={appStrings.order.track}
      size="small"
      onClick={() => setDialogOpen(true)}
    />
  );

  const cancelBtn = [
    {
      label: appStrings.common.cancel,
      btnType: buttonTypes.secondary,
      color: buttonColors.black,
      onClick: () => setDialogOpen(false),
    },
  ];

  return (
    <>
      <CustomBox>
        <OrderHeader data={orderData} trackBtn={trackBtn} />
        <Divider />
        {content}
      </CustomBox>
      <CustomDialog
        id="trackingNumbers"
        customClass={css.dialogStyle}
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        title={appStrings.order.orderTrackingNumbers}
        buttons={cancelBtn}
        message={
          <TrackingNumbers
            orderNo={orderData.number}
            lnOrderNumber={orderData.lnOrderNumber}
          />
        }
      />
    </>
  );
}

OrderContent.propTypes = {
  orderData: PropTypes.object,
  content: PropTypes.element,
};

export default OrderContent;
