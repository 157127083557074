import { makeStyles } from 'tss-react/mui';

const useLoginStyles = makeStyles()(theme => ({
  loginContainer: {
    background: `${theme.palette.background.paper} !important`,
    '& .MuiInputLabel-root': {
      color: `${theme.palette.text.primary} !important`,
    },
  },
  desktopContainer: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.common.grey4}`,
    borderRadius: theme.spacing(0.5),
  },
  smallDeviceContainer: {
    padding: theme.spacing(2, 1.5),
  },
  sectionTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '20px',
    paddingBottom: theme.spacing(2),
  },
  forgotPassLink: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.blue1,
    fontSize: '14px',
  },
  logInButton: {
    marginTop: theme.spacing(3),
    width: '100%',
    fontWeight: 'bold',
  },
  errorMessage: {
    color: theme.palette.common.red,
    marginBottom: theme.spacing(2),
  },
  loading: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
  },
  divider: {
    padding: theme.spacing(4, 0),
    color: theme.palette.common.grey2,
    fontSize: theme.spacing(1.5),

    '& span': {
      padding: theme.spacing(0, 3),
    },
  },
  registerTitle: {
    fontWeight: 'bold',
    fontSize: theme.spacing(2),
  },
  registerButton: {
    width: '100%',
    fontWeight: 'bold',
  },
}));

export default useLoginStyles;
