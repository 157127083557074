import appStrings from '../../../core/strings/appStrings';
import { formatNumberWithCurrency } from '../../../core/services/utilsService';
import { Grid, Typography } from '@mui/material';

export const getSubtotal = totalsInfo => {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      direction="row"
    >
      <Typography variant="h3">{`${appStrings.cart.subtotal}:`}</Typography>
      <Typography variant="h3">
        {`${
          totalsInfo.subtotal || totalsInfo.subtotal === 0
            ? formatNumberWithCurrency(totalsInfo.subtotal)
            : '-'
        }`}
      </Typography>
    </Grid>
  );
};

export const getTax = totalsInfo => {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      direction="row"
    >
      <Typography variant="h3">{`${appStrings.cart.tax}:`}</Typography>
      <Typography variant="h3">
        {`${
          (totalsInfo.tax || totalsInfo.tax === 0) &&
          !totalsInfo.taxErrorMessage
            ? formatNumberWithCurrency(totalsInfo.tax)
            : appStrings.cart.unableToCalculateTax
        }`}
      </Typography>
    </Grid>
  );
};

export const getDownPayment = totalsInfo => {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      direction="row"
    >
      <Typography variant="h3">
        {`${appStrings.order.ecContractFields.downPayment}:`}
      </Typography>
      <Typography variant="h3">
        {`${
          (totalsInfo.downPayment || totalsInfo.downPayment === 0) &&
          !totalsInfo.downPaymentErrorMessage
            ? formatNumberWithCurrency(totalsInfo.downPayment)
            : '-'
        }`}
      </Typography>
    </Grid>
  );
};

export const getGrandTotal = totalsInfo => {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      direction="row"
    >
      <Typography variant="h3">
        <strong>{`${appStrings.cart.grandTotal}:`}</strong>
      </Typography>
      <Typography variant="h3" fontWeight="bold">
        {`${
          (totalsInfo.grandTotal || totalsInfo.grandTotal === 0) &&
          !totalsInfo.taxErrorMessage
            ? formatNumberWithCurrency(totalsInfo.grandTotal)
            : '-'
        }`}
      </Typography>
    </Grid>
  );
};
