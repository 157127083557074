export const downloadFileFromBlob = (blob, fileName) => {
  if (!fileName) {
    throw new Error('Missing fileName');
  }

  const url = window.URL.createObjectURL(blob);
  const anchorElem = document.createElement('a');
  anchorElem.style.display = 'none';
  anchorElem.href = url;
  anchorElem.download = fileName;
  anchorElem.target = '_blank';

  document.body.appendChild(anchorElem);

  anchorElem.click();

  window.URL.revokeObjectURL(url);
  document.body.removeChild(anchorElem);
};
