import {
  Checkbox,
  ClickAwayListener,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CustomBox from '../CustomBox/CustomBox';
import CustomSkeleton from '../CustomSkeleton/CustomSkeleton';
import GeneralTableRowSkeleton from '../CustomSkeleton/GeneralTableRowSkeleton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { expandedLayout } from './customTableConstants';
import { formatNumberWithCurrency } from '../../../core/services/utilsService';
import { useCallback, useEffect, useState } from 'react';
import useCustomTableStyles from './useCustomTableStyles';
import { useLocation } from 'react-router-dom';
import { promoPriceTableColumn } from '../../industrial/OrderHistoryDetails/orderHistoryDetailsConstants';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import moment from 'moment';
import { dateFormat } from '../../../core/strings/appConstants';

const propTypes = {
  useCheckbox: PropTypes.bool,
  onSelectRow: PropTypes.func, // checkbox related
  onSelectAll: PropTypes.func, // checkbox related
  selectedCount: PropTypes.number, // the number of selected items (used to determine if Select All will be checked or not)
  rowCount: PropTypes.number, // the total number of records
  useIndex: PropTypes.bool,
  useExpand: PropTypes.bool,
  expanded: PropTypes.number,
  rowData: PropTypes.array, // array of objects containing props from columns like [{column[0].name: val1, column[1].name: val2}]
  currentPage: PropTypes.number, // the current page number starting from 1
  itemsPerPage: PropTypes.number, // number of items per page (used to transmit the real row index when selecting a row)
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string.isRequired,
      bold: PropTypes.bool,
      isCurrency: PropTypes.bool,
      colSpan: PropTypes.number,
      align: PropTypes.string,
      isSortable: PropTypes.bool, //sort related fields
      isSortedBy: PropTypes.bool, //sort related fields
      isSortedAsc: PropTypes.bool, //sort related fields
    })
  ), // array of objects like [{label: 'List Price', name: 'listPrice', bold: true/false, isCurrency: true/false, colSpan: 2, align: 'center'}]
  onExpand: PropTypes.func, // function called when a row is expanded/collapsed
  onRowBlur: PropTypes.func,
  onRowClick: PropTypes.func,
  rowsLoading: PropTypes.arrayOf(PropTypes.number), // array of indexes (numbers) of the rows that are loading (when some data inside has been edited and saved)
  sortHandler: PropTypes.func, // handler in case columns are sortable
  tableId: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
};

function CustomTable({
  useCheckbox,
  useIndex,
  useExpand,
  expanded,
  rowData,
  currentPage,
  itemsPerPage,
  selectedCount,
  rowCount,
  onSelectRow,
  onSelectAll,
  columns,
  onExpand,
  onRowBlur,
  onRowClick,
  rowsLoading,
  customClass,
  sortHandler,
  tableId,
  showDelete,
  onDelete,
}) {
  const css = useCustomTableStyles().classes;
  const { pathname } = useLocation();
  const isSearchResultPage = pathname.indexOf('searchResult') !== -1;
  const indexStart = (currentPage - 1) * itemsPerPage || 0;
  const [allSelected, setAllSelected] = useState(
    rowCount > 0 && selectedCount === rowCount
  );
  const expandColLength =
    columns.length +
    (useCheckbox ? 1 : 0) +
    (useIndex ? 1 : 0) +
    (useExpand ? 1 : 0);

  useEffect(() => {
    setAllSelected(rowCount > 0 && selectedCount === rowCount);
  }, [rowCount, selectedCount]);

  const onExpandChange = useCallback(
    index => {
      if (onExpand) {
        const expandedIndex = expanded === index ? null : index;
        onExpand(index, expandedIndex);
      }
    },
    [onExpand, expanded]
  );

  const handleOnRowClick = useCallback(
    (event, index) => {
      if (
        index !== expanded &&
        // check that row selection checkbox was not clicked
        !(
          event.target.nodeName === 'INPUT' && event.target.type === 'checkbox'
        ) &&
        event.target.parentElement.parentElement.id !== 'rowExpandIcon' &&
        // check that loading skeleton was not clicked
        (typeof event.target.className !== 'string' ||
          (event.target.className.indexOf('loadingCell') === -1 &&
            event.target.parentElement.parentElement.className.indexOf(
              'loadingCell'
            ) === -1 &&
            event.target.className.indexOf('MuiSkeleton') === -1))
      ) {
        onExpandChange(index);
      }
      if (onRowClick) {
        onRowClick(index);
      }
    },
    [onExpandChange, onRowClick, expanded]
  );

  const getCurrencyColumnValue = (column, row) =>
    formatNumberWithCurrency(
      row[promoPriceTableColumn.name] &&
        column.name === promoPriceTableColumn.assignedColumn
        ? row[promoPriceTableColumn.name]
        : row[column.name]
    );

  const getDateColumnValue = (column, row) =>
    moment(row[column.name]).format(dateFormat);

  const getCellValue = useCallback((column, row) => {
    if (
      column.isCurrency &&
      (typeof row[column.name] === 'number' ||
        (typeof row[column.name] === 'string' && row[column.name] !== '-'))
    ) {
      return getCurrencyColumnValue(column, row);
    }
    if (column.isDateType && row[column.name]) {
      return getDateColumnValue(column, row);
    }
    return row[column.name];
  }, []);

  const handleClickAway = (event, index) => {
    if (onRowBlur) {
      onRowBlur(event, index);
    }
  };

  const createRow = useCallback(
    (row, index) => {
      const fullIndex = indexStart + index;
      return (
        <TableBody
          key={`row${index}`}
          data-status={
            isSearchResultPage &&
            (rowData[index]?.active ? 'active' : 'inactive')
          }
        >
          <TableRow
            selected={row.selected}
            hover
            onClick={event => handleOnRowClick(event, index)}
            className={classNames({
              [css.expandedRow]: index === expanded,
              [css.disabledRow]: row.disabled,
            })}
            id={tableId + 'TableRow-' + index}
          >
            {useCheckbox && (
              <TableCell
                padding="checkbox"
                id={tableId + 'CheckboxRow-' + index}
              >
                <Checkbox
                  checked={!!row.selected}
                  onChange={() =>
                    onSelectRow(indexStart + index, Boolean(!row.selected), row)
                  }
                  data-test-id={`tableItemCheckbox${index}`}
                />
              </TableCell>
            )}
            {useIndex && (
              <TableCell id={tableId + 'RowIndex-' + index}>
                {indexStart + index + 1}
              </TableCell>
            )}
            {columns.map(column => (
              <TableCell
                key={`${column.name}${index}`}
                colSpan={column.colSpan}
                align={column.align ? column.align : 'inherit'}
                id={tableId + 'Row-' + column.name + '-' + index}
                data-status={
                  isSearchResultPage &&
                  (rowData[index]?.active ? 'active' : 'inactive')
                }
              >
                {column.bold ? (
                  <strong>{getCellValue(column, row)}</strong>
                ) : (
                  getCellValue(column, row)
                )}
              </TableCell>
            ))}
            {showDelete && (
              <TableCell id={tableId + 'RowDelete-' + index}>
                <IconButton
                  size="small"
                  onClick={e => {
                    e.stopPropagation();
                    onDelete && onDelete(index);
                  }}
                  id={'rowDeleteIcon' + index}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </TableCell>
            )}
            {useExpand && (
              <TableCell id={tableId + 'RowExpand-' + index}>
                <IconButton
                  size="small"
                  onClick={() => onExpandChange(index)}
                  id={'rowExpandIcon' + index}
                >
                  {expanded === index ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </TableCell>
            )}
            {rowsLoading && rowsLoading.indexOf(fullIndex) !== -1 ? (
              <TableCell
                className={css.loadingCell}
                id={tableId + 'RowLoading-' + index}
              >
                <GeneralTableRowSkeleton columnsNumber={6} columnXs={2} />
              </TableCell>
            ) : null}
          </TableRow>
          {expanded === index && row.expandedContent && (
            <TableRow
              id={`${tableId}RowExpandedContent-${index}`}
              className={css.expandedContentContainer}
            >
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={expandColLength}
              >
                <Collapse in={expanded === index} timeout="auto" unmountOnExit>
                  {row.expandedContent}
                </Collapse>
              </TableCell>
              {rowsLoading && rowsLoading.indexOf(fullIndex) !== -1 ? (
                <TableCell
                  className={css.loadingCell}
                  id={tableId + 'RowExpendedContentLoading-' + index}
                >
                  <CustomSkeleton
                    containerClass={css.loadingContainer}
                    layout={expandedLayout}
                    spacing={3}
                  />
                </TableCell>
              ) : null}
            </TableRow>
          )}
        </TableBody>
      );
    },
    [
      columns,
      css.disabledRow,
      css.expandedContentContainer,
      css.expandedRow,
      css.loadingCell,
      css.loadingContainer,
      expandColLength,
      expanded,
      getCellValue,
      handleOnRowClick,
      indexStart,
      isSearchResultPage,
      onExpandChange,
      onSelectRow,
      rowData,
      rowsLoading,
      tableId,
      useCheckbox,
      useExpand,
      useIndex,
      showDelete,
      onDelete,
    ]
  );

  const getSortDirection = column => (column.isSortedAsc ? 'asc' : 'desc');

  return (
    <CustomBox
      fadeBorder
      customClass={classNames(css.tableContainer, customClass)}
    >
      <Table
        className={css.table}
        data-test-id="customTableComponent"
        id={tableId + 'Table'}
      >
        <TableHead className={css.tableHeader} id={tableId + 'TableHeader'}>
          <TableRow>
            {useCheckbox && (
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selectedCount > 0 && selectedCount < rowCount}
                  checked={allSelected}
                  onChange={() => onSelectAll(!allSelected)}
                  data-test-id={'selectAllCheckbox'}
                  id={tableId + 'MasterCheckbox'}
                />
              </TableCell>
            )}
            {useIndex && (
              <TableCell
                className={css.indexColumn}
                id={tableId + 'TableIndexHeader'}
              >
                #
              </TableCell>
            )}
            {columns &&
              columns.map(column => {
                const isSortOn = Boolean(
                  column.isSortable && column.isSortedBy
                );
                const direction = getSortDirection(column);

                const getSortedContent = () => (
                  <TableSortLabel
                    active={isSortOn}
                    colSpan={column.colSpan}
                    align={column.align ? column.align : 'inherit'}
                    direction={isSortOn ? direction : 'asc'}
                    onClick={() => {
                      if (sortHandler) {
                        sortHandler(column.name);
                      }
                    }}
                    IconComponent={ArrowDropDownIcon}
                    classes={{
                      icon: css.tableSortIcon,
                    }}
                  >
                    {column.label}
                  </TableSortLabel>
                );

                return (
                  <TableCell
                    key={column.name}
                    sortDirection={isSortOn ? direction : false}
                    colSpan={column.colSpan}
                    align={column.align ? column.align : 'inherit'}
                    style={{ width: column.width ?? '' }}
                    id={tableId + 'TableHeader-' + column.name}
                  >
                    {column.isSortable ? getSortedContent() : column.label}
                  </TableCell>
                );
              })}
            {showDelete && (
              <TableCell
                className={css.expandColumn}
                id={tableId + 'TableHeaderDelete'}
              >
                {' '}
              </TableCell>
            )}
            {useExpand && (
              <TableCell
                className={css.expandColumn}
                id={tableId + 'TableHeaderUseExpand'}
              >
                {' '}
              </TableCell>
            )}
          </TableRow>
        </TableHead>

        {rowData.map((row, index) => {
          return row.expandedContent ? (
            <ClickAwayListener
              onClickAway={event => {
                handleClickAway(event, index);
              }}
              key={`listener${index}`}
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
            >
              {createRow(row, index)}
            </ClickAwayListener>
          ) : (
            createRow(row, index)
          );
        })}
      </Table>
    </CustomBox>
  );
}

CustomTable.propTypes = propTypes;

export default CustomTable;
