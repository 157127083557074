export const expandedLayout = [
  {
    xs: 6,
    width: '80%',
    height: '100%',
    variant: 'rect',
    container: true,
    justifyContent: 'flex-end',
  },
  {
    xs: 6,
    width: '80%',
    height: '100%',
    variant: 'rect',
  },
  {
    xs: 6,
    width: '0%',
    height: '40%',
    variant: 'rect',
  },
  {
    xs: 3,
    width: '60%',
    height: '40%',
    variant: 'rect',
    container: true,
    justifyContent: 'flex-end',
  },
  {
    xs: 3,
    width: '60%',
    height: '40%',
    variant: 'rect',
  },
];
