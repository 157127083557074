import React from 'react';
import PropTypes from 'prop-types';
import { useCustomInfoBoxStyles } from '../accountStyles';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import { Grid, Skeleton } from '@mui/material';

function CustomInfoBox({ title, content, icon, loading }) {
  const css = useCustomInfoBoxStyles().classes;

  return (
    <CustomBox>
      <Grid container>
        <Grid item container xs={12} className={css.title} columnGap={1}>
          {loading ? (
            <Grid item xs={4} paddingBottom={1}>
              <Skeleton
                data-test-id="customInfoBoxSkeleton-testId"
                height="24px"
                variant="rounded"
              />
            </Grid>
          ) : (
            <>
              <Grid item>{icon}</Grid>
              <Grid item>{title}</Grid>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          {content}
        </Grid>
      </Grid>
    </CustomBox>
  );
}

CustomInfoBox.prototypes = {
  title: PropTypes.string,
  content: PropTypes.element,
  icon: PropTypes.element,
  loading: PropTypes.bool,
};

export default CustomInfoBox;
