/**
 * Sets multiple inputs value
 * @param {function} setValue - function from react hook form
 * @param {object} values - all the inputs to be set
 * shape of the value object:
 *  {
 *      inputName1: value1,
 *      inputName2: value2,
 *  }
 * @param {object} options - additional options, please check the link bellow for available options.
 * https://react-hook-form.com/v6/api#setValue
 */
const setMultipleValues = ({ setValue, values = {}, options = {} }) => {
  Object.keys(values).forEach(field => {
    if (Array.isArray(values[field])) {
      values[field].forEach((group, index) => {
        Object.keys(group).forEach(subField => {
          setValue(`${field}.${index}.${subField}`, group[subField] ?? '', {
            ...options,
          });
        });
      });
    } else if (typeof values[field] === 'object' && values[field] !== null) {
      Object.keys(values[field]).forEach(subField => {
        setValue(`${field}.${subField}`, values[field][subField] ?? '', {
          ...options,
        });
      });
    } else {
      setValue(field, values[field] ?? '', { ...options });
    }
  });
};

export default setMultipleValues;
