import { makeStyles } from 'tss-react/mui';

const useHistoryFiltersStyles = makeStyles()(theme => ({
  customBox: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
  },
  label: {
    paddingRight: theme.spacing(1),
  },
}));

export default useHistoryFiltersStyles;
