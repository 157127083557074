import { makeStyles } from 'tss-react/mui';

const useSchoolListStyles = makeStyles()(theme => ({
  topContainer: {
    marginBottom: theme.spacing(3),
  },
  schoolsContainer: {
    '& .MuiAccordionDetails-root': {
      padding: '0px !important',
    },
    '& .MuiAccordion-root': {
      background: theme.palette.common.neutral1,
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      background: theme.palette.common.neutral5,
    },
    '& .MuiSkeleton-circular': {
      float: 'right',
      width: '1.7rem',
      height: '1.7rem',
    },
  },
  greyText: {
    color: theme.palette.common.darkGrey,
  },
  disabledCounter: {
    color: theme.palette.common.veryLightGrey,
  },
  schoolAddress: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1, 2, 0),
    },
  },
}));

export default useSchoolListStyles;
