import useBrRecommendationsWidgetStyles from './useBrRecommendationsWidgetStyles';
import CustomButton from '../CustomButton/CustomButton';
import { Grid, Skeleton } from '@mui/material';
import React, { memo } from 'react';

function BrRecommendationsWidgetSkeleton() {
  const css = useBrRecommendationsWidgetStyles().classes;

  const getLabel = () => (
    <Grid container spacing={2} alignItems="center" height="100%">
      <Grid item container>
        <Grid item container alignItems="center" xs={8}>
          <Grid item xs>
            <Skeleton variant="text" />
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent="flex-end"
          alignContent="center"
          xs={4}
        >
          <Grid item>
            <Skeleton variant="circular" width={20} height={20} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <Grid item xs>
          <Skeleton variant="rounded" height={130} />
        </Grid>
      </Grid>
      <Grid item container xs>
        <Grid item xs={10}>
          <Skeleton variant="text" />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="text" />
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={7}>
          <Grid item xs={8}>
            <Skeleton variant="text" />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="text" />
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={5}
          justifyContent="flex-end"
          alignContent="center"
        >
          <Grid item>
            <Skeleton variant="circular" width={30} height={30} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid item
      id="brRecommendationWidget-Skeleton"
      data-test-id="brRecommendationWidget-Skeleton"
      className={css.recommendationsWidgetSkeletonContainer}
      direction="row"
    >
      {Array(5)
      .fill(true)
      .map((item, index) => (
        <Grid key={index} item xs={6} md={4} xl={3} className={css.skeletonItemContainer}>
          <CustomButton
            customClass={css.skeletonItemContainer}
            id="recommendationsWidgetProduct-Skeleton"
            label={getLabel()}
            disabled
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default memo(BrRecommendationsWidgetSkeleton);