import { useEffect, useState } from 'react';
import { Checkbox } from '@mui/material';
import CustomAccordion from '../CustomAccordion/CustomAccordion';
import { v4 as uuidv4 } from 'uuid';
import useBrSearchFacetStyles from './useBrSearchFacetStyles';
import appStrings from '../../../core/strings/appStrings';
import CustomButton from '../CustomButton/CustomButton';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import KeyboardDoubleArrowUpOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowUpOutlined';
import { buttonTypes } from '../../../core/strings/appConstants';
function BrSearchFacet({
  filterName,
  filterOptions,
  onSelectedOptions,
  onUnselectedOptions,
  selectedFacets,
  isMobile = false,
}) {

  const css = useBrSearchFacetStyles().classes;

  const [facetsOptions] = useState(filterOptions);
  const [itemsToShow, setItemsToShow] = useState(4);
  const [checkedState, setCheckedState] = useState(
    new Array(filterOptions.length).fill(false),
  );
  const [expendedStatus, setExpandedStatus] = useState(!isMobile);
  const [expandFacetOptions, setExpandFacetOptions] = useState(false);
  const handleCheckboxChange = position => {
    const updatedCheckedState = checkedState.map((item, index) => (index === position ? !item : item));
    setCheckedState(updatedCheckedState);

    const handleCheckedStatus = (checkedStatus, callback) => {
      const returnedItems = [];
      updatedCheckedState.filter((checkbox, index) => {
        if (checkbox === checkedStatus) {
          returnedItems.push(filterOptions[index]);
        }
        return checkbox;
      });
      callback(filterName, returnedItems);
    };

    const handleUncheckedStatus = () => {
      onUnselectedOptions(filterName, [filterOptions[position]]);
    };

    if (updatedCheckedState.some(e => e === true)) {
      handleCheckedStatus(true, onSelectedOptions);
    } else {
      handleUncheckedStatus();
    }
  };

  useEffect(() => {
    if (selectedFacets && selectedFacets.length) {
      selectedFacets.forEach(facet => {
        if (facet.name === filterName) {
          setExpandedStatus(true);
          const updatedCheckedState = [...checkedState];
          facet.properties.forEach(prop => {
            const position = facetsOptions.indexOf(prop);
            updatedCheckedState[position] = true;
          });
          setCheckedState(updatedCheckedState);
        }
      });
    }
  }, [selectedFacets]);

  const showMore = () => (
    itemsToShow === 4 ? (
      () => {
        setItemsToShow(filterOptions.length);
        setExpandFacetOptions(true);
      }
    ) : (
      () => {
        setItemsToShow(4);
        setExpandFacetOptions(false);
      }
    )
  );

  const renderedFacetTitle = (
    <div className={css.facetsTitle} id={`facet-${filterName}`}>{filterName}</div>
  );

  const renderedFacetContent = (
    <div className={css.facetContent} id={`facetContainer-${filterName}`}>
      {((facetsOptions.length > 4) ? facetsOptions.slice(0, itemsToShow) : facetsOptions).map((option, index) => (
        <div id={`element-${index}`} key={uuidv4()}>
          <div className="facets-option-item">
            {option.name ? option.name : ''}
          </div>
          <div>
            <Checkbox
              checked={checkedState[index]}
              onClick={() => handleCheckboxChange(index)}
              className={css.checkbox}
            />
            {
              option
              && <span id={`label-${index}`} className="data-block">{option}</span>
            }
          </div>
        </div>
      ))}
      {(facetsOptions.length > 4) && (
        <div className="expand-button" onClick={showMore()}>
          {expandFacetOptions ? (
            <span id="showLess">
              <CustomButton
                btnType={buttonTypes.tertiary}
                label={appStrings.cart.showLess}
                icon={<KeyboardDoubleArrowUpOutlinedIcon />}
                customClass={css.expandBtn}
              />
            </span>
          ) : (
            <span id="showMore">
              <CustomButton
                btnType={buttonTypes.tertiary}
                label={appStrings.cart.showMore}
                icon={<KeyboardDoubleArrowDownOutlinedIcon />}
                customClass={css.expandBtn}
              />
            </span>
          )}
        </div>
      )}
    </div>
  );

  return (
    <div id="SearchFacetComponent" className={css.facetContainer}>
      <CustomAccordion
        id={filterName}
        expanded={expendedStatus}
        isOpen={expendedStatus}
        title={renderedFacetTitle}
        onExpandChange={setExpandedStatus}
        identifier={filterName}
        customClassDescription={css.customClassDescription}
        customAccordionClassSummary={css.customAccordionClassSummary}
        hasDivider={false}
      >
        {renderedFacetContent}
      </CustomAccordion>
    </div>
  );
}

export default BrSearchFacet;