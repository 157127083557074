import ConsignedInventoryDetails from './ConsignedInventoryDetails/ConsignedInventoryDetails';
import ConsignedInventoryHeader from './ConsignedInventoryHeader/ConsignedInventoryHeader';
import ConsignedInventorySkeleton from './ConsignedInventorySkeleton';
import ConsignedSource from './ConsignedSource/ConsignedSource';
import { Grid } from '@mui/material';
import OrderConsignedInventoryDetails from './OrderConsignedInventoryDetails/OrderConsignedInventoryDetails';
import useConsignedInventoryStyles from './useConsignedInventoryStyles';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ConsignedFilter from './ConsignedFilter/ConsignedFilter';
import { ADMIN } from '../../../core/navigation/roles';

function ConsignedInventory() {
  const css = useConsignedInventoryStyles().classes;
  const isLoading = useSelector(state => state.consignedInventory.isLoading);
  const isAdmin = useSelector(state => state.login.userDetails.role === ADMIN);
  const { pathname } = useLocation();
  const isOrderPage = pathname.indexOf('order') !== -1;

  return (
    <>
      {isLoading && <ConsignedInventorySkeleton />}
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        className={isLoading ? css.hideInventory : ''}
      >
        <Grid item>
          <ConsignedInventoryHeader isOrderPage={isOrderPage} />
        </Grid>
        <Grid item>
          {(!isAdmin || (isAdmin && isOrderPage)) && <ConsignedSource />}
          {isAdmin && !isOrderPage && <ConsignedFilter />}
        </Grid>
        <Grid item></Grid>
        <Grid item>
          {isOrderPage ? (
            <OrderConsignedInventoryDetails />
          ) : (
            <ConsignedInventoryDetails />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default ConsignedInventory;
