import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import NotificationSnackbar from './NotificationSnackbar';
import { notificationRole } from '../../../../core/strings/appConstants';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import useMainContainerStyles from '../../MainContainer/useMainContainerStyles';

function NotificationsViewStudent() {
  const theme = useTheme();
  const css = useMainContainerStyles().classes;
  const isXsDevice = useMediaQuery(theme.breakpoints.only('xs'));
  const studentNotifications = useSelector(
    state => state.notifications.student
  );
  const studentNotificationsClasses = classNames(
    css.notificationsContainerStudent,
    { [css.notificationsContainerXs]: isXsDevice }
  );

  return studentNotifications.length ? (
    <Grid
      item
      container
      direction="column"
      className={css.notificationsContainer}
    >
      <Grid item container className={studentNotificationsClasses}>
        <Grid item xs={11} sm={6} md={4} xl={4}>
          {studentNotifications.map((item, index) => (
            <NotificationSnackbar
              {...item}
              role={notificationRole.student}
              key={`notification${index}`}
              index={index}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  ) : null;
}

NotificationsViewStudent.propTypes = {
  isXsDevice: PropTypes.bool,
};

export default NotificationsViewStudent;
