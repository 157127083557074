import { makeStyles } from 'tss-react/mui';

export const useSkeletonStyles = makeStyles()(theme => ({
  skeletonContainer: {
    '& >div': {
      paddingBottom: theme.spacing(6),
    },
    '&.MuiBox-root': {
      marginBottom: theme.spacing(3),
    },
  },
  accordion: {
    width: '400px',
  },
}));
