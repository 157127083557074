import CustomSkeleton from '../../shared/CustomSkeleton/CustomSkeleton';
import { Grid } from '@mui/material';
import usePurchasePowerStyles from './usePurchasePowerStyles';

function PurchasePowerSkeleton({ standard, positionAbsolute }) {
  const css = usePurchasePowerStyles().classes;
  const layout = [
    { xs: 12, width: '100%' },
    { xs: 6, width: '30%' },
    { xs: 6, width: '30%', container: true, justifyContent: 'flex-end' },
  ];
  if (!standard) {
    const element = {
      xs: 12,
      container: true,
      width: '15%',
      justifyContent: 'flex-end',
    };
    layout.unshift(element);
  }
  return (
    <Grid
      container
      className={css.skeletonMainContainer}
      data-test-id="purchasePowerSkeletonContainer"
      style={{
        position: positionAbsolute ? 'absolute' : 'relative',
      }}
      id="purchasePowerLoading"
    >
      <CustomSkeleton layout={layout} />
    </Grid>
  );
}

export default PurchasePowerSkeleton;
