import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import { buttonTypes } from '../../../../core/strings/appConstants';
import { useAccountStyles } from '../accountStyles';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import classNames from 'classnames';

function SectionLinkMobile({ sections, onClick, formIndex }) {
  const css = useAccountStyles().classes;

  return (
    <CustomBox customClass={css.profileSectionLinkMobile}>
      {sections.map((section, index) => (
        <CustomButton
          customClass={classNames({
            [css.activeSection]: index === formIndex,
          })}
          key={index}
          btnType={buttonTypes.tertiary}
          onClick={() => {
            onClick(index);
          }}
          label={<Typography>{section.name || section.label}</Typography>}
        />
      ))}
    </CustomBox>
  );
}

SectionLinkMobile.prototypes = {
  section: PropTypes.object,
  onClick: PropTypes.func,
  formIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default SectionLinkMobile;
