import { makeStyles } from 'tss-react/mui';

const fitContent = 'fit-content';
const useBrSearchFacetStyles =  makeStyles()(theme => ({
  facetsComponentContainer: {
    width: fitContent,
  },
  productSearchResultsContainer: {
    flexWrap: 'nowrap',
    '@media screen and (max-width:1024px)': {
      flexDirection: 'column',
      height: '100vh',
    },

    '& .facets-button-container': {
      '@media screen and (min-width:1024px)': {
        marginLeft: '30px',
      }
    }
  },
  resultsContainer: {
    height: fitContent,
    '@media screen and (min-width:1024px)': {
      marginLeft: '16px',
    }
  },
  facetContainer: {
    padding: '16px',
    backgroundColor: 'white',
    borderBottom: `1px solid ${theme.palette.common.grey4}`,
    '@media screen and (min-width:1024px)': {
      border: `1px solid ${theme.palette.common.grey4}`,
      borderTop: 'none',
      width: '228px',
    },
    '&.MuiAccordionDetails-root': {
      padding: 0,
    },
    '&:last-of-type': {
      borderBottom: `1px solid ${theme.palette.common.grey4}`,
      borderBottomRightRadius: '8px',
      borderBottomLeftRadius: '8px',
    },
  },
  customClassDescription: {
    padding: '0 !important',
    backgroundColor: 'white',
  },
  customAccordionClassSummary: {
    padding: 0,
    margin: 0,
  },
  facetMobileContainer: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    zIndex: 9,
    top: '5px',
    marginLeft: '-10px',
    '&: .facetContainer': {
      border: 'none',
    }
  },
  applyFiltersBtn: {
    width: '100%',
    marginBottom: '12px',
  },
  mobileHeader: {
    backgroundColor: 'white',
    paddingTop: '8px',
    paddingRight: '12px',
    paddingLeft: '12px',
  },
  checkbox: {
    '&.Mui-checked': {
      color: 'red !important',
    },
  },
  cancelBtnContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  leftArrowIcon: {
    height: fitContent,
    marginTop: 'auto',
    marginBottom: 'auto',
    maxWidth: '20px',
  },
  expandBtn: {
    paddingLeft: '10px !important',
    color: `${theme.palette.common.blue1}!important`,
  },
  deleteAllFiltersBtn: {
    paddingLeft: '0 !important',
  },
  filtersLabel: {
    backgroundColor: 'white',
    padding: '16px',
    margin: 0,
    border: `1px solid ${theme.palette.common.grey4}`,
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  facetsTitle: {
    color: theme.palette.common.grey,
  }
}));

export default useBrSearchFacetStyles;