import { useMediaQuery } from '@mui/material';
import React from 'react';
import CartTotalsDesktopSkeleton from './CartTotalsDesktopSkeleton';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import useShoppingCartStyles from '../shoppingCartStyles';
import CartTotalsMobileSkeleton from './CartTotalsMobileSkeleton';

function CartTotalsSkeleton() {
  const isSmallDevice = useMediaQuery('(max-width:768px)');
  const css = useShoppingCartStyles(isSmallDevice).classes;

  return (
    <CustomBox
      fadeBorder
      customClass={isSmallDevice && css.containerXs}
      id="ShoppingCart-cartTotals"
      data-test-id="cartTotals"
    >
      {isSmallDevice ? (
        <CartTotalsMobileSkeleton />
      ) : (
        <CartTotalsDesktopSkeleton />
      )}
    </CustomBox>
  );
}

export default CartTotalsSkeleton;
