import React, { useEffect, useState } from 'react';
import {
  clearEmptySections,
  getEmployersInformation,
  getSpeedDialOptions,
  handleAddPresentEmployer,
  handleDeleteFutureEmployer,
  handleDeletePresentEmployer,
  handleDeletePreviousEmployer,
  handleEmployersInfo,
  onEmploymentLengthChange,
  onZipCodeChangeHandler,
} from './employerInformationService';
import {
  generateFutureInputs,
  generatePresentInputs,
  generatePreviousInputs,
} from './employerInfoInputs';

import CustomSpeedDial from '../../CustomSpeedDial/CustomSpeedDial';
import FormInput from '../../FormInput/FormInput';
import InputsTemplate from './InputsTemplate';
import NoDataAvailable from '../../../shared/NoDataAvailable/NoDataAvailable';
import StandardFormSkeleton from '../../CustomSkeleton/StandardFormSkeleton';
import appStrings from '../../../../core/strings/appStrings';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import { icons } from '../../../../core/strings/icons';
import setMultipleValues from '../../../../core/services/formService';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import useStudentProfileFormStyles from '../useStudentProfileFormStyles';
import { Grid } from '@mui/material';
import { isEmptyObject } from '../../../industrial/StudentProfile/Services/clearEmptySectionsService';

const getStyle = isLoading =>
  isLoading ? { display: 'none' } : { width: '100%' };
const shouldSetMultiples = ({ formattedValues, isLoading }) =>
  formattedValues !== null && isLoading;

function EmployerInformation({
  control,
  setValue,
  getValues,
  watch,
  errors,
  reset,
  isDirty,
  emptySections,
  isStudent,
}) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [deletedEmployers, setDeletedEmployers] = useState({});
  const [disabledStates, setDisabledStates] = useState({});
  const [employerInputs, setEmployerInputs] = useState({
    presentEmployer: false,
    futureEmployer: false,
    previousEmployers: [],
    prevEmpActualLength: 0,
  });
  const hasNoEmployers =
    !employerInputs.presentEmployer &&
    !employerInputs.futureEmployer &&
    employerInputs.prevEmpActualLength === 0;
  const [formattedValues, setFormattedValues] = useState(null);
  const formStyle = useStudentProfileFormStyles().classes;
  const watchPresentEmployer = watch('presentEmployer');

  const changeActions = {
    onZipCodeChange: ({ section, index }) =>
      onZipCodeChangeHandler({
        section,
        index,
        getValues,
        setValue,
        setDisabledStates,
      }),
    onEmploymentLengthChange: ({ section, index }) =>
      onEmploymentLengthChange({ section, index, getValues, setValue }),
  };

  useEffect(() => {
    getEmployersInformation(id)
      .then(res =>
        handleEmployersInfo({
          res,
          setEmployerInputs,
          setFormattedValues,
        })
      )
      .catch(() => {
        dispatch(displayError());
        setIsLoading(false);
      });
  }, [id, dispatch]);

  useEffect(() => {
    if (shouldSetMultiples({ formattedValues, isLoading })) {
      setMultipleValues({ setValue, values: formattedValues });
      if (!isDirty) {
        reset(getValues());
      }
      // setTimeout is necessary to allow setMultipleValues to set values
      setTimeout(() => {
        setIsLoading(false);
      });
    }
  }, [formattedValues, isLoading, setValue, reset, getValues, isDirty]);

  useEffect(() => {
    setValue('deletedEmployers', JSON.stringify(deletedEmployers));
  }, [deletedEmployers, setValue]);

  useEffect(() => {
    clearEmptySections(emptySections, setEmployerInputs, setValue, getValues);
  }, [emptySections, getValues, setValue]);

  useEffect(() => {
    const presentEmployer = getValues('presentEmployer') || {};
    if (isStudent && !isEmptyObject(presentEmployer) && watchPresentEmployer) {
      handleAddPresentEmployer(employerInputs, setEmployerInputs);
    }
  }, [employerInputs, getValues, isStudent, watchPresentEmployer]);

  const formProps = {
    control: control,
    setValue: setValue,
    watch: watch,
    errors: errors,
    getValues: getValues,
  };

  const speedDialOptions = getSpeedDialOptions({
    employerInputs,
    setEmployerInputs,
  });

  return (
    <>
      {isLoading && <StandardFormSkeleton />}

      <div style={getStyle(isLoading)}>
        {/* No Employers */}
        {hasNoEmployers && (
          <NoDataAvailable
            message={appStrings.studentProfile.noEmployers}
            icon={icons.noEmployers}
          />
        )}

        {employerInputs.presentEmployer && (
          <InputsTemplate
            title={appStrings.studentProfile.presentEmployer}
            inputs={generatePresentInputs({
              changeActions,
              getValues,
              setValue,
              disabledStates,
              isStudent,
            })}
            formProps={formProps}
            handleDelete={() =>
              handleDeletePresentEmployer({
                id: getValues('presentEmployer.id'),
                addressId: getValues('presentEmployer.addressId'),
                setDeletedEmployers,
                setEmployerInputs,
                getValues,
                setValue,
                studentId: id,
                reset,
              })
            }
            deleteLabel={appStrings.studentProfile.presentEmployer}
            id="presentEmployerSection"
            isStudent={isStudent}
          />
        )}

        {employerInputs.futureEmployer && (
          <InputsTemplate
            title={appStrings.studentProfile.futureEmployer}
            inputs={generateFutureInputs({
              changeActions,
              getValues,
              setValue,
              disabledStates,
              isStudent,
            })}
            formProps={formProps}
            handleDelete={() =>
              handleDeleteFutureEmployer({
                id: getValues('futureEmployer.id'),
                addressId: getValues('futureEmployer.addressId'),
                setDeletedEmployers,
                setEmployerInputs,
                getValues,
                setValue,
                studentId: id,
                reset,
              })
            }
            deleteLabel={appStrings.studentProfile.futureEmployer}
            id="futureEmployerSection"
            isStudent={isStudent}
          />
        )}

        <div className={formStyle.lineContainer}>
          {employerInputs.previousEmployers?.length > 0
            ? employerInputs.previousEmployers.map((employer, i) => (
                <div
                  id={`previousEmployersContainer-${i}`}
                  key={`previousEmployers-${i}`}
                >
                  <InputsTemplate
                    title={appStrings.studentProfile.previousEmployerNumber}
                    inputs={generatePreviousInputs({
                      changeActions,
                      index: i,
                      getValues,
                      setValue,
                      disabledStates,
                      isStudent,
                    })}
                    formProps={formProps}
                    handleDelete={() =>
                      handleDeletePreviousEmployer({
                        index: i,
                        id: getValues(`previousEmployers.${i}.id`),
                        addressId: getValues(
                          `previousEmployers.${i}.addressId`
                        ),
                        setDeletedEmployers,
                        setEmployerInputs,
                        setValue,
                        getValues,
                        studentId: id,
                        reset,
                      })
                    }
                    deleteLabel={appStrings.studentProfile.previousEmployer}
                    containerIndex={i}
                    id={`previousEmployerSection-${i}`}
                    isStudent={isStudent}
                  />
                </div>
              ))
            : null}
        </div>
        <FormInput
          {...formProps}
          field={{ name: 'deletedEmployers', type: 'hidden' }}
        />
        <Grid
          container
          justifyContent={isStudent ? 'flex-start' : 'flex-end'}
          className={formStyle.hide}
        >
          <CustomSpeedDial
            options={speedDialOptions}
            show
            id="employment"
            isStudent={isStudent}
            customClass={formStyle.speedDialEmployerInfo}
          />
        </Grid>
      </div>
    </>
  );
}

export default EmployerInformation;
