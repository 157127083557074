import { Grid, Skeleton, Typography } from '@mui/material';
import { STUDENTS, STUDENT_PROFILE } from '../../../core/navigation/paths';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import CustomButton from '../CustomButton/CustomButton';
import WizardContent from './WizardContent/WizardContent';
import appStrings from '../../../core/strings/appStrings';
import { buttonTypes } from '../../../core/strings/appConstants';
import { closeWizard } from '../../../core/redux/slices/wizardSlice';
import logo from '../../../core/assets/img/snapon-logo.png';
import logo15 from '../../../core/assets/img/snapon-logo1.5x.png';
import logo2 from '../../../core/assets/img/snapon-logo2x.png';
import { useEffect } from 'react';
import useWizardStyles from './useWizardStyles';
import PropTypes from 'prop-types';
import Logo from '../../student/Header/Logo/Logo';
import useMediaQuery from '@mui/material/useMediaQuery';
import Notification from '../Notification/Notification';

function ECAppWizard({ isStudent = false }) {
  const css = useWizardStyles().classes;
  const isSmallDevices = useMediaQuery('(max-width:768px)');
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { id, bp } = useParams();

  const student = useSelector(
    state => state.schoolsWithStudents.selectedStudent.student
  );

  const userDetails = useSelector(state => state.login.userDetails);

  const studentName = () =>
    student ? (
      `${student?.firstName || ''} ${student?.lastName || ''}`
    ) : (
      <span id="wizardTitleStudentNameSkeleton">
        <Skeleton
          variant="rounded"
          height={'25px'}
          width={'100px'}
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            marginRight: '15px',
          }}
        />
        <Skeleton
          variant="rounded"
          height={'25px'}
          width={'100px'}
          style={{ display: 'inline-block', verticalAlign: 'middle' }}
        />
      </span>
    );
  useEffect(() => {
    return () => dispatch(closeWizard());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleWizardClose = () => {
    if (isStudent) {
      navigation(-1);
    } else {
      navigation(STUDENT_PROFILE(bp, id));
    }
  };

  return (
    <Grid container className={css.mainContainer}>
      <Grid item xs={12}>
        <Grid item container xs={12} className={css.header}>
          <Grid item>
            {isStudent ? (
              <Logo />
            ) : (
              <img
                className={css.logo}
                srcSet={`${logo},
                        ${logo15} 1.5x,
                        ${logo2} 2x`}
                src={logo}
                alt="Snap-on SEP logo"
                id="wizardLogo"
                onClick={() => navigation(STUDENTS(userDetails?.employeeId))}
              />
            )}
          </Grid>

          {!isStudent && (
            <Grid item id="wizardTitle">
              <Typography variant="h2" fontWeight="bold">
                {`${appStrings.wizard.ECAppFor}`}
                <span id="wizardTitleStudentName">{studentName()}</span>
              </Typography>
            </Grid>
          )}

          {isStudent && !isSmallDevices && (
            <Grid item id="wizardTitle">
              <Typography
                variant="h2"
                fontWeight="bold"
              >{`${appStrings.wizard.ECApp}`}</Typography>
            </Grid>
          )}

          <Grid item>
            <CustomButton
              btnType={buttonTypes.primary}
              label={appStrings.common.close}
              onClick={handleWizardClose}
              id="wizardTitleCloseBtn"
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <WizardContent isStudent={isStudent} />
        </Grid>
      </Grid>

      <Notification />
    </Grid>
  );
}

ECAppWizard.propTypes = {
  isStudent: PropTypes.bool,
};
export default ECAppWizard;
