import { makeStyles } from 'tss-react/mui';

const useCustomBreadcrumbsStyles = makeStyles()(theme => ({
  links: {
    color: theme.palette.common.black,
    fontWeight: 'bold',
    fontSize: '18px',
  },
  currentLink: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
}));

export default useCustomBreadcrumbsStyles;
