import { makeStyles } from 'tss-react/mui';

const none = 'none !important';
const useMenuStyles = makeStyles()(theme => ({
  link: {
    textDecoration: 'none',
  },
  container: {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    height: '100vh',
    overflow: 'auto',
    borderRight: `1px solid ${theme.palette.common.fadeGrey}`,
    backgroundColor: theme.palette.common.neutral3,
    width: '270px',
    flexWrap: 'nowrap !important',
  },
  sectionsContainer: {
    maxWidth: none,
  },
  section: {
    maxWidth: none,
  },
  sectionHeading: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.primary,
    fontSize: theme.typography.h3.fontSize,
    margin: '25px 0 5px',
    alignItems: 'center',
  },
  menuStudentName: {
    wordBreak: 'break-word',
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  inactiveStudentName: {
    color: theme.palette.common.inactive,
  },
  toggleButton: {
    marginRight: theme.spacing(0.5),
    fontSize: theme.typography.h3.fontSize,
    cursor: 'pointer',
  },
  toggleExpanded: {
    position: 'absolute',
    right: '-12px',
  },
  collapsed: {
    width: '85px',
  },
  logoContainer: {
    flexWrap: 'nowrap',
    maxWidth: none,
    paddingTop: '20px',
    position: 'relative',
  },
  minWidth: {
    minWidth: '270px',
  },
}));

export default useMenuStyles;
