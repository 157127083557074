import React, { useCallback, useEffect, useState } from 'react';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import { Divider, Grid } from '@mui/material';
import useCheckoutStyles from '../checkoutStyles';
import {
  addressesTypes,
  checkoutAddressesSections,
  defaultSelectedAddress,
} from '../checkoutConstants';
import EditAddressButtons from './EditAddressButtons';
import SelectAddress from './SelectAddress';
import PropTypes from 'prop-types';
import { formatAddressAsText, getTax } from '../checkoutService';
import { useForm } from 'react-hook-form';
import AddressSkeleton from './AddressSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import {
  setRefreshTax,
  setTotals,
  setTotalsLoading,
} from '../../../../core/redux/slices/cartSlice';
import {
  formatPrice,
  getStudentCart,
} from '../../ShoppingCart/ShoppingCartService';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import { notificationRole } from '../../../../core/strings/appConstants';
import appStrings from '../../../../core/strings/appStrings';

function CheckoutAddresses({
  setSelectedAddresses,
  selectedAddresses,
  studentAddresses,
  loading,
  setLoading,
  setEditingAddresses,
}) {
  const css = useCheckoutStyles().classes;
  const dispatch = useDispatch();
  const { userId } = useSelector(state => state.login.userDetails);
  const cartTotalsWithoutDiscount = useSelector(
    state => state.guestCart.cartTotalsWithoutDiscount
  );
  const refreshTax = useSelector(state => state.cart.refreshTax);
  const [openEdit, setOpenEdit] = useState({
    shippingAddress: false,
    billingAddress: false,
  });
  const [selectedCheckbox, setSelectedCheckbox] = useState({
    ...defaultSelectedAddress,
  });

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    trigger,
    reset,
    formState: { errors, isValid },
  } = useForm();

  const formProps = {
    control,
    setValue,
    errors,
    trigger,
    getValues,
    reset,
  };

  const handleSaveNewAddress = useCallback(
    ({ section }) => {
      handleSubmit(() => {
        return;
      })();
      return {
        ...selectedAddresses,
        [section]: {
          ...getValues()[section],
          checkboxName: addressesTypes.addNewAddress,
        },
      };
    },
    [getValues, handleSubmit, selectedAddresses]
  );

  const handleSaveEditedAddress = useCallback(
    section => {
      let addressData;

      if (selectedCheckbox[section] === addressesTypes.addNewAddress) {
        addressData = handleSaveNewAddress({ section });
      } else {
        addressData = {
          ...selectedAddresses,
          [section]: {
            ...(studentAddresses[selectedCheckbox[section]] ||
              selectedAddresses.shippingAddress),
            checkboxName: selectedCheckbox[section],
          },
        };
      }

      if (section === 'billingAddress') {
        setSelectedAddresses(addressData);
        return;
      }

      if (isValid) {
        setLoading(prev => ({ ...prev, [section]: true }));
        getStudentCart(userId).then(resp => {
          const lineItems =
            resp.cartDetails?.cartItems?.map(item => ({
              productNumber: item.productNumber,
              quantity: item.quantity,
              listPrice: item.listPrice,
              studentPrice: item.studentPrice,
              studentId: userId,
            })) ?? [];
          getTax(userId, addressData.shippingAddress, lineItems)
            .then(resp => {
              dispatch(
                setTotals({
                  subtotal: formatPrice(resp.subtotal),
                  tax: formatPrice(resp.tax),
                  grandTotal: formatPrice(resp.grandTotal),
                  sepSavings: formatPrice(
                    cartTotalsWithoutDiscount - resp.subtotal
                  ),
                  paymentTotal: resp.grandTotal,
                  taxErrorMessage: resp.taxErrorMessage,
                })
              );
              if (resp.taxErrorMessage) {
                dispatch(
                  displayError({
                    role: notificationRole.student,
                    message: resp.taxErrorMessage,
                  })
                );
              }
            })
            .catch(error => {
              dispatch(
                displayError({
                  role: notificationRole.student,
                  message: error,
                })
              );
              dispatch(
                setTotals({
                  taxErrorMessage: error,
                  tax: appStrings.cart.unableToCalculateTaxStudent,
                })
              );
            })
            .finally(() => {
              dispatch(setTotalsLoading(false));
              setLoading(prev => ({ ...prev, [section]: false }));
              setSelectedAddresses(addressData);
            });
        });
      }
    },
    [
      cartTotalsWithoutDiscount,
      dispatch,
      handleSaveNewAddress,
      isValid,
      selectedAddresses,
      selectedCheckbox,
      setLoading,
      setSelectedAddresses,
      studentAddresses,
      userId,
    ]
  );

  const handleCancelEditedAddress = section => {
    setSelectedCheckbox(prev => ({
      ...prev,
      [section]: selectedAddresses[section].checkboxName,
    }));
  };

  useEffect(() => {
    if (
      selectedCheckbox.billingAddress === addressesTypes.sameAsShippingAddress
    ) {
      setSelectedAddresses(prev => ({
        ...prev,
        billingAddress: {
          ...selectedAddresses.shippingAddress,
          checkboxName: addressesTypes.sameAsShippingAddress,
        },
      }));
    }
  }, [
    selectedAddresses.shippingAddress,
    selectedCheckbox.billingAddress,
    setSelectedAddresses,
  ]);

  useEffect(() => {
    setEditingAddresses(openEdit);
  }, [openEdit, setEditingAddresses]);

  useEffect(() => {
    if (refreshTax) {
      handleSaveEditedAddress(checkoutAddressesSections[0].name);
      dispatch(setRefreshTax(false));
    }
  }, [dispatch, handleSaveEditedAddress, refreshTax]);

  return (
    <Grid container spacing={2}>
      {checkoutAddressesSections.map((section, index) => (
        <Grid item xs={12} key={index}>
          <CustomBox fadeBorder>
            <Grid
              item
              container
              xs={12}
              alignContent="center"
              className={css.boxStyle}
              id={`${section.name}_section`}
            >
              {!loading[section.name] ? (
                <>
                  <Grid item container alignContent="center" spacing={1} xs={6}>
                    <Grid item xs={12} className={css.boxTitle}>
                      {section.text}
                    </Grid>
                    {!openEdit[section.name] && (
                      <Grid item className={css.boxSubtitle}>
                        {formatAddressAsText({
                          address: selectedAddresses[section.name],
                        })}
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    item
                    container
                    xs
                    justifyContent="flex-end"
                    alignContent="center"
                  >
                    <EditAddressButtons
                      openEdit={openEdit}
                      setOpenEdit={setOpenEdit}
                      section={section.name}
                      handleSaveEditedAddress={handleSaveEditedAddress}
                      handleCancelEditedAddress={handleCancelEditedAddress}
                      isValid={isValid}
                    />
                  </Grid>
                </>
              ) : (
                <AddressSkeleton />
              )}
            </Grid>
            {openEdit[section.name] && (
              <>
                <Divider />
                <SelectAddress
                  section={section.name}
                  selectedCheckbox={selectedCheckbox}
                  setSelectedCheckbox={setSelectedCheckbox}
                  studentAddresses={studentAddresses}
                  selectedAddresses={selectedAddresses}
                  formProps={formProps}
                />
              </>
            )}
          </CustomBox>
        </Grid>
      ))}
    </Grid>
  );
}

CheckoutAddresses.prototypes = {
  setSelectedAddresses: PropTypes.func,
  selectedAddresses: PropTypes.func,
  studentAddresses: PropTypes.object,
  loading: PropTypes.object,
  setLoading: PropTypes.func,
  setEditingAddresses: PropTypes.func,
};

export default CheckoutAddresses;
