import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import useECContractSigningStyles from '../useECContractSigningStyles';
import appStrings from '../../../../core/strings/appStrings';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import { useParams } from 'react-router-dom';
import {
  displayError,
  displaySuccess,
} from '../../../../core/redux/slices/notificationsSlice';
import {
  exportDisclosure,
  exportEcContract,
  exportOrderReceipt,
} from '../ECContractSigningService';
import { openExportDocument } from '../../../../core/services/utilsService';
import SendEmailDialog from '../../SendEmailDialog/SendEmailDialog';

function OrderSubmitted({ documents = [] }) {
  const css = useECContractSigningStyles().classes;
  const { id, orderId } = useParams();
  const dispatch = useDispatch();
  const [selectedDocument, setSelectedDocument] = useState('');
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
  const student = useSelector(
    state => state.schoolsWithStudents.selectedStudent.student
  );

  const setDocument = evt => {
    setSelectedDocument(evt.target.value);
  };

  useEffect(() => {
    if (documents?.length) {
      setSelectedDocument(documents[0].id);
    }
  }, [documents]);

  const viewDocument = () => {
    const selected = documents[selectedDocument];
    if (selected.fileType === 'receipt') {
      exportOrderReceipt(id, orderId)
        .then(res => openExportDocument(res))
        .catch(e => displayError({ message: e }))
        .finally(() =>
          dispatch(
            displaySuccess({ message: appStrings.export.reportExported })
          )
        );
    } else if (selected.fileType === 'contract') {
      exportEcContract(id, orderId)
        .then(res => openExportDocument(res))
        .catch(e => displayError({ message: e }))
        .finally(() =>
          dispatch(
            displaySuccess({ message: appStrings.export.reportExported })
          )
        );
    } else {
      exportDisclosure(selected.signatureRequestId, selected.packageId)
        .then(res => openExportDocument(res))
        .catch(e => displayError({ message: e }))
        .finally(() =>
          dispatch(
            displaySuccess({ message: appStrings.export.reportExported })
          )
        );
    }
  };

  return (
    <>
      <Grid container>
        <Grid item container xs={12} justifyContent="center">
          <CheckCircleOutlineIcon className={css.checkIcon} />
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          className={css.orderSubmitted}
        >
          <Typography variant="h3" fontWeight="bold">
            {appStrings.ecContractSigning.orderSubmitted(
              `${student?.firstName} ${student?.lastName}`
            )}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          className={css.orderSubmitted}
          justifyContent="center"
          spacing={2}
        >
          <Grid item xs={4}>
            <FormControl fullWidth size="small">
              <InputLabel id="document-label">
                {appStrings.ecContractSigning.document}
              </InputLabel>
              <Select
                labelId="document-label"
                id="document-select"
                value={selectedDocument}
                label={appStrings.ecContractSigning.document}
                onChange={setDocument}
                renderValue={doc => documents[doc]?.label}
              >
                {documents?.map((document, index) => (
                  <MenuItem key={document.id} value={document.id}>
                    {document.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item>
            <CustomButton
              btnType="secondary"
              label={appStrings.ecContractSigning.sendEmail}
              onClick={() => setIsEmailDialogOpen(true)}
            />
          </Grid>
          <Grid item>
            <CustomButton
              btnType="secondary"
              label={appStrings.ecContractSigning.viewDocument}
              onClick={viewDocument}
            />
          </Grid>
        </Grid>
      </Grid>
      {isEmailDialogOpen && (
        <SendEmailDialog
          isECOrder
          orderId={orderId}
          handleClose={() => {
            setIsEmailDialogOpen(false);
          }}
          contractDocuments={documents}
        />
      )}
    </>
  );
}

export default OrderSubmitted;
