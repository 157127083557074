import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import { useDispatch, useSelector } from 'react-redux';
import {
  formatStudentProfileDetails,
  getStudentInfos,
} from './profileServices';
import ProfileSkeleton from './ProfileSkeleton';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import { notificationRole } from '../../../../core/strings/appConstants';
import { useProfileStyles } from './profileStyles';
import { getStudentPurchasePower } from '../accountServices';

function Profile() {
  const css = useProfileStyles().classes;
  const dispatch = useDispatch();
  const { userId } = useSelector(state => state.login.userDetails);
  const [profileDetails, setProfileDetails] = useState({});
  const [purchasePowerLoading, setPurchasePowerLoading] = useState(false);
  const [profileDetailsLoading, setProfileDetailsLoading] = useState(false);

  useEffect(() => {
    if (userId) {
      setPurchasePowerLoading(true);
      getStudentPurchasePower(userId)
        .then(resp =>
          setProfileDetails(prev => ({
            ...prev,
            purchasedValue: resp.purchasedValue,
          }))
        )
        .catch(() => dispatch(displayError({ role: notificationRole.student })))
        .finally(() => setPurchasePowerLoading(false));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (userId) {
      setProfileDetailsLoading(true);
      getStudentInfos(userId)
        .then(resp =>
          setProfileDetails(prev => ({
            ...prev,
            ...resp,
            school: resp.school.schoolName,
          }))
        )
        .catch(() => dispatch(displayError({ role: notificationRole.student })))
        .finally(() => setProfileDetailsLoading(false));
    }
  }, [dispatch, userId]);

  return (
    <CustomBox
      id="account-studentProfile"
      data-test-id="account-studentProfileTestId"
      customClass={css.boxStyle}
    >
      {purchasePowerLoading || profileDetailsLoading ? (
        <ProfileSkeleton />
      ) : (
        <Grid container className={css.mainContainer}>
          {formatStudentProfileDetails(profileDetails).map((info, index) => (
            <Grid
              item
              container
              xs={12}
              sm={6}
              md={4}
              key={index}
              className={css.infoContainerParent}
              id={info.id}
            >
              <Grid item container xs={12} className={css.infoContainer}>
                <Grid item xs={6} sm={12}>
                  <Typography fontWeight="bold" fontSize="medium">
                    {info.label}
                  </Typography>
                </Grid>
                <Grid item className={css.infoValueStyle} xs={6} sm={12}>
                  <Typography>{info.value}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    </CustomBox>
  );
}

export default Profile;
