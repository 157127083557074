import {
  getAuthToken,
  getIdentity,
  logoutUser,
  setDuration,
} from '../Authentication/authenticationService';
import { pkjwk, sKey } from '../../../core/services/security/securityConstants';
import responseTypeConstants, {
  isBlobResponseType,
  isTextResponseType,
} from './responseTypeConstants';

import { SimpleOttp } from '../../../core/services/security/simpleottp';
import { timeTokenRequiredUrls } from '../../../core/strings/urls';

/**
 * Creates the url string
 * @param {string} url
 * @returns {string}
 */
const buildUrl = url => {
  return `${process.env.REACT_APP_WEB_ROOT_API_ROOT}${url}`;
};

/**
 * Builds the headers needed for API requests
 * @returns {object} - the Authorization header
 */
const buildHeaders = async (url, isBlob = false, isFormData = false) => {
  const token = getAuthToken();
  const userDetails = getIdentity();
  const isTimeTokenNeeded = timeTokenRequiredUrls.includes(url);

  const headerOptions = {
    'Response-Type': isBlob
      ? responseTypeConstants.ArrayBuffer
      : responseTypeConstants.JSON,
    Accept: responseTypeConstants.ApplicationJSON,
  };

  if (!isFormData) {
    headerOptions['Content-Type'] = responseTypeConstants.ApplicationJSON;
  }

  if (token) {
    headerOptions.Authorization = `Bearer ${token}`;
    setDuration(userDetails.isIndustrial);
  }

  if (isTimeTokenNeeded) {
    const alg = new SimpleOttp(sKey, pkjwk);
    const timeToken = await alg.generate(); // call the generate method right before each API call

    headerOptions.Authorization = timeToken;
  }

  return headerOptions;
};

const handleErrorsBasedOnStatusCodes = resp => {
  const userDetails = getIdentity();
  const statuses = {
    401: () => {
      if (userDetails?.name?.length) {
        logoutUser();
      }
      return handleErrorsBasedOnContentType(resp);
    },
  };
  return (statuses[resp.status] ?? handleErrorsBasedOnContentType)(resp);
};

const handleErrorsBasedOnContentType = resp => {
  const contentType = resp.headers.get('content-type');
  if (contentType) {
    if (!isTextResponseType(contentType)) {
      return resp.json().then(res => Promise.reject(res));
    } else {
      return resp.text().then(res => Promise.reject(res));
    }
  }
  return resp;
};

/**
 * Handles the errors and builds the error messages
 * @param error - the error received from server
 */
const handleError = response => {
  if (response.status !== 200) {
    return handleErrorsBasedOnStatusCodes(response);
  }
  return null;
};

/**
 * Handles fetch response
 * @param {object} response
 * @returns
 */
const handleResponse = response => {
  if (!response.ok) {
    return handleError(response);
  }
  const contentType = response.headers.get('content-type');
  if (contentType) {
    if (isBlobResponseType(contentType)) {
      return response.blob();
    }
    return response.json();
  } else {
    return response.text();
  }
};

/**
 * Generic implementation for GET method
 * @param url - the url
 * @param isBlob - true if blob, false otherwise
 * @returns {Promise}
 */
const get = async (url, isBlob) => {
  return fetch(buildUrl(url), {
    method: 'GET',
    headers: await buildHeaders(url, isBlob),
  }).then(res => handleResponse(res));
};

const externalGet = async url => {
  return fetch(url, {
    method: 'GET',
  }).then(res => handleResponse(res));
};
/**
 * Parse the payload to create a form data body response
 * @param {object} formElement
 * @returns URLSearchParams object
 */
const convertObjectToFormData = formElement => {
  const data = new FormData();

  for (const [key, value] of Object.entries(formElement)) {
    data.append(key, value);
  }
  return data;
};

/**
 * The generic implementation for POST method
 * @param url - the URL
 * @param payload - the payload sent to server
 * @param isBlob - true if blob, false otherwise
 * @param isFormData - flag to POST a form data instead of json
 * @returns {Promise}
 */
const post = async (url, payload, isBlob, isFormData) => {
  const bodyContent = isFormData
    ? convertObjectToFormData(payload)
    : JSON.stringify(payload);

  return fetch(buildUrl(url), {
    method: 'POST',
    headers: await buildHeaders(url, isBlob, isFormData),
    body: bodyContent,
  }).then(res => handleResponse(res));
};

/**
 * The generic implementation for PUT method
 * @param url - the URL
 * @param payload - the payload sent to server
 * @param isBlob - true if blob, false otherwise
 * @returns {Promise}
 */
const put = async (url, payload, isBlob) => {
  return fetch(buildUrl(url), {
    method: 'PUT',
    headers: await buildHeaders(url, isBlob),
    body: JSON.stringify(payload),
  }).then(res => handleResponse(res));
};

const ApiService = {
  externalGet,
  get,
  post,
  put,
};

export default ApiService;
