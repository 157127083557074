import {
  checkCollapseRow,
  checkProductsForErrors,
  checkSaveOnBlur,
  createExpandedFormFields,
  formatNonLnProducts,
  generateNotifications,
  getProductIndexFromList,
  saveItem,
} from '../productsListService';
import { cloneDeep, findIndex } from 'lodash';
import {
  selectAllFilteredProducts,
  selectAllItems,
  selectCartItem,
  selectFilteredProduct,
  updateFilteredCounter,
} from '../../../../core/redux/slices/cartSlice';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import CreateDetailsView from './CreateDetailsView';
import CreateQtyFormInput from './CreateQtyFormInput';
import CreateStudentPriceFormInput from './CreateStudentPriceFormInput';
import CustomPagination from '../../../shared/CustomPagination/CustomPagination';
import CustomPrompt from '../../../../core/navigation/CustomPrompt';
import CustomTable from '../../../shared/CustomTable/CustomTable';
import { Grid } from '@mui/material';
import ProductListContext from '../context/ProductListContext';
import SelectedItemsCounter from './SelectedItemsCounter';
import { allFieldsValid } from '../context/productListProviderService';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import { getDataToDisplayFromTo } from '../../../shared/CustomPagination/customPaginationService';
import { productsListColumns } from '../productsListConstants';
import { useDispatch, useSelector } from 'react-redux';
import useProductsListStyles from '../useProductsListStyles';
import CartMenu from '../../Cart/components/CartMenu';

function ProductsListPage({ activePrompt, cartId, setSectionErrors }) {
  const css = useProductsListStyles().classes;
  const dispatch = useDispatch();

  const filteredProducts = useSelector(
    state => state.cart.cartInfo.filteredProducts
  );
  const [products, setProducts] = useState([]);
  const [keepPageOnLengthChange, setKeepPageOnLengthChange] = useState(true);

  const productListCtx = useContext(ProductListContext);
  const {
    cartInfo,
    expanded,
    setExpanded,
    displayedProducts,
    products: productsList,
    checkExpand,
    errors,
    paginationDetails,
    isDirty,
    expandedProduct,
    setExpandedProduct,
    setDisplayedProducts,
    setPaginationDetails,
    isOrder,
    bp,
    setValue,
    getValues,
    cartCtx,
  } = productListCtx;

  const editableQuantityRef = useRef();
  const editableStudentPriceRef = useRef();
  const isFilteredProducts = useRef(false);

  const errorCallback = useCallback(() => {
    dispatch(displayError());
  }, [dispatch]);

  const handleOnPageLeave = () => {
    return Object.keys(errors).length;
  };

  const handleSelectItem = (index, value, row) => {
    dispatch(
      filteredProducts
        ? selectFilteredProduct({
            value,
            index: getProductIndexFromList(productsList, row),
          })
        : selectCartItem({ index, value })
    );
  };

  const handleSelectAll = value => {
    dispatch(
      filteredProducts
        ? selectAllFilteredProducts(value)
        : selectAllItems(value)
    );
  };

  const handleExpand = (index, expandedIndex) => {
    checkExpand({
      index,
      expandedIndex,
      editableStudentPriceRef,
      editableQuantityRef,

      // other functions
      triggerSave,
      createDetailsView,
      createQtyFormInput,
      createStudentPriceFormInput,

      prodListCtx: productListCtx,
    });
  };

  const handleDelete = index => {
    cartCtx.deleteItems([displayedProducts[index]]);
  };

  const triggerSave = useCallback(
    async ({ productNumber, isReplaceItem = false, restorePrices }) => {
      const currentFormValues = productListCtx.getValues();
      // cancel save action if Student Price is invalid
      const productIsValid = await allFieldsValid(
        editableStudentPriceRef,
        editableQuantityRef,
        productListCtx
      );
      const restoredProduct = {
        ...productListCtx.expandedProduct,
        listPrice: restorePrices?.listPrice,
        studentPrice: restorePrices?.netPrice,
        initialStudentPrice: restorePrices?.netPrice,
      };

      if (productIsValid) {
        // add the same timeout as in the checkCollapseRow function, to only display loading after the collapse
        setTimeout(() => {
          productListCtx.addLoadingRows();
        }, 300);
        checkCollapseRow({
          editableQuantityRef,
          setExpanded: productListCtx.setExpanded,
          setExpandedProduct: productListCtx.setExpandedProduct,
        });
        saveItem({
          isReplaceItem,
          cartId,
          errorCallback,
          currentValues: currentFormValues,
          prodListCtx: productListCtx,

          replaceItem: productNumber,
          product: restorePrices
            ? restoredProduct
            : productListCtx.expandedProduct,
          studentDetails: cartCtx.student,
        }).finally(() => {
          productListCtx.removeLoadingRows();
        });
        return true;
      }
      return false;
    },
    [productListCtx, cartId, errorCallback, cartCtx.student]
  );

  const createDetailsView = useCallback(
    product => (
      <CreateDetailsView product={product} triggerSave={triggerSave} />
    ),
    [triggerSave]
  );
  const createQtyFormInput = qtyInputInfo => (
    <CreateQtyFormInput
      qtyInputInfo={qtyInputInfo}
      editableQuantityRef={editableQuantityRef}
    />
  );
  const createStudentPriceFormInput = studentPriceInputInfo => (
    <CreateStudentPriceFormInput
      studentPriceInputInfo={studentPriceInputInfo}
      editableStudentPriceRef={editableStudentPriceRef}
    />
  );

  const handleOnBlur = () => {
    checkSaveOnBlur({
      isDirty,
      triggerSave,
      setExpanded,
      setExpandedProduct,
      editableQuantityRef,
    });
  };

  const handlePageChange = ({ from, to, page, itemsPerPage }) => {
    setExpanded(null);
    setExpandedProduct(null);
    const newDisplayedProds = cloneDeep(
      getDataToDisplayFromTo(products, from, to)
    );
    formatNonLnProducts(newDisplayedProds);
    generateNotifications(newDisplayedProds, css);
    setDisplayedProducts(newDisplayedProds);
    setPaginationDetails({ from, to, page, itemsPerPage });
  };
  const formatProducts = useCallback(
    productList => {
      let finalProducts = productList;
      formatNonLnProducts(finalProducts);
      const prodIndex = findIndex(finalProducts, product => {
        return (
          expandedProduct?.productNumber === product.productNumber &&
          JSON.stringify(expandedProduct?.warehouse) ===
            JSON.stringify(product.warehouse)
        );
      });
      // if an expanded product exists add specific form fields
      if (
        expanded !== null &&
        expanded >= 0 &&
        expandedProduct &&
        prodIndex !== -1
      ) {
        finalProducts = createExpandedFormFields({
          productsToEdit: finalProducts,
          index: prodIndex,
          studentDetails: cartCtx.student,
          createDetailsView,
          createQtyFormInput,
          createStudentPriceFormInput,
          isOrder,
          bp,
          setValue,
        });
      }
      generateNotifications(finalProducts, css);
      return finalProducts;
    },
    [
      expanded,
      expandedProduct,
      css,
      cartCtx.student,
      createDetailsView,
      isOrder,
      bp,
      setValue,
    ]
  );

  useEffect(() => {
    let newDisplayedProds = cloneDeep(
      getDataToDisplayFromTo(
        products,
        paginationDetails.from,
        paginationDetails.to
      )
    );
    // get form values before list is reinitialized
    const values = getValues();
    newDisplayedProds = formatProducts(newDisplayedProds);
    setDisplayedProducts(newDisplayedProds);
    checkProductsForErrors({
      products: newDisplayedProds,
      isOrder,
      setSectionErrors,
    });
    // set the form values with the values before list refresh
    Object.keys(values).forEach(key => {
      setValue(key, values[key]);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paginationDetails.from,
    paginationDetails.to,
    products,
    expandedProduct,
    isOrder,
  ]);

  useEffect(() => {
    setProducts(filteredProducts || productsList);
    if (filteredProducts && !isFilteredProducts.current) {
      isFilteredProducts.current = true;
      setKeepPageOnLengthChange(false);
      setPaginationDetails(prev => ({ ...prev, itemsPerPage: 15 }));
    } else if (!filteredProducts && isFilteredProducts.current) {
      isFilteredProducts.current = false;
      setKeepPageOnLengthChange(false);
      setPaginationDetails(prev => ({ ...prev, itemsPerPage: 10 }));
    }

    if (filteredProducts?.length === 0) {
      setKeepPageOnLengthChange(false);
    }
  }, [dispatch, filteredProducts, productsList, setPaginationDetails]);

  useEffect(() => {
    if (!keepPageOnLengthChange) {
      setKeepPageOnLengthChange(true);
    }
  }, [keepPageOnLengthChange]);

  useEffect(() => {
    if (filteredProducts) {
      dispatch(updateFilteredCounter());
    }
  }, [dispatch, filteredProducts]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      data-test-id="productsList"
    >
      <Grid item>
        {activePrompt && <CustomPrompt blockNavigation={handleOnPageLeave()} />}

        <SelectedItemsCounter selectedCounter={cartInfo.selectedCounter} />
        <form
          autoComplete="off"
          className={css.form}
          onSubmit={event => {
            event.preventDefault();
          }}
        >
          <CustomTable
            useCheckbox={cartCtx.student?.active}
            useIndex
            useExpand
            expanded={expanded}
            setExpanded={setExpanded}
            columns={productsListColumns}
            rowData={displayedProducts}
            selectedCount={
              filteredProducts
                ? cartInfo.selectedFilteredCounter
                : cartInfo.selectedCounter
            }
            rowCount={products.length}
            onSelectRow={handleSelectItem}
            onSelectAll={handleSelectAll}
            onExpand={handleExpand}
            currentPage={paginationDetails.page}
            itemsPerPage={paginationDetails.itemsPerPage}
            onRowBlur={handleOnBlur}
            rowsLoading={cartCtx.rowsLoading}
            tableId={'quoteListItems'}
            showDelete
            onDelete={handleDelete}
          />
        </form>
      </Grid>
      <Grid container id="quoteListItemsPagination">
        <Grid item xs={11}>
          <CustomPagination
            itemsLength={products.length}
            itemsPerPage={paginationDetails.itemsPerPage}
            id={'productsList'}
            onPageChangeCallback={handlePageChange}
            keepPageOnLengthChange={keepPageOnLengthChange}
          />
        </Grid>
        <Grid item xs={1} className={css.menuContainer}>
          <CartMenu />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ProductsListPage;
