import urls from '../../../core/strings/urls';
import ApiService from '../../shared/Api/apiService';

export const updateContract = (studentId, orderNumber) => {
  return ApiService.post(urls.updateContract(studentId, orderNumber));
};

export const deleteContract = (studentId, orderNumber) => {
  return ApiService.post(urls.deleteContract(studentId, orderNumber));
};
