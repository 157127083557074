import appStrings from '../../../core/strings/appStrings';

export const totalsLabels = [
  {
    label: appStrings.cart.subtotal,
    name: 'subtotal',
  },
  {
    label: appStrings.cart.tax,
    name: 'tax',
  },
  {
    label: appStrings.cart.shippingAndHandling,
    name: 'shippingHandling',
  },
  {
    label: appStrings.cart.sepSavings,
    name: 'sepSavings',
  },
];
