import { makeStyles } from 'tss-react/mui';

const useCustomSpeedDialStyles = makeStyles()(theme => ({
  floatingButton: {
    position: 'relative',
    left: '65px',
    '& .MuiSpeedDialIcon-root, svg.MuiSpeedDialIcon-icon': {
      width: '34px',
      height: '34px',
    },
    '& .MuiSpeedDial-actions': {
      paddingBottom: 0,
      marginBottom: 0,
    },
    '& button': {
      width: '50px',
      height: '50px',
      margin: 0,
    },
  },
  floatingButtonCart: {
    bottom: '170px',
    left: theme.spacing(1),
    top: 'unset',
  },
  floatingButtonStudent: {
    position: 'absolute',
    zIndex: 7,
    bottom: theme.spacing(11),
    '& .MuiSpeedDial-actions': {
      paddingBottom: 0,
      marginBottom: 0,
    },
    '& button': {
      ':hover': {
        backgroundColor: theme.palette.background.paper,
      },
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.common.black,
      boxShadow: 'none',
      border: '1px solid',
      borderRadius: theme.spacing(0.5),
      width: 'auto',
      height: '10px',
      margin: 0,
      padding: theme.spacing(0, 2),
      textTransform: 'none',
    },
  },
  disabled: {
    '& svg, .MuiFab-label, >span': {
      color: `${theme.palette.common.veryLightGrey} !important`,
    },
    '& button, >span': {
      cursor: 'default',
      boxShadow: 'none !important',
    },
    '& button:hover svg, button:hover .MuiFab-label, >span:hover': {
      color: `${theme.palette.common.veryLightGrey} !important`,
      border: 0,
    },
    '& button:hover': {
      border: `1px solid ${theme.palette.common.lightGrey} !important`,
    },
  },
  backdrop: {
    zIndex: '6',
    background: theme.palette.common.fadeGrey,
  },
  option: {
    marginBottom: '10px',
    '& button, >span': {
      border: `1px solid ${theme.palette.common.lightGrey}`,
      boxShadow: 'none',
    },
    '& >span': {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 'inherit',
    },
    '& .MuiFab-label': {
      color: theme.palette.common.grey,
    },
    '& button:hover': {
      backgroundColor: theme.palette.common.neutral1,
    },
    '&:hover': {
      '& svg, .MuiFab-label, >span': {
        color: theme.palette.common.primary,
      },
    },
    '& .MuiFab-label span': {
      fontSize: `${2 * theme.typography.fontSize}px`,
    },
    '& .MuiSvgIcon-root': {
      width: '30px',
      height: '30px',
    },
  },
  optionStudent: {
    '&:hover': {
      '& svg, .MuiFab-label, >span': {
        color: theme.palette.text.primary,
      },
    },
  },
  red: {
    '&:hover': {
      '& svg, .MuiFab-label, >span': {
        color: theme.palette.common.red,
      },
      '& button': { border: `1px solid ${theme.palette.common.red}` },
    },
  },
  floatingLabel: {
    width: 'max-content',
    cursor: 'pointer',
    color: theme.palette.common.secondary4,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  floatingLabelStudent: {
    color: theme.palette.text.primary,
  },
}));

export default useCustomSpeedDialStyles;
