import { Grid, Skeleton } from '@mui/material';

function ECContractSigningSkeleton() {
  return (
    <Grid
      item
      container
      xs={12}
      justifyContent="center"
      data-test-id="ecContractSigningSkeleton"
      id="ecContractSigningSkeleton"
    >
      <Grid item xs={10} md={8} lg={6}>
        <Skeleton width={'100%'} height={'200px'} />
        <Skeleton width={'100%'} height={'300px'} />
      </Grid>
    </Grid>
  );
}

export default ECContractSigningSkeleton;
