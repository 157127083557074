import {
  APPROVE_REJECT,
  SEARCH,
  STUDENTS,
} from '../../../core/navigation/paths';
import {
  Backdrop,
  Grid,
  InputAdornment,
  Popover,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { createSearchInputValue, formatData } from './studentSearchService';
import {
  resetSearchInputs,
  updateSearchInputs,
} from '../../../core/redux/slices/studentSearchSlice';
import { useDispatch, useSelector } from 'react-redux';

import { ADMIN } from '../../../core/navigation/roles';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import StudentSearchForm from './StudentSearchForm/StudentSearchForm';
import TuneIcon from '@mui/icons-material/Tune';
import appStrings from '../../../core/strings/appStrings';
import { useLocation, useNavigate } from 'react-router';
import useStudentSearchStyles from './useStudentSearchStyles';
import { getHasSearchData } from './searchUtils';

function StudentSearch({ approveReject = false }) {
  const css = useStudentSearchStyles().classes;
  const navigate = useNavigate();
  const location = useLocation();

  const selectedRepValue = useSelector(
    state => state.approveReject.approveRejectRep
  );
  const selectedSchool = useSelector(
    state => state.approveReject.selectedSchool
  );
  const schoolListSelectedRep = useSelector(
    state => state.schoolsWithStudents.schoolListSelectedRep
  );

  const [searchDisplayInput, setSearchDisplayInput] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'search-popover' : undefined;
  const dispatch = useDispatch();
  const { employeeId, role } = useSelector(state => state.login.userDetails);
  const isAdmin = role === ADMIN;
  const searchInputsValue = useSelector(
    state => state.studentSearch.searchInputs
  );

  useEffect(() => {
    setSearchDisplayInput(createSearchInputValue(searchInputsValue));
  }, [dispatch, searchInputsValue]);

  const handleClick = event => {
    if (isAdmin) {
      if (approveReject && !selectedRepValue && !selectedSchool) {
        return;
      }
      
      if(!approveReject && !schoolListSelectedRep) {
        return;
      }
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLocationChange = () => {
    if (location.pathname !== SEARCH(approveReject)) {
      navigate(SEARCH(approveReject));
    }
  };

  const onSubmit = async data => {
    await handleLocationChange();
    const searchData = getHasSearchData(data);
    if (searchData.hasSearchData ||
        searchData.studentApprovedStatus ||
        (isAdmin && schoolListSelectedRep !== appStrings.searchInputs.allReps && data.studentStatus !== 'all') ||
        (!isAdmin && data.studentStatus !== 'all')) {
      setSearchDisplayInput(createSearchInputValue(data));
      dispatch(updateSearchInputs(formatData(data)));
    } else {
      handleClearAll();
    }
    handleClose();
  };

  const handleClearAll = () => {
    setSearchDisplayInput('');
    dispatch(resetSearchInputs());
    navigate(approveReject ? APPROVE_REJECT : STUDENTS(employeeId));
  };

  return (
    <>
      <Grid item id="schoolsSearchContainer" className={css.searchInput}>
        <TextField
          value={searchDisplayInput}
          readOnly
          variant="outlined"
          placeholder={appStrings.searchInputs.searchStudent}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                {!searchDisplayInput.length ? (
                  <TuneIcon className={css.filterIcon} />
                ) : (
                  <ClearIcon
                    onClick={handleClearAll}
                    className={css.clearIcon}
                    data-test-id={'clearAllSearchInput'}
                  />
                )}
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                {!searchDisplayInput.length ? (
                  ''
                ) : (
                  <SearchIcon className={css.searchIcon} />
                )}
              </InputAdornment>
            ),
          }}
          onClick={handleClick}
          aria-controls="customized-menu"
          aria-haspopup="true"
          size="small"
          aria-describedby={id}
          fullWidth
          data-test-id="searchInput"
          id="searchInput"
        />
      </Grid>
      <Backdrop
        open={Boolean(anchorEl)}
        style={{ zIndex: Boolean(anchorEl) && '1' }}
      >
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          classes={{ paper: css.popOverRoot }}
        >
          <StudentSearchForm
            onSubmit={onSubmit}
            approveReject={approveReject}
          />
        </Popover>
      </Backdrop>
    </>
  );
}

export default StudentSearch;
