import appStrings from '../../../core/strings/appStrings';
import ProductOverview from './Components/ProductOverview';
import ProductWarnings from './Components/ProductWarnings';
import Specifications from './Components/Specifications';
import UserManual from './Components/UserManual';

export const productAccordionConstants = [
  {
    title: appStrings.common.overview,
    data: ['description', 'videoUrl'],
    component: (props) => <ProductOverview {...props} />,
  },
  {
    title: appStrings.productPage.specs,
    data: ['specifications', 'specificationImageUrl'],
    component: (props) => <Specifications {...props} />,
  },
  {
    title: appStrings.productPage.userManual,
    data: ['manualUrl'],
    component: (props) => <UserManual {...props} />,
  },
  {
    title: appStrings.productPage.warnings,
    data: ['warningIcons'],
    component: (props) => <ProductWarnings {...props} />,
  }
];

export const productOverviewIndex = 0;
