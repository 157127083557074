import {
  handleRestorePrice,
  removeItemFromNotification,
} from '../productsListService';
import { useCallback, useContext } from 'react';

import CartViewDetails from '../CartViewDetails/CartViewDetails';
import ProductListContext from '../context/ProductListContext';
import { useDispatch } from 'react-redux';

function CreateDetailsView({ product, triggerSave }) {
  const dispatch = useDispatch();

  const productListCtx = useContext(ProductListContext);
  const {
    setExpanded,
    setDisplayedProducts,
    setExpandedProduct,
    setValue,
    getValues,
    expanded,
    formControl,
    cartCtx,
    addLoadingRows,
    isSavedItems,
    removeLoadingRows,
    isDirty,
    reset,
  } = productListCtx;

  const removeItem = useCallback(
    product => {
      addLoadingRows();
      setExpanded(null);
      setExpandedProduct(null);
      removeItemFromNotification({
        product,
        isSavedItems,
        id: cartCtx.student.id,
        dispatch,
        removeLoadingRows,
      });
    },
    [
      addLoadingRows,
      cartCtx.student.id,
      dispatch,
      isSavedItems,
      removeLoadingRows,
      setExpanded,
      setExpandedProduct,
    ]
  );

  const restorePrice = useCallback(
    product => {
      addLoadingRows();
      setExpanded(null);
      setExpandedProduct(null);
      handleRestorePrice({
        isDirty,
        id: cartCtx.student.id,
        product,
        setValue,
        reset,
        getValues,
        triggerSave,
      }).finally(() => {
        removeLoadingRows();
      });
    },
    [
      addLoadingRows,
      cartCtx.student.id,
      getValues,
      isDirty,
      removeLoadingRows,
      reset,
      setExpanded,
      setExpandedProduct,
      setValue,
      triggerSave,
    ]
  );

  return (
    <CartViewDetails
      product={product}
      studentDetails={cartCtx.student}
      control={formControl.current}
      setValue={setValue}
      getValues={getValues}
      expanded={expanded}
      setDisplayedProducts={setDisplayedProducts}
      setExpanded={setExpanded}
      setExpandedProduct={setExpandedProduct}
      handleOnReplace={partNo => {
        triggerSave({ productNumber: partNo, isReplaceItem: true });
      }}
      handleOnRestorePrice={() => {
        restorePrice(product);
      }}
      handleOnRemoveItem={() => {
        removeItem(product);
      }}
    />
  );
}

export default CreateDetailsView;
