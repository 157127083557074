import PropTypes from 'prop-types';

export const propTypes = {
  disableLeaveTimeout: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  isDirty: PropTypes.bool.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.string).isRequired,
  watch: PropTypes.func,
  reset: PropTypes.func,
  resetDirty: PropTypes.func,
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  onStay: PropTypes.func,
  id: PropTypes.string,
};

export const dialogBtnActions = {
  stay: 'stay',
  leave: 'leave',
  leaveNoSave: 'leaveNoSave',
  leaveAndSave: 'leaveAndSave',
};
