import { makeStyles } from 'tss-react/mui';

const useHistoryDetailsStyles = makeStyles()(theme => ({
  htmlTooltip: {
    backgroundColor: theme.palette.common.neutral1,
    color: theme.palette.common.red,
    maxWidth: 220,
    fontSize: theme.typography.caption.fontSize,
    border: `1px solid ${theme.palette.common.lightGrey}`,
    padding: theme.spacing(2),
  },
  statusHighlighted: {
    textDecoration: 'underline',
    '&:hover': { cursor: 'pointer' },
  },
  actionDetails: {
    padding: '5px 0 !important',
  },
  primaryActionText: {
    padding: '5px 0 !important',
    margin: 0,
  },
  customBoxNoDataAvailable: {
    background: theme.palette.common.neutral4,
    margin: theme.spacing(10),
    padding: theme.spacing(10),
    border: `1px solid ${theme.palette.common.lightGrey}`,
    borderRadius: '4px',
  },
  tableStyle: {
    width: '100%',
    '& th': {
      '&:nth-of-type(1)': {
        [theme.breakpoints.down('md')]: {
          padding: '5px 10px',
        },
      },
      padding: '5px 16px',
      [theme.breakpoints.down('md')]: {
        padding: '5px',
      },
    },
    '& td': {
      '&:nth-of-type(1)': {
        [theme.breakpoints.down('md')]: {
          padding: '5px 10px',
        },
      },
      padding: '0px 16px',
      [theme.breakpoints.down('md')]: {
        padding: '5px',
      },
    },
  },
  updateButton: {
    width: theme.spacing(1),
  },
}));

export default useHistoryDetailsStyles;
