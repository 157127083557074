/* eslint-disable no-useless-escape */
export const digitPattern = /^[0-9]*$/;
export const minOneNumberPatter = /.*[0-9].*/;
export const minOneLetterPattern = /.*[a-zA-Z].*/;
export const minOneSpecialCharPattern =
  /.?[()_=+[\]{};:'"<>,.#?!@$\s%^&*-\/].*/;
export const nameStringPattern = /^[a-zA-Z\s‘’\-']+$/;
export const currentInstructorNamePattern = /^[a-zA-Z\s‘’\-.']+$/;
export const noSpacePattern = /^\S*$/;
export const addressPattern = /^[a-zA-Z0-9\s‘’'\-.:,#!\/_@\[\]]+$/;
export const emailPattern =
  /^(?:[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+)*)@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]{1,}))$/;
export const passwordFormat =
  /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[()_=+[\]{};:'"<>,.#?!@$\s%^&*-\/]).{8,}$/;
export const productNumberPattern = /^[a-zA-Z0-9\-*‘’'".\/]+$/;
export const orderNumberPattern = /^SEP-\d+$/;
export const toUpperCaseEachWord = /(^\w{1})|(\s+\w{1})/g;
export const alphaNumeric = /[^a-zA-Z0-9]/g;
