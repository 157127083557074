import { makeStyles } from 'tss-react/mui';

const useHistoryStyles = makeStyles()(theme => ({
  customBox: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(1.5),
  },
  loading: {
    background: theme.palette.common.neutral2,
    position: 'relative',
    height: '80vh',
  },
  hideHistory: {
    display: 'none',
  },
}));

export default useHistoryStyles;
