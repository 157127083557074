import { createSlice } from '@reduxjs/toolkit';

import CONSTANTS from '../constants';

const registerSlice = createSlice({
  name: CONSTANTS.register,
  initialState: {
    isRegistered: false,
    firstName: '',
    lastName: '',
  },
  reducers: {
    setRegisteredStudent: (state, action) => {
      state.isRegistered = true;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
    },
    clearRegisteredStudent: state => {
      state.isRegistered = false;
      state.firstName = '';
      state.lastName = '';
    },
  },
});

export const { setRegisteredStudent, clearRegisteredStudent } =
  registerSlice.actions;
export default registerSlice.reducer;
