import { Grid, Skeleton } from '@mui/material';
import React from 'react';

function StudentPurchasePowerSkeleton() {
  const skeleton = <Skeleton height="24px" variant="rounded" />;

  return (
    <Grid
      container
      paddingTop={5}
      id="StudentPurchaseLimitSkeleton"
      data-test-id="StudentPurchaseLimitSkeleton-testId"
    >
      <Grid item container rowSpacing={2}>
        <Grid item container rowSpacing={2} xs={12} justifyContent="center">
          <Grid item xs={4}>
            {skeleton}
          </Grid>
          <Grid item xs={12}>
            <Skeleton height="12px" variant="rounded" />
          </Grid>
        </Grid>
        <Grid item container justifyContent="space-between">
          <Grid item xs={3}>
            {skeleton}
          </Grid>
          <Grid item xs={3}>
            {skeleton}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default StudentPurchasePowerSkeleton;
