import { useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

function CustomPrompt({
  handleDialog,
  useDialog = false,
  blockNavigation = false,
}) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!blockNavigation) {
      return;
    }

    const push = navigator.push;

    if (useDialog) {
      navigator.push = (...args) => {
        const param = [...args];
        handleDialog(param[0].pathname);
        if (!useDialog.isActive && useDialog.nextLocation) {
          push(...args);
        }
      };
    } else if (blockNavigation) {
      navigator.push = () => {
        return false;
      };
    }

    return () => {
      navigator.push = push;
    };
  }, [navigator, handleDialog, useDialog, blockNavigation]);
}

export default CustomPrompt;
