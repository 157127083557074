import AddProductSkeleton from '../AddProduct/AddProductSkeleton';
import CustomAccordionSkeleton from '../../shared/CustomSkeleton/CustomAccordionSkeleton';
import CustomBox from '../../shared/CustomBox/CustomBox';
import CustomSkeleton from '../../shared/CustomSkeleton/CustomSkeleton';
import { Grid } from '@mui/material';
import PurchasePowerSkeleton from '../PurchasePower/PurchasePowerSkeleton';
import TotalsSkeleton from '../Totals/TotalsSkeleton';
import { useLocation } from 'react-router-dom';
import useOrderTypeStyles from '../OrderType/useOrderTypeStyles';

function CartSkeleton() {
  const css = useOrderTypeStyles().classes;
  const { pathname } = useLocation();
  const isSavedItems = pathname.indexOf('saved') !== -1;
  const isOrder = pathname.indexOf('order') !== -1;
  let topLayout = [
    { xs: 6, width: '30%', variant: 'rect' },
    {
      xs: 6,
      width: '20%',
      variant: 'rect',
      container: true,
      justifyContent: 'flex-end',
    },
  ];
  if (isSavedItems) {
    topLayout.pop();
  }
  const orderTypeLayout = [
    { xs: 12, width: '5%' },
    { xs: 12, width: '10%', variant: 'rect' },
  ];
  const accordionTitle = [
    { xs: 11, width: '10%' },
    {
      xs: 1,
      width: '1.7rem',
      height: '1.7rem',
      variant: 'circular',
      container: true,
      justifyContent: 'flex-end',
    },
  ];
  const titleSkeleton = <CustomSkeleton layout={accordionTitle} />;

  return (
    <Grid item data-test-id="cartSkeleton">
      <CustomSkeleton layout={topLayout} />
      {isSavedItems ? (
        <CustomBox customClass={css.purchasePowerContainer}>
          <PurchasePowerSkeleton />
        </CustomBox>
      ) : (
        <TotalsSkeleton />
      )}
      {isOrder ? (
        <CustomBox customClass={css.purchasePowerContainer}>
          <CustomSkeleton layout={orderTypeLayout} />
        </CustomBox>
      ) : null}
      <AddProductSkeleton />
      {isOrder ? (
        <CustomAccordionSkeleton
          css={css.accordionSkeleton}
          numberOfSections={3}
          titleSkeleton={titleSkeleton}
        />
      ) : null}
    </Grid>
  );
}

export default CartSkeleton;
