import AddProduct from '../../AddProduct/AddProduct';
import CartContext from '../context/CartContext';
import { Grid } from '@mui/material';
import { useContext } from 'react';

function CartAddProductSection() {
  const cartCtx = useContext(CartContext);

  return (
    <>
      {cartCtx.isInactiveStudent || (
        <Grid item xs={12}>
          <AddProduct setAddingProduct={cartCtx.setAddingProduct} />
        </Grid>
      )}
    </>
  );
}

export default CartAddProductSection;
