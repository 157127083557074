import { makeStyles } from 'tss-react/mui';

const useMenuEntryStyles = makeStyles()(theme => ({
  container: {
    textAlign: 'left',
    height: '44px',
    borderRadius: '4px',
    textDecoration: 'none',
    cursor: 'pointer',
    color: theme.palette.common.black,
    verticalAlign: 'middle',
    marginBottom: '5px',
    width: '100%',
    overflow: 'auto',
  },
  selectedContainer: {
    backgroundColor: theme.palette.common.primary,
  },
  selectedName: {
    color: theme.palette.common.white,
  },
  containerCollapsed: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '44px',
    verticalAlign: 'middle',
  },
  name: {
    verticalAlign: 'middle',
    display: 'inline',
    width: 'fit-content',
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    height: 'fit-content',
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingRight: theme.spacing(2),
  },
  icon: {
    verticalAlign: 'middle',
    margin: `auto ${theme.spacing(2)}`,
    fontSize: theme.typography.h3.fontSize,
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  hidden: {
    display: 'none',
  },
  primaryLink: {
    color: theme.palette.common.primary,
  },
}));

export default useMenuEntryStyles;
