import {
  ACCOUNT,
  CATEGORY_ID,
  CHECKOUT,
  PROFILE,
  PRODUCT,
  PRODUCTSEARCHRESULTS,
  SELF_EC_APP_WIZARD,
  SHOPPINGCART,
  ACCEPTTERMSANDCONDITIONS,
  MYSAVEDITEMS,
  EDITPROFILE,
  MYORDERS,
  ACCOUNTORDERDETAILS,
  ORDERDETAILS,
  PRODUCT_SETS,
  PRODUCT_SET_MEMBERS,
  TERMSANDCONDITIONS,
  LOGIN,
  RESET_PASSWORD,
  RESET_LINK_EXPIRED,
  RESEND_LINK,
} from '../paths';
import Account from '../../../modules/student/Account/Account';
import ShoppingCart from '../../../modules/student/ShoppingCart/ShoppingCart';
import ProductSearchResults from '../../../modules/student/ProductsSearchResults/ProductSearchResults';
import ProductPage from '../../../modules/student/ProductPage/ProductPage';
import CategoriesContainer from '../../../modules/student/Category/CategoriesContainer/CategoriesContainer';
import Checkout from '../../../modules/student/Checkout/Checkout';
import ECAppWizard from '../../../modules/shared/ECAppWizard/ECAppWizard';
import AcceptTermsAndConditionsPage from '../../../modules/shared/TermsAndConditions/AcceptTermsAndConditionsPage';
import Profile from '../../../modules/student/Account/StudentProfile/Profile';
import MySavedItems from '../../../modules/student/Account/MySavedItems/MySavedItems';
import Orders from '../../../modules/student/Account/Orders/Orders';
import OrderDetails from '../../../modules/student/OrderDetails/OrderDetails';
import EditProfile from '../../../modules/student/Account/EditProfile/EditProfile';
import ProductSets from '../../../modules/student/ProductSets/ProductSets';
import TermsAndConditions from '../../../modules/shared/TermsAndConditions/TermsAndConditions';
import Login from '../../../modules/shared/Login/Login';
import HashRedirect from '../HashRedirect';
import ResetPassword from '../../../modules/shared/ResetPassword/ResetPassword';
import ForgotPassword from '../../../modules/shared/ForgotPassword/ForgotPassword';

const studentRoutes = isActive => [
  {
    component: <ResetPassword />,
    exact: true,
    path: RESET_PASSWORD(),
  },
  {
    component: <ResetPassword />,
    exact: true,
    path: RESET_LINK_EXPIRED,
  },
  {
    component: <ForgotPassword />,
    exact: true,
    path: RESEND_LINK,
  },
  {
    component: <AcceptTermsAndConditionsPage />,
    exact: true,
    path: ACCEPTTERMSANDCONDITIONS,
  },
  {
    component: <TermsAndConditions />,
    exact: true,
    path: TERMSANDCONDITIONS,
  },
  {
    component: <Account />,
    exact: true,
    path: ACCOUNT,
  },
  {
    component: <ProductPage />,
    exact: true,
    path: PRODUCT(),
  },
  {
    component: <ShoppingCart />,
    exact: true,
    path: SHOPPINGCART,
    disabled: !isActive,
  },
  {
    component: <ProductSearchResults />,
    exact: true,
    path: PRODUCTSEARCHRESULTS,
  },
  {
    component: <CategoriesContainer />,
    exact: true,
    path: CATEGORY_ID(),
  },
  {
    component: <ProductSearchResults />,
    exact: true,
    path: PRODUCTSEARCHRESULTS,
  },

  {
    component: <Checkout />,
    exact: true,
    path: CHECKOUT,
  },
  {
    component: <ECAppWizard isStudent />,
    exact: true,
    path: SELF_EC_APP_WIZARD(),
  },
  {
    component: <Account children={<Profile />} />,
    exact: true,
    path: PROFILE,
  },
  {
    component: <Account page={<MySavedItems />} />,
    exact: true,
    path: MYSAVEDITEMS,
  },
  {
    component: <Account page={<Orders />} />,
    exact: true,
    path: MYORDERS,
  },
  {
    component: <Account page={<OrderDetails />} />,
    exact: true,
    path: ACCOUNTORDERDETAILS(),
  },
  {
    component: <OrderDetails />,
    exact: true,
    path: ORDERDETAILS(),
  },
  {
    component: <Account children={<EditProfile />} />,
    exact: false,
    path: EDITPROFILE(),
  },
  {
    component: <ProductSets />,
    exact: true,
    path: PRODUCT_SETS(),
  },
  {
    component: <ProductSets />,
    exact: true,
    path: PRODUCT_SET_MEMBERS(),
  },
  {
    component: <Login />,
    exact: true,
    path: LOGIN,
  },
  {
    component: <HashRedirect />,
    exact: true,
    path: '/webclient/index.html/*',
  },
];

export default studentRoutes;
