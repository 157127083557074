import { Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import CustomButton from '../../../../shared/CustomButton/CustomButton';
import appStrings from '../../../../../core/strings/appStrings';
import {
  buttonColors,
  buttonTypes,
} from '../../../../../core/strings/appConstants';
import CustomBox from '../../../../shared/CustomBox/CustomBox';
import PropTypes from 'prop-types';

function ApplicationContractButtons({ setFormIndex }) {
  const isSmallDevice = useMediaQuery('(max-width:768px)');

  return (
    <CustomBox>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={isSmallDevice ? 6 : 12} md={12}>
          <CustomButton
            sx={{ width: '100%' }}
            label={appStrings.studentProfile.applicationFields}
            btnType={buttonTypes.secondary}
            color={buttonColors.black}
            onClick={() => setFormIndex('application')}
          />
        </Grid>
        <Grid item xs={6} sm={isSmallDevice ? 6 : 12} md={12}>
          <CustomButton
            sx={{ width: '100%' }}
            label={appStrings.studentProfile.contractFields}
            btnType={buttonTypes.secondary}
            color={buttonColors.black}
            onClick={() => setFormIndex('contract')}
          />
        </Grid>
      </Grid>
    </CustomBox>
  );
}

ApplicationContractButtons.propTypes = {
  setFormIndex: PropTypes.func,
};

export default ApplicationContractButtons;
