import { cartTypes, moveActionOptions, moveFlowOptions } from './cartConstants';
import {
  displayError,
  displaySuccess,
  displayWarning,
} from '../../../core/redux/slices/notificationsSlice';
import {
  displayLoading,
  hideLoading,
} from '../../../core/redux/slices/commonSlice';
import {
  setFilteredProducts,
  setItemsList,
  setTotalsLoading,
} from '../../../core/redux/slices/cartSlice';

import ApiService from '../../shared/Api/apiService';
import { addFromConsignedLoading } from '../../../core/redux/slices/consignedInventorySlice';
import appStrings from '../../../core/strings/appStrings';
import { defaultWarehouse } from '../AddProduct/addProductConstants';
import store from '../../../core/redux/store';
import { trackGAEvent } from '../../shared/Analytics/analyticsService';
import urls from '../../../core/strings/urls';
import { signalRStrings } from '../../../core/strings/signalRStrings';
import { closeSignalRConnection, startSignalRConnection } from '../../../core/services/signalR/signalRService';

export const getCartDetails = ({
  studentId,
  isSavedItems,
  isOrder,
  reloadOrderItems,
}) => {
  if (isOrder) {
    const callsList = reloadOrderItems
      ? [getOrderLineItems(studentId)]
      : [
          getOrderInfo(studentId),
          getOrderLineItems(studentId),
          getOrderPayments(studentId),
        ];

    return Promise.all(callsList).then(result => {
      return reloadOrderItems
        ? { lineItems: result[0] }
        : {
            ...result[0],
            lineItems: result[1],
            payments: result[2],
          };
    });
  }
  let cartType = isSavedItems ? cartTypes.saved : cartTypes.cart;
  return ApiService.get(urls.cartDetails(studentId, cartType));
};

const getOrderInfo = studentId => {
  return ApiService.get(urls.getOrderInfo(studentId));
};

const getOrderLineItems = studentId => {
  return ApiService.get(urls.getOrderLineItems(studentId));
};

const getOrderPayments = studentId => {
  return ApiService.get(urls.getOrderPayments(studentId));
};

export const deleteCartItems = selectedItemsList => {
  if (selectedItemsList.cartId) {
    return ApiService.put(urls.deleteItems, selectedItemsList);
  } else {
    const selectedItems = { ...selectedItemsList };
    selectedItems.recordIds = selectedItems.cartItemIds;
    delete selectedItems.cartId;
    delete selectedItems.cartItemIds;
    return ApiService.post(urls.deleteOrderItems, selectedItems);
  }
};

export const updateTotals = studentId => {
  const cartStudentId = store.getState().cart.cartInfo.studentId || studentId;
  const orderStudentId = store.getState().cart.orderInfo.studentId;
  store.dispatch(setTotalsLoading(true));
  const totalsCall = orderStudentId
    ? ApiService.put(urls.updateOrderSummary, { studentId: orderStudentId })
    : ApiService.put(urls.updateTotals, { studentId: cartStudentId });

  return totalsCall.finally(() => store.dispatch(setTotalsLoading(false)));
};

export const uploadCartData = (data, isOrder) => {
  return ApiService.post(
    isOrder ? urls.uploadOrderProducts : urls.uploadCartProducts,
    data,
    false,
    true
  );
};

export const downloadCartTemplate = () => {
  return ApiService.get(urls.downloadUploadTemplate);
};

export const exportCartByType = (
  studentId,
  isOrder = false,
  isECOrder = false,
  ecContract = {},
  isExcel = true
) => {
  let urlToCall;
  let payload = { studentId, clientDate: new Date() };

  if (isECOrder) {
    payload.contractTabInfo = ecContract.contract;
    urlToCall = isExcel ? urls.exportECOrderAsExcel : urls.exportECOrderAsPdf;
  } else if (isOrder) {
    urlToCall = isExcel ? urls.exportOrderAsExcel : urls.exportOrderAsPdf;
  } else {
    urlToCall = isExcel ? urls.exportCartAsExcel : urls.exportCartAsPdf;
  }
  return ApiService.post(urlToCall, payload);
};

export const getMoveOptions = flow => ({
  isFromOrder:
    flow === moveFlowOptions.orderToCart || flow === moveFlowOptions.orderToSaved,
  isToOrder:
    flow === moveFlowOptions.cartToOrder || flow === moveFlowOptions.savedToOrder,
  isToCart:
    flow === moveFlowOptions.orderToCart || flow === moveFlowOptions.savedToCart,
  isToSaved:
    flow === moveFlowOptions.orderToSaved || flow === moveFlowOptions.cartToSaved,
});

export const moveAction = (studentId, flow, validate) => {
  const { isFromOrder, isToOrder } = getMoveOptions(flow);
  const { selectedItems, selectedItemsDefaultWarehouse, unselectedItems } =
    getSelectedItems(isFromOrder);
  store.dispatch(displayLoading());
  return ApiService.post(isToOrder ? urls.moveItemsToOrder : urls.moveItems, {
    studentId,
    runValidations: Boolean(validate),
    itemsIds: isFromOrder ? selectedItemsDefaultWarehouse : selectedItems,
    moveFrom: moveActionOptions[flow].from,
    moveTo: moveActionOptions[flow].to,
  })
    .then(() => {
      if (
        isFromOrder &&
        selectedItems.length !== selectedItemsDefaultWarehouse.length
      ) {
        store.dispatch(
          displaySuccess({ message: appStrings.order.moveFromOrderSuccess })
        );
        store.dispatch(
          displayWarning({ message: appStrings.order.moveFromOrderWarning })
        );
      } else {
        store.dispatch(
          displaySuccess({ message: moveActionOptions[flow].successMessage })
        );
      }
      store.dispatch(setItemsList(unselectedItems));
    })
    .catch(() => {
      store.dispatch(displayError());
    })
    .finally(() => store.dispatch(hideLoading()));
};

export const moveActionWithSignalr = (studentId, flow, validate) => {
  const { isFromOrder, isToOrder } = getMoveOptions(flow);
  const { selectedItems, selectedItemsDefaultWarehouse } =
    getSelectedItems(isFromOrder);
  store.dispatch(displayLoading());

  const userId = store.getState().login.userDetails.accountIdentifier;
  startSignalRConnection(userId)
    .then(() => {
      return ApiService.post(isToOrder ? urls.moveItemsToOrderSignalr : urls.moveItemsSignalr, {
        studentId,
        userId,
        runValidations: Boolean(validate),
        itemsIds: isFromOrder ? selectedItemsDefaultWarehouse : selectedItems,
        moveFrom: moveActionOptions[flow].from,
        moveTo: moveActionOptions[flow].to,
        callbackMethodName: isToOrder ? signalRStrings.moveOrderItems : signalRStrings.moveCartItems,
      })
        .catch(() => {
          store.dispatch(displayError());
          store.dispatch(hideLoading());
          closeSignalRConnection();
        });
    })
    .catch(() => {
      store.dispatch(displayError());
      store.dispatch(hideLoading());
    });
};

export const getSelectedItems = (checkDefaultWarehouse, items = []) => {
  const selectedItems = [],
    unselectedItems = [],
    selectedItemsDefaultWarehouse = [],
    productsNumber = [];
  const products = store.getState().cart.cartInfo.cartDetails.cartItems;
  products.forEach(item => {
    if ((!items?.length && item.selected) || items.some(itemToDelete => itemToDelete.recordId === item.recordId)) {
      selectedItems.push(item.recordId);
      productsNumber.push(item.productNumber);
      if (checkDefaultWarehouse) {
        if (
          item.warehouse.name.toLowerCase() ===
          defaultWarehouse.value.name.toLowerCase()
        ) {
          selectedItemsDefaultWarehouse.push(item.recordId);
        } else {
          unselectedItems.push(item);
        }
      }
    } else {
      unselectedItems.push(item);
    }
  });
  return {
    selectedItems,
    unselectedItems,
    selectedItemsDefaultWarehouse,
    productsNumber,
  };
};

export const checkPricingUpdates = reloadOrderItems => {
  if (reloadOrderItems) {
    store.dispatch(hideLoading());
    store.dispatch(addFromConsignedLoading(false));
    store.dispatch(
      displayError({
        message: appStrings.validationMessages.lineItemsPricingUpdates,
      })
    );
  }
};

export const shouldGetCartDetails = ({
  id,
  isOrder,
  cartInfo,
  reloadCart,
  orderInfo,
  reloadOrderItems,
  cartResetDone,
  addItemLoading,
  getCartDetailsInProgress,
}) => {
  return (
    (!getCartDetailsInProgress &&
      // If it's not an order and the required conditions are met, get cart details
      id &&
      !isOrder &&
      !addItemLoading &&
      (cartInfo.cartDetails === null ||
        cartInfo.studentId !== id ||
        reloadCart)) ||
    // If it's an order and the required conditions are met, get cart details
    (!getCartDetailsInProgress &&
      isOrder &&
      !addItemLoading &&
      ((!orderInfo?.orderId && !orderInfo?.studentId) ||
        orderInfo.studentId !== id ||
        reloadOrderItems ||
        reloadCart) &&
      cartResetDone.current)
  );
};

export const handleTrackGAEvent = (moveFlow, id) => {
  if (moveFlow === 'savedToCart' || moveFlow === 'cartToOrder') {
    trackGAEvent(
      // action
      `Convert To ${
        moveFlow === 'savedToCart' ? 'Cart' : 'Order'
      } Without Validation`,
      // category
      `${
        moveFlow === 'savedToCart'
          ? 'Saved For Later Cart'
          : 'Order and Checkout'
      }`,
      // label
      id
    );
  }
};

export const validateTotals = totals => {
  if (Object.keys(totals).length > 0) {
    return totals;
  } else {
    return { subtotal: 0, tax: 0, grandTotal: 0 };
  }
};

export const getECOrderPromos = studentId => {
  return ApiService.get(urls.getECOrderPromos(studentId));
};

export const getECOrderAccounts = studentId => {
  return ApiService.get(urls.getECOrderAccounts(studentId));
};

export const saveInfo = data => {
  return ApiService.put(urls.saveInfo, data);
};

export const deleteOrder = (studentId, type) => {
  return ApiService.post(urls.deleteOrder, {
    studentId,
    type,
  });
};

export const setCurrentCartType = ({
  currentCartType,
  isSavedItems,
  isOrder,
}) => {
  currentCartType.current = isOrder
    ? cartTypes.order
    : isSavedItems
    ? cartTypes.saved
    : cartTypes.cart;
};

export const isCorrectCartType = ({ currentCartType, isSavedItems, isOrder }) =>
  (isSavedItems && currentCartType.current === cartTypes.saved) ||
  (!isSavedItems && !isOrder && currentCartType.current === cartTypes.cart) ||
  (isOrder && currentCartType.current === cartTypes.order);

export const filterLineItems = ({ filterValue, products }) => {
  let finalProducts = [];

  finalProducts = products
    .filter(product => {
      const productNumber = product.productNumber.toLowerCase();

      return productNumber.includes(filterValue);
    })
    .sort((a, b) => {
      const aStarts = a.productNumber.toLowerCase().startsWith(filterValue);
      const bStarts = b.productNumber.toLowerCase().startsWith(filterValue);
      if (aStarts && !bStarts) return -1;
      if (!aStarts && bStarts) return 1;
      return a.productNumber.localeCompare(b.productNumber);
    });

  store.dispatch(setFilteredProducts(finalProducts));
};
