import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import classNames from 'classnames';
import useCustomAccordionStyles from './useCustomAccordionStyles';

function CustomAccordion({
  title,
  expanded,
  onExpandChange,
  id,
  hasErrors,
  children,
  disabled,
  customClassDescription,
  customAccordionClassSummary,
  identifier,
  isOpen=null,
  hasDivider = true,
}) {
  const css = useCustomAccordionStyles().classes;

  const handleExpandChange = newId => (event, isExpanded) => {
    if (isOpen !== null) {
      onExpandChange(!isOpen);
    } else if (onExpandChange) {
      onExpandChange(isExpanded ? newId : null);
    }
  };

  const getExpandedState = () => {
    if (isOpen !== null){
      return isOpen;
    } else {
      return expanded === id;
    }
  }

  return (
    <Accordion
      id={identifier !== undefined ? `accordion-${identifier}` : ''}
      expanded={getExpandedState()}
      className={classNames(css.accordion)}
      onChange={handleExpandChange(id)}
      TransitionProps={{ mountOnEnter: true }}
      disabled={disabled}
    >
      <AccordionSummary
        id={identifier !== undefined ? `accordionSummary-${identifier}` : ''}
        expandIcon={onExpandChange ? <ExpandMoreIcon /> : null}
        className={classNames(css.summary, {
          [css.expanded]: expanded === id,
          [css.invalid]: hasErrors,
          [css.notExpandable]: !onExpandChange,
        }, customAccordionClassSummary)}
      >
        <Typography variant="h2" className={css.title}>
          {title}
        </Typography>
      </AccordionSummary>
      {hasDivider && <Divider />}
      <AccordionDetails
        id={identifier !== undefined ? `accordionDetails-${identifier}` : ''}
        className={customClassDescription ?? null}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

export default CustomAccordion;
