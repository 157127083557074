import { circularProgressClasses } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useShoppingCartIconStyles = makeStyles()(theme => ({
  labelStyle: {
    color: theme.palette.common.grey2,
    cursor: 'pointer',
  },

  loadingRing: {
    color: theme.palette.common.grey4,
  },

  loadingRingIndeterminate: {
    color: theme.palette.common.red,
    animationDuration: '800ms',
    position: 'absolute',
    left: 0,
    [`& .${circularProgressClasses.circle}`]: {
      strokeLinecap: 'round',
    },
  },

  navTabStyle: {
    '& .MuiBadge-badge': {
      backgroundColor: theme.palette.common.red,
    },
  },
}));

export default useShoppingCartIconStyles;
