import { makeStyles } from 'tss-react/mui';

const useConsignedInventoryStyles = makeStyles()(theme => ({
  title: {
    textTransform: 'capitalize',
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.spacing(1),
  },
  hideInventory: {
    display: 'none',
  },
  exportBtnContainer: {
    '& button .MuiButton-endIcon>span': {
      fontSize: '11px !important',
    },
  },
}));

export default useConsignedInventoryStyles;
