import {
  APPROVE_REJECT,
  CONSIGNED,
  REGISTER_IND,
  STUDENTS,
} from '../../../core/navigation/paths';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomIcon from '../../shared/CustomIcon/CustomIcon';
import MenuEntry from './MenuEntry/MenuEntry';
import SnaponLogoSmall from '../../../core/assets/img/SnaponLogoSmall.jsx';
import _uniqueId from 'lodash/uniqueId';
import classNames from 'classnames';
import logo from '../../../core/assets/img/SnapOnLogoRep.png';
import menuItemsConstants from './menuConstants';
import { resetSelectedStudent } from '../../../core/redux/slices/schoolsWithStudentsSlice';
import useMediaQuery from '@mui/material/useMediaQuery';
import useMenuStyles from './useMenuStyles';
import { useTheme } from '@mui/material/styles';
import { resetFilters } from '../../../core/redux/slices/historySlice.js';

function Menu() {
  const theme = useTheme();
  const css = useMenuStyles().classes;
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
  const [isPortraitMode, setIsPortraitMode] = useState(
    window.innerWidth < window.innerHeight
  );
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const [isExpanded, setExpandedState] = useState(matches ? false : true);
  const [mainPaths, setMainPaths] = useState([]);
  const employeeId = useSelector(state => state.login.userDetails.employeeId);
  const selectedStudentDetails = useSelector(
    state => state.schoolsWithStudents.selectedStudent
  );
  const studentName =
    selectedStudentDetails?.student?.firstName +
    ' ' +
    selectedStudentDetails?.student?.lastName;
  const activeStudent = selectedStudentDetails?.student?.active;
  const dispatch = useDispatch();
  const location = useLocation();

  const containerClasses = classNames(css.container, {
    [css.collapsed]: !isExpanded,
    [css.minWidth]: isExpanded,
  });

  const toggleExpanded = () => {
    setExpandedState(!isExpanded);
  };

  const updateMenuOnResize = () => {
    setIsPortraitMode(window.innerWidth < window.innerHeight);
    setIsDesktop(window.innerWidth > 1024);
  };

  useEffect(() => {
    setMainPaths([
      STUDENTS(employeeId),
      REGISTER_IND,
      APPROVE_REJECT,
      CONSIGNED,
    ]);
  }, [employeeId]);

  useEffect(() => {
    if (mainPaths.indexOf(location.pathname) !== -1) {
      dispatch(resetSelectedStudent());
    }
  }, [location.pathname, mainPaths, dispatch]);

  useEffect(() => {
    setExpandedState(matches ? false : true);
  }, [matches]);

  useEffect(() => {
    window.addEventListener('resize', updateMenuOnResize);
    return () => window.removeEventListener('resize', updateMenuOnResize);
  }, []);

  useEffect(() => {
    if (!isDesktop && isPortraitMode) {
      setExpandedState(false);
    } else {
      setExpandedState(true);
    }
  }, [isDesktop, isPortraitMode]);

  useEffect(() => {
    if (selectedStudentDetails?.student?.id) {
      dispatch(resetFilters());
    }
  }, [dispatch, selectedStudentDetails?.student?.id]);

  const toggleIcon = isExpanded ? 'left' : 'right';
  const expandedClass = isExpanded ? css.toggleExpanded : '';
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      id="menu_container"
      className={containerClasses}
      data-test-id="menuContainer"
    >
      <Grid
        item
        xs={1}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        id="menu_logo_icon_container"
        className={css.logoContainer}
        data-test-id="menuLogoIconContainer"
      >
        <Grid item>
          {isExpanded ? (
            <img src={logo} alt="Snap-on SEP logo" />
          ) : (
            <SnaponLogoSmall />
          )}
        </Grid>
        <Grid item className={expandedClass}>
          {!isDesktop && isPortraitMode ? null : (
            <CustomIcon
              data-test-id="toggleButton"
              id="toggleButton"
              icon={toggleIcon}
              onClick={toggleExpanded}
              customClasses={css.toggleButton}
            />
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={11}
        container
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        id="sections_container"
        className={css.sectionsContainer}
        data-test-id="sections-container"
      >
        {menuItemsConstants(employeeId, selectedStudentDetails, dispatch).map(
          section => (
            <Grid
              item
              container
              direction="column"
              className={css.section}
              key={_uniqueId('section_')}
            >
              {section && <Divider variant="fullWidth" />}
              {section?.title && isExpanded && (
                <Stack
                  direction="row"
                  spacing={1}
                  id="titleLabel"
                  className={css.sectionHeading}
                >
                  <div>{section.title}</div>
                  {section.studentMenu && selectedStudentDetails.student && (
                    <>
                      <Divider orientation="vertical" flexItem />
                      <Typography
                        className={classNames(
                          css.menuStudentName,
                          activeStudent ? '' : [css.inactiveStudentName]
                        )}
                      >
                        {studentName}
                      </Typography>
                    </>
                  )}
                </Stack>
              )}
              {section?.items
                ? section.items.map(e => (
                    <Grid item key={_uniqueId('menu-entry_')}>
                      <NavLink
                        to={e.redirectTo}
                        className={css.link}
                        onClick={e.onClick}
                      >
                        <MenuEntry
                          icon={e.icon}
                          name={e.name}
                          className={e.className}
                          redirectTo={e.redirectTo}
                          isExpanded={isExpanded}
                        />
                      </NavLink>
                    </Grid>
                  ))
                : null}
            </Grid>
          )
        )}
      </Grid>
    </Grid>
  );
}

export default Menu;
