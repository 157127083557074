import { makeStyles } from 'tss-react/mui';

const useSOCAccountInfoStyles = makeStyles()(theme => ({
  socAccountInfoContainer: {
    marginBottom: theme.spacing(2),
  },
  noInfoIcon: {
    fontSize: '60px',
    color: theme.palette.common.secondary,
  },
}));

export default useSOCAccountInfoStyles;
