import {
  generateFutureInputs,
  generatePresentInputs,
} from '../../../StudentProfileForm/EmployerInformation/employerInfoInputs';
import {
  getEmployersInformation,
  handleEmployersInfo,
  onEmploymentLengthChange,
  onZipCodeChangeHandler,
} from '../../../StudentProfileForm/EmployerInformation/employerInformationService';
import {
  handleErrors,
  handleSaveNotification,
  isSignNowBtnClicked,
} from '../wizardPageContainerService';
import { useCallback, useEffect, useState } from 'react';

import FormInput from '../../../FormInput/FormInput';
import { Grid } from '@mui/material';
import InputsTemplates from '../../../StudentProfileForm/Addresses/InputsTemplate';
import WizardActionButtons from '../../WizardActionButtons/WizardActionButtons';
import WizardFormSkeleton from '../../WizardFormSkeleton/WizardFormSkeleton';
import appStrings from '../../../../../core/strings/appStrings';
import { handleSaveErrors } from '../../../../industrial/StudentProfile/Services/studentProfileService';
import { saveEmployerInfo } from './employerInfoService';
import { setActiveStep } from '../../../../../core/redux/slices/wizardSlice';
import setMultipleValues from '../../../../../core/services/formService';
import {useDispatch, useSelector} from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useWizardStyles from '../../useWizardStyles';
import { wizardStepsCodes } from '../../wizardConstants';
import LeaveDialog from '../../../LeaveDialog/LeaveDialog';
import { dialogBtnActions } from '../../../LeaveDialog/leaveDialogConstants';
import { saveInformation } from '../CustomerInfo/customerInfoService';
import PropTypes from 'prop-types';

function EmployerInfo({ setDirtyInfos, shouldSaveOnLeavePage }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const css = useWizardStyles().classes;

  const [showPresentEmployer, setShowPresentEmployer] = useState(true);
  const [showFutureEmployer, setShowFutureEmployer] = useState(false);

  const [isLoading, setLoading] = useState(true);
  const [disabledStates, setDisabledStates] = useState({});
  const [employerInputs, setEmployerInputs] = useState({
    presentEmployer: false,
    futureEmployer: false,
    previousEmployers: [],
    prevEmpActualLength: 0,
  });
  const [formattedValues, setFormattedValues] = useState(null);
  const [initialFormValues, setInitialFormValues] = useState();
  const validationsPossibleFieldName = useSelector(state => state.wizard.possibleFieldName);

  const shouldSetMultiples = ({ formattedValues, isLoading }) =>
    formattedValues !== null && isLoading;
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    formState,
    reset,
    setError,
  } = useForm();
  const { isDirty, errors } = formState;
  const formProps = {
    control,
    setValue,
    watch,
    errors,
    getValues,
  };

  useEffect(() => {
    if (validationsPossibleFieldName) {
      const { fieldName, howToFix } = validationsPossibleFieldName;
      setError(fieldName, {type: 'manual', message: howToFix});
    }
  }, [validationsPossibleFieldName, setError])

  const isAbleToUncheck = value => {
    if (value === false && showPresentEmployer && showFutureEmployer) {
      return true;
    }
    return !(value === false && (!showPresentEmployer || !showFutureEmployer));
  };

  const changeActions = {
    onZipCodeChange: ({ section, index }) =>
      onZipCodeChangeHandler({
        section,
        index,
        getValues,
        setValue,
        setDisabledStates,
      }),
    onEmploymentLengthChange: ({ section, index }) =>
      onEmploymentLengthChange({ section, index, getValues, setValue }),
  };

  const onErrorCallback = useCallback(e => {
    handleSaveErrors(e, setError);
    setLoading(false);
  }, [setError]);

  const onSuccessCallback = useCallback(() => {
    if (isLoading) {
      setLoading(false);
    }
    setDirtyInfos(false);
    handleSaveNotification(true);
  }, [isLoading, setDirtyInfos]);

  const onSubmit = () => {
    if (!isSignNowBtnClicked()) {
      dispatch(setActiveStep(wizardStepsCodes.validation));
    }
  };

  const onLeave = () => {
    setLoading(true);

    saveEmployerInfo({
      data: getValues(),
      id: id,
      onSuccessCallback,
      reset,
      setValue,
      onErrorCallback,
    });
  };

  useEffect(() => {
    return () => {
      if (shouldSaveOnLeavePage.current) {
        onLeave();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLeaveSubmit = (data, callback) => {
    setLoading(true);
    saveInformation(data, reset, id, callback, onErrorCallback);
  };

  const customReset = () => {
    setMultipleValues({
      setValue,
      values: initialFormValues,
    });
    reset();
  };

  const leaveDialogProps = {
    title: appStrings.modal.ecApp.title,
    description: appStrings.modal.ecApp.description,
    isDirty,
    reset: customReset,
    watch,
    onSubmit: onLeaveSubmit,
    handleSubmit,
    buttons: [
      dialogBtnActions.stay,
      dialogBtnActions.leaveNoSave,
      dialogBtnActions.leaveAndSave,
    ],
    errors,
    dispatch,
    id: 'customerInfoUnsavedChangesDialog',
    disableLeaveTimeout: true,
  };

  useEffect(() => {
    getEmployersInformation(id)
      .then(res => {
        if (res.futureEmployer?.id) {
          setShowFutureEmployer(true);
        }

        handleEmployersInfo({
          res,
          setEmployerInputs,
          setFormattedValues,
          setInitialFormValues
        });
      })
      .catch(e => {
        handleErrors(e);
        setLoading(false);
      });
  }, [id, dispatch]);

  useEffect(() => {
    if (shouldSetMultiples({ formattedValues, isLoading })) {
      setMultipleValues({ setValue, values: formattedValues });
      if (!isDirty) {
        reset(getValues());
      }
      // setTimeout is necessary to allow setMultipleValues to set values
      setTimeout(() => {
        setLoading(false);
      });
    }
  }, [formattedValues, isLoading, setValue, reset, getValues, isDirty]);

  useEffect(() => {
    setDirtyInfos(isDirty);
    shouldSaveOnLeavePage.current = isDirty;
  }, [isDirty, setDirtyInfos, shouldSaveOnLeavePage]);

  return (
    <Grid>
      <form
        onSubmit={handleSubmit(onSubmit)}
        name="studentProfileWizard"
        noValidate
        autoComplete="off"
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          display={isLoading ? 'none' : ''}
        >
          <Grid item>
            <Grid
              alignItems="flex-start"
              justifyContent="flex-start"
              container
              direction="row"
            >
              <Grid
                item
                xs={12}
                className={
                  !showPresentEmployer ? css.spacingAroundCheckboxes : null
                }
              >
                <FormInput
                  {...formProps}
                  field={{
                    name: 'showPresentEmployer',
                    type: 'checkbox',
                    label: appStrings.studentProfile.presentEmployer,
                    value: showPresentEmployer,
                    disabled: showPresentEmployer && !showFutureEmployer,
                    onChangeCallback: v =>
                      isAbleToUncheck(v) && setShowPresentEmployer(v),
                  }}
                />
              </Grid>

              {showPresentEmployer ? (
                <Grid item>
                  <InputsTemplates
                    formProps={formProps}
                    inputs={generatePresentInputs({
                      changeActions,
                      getValues,
                      setValue,
                      disabledStates,
                      otherGridLayout: { xs: 12, sm: 6 },
                    })}
                    title={appStrings.studentProfile.presentEmployer}
                    titleInsideTheBox
                    id="presentEmployerSectionWizard"
                    customContainerCss={css.formContainer}
                  />
                </Grid>
              ) : null}

              <Grid item xs={12} className={css.checkboxFieldContainer}>
                <FormInput
                  {...formProps}
                  field={{
                    name: 'showFutureEmployer',
                    type: 'checkbox',
                    label: appStrings.studentProfile.futureEmployer,
                    value: showFutureEmployer,
                    disabled: !showPresentEmployer && showFutureEmployer,

                    onChangeCallback: v =>
                      isAbleToUncheck(v) && setShowFutureEmployer(v),
                  }}
                />
              </Grid>

              {showFutureEmployer ? (
                <Grid item>
                  <InputsTemplates
                    formProps={formProps}
                    inputs={generateFutureInputs({
                      changeActions,
                      getValues,
                      setValue,
                      disabledStates,
                      otherGridLayout: { xs: 12, sm: 6 },
                    })}
                    title={appStrings.studentProfile.futureEmployer}
                    titleInsideTheBox
                    id="futureEmployerSectionWizard"
                    customContainerCss={css.formContainer}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Grid item>
              <WizardActionButtons handleSubmit={handleSubmit} />
            </Grid>
          </Grid>
        </Grid>
        <LeaveDialog {...leaveDialogProps} />
      </form>
      {isLoading && <WizardFormSkeleton />}
    </Grid>
  );
}

EmployerInfo.propTypes = {
  setDirtyInfos: PropTypes.func,
  shouldSaveOnLeavePage: PropTypes.object,
};

export default EmployerInfo;
