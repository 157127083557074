/*
 * Copyright 2021 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { videoSingleImageBannerXStyles } from './useVideoSingleImageBannerXStyles';
export default function VideoSingleImageBannerX(VideoSingleImageBannerProps) {
  const { component, page } = VideoSingleImageBannerProps;
  const css = videoSingleImageBannerXStyles().classes;

  // Get component parameters
  const {
    videourl,
    textlocation,
    textalign,
    textcolor,
  } = component.getParameters();

  // Get document data
  const contentRef = component.model.content;
  const documentObject = contentRef && page.getContent(contentRef);
  const {
    content,
    cta,
    ctaStyle,
    externalLink,
    image,
    link,
    subtitle,
    title,
  } = documentObject && documentObject.data;
  const imageObject = image && page.getContent(image).getOriginal();

  let linkStyle = css.redOnWhite;
  if (ctaStyle) {
    linkStyle = ctaStyle.selectionValues[0].key;
  }

  let containerLocationClass;
  switch (textlocation) {
    case 'Right':
      containerLocationClass = 'right-container';
      break;
    case 'Center':
      containerLocationClass = 'center-container';
      break;
    default:
      containerLocationClass = 'left-container';
  }

  let textAlignClass;
  switch (textalign) {
    case 'Right':
      textAlignClass = 'text-right';
      break;
    case 'Center':
      textAlignClass = 'text-center';
      break;
    default:
      textAlignClass = 'text-left';
  }

  const textColorClass = textcolor === 'White' ? 'text-white' : '';
  const videoId = videourl && videourl.split('vimeo.com/').pop();
  const embedURL = videoId && `https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0&controls=0&autoplay=1&loop=1&muted=1&dnt=1`;

  /* eslint react/prop-types: 0 */

  // eslint-disable-next-line react/no-unstable-nested-components
  const BannerLink = ({ ...props }) => {
    const { bannerLink, bannerLinkType } = props;
    if (bannerLinkType === 'internal') {
      return <Link to={bannerLink} className={`${linkStyle} hover:${css[textColorClass]} ${css[textColorClass]}`}>{cta}</Link>;
    }
    if (bannerLinkType === 'external') {
      return <a href={bannerLink} className={`${linkStyle} py-3 hover:${css[textColorClass]} ${css[textColorClass]}`}>{cta}</a>;
    }

    return null;
  };

  const bannerLink = link ? page.getContent(link).getUrl() : externalLink;
  const bannerLinkType = link ? 'internal' : 'external';

  if (contentRef) {
    return (
      <div className={css.VideoSingleImageBannerX}>
        <div className={css.iframeContainer}>
          {(
            embedURL.length > 0 && <iframe className={css.iframe} src={`${embedURL}`} width="100%" height="100%" frameBorder="20" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="bannerVideo" />
          )
          || (imageObject && <img src={imageObject.getUrl()} alt="background" />
          )}
        </div>
        <div className={classNames(css.articleContainer, css.sectionWrapper, css.center)}>
          <article className={`${containerLocationClass} ${textAlignClass}`}>
            { title && <span className={`br-tag ${textColorClass}`} dangerouslySetInnerHTML={{ __html: title.value }} /> }
            { subtitle && <h1 className={`${textColorClass} br-title`}><span dangerouslySetInnerHTML={{ __html: subtitle.value }} /></h1> }
            <p>
              <span className={`${css[textColorClass]}`} dangerouslySetInnerHTML={{ __html: content.value }} />
            </p>
            { cta && bannerLink && <BannerLink bannerLink={bannerLink} bannerLinkType={bannerLinkType} /> }
          </article>
        </div>
      </div>
    );
  }

  return (
    <div>No Banner on component</div>
  );
}
