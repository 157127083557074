import React from 'react';
import { Grid, Paper, Popover, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import useShoppingCartStyles from '../shoppingCartStyles';
import ItemsContent from '../Components/ItemsContent';
import appStrings from '../../../../core/strings/appStrings';
import CartSummaryFooter from '../Components/CartSummaryFooter';

const styleParams = {
  isSmallDevice: false,
  isCartSummary: true,
};

function CartSummary({ anchorEl, handleClose, displayedItems, cartLength }) {
  const css = useShoppingCartStyles(styleParams).classes;

  return (
    <Popover
      data-test-id="shoppingCartSummary-testId"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <Paper className={css.cartSummary} id="shoppingCartSummary">
        <Grid container spacing={1}>
          <Grid
            container
            className={css.summaryTitle}
            id="shoppingCartSummaryItemsTitle"
          >
            <Grid item>
              <Typography variant="h1" fontWeight="bold" fontSize="small">
                {appStrings.cart.lastItemsAdded}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {displayedItems.map((item, index) => (
              <div
                key={`cartSummaryItem-${index}`}
                id={`cartSummaryItem-${index}`}
              >
                <ItemsContent item={item} isCartSummary={true} index={index} />
              </div>
            ))}
          </Grid>
          <Grid item xs={12}>
            <CartSummaryFooter
              cartLength={cartLength}
              handleClose={handleClose}
            />
          </Grid>
        </Grid>
      </Paper>
    </Popover>
  );
}

CartSummary.propTypes = {
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
  displayedItems: PropTypes.array,
  cartLength: PropTypes.number,
};

export default CartSummary;
