import { REP } from '../../../core/navigation/roles';
import ApiService from '../Api/apiService';
import urls from '../../../core/strings/urls';
import store from '../../../core/redux/store';

export const getSchoolsByStateAndRep = (
  state,
  employeeId,
  isLoggedIn = false
) => {
  let url;
  const { role } = store.getState().login.userDetails;
  if (isLoggedIn && role === REP) {
    url = urls.getSchoolsByStateAndRep(state, employeeId);
  } else {
    url = urls.getSchoolsByStateForGuest(state);
  }

  return ApiService.get(url).then(schools => {
    return schools;
  });
};

export const getSchoolsByState = state => {
  return ApiService.get(urls.getSchoolsByState(state)).then(schools => {
    return schools;
  });
};

export const getSchoolAddress = item => {
  const addressLine2 = item.location?.addressLine2?.trim()
    ? ` ${item.location?.addressLine2},`
    : '';
  return {
    label: item.schoolName,
    description: `${item.location?.addressLine1},${addressLine2} ${item.location?.city}`,
    value: item.schoolBpNumber,
  };
};

export const getSelectedSchoolDetails = ({ schoolBpNumber, schools }) => {
  if (schoolBpNumber) {
    const school = schools.find(sc => sc.schoolBpNumber === schoolBpNumber);
    if (school) {
      return {
        schoolName: school.schoolName,
        schoolCity: school.location.city,
      };
    } else {
      return null;
    }
  }
};

export const postStudentRegistration = data => {
  const values = {
    ...data,
    addressLine1: data.addressLine1.trim(),
    addressLine2: data.addressLine2.trim(),
  };
  return ApiService.post(urls.postStudentRegistration, values);
};
