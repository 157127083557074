import ApiService from '../../Api/apiService';
import appStrings from '../../../../core/strings/appStrings';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import { stateOptionsSortedByLabel } from '../../../../core/strings/states';
import store from '../../../../core/redux/store';
import urls from '../../../../core/strings/urls';

export const handleError = ({ error }) => {
  store.dispatch(
    displayError({
      message: error || appStrings.errors.somethingWentWrong,
    })
  );
};

export const getSchoolInformation = studentId => {
  const url = `${urls.getSchoolInfo}/${studentId}`;

  return ApiService.get(url);
};

export const getSortedRepSchoolStates = async employeeId => {
  const states = await ApiService.get(urls.getSchoolStatesByRep(employeeId));
  return stateOptionsSortedByLabel(states);
};
