import { CATEGORY_ID } from '../../../../core/navigation/paths';
import { setPaths } from '../../../../core/redux/slices/categoriesSlice';
import { homePath } from './categoriesContainerConstants';
import ApiService from '../../../shared/Api/apiService';
import urls from '../../../../core/strings/urls';
import {trackGTMEvent} from '../../../shared/Analytics/analyticsService';

export const findCategory = (category, id) => {
  return category?.subCategories.find(elem => elem.id === Number(id));
};

export const getCategory = (id, setCurrentCategory, category) => {
  const newCategory = findCategory(category, id);
  if (newCategory) {
    setCurrentCategory(newCategory);
  } else if (category?.subCategories) {
    for (const element of category.subCategories) {
      getCategory(id, setCurrentCategory, element);
    }
  }
};

export const getPaths = (breadcrumb, dispatch, currentCategory) => {
  const callback = (breadcrumbs, currentCategoryId) => {
    trackGTMEvent('category-pageview', {
      breadcrumbs: breadcrumbs,
      category_ID: currentCategoryId,
      page_type: 'category',
      orig_ref_url: document.location.href
    });
  }
  const newPath = breadcrumb?.map(elem => ({
    link: CATEGORY_ID(elem.id),
    label: elem.name,
    id: elem.id,
  }));
  if (newPath) {
    newPath?.unshift(...homePath);
    dispatch(setPaths({paths:[...newPath], currentCategory, callback}));
  }
};

export const getProducts = categoryId => {
  return ApiService.get(urls.getProducts(categoryId));
};
