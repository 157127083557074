import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { ZoomOutMapOutlined } from '@mui/icons-material';
import useImageZoomButtonStyles from './useImageZoomButtonStyles';

function ImageZoomButton({clickHandler}) {
  const css = useImageZoomButtonStyles().classes;

  return (
    <IconButton
      data-test-id="renderedZoomButton"
      id="imageZoomButton"
      className={css.zoomButton}
      onClick={() => clickHandler && clickHandler()}
    >
      <ZoomOutMapOutlined />
    </IconButton>
  )
}

ImageZoomButton.propTypes = {
  clickHandler: PropTypes.func,
};

export default ImageZoomButton;