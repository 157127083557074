import * as rgxPatterns from '../../../core/strings/regexValidations';

import { ADMIN, industrialRoleList } from '../../../core/navigation/roles';
import {
  dateFormat,
  phoneMask,
  snaponDomain,
} from '../../../core/strings/appConstants';

import appStrings from '../../../core/strings/appStrings';
import { gridLayout } from './registerConstants';
import { isValidZipCode } from '../../../core/services/zipCodeService';
import { xYearsFromNow } from '../../../core/services/utilsService';

export const getGuestRegisterInputs = {
  userDetails: {
    sectionTitle: appStrings.register.contactInformation,
    fields: [
      {
        name: 'firstName',
        label: appStrings.common.firstName,
        type: 'text',
        key: 'registerForm-firstName',
        validations: {
          required: true,
          pattern: rgxPatterns.nameStringPattern,
          maxLength: 25,
        },
        gridLayout: {
          xs: 6,
        },
        helperText: appStrings.validationMessages.nameString,
      },
      {
        name: 'lastName',
        label: appStrings.common.lastName,
        type: 'text',
        validations: {
          required: true,
          pattern: rgxPatterns.nameStringPattern,
          maxLength: 25,
        },
        gridLayout: {
          xs: 6,
        },
        helperText: appStrings.validationMessages.nameString,
      },
      {
        name: 'email',
        label: appStrings.register.email,
        type: 'text',
        validations: {
          required: true,
          validate: {
            emailDomain: value =>
              !value.includes(snaponDomain) ||
              appStrings.validationMessages.emailBadDomain,
            emailPattern: value =>
              rgxPatterns.emailPattern.test(value) ||
              appStrings.validationMessages.emailFormat,
          },
          maxLength: 100,
        },
        gridLayout: {
          xs: 12,
        },
      },
      {
        name: 'cellPhoneNumber',
        label: appStrings.register.cellPhone,
        type: 'tel',
        validations: {
          required: true,
          minLength: 10,
          maxLength: 10,
        },
        gridLayout: {
          xs: 6,
        },
        helperText: appStrings.validationMessages.xDigitsOnly(10),
        maskFormat: phoneMask,
      },
      {
        name: 'addressLine1',
        label: `${appStrings.register.address} #1`,
        type: 'text',
        validations: {
          required: true,
          pattern: rgxPatterns.addressPattern,
          maxLength: 50,
        },
        gridLayout: {
          xs: 12,
        },
        helperText: appStrings.validationMessages.address,
      },
      {
        name: 'addressLine2',
        label: `${appStrings.register.address} #2`,
        type: 'text',
        validations: {
          pattern: rgxPatterns.addressPattern,
          maxLength: 50,
        },
        gridLayout: {
          xs: 12,
        },
        helperText: appStrings.validationMessages.address,
      },
      {
        name: 'zipCode',
        label: appStrings.common.zipCode,
        type: 'text',
        validations: {
          required: true,
          minLength: {
            value: 5,
            message: appStrings.validationMessages.xDigitsOnly(5),
          },
          pattern: {
            value: rgxPatterns.digitPattern,
            message: appStrings.validationMessages.xDigitsOnly(5),
          },
          maxLength: 5,
          validate: {
            invalidZipCode: value => isValidZipCode(value),
          },
        },
        gridLayout: {
          xs: 6,
        },
      },
      {
        name: 'city',
        label: appStrings.common.city,
        type: 'select',
        validations: {
          required: true,
        },
        options: [],
        gridLayout: {
          xs: 6,
        },
      },
      {
        name: 'state',
        label: appStrings.common.state,
        type: 'text',
        validations: {
          required: true,
        },
        gridLayout: {
          xs: 6,
        },
        readOnly: true,
      },
    ],
  },
  schoolDetails: {
    sectionTitle: appStrings.register.schoolInformation,
    fields: [
      {
        name: 'schoolState',
        label: appStrings.register.schoolState,
        type: 'autocomplete',
        validations: {
          required: true,
        },
        options: [],
        gridLayout: {
          xs: 12,
        },
      },
      {
        name: 'schoolBpNumber',
        label: appStrings.register.schoolName,
        type: 'autocomplete',
        validations: {
          required: true,
        },
        options: [],
        key: '',
        gridLayout: {
          xs: 12,
        },
      },
      {
        name: 'educationAccountManagerName',
        label: appStrings.register.educationAccManagerName,
        type: 'text',
        validations: {
          required: true,
          maxLength: 50,
        },
        gridLayout: {
          xs: 12,
        },
        readOnly: true,
      },
      {
        name: 'currentInstructorName',
        label: appStrings.register.currentInstructorName,
        type: 'text',
        validations: {
          required: true,
          maxLength: 50,
          pattern: rgxPatterns.currentInstructorNamePattern,
        },
        gridLayout: {
          xs: 12,
        },
        helperText: appStrings.validationMessages.nameString,
      },
      {
        name: 'studentId',
        label: appStrings.register.studentId,
        type: 'text',
        validations: { maxLength: 20 },
        gridLayout: {
          xs: 12,
        },
      },
      {
        name: 'graduationDate',
        label: appStrings.register.graduationDay,
        type: 'date',
        format: dateFormat,
        disablePast: true,
        maxDate: xYearsFromNow(10),
        maxDateValidationMessage: appStrings.validationMessages.graduationDate,
        minDateValidationMessage:
          appStrings.validationMessages.graduationDateFuture,
        validations: {
          required: true,
        },
        gridLayout: {
          xs: 12,
        },
      },
      {
        name: 'isEmployed',
        label: appStrings.register.isEmployed,
        type: 'checkbox',
        validations: {},
        gridLayout: {
          xs: 12,
        },
      },
      {
        name: 'usesTools',
        label: appStrings.register.usesTools,
        type: 'checkbox',
        validations: {},
        gridLayout: {
          xs: 12,
        },
      },
      {
        name: 'receivePromotionalMessages',
        label: appStrings.register.promotionalMessages,
        type: 'checkbox',
        validations: {},
        gridLayout: {
          xs: 12,
        },
      },
      {
        name: 'receivePromotionalEmails',
        label: appStrings.register.promotionalEmails,
        type: 'checkbox',
        validations: {},
        gridLayout: {
          xs: 12,
        },
      },
    ],
  },
  accountDetails: {
    sectionTitle: appStrings.register.password,
    fields: [
      {
        name: 'password',
        label: appStrings.register.password,
        type: 'password',
        validations: {
          required: true,
          validate: {
            correctLength: value =>
              (value.length > 8 && value.length < 16) ||
              appStrings.validationMessages.passwordLength,
            letterPattern: value =>
              rgxPatterns.minOneLetterPattern.test(value) ||
              appStrings.validationMessages.passwordLetter,
            digitPattern: value =>
              rgxPatterns.minOneNumberPatter.test(value) ||
              appStrings.validationMessages.passwordNumber,
            specialCharPattern: value =>
              rgxPatterns.minOneSpecialCharPattern.test(value) ||
              appStrings.validationMessages.passwordSpecialCharacter,
          },
        },
        hideErrorMessage: true,
        showVisibilityBtn: true,
        gridLayout: {
          xs: 12,
        },
      },
      {
        name: 'passwordConfirmation',
        label: appStrings.register.confirmPassword,
        type: 'password',
        validations: {
          required: true,
        },
        gridLayout: {
          xs: 12,
        },
        helperText: appStrings.validationMessages.passwordMatch,
        showVisibilityBtn: true,
      },
      {
        name: 'termsAndConditions',
        label: appStrings.register.acceptTerms,
        type: 'checkbox',
        validations: {
          required: true,
        },
        gridLayout: {
          xs: 12,
        },
      },
    ],
  },
};

export const getRegisterInputs = {
  userDetails: {
    sectionTitle: appStrings.register.contactInformation,
    fields: [
      {
        name: 'firstName',
        label: appStrings.common.firstName,
        type: 'text',
        key: 'registerForm-firstName',
        validations: {
          required: true,
          pattern: rgxPatterns.nameStringPattern,
          maxLength: 25,
        },
        gridLayout,
        helperText: appStrings.validationMessages.nameString,
      },
      {
        name: 'lastName',
        label: appStrings.common.lastName,
        type: 'text',
        validations: {
          required: true,
          pattern: rgxPatterns.nameStringPattern,
          maxLength: 25,
        },
        gridLayout,
        helperText: appStrings.validationMessages.nameString,
      },
      {
        name: 'email',
        label: appStrings.register.email,
        type: 'text',
        validations: {
          required: true,
          validate: {
            emailDomain: value =>
              !value.includes(snaponDomain) ||
              appStrings.validationMessages.emailBadDomain,
            emailPattern: value =>
              rgxPatterns.emailPattern.test(value) ||
              appStrings.validationMessages.emailFormat,
          },
          maxLength: 100,
        },
        gridLayout,
      },
      {
        name: 'cellPhoneNumber',
        label: appStrings.register.cellPhone,
        type: 'tel',
        validations: {
          required: true,
          minLength: 10,
          maxLength: 10,
        },
        gridLayout,
        helperText: appStrings.validationMessages.xDigitsOnly(10),
        maskFormat: phoneMask,
      },
      {
        name: 'addressLine1',
        label: `${appStrings.register.permanentHome} ${appStrings.register.address} #1`,
        type: 'text',
        validations: {
          required: true,
          pattern: rgxPatterns.addressPattern,
          maxLength: 50,
        },
        gridLayout,
        helperText: appStrings.validationMessages.address,
      },
      {
        name: 'addressLine2',
        label: `${appStrings.register.permanentHome}  ${appStrings.register.address} #2`,
        type: 'text',
        validations: {
          pattern: rgxPatterns.addressPattern,
          maxLength: 50,
        },
        gridLayout,
        helperText: appStrings.validationMessages.address,
      },
      {
        name: 'zipCode',
        label: `${appStrings.register.permanentHome} ${appStrings.common.zipCode}`,
        type: 'text',
        validations: {
          required: true,
          minLength: {
            value: 5,
            message: appStrings.validationMessages.xDigitsOnly(5),
          },
          pattern: {
            value: rgxPatterns.digitPattern,
            message: appStrings.validationMessages.xDigitsOnly(5),
          },
          maxLength: 5,
          validate: {
            invalidZipCode: value => isValidZipCode(value),
          },
        },
        gridLayout,
      },
      {
        name: 'city',
        label: `${appStrings.register.permanentHome} ${appStrings.common.city}`,
        type: 'select',
        validations: {
          required: true,
        },
        options: [],
        gridLayout,
      },
      {
        name: 'state',
        label: `${appStrings.register.permanentHome} ${appStrings.common.state}`,
        type: 'text',
        validations: {
          required: true,
        },
        gridLayout,
        readOnly: true,
      },
    ],
  },
  schoolDetails: {
    sectionTitle: appStrings.register.schoolInformation,
    fields: [
      {
        name: 'schoolState',
        label: appStrings.register.schoolState,
        type: 'autocomplete',
        validations: {
          required: true,
        },
        options: [],
        gridLayout,
      },
      {
        name: 'schoolBpNumber',
        label: appStrings.register.schoolName,
        type: 'autocomplete',
        validations: {
          required: true,
        },
        options: [],
        key: '',
        gridLayout,
      },
      {
        name: 'educationAccountManagerName',
        label: appStrings.register.educationAccManagerName,
        type: 'text',
        validations: {
          required: true,
          maxLength: 50,
        },
        gridLayout,
        readOnly: true,
        roles: [ADMIN],
      },
      {
        name: 'currentInstructorName',
        label: appStrings.register.currentInstructorName,
        type: 'text',
        validations: {
          required: true,
          maxLength: 50,
          pattern: rgxPatterns.currentInstructorNamePattern,
        },
        gridLayout,
        helperText: appStrings.validationMessages.nameString,
      },
      {
        name: 'graduationDate',
        label: appStrings.register.graduationDate,
        type: 'date',
        format: dateFormat,
        validations: {
          required: true,
        },
        gridLayout,
        disablePast: true,
        maxDate: xYearsFromNow(10),
        maxDateValidationMessage: appStrings.validationMessages.graduationDate,
        minDateValidationMessage:
          appStrings.validationMessages.graduationDateFuture,
        roles: industrialRoleList,
      },
      {
        name: 'studentId',
        label: appStrings.register.studentId,
        type: 'text',
        validations: { maxLength: 20 },
        gridLayout,
      },
      {
        name: 'isEmployed',
        label: appStrings.register.isEmployed,
        type: 'switch',
        validations: {},
        gridLayout,
      },
      {
        name: 'usesTools',
        label: appStrings.register.usesTools,
        type: 'switch',
        validations: {},
        gridLayout,
      },
      {
        name: 'receivePromotionalMessages',
        label: appStrings.register.promotionalMessages,
        type: 'switch',
        validations: {},
        gridLayout,
      },
      {
        name: 'receivePromotionalEmails',
        label: appStrings.register.promotionalEmails,
        type: 'switch',
        validations: {},
        gridLayout: {
          xs: 12,
          sm: 8,
          md: 8,
          lg: 4,
        },
      },
      {
        name: 'approveStudent',
        label: appStrings.register.approveStudent,
        type: 'switch',
        validations: {},
        gridLayout,
        roles: industrialRoleList,
        defaultValue: true,
      },
    ],
  },
  accountDetails: {
    sectionTitle: appStrings.register.password,
    fields: [
      {
        name: 'password',
        label: appStrings.register.password,
        type: 'password',
        validations: {
          required: true,
          validate: {
            correctLength: value =>
              (value.length > 8 && value.length < 16) ||
              appStrings.validationMessages.passwordLength,
            letterPattern: value =>
              rgxPatterns.minOneLetterPattern.test(value) ||
              appStrings.validationMessages.passwordLetter,
            digitPattern: value =>
              rgxPatterns.minOneNumberPatter.test(value) ||
              appStrings.validationMessages.passwordNumber,
            specialCharPattern: value =>
              rgxPatterns.minOneSpecialCharPattern.test(value) ||
              appStrings.validationMessages.passwordSpecialCharacter,
          },
        },
        hideErrorMessage: true,
        gridLayout,
      },
      {
        name: 'passwordConfirmation',
        label: appStrings.register.confirmPassword,
        type: 'password',
        validations: {
          required: true,
        },
        gridLayout,
        helperText: appStrings.validationMessages.passwordMatch,
      },
    ],
  },
};

export const registerSections = Object.keys(getRegisterInputs);

export const updateSelectOptions = (section, field, data, isLoggedIn) => {
  const registerInputs = isLoggedIn
    ? getRegisterInputs
    : getGuestRegisterInputs;
  const target = registerInputs[section].fields.find(t => t.name === field);
  target.options = data;
};

export const initializeField = (section, field, isLoggedIn) => {
  const registerInputs = isLoggedIn
    ? getRegisterInputs
    : getGuestRegisterInputs;
  const fieldObj = registerInputs[section].fields.find(t => t.name === field);
  if (typeof fieldObj.key !== 'undefined') {
    fieldObj.key = Date.now();
  }
};
