import { Grid, Skeleton } from '@mui/material';
import React from 'react';
import CustomButton from '../../../modules/shared/CustomButton/CustomButton';
import useProductContentStyles from './productContentStyles';

function ProductContentSkeleton() {
  const css = useProductContentStyles().classes;

  const getLabel = () => (
    <Grid container spacing={2} alignItems="center" height="100%">
      <Grid item container>
        <Grid item container alignItems="center" xs={8}>
          <Grid item xs>
            <Skeleton variant="text" />
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent="flex-end"
          alignContent="center"
          xs={4}
        >
          <Grid item>
            <Skeleton variant="circular" width={20} height={20} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <Grid item xs>
          <Skeleton variant="rounded" height={130} />
        </Grid>
      </Grid>
      <Grid item container xs>
        <Grid item xs={10}>
          <Skeleton variant="text" />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="text" />
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={7}>
          <Grid item xs={8}>
            <Skeleton variant="text" />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="text" />
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={5}
          justifyContent="flex-end"
          alignContent="center"
        >
          <Grid item>
            <Skeleton variant="circular" width={30} height={30} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid
      item
      container
      spacing={2}
      id="productsContent-Skeleton"
      data-test-id="productsContent-SkeletonTestId"
    >
      {Array(9)
        .fill(true)
        .map((item, index) => (
          <Grid key={index} item xs={6} sm={4} md={4} xl={3}>
            <CustomButton
              customClass={css.productContentSkeleton}
              id="productContent-Skeleton"
              btnType="secondary"
              label={getLabel()}
              disabled
            />
          </Grid>
        ))}
    </Grid>
  );
}

export default ProductContentSkeleton;
