import { makeStyles } from 'tss-react/mui';

const useWizardStyles = makeStyles()(theme => ({
  dialogStyle: {
    background: theme.palette.common.neutral2,
  },
  mainContainer: {
    background: theme.palette.common.neutral2,
    minHeight: '100vh',
  },
  logo: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  header: {
    padding: theme.spacing(2, 3),
    backgroundColor: theme.palette.common.neutral1,
    color: theme.palette.text.primary,
    borderBottom: `1px solid ${theme.palette.common.lightGrey}`,
    boxShadow: 'none',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
    zIndex: '9',

    '& .MuiButtonBase-root': {
      padding: 0,
    },

    '#wizardTitle': {
      position: 'relative',
      left: theme.spacing(-5),
    },
  },

  contentContainer: {
    padding: theme.spacing(5, 0),
  },

  formContentContainer: {
    paddingTop: theme.spacing(4),
  },

  stepper: {
    // root step
    '& .MuiSvgIcon-fontSizeMedium': {
      height: '0.8em !important',
      width: '0.8em !important',
      fontSize: '35px',
    },
    '& .MuiSvgIcon-root': {
      borderRadius: '50%',
      border: `2px solid ${theme.palette.common.primary1}`,
      color: `transparent !important`,

      '& .MuiStepIcon-text': {
        fill: `${theme.palette.common.primary1}`,
        fontSize: '1rem',
      },
    },

    // active step
    '& .MuiSvgIcon-root.Mui-active': {
      border: `2px solid ${theme.palette.common.primary}`,
      color: `${theme.palette.common.primary} !important`,

      '& .MuiStepIcon-text': {
        fill: `${theme.palette.common.white}`,
        fontSize: '1rem',
      },
    },

    // completed step
    '& .Mui-completed .MuiSvgIcon-root': {
      borderColor: theme.palette.common.primary,
      color: `${theme.palette.common.primary} !important`,
    },
    '& .Mui-completed.MuiStepLabel-label': {
      fontWeight: 'normal',
    },
  },

  formContainer: {
    padding: '0 25px 25px 25px',
  },

  studentFormContainer: {
    padding: '0 25px 25px 25px',
  },

  checkboxFieldContainer: {
    marginTop: '-24px',
  },

  spacingAroundCheckboxes: {
    marginBottom: '24px',
  },

  mandatoryMessageContainer: {
    margin: '0 0 25px 25px',
  },
}));

export default useWizardStyles;
