import { InputAdornment } from '@mui/material';
import appStrings from '../../../../core/strings/appStrings';

export const orderInputs = (
  product,
  isActiveStudent,
  warehouseOptions,
  onWarehouseChange
) => [
  {
    name: `warehouse${product.recordId}`,
    labelText: `${appStrings.order.warehouse}:`,
    type: 'select',
    options: warehouseOptions,
    readOnly: warehouseOptions.length <= 1,
    gridLayout: { xs: 12 },
    disabled: !isActiveStudent,
    onChangeCallback: onWarehouseChange,
  },
  {
    name: `serialNumber${product.recordId}`,
    labelText: `${appStrings.order.serialNumber}:`,
    type: 'text',
    readOnly: true,
    gridLayout: { xs: 12 },
    InputProps: {
      endAdornment: <InputAdornment position="end">{'...'}</InputAdornment>,
    },
    disabled: !isActiveStudent,
  },
  {
    name: `serialNumberValue${product.recordId}`,
    type: 'hidden',
  },
  {
    name: `lnLineNotes${product.recordId}`,
    label: appStrings.cart.lnLineNotes,
    type: 'text',
    multiline: true,
    rows: 4,
    validations: {
      maxLength: 72,
    },
    gridLayout: { xs: 12 },
    disabled: !isActiveStudent,
  },
];

export const notesInputs = (product, isActiveStudent) => [
  {
    name: `lnLineNotes${product.recordId}`,
    label: appStrings.cart.lnLineNotes,
    type: 'text',
    multiline: true,
    rows: 3,
    validations: {
      maxLength: 72,
    },
    gridLayout: { xs: 12, sm: 4, md: 4, lg: 4 },
    disabled: !isActiveStudent,
  },
  {
    name: `studentNotes${product.recordId}`,
    label: appStrings.cart.studentNotes,
    type: 'text',
    multiline: true,
    rows: 3,
    validations: {
      maxLength: 2000,
    },
    gridLayout: { xs: 12, sm: 8, md: 8, lg: 8 },
    disabled: !isActiveStudent,
  },
];
