import {
  saveAddressInformation,
  validateDatePickers,
} from '../../../../industrial/StudentProfile/Services/studentProfileService';

import { cloneDeep } from 'lodash';
import { isSignNowBtnClicked } from '../wizardPageContainerService';

export const saveAddressInfoWizard = (
  data,
  reset,
  id,
  setValue,
  onSuccessCallback,
  onErrorCallback
) => {
  const initialData = cloneDeep(data);

  if (validateDatePickers().valid) {
    Promise.all([saveAddressInformation({ id, data, setValue })])
      .then(() => {
        if (onSuccessCallback) {
          onSuccessCallback(true, isSignNowBtnClicked());
        }
      })
      .catch(err => {
        if (onErrorCallback) {
          onErrorCallback(err);
        }
      });

    reset(initialData);
  }
};

export const makeCurrentAddressSameAsPermanent = initialData => {
  const newData = cloneDeep(initialData);

  newData.currentAddress = {
    addressLine1: initialData.permanentAddress.addressLine1,
    addressLine2: initialData.permanentAddress.addressLine2,
    zipCode: initialData.permanentAddress.zipCode,
    city: initialData.permanentAddress.city,
    state: initialData.permanentAddress.state,
    residencyStartDate: initialData.permanentAddress.residencyStartDate,
    addressType: 2,
  };

  if (initialData.currentAddress?.id) {
    newData.currentAddress.id = initialData.currentAddress.id;
  }

  return newData;
};

export const haveTheSameAddresses = data => {
  if (data.currentAddress) {
    const current = data.currentAddress;
    const permanent = data.permanentAddress;
    const fields = [
      'addressLine1',
      'addressLine2',
      'zipCode',
      'city',
      'state',
      'residencyStartDate',
    ];
    let result = true;
    fields.forEach(label => {
      if (current[label] !== permanent[label]) {
        result = false;
      }
    });

    return result;
  }
  return false;
};
