import { Grid, Skeleton } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

function ProductPageSkeleton({ css }) {
  return (
    <Grid
      container
      spacing={3}
      className={css.productSkeleton}
      id="productPageSkeleton"
      data-test-id="productPageSkeleton-testId"
    >
      <Grid item container>
        <Grid item xs={8} sm={12} md={9}>
          <Skeleton variant="text" height={30} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Skeleton variant="text" height={30} />
        </Grid>
        <Grid item xs={8} display={{ sm: 'none' }}>
          <Skeleton variant="text" height={30} />
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={7} md={8} justifyContent="center">
        <Grid item xs={10} sm={8} md={8} lg={5}>
          <Skeleton variant="rounded" height={300} />
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={5} md={4}>
        <Grid item xs={12}>
          <Skeleton variant="text" height={40} width="60%" />
          <Skeleton variant="text" height={40} width="80%" />
          <Skeleton
            variant="text"
            height={40}
            sx={{ marginBottom: '20px' }}
            width="70%"
          />
          <Skeleton variant="text" height={60} />
          <Grid item sm={12} display={{ xs: 'none', sm: 'block' }}>
            <Skeleton variant="text" height={60} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="text" height={30} />
        <Skeleton variant="text" height={30} />
      </Grid>
    </Grid>
  );
}

ProductPageSkeleton.propTypes = {
  css: PropTypes.object,
};

export default ProductPageSkeleton;
