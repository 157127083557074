import React from 'react';
import { Grid, Skeleton } from '@mui/material';
import { useProductsSetsStyles } from './useProductsSetsStyles';

function ProductSetsSkeleton() {
  const css = useProductsSetsStyles().classes;

  const renderItem = () => (
    <>
      <Grid item xs={4} md={2} lg={1}>
        <Skeleton variant="rounded" height={100} />
      </Grid>
      <Grid item container xs={6} md={9} lg={10} flexDirection="column">
        <Grid item xs={3}>
          <Skeleton variant="text" height={30}/>
        </Grid>
        <Grid item xs={5}>
          <Skeleton variant="text" height={30}/>
        </Grid>
        <Grid item xs={1} marginTop="auto">
          <Skeleton variant="text"/>
        </Grid>
      </Grid>
      <Grid item container xs={2} md={1} lg={1} alignContent="center">
        <Skeleton variant="circular" height={30} width={30} />
      </Grid>
    </>
  );

  return (
    <Grid 
      container
      item
      spacing={2}
      xs={12}
      lg={10}
      height="100%"
      alignContent="flex-start"
      margin="0 auto"
      className={css.setContainer}
    >
      <Grid item container>
        <Grid item container alignItems="center" xs={12} lg={6}>
          <Grid item xs={12}>
            <Skeleton variant="text" height={60} />
          </Grid>
          <Grid item alignItems="center" xs={8}>
            <Grid item xs>
              <Skeleton variant="text" height={30} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {Array(6).fill(renderItem()).map((item, index) => (
        <Grid key={index} item xs={12} container spacing={2}>
          {item}
        </Grid>
      ))}
    </Grid>
  );
}

export default ProductSetsSkeleton;