import CONSTANTS from '../constants';
import { NotificationTypeConstants } from '../../../modules/shared/Notification/notificationConstants';
import { createSlice } from '@reduxjs/toolkit';
import { filter } from 'lodash';
import { sortObjectsByProp } from '../../services/utilsService';
import { orderType } from '../../strings/appConstants';

const sortItemsByNotifications = items => {
  const errorItems = [],
    warningItems = [],
    restOfItems = [];
  items.forEach(item => {
    if (
      item.itemNotifications &&
      item.itemNotifications.findIndex(
        notification =>
          notification.messageType.toLowerCase() ===
          NotificationTypeConstants.error
      ) !== -1
    ) {
      errorItems.push(item);
      return;
    }
    if (
      item.itemNotifications &&
      item.itemNotifications.findIndex(
        notification =>
          notification.messageType.toLowerCase() ===
          NotificationTypeConstants.warning
      ) !== -1
    ) {
      warningItems.push(item);
      return;
    }
    restOfItems.push(item);
  });
  errorItems.sort((a, b) => sortObjectsByProp(a, b, 'productNumber'));
  warningItems.sort((a, b) => sortObjectsByProp(a, b, 'productNumber'));
  restOfItems.sort((a, b) => sortObjectsByProp(a, b, 'productNumber'));
  return [...errorItems, ...warningItems, ...restOfItems];
};

const getOrderItemIndex = (list, product) => {
  return list.findIndex(item => {
    return (
      item.productNumber.toLowerCase() ===
        product.productNumber.toLowerCase() &&
      item.warehouse.name.toLowerCase() === product.warehouse.name.toLowerCase()
    );
  });
};

const cartSlice = createSlice({
  name: CONSTANTS.cart,
  initialState: {
    cartInfo: {
      selectedCounter: 0,
      cartDetails: null,
      filteredProducts: null,
      selectedFilteredCounter: 0,
    },
    orderInfo: {
      shippingAddress: {},
      billingAddress: {},
      payments: [],
      studentId: null,
    },
    totals: {
      subtotal: 0,
      tax: 0,
      grandTotal: 0,
      taxErrorMessage: '',
      downPayment: 0,
      paymentTotal: 0,
    },
    ecContract: {
      contract: { paymentsNo: 0 },
      contractTerms: [],
    },
    n90Errors: {
      ecValidation: [],
      profileValidation: [],
    },
    ecContractLoading: false,
    totalsLoading: false,
    downPaymentLoading: false,
    uploadInProgress: false,
    reloadCart: false,
    uploadErrors: null,
    uploadStatus: null,
    reloadOrderItems: false,
    saveOrderStatus: null,
    addItemLoading: false,
    isECOrder: false,
    selectedAccount: null,
    selectedPromotion: null,
    hasNoAvailableCredit: false,
    moveItemsStatus: null,
    refreshTax: false,
  },
  reducers: {
    setAddItemLoading: (state, { payload }) => {
      state.addItemLoading = payload;
    },

    updateCart: (state, action) => {
      if (action.payload.cartDetails) {
        state.cartInfo = { ...action.payload };
      } else {
        state.orderInfo = { ...state.orderInfo, ...action.payload };
        state.orderInfo.payments = state.orderInfo.payments?.map(payment => {
          payment.amount = payment.amount !== 0 ? payment.amount : null;
          return payment;
        });
        delete state.orderInfo.lineItems;
        state.cartInfo.cartDetails = { cartItems: action.payload.lineItems };
        if (!state.reloadOrderItems) {
          state.isECOrder = action.payload.type === orderType.ec;
          state.selectedAccount = action.payload.accountDebtorNumber;
          state.selectedPromotion = action.payload.promoId;
        }
      }
      state.cartInfo.selectedCounter = 0;
      state.cartInfo.cartDetails.cartItems =
        state.cartInfo.cartDetails.cartItems.map(item => {
          // save initial studentPrice and replace studentPrice with the value that will be displayed, either promo or student price
          item.initialStudentPrice = item.studentPrice;
          item.studentPrice = item.promoPrice || item.studentPrice;
          return item;
        });
      state.cartInfo.cartDetails.cartItems = sortItemsByNotifications(
        state.cartInfo.cartDetails.cartItems
      );
    },

    addItem: (state, action) => {
      let itemIndex;
      if (state.cartInfo.cartDetails) {
        if (action.payload.warehouse) {
          itemIndex = getOrderItemIndex(
            state.cartInfo.cartDetails.cartItems,
            action.payload
          );
        } else {
          itemIndex = state.cartInfo.cartDetails.cartItems.findIndex(item => {
            return item.recordId === action.payload.recordId;
          });
        }
        if (itemIndex !== -1) {
          state.cartInfo.cartDetails.cartItems[itemIndex] = {
            ...action.payload,
            productNumber:
              state.cartInfo.cartDetails.cartItems[itemIndex].productNumber,
            description:
              state.cartInfo.cartDetails.cartItems[itemIndex].description,
            selected: state.cartInfo.cartDetails.cartItems[itemIndex].selected,
            recordId: state.cartInfo.cartDetails.cartItems[itemIndex].recordId,
            studentPrice:
              action.payload.promoPrice || action.payload.studentPrice,
          };
        } else {
          if (action.payload.recordId) {
            state.cartInfo.cartDetails.cartItems.push({
              ...action.payload,
              initialStudentPrice: action.payload.studentPrice,
              studentPrice:
                action.payload.promoPrice || action.payload.studentPrice,
            });
          }
        }
        state.cartInfo.cartDetails.cartItems = sortItemsByNotifications(
          state.cartInfo.cartDetails.cartItems
        );
      }
    },

    updateCartItem: (state, action) => {
      const description =
        state.cartInfo.cartDetails.cartItems[action.payload.index].description;
      const selected =
        state.cartInfo.cartDetails.cartItems[action.payload.index].selected;
      const recordId =
        state.cartInfo.cartDetails.cartItems[action.payload.index].recordId;

      state.cartInfo.cartDetails.cartItems.splice(action.payload.index, 1, {
        ...action.payload.item,
        description,
        selected,
        recordId,
        studentPrice:
          action.payload.item.promoPrice || action.payload.item.studentPrice,
      });
      state.cartInfo.cartDetails.cartItems = sortItemsByNotifications(
        state.cartInfo.cartDetails.cartItems
      );
    },

    deleteItemNotification: (state, action) => {
      const itemIndex = state.cartInfo.cartDetails.cartItems.findIndex(item => {
        return item.recordId === action.payload.recordId;
      });
      state.cartInfo.cartDetails.cartItems[itemIndex].itemNotifications.splice(
        action.payload.notificationIndex,
        1
      );
      state.cartInfo.cartDetails.cartItems = sortItemsByNotifications(
        state.cartInfo.cartDetails.cartItems
      );
    },

    updateItemPrice: (state, action) => {
      const itemIndex = getOrderItemIndex(
        state.cartInfo.cartDetails.cartItems,
        action.payload.product
      );
      state.cartInfo.cartDetails.cartItems[itemIndex].listPrice =
        action.payload.newPrices.listPrice;
      state.cartInfo.cartDetails.cartItems[itemIndex].studentPrice =
        action.payload.newPrices.netPrice;
      state.cartInfo.cartDetails.cartItems[itemIndex].initialStudentPrice =
        action.payload.newPrices.netPrice;
    },

    selectCartItem: (state, action) => {
      state.cartInfo.cartDetails.cartItems[action.payload.index].selected =
        action.payload.value;
      state.cartInfo.selectedCounter += action.payload.value ? 1 : -1;
    },

    selectAllItems: (state, action) => {
      state.cartInfo.cartDetails.cartItems.forEach(item => {
        item.selected = action.payload;
      });
      state.cartInfo.selectedCounter = action.payload
        ? state.cartInfo.cartDetails.cartItems.length
        : 0;
    },
    setCartId: (state, action) => {
      state.cartInfo.cartDetails.cartId = action.payload;
    },

    setItemsList: (state, action) => {
      state.cartInfo.cartDetails.cartItems = action.payload;
      state.cartInfo.selectedCounter = filter(
        action.payload,
        item => item.selected
      ).length;
      state.cartInfo.cartDetails.cartItems = sortItemsByNotifications(
        state.cartInfo.cartDetails.cartItems
      );
    },
    setTotals: (state, action) => {
      state.totals = { ...state.totals, ...action.payload };
      state.totals.taxErrorMessage = action.payload.taxErrorMessage ?? '';
    },
    setDownPayment: (state, action) => {
      state.totals.downPayment = action.payload.downPayment;
    },

    resetDownPayment: state => {
      state.totals.downPayment = 0;
    },

    resetTotals: state => {
      state.totals = {
        subtotal: 0,
        tax: 0,
        grandTotal: 0,
        taxErrorMessage: '',
        downPayment: 0,
        loading: false,
        paymentTotal: 0,
      };
    },

    setTotalsLoading: (state, action) => {
      state.totalsLoading = action.payload;
    },

    setDownPaymentLoading: (state, action) => {
      state.downPaymentLoading = action.payload;
    },

    updateUploadInProgress: (state, action) => {
      state.uploadInProgress = action.payload;
    },

    setReloadCart: (state, action) => {
      state.reloadCart = action.payload;
    },

    reloadCart: (state, { payload }) => {
      state.reloadCart = true;
      state.uploadStatus = payload;
    },

    resetReloadCart: state => {
      state.reloadCart = false;
      state.uploadStatus = null;
      state.uploadErrors = null;
      state.uploadInProgress = false;
    },

    handleUploadErrors: (state, { payload }) => {
      state.uploadErrors = payload.message;
    },

    reloadOrderItems: (state, { payload }) => {
      state.reloadOrderItems = true;
      state.saveOrderStatus = payload;
    },

    resetReloadOrderItems: state => {
      state.reloadOrderItems = false;
      state.saveOrderStatus = null;
    },

    handleSaveOrderSuccess: (state, { payload }) => {
      state.saveOrderStatus = payload;
    },

    resetCartInfo: state => {
      state.cartInfo.selectedCounter = 0;
      state.cartInfo.cartDetails = null;
      state.orderInfo = {
        shippingAddress: {},
        billingAddress: {},
        payments: [],
      };
      state.ecContract = {
        contract: { paymentsNo: 0 },
        contractTerms: [],
      };
      state.selectedAccount = null;
      state.selectedPromotion = null;
      state.hasNoAvailableCredit = false;
      state.isECOrder = false;
    },

    setShippingAndBilling: (state, { payload }) => {
      state.orderInfo.shippingAddress = payload.shippingAddress;
      state.orderInfo.billingAddress = payload.billingAddress;
    },
    mergeCartItems: (state, { payload }) => {
      const oldItemIndex = state.cartInfo.cartDetails.cartItems.findIndex(
        item => {
          return item.recordId === payload.oldItemId;
        }
      );
      const mergeItemIndex = state.cartInfo.cartDetails.cartItems.findIndex(
        item => {
          return item.recordId === payload.newItem.recordId;
        }
      );

      if (mergeItemIndex !== -1) {
        state.cartInfo.cartDetails.cartItems[mergeItemIndex] = {
          ...payload.newItem,
          selected:
            state.cartInfo.cartDetails.cartItems[mergeItemIndex].selected,
          studentPrice:
            payload.newItem.promoPrice || payload.newItem.studentPrice,
        };
      }
      state.cartInfo.cartDetails.cartItems.splice(oldItemIndex, 1);
    },
    setIsECOrder: (state, { payload }) => {
      state.isECOrder = payload.orderType === orderType.ec;
      if (payload.orderType !== orderType.ec) {
        state.ecContract = {
          contract: { paymentsNo: 0 },
          contractTerms: [],
        };
      }
    },
    setAccountAndPromo: (state, { payload }) => {
      state.selectedAccount = payload.selectedAccount;
      state.selectedPromotion = payload.selectedPromotion;
    },
    setContractDetails: (state, { payload }) => {
      state.ecContract = payload;
    },
    setECContractLoading: (state, action) => {
      state.ecContractLoading = action.payload;
      state.downPaymentLoading = action.payload;
    },
    setHasNoAvailableCredit: (state, { payload }) => {
      state.hasNoAvailableCredit = payload;
    },
    setN90Errors: (state, { payload }) => {
      state.n90Errors = {
        profileValidation: payload.profileValidation,
        ecValidation: payload.ecValidation,
      };
    },
    clearN90Errors: state => {
      state.n90Errors = {
        profileValidation: [],
        ecValidation: [],
      };
    },
    resetOrderData: state => {
      state.orderInfo.shippingAddress = {};
      state.orderInfo.billingAddress = {};
      state.orderInfo.payments = [];
    },
    setMoveItemsStatus: (state, { payload }) => {
      state.moveItemsStatus = payload;
    },
    setRefreshTax: (state, action) => {
      state.refreshTax = action.payload;
    },
    setFilteredProducts: (state, action) => {
      state.cartInfo.filteredProducts = action.payload;
    },
    selectFilteredProduct: (state, { payload }) => {
      state.cartInfo.cartDetails.cartItems[payload.index].selected =
        payload.value;
      state.cartInfo.selectedCounter += payload.value ? 1 : -1;
      state.cartInfo.selectedFilteredCounter += payload.value ? 1 : -1;
    },
    selectAllFilteredProducts: (state, action) => {
      state.cartInfo.filteredProducts.forEach(filteredItem => {
        const index = getOrderItemIndex(
          state.cartInfo.cartDetails.cartItems,
          filteredItem
        );
        state.cartInfo.cartDetails.cartItems[index].selected = action.payload;
      });
      state.cartInfo.selectedCounter =
        state.cartInfo.cartDetails.cartItems.filter(
          item => item.selected
        ).length;
      state.cartInfo.selectedFilteredCounter = action.payload
        ? state.cartInfo.filteredProducts.length
        : 0;
    },
    updateFilteredCounter: state => {
      state.cartInfo.selectedFilteredCounter =
        state.cartInfo.filteredProducts?.filter(item => item.selected).length;
    },
  },
});

export const {
  setAddItemLoading,
  addItem,
  resetTotals,
  deleteItemNotification,
  updateItemPrice,
  selectAllItems,
  selectCartItem,
  setCartId,
  setItemsList,
  setTotals,
  setTotalsLoading,
  updateCart,
  updateCartItem,
  updateUploadInProgress,
  reloadCart,
  resetReloadCart,
  setReloadCart,
  handleUploadErrors,
  reloadOrderItems,
  handleSaveOrderSuccess,
  resetReloadOrderItems,
  resetCartInfo,
  setShippingAndBilling,
  mergeCartItems,
  setIsECOrder,
  setAccountAndPromo,
  setDownPayment,
  setDownPaymentLoading,
  resetDownPayment,
  setContractDetails,
  setECContractLoading,
  setHasNoAvailableCredit,
  setN90Errors,
  clearN90Errors,
  setMoveItemsStatus,
  resetOrderData,
  setRefreshTax,
  setFilteredProducts,
  selectFilteredProduct,
  selectAllFilteredProducts,
  updateFilteredCounter,
} = cartSlice.actions;
export default cartSlice.reducer;
