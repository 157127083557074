import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ADMIN } from '../../../../core/navigation/roles';
import { debounce } from 'lodash';

function TypeAheadField({ getFieldOptions, debounced, field }) {
  const minLength = 2;
  const { role } = useSelector(state => state.login.userDetails);
  const isAdmin = role === ADMIN;
  const schoolsWithStudents = useSelector(state => state.schoolsWithStudents);
  const schoolListSelectedRep = schoolsWithStudents.schoolListSelectedRep;
  const schoolsListSearch = useSelector(
    state => state.studentSearch.schoolsList
  );

  const [fieldOptions, setFieldOptions] = useState([]);
  const [fieldValue, setFieldValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const currentFieldValue = useRef('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getDataDebounced = useCallback(
    debounce(value => {
      if (value?.trim()?.length > minLength) {
        setLoading(true);
        currentFieldValue.current = value;
        setFieldValue(value);
      } else {
        setLoading(false);
      }
    }, 200),
    []
  );

  useEffect(() => {
    setFieldOptions([]);
    getFieldOptions({
      isAdmin,
      schoolsWithStudents,
      schoolListSelectedRep,
      setFieldOptions,
      fieldValue,
      schoolsListSearch,
      currentFieldValue,
    });
  }, [
    fieldValue,
    getFieldOptions,
    isAdmin,
    schoolListSelectedRep,
    schoolsListSearch,
    schoolsWithStudents,
  ]);

  return {
    ...field,
    options: fieldOptions || [],
    onInputChange: event => debounced && getDataDebounced(event?.target?.value),
    loading: loading,
  };
}

export default TypeAheadField;
