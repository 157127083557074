import appStrings from '../../../../core/strings/appStrings';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import {
  studentsApprovalState,
  studentsApprovalOptionByCode,
} from '../../../../core/strings/appConstants';
import { updateStudentsStatus } from '../approveRejectService';
import { updateStudentsWithNewStatus } from '../../../../core/redux/slices/approveRejectSlice';
import { trackGAEvent } from '../../../shared/Analytics/analyticsService';

const updateDialogData = (
  setDialogData,
  open,
  status,
  count = 0,
  data = null
) => {
  const isApprove = status === studentsApprovalState.approved;
  const btnLabel = isApprove
    ? appStrings.approveReject.approveAll
    : appStrings.approveReject.rejectAll;
  const statusLabel = (
    isApprove
      ? appStrings.approveReject.approve
      : appStrings.approveReject.reject
  ).toLowerCase();

  setDialogData({
    isOpen: open,
    title: appStrings.approveReject.confirmationModalText(statusLabel, count),
    continueButtonLabel: btnLabel,
    propsToContinue: data,
  });
};

export const handleStatusUpdateHandler = ({
  studentsToUpdate,
  checkedStudents,
  setIsLoading,
  setDialogData,
  dispatch,
  status = studentsApprovalState.approved,
}) => {
  const studentsSelected =
    studentsToUpdate ??
    checkedStudents?.map(elem => ({
      studentId: elem.id,
    }));
  const continueData = {
    dispatch,
    setDialogData,
    setIsLoading,
    status,
    studentsSelected,
  };

  if (!studentsToUpdate) {
    return updateDialogData(
      setDialogData,
      true,
      status,
      studentsSelected.length,
      continueData
    );
  }
  continueStatusUpdate(continueData);
};

export const continueStatusUpdate = ({
  setIsLoading,
  setDialogData,
  dispatch,
  studentsSelected,
  status = studentsApprovalState.approved,
}) => {
  setIsLoading(true);
  updateDialogData(setDialogData, false, status);

  updateStudentsStatus({ students: studentsSelected, approval: status })
    .then(resp => {
      if (resp) {
        dispatch(
          updateStudentsWithNewStatus({
            changedStudents: studentsSelected,
            newStatus: status,
          })
        );
        studentsSelected.forEach(student => {
          trackGAEvent(
            `${
              appStrings.approveReject[studentsApprovalOptionByCode[status]]
            } ${studentsSelected.length === 1 ? 'Individually' : 'All'}`,
            'Approve and Reject',
            student.studentId
          );
        });
        return;
      }
      dispatch(displayError());
    })
    .catch(e => dispatch(displayError({ message: e })))
    .finally(() => {
      setIsLoading(false);
    });
};
