import {
  appStatuses,
  consentNotReceivedErrorMsg,
  creditTypes,
  disclosureStatuses,
  documentNotAvailableErrorMsg,
  errorMsgRetries,
  errorMsgRetriesStudent,
  fileStatuses,
  generalErrorMsg,
} from '../../../core/strings/ecFileOptions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';

import CustomBackdrop from '../CustomBackdrop/CustomBackdrop';
import CustomButton from '../CustomButton/CustomButton';
import HelloSign from 'hellosign-embedded';
import appStrings from '../../../core/strings/appStrings';
import {
  displayError,
  displayInfo,
  hideNotification,
} from '../../../core/redux/slices/notificationsSlice';

function SignComponent({
  label,
  id,
  disableSignNow,
  studentId,
  generateLink, // must return signUrl, creditAppId, signatureRequestId
  successCallback,
  errorCallback,
  openedFileCallback,
  getFileStatusCallback,
  creditType = creditTypes.creditApp,
  isStudent,
}) {
  const dispatch = useDispatch();
  const client = useRef();
  const [isLoading, setLoading] = useState(false);
  const [signatureData, setSignatureData] = useState({});
  const fileStatus = useRef(fileStatuses.none);
  const retries = useRef(0);
  const userDetails = useSelector(state => state.login.userDetails);
  const isRepOrAdmin = ['Admin', 'Rep'].indexOf(userDetails.role) !== -1;
  const preparingDocumentsNotificationId = `info${new Date().getTime()}`;

  const hidePreparingDocumentsNotification = () => {
    dispatch(hideNotification({ id: preparingDocumentsNotificationId }));
  };

  useEffect(() => {
    return () => {
      if (client.current?.isOpen) {
        unsubscribeClintEvents();
        client.current?.close();
        hidePreparingDocumentsNotification();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signingErrorHandler = ({ error, showMessage = false }) => {
    let message = generalErrorMsg;

    if (errorCallback) {
      errorCallback();
    }

    if (showMessage) {
      message = error;
    }
    hidePreparingDocumentsNotification();
    dispatch(displayError({ message }));

    client.current?.close();
  };

  const unsubscribeClintEvents = () => {
    client.current.off('cancel');
    client.current.off('close');
    client.current.off('decline');
    client.current.off('error');
    client.current.off('ready');
    client.current.off('sign');
  };

  const handleStatusResponse = resp => {
    if (resp.retry) {
      if (retries.current < 5) {
        return pollingForStatus();
      }

      signingErrorHandler({
        error: isRepOrAdmin ? errorMsgRetries : errorMsgRetriesStudent,
        showMessage: true,
      });
    } else if (
      resp.statusId === appStatuses.Submitted ||
      resp.status === disclosureStatuses.signed ||
      resp.status === appStatuses.Submitted
    ) {
      hidePreparingDocumentsNotification();
      successCallback(signatureData.creditAppId, studentId);
    }
  };

  const pollingForStatus = () => {
    retries.current = retries.current + 1;

    getFileStatusCallback(signatureData.creditAppId)
      .then(handleStatusResponse)
      .catch(signingErrorHandler);
  };

  function handleDecline() {
    let message = documentNotAvailableErrorMsg;

    if (creditType === creditTypes.disclosure) {
      message = consentNotReceivedErrorMsg;
    }

    if (creditType.length && errorCallback) {
      errorCallback();
    }

    dispatch(displayError({ message }));
  }

  const handleFinishSigning = () => {
    if (fileStatus.current === fileStatuses.signed) {
      if (getFileStatusCallback && creditType !== creditTypes.ecOrder) {
        retries.current = 0;
        pollingForStatus();
      } else if (creditType === creditTypes.ecOrder) {
        openUrl();
      } else {
        successCallback(signatureData.creditAppId, studentId);
      }
    } else if (fileStatus.current === fileStatuses.declined) {
      handleDecline();
    }
  };

  const handleGenerateLinkResponse = resp => {
    if (resp.signUrl) {
      setSignatureData(resp);
      hidePreparingDocumentsNotification();

      client.current.open(resp.signUrl);

      client.current.on('ready', () => {
        fileStatus.current = fileStatuses.open;

        dispatch(
          displayInfo({
            message: appStrings.order.preparingDocuments,
            permanent: true,
            id: preparingDocumentsNotificationId,
          })
        );
        if (openedFileCallback) {
          openedFileCallback();
        }
      });

      client.current.on('sign', () => {
        fileStatus.current = fileStatuses.signed;
      });

      client.current.on('error', (...resp) => {
        signingErrorHandler(resp);

        fileStatus.current = fileStatuses.error;
        hidePreparingDocumentsNotification();
      });

      client.current.on('close', () => {
        if (fileStatus.current === fileStatuses.open) {
          fileStatus.current = fileStatuses.closed;
        }

        if (
          fileStatus.current === fileStatuses.signed ||
          fileStatus.current === fileStatuses.declined
        ) {
          handleFinishSigning();
        }

        unsubscribeClintEvents();
      });

      client.current.on('decline', () => {
        fileStatus.current = fileStatuses.declined;
        hidePreparingDocumentsNotification();
      });

      client.current.on('cancel', () => {
        fileStatus.current = fileStatuses.canceled;
        unsubscribeClintEvents();
        hidePreparingDocumentsNotification();
      });
    } else {
      // handleFinishSigning();
      retries.current = 0;
      pollingForStatus();
    }
  };

  const openUrl = () => {
    setLoading(true);
    generateLink()
      .then(resp => {
        if (resp) {
          handleGenerateLinkResponse(resp);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const isSingingTestMode = process.env.REACT_APP_SIGN_TEST_MODE === 'true';
    const signingOptions = {
      clientId: process.env.REACT_APP_SIGN_CLIENT_ID,
    };

    if (isSingingTestMode) {
      signingOptions.testMode = isSingingTestMode;
      signingOptions.skipDomainVerification = isSingingTestMode;
    }
    client.current = new HelloSign(signingOptions);
    return unsubscribeClintEvents;
  }, []);

  return (
    <>
      <CustomButton
        label={label ?? appStrings.wizard.signNow}
        disabled={isLoading || disableSignNow}
        id={id ?? 'openSigningBtn'}
        onClick={openUrl}
      />

      <CustomBackdrop open={isLoading} />
    </>
  );
}

export default SignComponent;
