import { makeStyles } from 'tss-react/mui';

const useOrderHistoryDetailsStyles = makeStyles()(theme => ({
  accordion: {
    padding: '8px 16px 16px !important',
  },
  customBox: {
    padding: '8px 16px 16px !important',
  },
  sectionTitle: {
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: '10px',
  },
  highlight: {
    textTransform: 'capitalize',
    fontWeight: theme.typography.fontWeightBold,
  },
  greenText: {
    color: theme.palette.common.green,
    fontWeight: theme.typography.fontWeightBold,
  },
  redText: {
    color: theme.palette.common.red,
    fontWeight: theme.typography.fontWeightBold,
  },
  yellowText: {
    color: theme.palette.common.yellow,
    fontWeight: theme.typography.fontWeightBold,
  },
  link: {
    textDecoration: 'underline',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.secondary,

    '&:hover': {
      cursor: 'pointer',
    },
  },
  hidden: {
    display: 'none',
  },
  orderLinesTable: {
    tableLayout: 'fixed',

    '& .MuiTableCell-root': {
      wordBreak: 'break-word',
    },
  },
}));

export default useOrderHistoryDetailsStyles;
