import { floor } from 'lodash';
import { getFormattedValue } from '../../../industrial/PurchasePower/purchasePowerService';

export const formatPurchaseValues = values => {
  let newValues = { maximumValue: getFormattedValue(12000) };

  for (const val in values) {
    newValues[val] = getFormattedValue(values[val]);
  }

  return newValues;
};

export const getProgressLabelPaddingLeft = (
  progressPercentage,
  widthProgressLine,
  widthProgressLabel
) => {
  const labelMidPosition =
    progressPercentage.currentValue / 2 + progressPercentage.purchasedValue;
  const labelPercentageWidth = (widthProgressLabel * 100) / widthProgressLine;
  const maxPadding = 100 - labelPercentageWidth;
  const padding = labelMidPosition - labelPercentageWidth / 2;

  return padding > maxPadding ? `${maxPadding}%` : `${padding}%`;
};

export const getProgressLinePercentage = (value, total) => {
  let percentage = floor((value * 100) / total, 2);

  if (percentage > 100) {
    return 100;
  }
  if (percentage < 0) {
    return 0;
  }

  return percentage;
};
