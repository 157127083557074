import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import appStrings from '../../../../core/strings/appStrings';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import { getSOCAccountInfo } from './SOCAccountInfoService';
import StandardFormSkeleton from '../../CustomSkeleton/StandardFormSkeleton';
import CustomBox from '../../CustomBox/CustomBox';
import useSOCAccountInfoStyles from './SOCAccountInfoStyles';
import { sepLinks } from '../../../../core/strings/appConstants';
import NoDataAvailable from '../../NoDataAvailable/NoDataAvailable';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';

function SOCAccountInfo(props) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { isStudent, customerInfo } = props;
  const css = useSOCAccountInfoStyles().classes;
  const [isLoading, setIsLoading] = useState(false);
  const [debtorNumber, setDebtorNumber] = useState('');
  const [accountInfoDetails, setAccountInfoDetails] = useState([]);
  const [selectedAccountDetails, setSelectedAccountDetails] = useState();

  const handleChange = e => {
    setDebtorNumber(e.target.value);
    setSelectedAccountDetails(
      accountInfoDetails.find(item => item.debtorNumber === e.target.value)
    );
  };

  const getProductTypeDisplay = () => {
    return selectedAccountDetails?.productType !== ''
      ? selectedAccountDetails?.productType
      : '-';
  };

  const getAvailableCreditDisplay = () => {
    const availableCredit = selectedAccountDetails?.o2B;
    return isNaN(parseFloat(availableCredit))
      ? '-'
      : `$${availableCredit.toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })}`;
  };
  useEffect(() => {
    if (!accountInfoDetails.length && Object.keys(customerInfo ?? {}).length > 0) {
      const studentSSN = customerInfo.socialSecurityNumber === '' ? null : btoa(customerInfo.socialSecurityNumber);
      setIsLoading(true);
      getSOCAccountInfo(id, studentSSN)
        .then(res => {
          if (res.length > 0) {
            setAccountInfoDetails(res);
            setDebtorNumber(res[0].debtorNumber);
            setSelectedAccountDetails(res[0]);
          }
        })
        .catch(e => dispatch(displayError({ message: e })))
        .finally(() => setIsLoading(false));
    }
  }, [accountInfoDetails.length, dispatch, id, customerInfo]);

  return (
    <Grid>
      {isLoading && <StandardFormSkeleton />}

      {!isLoading && accountInfoDetails.length > 0 && (
        <CustomBox fadeBorder>
          <Grid className={css.socAccountInfoContainer} container spacing={6}>
            <Grid item xs={6} lg={4}>
              <FormControl size={isStudent ? 'medium' : 'small'} fullWidth>
                <InputLabel id="debtor-number-label">
                  {appStrings.studentProfile.debtorNumber}
                </InputLabel>
                <Select
                  labelId="debtor-number-label"
                  id="debtor-number"
                  value={debtorNumber}
                  label={appStrings.studentProfile.debtorNumber}
                  onChange={handleChange}
                >
                  {accountInfoDetails.map(item => (
                    <MenuItem key={item.debtorNumber} value={item.debtorNumber}>
                      {item.debtorNumber}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={6}>
                <Typography fontWeight="bold">
                  {appStrings.studentProfile.productType}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight="bold">
                  {appStrings.studentProfile.availableCredit}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{getProductTypeDisplay()}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{getAvailableCreditDisplay()}</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography fontWeight="bold">
                  <a rel="noreferrer" target="_blank" href={sepLinks.SOCUrl}>
                    {appStrings.studentProfile.snapOnCredit}
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CustomBox>
      )}
      {!isLoading && accountInfoDetails.length === 0 && (
        <NoDataAvailable
          message={appStrings.studentProfile.noInfo}
          iconElement={<ReceiptLongOutlinedIcon />}
        />
      )}
    </Grid>
  );
}

export default SOCAccountInfo;
