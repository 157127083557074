import { makeStyles } from 'tss-react/mui';

const useCartViewDetailsStyles = makeStyles()(theme => ({
  alertContainer: {
    '& .MuiAlert-root:not(:last-of-type)': {
      marginBottom: theme.spacing(1.5),
    },
    marginBottom: theme.spacing(3),
  },
  childContainer: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  },
  detailsContainer: {
    padding: `15px ${theme.spacing(10)}`,
    boxSizing: 'border-box',
  },
  notesContainer: {
    '& .MuiFormHelperText-root': {
      display: 'none',
    },
    marginBottom: theme.spacing(2),
  },
  actionButton: {
    background: `${theme.palette.common.neutral2} !important`,
  },
  bottomLabel: {
    float: 'left',
    marginRight: '50px',
  },
  bottomFieldContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  bottomInputContainer: {
    width: '60%',
    height: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    '& .MuiInputBase-root': {
      background: theme.palette.common.neutral2,
    },
  },
  serialNumber: {
    '& .MuiInputBase-root, input': { cursor: 'pointer' },
    '& .MuiInputAdornment-root p': {
      color: theme.palette.common.darkGrey,
      fontWeight: 500,
      fontSize: theme.typography.h2.fontSize,
      marginBottom: '8px',
    },
    '& >div >p': {
      display: 'none',
    },
  },
  warehouseLoader: {
    height: '100%',
  },
  auditUpdate: {
    '& .MuiAlert-icon': {
      backgroundColor: `${theme.palette.common.secondary} !important`,
    },
  },
}));

export default useCartViewDetailsStyles;
