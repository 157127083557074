import { makeStyles } from 'tss-react/mui';

const usePasswordValidationStyles = makeStyles()(theme => ({
  validationContainer: {
    marginTop: `-${theme.spacing(1)}`,
  },
  statusIcon: {
    marginBottom: '-5px',
    padding: `0 ${theme.spacing(1)}`,
  },
  validationMessage: {
    padding: `${theme.spacing(1)}`,
  },
}));

export default usePasswordValidationStyles;
