import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  paymentCodes,
  paymentErrors,
  propTypes,
} from './paymentDialogConstants';

import CustomDialog from '../CustomDialog/CustomDialog';
import PaymentDialogSkeleton from './PaymentDialogSkeleton';
import amex from '../../../core/assets/img/cvvamex.jpg';
import appStrings from '../../../core/strings/appStrings';
import { displayError } from '../../../core/redux/slices/notificationsSlice';
import { getPaymentIdentifier } from './paymentDialogService';
import urls from '../../../core/strings/urls';
import { useDispatch } from 'react-redux';
import usePaymentDialogStyles from './usePaymentDialogStyles';
import visa from '../../../core/assets/img/cvvvisa.jpg';

function PaymentDialog({ onCancel, onPaymentComplete, onError }) {
  const dispatch = useDispatch();
  const [errorCodes, setErrorCodes] = useState([]);
  const css = usePaymentDialogStyles().classes;
  const [uId, setUId] = useState(null);
  const url = `${urls.paymentUrl}${uId}`;
  const [showCvv, setShowCvv] = useState(false);
  const [showLoading, setShowLoading] = useState(true);

  const cvvButtons = [
    {
      label: appStrings.common.ok,
      onClick: () => setShowCvv(false),
    },
  ];

  //exposing payment methods to the global scope so the iframe can communicate
  window.completeCREPayment = resp => {
    onPaymentComplete(resp);
  };
  window.cancelCREPayment = () => onCancel();
  window.whatCVV2 = () => {
    setShowCvv(true);
  };
  window.creHandleErrors = errors => {
    let codes;
    if (errors.includes('|')) {
      codes = errors.split('|');
      codes.pop();
    } else {
      codes = [errors];
    }

    setErrorCodes(codes);
    if (codes.includes(paymentCodes.failedPayment)) {
      getPaymentIdentifier().then(res => setUId(res));
    }
    onError(errors);
  };

  useEffect(() => {
    const beforeUnload = e => e.preventDefault();

    window.addEventListener('beforeunload', beforeUnload);

    return () => window.removeEventListener('beforeunload', beforeUnload);
  });

  useEffect(() => {
    getPaymentIdentifier()
      .then(res => setUId(res))
      .catch(() => {
        dispatch(displayError());
        onCancel();
      })
      .finally(() => setShowLoading(false));
  }, [dispatch, onCancel]);

  return (
    <>
      <CustomDialog
        open
        title={appStrings.common.creditCard}
        message={
          <>
            <Grid item xs={12}>
              {showLoading ? <PaymentDialogSkeleton /> : null}
              {!uId ? null : (
                <iframe
                  title="Payment Dialog"
                  src={url}
                  id="paymentDialog"
                  className={css.paymentFrame}
                  frameBorder="0"
                />
              )}
            </Grid>
            <Grid item xs={12} className={css.errorsContainer}>
              {errorCodes.length
                ? errorCodes?.map(elem => (
                    <Typography key={`error-${elem}`}>
                      {paymentErrors[elem]}
                    </Typography>
                  ))
                : null}
            </Grid>
          </>
        }
        customClass={css.creditCardDialog}
      />
      <CustomDialog
        open={showCvv}
        message={
          <Grid container>
            <Grid item container xs={12} className={css.cvvFirstSection}>
              <Grid item xs={7}>
                <img src={visa} alt="Visa CVV" />
              </Grid>
              <Grid item xs={5}>
                <Typography className={css.cvvTitle}>
                  <strong>{appStrings.creditCard.cvvVisaTitle}</strong>
                </Typography>
                <Typography>{appStrings.creditCard.cvvVisaText}</Typography>
              </Grid>
            </Grid>

            <Grid item container xs={12}>
              <Grid item xs={7}>
                <img src={amex} alt="American Express CVV" />
              </Grid>
              <Grid item xs={5}>
                <Typography className={css.cvvTitle}>
                  <strong>{appStrings.creditCard.cvvAmericanTitle}</strong>
                </Typography>
                <Typography>{appStrings.creditCard.cvvAmericanText}</Typography>
              </Grid>
            </Grid>
          </Grid>
        }
        buttons={cvvButtons}
        customClass={css.cvvDialog}
      />
    </>
  );
}

PaymentDialog.propTypes = propTypes;

export default PaymentDialog;
