import { Grid, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import ItemsButtonsSkeleton from './ItemsButtonsSkeleton';
import useShoppingCartStyles from '../shoppingCartStyles';

function ItemsContentSkeleton() {
  const theme = useTheme();
  const isXSDevice = useMediaQuery(theme.breakpoints.only('xs'));
  const isSmallDevice = useMediaQuery('(max-width:768px)');
  const css = useShoppingCartStyles(isSmallDevice).classes;

  const itemImg = {
    variant: 'rounded',
    height: isXSDevice ? '104px' : '132px',
    width: '178px',
  };

  const variant = {
    text: 'text',
  };

  return (
    <Grid
      container
      className={css.cartItems}
      id="ShoppingCart-itemsContentSkeleton"
      data-test-id="ShoppingCart-itemsContentSkeletonTestId"
    >
      <Grid item>
        <Skeleton {...itemImg} />
      </Grid>
      <Grid item container xs paddingLeft={2}>
        <Grid item container direction="column" xs={12}>
          <Skeleton variant={variant.text} width="60%" />
          <Skeleton variant={variant.text} width="40%" />
        </Grid>
        <Grid item xs={isSmallDevice ? 12 : 6}>
          <Skeleton
            variant={variant.text}
            width={isSmallDevice ? '30%' : '0%'}
          />
          <Skeleton variant={variant.text} width="40%" />
        </Grid>
        {!isSmallDevice && (
          <Grid item container direction="column" alignItems="flex-end" xs={6}>
            <Skeleton variant={variant.text} width="20%" />
            <Skeleton variant={variant.text} width="30%" />
          </Grid>
        )}
        {!isXSDevice && <ItemsButtonsSkeleton />}
      </Grid>
      {isXSDevice && <ItemsButtonsSkeleton />}
    </Grid>
  );
}

export default ItemsContentSkeleton;
