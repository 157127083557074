import PropTypes from 'prop-types';

export const propTypes = {
  student: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }),
  showMoreMenu: PropTypes.bool,
  moreMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      handler: PropTypes.func,
      icon: PropTypes.string,
      id: PropTypes.string,
      isDisabled: PropTypes.bool,
      label: PropTypes.string,
    })
  ),
  children: PropTypes.node,
};
