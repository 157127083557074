import {
  CART,
  ORDER,
  STUDENT_PROFILE,
} from '../../../../core/navigation/paths';
import { Grid, Link } from '@mui/material';

import CustomButton from '../../../shared/CustomButton/CustomButton';
import PropTypes from 'prop-types';
import React from 'react';
import appStrings from '../../../../core/strings/appStrings';
import { buttonColors, phoneMask } from '../../../../core/strings/appConstants';
import classNames from 'classnames';
import { selectedStudent } from '../../../../core/redux/slices/schoolsWithStudentsSlice';
import { useDispatch } from 'react-redux';
import useStudentStyles from './useStudentStyles';
import { useNavigate } from 'react-router';
import NumberFormat from 'react-number-format';

const propTypes = {
  student: PropTypes.object.isRequired,
  bpNumber: PropTypes.string.isRequired,
  firstStudentRef: PropTypes.any,
  selectedStudentId: PropTypes.string,
  setSelectedStudentId: PropTypes.func,
  index: PropTypes.number,
};
const studentLayout = { xs: 12, sm: 3, md: 3, lg: 3 };

function Student({
  bpNumber,
  student,
  firstStudentRef,
  selectedStudentId,
  setSelectedStudentId,
  index,
}) {
  const css = useStudentStyles().classes;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectStudent = () => {
    dispatch(selectedStudent({ bpNumber, student }));
    setSelectedStudentId(student.id);
  };

  const goToCart = () => {
    navigate(CART(bpNumber ?? student.bpNumber, student.id));
  };

  const goToOrder = () => {
    navigate(ORDER(bpNumber ?? student.bpNumber, student.id));
  };

  const goToProfile = () => {
    navigate(STUDENT_PROFILE(bpNumber ?? student.bpNumber, student.id));
  };

  return (
    <Grid
      id={`studentRow-${index}`}
      container
      justifyContent="space-between"
      alignItems="center"
      className={classNames(
        css.studentContainer,
        selectedStudentId === student.id ? css.selected : ''
      )}
      ref={firstStudentRef}
      data-status={student.active ? 'active' : 'inactive'}
      onClick={selectStudent}
    >
      <Grid item {...studentLayout}>
        <Link
          id={`studentName-${index}`}
          onClick={goToProfile}
          className={classNames(
            css.link,
            student.active ? '' : [css.inactive, css.inactiveHover]
          )}
        >{`${student.firstName} ${student.lastName}`}</Link>
      </Grid>
      <Grid
        id={`studentEmail-${index}`}
        {...studentLayout}
        item
        className={classNames(
          css.emailContainer,
          student.active ? '' : css.inactive
        )}
      >
        {student.email}
      </Grid>
      <Grid
        id={`studentPhoneNo-${index}`}
        {...studentLayout}
        item
        className={classNames(
          css.phoneNoContainer,
          student.active ? '' : css.inactive
        )}
      >
        <NumberFormat
          format={phoneMask}
          value={student.phoneNumber}
          displayType="text"
        />
      </Grid>
      <Grid item xs={'auto'} className={css.buttonsContainer}>
        <CustomButton
          id={`studentGoToCart-${index}`}
          color={buttonColors.secondary}
          label={appStrings.studentsPage.goToCart}
          onClick={goToCart}
          size="small"
        />
        <CustomButton
          id={`studentGoToOrder-${index}`}
          label={appStrings.studentsPage.goToOrder}
          onClick={goToOrder}
          size="small"
        />
      </Grid>
    </Grid>
  );
}

Student.propTypes = propTypes;

export default Student;
