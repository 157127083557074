import { Grid, Typography } from '@mui/material';

import CustomIcon from '../CustomIcon/CustomIcon';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import useNoDataAvailableStyle from './useNoDataAvailableStyles';

const propTypes = {
  helperText: PropTypes.any,
  icon: PropTypes.string,
  message: PropTypes.any.isRequired,
  size: PropTypes.string,
  iconElement: PropTypes.element,
};

function NoDataAvailable({
  message,
  icon,
  size = 'normal',
  helperText,
  iconElement = null,
  id,
}) {
  const css = useNoDataAvailableStyle().classes;
  const isLargeIcon = size === 'large';
  const iconClasses = classNames(css.icon, {
    [css.largeIcon]: isLargeIcon,
  });

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      data-test-id="noDataAvailable"
      className={css.noDataContainer}
      id="noDataContainer"
    >
      <Grid item xs={12} id="noDataIconContainer">
        {icon ? (
          <CustomIcon icon={icon} customClasses={iconClasses} />
        ) : (
          <span className={iconClasses}>{iconElement}</span>
        )}
      </Grid>
      <Grid item xs={12} id="noDataMessageContainer">
        <Typography variant="h2" className={css.message} id="noDataMessage">
          {message}
        </Typography>
      </Grid>
      {helperText ? (
        <Grid item xs={12} id="noDataHelperTextContainer">
          <Typography
            variant="h3"
            className={classNames(css.message, css.helperText)}
          >
            {helperText}
          </Typography>
        </Grid>
      ) : null}
    </Grid>
  );
}

NoDataAvailable.propTypes = propTypes;

export default NoDataAvailable;
