import { makeStyles } from 'tss-react/mui';

const useItemAvailabilityStyles = makeStyles()(theme => ({
  noAvailability: {
    color: theme.palette.common.grey,
    maxWidth: '350px',
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    margin: theme.spacing(6),
  },
  title: {
    '& .MuiTypography-h6': {
      fontSize: theme.typography.h2.fontSize,
      fontWeight: theme.typography.fontWeightBold,
    },
    background: theme.palette.common.secondary6,
  },
  contentContainer: {
    paddingTop: '10px',
  },
  warehouseTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  alertContainer: {
    marginBottom: theme.spacing(1),
  },
  card: {
    marginBottom: theme.spacing(1),
  },
  cardContent: {
    paddingBottom: `${theme.spacing(1)} !important`,
    paddingTop: `${theme.spacing(1)} !important`,
    '& span': {
      float: 'left',
    },
    '& span:not(:last-of-type)': {
      marginRight: theme.spacing(3),
    },
    width: '400px',
  },
  dataContainer: {
    display: 'flex',
    marginTop: theme.spacing(1),
    overflowY: 'auto',
    whiteSpace: 'nowrap',
    justifyContent: 'space-between',
  },
  firstRow: {
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    float: 'right',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  skeleton: {
    minWidth: '300px',
  },
  dialogContainer: {
    '& .MuiPaper-elevation24': {
      boxShadow: 'none',
    },
  },
  closeButton: {
    padding: '0px',
    right: theme.spacing(1),
    color: theme.palette.common.black,
    float: 'right',
  },
}));

export default useItemAvailabilityStyles;
