import PropTypes from 'prop-types';

export const propTypes = {
  control: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.any.isRequired,
  getValues: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export const gridLayout = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 4,
};

export const studentGridLayout = {
  xs: 12,
  sm: 6,
  md: 6,
  lg: 4,
};
