import ApiService from '../../../shared/Api/apiService';
import urls from '../../../../core/strings/urls';

export const getSchools = ({
  id,
  AdminSchools,
  startIndex = 1,
  pageSize = 15,
}) => {
  const url = !AdminSchools
    ? `${urls.getSchoolsByEmployee}${id}`
    : urls.getSchoolsPagedByState(
        startIndex * pageSize - pageSize + 1,
        pageSize
      );

  return ApiService.get(url).then(schoolsList => {
    return schoolsList;
  });
};

export const getSchoolsLength = () => {
  const url = urls.getSchoolsLength;

  return ApiService.get(url).then(length => {
    return length;
  });
};
