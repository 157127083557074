import appStrings from '../../../../core/strings/appStrings';

export const maxLengthOfPreviousAddresses = 13;
export const gridLayout = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 4,
};
export const studentGridLayout = {
  xs: 12,
  sm: 6,
  md: 6,
  lg: 4,
};

export const addressTypes = {
  '-1': 'none',
  0: 'permanentAddress',
  1: 'additionalAddress',
  2: 'currentAddress',
  3: 'previousAddresses',
};
export const addressTypesByLabel = {
  none: '-1',
  permanentAddress: 0,
  additionalAddress: 1,
  currentAddress: 2,
  previousAddresses: 3,
};

export const addressTypeLabels = {
  '-1': appStrings.addresses.none,
  0: appStrings.addresses.permanentHome,
  1: appStrings.addresses.additional,
  2: appStrings.addresses.current,
  3: appStrings.addresses.previous,
};
