import { Divider, Grid, Tooltip, Typography } from '@mui/material';

import CustomDropDownMenu from '../../shared/CustomDropDownMenu/CustomDropDownMenu';
import appStrings from '../../../core/strings/appStrings';
import { propTypes } from './nameCardConstants';
import useNameCardStyles from './useNameCardStyles';
import CustomButton from '../../shared/CustomButton/CustomButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import classNames from 'classnames';
import ResendToExchange from '../ResendToExchange/ResendToExchange';

function NameCard({
  children,
  moreMenuItems,
  student,
  showMoreMenu = false,
  showDeleteButton = false,
  deleteCallback = () => {},
  showCompleteOrderButton = false,
  completeOrderButtonDisabled = false,
  completeOrderTooltip = '',
  completeOrderCallback = () => {},
  showName = true,
  disabled,
  isRegisterConfirmation = false,
  // TODO group delete/complete order related params into object params
  resendToExchange = {
    isDisplayed: false,
    orderNumber: null,
    callback: () => {},
  },
}) {
  const css = useNameCardStyles().classes;
  return (
    <Grid
      className={classNames(
        css.container,
        student?.active || isRegisterConfirmation
          ? ''
          : [css.inactiveStudentLink]
      )}
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      data-test-id="nameCard"
    >
      {showName ? (
        <Grid item xs={6}>
          <Typography
            variant="h2"
            className={classNames(
              css.name,
              student?.active ? '' : [css.inactiveStudent]
            )}
            data-test-id="nameCardStudentName"
            id="studentNameTitle"
          >
            {student?.firstName} {student?.lastName}
          </Typography>
        </Grid>
      ) : null}
      {children}
      <Grid item container xs={6} spacing={2} justifyContent="flex-end">
        {showDeleteButton && (
          <Grid
            item
            data-test-id="nameCardDeleteButton"
            id="nameCardDeleteButton"
          >
            <CustomButton
              customClass={css.smallButton}
              btnType="secondary"
              label={<DeleteOutlineIcon />}
              disabled={disabled}
              onClick={deleteCallback}
            />
          </Grid>
        )}
        {resendToExchange?.isDisplayed && (
          <Grid
            item
            data-test-id="nameCardResendToExchangeButton"
            id="nameCardResendToExchangeButton"
          >
            <ResendToExchange
              orderNumber={resendToExchange.orderNumber}
              callback={resendToExchange.callback}
            />
          </Grid>
        )}
        {showMoreMenu && (
          <Grid item data-test-id="nameCardMoreButton" id="nameCardMoreButton">
            <CustomDropDownMenu
              customClass={css.smallButton}
              buttonType="secondary"
              color="greyBlue"
              menuItems={moreMenuItems}
              menuLabel={<MoreVertIcon />}
              menuId={'quoteMoreActionButton'}
              disabled={disabled}
            />
          </Grid>
        )}
        {showCompleteOrderButton && (
          <>
            <Grid item>
              <Divider
                orientation="vertical"
                flexItem
                className={css.divider}
              />
            </Grid>
            <Grid
              item
              data-test-id="nameCartCompleteOrderButton"
              id="nameCartCompleteOrderButton"
            >
              <Tooltip
                placement="left"
                label={appStrings.order.completeOrder}
                arrow
                id="completeOrderBtn"
                title={completeOrderTooltip}
                enterTouchDelay={0}
              >
                <div>
                  <CustomButton
                    label={appStrings.order.completeOrder}
                    disabled={disabled || completeOrderButtonDisabled}
                    onClick={completeOrderCallback}
                  />
                </div>
              </Tooltip>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}

NameCard.propTypes = propTypes;

export default NameCard;
