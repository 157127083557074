import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { useOrderDetailsStyles } from '../orderDetailsStyles';

function TrackingNumbersSkeleton() {
  const css = useOrderDetailsStyles().classes;

  return (
    <Table data-test-id="trackingNumbersSkeletonTableTestId">
      <TableHead>
        <TableRow>
          <TableCell>
            <Skeleton variant="rounded" />
          </TableCell>
          <TableCell>
            <Skeleton variant="rounded" />
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody className={css.tableBodySkeleton}>
        {Array(3)
          .fill(true)
          .map((_, index) => (
            <TableRow
              key={index}
              id={`trackingNumberSkeleton-${index}`}
              data-test-id="trackingNumberSkeleton"
            >
              <TableCell>
                <Skeleton variant="rounded" />
              </TableCell>
              <TableCell>
                <Skeleton variant="rounded" />
              </TableCell>
              <TableCell>
                <Skeleton variant="circular" width="auto" />
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}

export default TrackingNumbersSkeleton;
