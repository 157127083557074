export const shouldGetStudents = (
  finalId,
  isAdmin,
  approveReject,
  schoolsWithStudentsState,
  approveRejectState
) => {
  return (
    isAdmin &&
    ((!approveReject && finalId !== schoolsWithStudentsState) ||
      (approveReject && finalId !== approveRejectState))
  );
};
