import { makeStyles } from 'tss-react/mui';

const useTotalsStyles = makeStyles()(theme => ({
  totalsSkeletonContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  gridContainer: {
    margin: 0,
    width: 'auto',
  },
  totalsSection: {
    position: 'relative',
    borderLeft: `1px solid ${theme.palette.common.veryLightGrey}`,
    maxWidth: '40% !important',
    margin: '14px 14px 10px 20px',
    paddingTop: '0 !important',
  },
  grandTotal: {
    borderTop: `1px solid ${theme.palette.common.veryLightGrey}`,
    marginTop: '8px',
  },
  quoteTotalsLabel: {
    marginBottom: '16px',
  },
  purchasePowerContainer: {
    marginTop: 0,
    marginBottom: 'auto',
    paddingTop: '14px',
  }
}));

export default useTotalsStyles;
