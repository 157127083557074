import CONSTANTS from '../constants';
import { createSlice } from '@reduxjs/toolkit';
import { studentsApprovalStateByCode } from '../../strings/appConstants';

const sortByStatusApproval = (state, students) => {
  const newSortedList = {
    approved: [],
    rejected: [],
    pending: [],
  };

  if (students) {
    students.forEach(element => {
      newSortedList[studentsApprovalStateByCode[element.approval]].push(
        element
      );
    });
  }

  return (state.sortedList = newSortedList);
};

const approveRejectSlice = createSlice({
  name: CONSTANTS.approveReject,
  initialState: {
    studentsList: null,
    sortedList: {
      approved: [],
      rejected: [],
      pending: [],
    },
    checkedStudents: [],
    newStatusStudents: false,
    newStudentRegistered: false,
    approveRejectRep: null,
    employeeIdSearched: null,
    bpNumberSearched: null,
    repList: null,
    schoolsList: null,
    selectedSchool: null,
  },
  reducers: {
    // payload: []; - list of students
    saveStudentsListApproveReject: (state, { payload }) => {
      state.studentsList = payload?.resp;
      state.employeeIdSearched = payload?.employeeIdSearched || null;
      sortByStatusApproval(state, payload?.resp);
    },
    // payload: { reset: Boolean, students: Array};
    updateCheckedStudent: (state, { payload }) => {
      if (payload.reset) {
        state.checkedStudents = [];
        return;
      }

      // if user clicks select all, checked list becomes list
      if (payload.students.length > 1) {
        state.checkedStudents = payload.students;
        return;
      }

      // if user clicks individual checkboxes
      const elementIndex = state.checkedStudents.findIndex(
        elem => elem.id === payload.students[0].id
      );

      if (elementIndex === -1) {
        state.checkedStudents.push(payload.students[0]);
        return;
      }

      state.checkedStudents.splice(elementIndex, 1);
    },
    // payload: { changedStudents: [{ studentId: '' }], newStatus: OneOf[studentsApprovalState]};
    updateStudentsWithNewStatus: (state, { payload }) => {
      payload.changedStudents?.forEach(elem => {
        const foundIndex = state.studentsList.findIndex(
          student => student.id === elem.studentId
        );
        state.studentsList[foundIndex].approval = payload.newStatus;
      });

      sortByStatusApproval(state, state.studentsList);
      state.checkedStudents = [];
      state.newStatusStudents = true;
    },
    // payload: {approvedRejected: Boolean}
    updateNewStatusStudents: (state, { payload }) => {
      state.newStatusStudents = payload.approvedRejected;
    },
    //payload: {approved: Boolean}
    updateNewStudentRegistered: (state, { payload }) => {
      state.newStudentRegistered = payload;
    },
    setApproveRejectRep: (state, action) => {
      state.approveRejectRep = action.payload;
    },
    updateApproveRejectRepList: (state, action) => {
      state.repList = action.payload;
    },
    setSchoolsList: (state, action) => {
      state.schoolsList = action.payload;
    },
    setBpNumberSearched: (state, action) => {
      state.bpNumberSearched = action.payload;
    },
    setSelectedSchool: (state, action) => {
      state.selectedSchool = action.payload;
    },
  },
});

export const {
  saveStudentsListApproveReject,
  updateCheckedStudent,
  updateStudentsWithNewStatus,
  updateNewStatusStudents,
  updateNewStudentRegistered,
  setApproveRejectRep,
  updateApproveRejectRepList,
  setSchoolsList,
  setBpNumberSearched,
  setSelectedSchool,
} = approveRejectSlice.actions;

export default approveRejectSlice.reducer;
