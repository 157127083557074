import React from 'react';
import useCustomAccordionContainerStyles from './useCustomAccordionContainerStyles';

function CustomAccordionContainer({ children }) {
  const css = useCustomAccordionContainerStyles().classes;

  return <div className={css.accordionContainer}>{children}</div>;
}

export default CustomAccordionContainer;
