import { makeStyles } from 'tss-react/mui';

export const useOrdersStyles = makeStyles()(theme => ({
  orderContainer: {
    '& .MuiChip-root': {
      borderRadius: theme.spacing(1),
    },

    '& .MuiTypography-root': {
      color: theme.palette.common.grey1,
    },

    '& strong': {
      color: theme.palette.text.primary,
      fontSize: 'medium',
    },
  },

  viewDetailsStyle: {
    color: `${theme.palette.common.blue1} !important`,
    padding: `0px !important`,
    textAlign: 'end',
  },

  currencyStyle: {
    color: `${theme.palette.common.red} !important`,
  },
}));
