import appStrings from './appStrings';

export const loadingStatuses = {
  pending: 'pending',
  idle: 'idle',
};

export const buttonColors = {
  '': '',
  green: 'green',
  yellow: 'yellow',
  red: 'red',
  black: 'black',
  secondary: 'secondary',
  eucalyptus: 'eucalyptus',
};

export const buttonTypes = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  default: 'default',
};

export const sepIndustrialFont = 'sep-industrial-font';
export const ssnMask = '###-##-####';
export const phoneMask = '(###) ###-####';
export const snaponDomain = 'snapon.com';
export const dateFormat = 'MM/DD/YYYY';

export const connectionStatus = {
  connecting: 1,
  error: 2,
  started: 10,
  stopped: 0,
};

export const connectionResponseState = {
  done: 3,
  error: 2,
  inProgress: 1,
  none: 0,
};

export const speedDialColors = {
  red: 'red',
};
export const historyTransactionType = {
  ecApplication: 2,
  ecOrder: 3,
  none: 0,
  order: 1,
};

export const historyTransactionTypeByCode = {
  0: 'All',
  1: 'Sale Order',
  2: 'EC Application',
  3: 'EC Order',
};

export const historySortBy = {
  date: 2,
  none: 0,
  number: 3,
  placedBy: 6,
  status: 4,
  total: 5,
  type: 1,
};

export const historySortDirection = {
  none: 0,
  ascending: 1,
  descending: 2,
};

export const ecOrderStatusesByCode = {
  0: 'None',
  1: 'Open',
  2: 'Pending',
  3: 'Submitted',
  4: 'Approved',
  5: 'Declined',
  6: 'Sending in progress',
  7: 'Error',
  8: 'Recovered',
  9: 'Deleted',
  10: 'Completed',
  11: 'Open ',
  16: 'Signing',
  20: 'CIC Error',
  21: 'SOC Error',
  22: 'Signed',
  23: 'Not Signed',
};
export const ecOrderStatusesByLabel = {
  none: 0,
  open: 1,
  pending: 2,
  submitted: 3,
  approved: 4,
  declined: 5,
  sendingInProgress: 6,
  error: 7,
  recovered: 8,
  deleted: 9,
  completed: 10,
  openWithDisclosure: 11,
  signing: 16,
  CICError: 20,
  SOCError: 21,
  signed: 22,
  notSigned: 23,
};

export const orderStatusesByCode = {
  0: 'Pending',
  1: 'Completed',
  2: 'Canceled',
  3: 'Rejected',
  9: 'Failed',
};

export const orderStatusesByName = {
  pending: 0,
  completed: 1,
  canceled: 2,
  rejected: 3,
  failed: 9,
};

export const studentsApprovalState = {
  pending: 0,
  approved: 1,
  rejected: 2,
};

export const studentsApprovalStateByCode = {
  0: 'pending',
  1: 'approved',
  2: 'rejected',
};

export const studentsApprovalOptionByCode = {
  0: 'pending',
  1: 'approve',
  2: 'reject',
};

export const orderType = {
  orderSales: 1,
  ec: 3,
};

export const paymentType = {
  giftCard: 1,
  check: 2,
  moneyOrder: 3,
};

export const paymentTypeLabel = {
  1: appStrings.order.giftCard,
  2: appStrings.order.check,
  3: appStrings.order.moneyOrder,
};

export const paymentMethod = {
  card: 0,
  voucher: 1,
  otherPayments: 2,
  ecOrder: 3,
};

export const paymentMethodLabel = {
  0: appStrings.orderDetailsHistory.creditCard,
  1: appStrings.order.voucher,
  2: appStrings.order.otherPayments,
};

export const lineTypes = {
  0: 'Sale',
  1: 'Return',
};

export const notificationRole = {
  student: 'student',
  industrial: 'industrial',
};

export const cartTypes = {
  cart: 1,
  order: 2,
  saved: 3,
};

export const sepLinks = {
  privacyPolicy: 'https://www.snapon.com/en/us-can/privacypolicy/industrial',
  SOCUrl: 'https://customer.snaponcredit.com/eSnaponCredit/',
  conditionsOfUse: '',
};
