import { makeStyles } from 'tss-react/mui';

const usePaymentDialogStyles = makeStyles()(theme => ({
  paymentFrame: {
    maxWidth: '400px',
    minHeight: '525px',
    width: '80vw',
  },

  paymentContainer: {
    maxWidth: '420px',
    minWidth: '256px',
    minHeight: '400px',
  },

  captchaSkeleton: {
    marginTop: theme.spacing(2),
  },

  cvvDialog: {
    '& .MuiDialogContent-root': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '& p': {
      textAlign: 'left',
    },
  },
  cvvTitle: {
    marginBottom: theme.spacing(1),
  },
  cvvFirstSection: {
    marginBottom: theme.spacing(4),
  },

  creditCardDialog: {
    margin: theme.spacing(2),
    padding: theme.spacing(3, 2),
    '& .MuiPaper-root': {
      background: theme.palette.common.white,
    },
    '& .MuiDialogTitle-root h2': {
      fontSize: theme.typography.h2.fontSize,
      textAlign: 'left',
    },
    '& .MuiDialogContent-root': {
      padding: 0,
      marginBottom: 0,
    },
  },
  errorsContainer: {
    color: theme.palette.common.red,
  },
}));

export default usePaymentDialogStyles;
