import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { cartInputs } from '../../../industrial/AddProduct/productDetailsConstants';
import useShoppingCartStyles from '../shoppingCartStyles';
import { useDispatch, useSelector } from 'react-redux';
import {
  displayError,
  displaySuccess,
} from '../../../../core/redux/slices/notificationsSlice';
import appStrings from '../../../../core/strings/appStrings';
import { saveItem } from '../ShoppingCartService.js';
import { notesIds } from '../../../../core/strings/IDs.js';
import { cloneDeep } from 'lodash';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

function StudentNotes({ item, index, onItemUpdate }) {
  const [initialNotes, setInitialNotes] = useState('');
  const [newNotes, setNewNotes] = useState('');
  const [hasNotes, setHasNotes] = useState(false);
  const [savingData, setSavingData] = useState(false);
  const notesRef = useRef();
  notesRef.current = newNotes;
  const { userDetails, isLoggedIn } = useSelector(state => state.login);
  const isSmallDevice = useMediaQuery('(max-width:768px)');
  const { pathname } = useLocation();
  const isOrderDetails = pathname.indexOf('/orderDetails') !== -1;
  const dispatch = useDispatch();
  const studentNotesField = cartInputs.find(
    input => input.name === 'studentNotes'
  );
  const styleParams = {
    isSmallDevice,
    isCartSummary: false,
  };
  const css = useShoppingCartStyles(styleParams).classes;

  const saveNotes = () => {
    if (notesRef.current === initialNotes) {
      return;
    }
    setSavingData(true);

    const newItem = cloneDeep(item);
    const studentNotesIndex = newItem.notes?.findIndex(
      note => note.noteType === notesIds.StudentNote
    );
    if (studentNotesIndex === -1) {
      newItem.notes.push({
        noteType: notesIds.StudentNote,
        content: notesRef.current,
      });
    } else {
      if (notesRef.current) {
        newItem.notes[studentNotesIndex].content = notesRef.current;
      } else {
        newItem.notes.splice(studentNotesIndex, 1);
      }
    }

    saveItem(userDetails.userId, newItem)
      .then(() => {
        setInitialNotes(notesRef.current);
        onItemUpdate(newItem);
        dispatch(displaySuccess({ message: appStrings.cart.itemSaved }));
      })
      .catch(() => dispatch(displayError()))
      .finally(() => setSavingData(false));
  };

  useEffect(() => {
    const studentNotes = item.notes?.find(
      note => note.noteType === notesIds.StudentNote
    );
    if (studentNotes) {
      setHasNotes(true);
      setNewNotes(studentNotes.content);
      setInitialNotes(studentNotes.content);
    } else {
      setHasNotes(false);
      setNewNotes('');
      setInitialNotes('');
    }
  }, [item.notes]);

  const handleInputChange = e => {
    setNewNotes(e.target.value);
  };

  const onHasNotesChange = () => {
    if (hasNotes) {
      setNewNotes('');
      setTimeout(() => {
        saveNotes();
      });
    }
    setHasNotes(!hasNotes);
  };

  return (
    isLoggedIn && (
      <Grid
        container
        flexDirection="column"
        className={classNames({
          [css.studentNotes]: !isOrderDetails || hasNotes,
        })}
      >
        {!isOrderDetails && (
          <Grid item>
            <FormControlLabel
              label={appStrings.cart.addNotes}
              labelPlacement={'end'}
              control={
                <Checkbox
                  checked={hasNotes}
                  className={css.itemCheckbox}
                  disableRipple
                  onChange={onHasNotesChange}
                  data-test-id={`studentNotes${index}`}
                  id={`studentNotes${index}`}
                  disabled={savingData}
                />
              }
            />
          </Grid>
        )}
        {hasNotes && (
          <Grid item>
            <Typography fontWeight="bold">{studentNotesField.label}</Typography>
            <TextField
              fullWidth={true}
              value={newNotes}
              placeholder={studentNotesField.placeholderText}
              variant="outlined"
              type={studentNotesField.type}
              minRows={3}
              onChange={handleInputChange}
              onBlur={saveNotes}
              inputProps={{
                ...studentNotesField.validations,
                readOnly: studentNotesField.readOnly,
                'data-test-id': `normalInput-${studentNotesField.name}`,
              }}
              autoComplete="off"
              FormHelperTextProps={{
                'data-test-id': 'validation-helperText',
              }}
              id={studentNotesField.name}
              multiline={studentNotesField.multiline}
              disabled={savingData}
            />
          </Grid>
        )}
      </Grid>
    )
  );
}

StudentNotes.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  onItemUpdate: PropTypes.func,
};

export default StudentNotes;
