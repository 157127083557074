import { Grid, Step, StepLabel, Stepper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { setActiveStep } from '../../../../core/redux/slices/wizardSlice';
import useWizardStyles from '../useWizardStyles';
import PropTypes from 'prop-types';
import { wizardSteps, wizardStepsStudent } from '../wizardConstants';

function WizardMenu({ isStudent }) {
  const dispatch = useDispatch();
  const css = useWizardStyles().classes;

  const activeStep = useSelector(state => state.wizard.activeStep);

  const handleNext = index => {
    dispatch(setActiveStep(index));
  };

  return (
    <Grid item id="wizardStepperContainer">
      <Stepper activeStep={activeStep} alternativeLabel className={css.stepper}>
        {(isStudent ? wizardStepsStudent : wizardSteps).map((step, index) => (
          <Step key={step.label} id={`wizardStepperStep-${index}`}>
            <StepLabel
              onClick={() => handleNext(index)}
              id={`wizardStepperStepLabel-${index}`}
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Grid>
  );
}

WizardMenu.propTypes = {
  isStudent: PropTypes.bool,
};

export default WizardMenu;
