import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid, Link, Tooltip, Typography } from '@mui/material';
import { useProductsSetsStyles } from './useProductsSetsStyles';
import { useNavigate } from 'react-router-dom';
import { PRODUCT } from '../../../core/navigation/paths';
import ItemPrice from '../ShoppingCart/Components/ItemPrice';
import CartButton from '../ProductContent/Components/CartButton';
import noProductImage from '../../../core/assets/img/noProduct-img.png';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import appStrings from '../../../core/strings/appStrings';

function ProductSetItem({ itemDetails, index }) {
  const css = useProductsSetsStyles().classes;
  const navigate = useNavigate();

  const goToProductPage = () => {
    if (!itemDetails.isAvailableForStudent) {
      return;
    }

    window.scrollTo(0, 0);
    navigate(PRODUCT(itemDetails.productNumber));
  };

  const getProductImage = event => (event.target.src = noProductImage);

  const renderCartIcon = () => (
    <Grid item xs={2} justifyContent="flex-end" display="flex">
      <Grid
          container
          justifyContent="center"
          className={
            classNames(css.cartButton, !itemDetails.isAvailableForStudent ? 'cart-button-disabled' : '')
          }
        >
          {
            itemDetails.isAvailableForStudent ?
              <CartButton productDetails={itemDetails} /> :
              <Tooltip
                title={appStrings.productPage.itemUnavailable}
                placement="bottom"
                arrow
                enterTouchDelay={0}
              >
                <ShoppingCartOutlinedIcon
                  id="productContentCart-Button"
                  data-test-id="ShoppingCartOutlinedIcon"
                  fontSize="medium"
                  className="disabled"
                />
              </Tooltip>
          }
        </Grid>
    </Grid>
  );

  return(
    <Grid container item xs={12} className={css.setItemContainer} id={`setItemContainer_${index}`}>
      <Grid container item xs={4} lg={1}
        className={classNames('picture-link', !itemDetails.isAvailableForStudent ? 'disabled-link' : '')}
        onClick={goToProductPage} id={`setItemPicture_${index}`}
      >
        <img
          loading="lazy"
          src={itemDetails.image || noProductImage}
          alt="Product"
          className={css.setItemImage}
          onError={getProductImage}
        />
      </Grid>
      <Grid item xs={6} lg={10} className={css.itemContentContainer}>
        <Grid container flexDirection="column" justifyContent="flex-start" height="100%">
          <Grid item id={`setItemTitle_${index}`}>
            <Link
              className={classNames('product-title', !itemDetails.isAvailableForStudent ? 'disabled-link' : '')}
              onClick={goToProductPage}
            >
                <Typography variant="h3" fontWeight="700">{itemDetails.productNumber}</Typography>
            </Link>
          </Grid>
          <Grid item id={`setItemDescription_${index}`}>
            <Typography className='product-name' variant="h3">{itemDetails.description}</Typography>
          </Grid>
          <Grid item className={css.itemPrice}>
            <ItemPrice item={itemDetails} index={index} isSetItem={true}/>
          </Grid>
        </Grid>
      </Grid>
      {renderCartIcon()}
    </Grid>
  );
}

ProductSetItem.propTypes = {
  itemDetails: PropTypes.object,
  index: PropTypes.number,
};

export default ProductSetItem;