import { ClickAwayListener, Divider, Grid } from '@mui/material';
import { Fragment, useContext, useEffect, useState } from 'react';

import CartContext from '../context/CartContext';
import CustomAccordion from '../../../shared/CustomAccordion/CustomAccordion';
import CustomAccordionContainer from '../../../shared/CustomAccordionContainer/CustomAccordionContainer';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import CustomTableSkeleton from '../../../shared/CustomSkeleton/CustomTableSkeleton';
import OrderPayment from '../../OrderPayment/OrderPayment';
import ProductsList from '../../ProductsList/ProductsList';
import ShippingAndBilling from '../../ShippingAndBilling/ShippingAndBilling';
import ECContract from '../../ECContract/ECContract';
import appStrings from '../../../../core/strings/appStrings';
import classNames from 'classnames';
import { defaultWarehouse } from '../../AddProduct/addProductConstants';
import { every } from 'lodash';
import { productsListColumns } from '../../ProductsList/productsListConstants';
import useCartStyles from '../useCartStyles';
import { useSelector } from 'react-redux';
import LineItemsFilter from './LineItemsFilter';

function CartLineItemsSection() {
  const css = useCartStyles().classes;
  const cartCtx = useContext(CartContext);
  const {
    deletingItems,
    isOrder,
    id,
    addingProduct,
    setRowsLoading,
    setOrderStatus,
    expandedSection,
    setExpandedSection,
    setOnlyConsignedSelected,
    sectionErrors,
    setSectionErrors,
    completeOrderClicked,
    setCompleteOrderClicked,
  } = cartCtx;

  const addFromConsignedLoading = useSelector(
    state => state.consignedInventory.addFromConsignedLoading
  );
  const { cartInfo, isECOrder } = useSelector(state => state.cart);

  const [shouldSaveShippingInfo, setShouldSaveShippingInfo] = useState(false);
  const [, setShouldSaveECContract] = useState(false);
  const [shouldSavePayment, setShouldSavePayment] = useState(false);

  const expandHandler = tabId => {
    setExpandedSection(tabId);
  };

  const handleShippingClickAway = event => {
    // check if click was not on Select element inside accordion
    if (event.target.localName !== 'body') {
      setShouldSaveShippingInfo(true);
    }
  };

  const handleECContractClickAway = event => {
    // check if click was not on Select element inside accordion
    if (event.target.localName !== 'body') {
      setShouldSaveECContract(true);
    }
  };

  const handlePaymentClickAway = event => {
    if (event.target.localName !== 'body') {
      setShouldSavePayment(true);
    }
  };

  const orderSections = [
    {
      label: (
        <LineItemsFilter displayFilter={expandedSection === 'lineItems'} />
      ),
      id: 'lineItems',
      handleClickAway: event => {
        if (
          event.target.localName === 'a' ||
          event.target.id === 'importFileFromExcelInput'
        ) {
          return;
        }
        event.preventDefault();
      },
      component: addFromConsignedLoading ? (
        <CustomTableSkeleton columns={productsListColumns} />
      ) : (
        <ProductsList
          setRowsLoading={setRowsLoading}
          cartId={cartInfo?.cartDetails?.cartId}
          addingProduct={addingProduct}
          deletingItems={deletingItems}
          activePrompt={expandedSection === 'lineItems'}
          setSectionErrors={setSectionErrors}
        />
      ),
    },
    {
      label: appStrings.order.ecContract,
      id: 'ecContract',
      handleClickAway: handleECContractClickAway,
      component: <ECContract setSectionErrors={setSectionErrors} />,
      isHidden: !isECOrder,
    },
    {
      label: appStrings.order.shippingAndBilling,
      id: 'shippingAndBilling',
      handleClickAway: handleShippingClickAway,
      component: (
        <ShippingAndBilling
          shouldSave={shouldSaveShippingInfo}
          setShouldSave={setShouldSaveShippingInfo}
          isExpanded={expandedSection === 'shippingAndBilling'}
          setSectionErrors={setSectionErrors}
        />
      ),
    },
    {
      label: appStrings.order.payment,
      id: 'payment',
      handleClickAway: handlePaymentClickAway,
      component: (
        <OrderPayment
          shouldSave={shouldSavePayment}
          setShouldSave={setShouldSavePayment}
          isExpanded={expandedSection === 'payment'}
          completeOrderClicked={completeOrderClicked}
          setCompleteOrderClicked={setCompleteOrderClicked}
          sectionErrors={sectionErrors}
          setSectionErrors={setSectionErrors}
          setOrderStatus={setOrderStatus}
          studentId={id}
        />
      ),
    },
  ];

  useEffect(() => {
    if (
      isOrder &&
      cartInfo.selectedCounter > 0 &&
      every(
        cartInfo.cartDetails.cartItems,
        item =>
          !item.selected ||
          (item.selected &&
            item.warehouse?.name?.toLowerCase() !==
              defaultWarehouse.value.name.toLowerCase())
      )
    ) {
      setOnlyConsignedSelected(true);
    } else {
      setOnlyConsignedSelected(false);
    }
  }, [cartInfo, isOrder, setOnlyConsignedSelected]);

  return (
    <Grid item xs={12}>
      {isOrder ? (
        <CustomBox
          customClass={classNames(css.sectionsContainer, {
            [css.hasNoItems]:
              (!cartInfo.cartDetails ||
                cartInfo.cartDetails.cartItems.length === 0) &&
              !addFromConsignedLoading,
          })}
        >
          <CustomAccordionContainer>
            {orderSections.map(
              (elem, index) =>
                !elem.isHidden && (
                  <Fragment key={elem.id}>
                    <ClickAwayListener
                      touchEvent={false}
                      onClickAway={elem.handleClickAway}
                    >
                      <div>
                        <CustomAccordion
                          id={elem.id}
                          title={elem.label}
                          expanded={expandedSection}
                          onExpandChange={expandHandler}
                          hasErrors={
                            sectionErrors[elem.id] && completeOrderClicked
                          }
                          disabled={
                            elem.id !== 'lineItems' &&
                            (!cartInfo.cartDetails ||
                              cartInfo.cartDetails.cartItems.length === 0)
                          }
                          identifier={elem.id}
                        >
                          {elem.component}
                        </CustomAccordion>
                      </div>
                    </ClickAwayListener>
                    {index < orderSections.length - 1 ? <Divider /> : null}
                  </Fragment>
                )
            )}
          </CustomAccordionContainer>
        </CustomBox>
      ) : (
        <ProductsList
          setRowsLoading={setRowsLoading}
          cartId={cartInfo?.cartDetails?.cartId}
          addingProduct={addingProduct}
          deletingItems={deletingItems}
          activePrompt={true}
          setSectionErrors={setSectionErrors}
        />
      )}
    </Grid>
  );
}

export default CartLineItemsSection;
