import { makeStyles } from 'tss-react/mui';

const useEmployerStyles = makeStyles()(theme => ({
  employerContainer: {
    '&.MuiBox-root': {
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  deleteContainer: {
    textAlign: 'right',
    marginTop: theme.spacing(-2),
  },
  previousAddressTitle: {
    counterIncrement: 'line-number',
    '&::after': {
      content: 'counter(line-number)',
    },
  },
  actionsContainer: {
    marginTop: theme.spacing(2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(3),
    },
  },
  deleteIcon: {
    color: theme.palette.common.red,
  },
}));

export default useEmployerStyles;
