import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import appStrings from '../../../core/strings/appStrings';
import CustomButton from '../../shared/CustomButton/CustomButton';
import { buttonTypes } from '../../../core/strings/appConstants';
import useECAppRedirectStyles from './useECAppRedirectStyles';

function ECAppRedirect({ handleToggle, handleRedirect }) {
  const css = useECAppRedirectStyles().classes;
  const [isECAppRedirect, setIsECAppRedirect] = useState(true);

  const handleChange = (e, value) => {
    setIsECAppRedirect(value);
    handleToggle(value);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography
          fontWeight="bold"
          id="ecAppRedirect"
          data-test-id="ecAppRedirect"
        >
          {appStrings.order.appForm}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="ECAppRedirect"
            name="ecAppRedirect-radioGroup"
            value={isECAppRedirect}
            onChange={handleChange}
          >
            <FormControlLabel
              id="ecAppRedirect-yes"
              value={true}
              control={<Radio />}
              label={appStrings.order.yesLetsDoIt}
            />
            <CustomButton
              customClass={css.buttonContainer}
              data-test-id="ecApp-button"
              id="ecApp-button"
              size="small"
              btnType={buttonTypes.secondary}
              label={appStrings.wizard.ECApp}
              onClick={handleRedirect}
            ></CustomButton>
            <FormControlLabel
              id="ecAppRedirect-no"
              value={false}
              control={<Radio />}
              label={appStrings.order.noMaybeLater}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </>
  );
}

export default ECAppRedirect;
