import {
  historySortBy,
  historySortDirection,
  historyTransactionType,
} from '../../../../core/strings/appConstants';
import urls from '../../../../core/strings/urls';
import ApiService from '../../../shared/Api/apiService';

export const getOrders = ({ page, studentId, itemsPerPage }) => {
  const params = {
    transactionType: historyTransactionType.none,
    sortBy: historySortBy.date,
    sortDirection: historySortDirection.descending,
    page: {
      pageNumber: page - 1,
      customPageSize: itemsPerPage,
    },
    studentId: studentId,
  };

  return ApiService.post(urls.getHistoryDetails, params);
};
