import { Grid } from '@mui/material';
import React, { useState } from 'react';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import {
  buttonColors,
  buttonTypes,
  cartTypes,
} from '../../../../core/strings/appConstants';
import appStrings from '../../../../core/strings/appStrings';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import PropTypes from 'prop-types';
import CustomDialog from '../../../shared/CustomDialog/CustomDialog';
import { useSelector } from 'react-redux';
import { AddShoppingCart } from '@mui/icons-material';
import { useItemsButtonsStyle } from './useItemsButtonsStyle';

function ItemsButtons({
  isSavedCart,
  indexItem,
  handleDeleteFromCart,
  handleMoveItems,
  source,
  ...props
}) {
  const { isLoggedIn } = useSelector(state => state.login);
  const [dialog, setDialog] = useState({
    open: false,
    title: '',
    yesAction: null,
  });
  const css = useItemsButtonsStyle().classes;

  const handleOpenDialog = ({ isRemoveButton }) =>
    isRemoveButton
      ? setDialog({
          open: true,
          title: appStrings.cart.removeSelectedItems,
          yesAction: () => {
            handleDeleteFromCart(indexItem);
            handleCloseDialog();
          },
        })
      : setDialog({
          open: true,
          title: isSavedCart
            ? appStrings.cart.moveToCartSelectedItems
            : appStrings.cart.saveForLaterSelectedItems,
          yesAction: () => {
            isSavedCart
              ? handleMoveItems(cartTypes.cart, indexItem)
              : handleMoveItems(cartTypes.saved, indexItem);
            handleCloseDialog();
          },
        });

  const handleCloseDialog = () =>
    setDialog(prev => ({
      ...prev,
      open: false,
    }));

  return (
    <>
      <CustomDialog
        open={dialog.open}
        title={dialog.title}
        buttons={[
          {
            btnType: buttonTypes.secondary,
            label: appStrings.common.no,
            onClick: () => handleCloseDialog(),
          },
          {
            color: buttonColors.red,
            label: appStrings.common.yes,
            onClick: dialog.yesAction,
          },
        ]}
      />
      <Grid item container spacing={2}>
        {isLoggedIn && (
          <Grid item {...props}>
            <CustomButton
              data-test-id={`saveItemButton-${indexItem}-${source}`}
              id={`saveItemButton-${source}`}
              size="small"
              btnType={buttonTypes.secondary}
              label={
                isSavedCart
                  ? appStrings.cart.moveToCart
                  : appStrings.cart.saveForLater
              }
              startIcon={isSavedCart ? <AddShoppingCart /> : <StarBorderIcon />}
              onClick={() => handleOpenDialog({ isRemoveButton: false })}
            />
          </Grid>
        )}
        <Grid
          id="saveItemBtnContainer"
          item
          {...props}
          className={css.saveItemBtnContainer}
        >
          <CustomButton
            data-test-id={`removeItemButton-${indexItem}-${source}`}
            id={`removeItemButton-${source}`}
            size="small"
            btnType={buttonTypes.tertiary}
            label={appStrings.common.remove}
            startIcon={<DeleteOutlineIcon />}
            onClick={() => handleOpenDialog({ isRemoveButton: true })}
          />
        </Grid>
      </Grid>
    </>
  );
}

ItemsButtons.propTypes = {
  indexItem: PropTypes.number,
  isSavedCart: PropTypes.bool,
  handleDeleteFromCart: PropTypes.func,
  handleMoveItems: PropTypes.func,
  source: PropTypes.string,
};

export default ItemsButtons;
