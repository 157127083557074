import { makeStyles } from 'tss-react/mui';

const spaceBetween = 'space-between';

const useShoppingCartStyles = makeStyles()(
  (theme, { isSmallDevice, isCartSummary }) => ({
    mainContainer: {
      '& .MuiSkeleton-root': {
        borderRadius: theme.spacing(2),
      },
    },

    containerXs: {
      border: `solid ${theme.palette.common.fadeGrey}`,
      borderWidth: `${theme.spacing(0.2, 0, 0.2, 0)}`,
      borderRadius: '0px',
    },

    stickyPos: {
      position: 'sticky',
      top: 'auto',
    },

    cartItems: {
      border: `solid ${theme.palette.common.fadeGrey}`,
      borderWidth: `${theme.spacing(0, 0, 0.1, 0)}`,
      padding: isCartSummary ? theme.spacing(0.5) : theme.spacing(2),
      '& img': {
        maxWidth: isCartSummary ? theme.spacing(10) : theme.spacing(20),
        maxHeight: isCartSummary ? theme.spacing(10) : theme.spacing(20),
        width: isCartSummary ? theme.spacing(10) : theme.spacing(20),
        objectFit: 'contain',
        paddingRight: '12px',
      },
      '& button': {
        width: '100%',
        fontWeight: 'bold',
        color: 'black !important',
      },
      '& .MuiTypography-root': {
        fontSize: isCartSummary ? '12px !important' : '14px',
      },
    },

    title: {
      '& .MuiDivider-root': {
        color: theme.palette.common.fadeGrey,
      },
    },

    goToProductDetailsElement: {
      '&:hover': {
        cursor: 'pointer',
        color: 'grey'
      },
    },

    cartTotals: {
      padding: theme.spacing(2),
      justifyContent: spaceBetween,

      '& p': {
        fontSize: theme.spacing(2),
      },

      '& .MuiGrid-container': {
        justifyContent: spaceBetween,
      },

      '#sectionTwo': {
        '& p': {
          fontWeight: 'bold',
        },
      },

      '& button': {
        width: '100%',
        fontWeight: 'bold',
      },

      '& .tax-value': {
        maxWidth: '50%',
        textAlign: 'end',
      }
    },

    cartTotalsDivider: {
      width: !isSmallDevice && '100%',
      height: isSmallDevice && '100%',
      padding: !isSmallDevice && theme.spacing(1, 0, 1, 0),

      '& .MuiDivider-root': {
        background: isSmallDevice ? 'red' : null,
      },
    },

    textOverline: {
      fontWeight: 'bold',
      textDecorationLine: 'line-through',
      fontSize: `${theme.spacing(1.5)} !important`,
    },

    studentPriceStyle: {
      color: `${theme.palette.common.red} !important`,
      fontSize: `${theme.spacing(2)} !important`,
      fontWeight: isCartSummary ? 'bold !important' : 'regular',
    },

    cartSummary: {
      padding: theme.spacing(1, 3, 2),
      maxWidth: '400px',
      '& button': {
        width: '100%',
      },
    },

    summaryTitle: {
      alignItems: 'center',
      justifyContent: 'left',
      padding: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.common.fadeGrey}`,
    },

    titleContainer: {
      borderBottom: `1px solid ${theme.palette.common.fadeGrey}`,

      '& .MuiButton-endIcon': {
        marginLeft: 0,
      },
    },

    selectAll: {
      paddingRight: 0,
      paddingLeft: '16px',
      '&.Mui-checked': {
        color: theme.palette.common.red,
      },
    },

    itemCheckbox: {
      paddingLeft: 0,
      '&.Mui-checked': {
        color: theme.palette.common.red,
      },
    },

    floatingActions: {
      display: 'flex',
      justifyContent: spaceBetween,
      flexDirection: isSmallDevice && 'column-reverse',
      width: isSmallDevice ? '80%' : '640px',
      padding: '16px',
      alignItems: 'center',
      borderRadius: '8px',
      border: `1px solid ${theme.palette.common.fadeGrey}`,
      boxShadow: `0px 2px 4px 0px ${theme.palette.common.fadeGrey}`,
      zIndex: '8',
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: '4%',
      position: 'fixed',

      '& .MuiButton-root': {
        color: `${theme.palette.common.black} !important`,
        width: isSmallDevice && '100%',
      },

      '#saveItemBtnContainer': {
        ...(isSmallDevice && {
          display: 'flex',
          justifyContent: 'flex-end',
        }),
      },
    },

    itemsCount: {
      color: theme.palette.text.secondary,
      marginLeft: '24px',
      paddingLeft: '24px',
      borderLeft: `1px solid ${theme.palette.common.fadeGrey}`,
      lineHeight: '32px',
    },

    itemsCountMobile: {
      color: theme.palette.text.secondary,
      lineHeight: '32px',
    },

    unselectAll: {
      paddingRight: '0px !important',
    },

    horizontalSeparator: {
      borderBottom: `1px solid ${theme.palette.common.fadeGrey}`,
      marginBottom: '12px',
    },

    hide: {
      display: 'none',
    },

    auditUpdate: {
      '& .MuiAlert-icon': {
        backgroundColor: `${theme.palette.common.secondary} !important`,
      },
    },

    studentNotes: {
      margin: '12px 0 12px 0',
    },

    paginationStyle: {
      '& .MuiGrid-root': {
        border: 0,
        backgroundColor: 'transparent',
      },
    },

    itemDetailsContainer: {
      marginBottom: theme.spacing(2),
    },

    emptyCartMessage: {
      justifyContent: 'center',
      padding: '25px 0 25px 0',
    },

    unitPrice: {
      color: 'grey',

      '& .MuiTypography-root': {
        fontSize: '13px',
      }
    },
  })
);

export default useShoppingCartStyles;
