import React, { useEffect, useState } from 'react';
import NavTab from '../../Header/NavTab/NavTab';
import appStrings from '../../../../core/strings/appStrings';
import { fullIcons } from '../../../../core/strings/icons';
import { SHOPPINGCART } from '../../../../core/navigation/paths';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCartLength,
  setCartSummaryItems,
  setCartSummaryOrderTotal,
  setShouldGetCartSummary,
} from '../../../../core/redux/slices/guestCartSlice';
import PropTypes from 'prop-types';

import { STUDENT } from '../../../../core/navigation/roles';
import {
  isCartIconLoading,
  mergeCartToStudent,
  setGuestItemsCartLocal,
} from './shoppingCartIconService';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import { notificationRole } from '../../../../core/strings/appConstants';
import CartSummary from '../Views/CartSummary';
import {
  calculateCartLength,
  getStudentCart,
  getStudentSummary,
  setStudentCartDetails,
  updateStudentSummary,
} from '../ShoppingCartService';
import CartIconLoading from '../Skeletons/CartIconLoading';
import useShoppingCartIconStyles from './shoppingCartIconStyles';
import { useLocation } from 'react-router-dom';

function ShoppingCartIcon({ isXsDevices }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const shoppingCartPage = pathname.indexOf(SHOPPINGCART) !== -1;
  const css = useShoppingCartIconStyles().classes;
  const [loading, setLoading] = useState({
    mergeLoading: false,
    getCartLoading: false,
  });
  const { cartLength, shouldGetCartSummary } = useSelector(
    state => state.guestCart
  );
  const { isLoggedIn, userDetails } = useSelector(state => state.login);
  const [cartSummaryAnchorEl, setCartSummaryAnchorEl] = useState(null);
  const cartSummaryItems = useSelector(
    state => state.guestCart.cartSummaryItems
  );

  const handleSetGuestCartSummary = () => {
    if (!isLoggedIn) {
      dispatch(
        setCartSummaryItems({
          cartItems:
            JSON.parse(localStorage.getItem('guestItemsCart'))?.value || [],
        })
      );
    }
  };

  useEffect(() => {
    const guestItems =
      JSON.parse(localStorage.getItem('guestItemsCart'))?.value || [];
    if (!isLoggedIn) {
      setGuestItemsCartLocal();
      dispatch(setCartLength(calculateCartLength(guestItems)));
    } else if (
      isLoggedIn &&
      userDetails.role === STUDENT &&
      guestItems.length &&
      !shoppingCartPage
    ) {
      setLoading(prev => ({ ...prev, mergeLoading: true }));
      mergeCartToStudent(userDetails.userId, guestItems)
        .then(async resp => {
          setGuestItemsCartLocal(false);
          if (resp.errors) {
            dispatch(
              displayError({
                role: notificationRole.student,
                message: resp.errors,
              })
            );
          }
          await updateStudentSummary(userDetails.userId);
        })
        .catch(() => dispatch(displayError({ role: notificationRole.student })))
        .finally(() => {
          setLoading(prev => ({ ...prev, mergeLoading: false }));
          dispatch(setShouldGetCartSummary(true));
        });
    }
  }, [
    dispatch,
    isLoggedIn,
    shoppingCartPage,
    userDetails.role,
    userDetails.userId,
  ]);

  useEffect(() => {
    if (isLoggedIn && shouldGetCartSummary && !shoppingCartPage) {
      setCartSummaryAnchorEl(null);
      setLoading(prev => ({ ...prev, getCartLoading: true }));
      getStudentCart(userDetails.userId)
        .then(resp => {
          setStudentCartDetails(resp.cartDetails, dispatch);
          getStudentSummary(resp.cartDetails.cartId).then(resp =>
            dispatch(setCartSummaryOrderTotal(resp?.grandTotal))
          );
        })
        .finally(() => {
          setLoading(prev => ({ ...prev, getCartLoading: false }));
          dispatch(setShouldGetCartSummary(false));
        });
    }
  }, [
    dispatch,
    isLoggedIn,
    shoppingCartPage,
    shouldGetCartSummary,
    userDetails.userId,
  ]);

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(setShouldGetCartSummary(true));
    }
  }, [dispatch, isLoggedIn]);

  return (
    <>
      {isCartIconLoading(loading, isLoggedIn) ? (
        <CartIconLoading
          loading={loading}
          isXsDevices={isXsDevices}
          isLoggedIn={isLoggedIn}
        />
      ) : (
        <NavTab
          label={appStrings.menu.cart}
          hideLabel={isXsDevices}
          icon={fullIcons.cart}
          showBadge={Boolean(cartLength)}
          normalBadge={true}
          badgeAnchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          badgeContent={cartLength}
          aria-describedby="shoppingCartSummary"
          isAction={!isXsDevices}
          className={css.navTabStyle}
          disabled={!userDetails.isActive && isLoggedIn}
          navigateTo={SHOPPINGCART}
          onClick={(label, event) => {
            if (!isXsDevices && !shoppingCartPage) {
              handleSetGuestCartSummary();
              setCartSummaryAnchorEl(event.currentTarget);
            }
          }}
        />
      )}
      <CartSummary
        anchorEl={cartSummaryAnchorEl}
        handleClose={() => setCartSummaryAnchorEl(null)}
        displayedItems={cartSummaryItems}
        cartLength={cartLength}
      />
    </>
  );
}

ShoppingCartIcon.propTypes = {
  isXSDevice: PropTypes.bool,
};

export default ShoppingCartIcon;
