import { makeStyles } from 'tss-react/mui';

const useNumberFieldStyles = makeStyles()(theme => ({
  helperText: {
    color: theme.palette.common.red,
    margin: 0,
  },
  errors: {
    color: `${theme.palette.common.red} !important`,

    '& label': {
      color: `${theme.palette.common.red} !important`,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.common.red} !important`,
    },
    '& .Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${theme.palette.common.red} !important`,
      },
    },
  },
  errorMessage: {
    fontSize: '0.8rem',
    color: theme.palette.common.red,
    marginTop: '6px',
  },
}));

export default useNumberFieldStyles;
