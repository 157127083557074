import { makeStyles } from 'tss-react/mui';

const useValidationStyles = makeStyles()(theme => ({
  message: {
    '&:hover': {
      cursor: 'pointer',
    },

    fontWeight: 'bold',
  },
  customAlert: {
    '& .MuiAlertTitle-root': {
      fontWeight: 'normal',
    },
  },
  howToFix: {
    textDecoration: 'underline',
  },
  formContainer: {
    padding: '0 25px 25px 25px',
  },
  iconClasses: {
    fontSize: '90px !important',
  },
  noDataMessage: {
    fontWeight: 'normal',
  },
}));

export default useValidationStyles;
