import { makeStyles } from 'tss-react/mui';

const none = '0 !important';
const useOrderConfirmationStyles = makeStyles()(theme => ({
  orderConfirmation: {
    '& .message': {
      marginBottom: theme.spacing(6),
    },

    justifyContent: 'center',
    alignItems: 'center',

    '& .MuiGrid-item': {
      paddingTop: none,
      paddingLeft: none,
    },

    '& .MuiGrid-root': {
      marginLeft: none,
      maxWidth: '100% !important',
    },

    '& .button-container': {
      marginTop: theme.spacing(6),
    },

    '& .thanks-message': {
      marginBottom: theme.spacing(1),
    },
  },
  orderConfirmationContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(4),
  },
  leftMargin: {
    marginLeft: '5px',
  },
  
}));

export default useOrderConfirmationStyles;
