import { makeStyles } from 'tss-react/mui';

const useEditProfileStyles = makeStyles()(theme => ({
  mainContainer: {
    position: 'relative',
    '& .MuiBox-root': {
      width: '100%',
      border: 0,
      padding: `${theme.spacing(2, 0, 0, 0)} !important`,
    },

    '#noDataIconContainer span:first-of-type': {
      color: theme.palette.common.black,
    },
  },

  saveBtn: {
    paddingTop: theme.spacing(5),
  },

  activeSectionText: {
    '& .MuiTypography-root': {
      color: `${theme.palette.common.red} !important`,
    },
  },

  mandatoryMessageContainer: {
    marginBottom: theme.spacing(2),
  },
}));

export default useEditProfileStyles;
