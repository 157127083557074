import { makeStyles } from 'tss-react/mui';

const useCustomerInformationStyles = makeStyles()(theme => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    borderTop: `1px solid ${theme.palette.common.fadeGrey}`,
  },
  resetPassContainer: {
    marginTop: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  resetPassBtn: {
    textTransform: 'capitalize',
    textAlign: 'right',
  },
  hideInput: {
    display: 'none',
  },
}));

export default useCustomerInformationStyles;
