import {
  displayError,
  displaySuccess,
} from '../../../../core/redux/slices/notificationsSlice';

import appStrings from '../../../../core/strings/appStrings';
import store from '../../../../core/redux/store';
import { wizardSteps } from '../wizardConstants';

export const handleErrors = error => {
  const errMsg = typeof error === 'object' ? error?.title : error;

  store.dispatch(
    displayError({
      message: errMsg || appStrings.errors.somethingWentWrong,
    })
  );
};

export const handleSaveNotification = (fromSubmit = false) => {
  if (fromSubmit) {
    store.dispatch(
      displaySuccess({
        message: appStrings.studentProfile.studentSuccessfulSave,
      })
    );
  }
};

export const isSignNowBtnClicked = () =>
  store.getState().wizard.activeStep === wizardSteps.length - 1;
