import { ADMIN, REP } from '../../../../core/navigation/roles';
import {
  getGuestRegisterInputs,
  getRegisterInputs,
  initializeField,
} from '../registerInputs';
import { getSchoolAddress, getSchoolsByStateAndRep } from '../registerService';
import {
  stateOptionsSortedByLabel,
  states,
  statesOptionsSortedByLabel,
} from '../../../../core/strings/states';

import appStrings from '../../../../core/strings/appStrings';
import { getSortedRepSchoolStates } from '../../StudentProfileForm/SchoolInformation/schoolInformationService';
import { getZipCodeInfo } from '../../../../core/services/zipCodeService';
import { useEffect } from 'react';

export const UpdateStateAndCityByZipCode = ({
  formFields,
  setValue,
  setError,
  clearErrors,
  updateSelectOptions,
  employeeId,
}) => {
  useEffect(() => {
    const isLoggedIn = employeeId !== undefined;

    if (formFields.zipCode?.length === 5) {
      getZipCodeInfo(formFields.zipCode)
        .then(res => {
          if (res) {
            updateSelectOptions('userDetails', 'city', res.cities, isLoggedIn);
            if (res.cities.length === 1) {
              setValue('city', res.cities[0].value);
            }
            if (res.state) {
              setValue('state', res.state);
              clearErrors('zipCode');
            } else {
              setValue('state', null);
              setError('zipCode', {
                type: 'manual',
                message: appStrings.validationMessages.noStateZipCode,
              });
            }
          }
        })
        .catch(() => {
          setError('zipCode', {
            type: 'manual',
            message: appStrings.validationMessages.invalidZipCode,
          });
          setValue('state', null);
          setValue('city', null);
          updateSelectOptions('userDetails', 'city', [], isLoggedIn);
        });
    } else {
      setValue('state', null);
      setValue('city', null);
      updateSelectOptions('userDetails', 'city', [], isLoggedIn);
    }
  }, [
    setValue,
    formFields.zipCode,
    clearErrors,
    setError,
    updateSelectOptions,
    employeeId,
  ]);
};

export const GetStateOptions = ({ employeeId, role }) => {
  useEffect(() => {
    const isLoggedIn = employeeId !== undefined;
    const registerInputs = isLoggedIn
      ? getRegisterInputs
      : getGuestRegisterInputs;
    const field = registerInputs.schoolDetails.fields.find(
      field => field.name === 'schoolState'
    );
    if (employeeId) {
      if (role !== ADMIN) {
        getSortedRepSchoolStates(employeeId).then(response => {
          field.options = response;
        });
      } else {
        const sortedStates = stateOptionsSortedByLabel(
          Object.getOwnPropertyNames(states)
        );
        field.options = sortedStates;
      }
    } else {
      field.options = statesOptionsSortedByLabel;
    }
  }, [employeeId, role]);
};

export const UpdateSchools = ({
  formFields,
  updateSelectOptions,
  setSchools,
  employeeId,
  role,
  setValue,
}) => {
  useEffect(() => {
    const isLoggedIn = employeeId !== undefined;

    if (formFields.schoolState) {
      setValue('schoolBpNumber', null);
      setValue('educationAccountManagerName', null);
      updateSelectOptions('schoolDetails', 'schoolBpNumber', [], isLoggedIn);
      getSchoolsByStateAndRep(
        formFields.schoolState,
        employeeId,
        isLoggedIn
      ).then(res => {
        if (res) {
          const options = res.map(item => {
            return getSchoolAddress(item);
          });
          updateSelectOptions(
            'schoolDetails',
            'schoolBpNumber',
            options,
            isLoggedIn
          );
          setSchools(res);
        }
      });
    }
  }, [
    employeeId,
    formFields.schoolState,
    role,
    setSchools,
    updateSelectOptions,
    setValue,
  ]);
};

export const UpdateEducationManager = ({
  formFields,
  schools,
  setValue,
  userData,
}) => {
  useEffect(() => {
    if (formFields.schoolBpNumber) {
      const school = schools.find(
        sc => sc.schoolBpNumber === formFields.schoolBpNumber
      );
      if (school) {
        const schoolFieldName = 'accountManagerName';
        const newValue =
          userData.role === REP ? userData.name : school[schoolFieldName];

        setValue('educationAccountManagerName', newValue);
      } else {
        setValue('educationAccountManagerName', null);
      }
    }
  }, [
    schools,
    setValue,
    userData.name,
    userData.role,
    formFields.schoolBpNumber,
  ]);
};

export const UpdateUsesTools = ({ formFields, setValue }) => {
  useEffect(() => {
    const registerInputs = getRegisterInputs;
    if (!formFields.isEmployed) {
      setValue('usesTools', false);

      registerInputs.schoolDetails.fields.find(
        field => field.name === 'usesTools'
      ).disabled = true;
    } else {
      setValue('usesTools', false);

      registerInputs.schoolDetails.fields.find(
        field => field.name === 'usesTools'
      ).disabled = false;
    }
  }, [formFields.isEmployed, setValue]);
};

export const ClearSchoolBpNumber = ({
  formFields,
  updateSelectOptions,
  setValue,
  errors,
  employeeId,
}) => {
  useEffect(() => {
    const isLoggedIn = employeeId !== undefined;
    const registerInputs = isLoggedIn
      ? getRegisterInputs
      : getGuestRegisterInputs;
    registerInputs.schoolDetails.fields.find(
      f => f.name === 'schoolState'
    ).onInputChange = () => {
      // if no state, reset school
      if (!!formFields.schoolState) {
        setValue('schoolBpNumber', '', {
          shouldValidate: Object.keys(errors).length > 0 ? true : false,
        });
        // setting the control key to a new value to re-initialize it
        initializeField('schoolDetails', 'schoolBpNumber', isLoggedIn);
        updateSelectOptions('schoolDetails', 'schoolBpNumber', [], isLoggedIn);
      }
    };
  }, [errors, formFields, setValue, updateSelectOptions, employeeId]);
};
