import {
  formatStudentsList,
  getHasSearchData,
  getListOfAllStudents,
  isDateBetween,
  sortByFirstName,
  sortByName,
  sortByScore,
} from './searchUtils';

import ApiService from '../../shared/Api/apiService';
import { cloneDeep, isString } from 'lodash';
import {
  dateFormat,
  studentsApprovalState,
} from '../../../core/strings/appConstants';
import moment from 'moment';
import urls from '../../../core/strings/urls';
import appStrings from '../../../core/strings/appStrings';
import { ORDER } from '../../../core/navigation/paths';
import { getDateWithoutOffset } from '../../../core/services/utilsService';

export const formatData = data => {
  Object.keys(data).forEach(field => {
    const element = data[field];
    if (
      typeof element !== 'string' &&
      field !== 'studentApprovedStatus' &&
      element
    ) {
      data[field] = element.format(dateFormat);
    }
  });
  return data;
};

const getValueForCreateSearchInputValue = (value, field) => {
  if (typeof value !== 'string' && field !== 'studentApprovedStatus' && value) {
    return `${moment(value).format(dateFormat)};`;
  }
  if (typeof value !== 'string' && field === 'studentApprovedStatus') {
    let statusValue = '';
    Object.keys(value).forEach(elem => {
      if (value[elem]) {
        statusValue += `${elem};`;
      }
    });

    return statusValue;
  }
  return value?.trim().length ? `${value.trim()};` : '';
};

export const createSearchInputValue = data => {
  if (getHasSearchData(data).hasSearchData) {
    let result = '';

    Object.keys(data).forEach(field => {
      result += getValueForCreateSearchInputValue(data[field], field);
    });

    return result;
  }
  return '';
};

const filterStudentsByActive = (studentStatus, studentsList) => {
  if (studentStatus !== 'all') {
    return studentsList.filter(
      student => student.active === (studentStatus === 'active')
    );
  }
  return studentsList;
};

const filterApprovedRejectStudentsByStatus = (status, studentsList) => {
  let toReturn = [];

  if (status.approved && status.pending && status.rejected) {
    return studentsList;
  }
  if (status.approved) {
    toReturn.push(
      ...studentsList.filter(
        student => student.approval === studentsApprovalState.approved
      )
    );
  }
  if (status.pending) {
    toReturn.push(
      ...studentsList.filter(
        student => student.approval === studentsApprovalState.pending
      )
    );
  }
  if (status.rejected) {
    toReturn.push(
      ...studentsList.filter(
        student => student.approval === studentsApprovalState.rejected
      )
    );
  }
  return toReturn;
};

const getStudentsWithSchoolNameAndBpNumber = schools => {
  if (!schools || !schools?.length) {
    return [];
  }
  const toBeReturned = [];
  schools.forEach(school => {
    toBeReturned.push(
      ...school?.students?.map(student => ({
        ...student,
        schoolName: school.schoolName,
        bpNumber: school.bpNumber,
      }))
    );
  });
  return toBeReturned;
};

const filterElements = (filters, listToSearchIn, filteredSchools) => {
  Object.entries(filters).forEach(filter => {
    const key = filter[0];
    const value = filter[1];
    if (!value) {
      return;
    }
    listToSearchIn.forEach(school => {
      if (
        value?.toUpperCase()?.trim() === school[key]?.toUpperCase()?.trim() ||
        school[key]?.toUpperCase().indexOf(value?.toUpperCase()) !== -1
      ) {
        filteredSchools.push(school);
      }
    });
  });
};

const filterSchools = (listToSearchIn, mainOrderedFilters, filteredSchools) => {
  listToSearchIn.forEach(school => {
    let score = 0;
    Object.entries(mainOrderedFilters).forEach(filter => {
      const key = filter[0];
      const value = filter[1];
      if (!value) {
        return;
      }
      const splitValue = value.split(' ');
      splitValue.forEach(sv => {
        if (
          school[key]
            ?.toUpperCase()
            ?.trim()
            .includes(sv?.toUpperCase()?.trim()) ||
          sv
            ?.toUpperCase()
            ?.trim()
            .includes(school[key]?.toUpperCase()?.trim()) ||
          sv
            ?.toUpperCase()
            ?.trim()
            .indexOf(school[key]?.toUpperCase()?.trim()) !== -1 ||
          school[key]
            ?.toUpperCase()
            ?.trim()
            .indexOf(sv?.toUpperCase()?.trim()) !== -1
        ) {
          score++;
        }
      });
    });
    if (
      score >= 1 &&
      filteredSchools.findIndex(fs => fs.bpNumber === school.bpNumber) === -1
    ) {
      const schoolCopy = {
        ...school,
        score,
      };
      filteredSchools.push(schoolCopy);
    }
  });
};

const getScoreByDates = (student, secondaryOrderedFilters) => {
  const {
    registrationStartDate,
    registrationEndDate,
    graduationStartDate,
    graduationEndDate,
  } = secondaryOrderedFilters;
  let score = 0;

  const { registerDate, graduationDate } = student;
  if (
    (registrationStartDate || registrationEndDate) &&
    isDateBetween(registerDate, registrationStartDate, registrationEndDate)
  ) {
    score++;
  }
  if (
    (graduationStartDate || graduationEndDate) &&
    isDateBetween(graduationDate, graduationStartDate, graduationEndDate)
  ) {
    score++;
  }
  return score;
};

const containsValue = (value, stringToCheck) => {
  return (
    value &&
    isString(value) &&
    (value?.toUpperCase()?.trim() === stringToCheck?.toUpperCase()?.trim() ||
      stringToCheck
        ?.toUpperCase()
        ?.trim()
        .includes(value?.toUpperCase()?.trim()))
  );
};

const filterByName = (student, name) => {
  let score = 0;
  const words = name.split(' ');
  for (const word of words) {
    if (containsValue(word, `${student.firstName} ${student.lastName}`)) {
      score++;
    }
  }

  return score;
};

const filterStudents = (
  listToSearchStudentsIn,
  secondaryOrderedFilters,
  filteredStudents,
  approveReject
) => {
  listToSearchStudentsIn?.length &&
    listToSearchStudentsIn.forEach(student => {
      let score = 0;

      Object.entries(secondaryOrderedFilters).forEach(filter => {
        const key = filter[0];
        const value = filter[1];
        if (!value) {
          return;
        }
        if (key === 'name') {
          score += filterByName(student, value);
        } else {
          if (containsValue(value, student[key])) {
            score++;
          }
        }
      });
      score += getScoreByDates(student, secondaryOrderedFilters);
      if (score >= 1) {
        const studentCopy = {
          ...student,
          score: score,
        };
        filteredStudents.push(studentCopy);
      }
    });
};

const filterBySchoolOrBpNumber = (
  searchInputs,
  approveReject,
  mainOrderedFilters,
  listToSearchIn
) => {
  const filteredSchools = [];
  const studentsToBeReturned = [];
  if (searchInputs.schoolName || searchInputs.bpNumber?.length) {
    if (approveReject) {
      filterElements(mainOrderedFilters, listToSearchIn, studentsToBeReturned);
    } else {
      studentsToBeReturned.push(
        ...getStudentsWithSchoolNameAndBpNumber(filteredSchools)
      );
      filterElements(mainOrderedFilters, listToSearchIn, filteredSchools);
    }
  }

  return studentsToBeReturned;
};

const filterResultsByStatus = (
  filteredStudents,
  hasSearchData,
  searchInputs,
  listToSearchStudentsIn,
  studentsToBeReturned,
  approveReject
) => {
  if (!filteredStudents.length) {
    if (!hasSearchData.hasSearchData) {
      return filterStudentsByActive(
        searchInputs.studentStatus,
        listToSearchStudentsIn
      );
    }

    return filterStudentsByActive(
      searchInputs.studentStatus,
      studentsToBeReturned
    );
  }
  if (approveReject) {
    return formatStudentsList(
      filterApprovedRejectStudentsByStatus(
        searchInputs.studentApprovedStatus,
        filteredStudents.sort(sortByFirstName)
      ).sort(sortByScore)
    );
  } else {
    return filterStudentsByActive(
      searchInputs.studentStatus,
      filteredStudents.sort(sortByName).sort(sortByScore)
    );
  }
};

export const studentsSearch = (
  listToSearchIn,
  searchInputs,
  approveReject = false
) => {
  const hasSearchData = getHasSearchData(searchInputs);
  if (
    (!hasSearchData.hasSearchData && !searchInputs.studentStatus) ||
    !listToSearchIn?.length
  ) {
    if (!listToSearchIn?.length || !approveReject) {
      return [];
    }

    return formatStudentsList(
      filterApprovedRejectStudentsByStatus(
        searchInputs.studentApprovedStatus,
        listToSearchIn
      )
    ).sort(sortByFirstName);
  }

  const mainOrderedFilters = {
    schoolName: searchInputs.schoolName,
    bpNumber: searchInputs.bpNumber,
  };

  const secondaryOrderedFilters = approveReject
    ? searchInputs
    : {
        email: searchInputs.email,
        name: searchInputs.name,
        studentStatus: searchInputs.studentStatus,
        phoneNumber: searchInputs.phoneNumber,
        graduationStartDate: searchInputs.graduationStartDate,
        graduationEndDate: searchInputs.graduationEndDate,
      };

  const studentsToBeReturned = filterBySchoolOrBpNumber(
    searchInputs,
    approveReject,
    mainOrderedFilters,
    listToSearchIn
  );

  let filteredSchools = [];
  let filteredStudents = [];
  let listToSearchStudentsIn = [];

  filterSchools(listToSearchIn, mainOrderedFilters, filteredSchools);
  filteredSchools.sort(sortByScore);

  if (approveReject) {
    listToSearchStudentsIn = [...listToSearchIn];
  } else {
    studentsToBeReturned.push(
      ...getStudentsWithSchoolNameAndBpNumber(filteredSchools)
    );
    listToSearchStudentsIn = studentsToBeReturned.length
      ? studentsToBeReturned
      : [...getStudentsWithSchoolNameAndBpNumber(listToSearchIn)];
  }

  filterStudents(
    listToSearchStudentsIn,
    secondaryOrderedFilters,
    filteredStudents,
    approveReject
  );

  return filterResultsByStatus(
    filteredStudents,
    hasSearchData,
    searchInputs,
    listToSearchStudentsIn,
    studentsToBeReturned,
    approveReject
  );
};

export const getSearchedStudents = (
  searchInputs,
  approveReject //TODO: when available
) => {
  const formattedSearchInputs = {
    name: searchInputs.name,
    schoolName: searchInputs.schoolName,
    emailAddress: searchInputs.email,
    bpNumber: searchInputs.bpNumber,
    phoneNumber: searchInputs.phoneNumber,
    graduationStartDate: getDateWithoutOffset(
      moment(searchInputs.graduationStartDate)
    ),
    graduationEndDate: getDateWithoutOffset(
      moment(searchInputs.graduationEndDate)
    ),
    status:
      searchInputs.studentStatus === 'all'
        ? null
        : Number(searchInputs.studentStatus === 'active'),
    // null for All Students, 1 for Active and 0 for Inactive
  };

  return ApiService.post(urls.getSearchedStudents, formattedSearchInputs);
};

export const updateDisabledStateCheckboxes = (
  getValues,
  setCheckboxesDisabled
) => {
  const values = getValues()['studentApprovedStatus'] || {};
  let count = 0;
  let checked = null;

  Object.keys(values).forEach(elem => {
    if (values[elem]) {
      count++;
      checked = elem;
    }
  });

  if (count === 1) {
    return setCheckboxesDisabled(prev => {
      const next = cloneDeep(prev);
      next[checked] = true;
      return next;
    });
  }

  setCheckboxesDisabled({
    approved: false,
    rejected: false,
    pending: false,
  });
};

export const getValidations = getValues => {
  const allFields = getValues();

  return {
    maxRegSD: allFields.registrationEndDate
      ? new Date(allFields.registrationEndDate)
      : undefined,
    minRegED: allFields.registrationStartDate
      ? new Date(allFields.registrationStartDate)
      : undefined,
    maxGradSD: allFields.graduationEndDate
      ? new Date(allFields.graduationEndDate)
      : undefined,
    minGradED: allFields.graduationStartDate
      ? new Date(allFields.graduationStartDate)
      : undefined,
  };
};

export const getAllSchools = () => ApiService.get(urls.getAllSchools);

export const formatSchoolNamesOptions = schoolNames =>
  schoolNames?.map(school => ({
    label: school.schoolName,
    value: school.schoolName,
  }));

export const getSchoolNamesTypeAhead = ({
  isAdmin,
  schoolsWithStudents,
  schoolListSelectedRep,
  setFieldOptions,
  schoolsListSearch,
}) =>
  !isAdmin || schoolListSelectedRep !== appStrings.searchInputs.allReps
    ? setFieldOptions(formatSchoolNamesOptions(schoolsWithStudents?.schools))
    : setFieldOptions(schoolsListSearch);

const getStudentNameSuggestions = value =>
  ApiService.get(urls.getStudentNameSuggestions(value));

const formatStudentNameOptions = studentNames =>
  studentNames.sort(sortByName).map(student => {
    const fullName = student.firstName + ' ' + student.lastName;
    return {
      label: fullName,
      value: fullName,
      link: ORDER(student.bpNumber, student.id ?? student.studentId),
    };
  });

export const getStudentNamesTypeAhead = ({
  isAdmin,
  schoolsWithStudents,
  schoolListSelectedRep,
  fieldValue,
  setFieldOptions,
  currentFieldValue,
}) => {
  !isAdmin || schoolListSelectedRep !== appStrings.searchInputs.allReps
    ? setFieldOptions(
        formatStudentNameOptions(
          getListOfAllStudents(schoolsWithStudents.schools)
        )
      )
    : fieldValue &&
      getStudentNameSuggestions(fieldValue).then(resp => {
        if (currentFieldValue.current === fieldValue) {
          setFieldOptions(formatStudentNameOptions(resp));
        }
      });
};
