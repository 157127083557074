import { useParams } from 'react-router-dom';
import useSendEmailModalDialogStyles from './useSendEmailDialogStyles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { emailPattern } from '../../../core/strings/regexValidations';
import {
  exportDisclosure,
  exportEcContract,
  exportOrderReceipt,
  getAllDisclosures,
} from '../ECContractSigning/ECContractSigningService';
import {
  displayError,
  displayInfo,
  displaySuccess,
} from '../../../core/redux/slices/notificationsSlice';
import appStrings from '../../../core/strings/appStrings';
import CustomDialog from '../../shared/CustomDialog/CustomDialog';
import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import CustomButton from '../../shared/CustomButton/CustomButton';
import { buttonTypes } from '../../../core/strings/appConstants';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { openExportDocument } from '../../../core/services/utilsService';
import {
  documentTypes,
  exportECApp,
  isInvalidEmail,
  sendEmail,
} from './SendEmailDialogService';
import CustomBackdrop from '../../shared/CustomBackdrop/CustomBackdrop';
import EmailInput from './Components/EmailInput';

function SendEmailDialog({
  isECOrder = false,
  isHistory = false,
  orderId,
  contractDocuments = [],
  handleClose,
}) {
  const css = useSendEmailModalDialogStyles().classes;
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isStudentChecked, setIsStudentChecked] = useState(false);
  const [isRepChecked, setIsRepChecked] = useState(false);

  const student = useSelector(
    state => state.schoolsWithStudents.selectedStudent.student
  );

  const userDetails = useSelector(state => state.login.userDetails);

  const [studentEmail, setStudentEmail] = useState(student?.email);
  const [repEmail, setRepEmail] = useState(userDetails?.email);
  const [additionalRecipients, setAdditionalRecipients] = useState(['']);
  const [selectedDocument, setSelectedDocument] = useState('');
  const [documents, setDocuments] = useState(contractDocuments);
  const [isLoading, setIsLoading] = useState(false);

  const hasDisclosure = () =>
    documents.some(
      document =>
        document.fileType !== 'receipt' && document.fileType !== 'contract'
    );

  const setDocument = evt => {
    setSelectedDocument(evt.target.value);
  };

  const viewDocument = () => {
    const selected = documents[selectedDocument];
    dispatch(displayInfo({ message: appStrings.export.exportInProgress }));
    if (selected.fileType === documentTypes.receipt.fileType) {
      exportOrderReceipt(id, orderId)
        .then(res => openExportDocument(res))
        .catch(e => displayError({ message: e }))
        .finally(() =>
          dispatch(
            displaySuccess({ message: appStrings.export.reportExported })
          )
        );
    } else if (selected.fileType === documentTypes.contract.fileType) {
      exportEcContract(id, orderId)
        .then(res => openExportDocument(res))
        .catch(e => displayError({ message: e }))
        .finally(() =>
          dispatch(
            displaySuccess({ message: appStrings.export.reportExported })
          )
        );
    } else if (selected.fileType === documentTypes.ecApp.fileType) {
      exportECApp(id, orderId)
        .then(res => openExportDocument(res))
        .catch(e => displayError({ message: e }))
        .finally(() =>
          dispatch(
            displaySuccess({ message: appStrings.export.reportExported })
          )
        );
    } else {
      exportDisclosure(selected.signatureRequestId, selected.packageId)
        .then(res => openExportDocument(res))
        .catch(e => displayError({ message: e }))
        .finally(() =>
          dispatch(
            displaySuccess({ message: appStrings.export.reportExported })
          )
        );
    }
  };

  const updateRecipient = (e, index) => {
    const updatedRecipients = cloneDeep(additionalRecipients);
    updatedRecipients[index] = e.target.value;
    setAdditionalRecipients(updatedRecipients);
  };

  const handleDeleteEmail = index => {
    if (additionalRecipients.length > 1) {
      const deleted = additionalRecipients.filter((item, i) => {
        return i !== index;
      });
      setAdditionalRecipients(deleted);
    } else {
      setAdditionalRecipients(['']);
    }
  };

  const handleAddEmail = () => {
    const lastEmailIndex = additionalRecipients.length;
    if (additionalRecipients[lastEmailIndex] !== '') {
      setAdditionalRecipients(oldRecipients => [...oldRecipients, '']);
    }
  };

  const canSendEmail = () => {
    const isStudentValid = isStudentChecked
      ? emailPattern.test(studentEmail)
      : true;
    const isRepValid = isRepChecked ? emailPattern.test(repEmail) : true;
    const recipientsValid = additionalRecipients.every(recipient => {
      return recipient === '' || emailPattern.test(recipient);
    });
    const hasAtLeastOneRecipient =
      isStudentChecked ||
      isRepChecked ||
      (additionalRecipients.length > 0 && additionalRecipients[0] !== '');

    return (
      isStudentValid && isRepValid && recipientsValid && hasAtLeastOneRecipient
    );
  };

  const sendEmails = () => {
    const recipients = [];
    if (isStudentChecked) {
      recipients.push(studentEmail);
    }
    if (isRepChecked) {
      recipients.push(repEmail);
    }
    if (additionalRecipients.some(Boolean)) {
      recipients.push(...additionalRecipients);
    }
    setIsLoading(true);
    sendEmail(
      {
        orderId,
        applicationId: orderId,
        studentId: id,
        recipients,
        hasDisclosure: hasDisclosure(),
      },
      isECOrder
    )
      .catch(e => {
        dispatch(displayError(e));
      })
      .finally(() => {
        dispatch(displaySuccess({ message: appStrings.wizard.emailsSent }));
        setIsLoading(false);
        handleClose();
      });
  };

  const getModalTitle = () => {
    if (isECOrder && !isHistory) {
      return appStrings.ecContractSigning.sendEmail;
    } else if (isECOrder && isHistory) {
      return appStrings.history.orderReceipt;
    } else {
      return appStrings.history.creditDetails;
    }
  };

  const buttons = [
    {
      label: appStrings.common.cancel,
      variant: 'outlined',
      className: css.button,
      onClick: handleClose,
    },
    {
      label: appStrings.ecContractSigning.sendEmails,
      className: css.button,
      disabled: !canSendEmail(),
      onClick: sendEmails,
    },
  ];

  useEffect(() => {
    if (documents?.length) {
      setSelectedDocument(documents[0].id);
    }
  }, [documents]);

  useEffect(() => {
    if (isHistory) {
      const orderDocuments = [];
      if (isECOrder) {
        setIsLoading(true);
        getAllDisclosures(id, orderId).then(res => {
          orderDocuments.push(documentTypes.receipt);
          orderDocuments.push(documentTypes.contract);
          if (res?.disclosures?.length) {
            orderDocuments.push(...res.disclosures[0].files);
          }
          setDocuments(
            orderDocuments.map((document, index) => ({
              ...document,
              id: index,
            }))
          );
          setIsLoading(false);
        });
      } else if (!isECOrder) {
        orderDocuments.push(documentTypes.ecApp);
        setDocuments(
          orderDocuments.map((document, index) => ({
            ...document,
            id: index,
          }))
        );
      }
    }
  }, [id, isECOrder, isHistory, orderId]);

  return (
    <>
      <CustomBackdrop open={isLoading} />
      <CustomDialog
        id="send-email"
        open={!isLoading}
        showCloseButton
        customClass={css.emailDialog}
        title={getModalTitle()}
        handleClose={handleClose}
        message={
          <Grid
            item
            xs={12}
            id="send-email-container"
            data-test-id="send-email-container"
          >
            {isHistory && (
              <Grid container className={css.container}>
                <Grid container spacing={1}>
                  <Grid item xs={7}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="document-label">
                        {appStrings.ecContractSigning.document}
                      </InputLabel>
                      <Select
                        labelId="document-label"
                        id="document-select"
                        value={selectedDocument}
                        label={appStrings.ecContractSigning.document}
                        onChange={setDocument}
                        renderValue={doc => documents[doc]?.label}
                      >
                        {documents?.map((document, index) => (
                          <MenuItem
                            key={`${document.id}-${index}`}
                            value={document.id}
                          >
                            {document.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <CustomButton
                      btnType="secondary"
                      label={appStrings.ecContractSigning.viewDocument}
                      onClick={viewDocument}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} className={css.divider}>
                  <Divider orientation="horizontal" />
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid item container>
                <Typography fontWeight="bold">
                  {appStrings.ecContractSigning.emailTo}
                </Typography>
              </Grid>
              <Grid item container>
                <EmailInput
                  value={studentEmail}
                  setValue={setStudentEmail}
                  label={appStrings.ecContractSigning.student}
                  isChecked={isStudentChecked}
                  setIsChecked={setIsStudentChecked}
                />
                <EmailInput
                  value={repEmail}
                  setValue={setRepEmail}
                  label={appStrings.ecContractSigning.industrialRep}
                  isChecked={isRepChecked}
                  setIsChecked={setIsRepChecked}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item container className={css.container}>
                <Typography fontWeight="bold">
                  {appStrings.ecContractSigning.additionalRecipients}
                </Typography>
              </Grid>
              <Grid item container>
                {additionalRecipients.map((recipient, index) => (
                  <Grid item container key={`${recipient}-${index}`}>
                    <Grid item xs={9}>
                      <TextField
                        error={isInvalidEmail(recipient)}
                        size="small"
                        defaultValue={recipient}
                        onBlur={e => updateRecipient(e, index)}
                        label={appStrings.ecContractSigning.addEmail}
                        className={css.emailInput}
                        helperText={
                          isInvalidEmail(recipient)
                            ? appStrings.ecContractSigning.invalidEmail
                            : ''
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <CustomButton
                        onClick={() => handleDeleteEmail(index)}
                        startIcon={<DeleteOutlineIcon />}
                        label={appStrings.common.delete}
                        btnType={buttonTypes.tertiary}
                        customClass={css.tertiaryButton}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item container className={css.buttonContainer}>
              <CustomButton
                onClick={handleAddEmail}
                label={appStrings.ecContractSigning.addNewEmail}
                btnType={buttonTypes.secondary}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider orientation="horizontal" />
            </Grid>
          </Grid>
        }
        buttons={buttons}
      />
    </>
  );
}

export default SendEmailDialog;
