import appStrings from '../../../core/strings/appStrings';
import { buttonColors, buttonTypes } from '../../../core/strings/appConstants';
import useTermsAndConditionsStyles from './useTermsAndConditionsStyles';
import { Dialog, Grid, Typography } from '@mui/material';
import Logo from '../../student/Header/Logo/Logo';
import CustomButton from '../CustomButton/CustomButton';
import { ArrowBack } from '@mui/icons-material';

function TermsAndConditions({ handleAgree, handleGoBack }) {
  const css = useTermsAndConditionsStyles().classes;
  const isDisplayOnly = !handleAgree && !handleGoBack;

  const buttons = [
    {
      label: appStrings.common.goBack,
      color: buttonColors.black,
      btnType: buttonTypes.secondary,
      onClick: handleGoBack,
      id: 'goBackButton',
    },
    {
      label: appStrings.common.agree,
      color: buttonColors.red,
      btnType: buttonTypes.primary,
      onClick: handleAgree,
      id: 'agreeButton',
    },
  ];

  return (
    <Dialog className={css.container} open={true}>
      <Grid className={css.header} container justifyContent="space-between">
        <Grid item xs={8} sm={8} lg={10}>
          <Logo customClass={isDisplayOnly ? '' : css.logo} />
        </Grid>
        <Grid item container xs={4} sm={4} lg={1} justifyContent="flex-end">
          {!isDisplayOnly && (
            <CustomButton
              {...buttons[0]}
              key={buttons[0].label}
              startIcon={<ArrowBack />}
              customClass={css.goBack}
              id="headerGoBackButton"
            />
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent="center" className={css.content}>
        <Grid
          item
          container
          lg={6}
          sm={10}
          xs={11}
          className={css.paper}
          id="termsAndConditionsContent"
        >
          <Grid item xs={12}>
            <Typography className={css.title} fontWeight="bold" variant="h2">
              {appStrings.termsAndConditions.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={css.body}>
              {appStrings.termsAndConditions.content}
            </Typography>
          </Grid>
          <Grid item container xs={12} spacing={2}>
            {!isDisplayOnly &&
              buttons.map(button => (
                <Grid
                  key={button.label}
                  item
                  xs={12}
                  sm={6}
                  justifyContent="center"
                  className={
                    button.btnType === buttonTypes.secondary
                      ? css.secondaryButton
                      : ''
                  }
                >
                  <CustomButton {...button} customClass={css.action} />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default TermsAndConditions;
