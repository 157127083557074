import { makeStyles } from 'tss-react/mui';

const useLoadingOverlayStyles = makeStyles()(theme => ({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: '9',
    backgroundColor: theme.palette.common.neutral1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingContent: {
    width: '50%',
    textAlign: 'center',
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: theme.palette.common.neutral5,
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.common.grey,
    },
  },
  logo: {
    width: '150px',
  },
  text: {
    fontSize: '1.5rem',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '10px',
    color: theme.palette.common.neutral5,
  },
}));

export default useLoadingOverlayStyles;
