import { makeStyles } from 'tss-react/mui';

const useNavTabStyles = makeStyles()((theme, _params, css) => ({
  navigationTab: {
    [`& .${css.icon}`]: {
      display: 'block',
      fontSize: theme.typography.h6.fontSize,
      margin: '0 auto',
    },
    '& a': {
      textDecoration: 'none',
    },
    '& span': {
      color: theme.palette.text.secondary,
      '&:hover': {
        color: theme.palette.text.primary,
        cursor: 'pointer',
      },
    },
    '& p': {
      color: theme.palette.text.secondary,
      cursor: 'pointer',
      margin: 0,
      padding: 0,
      '&:hover': {
        color: theme.palette.text.primary,
      },
    },
  },
  icon: { '& .MuiBadge-badge': { color: 'white !important' } }, //needed in creating the specificity of the class
  disabled: {
    pointerEvents: 'none',

    '& span': {
      color: theme.palette.text.disabled,
    },
    '& p': {
      color: theme.palette.text.disabled,
    },
  },
  hideLabel: {
    display: 'flex',
    padding: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useNavTabStyles;
