import { Divider, Grid, Typography } from '@mui/material';

import CustomButton from '../CustomButton/CustomButton';
import { REGISTER } from '../../../core/navigation/paths';
import appStrings from '../../../core/strings/appStrings';
import { buttonColors, buttonTypes } from '../../../core/strings/appConstants';
import useLoginStyles from './useLoginStyles';
import { useNavigate } from 'react-router';

function CreateAccount() {
  const navigate = useNavigate();
  const css = useLoginStyles().classes;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Divider className={css.divider}>OR</Divider>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs>
          <Typography className={css.registerTitle}>
            {appStrings.login.newToSnapOn}
          </Typography>
        </Grid>

        <Grid item>
          <Typography>{appStrings.login.registerDescription}</Typography>
        </Grid>

        <Grid item xs>
          <CustomButton
            btnType={buttonTypes.secondary}
            color={buttonColors.black}
            label={appStrings.login.register}
            data-test-id="createAccount-button"
            id="createAccount-button"
            size="large"
            customClass={css.registerButton}
            onClick={() => navigate(REGISTER)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
export default CreateAccount;
