import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import CONSTANTS from '../constants';
import { getSchools } from '../../../modules/industrial/SchoolsList/Services/studentListService';
import { loadingStatuses } from '../../strings/appConstants';
import { sortBy } from 'lodash';

const formatStudent = student => {
  return {
    active: student.approveStudent,
    email: student.email,
    firstName: student.firstName,
    lastName: student.lastName,
    id: student.id,
    userName: null,
    bpNumber: student.schoolBpNumber,
  };
};

const sortByFirstAndLastName = (a, b) => {
  let nameA = `${a.firstName.toUpperCase()} ${a.lastName.toUpperCase()}`;
  let nameB = `${b.firstName.toUpperCase()} ${b.lastName.toUpperCase()}`;
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export const fetchSchoolsByEmployeeId = createAsyncThunk(
  `${CONSTANTS.schoolsWithStudentsSlice}/fetchSchoolsByEmployeeId`,

  async (arg, { getState, requestId }) => {
    const state = getState();
    const { employeeId } = state.login.userDetails;
    const { currentRequestId, loading } = state.schoolsWithStudents;

    if (loading !== loadingStatuses.pending || requestId !== currentRequestId) {
      return {};
    }
    try {
      return await getSchools({ id: employeeId });
    } catch (err) {
      console.error(err);
      return {};
    }
  }
);

const _resetSchoolFilters = state => {
  for (const key in state.schoolsFilters) {
    delete state.schoolsFilters[key];
  }
};

const schoolsWithStudentsSlice = createSlice({
  name: CONSTANTS.schoolsWithStudentsSlice,
  initialState: {
    currentRequestId: null,
    employeeIdSearched: null,
    entities: [],
    error: null,
    loading: loadingStatuses.idle,
    schools: null,
    repList: null,
    schoolsFilters: {},
    selectedStudent: { bpNumber: null, student: null },
    schoolListSelectedRep: null,
  },
  reducers: {
    updateSchoolList: (state, action) => {
      state.schools = action.payload?.res || [];
      state.employeeIdSearched = action.payload?.employeeId || null;
      _resetSchoolFilters(state);
    },
    updateSchoolFilter: (state, action) => {
      if (state.schools?.length) {
        state.schoolsFilters[action.payload.bpNumber] =
          action.payload.showInactive;
      }
    },
    resetSchoolFilters: state => {
      _resetSchoolFilters(state);
    },
    // payload = {bpNumber, student || id}
    selectedStudent: (state, action) => {
      if (action.payload.id && state.schools?.length) {
        const school = state.schools?.find(
          s => s.bpNumber === action.payload.bpNumber
        );
        const student = school?.students.find(
          user => user.id === action.payload.id
        );
        state.employeeIdSearched = action.payload.id;
        state.selectedStudent = { bpNumber: action.payload.bpNumber, student };
      } else {
        state.selectedStudent = { ...action.payload };
      }
    },
    resetSelectedStudent: state => {
      delete state.selectedStudent.student;
      state.selectedStudent.student = null;
      state.selectedStudent.bpNumber = null;
    },
    updateEditedStudent: (state, action) => {
      let studentIndex;
      const schoolIndex = state.schools.findIndex(school => {
        return (
          school.students.findIndex((student, index) => {
            if (student.id === action.payload.id) {
              studentIndex = index;
              return true;
            }
            return false;
          }) !== -1
        );
      });
      if (schoolIndex > -1) {
        // update student values displayed in the schools list
        state.schools[schoolIndex].students[studentIndex].firstName =
          action.payload.firstName;
        state.schools[schoolIndex].students[studentIndex].lastName =
          action.payload.lastName;
        state.schools[schoolIndex].students[studentIndex].email =
          action.payload.email;
        // if the student active status has changed, set the new status
        state.schools[schoolIndex].students[studentIndex].active =
          action.payload.isActive ??
          state.schools[schoolIndex].students[studentIndex].active;
        // if schoolBp changed move the student to the new school
        if (
          action.payload.schoolBp &&
          action.payload.schoolBp !== state.schools[schoolIndex].bpNumber
        ) {
          const newSchoolIndex = state.schools.findIndex(school => {
            return school.bpNumber === action.payload.schoolBp;
          });
          state.schools[newSchoolIndex].students.push(
            state.schools[schoolIndex].students[studentIndex]
          );
          state.schools[schoolIndex].students.splice(studentIndex, 1);
          state.schools[newSchoolIndex].students = sortBy(
            state.schools[newSchoolIndex].students,
            ['firstName', 'lastName']
          );
        }
      }
    },
    registerStudentInList: (state, { payload }) => {
      if (payload.approveStudent && state.schools?.length) {
        const registeredStudent = formatStudent(payload);

        const schoolIndex = state.schools?.findIndex(
          s => s.bpNumber === registeredStudent.bpNumber
        );

        if (schoolIndex !== -1) {
          state.schools[schoolIndex].students.push(registeredStudent);
          state.schools[schoolIndex].students.sort(sortByFirstAndLastName);
        }
      }
    },
    updateSchoolsRepList: (state, { payload }) => {
      state.repList = payload;
    },
    setSchoolListRep: (state, action) => {
      state.schoolListSelectedRep = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchSchoolsByEmployeeId.pending, (state, action) => {
      if (state.loading === loadingStatuses.idle) {
        state.loading = loadingStatuses.pending;
        state.currentRequestId = action.meta.requestId;
      }
    });
    builder.addCase(fetchSchoolsByEmployeeId.fulfilled, (state, action) => {
      const { requestId } = action.meta;
      if (
        state.loading === loadingStatuses.pending &&
        state.currentRequestId === requestId
      ) {
        state.loading = loadingStatuses.idle;
        state.schools = action.payload || [];
        state.schoolsFilters = {};
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(fetchSchoolsByEmployeeId.rejected, (state, action) => {
      const { requestId } = action.meta;
      if (
        state.loading === loadingStatuses.pending &&
        state.currentRequestId === requestId
      ) {
        state.loading = loadingStatuses.idle;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    });
  },
});

export const {
  resetSchoolFilters,
  resetSelectedStudent,
  selectedStudent,
  updateSchoolList,
  updateSchoolFilter,
  updateEditedStudent,
  registerStudentInList,
  updateSchoolsRepList,
  setSchoolListRep,
} = schoolsWithStudentsSlice.actions;
export default schoolsWithStudentsSlice.reducer;
