import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import {
  fetchSchoolsByEmployeeId,
  selectedStudent,
} from '../redux/slices/schoolsWithStudentsSlice';

function IndustrialRoutesServices() {
  const { id, bp } = useParams();
  const { schools } = useSelector(state => state.schoolsWithStudents);

  const dispatch = useDispatch();

  const getSchools = useCallback(() => {
    dispatch(fetchSchoolsByEmployeeId(''));
  }, [dispatch]);

  useEffect(() => {
    if (!schools && bp && id) {
      getSchools();
      dispatch(selectedStudent({ bpNumber: bp, id }));
    }
  }, [bp, dispatch, getSchools, id, schools]);

  return <Outlet />;
}

export default IndustrialRoutesServices;
