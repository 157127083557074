import appStrings from '../../../core/strings/appStrings';
import urls from '../../../core/strings/urls';
import ApiService from '../../shared/Api/apiService';

const gridLayout = { xs: 12 };

export const calculateRepaymentConversions = (studentId, orderId) => {
  return ApiService.get(urls.calculateRepaymentConversions(studentId, orderId));
};

export const getDirectDebit = (studentId, orderId) => {
  return ApiService.get(urls.getDirectDebit(studentId, orderId));
};

export const saveDirectDebit = (studentId, orderId, data) => {
  return ApiService.post(urls.saveDirectDebit(studentId, orderId), data);
};

export const directDebitInputs = (
  directDebitOptions,
  onDrawOptionChange,
  selectedDrawFrequencyInfo
) => {
  const isNoDraw = !selectedDrawFrequencyInfo.amount;
  return [
    {
      name: 'drawOption',
      label: appStrings.order.directDebitFields.drawOption,
      type: 'select',
      options: directDebitOptions,
      gridLayout: { xs: 6 },
      onChangeCallback: onDrawOptionChange,
    },
    {
      name: 'directDebitDate',
      label: appStrings.order.directDebitFields.date,
      type: 'date',
      minDate: selectedDrawFrequencyInfo.lowerDateLimit,
      maxDate: selectedDrawFrequencyInfo.upperDateLimit,
      gridLayout: { xs: 6 },
      disabled: isNoDraw,
      validations: {
        required: !isNoDraw,
      },
    },
    {
      name: 'directDebitAmount',
      label: appStrings.order.directDebitFields.amount,
      type: 'currency',
      gridLayout: { xs: 6 },
      disabled: isNoDraw,
      validations: {
        required: !isNoDraw,
        validate: {
          minValue: value =>
            !value ||
            Number(value) >= selectedDrawFrequencyInfo.amount ||
            appStrings.validationMessages.minAmountDefined(
              selectedDrawFrequencyInfo.amount
            ),
        },
      },
    },
    {
      name: 'directDebitInstitution',
      label: appStrings.order.directDebitFields.institution,
      type: 'text',
      gridLayout,
      disabled: isNoDraw,
      validations: {
        required: !isNoDraw,
      },
    },
    {
      name: 'directDebitRoutingNumber',
      label: appStrings.order.directDebitFields.routingNumber,
      type: 'number',
      gridLayout: { xs: 6 },
      disabled: isNoDraw,
      validations: {
        required: !isNoDraw,
        maxLength: 9,
      },
    },
    {
      name: 'directDebitAccountNumber',
      label: appStrings.order.directDebitFields.accountNumber,
      type: 'number',
      gridLayout,
      disabled: isNoDraw,
      validations: {
        required: !isNoDraw,
      },
    },
  ];
};
