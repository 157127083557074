import { Fragment, useCallback, useEffect, useState } from 'react';
import { handleErrors, handleSaveNotification, isSignNowBtnClicked } from '../wizardPageContainerService';

import CustomBox from '../../../CustomBox/CustomBox';
import FormInput from '../../../FormInput/FormInput';
import { Grid } from '@mui/material';
import WizardActionButtons from '../../WizardActionButtons/WizardActionButtons';
import WizardFormSkeleton from '../../WizardFormSkeleton/WizardFormSkeleton';
import appStrings from '../../../../../core/strings/appStrings';
import { getCustomerInfoInputs } from './customerInfoConstants';
import { getSchoolInformation } from '../../../StudentProfileForm/SchoolInformation/schoolInformationService';
import { getStudentDetails } from '../../../StudentProfileForm/studentProfileFormService';
import { handleSaveErrors } from '../../../../industrial/StudentProfile/Services/studentProfileService';
import { saveInformation } from './customerInfoService';
import {
  setActiveStep,
  setPossibleFieldName,
} from '../../../../../core/redux/slices/wizardSlice';
import setMultipleValues from '../../../../../core/services/formService';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useWizardStyles from '../../useWizardStyles';
import { wizardStepsCodes } from '../../wizardConstants';
import LeaveDialog from '../../../LeaveDialog/LeaveDialog';
import { dialogBtnActions } from '../../../LeaveDialog/leaveDialogConstants';
import PropTypes from 'prop-types';
import MandatoryFieldsMessage from '../../../MandatoryFieldsMessage/MandatoryFieldsMessage';

function CustomerInfo({ setDirtyInfos, shouldSaveOnLeavePage }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const css = useWizardStyles().classes;

  const [disableDriverLicenseState, setDisableDriverLicenseState] =
      useState(false);
  const [disableUsesTools, setDisableUsesTools] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [shouldGetCustomerInfo, setShouldGetCustomerInfo] = useState(true);
  const [initialFormValues, setInitialFormValues] = useState();

  const validationsPossibleFieldName = useSelector(state => state.wizard.possibleFieldName);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    formState,
    reset,
    setError,
    getFieldState,
    clearErrors,
  } = useForm();
  const { isDirty, errors } = formState;
  const possibleFieldNameDirty = getFieldState(
    validationsPossibleFieldName?.fieldName
  ).isDirty;

  useEffect(() => {
    if (validationsPossibleFieldName) {
      const { fieldName, howToFix } = validationsPossibleFieldName;
      setError(fieldName, { type: 'manual', message: howToFix });
    }
  }, [validationsPossibleFieldName, setError]);

  useEffect(() => {
    if (possibleFieldNameDirty) {
      clearErrors(validationsPossibleFieldName.fieldName);
      dispatch(
        setPossibleFieldName({
          fieldName: '',
          howToFix: '',
        })
      );
    }
  }, [
    clearErrors,
    dispatch,
    possibleFieldNameDirty,
    validationsPossibleFieldName.fieldName,
  ]);

  useEffect(() => {
    if (shouldGetCustomerInfo) {
      setShouldGetCustomerInfo(false);
      setLoading(true);

      Promise.all([getStudentDetails(id), getSchoolInformation(id)])
          .then(resp => {
            setMultipleValues({setValue, values: {...resp[0], ...resp[1]}});
            setInitialFormValues(getValues());
            reset(getValues());
          })
          .catch(e => handleErrors(e))
          .finally(() => {
            setLoading(false);
            if (validationsPossibleFieldName) {
              const {fieldName, howToFix} = validationsPossibleFieldName;
              if (fieldName && howToFix) {
                setError(fieldName, {type: 'manual', message: howToFix});
              }
            }
          });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDirtyInfos(isDirty);
    shouldSaveOnLeavePage.current = isDirty;
  }, [isDirty, setDirtyInfos, shouldSaveOnLeavePage]);

  const onSuccessCallback = useCallback(() => {
    if (isLoading) {
      setLoading(false);
    }
    setDirtyInfos(false);
    handleSaveNotification(true);
  }, [isLoading, setDirtyInfos]);

  const onErrorCallback = useCallback(e => {
    handleSaveErrors(e, setError);
    setLoading(false);
  }, [setError]);

  const onSubmit = () => {
    if (!isSignNowBtnClicked()) {
      dispatch(setActiveStep(wizardStepsCodes.addressInfo));
    }
  };

  const onLeaveSubmit = (data, callback) => {
    setLoading(true);
    saveInformation(data, reset, id, callback, onErrorCallback);
  };

  const customReset = () => {
    setMultipleValues({ setValue, values: initialFormValues });
    reset();
  };

  const onLeave = () => {
    setLoading(true);
    saveInformation(getValues(), reset, id, onSuccessCallback, onErrorCallback);
  };

  useEffect(() => {
    return () => {
      if (shouldSaveOnLeavePage.current) {
        onLeave();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const leaveDialogProps = {
    title: appStrings.modal.ecApp.title,
    description: appStrings.modal.ecApp.description,
    isDirty,
    reset: customReset,
    watch,
    onSubmit: onLeaveSubmit,
    handleSubmit,
    buttons: [
      dialogBtnActions.stay,
      dialogBtnActions.leaveNoSave,
      dialogBtnActions.leaveAndSave,
    ],
    errors,
    dispatch,
    id: 'customerInfoUnsavedChangesDialog',
    disableLeaveTimeout: true,
  };

  return (
    <Grid>
      <form
        onSubmit={e => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
        name="studentProfileWizard"
        noValidate
        autoComplete="off"
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          display={isLoading ? 'none' : ''}
          spacing={3}
        >
          <Grid item>
            <CustomBox fadeBorder>
              <Grid
                alignItems="flex-start"
                container
                direction="row"
                spacing={2}
                className={css.formContainer}
              >
                <Grid item xs={12}>
                  <h3 id="customerInfoSectionTitle">
                    {appStrings.wizard.customerInfo}
                  </h3>
                </Grid>

                {getCustomerInfoInputs({
                  getValues,
                  setValue,
                  disableDriverLicenseState,
                  setDisableDriverLicenseState,
                  disableUsesTools,
                  setDisableUsesTools,
                })?.map(field => {
                  return (
                    <Fragment key={`${field.name}-container`}>
                      <Grid
                        item
                        {...field.gridLayout}
                        key={`${field.name}-container`}
                        data-test-id={`${field.name}`}
                      >
                        <FormInput
                          field={field}
                          control={control}
                          setValue={setValue}
                          getValues={getValues}
                          watch={watch}
                          errors={errors}
                        />
                      </Grid>
                    </Fragment>
                  );
                })}
              </Grid>
              <Grid container className={css.mandatoryMessageContainer}>
                <MandatoryFieldsMessage />
              </Grid>
            </CustomBox>
          </Grid>
          <Grid item>
            <WizardActionButtons handleSubmit={handleSubmit} />
          </Grid>
        </Grid>
        <LeaveDialog {...leaveDialogProps} />
      </form>

      {isLoading && <WizardFormSkeleton />}
    </Grid>
  );
}

CustomerInfo.prototypes = {
  setDirtyInfos: PropTypes.func,
  shouldSaveOnLeavePage: PropTypes.object,
};

export default CustomerInfo;
