import {
  historySortBy,
  historySortDirection,
  historyTransactionType,
} from '../../strings/appConstants';

import CONSTANTS from '../constants';
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const defaultEndDate = moment().endOf('day').toISOString();
const daysInPastToShow = 90;
const defaultStartDate = moment()
  .startOf('day')
  .subtract(daysInPastToShow, 'days')
  .toISOString();

const historySlice = createSlice({
  name: CONSTANTS.history,
  initialState: {
    details: null,
    rowsCount: 0,
    filters: {
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      transactionType: `${historyTransactionType.none}`,
      sortBy: historySortBy.date,
      sortDirection: historySortDirection.descending,
      page: {
        pageNumber: 0,
      },
    },
  },
  reducers: {
    saveHistoryDetails: (state, { payload }) => {
      state.details = payload.details;
      state.rowsCount = payload.rowsCount;
    },
    updateFilters: (state, { payload }) => {
      state.filters = payload;
    },
    resetFilters: state => {
      state.filters = {
        startDate: defaultStartDate,
        endDate: defaultEndDate,
        transactionType: `${historyTransactionType.none}`,
        sortBy: historySortBy.date,
        sortDirection: historySortDirection.descending,
        page: {
          pageNumber: 0,
        },
      };
    },
  },
});

export const { saveHistoryDetails, updateFilters, resetFilters } =
  historySlice.actions;
export default historySlice.reducer;
