import moment from 'moment';
import urls from '../../../../core/strings/urls';
import { getFormattedValue } from '../../../industrial/PurchasePower/purchasePowerService';
import ApiService from '../../../shared/Api/apiService';
import { profileInfosConstants } from './profileConstants';
import { dateFormat, phoneMask } from '../../../../core/strings/appConstants';
import NumberFormat from 'react-number-format';

export const getStudentInfos = studentId =>
  ApiService.get(urls.getStudentProfileInfos(studentId));

export const formatStudentProfileDetails = profileDetails => {
  const maxPurchaseLimit = 12000;
  let details = [];
  const address = profileDetails?.studentAddress?.permanentAddress;

  profileInfosConstants.forEach(info => {
    const label = info.label;
    const name = info.name;
    const id = `studentProfile-${info.name}`;

    if (name === 'address') {
      details.push({
        label: label,
        value: address
          ? `${address?.addressLine1}, ${
              address?.zipCode
            }, ${address?.state.toUpperCase()}`
          : '-',
        id,
      });
    } else if (name === 'graduationDate') {
      details.push({
        label: label,
        value: moment(new Date(profileDetails[name])).format(dateFormat),
        id,
      });
    } else if (name === 'phoneNumber' || name === 'accountManagerPhoneNumber') {
      details.push({
        label: label,
        value: profileDetails[name] ? (
          <NumberFormat
            format={phoneMask}
            value={profileDetails[name]}
            displayType="text"
          />
        ) : (
          '-'
        ),
        id,
      });
    } else if (name === 'purchasingLimit') {
      details.push({
        label: label,
        value:
          profileDetails?.purchasedValue !== null
            ? `${getFormattedValue(
                profileDetails.purchasedValue
              )} of ${getFormattedValue(maxPurchaseLimit)}`
            : '-',
        id,
      });
    } else {
      details.push({
        label: label,
        value: profileDetails[name] || '-',
        id,
      });
    }
  });

  return details;
};
