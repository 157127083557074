import appStrings from '../../../core/strings/appStrings';

export const purchaseTypes = {
  none: 0,
  cart: 1,
  order: 2,
  saved: 3,
  restore: 4,
};

export const purchaseTypeLabels = {
  1: appStrings.cart.currentCartValue,
  2: appStrings.order.currentOrderValue,
  3: appStrings.cart.currentSavedValue,
  4: appStrings.restorePurchase.returnListPrice,
};
