import { Divider, Grid } from '@mui/material';

import CustomAccordion from '../CustomAccordion/CustomAccordion';
import CustomAccordionContainer from '../CustomAccordionContainer/CustomAccordionContainer';
import CustomBox from '../CustomBox/CustomBox';
import { Fragment } from 'react';

function CustomAccordionSkeleton({ css, numberOfSections, titleSkeleton }) {
  return (
    <>
      <Grid item xs={12} data-test-id="schoolsListSkeleton" className={css}>
        <CustomBox>
          <CustomAccordionContainer>
            {Array.from(Array(numberOfSections).keys()).map((number, index) => (
              <Fragment key={number}>
                <CustomAccordion
                  id={number}
                  expanded={false}
                  title={titleSkeleton}
                ></CustomAccordion>
                {index < numberOfSections - 1 ? <Divider /> : null}
              </Fragment>
            ))}
          </CustomAccordionContainer>
        </CustomBox>
      </Grid>
    </>
  );
}

export default CustomAccordionSkeleton;
