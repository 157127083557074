import appStrings from '../../../core/strings/appStrings';
import { emailPattern } from '../../../core/strings/regexValidations';
import { snaponDomain } from '../../../core/strings/appConstants';

export const gridLayout = {
  xs: 11,
};
export const gridLayoutCheckbox = {
  xs: 1,
};
export const gridLayoutAdditionalEmails = {
  xs: 9,
};

export const sendEmailInputs = ({
  shouldSendToStudent,
  setShouldSendStudent,
  shouldSendToRep,
  setShouldSendToRep,
}) => [
  {
    name: 'shouldSendToStudent',
    type: 'checkbox',
    value: shouldSendToStudent,
    disabled: false,
    onChangeCallback: setShouldSendStudent,
    gridLayout: gridLayoutCheckbox,
    defaultValue: true,
  },
  {
    name: 'studentEmail',
    label: appStrings.common.student,
    type: 'text',
    validations: {
      validate: {
        emailDomain: value =>
          !value.includes(snaponDomain) ||
          appStrings.validationMessages.emailBadDomain,
        emailPattern: value =>
          emailPattern.test(value) || appStrings.validationMessages.emailFormat,
      },
      maxLength: 100,
    },
    gridLayout,
  },
  {
    name: 'shouldSendToRep',
    type: 'checkbox',
    value: shouldSendToRep,
    onChangeCallback: setShouldSendToRep,
    gridLayout: gridLayoutCheckbox,
  },
  {
    name: 'repEmail',
    label: appStrings.wizard.industrialRep,
    type: 'text',
    validations: {
      validate: {
        emailPattern: value =>
          emailPattern.test(value) || appStrings.validationMessages.emailFormat,
      },
      maxLength: 100,
    },
    gridLayout,
  },
];

export const getOtherRecipients = index => {
  return {
    name: `otherEmails.${index}`,
    label: appStrings.wizard.addEmail,
    type: 'text',
    validations: {
      validate: {
        emailPattern: value =>
          value === '' ||
          emailPattern.test(value) ||
          appStrings.validationMessages.emailFormat,
      },
      maxLength: 100,
    },
    gridLayout: gridLayoutAdditionalEmails,
  };
};
