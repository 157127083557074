import { makeStyles } from 'tss-react/mui';

const disabledBorderStyle = theme => `1px solid ${theme.palette.common.red}`;
const useSelectFieldStyles = makeStyles()(theme => ({
  paper: {
    maxHeight: '200px',
    boxShadow: 'none',
    border: `1px solid ${theme.palette.common.fadeGrey}`,
  },
  wrapper: {
    width: '100%',
  },
  label: {
    marginRight: theme.spacing(4),
  },
  disabled: {
    border: disabledBorderStyle(theme),
  },
  disabledAutoComplete: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: disabledBorderStyle(theme),
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.common.red,
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      border: disabledBorderStyle(theme),
    },
  },
  icon: {
    border: 'none',
  },
  optionDescription: {
    fontSize: '0.8rem',
    color: theme.palette.common.grey,
  },
  errorMessage: {
    fontSize: '0.8rem',
    color: theme.palette.common.red,
    marginTop: '6px',
  },
  optionLink: {
    '& .MuiLink-underlineAlways': {
      display: 'flex',
      textDecoration: 'none',
      color: theme.palette.common.secondary,
    },
    '& .MuiTypography-root': {
      marginTop: '1px',
      fontSize: '12px',
    },
    '& .MuiSvgIcon-root': {
      marginTop: '1px',
      marginLeft: '4px',
      fontSize: '18px',
    },
  },
}));

export default useSelectFieldStyles;
