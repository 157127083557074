import { Grid, Typography } from '@mui/material';
import {
  dateFormat,
  historyTransactionTypeByCode,
  orderStatusesByCode,
  orderStatusesByName,
} from '../../../../core/strings/appConstants';

import CustomBox from '../../../shared/CustomBox/CustomBox';
import appStrings from '../../../../core/strings/appStrings';
import classNames from 'classnames';
import moment from 'moment';
import useOrderHistoryDetailsStyles from '../useOrderHistoryDetailsStyles';

function OrderDetails({ orderDetails }) {
  const css = useOrderHistoryDetailsStyles().classes;

  return !orderDetails ? null : (
    <Grid
      item
      xs={12}
      data-test-id="orderHistoryDetails-orderDetails"
      id="historyOrderDetailsContainer"
    >
      <CustomBox customClass={css.customBox}>
        <Grid container id="historyOrderDetails">
          <Grid item xs={12}>
            <Typography
              variant="h3"
              className={css.sectionTitle}
            >{`${appStrings.history.orderDetails}:`}</Typography>
          </Grid>
          <Grid item xs={12} id="historyOrderDetails-orderStatus">
            <Typography>
              {`${appStrings.orderDetailsHistory.orderStatus}: `}
              <span
                className={classNames({
                  [css.greenText]:
                    orderDetails.orderStatus === orderStatusesByName.completed,
                  [css.yellowText]:
                    orderDetails.orderStatus === orderStatusesByName.pending,
                  [css.redText]:
                    orderDetails.orderStatus !== orderStatusesByName.pending &&
                    orderDetails.orderStatus !== orderStatusesByName.completed,
                })}
              >
                {orderStatusesByCode[orderDetails?.orderStatus]}
              </span>
            </Typography>
          </Grid>

          <Grid item xs={12} id="historyOrderDetails-orderNumber">
            <Typography>
              {`${appStrings.orderDetailsHistory.orderNumber}: ${orderDetails?.orderNumber}`}
            </Typography>
          </Grid>

          <Grid item xs={12} id="historyOrderDetails-orderType">
            <Typography>
              {`${appStrings.order.orderType}:${
                historyTransactionTypeByCode[orderDetails?.type]
              }`}
            </Typography>
          </Grid>

          <Grid item xs={12} id="historyOrderDetails-orderSubmitDate">
            <Typography>
              {`${appStrings.orderDetailsHistory.orderSubmitDate}: ${moment(
                orderDetails?.submittedDate
              ).format(dateFormat)}`}
            </Typography>
          </Grid>

          <Grid item xs={12} id="historyOrderDetails-orderedBy">
            <Typography>
              {`${appStrings.orderDetailsHistory.orderedBy}: ${orderDetails?.userAudit?.createdByRole}`}
            </Typography>
          </Grid>
        </Grid>
      </CustomBox>
    </Grid>
  );
}

export default OrderDetails;
