import useFormInputStyles, { helperTextStyle } from '../useFormInputStyles';

import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { TextField } from '@mui/material';
import appStrings from '../../../../core/strings/appStrings';
import propTypes from '../propTypes';
import { forwardRef } from 'react';

const NumberFormatCustom = forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            // the below logic is necessary to avoid maxLength being added on input, which blocks editing when maxLength is reached
            value:
              values.value.length > 11
                ? (values.floatValue.toFixed(2) / 10)
                    .toFixed(3)
                    .toString()
                    .slice(0, -1)
                : values.value,
          },
        });
      }}
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale={true}
      prefix={appStrings.common.currencySign}
    />
  );
});

function CurrencyField({ field, fieldErrors, ...props }) {
  const helperCss = helperTextStyle().classes;
  const css = useFormInputStyles().classes;

  const getHelperText = () => {
    if (fieldErrors && fieldErrors.type !== 'required') {
      return field.helperText ? field.helperText : fieldErrors.message || ' ';
    }
    return ' ';
  };

  const handleOnKeyPress = event => {
    if (event.key === 'Enter' && !event.shiftKey && field.onEnterCallback) {
      field.onEnterCallback();
    }
    if (event.key === 'Enter' && event.shiftKey && field.onShiftEnterCallback) {
      field.onShiftEnterCallback();
    }
  };

  return (
    <Controller
      control={props.control}
      defaultValue={field.defaultValue ?? ''}
      name={field.name}
      rules={{ ...field.validations }}
      render={({ field: { ref, onChange, value } }) => (
        <>
          <TextField
            value={value}
            variant="outlined"
            onKeyPress={handleOnKeyPress}
            label={field.label}
            inputRef={e => {
              ref.current = e;
              if (props?.fieldRef) {
                props.fieldRef.current = e;
              }
            }}
            required={field.validations?.required}
            size={props.size ?? 'small'}
            error={fieldErrors ? true : false}
            className={css.simpleInput}
            helperText={getHelperText()}
            disabled={field.disabled ?? false}
            InputProps={{
              inputComponent: NumberFormatCustom,
              ...props.InputProps,
              ...field.validations,
              readOnly: field.readOnly,
              'data-test-id': `currencyInput-${field.name}`,
            }}
            autoComplete="off"
            FormHelperTextProps={{
              classes: { root: helperCss.root },
              'data-test-id': 'validation-helperText',
            }}
            fullWidth={props.fullWidth}
            onChange={e => {
              onChange(e.target.value);
              field.onChangeCallback && field.onChangeCallback();
            }}
            onBlur={field.onBlurCallback}
            id={field.name}
            name={field.name}
          />
        </>
      )}
    />
  );
}
CurrencyField.propTypes = propTypes;
export default CurrencyField;
