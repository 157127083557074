import { useNavigate } from 'react-router';
import React, { useEffect, useState } from 'react';
import { dialogBtnActions, propTypes } from './leaveDialogConstants';

import CustomDialog from '../CustomDialog/CustomDialog';
import appStrings from '../../../core/strings/appStrings';
import { buttonTypes } from '../../../core/strings/appConstants';
import { logout } from '../../../core/redux/slices/loginSlice';
import { LOGIN } from '../../../core/navigation/paths';
import CustomPrompt from '../../../core/navigation/CustomPrompt';

function LeaveDialog({
  buttons,
  description,
  disableLeaveTimeout = false,
  icon,
  isDirty = false,
  title,
  watch,
  reset,
  resetDirty,
  onSubmit,
  handleSubmit,
  onStay,
  errors,
  dispatch,
  id,
}) {
  const [isLeavePageActive, setIsLeavePageActive] = useState({
    nextLocation: null,
    isActive: false,
  });
  const navigate = useNavigate();

  const showModal = nextLocation => {
    setIsLeavePageActive({
      nextLocation: nextLocation,
      isActive: true,
    });
    if (!hasErrors()) {
      handleSubmit(() => {
        return;
      })();
    }
  };

  const hasErrors = () => {
    return Object.keys(errors).length !== 0;
  };

  const handleDialog = nextLocation => {
    if (isDirty || hasErrors()) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };

  const onPageStay = () => {
    setIsLeavePageActive({ nextLocation: null, isActive: false });
    if (onStay) {
      onStay();
    }
  };

  const onPageLeave = () => {
    if (reset) {
      reset(watch(), { isDirty: false });
    } else {
      resetDirty(false);
    }

    setIsLeavePageActive({
      nextLocation: isLeavePageActive.nextLocation,
      isActive: false,
    });
  };

  const onPageLeaveAndSave = () => {
    if (handleSubmit && onSubmit) {
      handleSubmit(data => onSubmit(data, pageLeaveAndSaveTimeout))();
    }
  };

  const pageLeaveAndSaveTimeout = () => {
    if (isLeavePageActive.isActive) {
      if (disableLeaveTimeout) {
        onPageLeave();
      } else {
        setTimeout(() => {
          onPageLeave();
        }, 3000);
      }
    }
  };

  const dialogButtons = {
    [dialogBtnActions.stay]: {
      label: appStrings.modal.btnStay,
      onClick: onPageStay,
      btnType: buttonTypes.secondary,
      id: `${dialogBtnActions.stay}Btn`,
    },
    [dialogBtnActions.leave]: {
      label: appStrings.modal.btnLeave,
      onClick: onPageLeave,
      id: `${dialogBtnActions.leave}Btn`,
    },
    [dialogBtnActions.leaveNoSave]: {
      label: appStrings.modal.btnLeaveNoSave,
      onClick: onPageLeave,
      btnType: buttonTypes.secondary,
      id: `${dialogBtnActions.leaveNoSave}Btn`,
    },
    [dialogBtnActions.leaveAndSave]: {
      label: appStrings.modal.btnLeaveAndSave,
      onClick: onPageLeaveAndSave,
      disabled: hasErrors(),
      id: `${dialogBtnActions.leaveAndSave}Btn`,
    },
  };

  const getButtons = () => {
    return buttons.map(buttonType => dialogButtons[buttonType]);
  };

  useEffect(() => {
    if (!isLeavePageActive.isActive && isLeavePageActive.nextLocation) {
      navigate(`${isLeavePageActive.nextLocation}`);
    }
    if (
      isLeavePageActive.nextLocation === LOGIN &&
      !isLeavePageActive.isActive
    ) {
      dispatch(logout());
    }
  }, [isLeavePageActive, dispatch, navigate]);

  return (
    <>
      <CustomPrompt
        handleDialog={handleDialog}
        useDialog={isLeavePageActive}
        blockNavigation={isDirty}
      />
      <CustomDialog
        buttonContainerClass="save-changes-buttons"
        title={title}
        message={description}
        icon={icon}
        open={isLeavePageActive.isActive}
        buttons={getButtons()}
        id={id}
      />
    </>
  );
}

LeaveDialog.propTypes = propTypes;

export default LeaveDialog;
