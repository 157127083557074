import { Grid, Typography } from '@mui/material';
import {
  alertButtonIds,
  alertTypes,
} from '../../shared/CustomAlert/customAlertConstants';
import {
  cartInputs,
  orderInputs,
  productDetailsFields,
} from './productDetailsConstants';
import {
  formatNumberWithCurrency,
  formatNumberWithPercentage,
} from '../../../core/services/utilsService';
import {
  getContent,
  getItemNotifications,
  getPopulatedFields,
  removeRefreshAlertValues,
  updateProductDetails,
} from './productDetailsService';
import { useLocation, useParams } from 'react-router-dom';

import CustomAlert from '../../shared/CustomAlert/CustomAlert';
import CustomBox from '../../shared/CustomBox/CustomBox';
import CustomButton from '../../shared/CustomButton/CustomButton';
import FormInput from '../../shared/FormInput/FormInput';
import ItemAvailability from '../ItemAvailability/ItemAvailability';
import SerialNumbersDialog from '../SerialNumbersDialog/SerialNumbersDialog';
import appStrings from '../../../core/strings/appStrings';
import { buttonTypes } from '../../../core/strings/appConstants';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import productDetailsStyles from './useProductDetailsStyles';
import { useState } from 'react';
import ProductPictureModal from '../ProductsList/ProductPictureModal/ProductPictureModal';

function ProductDetails({
  control,
  setValue,
  getValues,
  itemDetails,
  setItemDetails,
  onProductNumberChange,
  getProductDetails,
  removeNotification,
  warehouseOptions,
  id,
}) {
  const css = productDetailsStyles().classes;
  const { bp } = useParams();
  const [showAvailability, setShowAvailability] = useState(false);
  const [alertValues, setAlertValues] = useState({});
  const [serialNumberOpen, setSerialNumberOpen] = useState(false);
  const { pathname } = useLocation();
  const isOrder = pathname.indexOf('order') !== -1;
  const [productPictureOpen, setProductPictureOpen] = useState(false);

  const handleCheckAvailability = () => {
    setShowAvailability(true);
  };

  const handleAlertChange = (event, index) => {
    setAlertValues({ ...alertValues, [index]: event.target.value });
  };

  const alertActions = {
    [alertButtonIds.remove]: () => {
      setValue('productNumber', null);
      setValue('quantity', '');
      setValue('studentPrice', '');
      onProductNumberChange(true);
    },
    [alertButtonIds.replace]: ({ alertValue }) => {
      setValue('productNumber', alertValue);
      getProductDetails();
      setAlertValues({});
    },
    [alertButtonIds.keep]: ({ alertIndex }) => {
      removeNotification(alertIndex);
      removeRefreshAlertValues(alertIndex, alertValues, setAlertValues);
    },
  };

  const handleAlertAction = (button, alertValue, alertIndex) => {
    alertActions[button]({ alertValue, alertIndex });
  };

  const handleOpenSerialNumber = () => {
    setSerialNumberOpen(true);
  };

  const handleCloseSerialNumber = () => {
    setSerialNumberOpen(false);
  };

  const handleAddSerialNumbers = data => {
    const newSerialNumbers = getPopulatedFields(data);
    setSerialNumberOpen(false);
    setValue('serialNumber', newSerialNumbers[0]);
    setValue('serialNumberValue', newSerialNumbers);
  };

  const displayAvailability = showAvailability ? (
    <ItemAvailability
      productNumber={itemDetails.productNumber}
      quantity={getValues('quantity')}
      bp={bp}
      setShowDialog={setShowAvailability}
    />
  ) : null;

  const onWarehouseChange = () => {
    const warehouse = getValues(appStrings.order.warehouse.toLowerCase());
    updateProductDetails(itemDetails.productNumber, bp, {
      warehouseCode: warehouse.code,
      warehouseName: warehouse.name,
      studentPrice: itemDetails.studentPrice.substring(1),
    }).then(response => {
      setItemDetails(prev => ({
        ...prev,
        listPrice: formatNumberWithCurrency(response.itemPriceDto.listPrice),
        discountPercentage: formatNumberWithPercentage(
          response.pricingDiscountsDto.discountPercentage
        ),
        discountAmount: formatNumberWithCurrency(
          response.pricingDiscountsDto.discountAmount
        ),
      }));
    });
  };

  const notesSection = isOrder ? (
    <Grid container spacing={5} className={css.orderViewBottomContainer}>
      <Grid item container xs={6}>
        {orderInputs(warehouseOptions, onWarehouseChange)
          .slice(0, 3)
          .map(field => {
            return (
              <Grid
                item
                {...field.gridLayout}
                key={field.name}
                className={css.bottomFieldContainer}
              >
                {field.type !== 'hidden' && (
                  <Typography className={css.bottomLabel}>
                    <strong>{field.labelText}</strong>
                  </Typography>
                )}
                <div
                  className={classNames(css.bottomInputContainer, {
                    [css.serialNumber]: field.name === 'serialNumber',
                  })}
                  onClick={
                    field.name === 'serialNumber'
                      ? handleOpenSerialNumber
                      : null
                  }
                >
                  <FormInput
                    field={field}
                    control={control}
                    InputProps={field.InputProps}
                  />
                </div>
              </Grid>
            );
          })}
      </Grid>
      <Grid item xs={6} className={css.notesContainer}>
        <FormInput field={orderInputs(warehouseOptions)[3]} control={control} />
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={2} className={css.cartViewBottomContainer}>
      {cartInputs.map(field => {
        return (
          <Grid item {...field.gridLayout} key={field.name}>
            <FormInput field={field} control={control} />
          </Grid>
        );
      })}
    </Grid>
  );

  const getDetails = () => {
    const notifications = getItemNotifications({
      itemDetails,
      alertValues,
      handleAlertChange,
      handleAlertAction,
      css,
    });

    return isEmpty(itemDetails) ? (
      <CustomBox customClass={css.greyContainer}>
        <CustomAlert
          messageType={alertTypes.error}
          messageTitle={appStrings.cart.productNotFound}
          messages={[{ text: appStrings.cart.productNotFoundMessage }]}
          actionButtons={[alertButtonIds.remove]}
          handleAlertAction={handleAlertAction}
          customClass={css.detailsAlert}
        />
      </CustomBox>
    ) : (
      <>
        <CustomBox customClass={css.greyContainer}>
          <Grid container spacing={2}>
            {notifications}
            <Grid
              container
              item
              spacing={2}
              xs={12}
              className={css.fieldsContainer}
            >
              {productDetailsFields.map(field => {
                return (
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={3}
                    lg={3}
                    key={field.name}
                    id={id + 'FieldContainer-' + field.name}
                  >
                    <strong>{field.label}</strong>
                    <div id={id + 'FieldValue-' + field.name}>
                      {getContent(field.name, itemDetails)}
                    </div>
                  </Grid>
                );
              })}

              <Grid item xs={6} sm={6} md={3} lg={3}>
                <CustomButton
                  btnType={buttonTypes.secondary}
                  label={appStrings.cart.checkAvailability}
                  onClick={handleCheckAvailability}
                  customClass={css.detailsButton}
                  disabled={itemDetails.productNumber === null}
                  id={id + 'CheckAvailabilityButton'}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <CustomButton
                  btnType={buttonTypes.secondary}
                  label={appStrings.cart.viewPicture}
                  customClass={css.detailsButton}
                  disabled={itemDetails.productNumber === null}
                  id={id + 'viewPictureButton'}
                  onClick={() => setProductPictureOpen(true)}
                />
              </Grid>
            </Grid>
          </Grid>
        </CustomBox>
        {notesSection}
        {displayAvailability}
        {serialNumberOpen && (
          <SerialNumbersDialog
            serialNumbers={getValues('serialNumberValue')}
            quantity={Number(getValues('quantity'))}
            handleClose={handleCloseSerialNumber}
            onSubmit={handleAddSerialNumbers}
          />
        )}
        {productPictureOpen && (
          <ProductPictureModal
            productNumber={itemDetails.productNumber}
            handleClose={() => setProductPictureOpen(false)}
          />
        )}
      </>
    );
  };

  return !itemDetails ? (
    <CustomAlert
      messageType={alertTypes.info}
      messageTitle={appStrings.cart.pleaseEnterProduct}
      messages={[{ text: appStrings.cart.pleaseEnterProductMessage }]}
      customClass={css.detailsAlert}
      id="addProductNoProductEnteredAlert"
    />
  ) : (
    getDetails()
  );
}

export default ProductDetails;
