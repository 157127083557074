import { Badge, Grid, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';

import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import sepFonts from '../../../../core/assets/sep-industrial-font.module.css';
import { sepIndustrialFont } from '../../../../core/strings/appConstants';
import useNavTabStyles from './useNavTabStyles';

const propTypes = {
  // name of the icon used
  icon: PropTypes.string.isRequired,
  // text to be displayed as label
  label: PropTypes.string.isRequired,
  // flag to hide label
  hideLabel: PropTypes.bool,
  // link to navigate too
  navigateTo: PropTypes.string,
  // in case is not a link the action that should happen on click
  onClick: PropTypes.func,
  // flag to not use link
  isAction: PropTypes.bool,
  // flag to disable tab
  disabled: PropTypes.bool,
  // to add a dropdown in menu
  isDropDown: PropTypes.bool,
  //dropDownData
  dropDownData: PropTypes.array,
  // shape([
  //   {
  //     id: PropTypes.string,
  //     isNavLink: PropTypes.bool,
  //     label: PropTypes.string,
  //     navigateTo: PropTypes.string,
  //     onClick: PropTypes.func,
  //   },
  // ]),
  showBadge: PropTypes.bool,
};

function NavTab({
  icon,
  label,
  hideLabel = false,
  navigateTo,
  onClick,
  isAction = false,
  disabled = false,
  isDropDown = false,
  dropDownData,
  ...props
}) {
  const navCss = useNavTabStyles().classes;

  const classes = classNames(
    navCss.navigationTab,
    {
      [navCss.hideLabel]: hideLabel,
      [navCss.disabled]: disabled,
    },
    props.className
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const iconCss = classNames(
    sepFonts[sepIndustrialFont],
    sepFonts[icon],
    navCss.icon
  );

  const content = (labelToShow, ignoreLabelHide = false) => (
    <Grid item container alignItems="flex-end" spacing={1}>
      <Grid item>
        {(!hideLabel || ignoreLabelHide) && <span>{labelToShow}</span>}
      </Grid>
      <Grid item>
        <Badge
          className={navCss.icon}
          anchorOrigin={props.badgeAnchorOrigin}
          badgeContent={props.badgeContent || ''}
          color={props.badgeColor}
          invisible={!props.showBadge}
          variant={props.badgeVariant || 'standard'}
          id="IconBadge"
          data-test-id="icon-badge"
        >
          <span className={iconCss}></span>
        </Badge>
      </Grid>
    </Grid>
  );

  const navigationLink = (
    linkToNavigate,
    labelToShow,
    ignoreLabelHide = false
  ) => (
    <NavLink to={linkToNavigate}>
      {content(labelToShow, ignoreLabelHide)}
    </NavLink>
  );

  const dropDownContent = () => {
    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <div
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          {content(label)}
        </div>
        <Menu
          id={`dropDownNavBar-${icon}`}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {dropDownData.map(elem => (
            <MenuItem
              key={elem.id}
              id={elem.id}
              onClick={() => {
                if (elem.onClick) {
                  elem.onClick();
                }
                handleClose();
              }}
            >
              {elem.isNavLink
                ? navigationLink(elem.navigateTo, elem.label, true)
                : elem.label}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  };

  return (
    <Grid className={classes} alignContent="center" data-test-id="navTabHeader">
      {isDropDown && dropDownContent()}

      {isAction && (
        <div onClick={event => (onClick ? onClick(label, event) : null)}>
          {content(label)}
        </div>
      )}

      {!isDropDown && !isAction ? navigationLink(navigateTo, label) : null}
    </Grid>
  );
}

NavTab.propTypes = propTypes;

export default NavTab;
