import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import ProductSetsSkeleton from './ProductSetsSkeleton';
import { useNavigate, useParams } from 'react-router-dom';
import { getProductDetails, getProductSetMembers, getProductSets, processProductSetItems } from '../ProductPage/productPageService';
import { useDispatch } from 'react-redux';
import { displayError } from '../../../core/redux/slices/notificationsSlice';
import { useProductsSetsStyles } from './useProductsSetsStyles';
import { ChevronLeftOutlined } from '@mui/icons-material';
import ProductSetItem from './ProductSetItem';
import { useLocation } from 'react-router-dom';
import appStrings from '../../../core/strings/appStrings';

function ProductSets() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { productNumber } = useParams();
  const [productDetails, setProductDetails] = useState(null);
  const [products, setProducts] = useState([]);
  const css = useProductsSetsStyles().classes;
  const navigation = useNavigate();
  const { pathname } = useLocation();

  const goBack = () => {
    navigation(-1);
  };

  useEffect(() => {
    setIsLoading(true);
    getProductDetails(productNumber)
      .then(resp => {
        setProductDetails(resp);
        const promise = pathname.includes('setmembers') ? getProductSetMembers : getProductSets;
        promise(productNumber)
          .then(setItems => {
            const processedItems = processProductSetItems(setItems);
            setProducts(processedItems);
          })
          .finally(() => setIsLoading(false));
      })
      .catch(err => {
        dispatch(displayError({ message: err }));
        setIsLoading(false);
      });
  }, [dispatch, pathname, productNumber]);

  return productDetails && !isLoading ? (
    <Grid container item xs={12} lg={10} margin={'0 auto'} alignContent="flex-start" className={css.setContainer}>
      <Grid container flexDirection="column">
        <Grid item className={css.titleText}>
          <Typography variant="h1" fontWeight="700" className="sets-title">
            {productDetails.productNumber} - {productDetails.name}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" className={css.backLink} onClick={goBack}>
            <IconButton className={css.arrowButton} disableRipple>
              <ChevronLeftOutlined />
            </IconButton>
            <Typography variant="h3" fontWeight="700">
              {appStrings.productPage.backToProduct}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        {products.map((product, index) => (
          <ProductSetItem itemDetails={product} index={index} key={index} />
        ))}
      </Grid>
    </Grid>
  ) : (<ProductSetsSkeleton />);
}

export default ProductSets;