import { currentAddressInputs, permanentAddressInputs } from '../../../StudentProfileForm/Addresses/addressesInputs';
import { getStudentAddressDetails, handleErrors } from '../../../StudentProfileForm/studentProfileFormService';
import { haveTheSameAddresses, makeCurrentAddressSameAsPermanent, saveAddressInfoWizard } from './addressInfoService';
import { useCallback, useEffect, useState } from 'react';

import FormInput from '../../../FormInput/FormInput';
import { Grid } from '@mui/material';
import InputsTemplates from '../../../StudentProfileForm/Addresses/InputsTemplate';
import WizardActionButtons from '../../WizardActionButtons/WizardActionButtons';
import WizardFormSkeleton from '../../WizardFormSkeleton/WizardFormSkeleton';
import appStrings from '../../../../../core/strings/appStrings';
import { formatReceivedData } from '../../../StudentProfileForm/Addresses/addressesService';
import { handleSaveErrors } from '../../../../industrial/StudentProfile/Services/studentProfileService';
import { handleSaveNotification, isSignNowBtnClicked } from '../wizardPageContainerService';
import { setActiveStep } from '../../../../../core/redux/slices/wizardSlice';
import setMultipleValues from '../../../../../core/services/formService';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useWizardStyles from '../../useWizardStyles';
import { wizardStepsCodes } from '../../wizardConstants';
import { dialogBtnActions } from '../../../LeaveDialog/leaveDialogConstants';
import LeaveDialog from '../../../LeaveDialog/LeaveDialog';
import { saveInformation } from '../CustomerInfo/customerInfoService';
import PropTypes from 'prop-types';
import MandatoryFieldsMessage from '../../../MandatoryFieldsMessage/MandatoryFieldsMessage';

function AddressInfo({ setDirtyInfos, shouldSaveOnLeavePage }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const css = useWizardStyles().classes;

  const [isLoading, setLoading] = useState(true);
  const [disabledStates, setDisabledStates] = useState({});
  const [formWasSubmitted, setFormWasSubmitted] = useState(false);
  const [isCurrentAddressSameAsPermanent, setIsCurrentAddressSameAsPermanent] =
      useState(false);

  const validationsPossibleFieldName = useSelector(state => state.wizard.possibleFieldName);

  const [addressesState, setAddressesState] = useState({
    currentAddress: false,
    permanentAddress: true,
    previousAddresses: [],
    prevAddressesActualLength: 0,
  });
  const [addressesSavedData, setAddressesSavedData] = useState({
    formattedValues: {},
    codes: {},
  });
  const [initialFormValues, setInitialFormValues] = useState();

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    formState,
    reset,
    setError,
  } = useForm();
  const { isDirty, errors } = formState;
  const formProps = {
    control,
    setValue,
    watch,
    errors,
    getValues,
  };

  useEffect(() => {
    if (validationsPossibleFieldName) {
      const { fieldName, howToFix } = validationsPossibleFieldName;
      setError(fieldName, { type: 'manual', message: howToFix });
    }
  }, [validationsPossibleFieldName, setError])

  

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuccessCallback = useCallback(() => {
    if (isLoading) {
      setLoading(false);
    }
    setDirtyInfos(false);
    handleSaveNotification(true);
  }, [isLoading, setDirtyInfos]);

  const onErrorCallback = useCallback(e => {

    handleSaveErrors(e, setError);
    setLoading(false);
  }, [setError]);

  const onSubmit = () => {
    if (!isSignNowBtnClicked()) {
      dispatch(setActiveStep(wizardStepsCodes.employerInfo));
    }
  };

  const onLeave = () => {
    setLoading(true);
    const formValues = getValues();
    if (formValues.currentAddressSameAsPermanent) {
      const formattedData = makeCurrentAddressSameAsPermanent(formValues);

      return saveAddressInfoWizard(
        formattedData,
        reset,
        id,
        setValue,
        onSuccessCallback,
        onErrorCallback
      );
    }
    saveAddressInfoWizard(
      formValues,
      reset,
      id,
      setValue,
      onSuccessCallback,
      onErrorCallback
    );
  };

  const onLeaveSubmit = (data, callback) => {
    setLoading(true);
    saveInformation(data, reset, id, callback, onErrorCallback);
  };

  useEffect(() => {
    return () => {
      if (shouldSaveOnLeavePage.current) {
        onLeave();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customReset = () => {
    setMultipleValues({
      setValue,
      values: initialFormValues,
    });
    reset();
  };

  const leaveDialogProps = {
    title: appStrings.modal.ecApp.title,
    description: appStrings.modal.ecApp.description,
    isDirty,
    reset: customReset,
    watch,
    onSubmit: onLeaveSubmit,
    handleSubmit,
    buttons: [
      dialogBtnActions.stay,
      dialogBtnActions.leaveNoSave,
      dialogBtnActions.leaveAndSave,
    ],
    errors,
    dispatch,
    id: 'customerInfoUnsavedChangesDialog',
    disableLeaveTimeout: true,
  }

  useEffect(() => {
    if (id) {
      getStudentAddressDetails(id)
          .then(resp => {
            if (resp) {
              setAddressesState({
                currentAddress: Boolean(resp.currentAddress),
                permanentAddress: Boolean(resp.permanentAddress),
                previousAddresses: Array(
                    resp.previousAddresses?.length ?? 0
                ).fill(''),
                prevAddressesActualLength: resp.previousAddresses?.length ?? 0,
              });
              formatReceivedData(resp).then(({formattedValues, codes}) => {
                setAddressesSavedData({formattedValues, codes});
                setInitialFormValues(formattedValues);
              });
            }
          })
          .catch(error => {
            handleErrors(error);
            setLoading(false);
          });
    }
  }, [id]);

  useEffect(() => {
    if (addressesSavedData.formattedValues.permanentAddress && isLoading) {
      setMultipleValues({
        setValue,
        values: addressesSavedData.formattedValues,
      });
      const hasSameAddresses = haveTheSameAddresses(addressesSavedData.formattedValues);
      setValue('currentAddressSameAsPermanent', hasSameAddresses);
      setIsCurrentAddressSameAsPermanent(hasSameAddresses);
      // setTimeout is necessary to allow setMultipleValues to set values
      setTimeout(() => {
        setLoading(false);
      });
    }
  }, [addressesSavedData.formattedValues, isLoading, setValue]);

  useEffect(() => {
    setDirtyInfos(isDirty);
    shouldSaveOnLeavePage.current = isDirty;
  }, [isDirty, setDirtyInfos, shouldSaveOnLeavePage]);

  return (
    <Grid>
      <form
        onSubmit={handleSubmit(onSubmit)}
        name="studentProfileWizard"
        noValidate
        autoComplete="off"
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          display={isLoading ? 'none' : ''}
        >
          <Grid item>
            <Grid container alignItems="flex-start" direction="row" spacing={2}>
              <Grid item>
                <InputsTemplates
                  formProps={formProps}
                  inputs={permanentAddressInputs({
                    getValues,
                    setValue,
                    formWasSubmitted,
                    disabledStates,
                    setDisabledStates,
                    otherGridLayout: { xs: 12, sm: 6 },
                  })}
                  title={`${appStrings.addresses.permanentHome} ${appStrings.register.address}`}
                  titleInsideTheBox
                  customContainerCss={css.formContainer}
                  showMandatoryMessage={true}
                />
              </Grid>
              <Grid item className={css.checkboxFieldContainer}>
                <FormInput
                  {...formProps}
                  field={{
                    name: 'currentAddressSameAsPermanent',
                    type: 'checkbox',
                    label: appStrings.wizard.currentAddSameAsPermLabel,
                    value: isCurrentAddressSameAsPermanent,
                    onChangeCallback: v =>
                      setIsCurrentAddressSameAsPermanent(v),
                  }}
                />
              </Grid>
              <Grid item>
                {!isCurrentAddressSameAsPermanent ? (
                  <InputsTemplates
                    formProps={formProps}
                    inputs={currentAddressInputs({
                      getValues,
                      setValue,
                      disabledStates,
                      setDisabledStates,
                      otherGridLayout: { xs: 12, sm: 6 },
                    })}
                    title={appStrings.addresses.currentAddress}
                    titleInsideTheBox
                    customContainerCss={css.formContainer}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <WizardActionButtons handleSubmit={handleSubmit} />
          </Grid>
        </Grid>
        <LeaveDialog {...leaveDialogProps} />
      </form>
      {isLoading && <WizardFormSkeleton />}
    </Grid>
  );
}

AddressInfo.propTypes = {
  setDirtyInfos: PropTypes.func,
  shouldSaveOnLeavePage: PropTypes.object,
};

export default AddressInfo;
