import { Grid } from '@mui/material';
import CustomBox from '../CustomBox/CustomBox';
import CustomSkeleton from './CustomSkeleton';
import { useSkeletonStyles } from './useSkeletonSyles';

function StandardFormSkeleton() {
  const css = useSkeletonStyles().classes;
  const item = {
    xs: 4,
    width: '98%',
    height: '2rem',
    variant: 'rect',
    container: true,
    justifyContent: 'space-evenly',
  };
  const layout = Array(9).fill(item);

  return (
    <CustomBox customClass={css.skeletonContainer}>
      <Grid container>
        <CustomSkeleton layout={layout} spacing={6} />
      </Grid>
    </CustomBox>
  );
}

export default StandardFormSkeleton;
