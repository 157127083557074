import { makeStyles } from 'tss-react/mui';

const useCustomTableStyles = makeStyles()(theme => ({
  tableContainer: {
    '& tbody': {
      position: 'relative',
    },
    padding: '5px',
    '& td, th': {
      border: 0,
    },
    '& tbody:not(tbody:last-of-type)': {
      borderBottom: `1px solid ${theme.palette.common.shadow}`,
    },
    '& .MuiTableRow-root.Mui-selected': {
      background: theme.palette.common.neutral6,
    },
    '& .MuiTableRow-root.MuiTableRow-hover:hover': {
      background: theme.palette.common.neutral7,
    },
    '& .MuiCheckbox-root': {
      color: theme.palette.common.darkGrey,
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.common.primary,
    },
    '& tr': {
      position: 'relative',
    },
    '& td': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    '& th': {
      paddingTop: '10px',
      paddingBottom: '6px',
    },
  },
  tableHeader: {
    background: theme.palette.common.neutral3,
    '& .MuiTableCell-head': {
      fontWeight: theme.typography.fontWeightBold,
    },
    '& th:first-of-type': {
      borderRadius: '3px 0 0 3px',
    },
    '& th:last-of-type': {
      borderRadius: '0 3px 3px 0',
    },
  },
  expandedRow: {
    background: theme.palette.common.neutral8,
  },
  expandedContentContainer: {
    zIndex: 8, //  needed to allow loading overlay to be displayed over the expanded row
    '& td': {
      padding: 0,
    },
  },
  tableSortIcon: {
    color: `${theme.palette.common.primary} !important`,
    fontSize: '24px',
  },
  loadingCell: {
    zIndex: '9',
    backgroundColor: theme.palette.common.neutral1,
    position: 'absolute',
    padding: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    paddingTop: `0 !important`,
    paddingBottom: `0 !important`,
    marginTop: '1px',
    display: 'flex',
    alignItems: 'center',
  },
  loadingContainer: {
    height: '100%',
    maxHeight: '200px',
  },
  indexColumn: {
    width: '35px',
  },
  expandColumn: {
    width: '20px',
  },
  disabledRow: {
    '& .MuiTableCell-body': {
      color: theme.palette.common.inactive,
    },
  },
  bodySkeleton: {
    height: '3rem',
  },
}));

export default useCustomTableStyles;
