import { red } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';

const useCategoriesContainerStyles = makeStyles()(theme => ({
  categoriesContainer: {
    '& a': {
      textDecoration: 'none',
    },

    '& .MuiTypography-root': {
      color: 'black',
    },

    '& button': {
      width: '100%',
    },
  },

  breadCrumbs: {
    '& a, p': {
      fontSize: '14px !important',
    },
  },

  contentButton: {
    '#category-content': {
      height: theme.spacing(35),
      padding: theme.spacing(2),

      '&:hover p': {
        color: red[900],
      },
    },

    '& img': {
      width: '100%',
      height: 'auto',
      maxWidth: theme.spacing(22),
      maxHeight: theme.spacing(22),
    },

    '& button': {
      borderColor: `${theme.palette.grey['200']} !important`,

      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 1) !important',
        borderColor: `${theme.palette.grey['900']} !important`,
      },
    },
  },

  textBold: {
    fontWeight: 'bold',
  },

  title: {
    fontWeight: 'bold',
    fontSize: '16px',
  },

  categoryContentTitle: {
    wordBreak: 'break-word',
  },
}));

export default useCategoriesContainerStyles;
