import { makeStyles } from 'tss-react/mui';

const zeroImportant = '0 !important';

const productDetailsStyles = makeStyles()(theme => ({
  greyContainer: {
    background: theme.palette.common.neutral2,
    padding: theme.spacing(3.5),
  },
  detailsAlert: {
    background: theme.palette.common.neutral1,
  },
  fieldsContainer: {
    paddingLeft: theme.spacing(2),
  },
  cartViewBottomContainer: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    '& p': {
      display: 'none',
    },
  },
  orderViewBottomContainer: {
    marginTop: theme.spacing(2),
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    '& >div': {
      paddingTop: zeroImportant,
      paddingBottom: zeroImportant,
    },
  },
  button: {
    cursor: 'pointer',
  },
  detailsButton: {
    backgroundColor: `${theme.palette.common.neutral1} !important`,
  },
  bottomFieldContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  bottomInputContainer: {
    width: '50%',
    display: 'inline-block',
    '& .MuiInputBase-root': {
      background: theme.palette.common.neutral2,
    },
  },
  bottomLabel: {
    float: 'left',
    marginRight: '50px',
  },
  serialNumber: {
    '& .MuiInputBase-root, input': { cursor: 'pointer' },
    '& .MuiInputAdornment-root p': {
      color: theme.palette.common.darkGrey,
      fontWeight: 500,
      fontSize: theme.typography.h2.fontSize,
      marginBottom: '8px',
    },
    '& >div >p': {
      display: 'none',
    },
  },
  notesContainer: {
    paddingRight: zeroImportant,
    display: 'flex',
    '& p': {
      display: 'none',
    },
  },
  serialNumberDialog: {
    padding: '0 0 50px !important',
    minWidth: '500px',
    '& .MuiDialogTitle-root': {
      background: theme.palette.common.secondary6,
      padding: '20px',
      alignItems: 'center',
      display: 'flex',
      '& h2': {
        fontSize: theme.typography.body1.fontSize,
      },
    },
  },
}));

export default productDetailsStyles;
