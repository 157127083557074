import { makeStyles } from 'tss-react/mui';

const useRestorePurchaseStyles = makeStyles()(theme => ({
  customBoxNoDataAvailable: {
    background: theme.palette.common.neutral4,
    margin: `${theme.spacing(2)} ${theme.spacing(10)}`,
    padding: theme.spacing(10),
    border: `1px solid ${theme.palette.common.lightGrey}`,
    borderRadius: '4px',
  },
  hide: {
    display: 'none',
  },
  totalsContainer: {
    padding: '0 10px',
  },
  bold: { fontWeight: theme.typography.fontWeightBold },
  greyContainer: {
    background: theme.palette.common.neutral4,
    padding: '16px',
  },
  undoBtn: {
    padding: '0 !important',
  },

  restoreFilterDropdown: {
    textTransform: 'none',
    color: theme.palette.common.darkGrey,
    padding: '8px',
    background: theme.palette.common.neutral3,
    borderRadius: '3px 0 0 3px',
    boxShadow: 'none',
  },
  restoreFilterContainer: {
    border: '1px solid ' + theme.palette.common.lightGrey,
    boxShadow: 'none',
  },
  filtersDivider: {
    backgroundColor: theme.palette.common.lightGrey,
  },
  filterInput: {
    marginLeft: '16px',
    color: theme.palette.common.darkGrey,
  },
  filterSearchBtn: {
    padding: '8px 11px',
    minWidth: 'auto',
    background: theme.palette.common.primary,
    borderRadius: '0 3px 3px 0',
    color: theme.palette.common.white,
    '&[disabled]': {
      color: theme.palette.common.neutral2,
      opacity: '0.6',
    },
    '&:hover': {
      color: theme.palette.common.white,
      background: theme.palette.common.primary5,
    },
  },
  filterClearIcon: {
    width: '40px',
    height: '40px',
    padding: '8px',
  },
  filterChips: {
    background: theme.palette.common.neutral3,
    color: theme.palette.common.darkGrey,
    borderRadius: '4px',
  },
  clearAllBtn: {
    padding: '5px 0 !important',
    fontWeight: 'bold',
  },
}));

export default useRestorePurchaseStyles;
