import { Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import appStrings from '../../../core/strings/appStrings';
import {
  buttonTypes,
  dateFormat,
  historyTransactionTypeByCode,
  orderStatusesByCode,
} from '../../../core/strings/appConstants';
import logo from '../../../core/assets/img/snapon-logo.png';
import logo15 from '../../../core/assets/img/snapon-logo1.5x.png';
import logo2 from '../../../core/assets/img/snapon-logo2x.png';
import CustomButton from '../../shared/CustomButton/CustomButton';
import CustomBox from '../../shared/CustomBox/CustomBox';
import { useDispatch, useSelector } from 'react-redux';
import { HISTORY } from '../../../core/navigation/paths';
import { useCallback, useEffect, useState } from 'react';
import {
  getAllDisclosures,
  orderInfoDetails,
} from './ECContractSigningService';
import { displayError } from '../../../core/redux/slices/notificationsSlice';
import { getOrderDetailsHistory } from '../OrderHistoryDetails/orderDetailsHistoryService';
import moment from 'moment';
import ECContractSigningSkeleton from './ECContractSigningSkeleton';
import useECContractSigningStyles from './useECContractSigningStyles';
import Contracts from './Components/Contracts';
import OrderSubmitted from './Components/OrderSubmitted';
import { disclosureStatuses } from '../../../core/strings/ecFileOptions';

function ECContractSigning() {
  const dispatch = useDispatch();
  const css = useECContractSigningStyles().classes;
  const navigation = useNavigate();
  const { bp, id, orderId } = useParams();
  const student = useSelector(
    state => state.schoolsWithStudents.selectedStudent.student
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isOrderSubmitted, setIsOrderSubmitted] = useState(false);
  const [orderDetails, setOrderDetails] = useState();
  const [disclosures, setDisclosures] = useState();
  const [documents, setDocuments] = useState();
  const [currentEnabledDisclosure, setCurrentEnabledDisclosure] = useState();

  const loadDisclosures = useCallback(() => {
    return getAllDisclosures(id, orderId).then(res => {
      if (res?.disclosures) {
        setDisclosures(res.disclosures[0]);
        const lastSignedDisclosure = res?.disclosures[0]?.files?.findLast(
          disc => disc.status === disclosureStatuses.signed
        );
        setCurrentEnabledDisclosure(
          res?.disclosures[0]?.files?.indexOf(lastSignedDisclosure) + 1
        );
        setIsLoading(false);
      }
    });
  }, [id, orderId]);

  useEffect(() => {
    setIsLoading(true);
    if (id && orderId) {
      getOrderDetailsHistory(orderId, id)
        .then(res => {
          setOrderDetails(res);
          loadDisclosures();
        })
        .catch(err => {
          dispatch(displayError(err));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOrderSubmitted = () => {
    setIsLoading(true);
    getAllDisclosures(id, orderId).then(res => {
      const orderDocuments = [
        {
          label: 'Order Receipt',
          fileType: 'receipt',
        },
        {
          label: 'Credit Sale Contract',
          fileType: 'contract',
        },
      ];
      if (res?.disclosures?.length) {
        orderDocuments.push(...res.disclosures[0].files);
      }
      setDocuments(
        orderDocuments.map((document, index) => ({
          ...document,
          id: index,
        }))
      );
      setIsLoading(false);
      setIsOrderSubmitted(true);
    });
  };

  return (
    <Grid container className={css.mainContainer} id="ec-contract-signing">
      <Grid item xs={12}>
        <Grid item container xs={12} className={css.header}>
          <Grid item xs={4}>
            <img
              id="logo"
              className={css.logo}
              srcSet={`${logo},
                        ${logo15} 1.5x,
                        ${logo2} 2x`}
              src={logo}
              alt="Snap-on SEP logo"
            />
          </Grid>
          <Grid item xs={6} className={css.title}>
            <Typography variant="h2" fontWeight="bold" id="history-for">
              {`${appStrings.ecContractSigning.ecOrderFor} ${student?.firstName} ${student?.lastName}`}
              <span></span>
            </Typography>
          </Grid>
          <Grid item>
            <CustomButton
              id="close-button"
              btnType={buttonTypes.secondary}
              label={appStrings.common.close}
              onClick={() => navigation(HISTORY(bp, id))}
            />
          </Grid>
        </Grid>
        {isLoading && <ECContractSigningSkeleton />}
        {!isLoading && (
          <Grid
            container
            justifyContent="center"
            className={css.container}
            id="order-details"
          >
            <Grid item xs={10} md={8} lg={6}>
              <CustomBox customClass={css.detailsContainer}>
                <Grid item container>
                  {orderInfoDetails.map((item, index) => (
                    <Grid item xs={2} key={`${index}-${item.name}`}>
                      <Typography
                        fontWeight="bold"
                        id={`order-${item.name}-label`}
                        data-test-id={`order-${item.name}-label`}
                      >
                        {`${item.label}:`}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                <Grid item container>
                  <Grid item xs={2}>
                    <Typography id="order-type" data-test-id="order-type">
                      {historyTransactionTypeByCode[orderDetails?.type]}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography id="order-date" data-test-id="order-date">
                      {moment(orderDetails?.submittedDate).format(dateFormat)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    id="order-number"
                    data-test-id="order-number"
                  >
                    <Typography>{orderDetails?.orderNumber}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography>
                      {orderStatusesByCode[orderDetails?.orderStatus]}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography>{`$${orderDetails?.total}`}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography>
                      {orderDetails?.userAudit?.createdByRole}
                    </Typography>
                  </Grid>
                </Grid>
              </CustomBox>
            </Grid>
            <Grid
              container
              justifyContent="center"
              className={css.container}
              id="order-details"
            >
              <Grid item xs={10} md={8} lg={6}>
                <CustomBox customClass={css.customBox}>
                  {isOrderSubmitted ? (
                    <OrderSubmitted documents={documents} />
                  ) : (
                    <Contracts
                      loadDisclosures={loadDisclosures}
                      disclosures={disclosures}
                      currentEnabledDisclosure={currentEnabledDisclosure}
                      onOrderSubmitted={onOrderSubmitted}
                    />
                  )}
                </CustomBox>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default ECContractSigning;
