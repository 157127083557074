import React, { useCallback, useEffect, useState } from 'react';
import CustomBox from '../../../../shared/CustomBox/CustomBox';
import { Divider, Grid, useMediaQuery } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  applicationContractFields,
  editProfileSectionsConstants,
} from '../editProfileConstants';
import useEditProfileStyles from '../editProfileStyles';
import CustomButton from '../../../../shared/CustomButton/CustomButton';
import {
  buttonColors,
  buttonTypes,
} from '../../../../../core/strings/appConstants';
import appStrings from '../../../../../core/strings/appStrings';
import { GoBackBtn } from '../../accountServices';
import { clearEmptySections } from '../../../../industrial/StudentProfile/Services/clearEmptySectionsService';
import {
  onFormError,
  onFormSubmit,
  validateDatePickers,
} from '../../../../industrial/StudentProfile/Services/studentProfileService';
import LeaveDialog from '../../../../shared/LeaveDialog/LeaveDialog';
import CustomIcon from '../../../../shared/CustomIcon/CustomIcon';
import { icons } from '../../../../../core/strings/icons';
import { dialogBtnActions } from '../../../../shared/LeaveDialog/leaveDialogConstants';
import { useParams } from 'react-router';
import {
  addressesErrorFields,
  customerInfoErrorFields,
  employerInfoErrorFields,
  referencesErrorFields,
  schoolInfoErrorFields,
} from '../../../../industrial/StudentProfile/studentProfileConstants';
import PropTypes from 'prop-types';
import { makeCurrentAddressSameAsPermanent } from '../../../../shared/ECAppWizard/WizardPageContainer/AddressInfo/addressInfoService';

function EditContent({ formIndex, setSectionErrors, sectionErrors }) {
  const css = useEditProfileStyles().classes;
  const isSmallDevice = useMediaQuery('(max-width:1366px)');
  const dispatch = useDispatch();
  const { id } = useParams();
  const { userId } = useSelector(state => state.login.userDetails);
  const [loading, setLoading] = useState(false);
  const [oldErrorsCount, setOldErrorsCount] = useState(0);
  const [formWasSubmitted, setFormWasSubmitted] = useState(false);
  const [emptySections, setEmptySections] = useState({
    currentAddress: false,
    previousAddresses: null,
    presentEmployer: false,
    futureEmployer: false,
    previousEmployers: null,
    emptyRefs: null,
  });
  const [isCurrentAddressSameAsPermanent, setIsCurrentAddressSameAsPermanent] =
    useState(false);
  const {
    control,
    setValue,
    getValues,
    watch,
    reset,
    handleSubmit,
    setError,
    formState: { errors, isDirty },
  } = useForm();
  const [customerInfo, setCustomerInfo] = useState({});

  const onSubmit = (data, pageLeaveAndSaveTimeout = false) => {
    if (isDirty) {
      let formData = data;
      if (isCurrentAddressSameAsPermanent) {
        formData = makeCurrentAddressSameAsPermanent(data);
      }
      clearEmptySections(formData, setEmptySections);
      onFormSubmit({
        data: formData,
        pageLeaveAndSaveTimeout,
        id,
        setValue,
        setFormWasSubmitted,
        setSectionErrors,
        refreshSectionErrors,
        setError,
        reset,
        saveCallback: data => {
          if ((data?.socialSecurityNumber ?? '') !== customerInfo.socialSecurityNumber) {
            setCustomerInfo(data);
          }
        },
        dispatch,
        isStudent: true,
      });
    }
  };

  const onError = errorList => {
    onFormError({
      setFormWasSubmitted,
      refreshSectionErrors,
      errorList,
    });
  };

  const callDataOnSubmit = data => {
    onSubmit(data);
  };

  const refreshSectionErrors = useCallback(
    errorList => {
      const finalErrors = errorList ? errorList : errors;
      const newSectionErrors = {
        customerInfo: false,
        schoolInfo: false,
        employerInfo: false,
        addresses: false,
        references: false,
      };
      Object.keys(finalErrors).forEach(prop => {
        newSectionErrors.customerInfo =
          newSectionErrors.customerInfo ||
          customerInfoErrorFields.indexOf(prop) !== -1;
        newSectionErrors.schoolInfo =
          newSectionErrors.schoolInfo ||
          schoolInfoErrorFields.indexOf(prop) !== -1;
        newSectionErrors.employerInfo =
          newSectionErrors.employerInfo ||
          employerInfoErrorFields.indexOf(prop) !== -1;
        newSectionErrors.addresses =
          newSectionErrors.addresses ||
          addressesErrorFields.indexOf(prop) !== -1;
        newSectionErrors.references =
          newSectionErrors.references ||
          referencesErrorFields.indexOf(prop) !== -1;
      });
      const dateValidations = validateDatePickers();
      Object.keys(newSectionErrors).forEach(prop => {
        newSectionErrors[prop] =
          newSectionErrors[prop] || dateValidations.errors[prop];
      });
      setSectionErrors({
        customerInfo: newSectionErrors.customerInfo,
        schoolInfo: newSectionErrors.schoolInfo,
        employerInfo: newSectionErrors.employerInfo,
        addresses: newSectionErrors.addresses,
        references: newSectionErrors.references,
      });
      return dateValidations.valid;
    },
    [errors, setSectionErrors]
  );

  useEffect(() => {
    if (Object.keys(errors).length !== oldErrorsCount) {
      refreshSectionErrors();
      setOldErrorsCount(Object.keys(errors).length);
    }
  }, [errors, oldErrorsCount, refreshSectionErrors]);

  const props = {
    dispatch,
    id: userId,
    setLoading,
    control,
    setValue,
    getValues,
    watch,
    errors,
    reset,
    isDirty,
    isStudent: true,
    sectionErrors,
    formWasSubmitted,
    emptySections,
    loading,
    isCurrentAddressSameAsPermanent,
    setIsCurrentAddressSameAsPermanent,
    customerInfo,
    setCustomerInfo,
  };

  const dialogData = {
    title: appStrings.modal.editProfile.title,
    description: appStrings.modal.editProfile.description,
    icon: <CustomIcon icon={icons.editProfile} customClasses={css.leaveIcon} />,
    isDirty,
    reset,
    watch,
    onSubmit,
    handleSubmit,
    buttons: [
      dialogBtnActions.stay,
      dialogBtnActions.leaveNoSave,
      dialogBtnActions.leaveAndSave,
    ],
    errors,
    dispatch,
    id: 'unsavedChangesDialog',
  };

  return (
    <CustomBox customClass={css.mainContainer}>
      <form
        name="accountStudentProfile"
        onSubmit={handleSubmit(callDataOnSubmit, onError)}
        noValidate
        autoComplete="off"
      >
        {editProfileSectionsConstants({ sectionErrors }).map((form, index) => (
          <Grid
            container
            style={{
              display: index === formIndex ? 'block' : 'none',
            }}
            key={index}
          >
            {form.component(props)}
          </Grid>
        ))}
        {applicationContractFields({ props }).map((form, index) => (
          <Grid container key={index}>
            {form.index === formIndex && form.component}
          </Grid>
        ))}
      </form>
      {!loading && (
        <Grid
          container
          justifyContent={{
            xs: 'center',
            sm: isSmallDevice ? 'flex-start' : 'center',
          }}
          className={css.saveBtn}
          spacing={2}
        >
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item>
            <CustomButton
              btnType={buttonTypes.primary}
              color={buttonColors.red}
              label={appStrings.studentProfile.saveChanges}
              disabled={!isDirty}
              onClick={handleSubmit(callDataOnSubmit, onError)}
            />
          </Grid>
          {isSmallDevice && (
            <Grid item>
              <GoBackBtn />
            </Grid>
          )}
        </Grid>
      )}
      <LeaveDialog {...dialogData} />
    </CustomBox>
  );
}

EditContent.propTypes = {
  formIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setSectionErrors: PropTypes.func,
  sectionErrors: PropTypes.object,
};

export default EditContent;
