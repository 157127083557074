import AddIcon from '@mui/icons-material/Add';
import ApiService from '../../Api/apiService';
import CheckIcon from '@mui/icons-material/Check';
import appStrings from '../../../../core/strings/appStrings';
import { getZipCodeInfo } from '../../../../core/services/zipCodeService';
import store from '../../../../core/redux/store';
import urls from '../../../../core/strings/urls';
import { saveReferencesInformation } from '../../../industrial/StudentProfile/Services/studentProfileService';

const maxRefsNumber = 3;

export const getStudentReferences = studentId => {
  const url = `${urls.getReferences}/${studentId}`;
  return ApiService.get(url);
};

export const formatRefsState = refs => {
  const zipCodes = store.getState().zipCode.formattedCodeList;

  if (refs.length) {
    refs.forEach(ref => {
      ref.state = zipCodes[ref?.zipCode]?.state ?? '';
    });
  }

  return refs;
};

export const getCities = refInfo => {
  return Promise.all([...refInfo.map(ref => getZipCodeInfo(ref.zipCode))]);
};

export const handleReferencesInfo = ({
  res,
  setRefs,
  setIsLoading,
  setRefsActualLength,
}) => {
  const refs = res.references ?? [];
  if (refs.length) {
    getCities(refs).then(resp => {
      formatRefsState(refs);

      setRefsActualLength(refs.length);
      setRefs(refs);
    });
  } else {
    setRefs([]);
    setIsLoading(false);
  }
};

export const deleteReference = ({
  index,
  addressId,
  id,
  setDeletedRefs,
  setRefs,
  setRefsActualLength,
  getValues,
  setValue,
  studentId,
  reset,
}) => {
  if (id) {
    setDeletedRefs(deletedRefs => {
      const outputState = deletedRefs ? deletedRefs.slice(0) : [];
      outputState.push({ id, addressId, isDeleted: true });
      return outputState;
    });
  } else {
    const refs = getValues('references');
    if (refs.length) {
      refs.splice(index, 1);
    }
  }

  setRefs(refs => {
    const outputState = refs.slice(0);
    delete outputState[index];
    return outputState;
  });
  setRefsActualLength(prevRefLength => prevRefLength - 1);

  if (id) {
    setTimeout(() => {
      saveDeletedReferences({ studentId, data: getValues(), setValue, reset });
    });
  }
};

export const addNewReference = ({
  refs,
  setRefs,
  refsActualLength,
  setRefsActualLength,
}) => {
  if (refsActualLength < maxRefsNumber) {
    const newRef = {
      id: '',
      firstName: '',
      lastName: '',
      addressId: '',
      relationship: null,
      addressLine1: '',
      addressLine2: '',
      state: '',
      city: '',
      zipCode: '',
      phoneNumber: '',
    };
    refs.push(newRef);
    setRefsActualLength(refsActualLength + 1);
    setRefs(refs);
  }
};

export const getSpeedDialOptions = ({
  refsActualLength,
  refs,
  setRefs,
  setRefsActualLength,
}) => {
  return [
    {
      text:
        refsActualLength >= maxRefsNumber
          ? appStrings.studentProfile.allReferencesAdded
          : `${
              appStrings.studentProfile.addReference
            } (${appStrings.studentProfile.nrMoreToAdd(3 - refsActualLength)})`,
      icon: refsActualLength >= maxRefsNumber ? <CheckIcon /> : <AddIcon />,
      onClick: () =>
        addNewReference({
          refs,
          setRefs,
          refsActualLength,
          setRefsActualLength,
        }),
      disabled: refsActualLength >= maxRefsNumber,
    },
  ];
};

export const saveDeletedReferences = ({ studentId, data, setValue, reset }) => {
  saveReferencesInformation({ id: studentId, data, setValue }).then(() =>
    reset(data)
  );
};
