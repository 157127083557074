import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import { buttonTypes } from '../../../../core/strings/appConstants';
import appStrings from '../../../../core/strings/appStrings';
import React from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

function ShowMoreButton({ isSavedCart, showSavedItems, setShowSavedItems }) {

  return (
    <>
      {isSavedCart && (
        <Grid container item xs={2} justifyContent="flex-end">
          <CustomButton
            data-test-id="showMoreBtn"
            id="showMoreBtn"
            size="small"
            btnType={buttonTypes.tertiary}
            endIcon={showSavedItems ? <ExpandLess /> : <ExpandMore />}
            label={showSavedItems
              ? appStrings.cart.showLess
              : appStrings.cart.showMore}
            onClick={() => setShowSavedItems(prev => !prev)}
          />
        </Grid>
      )}
    </>
  );
}

ShowMoreButton.propTypes = {
  isSavedCart: PropTypes.bool,
  setShowSavedItems: PropTypes.func,
  showSavedItems: PropTypes.bool,
};

export default ShowMoreButton;
