import { makeStyles } from 'tss-react/mui';

const useStudentsListStyles = makeStyles()(theme => ({
  paginationContainer: {
    '& >:first-of-type': { justifyContent: 'end' },
  },
  bottomContainer: {
    padding: `${theme.spacing(0)} ${theme.spacing(2)} ${theme.spacing(
      3
    )} ${theme.spacing(2)}`,
  },
  inactiveFlagContainer: {
    textAlign: 'right',
    '& .MuiFormControlLabel-label': {
      fontSize: theme.typography.caption.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  noDataContainer: {
    '& >div': {
      padding: `${theme.spacing(7)} 0 ${theme.spacing(9)} 0`,
    },
    '& h2': { fontSize: theme.typography.subtitle2.fontSize },
    '& span': { fontSize: '44px' },
  },
  studentDivider: {
    margin: '1px 0px !important',
  },
}));

export default useStudentsListStyles;
