import CONSTANTS from '../constants';
import { createSlice } from '@reduxjs/toolkit';

const purchasePowerSlice = createSlice({
  name: CONSTANTS.purchasePower,
  initialState: {
    currentPurchaseValue: 0,
    purchasedValue: 0,
    remainingValue: 0,
    isLoading: false,
    shouldGetPurchase: false,
  },
  reducers: {
    setPurchasePower: (state, action) => {
      state.currentPurchaseValue = action.payload.currentPurchaseValue;
      state.purchasedValue = action.payload.purchasedValue;
      state.remainingValue = action.payload.remainingValue;
      state.isLoading = false;
    },
    setPurchasePowerLoading: state => {
      state.isLoading = true;
    },
    setShouldGetPurchase: (state, action) => {
      state.shouldGetPurchase = action.payload;
    },
  },
});

export const {
  setPurchasePower,
  setPurchasePowerLoading,
  setShouldGetPurchase,
} = purchasePowerSlice.actions;
export default purchasePowerSlice.reducer;
