import CustomBox from '../../shared/CustomBox/CustomBox';
import CustomSkeleton from '../../shared/CustomSkeleton/CustomSkeleton';
import useAddProductStyles from './useAddProductStyles';

function AddProductSkeleton({ expanded }) {
  const css = useAddProductStyles().classes;
  const addProductLayout = [
    { xs: 3, width: '90%', height: '100%', variant: 'rect' },
    { xs: 3, width: '50%', height: '100%', variant: 'rect' },
    {
      xs: 5,
      width: '20%',
      height: '100%',
      variant: 'rect',
      container: true,
      justifyContent: 'flex-end',
    },
    {
      xs: 1,
      width: '1.7rem',
      height: '1.7rem',
      variant: 'circular',
      container: true,
      justifyContent: 'flex-end',
    },
  ];
  return (
    <CustomBox
      data-test-id="addProductSkeleton"
      customClass={css.addProductSkeletonContainer}
    >
      <CustomSkeleton
        layout={addProductLayout}
        containerClass={css.addProductSkeleton}
      />
    </CustomBox>
  );
}

export default AddProductSkeleton;
