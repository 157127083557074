import ApiService from '../../../shared/Api/apiService';
import urls from '../../../../core/strings/urls';
import moment from 'moment';
import { cartTypes } from '../../../../core/strings/appConstants';

export const setGuestItemsCartLocal = (isGuest = true) => {
  const guestItemsCart = JSON.parse(localStorage.getItem('guestItemsCart'));
  const now = moment();
  const value = guestItemsCart?.value;
  localStorage.setItem(
    'guestItemsCart',
    JSON.stringify({
      date: now.toJSON(),
      value: isGuest ? value || [] : [],
    })
  );
};

export const mergeCartToStudent = (studentId, guestItems) => {
  const apiData = guestItems.map(item => ({
    studentId: studentId,
    cartId: '',
    cartType: cartTypes.cart,
    productNumber: item.productNumber,
    quantity: 1,
    notes: [
      {
        noteType: 0,
        content: '',
      },
    ],
    recordId: 0,
  }));

  return ApiService.post(urls.mergeCartToStudent, apiData);
};

export const isCartIconLoading = (loading, isLoggedIn) =>
  (loading.mergeLoading || loading.getCartLoading) && isLoggedIn;
