import { Divider, Grid, Typography } from '@mui/material';
import { Fragment, useCallback, useEffect, useState } from 'react';
import {
  saveStudentsListApproveReject,
  updateCheckedStudent,
  updateNewStudentRegistered,
} from '../../../core/redux/slices/approveRejectSlice';
import { useDispatch, useSelector } from 'react-redux';

import { ADMIN } from '../../../core/navigation/roles';
import ApproveRejectSkeleton from './ApproveRejectSkeleton';
import CustomAccordion from '../../shared/CustomAccordion/CustomAccordion';
import CustomAccordionContainer from '../../shared/CustomAccordionContainer/CustomAccordionContainer';
import CustomBox from '../../shared/CustomBox/CustomBox';
import ListStudentsCards from './ListStudentsCards/ListStudentsCards';
import appStrings from '../../../core/strings/appStrings';
import { displayError } from '../../../core/redux/slices/notificationsSlice';
import { checkNoResult, getStudentsList } from './approveRejectService';
import { studentsApprovalState } from '../../../core/strings/appConstants';
import { trackGAPageViews } from '../../shared/Analytics/analyticsService';
import useApproveRejectStyles from './useApproveRejectStyles';
import Filters from './Filters/Filters';
import CustomIcon from '../../shared/CustomIcon/CustomIcon';
import { icons } from '../../../core/strings/icons';
import { getStudentsBySchool } from './Filters/filtersService';

function ApproveReject() {
  const css = useApproveRejectStyles().classes;
  const dispatch = useDispatch();
  const { employeeId, role } = useSelector(state => state.login.userDetails);
  const isAdmin = role === ADMIN;
  const { sortedList, studentsList } = useSelector(
    state => state.approveReject
  );
  const newStudentRegistered = useSelector(
    state => state.approveReject.newStudentRegistered
  );
  const selectedRepValue = useSelector(
    state => state.approveReject.approveRejectRep
  );
  const selectedSchool = useSelector(
    state => state.approveReject.selectedSchool
  );
  const employeeIdSearched = useSelector(
    state => state.approveReject.employeeIdSearched
  );
  const [expendedStatus, setExpandedStatus] = useState('pendingStudents');
  const [isLoading, setIsLoading] = useState(
    Boolean(!studentsList && !isAdmin)
  );
  const noResults = checkNoResult(sortedList);

  trackGAPageViews('/industrial/approveReject');

  const handleGetStudents = useCallback(
    id =>
      getStudentsList(id)
        .then(resp => {
          dispatch(
            saveStudentsListApproveReject({ resp, employeeIdSearched: id })
          );
        })
        .catch(e => dispatch(displayError({ message: e }))),
    [dispatch]
  );

  useEffect(() => {
    const id = isAdmin ? selectedRepValue : employeeId;
    if (
      id?.length &&
      (!studentsList || newStudentRegistered || id !== employeeIdSearched)
    ) {
      setIsLoading(true);
      dispatch(updateNewStudentRegistered(false));

      handleGetStudents(id).finally(() => setIsLoading(false));
    }
  }, [
    dispatch,
    employeeId,
    employeeIdSearched,
    handleGetStudents,
    isAdmin,
    newStudentRegistered,
    selectedRepValue,
    studentsList,
  ]);

  useEffect(() => {
    dispatch(updateCheckedStudent({ reset: true }));
  }, [dispatch, expendedStatus]);

  useEffect(() => {
    return () => {
      const id = isAdmin ? selectedRepValue : employeeId;
      if (id && !selectedSchool) {
        handleGetStudents(id);
      }
      if (selectedSchool) {
        getStudentsBySchool(selectedSchool)
          .then(resp => {
            dispatch(
              saveStudentsListApproveReject({
                resp: resp,
              })
            );
          })
          .catch(() => {
            dispatch(displayError());
          });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sections = [
    {
      id: 'pendingStudents',
      title: `${appStrings.approveReject.pendingStudents} (${sortedList.pending.length})`,
      data: sortedList.pending,
      sectionType: studentsApprovalState.pending,
    },
    {
      id: 'approvedStudents',
      title: `${appStrings.approveReject.approvedStudents} (${sortedList.approved.length})`,
      data: sortedList.approved,
      sectionType: studentsApprovalState.approved,
    },
    {
      id: 'rejectedStudents',
      title: `${appStrings.approveReject.rejectedStudents} (${sortedList.rejected.length})`,
      data: sortedList.rejected,
      sectionType: studentsApprovalState.rejected,
    },
  ];

  const filtersProps = {
    isAdmin,
    setIsLoading,
    isLoading,
  };

  const getAccordionLineContent = (elem, index) => (
    <Fragment key={elem.id}>
      <CustomAccordion
        id={elem.id}
        title={elem.title}
        expanded={expendedStatus}
        onExpandChange={() => setExpandedStatus(elem.id)}
        identifier={elem.id}
      >
        <ListStudentsCards
          students={elem.data}
          sectionType={elem.sectionType}
        />
      </CustomAccordion>
      {index < sections.length - 1 ? <Divider /> : null}
    </Fragment>
  );

  return (
    <Grid container id="approveRejectContainer">
      <Grid
        container
        item
        justifyContent="space-between"
        alignItems="center"
        className={css.topContainer}
        spacing={2}
      >
        <Grid item>
          <Typography variant="h2" id="approveRejectPageTitle">
            <strong>{appStrings.menu.approveRejectStudent}</strong>
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Filters {...filtersProps} />
        </Grid>

        {!isLoading ? (
          <>
            {!noResults && (
              <Grid
                item
                xs={12}
                data-test-id="renderedStudents"
                className={css.studentsContainer}
                id="approveRejectContentContainer"
              >
                <CustomBox width="100%">
                  <CustomAccordionContainer>
                    {sections.map((elem, index) =>
                      getAccordionLineContent(elem, index)
                    )}
                  </CustomAccordionContainer>
                </CustomBox>
              </Grid>
            )}
            {noResults && (
              <Grid
                item
                textAlign="center"
                container
                xs={12}
                className={css.noFilterContainer}
                id="approveRejectSelectFilterMessage"
                data-test-id="approveRejectSelectFilter"
              >
                <Grid item xs={12}>
                  <CustomIcon icon={icons.search} />
                </Grid>
                <Grid item xs={12}>
                  <p>{appStrings.approveReject.noFilter}</p>
                </Grid>
              </Grid>
            )}
          </>
        ) : (
          <ApproveRejectSkeleton />
        )}
      </Grid>
    </Grid>
  );
}
export default ApproveReject;
