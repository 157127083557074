import { useSelector } from 'react-redux';
import { wizardSteps } from '../wizardConstants';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import useWizardStyles from '../useWizardStyles';
import { useRef, useState } from 'react';

function WizardPageContainer({ isStudent }) {
  const css = useWizardStyles().classes;
  const activeStep = useSelector(state => state.wizard.activeStep);
  const shouldSaveOnLeavePage = useRef(false);
  const [dirtyInfos, setDirtyInfos] = useState(false);

  return (
    <Grid item className={css.formContentContainer}>
      {wizardSteps[activeStep]
        ? wizardSteps[activeStep].component({
            isStudent,
            dirtyInfos,
            setDirtyInfos,
            shouldSaveOnLeavePage,
          })
        : null}
    </Grid>
  );
}

WizardPageContainer.propTypes = {
  isStudent: PropTypes.bool,
};

export default WizardPageContainer;
