import CookieService from '../Cookie/cookieService';
import moment from 'moment';

const cookieKeys = {
  auth: 'userAuth',
  userDetails: 'userDetails',
  duration: 'duration',
};

const durationBasedOnRole = {
  industrial: { timeValue: 1, timeMetric: 'hours' },
  student: { timeValue: 30, timeMetric: 'minutes' },
};

const defaultOptions = { path: '/' };

/**
 * Gets the token used for Authentication
 * @returns the Authentication token
 */
export function getAuthToken() {
  return CookieService.getCookie(cookieKeys.auth);
}

/**
 * Sets the Authentication token
 * @param token - the Authentication token from SSO
 */
export function setAuthToken(token) {
  CookieService.setCookie(cookieKeys.auth, token, defaultOptions);
}

/**
 * Gets the user details from cookies
 * @returns the user details from cookies
 */
export function getIdentity() {
  return CookieService.getCookie(cookieKeys.userDetails);
}

/**
 * Sets the user details in cookies
 */
export function setIdentity(userDetails) {
  CookieService.setCookie(cookieKeys.userDetails, userDetails, defaultOptions);
}

export function logoutUser() {
  CookieService.removeCookie(cookieKeys.userDetails, defaultOptions);
  CookieService.removeCookie(cookieKeys.auth, defaultOptions);
  CookieService.removeCookie(cookieKeys.duration, defaultOptions);
}

export function getDuration() {
  return CookieService.getCookie(cookieKeys.duration);
}

export function setDuration(isIndustrial) {
  const durationValues = isIndustrial
    ? durationBasedOnRole.industrial
    : durationBasedOnRole.student;

  const newDuration = moment().add(
    durationValues.timeValue,
    durationValues.timeMetric
  );

  CookieService.setCookie(cookieKeys.duration, newDuration, defaultOptions);
}
