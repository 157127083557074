import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import useProductPageStyles from '../productPageStyles';
import SpecificationsImage from './SpecificationsImage';
import appStrings from '../../../../core/strings/appStrings';

function Specifications({specifications, specificationImageUrl}) {
  const css = useProductPageStyles().classes;

  return (
    <Grid
      data-test-id="renderedSpecifications"
      id="productPageSpecsContainer"
      container
      item
      xs={12}
      lg={specificationImageUrl ? 12 : 6}
      alignItems="flex-start"
      rowSpacing={2}
      className={css.specificationsContainer}
    >
      <Grid
        id="productPageSpecsTable"
        container
        item
        className={css.specificationsTable}
        rowSpacing={2} xs={12}
        lg={specificationImageUrl ? 6 : 12}
      >
        {specifications && specifications?.length ?
          specifications.map((specification, index) => 
            <Grid id={`productPageSpecItem-${index}`} key={index} item container>
              <Grid item xs={6} md={true}>{specification.name}</Grid>
              <Grid container item xs={6} md={true} justifyContent="center">
                <Typography fontWeight="bold">{specification.value}</Typography>
              </Grid>
            </Grid>)
          : <Typography data-test-id="renderedSpecificationsMessage">{appStrings.productPage.noSpecsAvailable}</Typography>
        }
      </Grid>
      {specificationImageUrl ? 
        <SpecificationsImage imageUrl={specificationImageUrl} /> : null
      }
    </Grid>
  );
}

Specifications.propTypes = {
  specifications: PropTypes.array,
  specificationImageUrl: PropTypes.string
};

export default Specifications;