import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CustomIcon from '../CustomIcon/CustomIcon';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PropTypes from 'prop-types';
import { icons } from '../../../core/strings/icons';

export const propTypes = {
  notice: PropTypes.string.isRequired,
  notificationType: PropTypes.string.isRequired,
  permanent: PropTypes.bool,
  action: PropTypes.func,
  onCloseCallback: PropTypes.func,
};

export const NotificationTypeConstants = {
  error: 'error',
  info: 'info',
  success: 'success',
  warning: 'warning',
};

export const notificationIcons = {
  error: <CustomIcon icon={icons.error} />,
  info: <InfoOutlinedIcon />,
  success: <CheckCircleOutlineIcon />,
  warning: <ErrorOutlineIcon />,
};
