import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import _uniqueId from 'lodash/uniqueId';
import {
  trackGAPageViews,
  trackGTMEvent,
} from '../../modules/shared/Analytics/analyticsService';
import { industrialRoleList } from './roles';
import IndustrialRoutesServices from './IndustrialRoutesServices';
import GuestRoutesServices from './GuestRoutesServices';
import { useSelector } from 'react-redux';
import ErrorContextProvider from '../../../ErrorContext';
import BrPageComponent from '../../../BrPageComponent';
import PrivateRoute from './privateRoute';
import { menuCategoriesList } from '../../modules/student/Category/CategoriesMenu/categoriesMenuService';
import { ROOT } from './paths';

const RouterConfig = () => {
  const location = useLocation();
  const { role } = useSelector(state => state.login.userDetails);
  const { routes, key } = PrivateRoute(role);
  const serviceElement = industrialRoleList.includes(role) ? (
    <IndustrialRoutesServices />
  ) : (
    <GuestRoutesServices />
  );

  useEffect(() => {
    const { pathname } = location;
    trackGAPageViews(pathname);
    trackGTMEvent('routeChange', {
      pathname,
      orig_ref_url: document.location.href,
    });
  }, [location]);

  const BrPage = (
    <ErrorContextProvider>
      <BrPageComponent />
    </ErrorContextProvider>
  );

  return (
    <Routes>
      <Route element={serviceElement}>
        <Route exact path={ROOT} element={BrPage} />
      </Route>

      <Route element={serviceElement}>
        {routes.map(
          route =>
            !route.disabled && (
              <Route
                element={route.component}
                path={route.path}
                exact={route.exact}
                key={_uniqueId(key)}
              />
            )
        )}
      </Route>

      {menuCategoriesList.map(
        route =>
          route.path && (
            <Route
              exact
              path={route.path}
              element={BrPage}
              key={_uniqueId(key)}
            />
          )
      )}

      <Route element={serviceElement}>
        <Route path={'/*'} element={BrPage}>
        </Route>
      </Route>
    </Routes>
  );
};

export default RouterConfig;
