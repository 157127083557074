import appStrings from '../../../../core/strings/appStrings';

export const clearEmptySections = (data, setEmptySections) => {
  clearEmptyAddresses(data, setEmptySections);
  clearEmptyEmployers(data, setEmptySections);
  clearEmptyReferences(data, setEmptySections);
};

const clearEmptyAddresses = (data, setEmptySections) => {
  const { currentAddress, previousAddresses } = data;
  if (currentAddress) {
    clearEmptyCurrentAddressWithId(data);
    setEmptySections(prevState => ({
      ...prevState,
      currentAddress: isEmptyObject(currentAddress),
    }));

    setTimeout(() => {
      setEmptySections(prevState => ({
        ...prevState,
        currentAddress: false,
      }));
    });
  }

  if (previousAddresses) {
    clearEmptyPrevAddressWithId(data);
    setEmptySections(prevState => ({
      ...prevState,
      previousAddresses: previousAddresses
        ?.map((elem, index) =>
          isEmptyObject(elem) && Object.keys(elem).length > 0 ? index : -1
        )
        .filter(index => index !== -1),
    }));

    setTimeout(() => {
      setEmptySections(prevState => ({
        ...prevState,
        previousAddresses: null,
      }));
    });
  }
};

const clearEmptyCurrentAddressWithId = data => {
  const { currentAddress } = data;
  const isEmpty = isEmptyObject(currentAddress);

  if (isEmpty) {
    const toDelete = JSON.stringify({
      currentAddress: {
        id: currentAddress.id,
        isDeleted: true,
      },
    });
    if (data.deletedAddresses === null || data.deletedAddresses === '{}') {
      data.deletedAddresses = toDelete;
    } else {
      data.deletedAddresses = combine(data.deletedAddresses, toDelete);
    }
    delete currentAddress.id;
    delete currentAddress.residencyLength;
  }
};

const clearEmptyPrevAddressWithId = data => {
  const { previousAddresses } = data;
  let addressesToDelete = [];
  for (let [index, address] of previousAddresses.entries()) {
    if (address) {
      let isEmpty = isEmptyObject(address);
      if (isEmpty) {
        addressesToDelete.push({
          id: address.id,
          isDeleted: true,
        });
        delete previousAddresses[index].id;
      }
    }
  }
  if (addressesToDelete.length) {
    updateDeletedAddresses(data, addressesToDelete);
  }
};

const updateDeletedAddresses = (data, addressesToDelete) => {
  const toDelete = JSON.stringify({
    previousAddresses: addressesToDelete,
  });
  if (data.deletedAddresses === null || data.deletedAddresses === '{}') {
    data.deletedAddresses = toDelete;
  } else {
    const deletedAddresses = JSON.parse(data.deletedAddresses);
    if (deletedAddresses.previousAddresses?.length) {
      deletedAddresses.previousAddresses =
        deletedAddresses.previousAddresses.concat(addressesToDelete);
      data.deletedAddresses = JSON.stringify(deletedAddresses);
    } else {
      data.deletedAddresses = combine(data.deletedAddresses, toDelete);
    }
  }
};

const clearEmptyEmployers = (data, setEmptySections) => {
  const { presentEmployer, futureEmployer, previousEmployers } = data;
  if (presentEmployer) {
    clearEmptyPresentEmployerWithId(data);
    setEmptySections(prevState => ({
      ...prevState,
      presentEmployer: isEmptyObject(presentEmployer),
    }));

    setTimeout(() => {
      setEmptySections(prevState => ({
        ...prevState,
        presentEmployer: false,
      }));
    });
  }

  if (futureEmployer) {
    clearEmptyFutureEmployerWithId(data);
    setEmptySections(prevState => ({
      ...prevState,
      futureEmployer: isEmptyObject(futureEmployer),
    }));

    setTimeout(() => {
      setEmptySections(prevState => ({
        ...prevState,
        futureEmployer: false,
      }));
    });
  }

  if (previousEmployers) {
    clearEmptyPrevEmployersWithId(data);
    setEmptySections(prevState => ({
      ...prevState,
      previousEmployers: previousEmployers
        ?.map((elem, index) =>
          isEmptyObject(elem || {}) && Object.keys(elem || {}).length > 0
            ? index
            : -1
        )
        .filter(index => index !== -1),
    }));

    setTimeout(() => {
      setEmptySections(prevState => ({
        ...prevState,
        previousEmployers: null,
      }));
    });
  }
};

const clearEmptyPresentEmployerWithId = data => {
  clearEmptyEmployerTypeWithId(data, 'presentEmployer');
};

const clearEmptyFutureEmployerWithId = data => {
  clearEmptyEmployerTypeWithId(data, 'futureEmployer');
};

const clearEmptyEmployerTypeWithId = (data, employerType) => {
  const employer = data[employerType];
  if (employer.id) {
    const isEmpty = isEmptyObject(employer);

    if (isEmpty) {
      const toDelete = JSON.stringify({
        [employerType]: {
          id: employer.id,
          addressId: employer.addressId,
          isDeleted: true,
        },
      });
      if (data.deletedEmployers === null || data.deletedEmployers === '{}') {
        data.deletedEmployers = toDelete;
      } else {
        data.deletedEmployers = combine(data.deletedEmployers, toDelete);
      }
      delete employer.id;
      delete employer.addressId;
    }
  }
};

const clearEmptyPrevEmployersWithId = data => {
  const { previousEmployers } = data;
  let employersToDelete = [];
  for (let [index, employer] of previousEmployers.entries()) {
    if (employer) {
      let isEmpty = isEmptyObject(employer);
      if (isEmpty) {
        employersToDelete.push({
          id: employer.id,
          addressId: employer.addressId,
          isDeleted: true,
        });
        delete previousEmployers[index].id;
        delete previousEmployers[index].addressId;
      }
    }
  }
  if (employersToDelete.length) {
    updateDeletedEmployers(data, employersToDelete);
  }
};

const updateDeletedEmployers = (data, employersToDelete) => {
  const toDelete = JSON.stringify({
    previousEmployers: employersToDelete,
  });
  if (data.deletedEmployers === null || data.deletedEmployers === '{}') {
    data.deletedEmployers = toDelete;
  } else {
    const deletedEmployers = JSON.parse(data.deletedEmployers);
    if (deletedEmployers.previousEmployers?.length) {
      deletedEmployers.previousEmployers =
        deletedEmployers.previousEmployers.concat(employersToDelete);
      data.deletedEmployers = JSON.stringify(deletedEmployers);
    } else {
      data.deletedEmployers = combine(data.deletedEmployers, toDelete);
    }
  }
};

const clearEmptyReferences = (data, setEmptySections) => {
  const { references } = data;
  if (references) {
    clearEmptyRefsWithId(data);
    setEmptySections(prevState => ({
      ...prevState,
      emptyRefs: references
        ?.map((elem, index) =>
          isEmptyObject(elem || {}) && Object.keys(elem || {}).length > 0
            ? index
            : -1
        )
        .filter(index => index !== -1),
    }));

    setTimeout(() => {
      setEmptySections(prevState => ({
        ...prevState,
        emptyRefs: null,
      }));
    });
  }
};

const clearEmptyRefsWithId = data => {
  const { references } = data;
  let refsToDelete = [];
  for (let [index, reference] of references.entries()) {
    if (reference) {
      let isEmpty = isEmptyObject(reference);
      if (isEmpty) {
        refsToDelete.push({
          id: reference.id,
          addressId: reference.addressId,
          isDeleted: true,
        });
        delete references[index].id;
        delete references[index].addressId;
      }
    }
  }
  if (refsToDelete.length) {
    if (data.deletedRefs === null || data.deletedRefs === '') {
      data.deletedRefs = JSON.stringify(refsToDelete);
    } else {
      data.deletedRefs = JSON.stringify(
        JSON.parse(data.deletedRefs).concat(refsToDelete)
      );
    }
  }
};

export const isEmptyObject = object => {
  return Object.keys(object).every(key => {
    switch (key) {
      case 'id':
      case 'addressId':
        return object[key] !== '' || object[key] !== null;
      case 'lengthOfEmployment':
      case 'residencyLength':
        return (
          object[key] === appStrings.studentProfile.yearsAndMonths(0, 0) ||
          object[key] === null
        );
      case 'month':
      case 'months':
      case 'year':
      case 'years':
      case 'employmentLength':
      case 'positionId':
        return object[key] === 0 || object[key] === null || object[key] === '';
      case 'addressType':
      case 'employerType':
      case 'isDeleted':
        return true;
      default:
        return isNullOrEmptyString(object[key]);
    }
  });
};

const isNullOrEmptyString = value => {
  const result = typeof value === 'string' ? value.trim() : null;
  return value === null || result === '';
};

const combine = (initial, extra) => {
  return `${initial.slice(0, -1)},${extra.slice(1)}`;
};
