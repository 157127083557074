import { Grid, Skeleton, useMediaQuery } from '@mui/material';
import React from 'react';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import { useOrderDetailsStyles } from '../orderDetailsStyles';

function OrderSummarySkeleton() {
  const css = useOrderDetailsStyles().classes;
  const isSmallDevice = useMediaQuery('(max-width:768px)');

  return (
    <Grid item xs={12} sm={isSmallDevice ? 12 : 5} md={5}>
      <CustomBox>
        <Grid item className={css.title} padding={2}>
          <Skeleton variant="rounded" height="30px" width="30%" />
        </Grid>
        <Grid item container rowSpacing={2} className={css.content}>
          {Array(3)
            .fill(true)
            .map((_, index) => (
              <Grid item container justifyContent="space-between" key={index}>
                <Skeleton variant="rounded" width="40%" />
                <Skeleton variant="rounded" width="20%" />
              </Grid>
            ))}
        </Grid>
        <Grid
          item
          container
          padding={2}
          justifyContent="space-between"
          className={css.orderTotal}
        >
          <Skeleton variant="rounded" width="40%" />
          <Skeleton variant="rounded" width="20%" />
        </Grid>
      </CustomBox>
    </Grid>
  );
}

export default OrderSummarySkeleton;
