import { Grid, Skeleton } from '@mui/material';
import React from 'react';
import { useProfileStyles } from './profileStyles';
import { profileInfosConstants } from './profileConstants';

function ProfileSkeleton() {
  const css = useProfileStyles().classes;

  return (
    <Grid
      container
      id="studentProfileDetails-skeleton"
      data-test-is="studentProfileDetails-skeletonTestId"
      className={css.mainContainer}
    >
      {Array(profileInfosConstants.length)
        .fill(true)
        .map((_, index) => (
          <Grid
            item
            container
            className={css.infoContainerParent}
            xs={12}
            sm={6}
            md={4}
            key={index}
          >
            <Grid item container xs={12} className={css.infoContainer}>
              <Grid item xs={6} sm={12} paddingBottom={1}>
                <Skeleton width="50%" variant="rounded" height="24px" />
              </Grid>
              <Grid
                item
                container
                xs={6}
                sm={12}
                justifyContent={{ xs: 'flex-end', sm: 'flex-start' }}
              >
                <Skeleton width="60%" variant="rounded" />
              </Grid>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
}

export default ProfileSkeleton;
