import { makeStyles } from 'tss-react/mui';

const usePrintOrderStyles = makeStyles()(() => ({
  printContainer: {
    padding: '0 40px 0 40px',
    '@page': {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
  },
  printHeader: {
    border: '25px solid #2d272b',
    height: '50px',

    'img': {
      marginTop: '-12px',
      marginLeft: '10px',
      verticalAlign: 'top',
    },
  },
  printTitle: {
    fontSize: '17px',
    color: '#ccc !important',
    fontStyle: 'italic',
    display: 'inline-block',
    marginTop: '-10px',
    marginLeft: '15px',
    verticalAlign: 'top',
    fontWeight: '500',
  },
  orderConfirmation: {
    fontWeight: '500',
    padding: '24px 0 24px 0',
    borderBottom: '3px solid black',
    fontSize: '30px',
  },
  summaryTitle: {
    fontWeight: '500',
    margin: '24px 0 24px 0',
  },
  tableContent: {
    paddingBottom: '6px',
    borderBottom: '1px solid black'
  },
  textRight: {
    textAlign: 'right',
  },
  itemRow: {
    margin: '4px 0 4px 0',
    wordBreak: 'break-word',
    pageBreakInside: 'avoid',
  },
  serialNumbers: {
    marginLeft: '15px',
  },
  contactDetailsContainer: {
    marginTop: '6px',
    pageBreakInside: 'avoid',
  },
  repInfo: {
    'p': {
      wordBreak: 'break-all',
    },
  },
  contactDetailsColumn: {
    height: 'fit-content',
  },
  totalsBorder: {
    paddingBottom: '10px',
    borderBottom: '1px solid black',
  },
  priceTotal: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  savingsTotal: {
    fontSize: '15px',
    fontWeight: 'bold',
  },
  divider: {
    width: '100%',
  },
}));

export default usePrintOrderStyles;