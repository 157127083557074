import CustomBox from '../../shared/CustomBox/CustomBox';
import CustomSkeleton from '../../shared/CustomSkeleton/CustomSkeleton';
import { Grid } from '@mui/material';
import PurchasePowerSkeleton from '../PurchasePower/PurchasePowerSkeleton';
import useTotalsStyles from './useTotalsStyles';

function TotalsSkeleton() {
  const css = useTotalsStyles().classes;
  const totalsTitleLayout = [{ xs: 12, width: '30%', variant: 'rect' }];
  const totalsContentLayout = [
    { xs: 4, width: '80%' },
    { xs: 4, width: '50%' },
    { xs: 4, width: '80%' },
  ];

  return (
    <CustomBox customClass={css.totalsSkeletonContainer}>
      <Grid
        container
        justifyContent="space-between"
        align-items="center"
        spacing={2}
        className={css.gridContainer}
      >
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={6}
          lg={6}
          spacing={1}
          className={css.totalsSection}
        >
          <CustomSkeleton layout={totalsTitleLayout} />
          <CustomSkeleton layout={totalsContentLayout} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <PurchasePowerSkeleton />
        </Grid>
      </Grid>
    </CustomBox>
  );
}

export default TotalsSkeleton;
