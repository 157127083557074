import React from 'react';
import PropTypes from 'prop-types';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import { Grid, Typography } from '@mui/material';
import appStrings from '../../../../core/strings/appStrings';
import { totalsLabels } from '../orderDetailsConstants';
import { useOrderDetailsStyles } from '../orderDetailsStyles';
import ItemsTitle from '../../ShoppingCart/Components/ItemsTitle';
import classNames from 'classnames';

function OrderSummary({ totals }) {
  const css = useOrderDetailsStyles().classes;

  return (
    <CustomBox
      id="OrderDetails-summary"
      data-test-id="OrderDetails-summaryTestId"
    >
      <Grid item className={css.title}>
        <ItemsTitle isOrderSummary />
      </Grid>
      <Grid
        item
        container
        rowSpacing={1}
        className={classNames(css.content, css.orderSummaryText)}
      >
        {totalsLabels.map((elem, index) => (
          <Grid
            item
            container
            justifyContent="space-between"
            key={index}
            id={`OrderDetails-summary-${elem.name}`}
          >
            <Typography>{elem.label}</Typography>
            <Typography>{totals[elem.name]}</Typography>
          </Grid>
        ))}
      </Grid>
      <Grid
        item
        container
        padding={2}
        justifyContent="space-between"
        className={css.orderTotal}
        id="OrderDetails-summary-total"
      >
        <Typography>{appStrings.cart.orderTotal}</Typography>
        <Typography>{totals.total}</Typography>
      </Grid>
    </CustomBox>
  );
}

OrderSummary.propTypes = {
  totals: PropTypes.object,
};

export default OrderSummary;
