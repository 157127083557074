import {
  addFromConsignedLoading,
  resetSelectedConsignedItems,
  updateLoadingState,
} from '../../../../core/redux/slices/consignedInventorySlice';
import {
  closeSignalRConnection,
  startSignalRConnection,
} from '../../../../core/services/signalR/signalRService';

import ApiService from '../../../shared/Api/apiService';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import { signalRStrings } from '../../../../core/strings/signalRStrings';
import store from '../../../../core/redux/store';
import urls from '../../../../core/strings/urls';

export const addConsignedItemsToOrder = ({
  selectedInventoryList,
  warehouse,
  bp,
  studentId,
  dispatch,
}) => {
  const selectedItems = selectedInventoryList.filter(item => item.selected);
  const formattedItems = selectedItems.map(item => {
    return {
      quantityToAdd: item.qtyToAdd,
      quantityInWarehouse: item.quantity,
      productNumber: item.productNumber,
      description: item.description,
      listPrice: item.listPrice,
    };
  });

  dispatch(addFromConsignedLoading(true));
  dispatch(updateLoadingState(true));
  startSignalRConnection(store.getState().login.userDetails.accountIdentifier)
    .then(() => {
      addConsignedItems({ formattedItems, studentId, bp, warehouse })
        .then(() => {
          dispatch(resetSelectedConsignedItems());
        })
        .catch(() => {
          closeSignalRConnection();
          dispatch(displayError());
        });
    })
    .catch(() => {
      dispatch(displayError());
    });
};

export const addConsignedItems = data => {
  return ApiService.post(urls.addConsignedItems, {
    consignedInventoryItems: data.formattedItems,
    studentId: data.studentId,
    businessPartNumber: data.bp,
    warehouse: data.warehouse,
    callbackMethodName: signalRStrings.addFromConsignedToOrder,
  });
};
