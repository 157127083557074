import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import React from 'react';
import { TextField } from '@mui/material';
import propTypes from '../propTypes';
import useMaskStyles from './useMaskedFieldStyles';
import classNames from 'classnames';

function MaskedField({ field, fieldErrors, control, errors, ...props }) {
  const maskStyles = useMaskStyles().classes;

  return (
    <Controller
      render={({ field: { ref, onChange, value, ...prop } }) => (
        <>
          <NumberFormat
            inputRef={ref}
            customInput={TextField}
            format={field.maskFormat}
            onValueChange={v => {
              onChange(v.value);
              field.onChangeCallback && field.onChangeCallback();
            }}
            variant="outlined"
            {...prop}
            fullWidth={props.fullWidth}
            FormHelperTextProps={{
              classes: { root: maskStyles.helperText },
            }}
            label={field.label}
            name={field.name}
            value={value}
            className={`${maskStyles.container} ${
              fieldErrors && maskStyles.errors
            }`}
            InputLabelProps={{
              classes: { root: fieldErrors && maskStyles.errors },
            }}
            InputProps={{ ...props.InputProps }}
            inputProps={{ 'data-test-id': `maskedInput-${field.name}` }}
            error={Boolean(fieldErrors)}
            helperText={
              fieldErrors &&
              fieldErrors.type !== 'required' &&
              fieldErrors.type !== 'manual'
                ? field.helperText || ' '
                : ' '
            }
            required={field.validations.required}
            id={field.name}
            size={props.size ? props.size : 'small'}
          />
          {fieldErrors?.message && (
            <span
              id="errorMessage"
              className={classNames(maskStyles.errorMessage)}
            >
              {fieldErrors.message}
            </span>
          )}
        </>
      )}
      control={control}
      defaultValue={field.defaultValue ?? ''}
      name={field.name}
      rules={{ ...field.validations }}
    />
  );
}

MaskedField.propTypes = propTypes;

export default MaskedField;
