import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateSchoolsRepList } from '../../../core/redux/slices/schoolsWithStudentsSlice';
import FormInput from '../../shared/FormInput/FormInput';
import useRepSearchStyles from './useRepSearchStyle';
import appStrings from '../../../core/strings/appStrings';
import { getReps, sortRepsByName } from './repSearchServices';
import { displayError } from '../../../core/redux/slices/notificationsSlice';
import { updateApproveRejectRepList } from '../../../core/redux/slices/approveRejectSlice';

function RepSearch({
  isLoading,
  setLoading,
  selectedRep,
  setSelectedRep,
  approveReject = false,
}) {
  const css = useRepSearchStyles().classes;
  const dispatch = useDispatch();
  const schoolsRepList = useSelector(
    state => state.schoolsWithStudents.repList
  );
  const approveRejectRepList = useSelector(
    state => state.approveReject.repList
  );
  const approveRejectSelectedSchool = useSelector(
    state => state.approveReject.selectedSchool
  );
  const repList = !approveReject ? schoolsRepList : approveRejectRepList;
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const fieldName = 'selectedRep';
  const watchSelectedRep = watch(fieldName);
  const AllReps = appStrings.searchInputs.allReps;

  const autocompleteField = {
    name: fieldName,
    label: !approveReject
      ? appStrings.searchInputs.searchReps
      : appStrings.searchInputs.selectRep,
    type: 'autocomplete',
    options: repList || [],
    defaultValue: selectedRep,
    disableClearable: true,
    disabled: isLoading,
    validations: { required: false },
  };

  useEffect(() => {
    if (!repList) {
      setLoading(true);
      getReps()
        .then(resp => {
          const formatRepList = resp.map(elem => ({
            label: elem.searchName,
            value: elem.id,
          }));
          formatRepList.sort(sortRepsByName);
          if (approveReject) {
            dispatch(updateApproveRejectRepList(formatRepList));
          } else {
            formatRepList.unshift({ label: AllReps, value: AllReps });
            dispatch(updateSchoolsRepList(formatRepList));
          }
        })
        .catch(() => {
          setLoading(false);
          dispatch(displayError());
        })
        .finally(() => {
          if (approveReject) {
            setLoading(false);
          }
        });
    }
  }, [AllReps, approveReject, dispatch, repList, setLoading]);

  useEffect(() => {
    if (watchSelectedRep) {
      dispatch(setSelectedRep(watchSelectedRep));
    } else if (repList && !selectedRep && !approveReject) {
      setValue(fieldName, AllReps);
    }
  }, [
    AllReps,
    approveReject,
    dispatch,
    repList,
    selectedRep,
    setSelectedRep,
    setValue,
    watchSelectedRep,
  ]);

  // reset rep field when use school filter
  useEffect(() => {
    if (approveRejectSelectedSchool) {
      setValue(fieldName, null);
      dispatch(setSelectedRep(null));
    }
  }, [approveRejectSelectedSchool, dispatch, setSelectedRep, setValue]);

  return (
    <Grid item className={css.searchInput}>
      <form>
        <FormInput
          field={autocompleteField}
          control={control}
          errors={errors}
        />
      </form>
    </Grid>
  );
}

export default RepSearch;
