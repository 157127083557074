import React from 'react';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import useCustomRotateStyle from './customRotateLoadingStyle';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function CustomRotateLoading({ id, dataTestId, customClass }) {
  const css = useCustomRotateStyle().classes;

  return (
    <RotateRightIcon
      id={id}
      data-test-id={dataTestId}
      onClick={event => {
        event.stopPropagation();
      }}
      className={classNames(css.icon, customClass)}
    />
  );
}

CustomRotateLoading.propTypes = {
  id: PropTypes.string,
  dataTestId: PropTypes.string,
  customClass: PropTypes.string,
};

export default CustomRotateLoading;
