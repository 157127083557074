import PropTypes from 'prop-types';

export const propTypes = {
  icon: PropTypes.any.isRequired,
  size: PropTypes.string,
  customClasses: PropTypes.any,
};

export const customIconMapping = {
  error: 3,
  info: 4,
  success: 2,
  help: 3,
  'no-references': 2,
  payment: 2,
  saved_items: 2,
  warning: 4,
};
