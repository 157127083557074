import { Grid, Typography } from '@mui/material';
import {
  alertButtonIds,
  auditAlertTypes,
  isUpdatedByAuditAlert,
} from '../../../shared/CustomAlert/customAlertConstants';
import { notesInputs, orderInputs } from './cartViewDetailsConstants';
import { useEffect, useRef, useState } from 'react';

import CustomAlert from '../../../shared/CustomAlert/CustomAlert';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import CustomSkeleton from '../../../shared/CustomSkeleton/CustomSkeleton';
import FormInput from '../../../shared/FormInput/FormInput';
import ItemAvailability from '../../ItemAvailability/ItemAvailability';
import SerialNumbersDialog from '../../SerialNumbersDialog/SerialNumbersDialog';
import appStrings from '../../../../core/strings/appStrings';
import { buttonTypes } from '../../../../core/strings/appConstants';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import { deleteItemNotification } from '../../../../core/redux/slices/cartSlice';
import { deleteNotification } from '../productsListService';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import { getWarehouseDetails } from '../../ConsignedInventory/consignedInventoryService';
import { notesIds } from '../../../../core/strings/IDs';
import { updateProductDetails } from '../../AddProduct/productDetailsService';
import useCartViewDetailsStyles from './useCartViewDetailsStyles';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import ProductPictureModal from '../ProductPictureModal/ProductPictureModal';

function CartViewDetails({
  product,
  studentDetails,
  control,
  setValue,
  getValues,
  expanded,
  setDisplayedProducts,
  handleOnReplace,
  handleOnRestorePrice,
  handleOnRemoveItem,
  setExpanded,
  setExpandedProduct,
}) {
  const { pathname } = useLocation();
  const isOrder = pathname.indexOf('order') !== -1;
  // check that student is active and product is found in LN
  const isActiveStudent = studentDetails.active && product.lineTotal !== '-';
  const { bp } = useParams();
  const [showAvailability, setShowAvailability] = useState(false);
  const [serialNumberOpen, setSerialNumberOpen] = useState(false);
  const [alertValues, setAlertValues] = useState({});
  const css = useCartViewDetailsStyles().classes;
  const dispatch = useDispatch();
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [warehouseLoading, setWarehouseLoading] = useState(false);
  const warehouseLayout = [
    {
      xs: 12,
      width: '100%',
      height: '100%',
      variant: 'rect',
    },
  ];
  const initialCall = useRef(true);
  const [productPictureOpen, setProductPictureOpen] = useState(false);

  const handleCheckAvailability = () => {
    setShowAvailability(true);
  };

  const handleAlertChange = (event, index) => {
    setAlertValues({ ...alertValues, [index]: event.target.value });
  };

  const handleOpenSerialNumber = () => {
    if (isActiveStudent) {
      setSerialNumberOpen(true);
    }
  };

  const handleCloseSerialNumber = () => {
    setSerialNumberOpen(false);
  };

  const handleAddSerialNumbers = data => {
    const newSerialNumbers = getPopulatedFields(data);
    setSerialNumberOpen(false);
    setValue(`serialNumber${product.recordId}`, newSerialNumbers[0]);
    setValue(`serialNumberValue${product.recordId}`, newSerialNumbers, {
      shouldDirty: true,
    });
  };

  const getPopulatedFields = data => {
    const populatedFields = [];
    Object.keys(data).forEach(field => {
      const fieldValue = data[field].trim();
      if (fieldValue) {
        populatedFields.push(fieldValue);
      }
    });
    return populatedFields;
  };

  const deleteAction = alertIndex => {
    deleteNotification(product.itemNotifications[alertIndex].id)
      .then(() => {
        dispatch(
          deleteItemNotification({
            recordId: product.recordId,
            notificationIndex: alertIndex,
          })
        );
        setAlertValues({});
      })
      .catch(() => {
        dispatch(displayError());
      });
  };

  const alertActions = {
    [alertButtonIds.remove]: () => {
      handleOnRemoveItem();
    },
    [alertButtonIds.replace]: ({ alertValue }) => {
      handleOnReplace(alertValue, true);
      setAlertValues({});
    },
    [alertButtonIds.keep]: ({ alertIndex }) => {
      deleteAction(alertIndex);
    },
    [alertButtonIds.ok]: ({ alertIndex }) => {
      setExpanded(null);
      setExpandedProduct(null);
      deleteAction(alertIndex);
    },
    [alertButtonIds.restore]: ({ alertIndex }) => {
      deleteAction(alertIndex);
      handleOnRestorePrice();
    },
  };

  const handleAlertAction = (button, alertValue, alertIndex) => {
    alertActions[button]({ alertValue, alertIndex });
  };

  const getAuditAlert = () => {
    if (!isOrder) {
      const userAudit = product.itemAuditNotification;
      if (userAudit) {
        return (
          <CustomAlert
            messageType={auditAlertTypes[userAudit.messageType]}
            messageTitle={userAudit.message}
            customClass={classNames({
              [css.auditUpdate]: isUpdatedByAuditAlert(userAudit.messageType),
            })}
          />
        );
      }
    }
    return null;
  };

  useEffect(() => {
    if (warehouseOptions.length > 0 && !warehouseLoading) {
      const selectedWarehouse = getValues(`warehouse${product.recordId}`);
      let savedWarehouse;
      if (selectedWarehouse) {
        savedWarehouse = warehouseOptions.find(
          option => option.value.name === selectedWarehouse.name
        );
      } else {
        savedWarehouse = warehouseOptions.find(
          option => option.value.name === product.warehouse.name
        );
      }
      setTimeout(() => {
        setValue(`warehouse${product.recordId}`, savedWarehouse?.value);
      });
    }
  }, [
    getValues,
    product.recordId,
    product.warehouse,
    setValue,
    warehouseLoading,
    warehouseOptions,
  ]);

  useEffect(() => {
    if (initialCall.current) {
      initialCall.current = false;
      if (isOrder) {
        setValue(
          `serialNumber${product.recordId}`,
          product.serialNumbers[0] || ''
        );
        setValue(`serialNumberValue${product.recordId}`, product.serialNumbers);
        setValue(`lnLineNotes${product.recordId}`, product.notes || '');
        setWarehouseLoading(true);
        getWarehouseDetails(
          product.productNumber,
          setWarehouseOptions,
          true,
          isOrder
        ).finally(() => setWarehouseLoading(false));
      } else {
        const lnLineNotes = product.notes?.find(
          elem => elem.noteType === notesIds.LnLineNote
        );
        const studentNotes = product.notes?.find(
          elem => elem.noteType === notesIds.StudentNote
        );
        setValue(
          `lnLineNotes${product.recordId}`,
          lnLineNotes ? lnLineNotes.content : ''
        );
        setValue(
          `studentNotes${product.recordId}`,
          studentNotes ? studentNotes.content : ''
        );
      }
    }
  }, [dispatch, isOrder, product, setValue]);

  const onWarehouseChange = () => {
    const warehouse = getValues(`warehouse${product.recordId}`);
    updateProductDetails(product.productNumber, bp, {
      warehouseCode: warehouse.code,
      warehouseName: warehouse.name,
      studentPrice: getValues(`editableStudentPrice${product.recordId}`),
    }).then(response => {
      setDisplayedProducts(prev => {
        const displayedProducts = cloneDeep(prev);
        displayedProducts[expanded].listPrice = response.itemPriceDto.listPrice;
        return displayedProducts;
      });
    });
  };

  const getInputs = () => {
    const inputs = isOrder
      ? orderInputs(
          product,
          isActiveStudent,
          warehouseOptions,
          onWarehouseChange
        )
      : notesInputs(product, isActiveStudent);
    return isOrder ? (
      <Grid container>
        <Grid container item xs={5}>
          {inputs.slice(0, 3).map(field => {
            return (
              <Grid
                item
                {...field.gridLayout}
                key={field.name}
                className={css.bottomFieldContainer}
              >
                {field.type !== 'hidden' && (
                  <Typography className={css.bottomLabel}>
                    <strong>{field.labelText}</strong>
                  </Typography>
                )}
                <div
                  className={classNames(css.bottomInputContainer, {
                    [css.serialNumber]:
                      field.name === `serialNumber${product.recordId}`,
                  })}
                  onClick={
                    field.name === `serialNumber${product.recordId}`
                      ? handleOpenSerialNumber
                      : null
                  }
                >
                  {warehouseLoading &&
                  field.name === `warehouse${product.recordId}` ? (
                    <CustomSkeleton
                      layout={warehouseLayout}
                      containerClass={css.warehouseLoader}
                    />
                  ) : (
                    <FormInput
                      field={field}
                      control={control}
                      InputProps={field.InputProps}
                    />
                  )}
                </div>
              </Grid>
            );
          })}
        </Grid>
        <Grid item container xs justifyContent="flex-end">
          <Grid item xs={11}>
            <FormInput field={inputs[3]} control={control} />
          </Grid>
        </Grid>
      </Grid>
    ) : (
      notesInputs(product, isActiveStudent).map((field, index) => {
        return (
          <Grid item {...field.gridLayout} key={index}>
            <FormInput field={field} control={control} />
          </Grid>
        );
      })
    );
  };

  return (
    <div data-test-id="productCartViewDetails" className={css.detailsContainer}>
      {product?.itemNotifications?.length || product?.itemAuditNotification ? (
        <div className={css.alertContainer}>
          {getAuditAlert()}
          {product.itemNotifications?.map((notification, index) => {
            return (
              <CustomAlert
                {...notification}
                alertValue={
                  alertValues[index]
                    ? alertValues[index]
                    : notification.messages[0].value
                }
                handleAlertChange={event => handleAlertChange(event, index)}
                handleAlertAction={handleAlertAction}
                alertIndex={index}
                key={notification.id}
                id={`${notification.messageType}-${index}`}
              />
            );
          })}
        </div>
      ) : null}

      <Grid
        container
        className={classNames(css.notesContainer, css.childContainer)}
      >
        {getInputs()}
      </Grid>
      <Grid
        container
        justifyContent="flex-end"
        spacing={2}
        className={css.childContainer}
      >
        <Grid item>
          <CustomButton
            label={appStrings.cart.checkAvailability}
            btnType={buttonTypes.secondary}
            onClick={handleCheckAvailability}
            customClass={css.actionButton}
            id="quoteViewDetailsCheckAvailabilityButton"
          />
        </Grid>
        <Grid item>
          <CustomButton
            label={appStrings.cart.viewPicture}
            btnType={buttonTypes.secondary}
            customClass={css.actionButton}
            id="quoteViewDetailsViewPictureButton"
            onClick={() => setProductPictureOpen(true)}
          />
        </Grid>
      </Grid>
      {showAvailability ? (
        <ItemAvailability
          productNumber={product.productNumber}
          quantity={
            getValues(`editableQuantity${product.recordId}`) ?? product.quantity
          }
          bp={bp}
          setShowDialog={setShowAvailability}
        />
      ) : null}
      {serialNumberOpen && (
        <SerialNumbersDialog
          serialNumbers={getValues(`serialNumberValue${product.recordId}`)}
          quantity={Number(getValues(`editableQuantity${product.recordId}`))}
          handleClose={handleCloseSerialNumber}
          onSubmit={handleAddSerialNumbers}
        />
      )}
      {productPictureOpen && (
        <ProductPictureModal
          productNumber={product.productNumber}
          handleClose={() => setProductPictureOpen(false)}
        />
      )}
    </div>
  );
}
export default CartViewDetails;
