import { InputAdornment } from '@mui/material';
import appStrings from '../../../core/strings/appStrings';

export const productDetailsFields = [
  { label: appStrings.cart.listPrice, name: 'listPrice' },
  { label: appStrings.cart.discountPercent, name: 'discountPercentage' },
  { label: appStrings.cart.discountAmount, name: 'discountAmount' },
  { label: appStrings.cart.studentPrice, name: 'studentPrice' },
  { label: appStrings.cart.description, name: 'description' },
  { label: appStrings.cart.lifeCycle, name: 'lifeCycleDescription' },
  { label: appStrings.cart.weight, name: 'weight' },
  { label: appStrings.cart.catalogPage, name: 'catalogPage' },
  { label: appStrings.cart.directDelivery, name: 'directDelivery' },
  { label: appStrings.cart.supersede, name: 'supersede' },
];

export const cartInputs = [
  {
    name: 'lnLineNotes',
    label: appStrings.cart.lnLineNotes,
    type: 'text',
    multiline: true,
    rows: 3,
    validations: {
      maxLength: 72,
    },
    gridLayout: { xs: 12, sm: 4, md: 4, lg: 4 },
  },
  {
    name: 'studentNotes',
    label: appStrings.cart.studentNotes,
    type: 'text',
    multiline: true,
    rows: 3,
    validations: {
      maxLength: 2000,
    },
    gridLayout: { xs: 12, sm: 8, md: 8, lg: 8 },
    placeholderText: appStrings.cart.studentNotesPlaceholder
  },
];

export const orderInputs = (warehouseOptions, onWarehouseChange) => [
  {
    name: 'warehouse',
    labelText: `${appStrings.order.warehouse}:`,
    type: 'select',
    options: warehouseOptions,
    defaultValue: warehouseOptions[0]?.value,
    readOnly: warehouseOptions.length <= 1,
    gridLayout: { xs: 12 },
    onChangeCallback: onWarehouseChange,
  },
  {
    name: 'serialNumber',
    labelText: `${appStrings.order.serialNumber}:`,
    type: 'text',
    readOnly: true,
    gridLayout: { xs: 12 },
    InputProps: {
      endAdornment: <InputAdornment position="end">{'...'}</InputAdornment>,
    },
  },
  {
    name: 'serialNumberValue',
    type: 'hidden',
  },
  {
    name: 'lnLineNotes',
    label: appStrings.cart.lnLineNotes,
    type: 'text',
    multiline: true,
    rows: 4,
    validations: {
      maxLength: 72,
    },
    gridLayout: { xs: 12 },
  },
];
