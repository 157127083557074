import { Divider, Grid, Typography } from '@mui/material';
import appStrings from '../../../core/strings/appStrings';

import CustomDialog from '../../shared/CustomDialog/CustomDialog';
import ErrorIcon from '@mui/icons-material/Error';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import useErrorDialogClasses from './useErrorDialogStyles';
import { ecErrorTypes } from '../Cart/cartConstants';
import { useDispatch, useSelector } from 'react-redux';
import { clearN90Errors } from '../../../core/redux/slices/cartSlice';
import { buttonTypes } from '../../../core/strings/appConstants';

function ErrorDialog({ callback, handleClose }) {
  const css = useErrorDialogClasses().classes;
  const dispatch = useDispatch();
  const { ecValidation, profileValidation } = useSelector(
    state => state.cart.n90Errors
  );

  const isProfileValidation = profileValidation.length > 0;
  const isWarningsOnly =
    !isProfileValidation &&
    !ecValidation.some(item => item.status === ecErrorTypes.error);
  const closeDialog = () => {
    dispatch(clearN90Errors());
    handleClose();
  };

  const warningButtons = [
    {
      label: appStrings.common.ignore,
      btnType: buttonTypes.secondary,
      onClick: () => {
        if (callback) {
          callback();
        }
        closeDialog();
      },
    },
    {
      label: appStrings.common.close,
      onClick: closeDialog,
    },
  ];

  const errorButtons = [
    {
      label: appStrings.common.close,
      onClick: closeDialog,
    },
  ];

  const ProfileValidation = () => {
    return (
      <Grid item xs={12}>
        <Grid container rowSpacing={2} spacing={2}>
          <Grid item xs={2}>
            <Typography fontWeight="bold">{appStrings.order.status}</Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography fontWeight="bold" textAlign="left">
              {appStrings.order.description}
            </Typography>
          </Grid>
        </Grid>
        <Divider orientation="horizontal" className={css.divider} />
        <Grid container rowSpacing={2} className={css.container}>
          {profileValidation?.map((error, index) => {
            return (
              <Grid item xs={12} key={`${index}-error`}>
                <Grid container xs={12} item>
                  <Grid item xs={2}>
                    <ErrorIcon color="error" />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography textAlign="left">{error}</Typography>
                  </Grid>
                </Grid>
                <Divider orientation="horizontal" className={css.divider} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  };

  const EcValidation = () => {
    return (
      <Grid item xs={12}>
        <Grid container rowSpacing={2} spacing={2}>
          <Grid item xs={2}>
            <Typography fontWeight="bold">{appStrings.order.status}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography fontWeight="bold" textAlign="left">
              {appStrings.order.description}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography fontWeight="bold" textAlign="left">
              {appStrings.order.howToFix}
            </Typography>
          </Grid>
        </Grid>
        <Divider orientation="horizontal" className={css.divider} />
        <Grid container rowSpacing={2} className={css.container}>
          {ecValidation?.map((error, index) => {
            return (
              <Grid item xs={12} key={`${index}-error`}>
                <Grid container xs={12} spacing={2} item>
                  <Grid item xs={2}>
                    {error.status === ecErrorTypes.warning ? (
                      <WarningAmberIcon color="warning" />
                    ) : (
                      <ErrorIcon color="error" />
                    )}
                  </Grid>
                  <Grid item xs={5}>
                    <Typography textAlign="left">
                      {error.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography textAlign="left">{error.howToFix}</Typography>
                  </Grid>
                </Grid>
                <Divider orientation="horizontal" className={css.divider} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  };

  return (
    <CustomDialog
      id="direct-debit"
      open
      customClass={css.errorContainer}
      title={
        isProfileValidation
          ? appStrings.order.profileValidation
          : appStrings.order.ecValidation
      }
      message={isProfileValidation ? <ProfileValidation /> : <EcValidation />}
      buttons={isWarningsOnly ? warningButtons : errorButtons}
    />
  );
}

export default ErrorDialog;
