import { makeStyles } from 'tss-react/mui';

const useCustomRotateStyle = makeStyles()(theme => ({
  icon: {
    animation: 'spin 3s linear infinite',
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(-360deg)',
      },
    },
  },
}));

export default useCustomRotateStyle;
