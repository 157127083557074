import { useEffect, useState } from 'react';

import CustomBox from '../../../shared/CustomBox/CustomBox';
import { Grid } from '@mui/material';
import PurchasePower from '../../PurchasePower/PurchasePower';
import appStrings from '../../../../core/strings/appStrings';
import { formatNumberWithCurrency } from '../../../../core/services/utilsService';
import { purchaseTypes } from '../../PurchasePower/purchasePowerConstants';
import useRestorePurchaseStyles from '../useRestorePurchaseStyles';
import { useSelector } from 'react-redux';
import { categories } from '../restorePurchasesConstants';

function RestoreTotals() {
  const css = useRestorePurchaseStyles().classes;
  const {
    selectedItems,
    selectedAll,
    linesTotal,
    hasSelectedAllItems,
    exceptionListSelectAll,
  } = useSelector(state => state.restorePurchases);
  const [totals, setTotals] = useState(0);

  useEffect(() => {
    let newTotals = 0;
    if (hasSelectedAllItems) {
      newTotals = linesTotal;
      if (!selectedAll && exceptionListSelectAll.length) {
        exceptionListSelectAll.forEach(item => {
          newTotals -= item.lineTotal;
        });
      }
      selectedItems.forEach(item => {
        if (item.category === categories.none) {
          newTotals -= item.lineTotal;
        }
      });
    } else {
      selectedItems.forEach(item => {
        if (item.category !== categories.none) {
          newTotals += item.lineTotal;
        }
      });
    }

    setTotals(newTotals);
  }, [
    exceptionListSelectAll,
    hasSelectedAllItems,
    linesTotal,
    selectedAll,
    selectedItems,
  ]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <CustomBox customClass={css.totalsContainer}>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={4}>
              <h3>
                {`${
                  appStrings.restorePurchase.totalListPrice
                }: ${formatNumberWithCurrency(totals)}`}
              </h3>

              <p>{appStrings.restorePurchase.amountRestoredToStdPP}</p>
            </Grid>
            <Grid item xs={12} sm={8}>
              <PurchasePower
                purchaseType={purchaseTypes.restore}
                returnListTotal={totals}
              />
            </Grid>
          </Grid>
        </CustomBox>
      </Grid>
    </Grid>
  );
}

export default RestoreTotals;
