import { makeStyles } from 'tss-react/mui';

const useShippingAndBillingStyles = makeStyles()(theme => ({
  sectionTitle: {
    marginBottom: theme.spacing(1),
    marginLeft: '4px',
  },
  sectionBox: {
    border: `1px solid ${theme.palette.common.secondary3}`,
    padding: '16px 40px 8px',
    marginBottom: theme.spacing(2),
  },
  sectionFormContainer: {
    '& >div:nth-of-type(2)': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(3.5),
    },
    '& >div:nth-of-type(-n+2)': {
      textAlign: '-webkit-center',
      '& >div': {
        maxWidth: '310px',
        display: 'flex',
        alignContent: 'space-between',
        '& label': {
          button: {
            marginLeft: 'auto',
            marginRight: 0,
          },
        },
      },
    },
  },
}));

export default useShippingAndBillingStyles;
