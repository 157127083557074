import {
  ClearSchoolBpNumber,
  GetStateOptions,
  UpdateEducationManager,
  UpdateSchools,
  UpdateStateAndCityByZipCode,
  UpdateUsesTools,
} from './Hooks/updaters';
import { Grid, Link, Paper, Typography } from '@mui/material';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { UseErrorSnackbars, UseUpdateErrors } from './Hooks/errorHandler';
import {
  displayError,
  displaySuccess,
} from '../../../core/redux/slices/notificationsSlice';
import {
  getGuestRegisterInputs,
  registerSections,
  updateSelectOptions,
} from './registerInputs';
import { useDispatch, useSelector } from 'react-redux';

import CustomBox from '../CustomBox/CustomBox';
import CustomButton from '../CustomButton/CustomButton';
import FormInput from '../FormInput/FormInput';
import LeaveDialog from '../LeaveDialog/LeaveDialog';
import MD5 from 'crypto-js/md5';
import appStrings from '../../../core/strings/appStrings';
import { dialogBtnActions } from '../LeaveDialog/leaveDialogConstants';
import { getStateCodeByName } from '../../../core/strings/states';
import { passwordHashSalt, steps } from './registerConstants';
import {
  getSelectedSchoolDetails,
  postStudentRegistration,
} from './registerService';
import { useForm } from 'react-hook-form';
import { useRegisterStyles } from './useRegisterStyles';
import { registerStudentInList } from '../../../core/redux/slices/schoolsWithStudentsSlice';
import { updateNewStudentRegistered } from '../../../core/redux/slices/approveRejectSlice';
import { trackGAPageViews } from '../Analytics/analyticsService';
import { useNavigate } from 'react-router-dom';
import { ROOT } from '../../../core/navigation/paths';
import { setRegisteredStudent } from '../../../core/redux/slices/registerSlice';
import CustomStepper from '../CustomStepper/CustomStepper';
import PasswordValidation from '../PasswordValidation/PasswordValidation';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';
import { getDateWithoutOffset, getDisplayErrors } from '../../../core/services/utilsService';
import { notificationRole } from '../../../core/strings/appConstants';
import MandatoryFieldsMessage from '../MandatoryFieldsMessage/MandatoryFieldsMessage';

function RegisterGuest() {
  const navigate = useNavigate();
  const css = useRegisterStyles().classes;
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { isDirty, errors, touchedFields },
    clearErrors,
    setError,
    getValues,
    trigger,
  } = useForm({ criteriaMode: 'all', mode: 'onTouched' });

  const formFields = watch();
  const loginState = useSelector(state => state.login);
  const userData = loginState.userDetails;
  const [schools, setSchools] = useState({});
  const [formWasSubmitted, setFormWasSubmitted] = useState(false);
  const registerInputs = getGuestRegisterInputs;
  const [activeStep, setActiveStep] = useState(0);
  const [isTermsAndConditionsVisible, setIsTermsAndConditionsVisible] =
    useState(false);
  const [registrationLoading, setRegistrationLoading] = useState(false);

  trackGAPageViews('/register');

  registerInputs.accountDetails.fields.find(
    field => field.name === 'passwordConfirmation'
  ).validations.validate = {
    passwordConfirm: value =>
      formFields.password &&
      formFields.passwordConfirmation &&
      value === formFields.password,
  };

  GetStateOptions({
    employeeId: userData.employeeId,
  });
  UpdateStateAndCityByZipCode({
    formFields,
    setValue,
    setError,
    clearErrors,
    updateSelectOptions,
    employeeId: userData.employeeId,
  });
  UpdateSchools({
    formFields,
    updateSelectOptions,
    setSchools,
    employeeId: userData.employeeId,
    setValue
  });
  UpdateEducationManager({ formFields, schools, setValue, userData });
  UpdateUsesTools({ formFields, setValue });
  ClearSchoolBpNumber({
    formFields,
    updateSelectOptions,
    setValue,
    errors,
    employeeId: userData.employeeId,
  });
  UseErrorSnackbars({ errors });
  UseUpdateErrors({ formFields, clearErrors });

  const resetSchoolBpOptions = useCallback(() => {
    const isLoggedIn = userData.employeeId !== undefined;
    updateSelectOptions('schoolDetails', 'schoolBpNumber', [], isLoggedIn);
  }, [userData.employeeId]);

  useEffect(() => {
    if (formWasSubmitted && !isDirty) {
      navigate(ROOT);
    }
  }, [isDirty, formWasSubmitted, navigate]);

  useEffect(() => () => resetSchoolBpOptions(), [resetSchoolBpOptions]);

  const onSubmit = data => {
    setRegistrationLoading(true);
    const {
      educationAccountManagerName,
      password,
      passwordConfirmation,
      graduationDate,
      ...submitData
    } = data;
    const hashedPassword = MD5(password + passwordHashSalt).toString();
    Object.keys(submitData).forEach(k => {
      if (typeof submitData[k] === 'string') {
        submitData[k] = submitData[k]?.trim()
      }
    });
    const registrationData = {
      ...submitData,
      hashedPassword,
      graduationDate: getDateWithoutOffset(graduationDate),
      ...getSelectedSchoolDetails({
        schoolBpNumber: data.schoolBpNumber,
        schools,
      }),
    };
    registrationData.state = getStateCodeByName(registrationData.state);
    postStudentRegistration(registrationData)
      .then(resp => {
        registrationData.id = resp;
        delete registrationData.graduationDate;

        dispatch(registerStudentInList(registrationData));

        dispatch(updateNewStudentRegistered(true));

        dispatch(
          displaySuccess({
            message: appStrings.register.registerSuccessfulGuest,
            role: notificationRole.student,
          })
        );
        dispatch(setRegisteredStudent(registrationData));
        reset(data);
        setFormWasSubmitted(true);
      })
      .catch(e => {
        dispatch(
          displayError({
            message:
              e.errors && Object.keys(e.errors).length === 1 && e.errors?.Email
                ? appStrings.validationMessages.existingEmail
                : getDisplayErrors(e),
            role: notificationRole.student,
          })
        );
      })
      .finally(() => {
        setRegistrationLoading(false);
      });
  };

  const dialogData = {
    title: appStrings.modal.register.title,
    description: appStrings.modal.register.description,
    isDirty,
    reset,
    watch,
    buttons: [dialogBtnActions.stay, dialogBtnActions.leave],
    errors,
    handleSubmit,
    dispatch,
  };

  // Enable or disable State according to its value
  if (getValues('state')) {
    registerInputs.userDetails.fields[8].disabled = false;
  } else {
    registerInputs.userDetails.fields[8].disabled = true;
  }

  const submitSection = index => {
    const isSubmit = registerSections.length - 1 === index;
    const id = `register-${isSubmit ? 'submit' : 'continue'}-button${
      isSubmit ? '' : '-' + index
    }`;
    return (
      <Grid item xs={12} className={css.guestSubmitContainer}>
        <CustomButton
          label={
            isSubmit ? appStrings.login.register : appStrings.register.continue
          }
          data-test-id={id}
          id={id}
          color="red"
          onClick={() => {
            isSubmit ? handleSubmit(onSubmit)() : submitCurrentStep(index);
          }}
          disabled={registrationLoading}
        />
      </Grid>
    );
  };

  const submitCurrentStep = index => {
    const currentFields = registerInputs[registerSections[index]].fields.map(
      item => {
        return item.name;
      }
    );
    trigger(currentFields).then(isValid => {
      if (isValid) {
        setActiveStep(activeStep + 1);
      }
    });
  };

  const handleSetIsAgreed = () => {
    setIsTermsAndConditionsVisible(false);
    setValue('termsAndConditions', true, { shouldDirty: true });
  };

  const isLastInput = (section, field) => {
    return (
      (registerInputs[section].sectionTitle ===
        appStrings.register.contactInformation &&
        field.label ===
          `${appStrings.register.permanentHome} ${appStrings.common.state}`) ||
      (registerInputs[section].sectionTitle ===
        appStrings.register.schoolInformation &&
        field.label === appStrings.register.graduationDay) ||
      (registerInputs[section].sectionTitle === appStrings.register.password &&
        field.label === appStrings.register.confirmPassword)
    );
  };

  const mandatoryFieldInfo = (section, field) => {
    if (isLastInput(section, field)) {
      return (
        <div id="mandatoryFieldInfo">
          <MandatoryFieldsMessage />
        </div>
      );
    }
  };

  const getInputClass = field => {
    if (field.type !== 'checkbox') {
      return css.spacedInput;
    }
  };

  const customFieldDetails = (section, field, errors) => {
    if (
      registerInputs[section].sectionTitle === appStrings.register.password &&
      field.label === appStrings.register.password
    ) {
      return (
        <PasswordValidation
          isTouched={touchedFields.password}
          warnCapsLock
          validateItems={[
            appStrings.validationMessages.passwordLength,
            appStrings.validationMessages.passwordLetter,
            appStrings.validationMessages.passwordNumber,
            appStrings.validationMessages.passwordSpecialCharacter,
          ]}
          errors={Object.values(errors?.password?.types ?? {})}
        />
      );
    } else if (
      registerInputs[section].sectionTitle ===
        appStrings.register.schoolInformation &&
      field.label === appStrings.register.graduationDay
    ) {
      return (
        <Grid item className={css.info}>
          <Typography variant="caption">
            {appStrings.register.graduationDayDefaultInfo}
          </Typography>
        </Grid>
      );
    } else if (
      registerInputs[section].sectionTitle === appStrings.register.password &&
      field.label === appStrings.register.acceptTerms
    ) {
      return (
        <Grid item className={css.terms} id="register-termsAndAgreementLabel">
          <Typography variant="caption">
            Read our{' '}
            <Link
              id="register-termsAndAgreementLink"
              onClick={() => {
                setIsTermsAndConditionsVisible(true);
              }}
            >
              Terms and Agreement
            </Link>
            .
          </Typography>
        </Grid>
      );
    }
  };

  return (
    <Paper className={css.paper}>
      {isTermsAndConditionsVisible && (
        <TermsAndConditions
          handleAgree={handleSetIsAgreed}
          handleGoBack={() => {
            setIsTermsAndConditionsVisible(false);
          }}
        />
      )}

      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        data-test-id="register-section"
      >
        <CustomStepper
          activeStep={activeStep}
          steps={steps}
          setActiveStep={setActiveStep}
        ></CustomStepper>
        {registerSections.map((section, index) => (
          <form
            key={index}
            data-test-id={section}
            name="registration"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            autoComplete="off"
            className={index !== activeStep ? css.hidden : css.activeForm}
          >
            <Typography variant="h2" className={css.sectionTitle}>
              {steps[index].label}
            </Typography>
            <CustomBox className={css.guestBox} key={`registration-${section}`}>
              <Grid
                container
                spacing={2}
                data-test-id="small-container"
                justifyContent={
                  registerInputs[section].sectionTitle ===
                  appStrings.register.password
                    ? 'center'
                    : 'flex-start'
                }
              >
                {registerInputs[section].fields.map(field => (
                  <Fragment key={`${section}-${field.name}-container`}>
                    <Grid
                      className={getInputClass(field)}
                      item
                      {...field.gridLayout}
                      data-test-id={`${section}-${field.name}`}
                    >
                      <FormInput
                        field={field}
                        control={control}
                        setValue={setValue}
                        watch={watch}
                        getValues={getValues}
                        errors={errors}
                      />
                      {customFieldDetails(section, field, errors)}
                      {mandatoryFieldInfo(section, field)}
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            </CustomBox>
            {submitSection(index)}
          </form>
        ))}
        <LeaveDialog {...dialogData} />
      </Grid>
    </Paper>
  );
}

export default RegisterGuest;
