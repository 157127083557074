import CONSTANTS from '../constants';
import { cloneDeep } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

export const defaultFilters = {
  orderNumber: null,
  productNumber: null,
  description: null,
  page: {
    customPageSize: 10,
    pageNumber: 0,
  },
};

const findItemsCondition = (elem, item) =>
  item.orderNumber === elem.orderNumber &&
  item.productNumber === elem.productNumber &&
  item.warehouse === elem.warehouse;

const updateDetails = (state, details) => {
  const newDetails = cloneDeep(details);
  newDetails.forEach(element => {
    element.selected = Boolean(
      state.hasSelectedAllItems
        ? !state.exceptionListSelectAll.find(item =>
            findItemsCondition(element, item)
          )
        : state.selectedItems.find(item => findItemsCondition(element, item))
    );
  });
  state.details = newDetails;
};

const updateSelectedItems = (state, details) => {
  details.forEach(elem => {
    const isInSelectedList = state.selectedItems.find(item =>
      findItemsCondition(elem, item)
    );
    const isInExceptionList = state.exceptionListSelectAll.find(item =>
      findItemsCondition(elem, item)
    );

    if (!isInSelectedList && !isInExceptionList) {
      state.selectedItems.push(elem);
    }
  });
};

const updateSelectedCount = state => {
  state.selectedAll = state.hasSelectedAllItems
    ? state.exceptionListSelectAll.length === 0
    : state.rowsCount === state.selectedItems.length;

  const getSelectAllCount = () =>
    state.selectedAll
      ? state.rowsCount
      : state.rowsCount - state.exceptionListSelectAll.length;

  const selectedCount = state.hasSelectedAllItems
    ? getSelectAllCount()
    : state.selectedItems.length;

  state.selectedCount = selectedCount;
  if (state.selectedAll && !state.hasSelectedAllItems) {
    state.hasSelectedAllItems = true;
  }
  if (state.hasSelectedAllItems && selectedCount === 0) {
    state.hasSelectedAllItems = false;
    state.exceptionListSelectAll = [];
    state.selectedItems = [];
  }
};

const resetStoreDataHandler = (state, orderNumber = null) => {
  state.details = null;
  state.filters = cloneDeep(defaultFilters);
  state.rowsCount = 0;
  state.selectedItems = [];
  state.selectedAll = false;
  state.lastRestoreList = [];
  state.linesTotal = 0;

  state.hasSelectedAllItems = false;
  state.exceptionListSelectAll = [];
  state.selectedCount = 0;

  if (orderNumber !== null) {
    state.filters.orderNumber = orderNumber;
  }
};

const restorePurchasesSlice = createSlice({
  name: CONSTANTS.restorePurchases,
  initialState: {
    details: null,
    rowsCount: 0,
    filters: { ...defaultFilters },
    selectedItems: [],
    selectedAll: false,
    lastRestoreList: [],
    linesTotal: 0,
    hasSelectedAllItems: false,
    exceptionListSelectAll: [],
    selectedCount: 0,
  },
  reducers: {
    saveRestorePurchasesDetails: (state, { payload }) => {
      if (state.hasSelectedAllItems === true) {
        updateSelectedItems(state, payload.items);
      }
      updateDetails(state, payload.items);
      state.rowsCount = payload.count;
      state.linesTotal = payload.linesTotal;
    },

    updateFiltersRestore: (state, { payload }) => {
      if (payload.reset) {
        resetStoreDataHandler(state, payload.orderNumber);
        return;
      }

      state.filters = payload;
    },

    updateSelectedItemsRestore: (state, { payload }) => {
      if (payload.all) {
        updateSelectedItems(state, state.details);

        state.selectedAll = true;
        state.hasSelectedAllItems = true;
        state.exceptionListSelectAll = [];
        updateDetails(state, state.details);
        updateSelectedCount(state);

        return;
      }

      if (payload.reset) {
        state.selectedItems = [];
        state.selectedAll = false;
        state.hasSelectedAllItems = false;
        state.exceptionListSelectAll = [];
        updateDetails(state, state.details);
        updateSelectedCount(state);

        return;
      }

      if (state.hasSelectedAllItems === true) {
        // in case of select all and toggle only some items
        if (
          state.selectedAll === true ||
          payload.rowDetails.selected === true
        ) {
          state.exceptionListSelectAll.push(payload.rowDetails);
        } else if (payload.rowDetails.selected !== true) {
          const index = state.exceptionListSelectAll.findIndex(item =>
            findItemsCondition(payload.rowDetails, item)
          );
          state.exceptionListSelectAll.splice(index, 1);
        }
      } else {
        const location = state.selectedItems.findIndex(item =>
          findItemsCondition(payload.rowDetails, item)
        );
        if (location === -1) {
          state.selectedItems.push(payload.rowDetails);
        } else {
          state.selectedItems.splice(location, 1);
        }
      }

      updateDetails(state, state.details);

      updateSelectedCount(state);
    },

    completeRestoreSelected: (state, { payload }) => {
      state.lastRestoreList = cloneDeep(
        payload?.restoredItems ?? state.selectedItems
      );
      state.selectedItems = [];
      state.selectedAll = false;
      state.filters.page.pageNumber = 0;

      state.hasSelectedAllItems = false;
      state.exceptionListSelectAll = [];
      state.selectedCount = 0;
    },

    resetLastRestore: state => {
      state.lastRestoreList = [];
      state.filters.page.pageNumber = 0;
    },

    resetRestoreData: state => resetStoreDataHandler(state),
  },
});

export const {
  completeRestoreSelected,
  resetLastRestore,
  resetRestoreData,
  saveRestorePurchasesDetails,
  updateFiltersRestore,
  updateSelectedItemsRestore,
} = restorePurchasesSlice.actions;
export default restorePurchasesSlice.reducer;
