import {
  resetCartInfo,
  resetReloadCart,
} from '../../../core/redux/slices/cartSlice';
import { useContext, useEffect } from 'react';

import CartContent from './components/CartContent';
import CartContext from './context/CartContext';
import CartSkeleton from './CartSkeleton';
import { Grid } from '@mui/material';
import useCartStyles from './useCartStyles';
import { useDispatch } from 'react-redux';

function Cart() {
  const dispatch = useDispatch();
  const css = useCartStyles().classes;
  const cartCtx = useContext(CartContext);
  const { isSavedItems, isOrder, showLoading, cartResetDone } = cartCtx;

  useEffect(() => {
    dispatch(resetCartInfo());
    cartResetDone.current = true;
  }, [isSavedItems, isOrder, dispatch, cartResetDone]);

  useEffect(() => {
    // on unmount
    return () => {
      dispatch(resetReloadCart());
      dispatch(resetCartInfo());
    };
  }, [dispatch]);

  return (
    <Grid
      container
      direction="column"
      spacing={3}
      className={css.noBottomPaddingPanel}
    >
      {showLoading ? <CartSkeleton /> : <CartContent />}
    </Grid>
  );
}

export default Cart;
