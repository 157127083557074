/*
 * Copyright 2021 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { BrPageContext } from '@bloomreach/react-sdk';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { sliderSettings } from '../utils';
import { bannerCarouselXStyles } from './useBannerCarouselXStyles';
import classNames from 'classnames';
import { useMediaQuery, useTheme } from '@mui/material';

export default function BannerCarouselX(props) {
  const { component, page } = props;

  const contentRef = component.model.content;
  const documentObject = contentRef && page.getContent(contentRef);
  const { banners } = documentObject.data;
  const css = bannerCarouselXStyles().classes;
  const theme = useTheme();
  const isXlDevice = useMediaQuery(theme.breakpoints.only('xl'));

  if (banners && banners.length && banners.length > 0) {
    return (
      <div style={{ height: 'fit-content' }}>
        <div className={css.wrapper}>
          <div className={classNames(css.sliderContainer, 'slider-container')}>
            <Slider {...sliderSettings}>
              {banners.map(banner => (
                <Slide banner={banner} isXlDevice={isXlDevice} />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>No Banners in banner collection</div>
  );
}

/* eslint react/prop-types: 0 */
const Slide = ({ banner, isXlDevice }) => {
  const page = React.useContext(BrPageContext);
  const {
    content,
    cta,
    ctaStyle,
    externalLink,
    image,
    link,
    title,
  } = banner;

  const bannerLink = link ? page.getContent(link).getUrl() : externalLink;
  const bannerLinkType = link ? 'internal' : 'external';
  const css = bannerCarouselXStyles().classes;

  return (
    <div className="slide-content" key={banner.id}>
      <div className="slide-content__img">
        {image && (isXlDevice ?
          <div className="picture-holder" style={{ backgroundImage: `url(${page.getContent(image).getOriginal().getUrl()})` }}/> :
          <img className="carousel-img" src={page.getContent(image).getOriginal().getUrl()} alt="" />
        )}
      </div>
      <div id="carouselCaption" className={classNames(css.customCarouselCaption, 'custom-carousel-caption')}>
        { title && <h3>{title}</h3> }
        { content && <p className="text-base" dangerouslySetInnerHTML={{ __html: content.value }} /> }
        { cta && bannerLink && <SlideLink cta={cta} ctaStyle={ctaStyle} bannerLink={bannerLink} bannerLinkType={bannerLinkType} /> }
      </div>
    </div>
  );
};

/* eslint react/prop-types: 0 */
const SlideLink = ({ ...props }) => {
  const {
    cta, ctaStyle, bannerLink, bannerLinkType,
  } = props;
  const css = bannerCarouselXStyles().classes;

  let linkStyle = 'red-on-white';
  if (ctaStyle && ctaStyle.selectionValues[0]) {
    linkStyle = ctaStyle.selectionValues[0].key;
  }

  if (bannerLinkType === 'internal') {
    return <Link to={bannerLink} className={`${css[linkStyle]}`}>{cta}</Link>;
  }
  if (bannerLinkType === 'external') {
    return <a href={bannerLink} className={`${css[linkStyle]}`}>{cta}</a>;
  }

  return null;
};
