import CONSTANTS from '../constants';
import { NotificationTypeConstants } from '../../../modules/shared/Notification/notificationConstants';
import appStrings from '../../strings/appStrings';
import { createSlice } from '@reduxjs/toolkit';
import { notificationRole } from '../../strings/appConstants';

const shouldPushNotification = (
  state,
  message = appStrings.errors.somethingWentWrong,
  role = notificationRole.industrial
) => {
  return role === notificationRole.student
    ? state.student.findIndex(
        notification => notification.notice === message
      ) === -1
    : state.industrial.findIndex(
        notification => notification.notice === message
      ) === -1;
};

const notificationsSlice = createSlice({
  name: CONSTANTS.notifications,
  initialState: { industrial: [], student: [] },
  reducers: {
    displayError: (state, action) => {
      if (
        shouldPushNotification(
          state,
          action.payload?.message,
          action.payload?.role
        )
      ) {
        state[
          action.payload?.role === notificationRole.student
            ? 'student'
            : 'industrial'
        ].push({
          notice:
            action.payload?.message &&
            typeof action.payload.message === 'string'
              ? action.payload.message
              : appStrings.errors.somethingWentWrong,
          notificationType: NotificationTypeConstants.error,
          permanent: action.payload?.permanent ?? false,
          id: `error${new Date().getTime()}`,
        });
      }
    },
    displaySuccess: (state, action) => {
      state[
        action.payload?.role === notificationRole.student
          ? 'student'
          : 'industrial'
      ].push({
        notice: action.payload?.message,
        notificationType: NotificationTypeConstants.success,
        permanent: action.payload?.permanent ?? false,
        id: `success${new Date().getTime()}`,
      });
    },
    displayWarning: (state, action) => {
      state[
        action.payload?.role === notificationRole.student
          ? 'student'
          : 'industrial'
      ].push({
        notice: action.payload?.message,
        notificationType: NotificationTypeConstants.warning,
        permanent: action.payload?.permanent ?? false,
        id: `warning${new Date().getTime()}`,
      });
    },
    displayInfo: (state, action) => {
      state[
        action.payload?.role === notificationRole.student
          ? 'student'
          : 'industrial'
      ].push({
        notice: action.payload?.message,
        notificationType: NotificationTypeConstants.info,
        permanent: action.payload?.permanent ?? false,
        id: action.payload?.id ?? `info${new Date().getTime()}`,
      });
    },
    hideNotification: (state, action) => {
      const getState =
        action.payload?.role === notificationRole.student
          ? state.student
          : state.industrial;
      const notificationIndex = getState.findIndex(
        notification => notification.id === action.payload.id
      );
      if (notificationIndex !== -1) {
        getState.splice(notificationIndex, 1);
      }
    },
    clearNotifications: state => {
      state.student = [];
      state.industrial = [];
    },
  },
});

export const {
  displayError,
  displayInfo,
  displaySuccess,
  displayWarning,
  hideNotification,
  clearNotifications,
} = notificationsSlice.actions;
export default notificationsSlice.reducer;
