import { makeStyles } from 'tss-react/mui';

const setAlertColors = (lightColor, darkColor, borderColor, iconColor) => {
  return {
    border: `1px solid ${borderColor}`,
    backgroundColor: lightColor,
    '& .MuiAlert-icon': {
      backgroundColor: darkColor,
      color: iconColor,
    },
  };
};

const setAlertColorsForStudent = (backgroundColor, iconColor) => {
  return {
    backgroundColor: backgroundColor,
    '& .MuiAlert-icon': {
      color: iconColor,
    },
    borderLeft: `6px solid ${iconColor} !important`,
  };
};

export const useStylesForSnackbar = makeStyles()(theme => ({
  rootIndustrial: {
    width: 'auto',
    bottom: 0,
    '& .MuiAlert-root': {
      alignItems: 'center',
      boxShadow: 'none',
      color: theme.palette.common.darkGrey,
      padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(
        1
      )} ${theme.spacing(2)}`,
    },
    '& .MuiAlert-filledError': setAlertColors(
      theme.palette.common.neutralRed,
      theme.palette.common.red,
      theme.palette.common.fadeRed1,
      theme.palette.common.white
    ),
    '& .MuiAlert-filledSuccess': setAlertColors(
      theme.palette.common.neutralGreen,
      theme.palette.common.green,
      theme.palette.common.fadeGreen1,
      theme.palette.common.white
    ),
    '& .MuiAlert-filledWarning': setAlertColors(
      theme.palette.common.neutralYellow,
      theme.palette.common.darkYellow,
      theme.palette.common.fadeDarkYellow,
      theme.palette.common.white
    ),
    '& .MuiAlert-filledInfo': setAlertColors(
      theme.palette.common.neutralBlue,
      theme.palette.common.darkBlue,
      theme.palette.common.fadeDarkBlue,
      theme.palette.common.white
    ),
  },
  rootStudent: {
    left: 0,
    right: 0,
    width: 'auto',
    '& .MuiAlert-root': {
      alignItems: 'center',
      boxShadow:
        '0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)',
      color: theme.palette.common.darkGrey,
      padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(
        1
      )} ${theme.spacing(2)}`,
    },
    '& .MuiAlert-filledError': setAlertColorsForStudent(
      theme.palette.background.paper,
      theme.palette.common.red
    ),
    '& .MuiAlert-filledSuccess': setAlertColorsForStudent(
      theme.palette.background.paper,
      theme.palette.common.green
    ),
    '& .MuiAlert-filledWarning': setAlertColorsForStudent(
      theme.palette.background.paper,
      theme.palette.common.darkYellow
    ),
    '& .MuiAlert-filledInfo': setAlertColorsForStudent(
      theme.palette.background.paper,
      theme.palette.common.darkBlue
    ),
  },
}));

export const useStylesForAlert = makeStyles()(theme => ({
  root: {
    '& .MuiAlert-icon': {
      width: '40px',
      height: '40px',
      flex: 'none',
      borderRadius: '20px',
      marginRight: theme.spacing(2),
      padding: 0,
      alignItems: 'center',
      justifyContent: 'center',
      '& svg': {
        width: '100%',
        height: '100%',
      },
      '& ::before': {
        fontSize: '30px',
        display: 'block',
      },
    },
    '& .MuiAlert-message': {
      fontSize: theme.typography.h3.fontSize,
      whiteSpace: 'pre-line',
    },
    '& .MuiAlert-action': {
      padding: theme.spacing(0, 0, 0, 2),
      '& svg': {
        width: '24px',
        height: '24px',
      },
    },
  },

  studentAlert: {
    width: '100%',
    '& .MuiAlert-icon': {
      width: '24px',
      height: '24px',
    },
    '& .MuiAlert-message': {
      fontSize: theme.typography.body1.fontSize,
    },
  },
}));
