import CONSTANTS from '../constants';
import { connectionStatus } from '../../strings/appConstants';
import { createSlice } from '@reduxjs/toolkit';
import logError from '../../errorLogging/errorLogging';

const signalRSlice = createSlice({
  name: CONSTANTS.signalR,
  initialState: {
    connection: null,
    hubConnected: false,
    currentConnectionUserId: null,
    connectionStatus: connectionStatus.stopped,
  },
  reducers: {
    /**
     * Saves SignalR status
     * @param {object} state
     * @param {object} payload = {
     *    connection {object}
     *    hubConnected {boolean}
     *    userId {string}
     *    connectionStatus {number} - statuses available in connectionStatus object
     *    errorMessage {string}
     * }
     */
    saveConnection: (state, { payload }) => {
      state.connection = payload.connection;
      state.hubConnected = payload.hubConnected;
      state.currentConnectionUserId = payload.userId;
      state.connectionStatus = payload.connectionStatus;

      if (payload.connectionStatus === connectionStatus.error) {
        logError({
          error: payload.errorMessage,
          componentName: 'SignalR Service',
        });
      }
    },
    connectionClosed: state => {
      state.connection = null;
      state.hubConnected = false;
      state.currentConnectionUserId = null;
      state.connectionStatus = connectionStatus.stopped;
    },
  },
});

export const { saveConnection, connectionClosed } = signalRSlice.actions;
export default signalRSlice.reducer;
