import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { buttonTypes, dateFormat } from '../../../core/strings/appConstants';
import { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import CustomAlert from '../../shared/CustomAlert/CustomAlert';
import CustomButton from '../../shared/CustomButton/CustomButton';
import ItemAvailabilitySkeleton from './ItemAvailabilitySkeleton';
import appStrings from '../../../core/strings/appStrings';
import { getItemAvailability } from './itemAvailabilityService';
import moment from 'moment';
import useItemAvailabilityStyles from './useItemAvailabilityStyles';

function ItemAvailability({ productNumber, quantity, bp, setShowDialog }) {
  const css = useItemAvailabilityStyles().classes;
  const [isLoading, setIsLoading] = useState(false);
  const [warehouses, setWarehouses] = useState([]);

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    setIsLoading(true);
    setWarehouses([]);
    getItemAvailability(bp, productNumber, quantity)
      .then(response => {
        setWarehouses(response);
      })
      .catch(() => {
        return;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [productNumber, bp, quantity]);

  const noAvailability = warehouses.length ? null : (
    <Typography variant="h3" className={css.noAvailability}>
      {appStrings.cart.productAvailabilityError}
    </Typography>
  );

  const notification = warehouses.length
    ? warehouses.find(warehouse => warehouse.itemNotifications?.length > 0)
        .itemNotifications[0]
    : null;

  const showNotification = notification ? (
    <div className={css.alertContainer}>
      <CustomAlert
        messageType={notification.messageType}
        messages={notification.messages}
      />
    </div>
  ) : null;

  return (
    <Dialog open onClose={handleCloseDialog} className={css.dialogContainer}>
      <DialogTitle classes={{ root: css.title }}>
        <IconButton
          className={css.closeButton}
          onClick={handleCloseDialog}
          size="large"
        >
          <CloseIcon />
        </IconButton>
        {appStrings.cart.availabilityFor(productNumber)}
      </DialogTitle>
      <DialogContent classes={{ root: css.contentContainer }}>
        {isLoading ? (
          <ItemAvailabilitySkeleton />
        ) : (
          <>
            {noAvailability}
            {showNotification}
            {warehouses.map((item, index) => {
              return (
                <Card
                  key={`warehouseContainer-${index}`}
                  id={`warehouseContainer-${index}`}
                  className={css.card}
                >
                  <CardContent className={css.cardContent}>
                    <Typography
                      id={`warehouseName-${index}`}
                      className={css.warehouseTitle}
                    >{`${item.warehouse.code}, ${item.warehouse.name}`}</Typography>
                    <Divider />
                    <div className={css.dataContainer}>
                      <span>
                        <Typography className={css.firstRow}>
                          {appStrings.cart.itemsAvailable}:
                        </Typography>
                        <Typography>
                          {' '}
                          {appStrings.cart.deliveryDate}:{' '}
                        </Typography>
                      </span>
                      {item.atpInventories.map((inventory, index1) => (
                        <span key={`inventoryValues-${index}-${index1}`}>
                          <Typography
                            id={`availableQuantity-${index}-${index1}`}
                            className={css.firstRow}
                            align="right"
                          >
                            {inventory.availableQuantity}
                          </Typography>
                          <Typography
                            id={`deliveryDate-${index}-${index1}`}
                            variant="body1"
                            align="right"
                          >
                            {moment(inventory.receiptDate).format(dateFormat)}
                          </Typography>
                        </span>
                      ))}
                    </div>
                  </CardContent>
                </Card>
              );
            })}
            <div className={css.buttonContainer}>
              <CustomButton
                btnType={buttonTypes.secondary}
                onClick={handleCloseDialog}
                label={appStrings.common.close}
              />
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
export default ItemAvailability;
