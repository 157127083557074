import Cookies from 'universal-cookie';

export default class CookieService {
  static getCookie(key) {
    const cookies = new Cookies();
    return cookies.get(key);
  }

  static setCookie(key, value, options) {
    const cookies = new Cookies();
    cookies.set(key, value, options);
  }

  static removeCookie(key, options) {
    const cookies = new Cookies();
    cookies.remove(key, options);
  }
}
