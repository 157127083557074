import { makeStyles } from 'tss-react/mui';

const useTermsAndConditionsStyles = makeStyles()(theme => ({
  container: {
    '& .MuiPaper-root': {
      borderTop: '8px solid red',
      height: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      margin: 0,
      padding: 0,
      borderRadius: 0,
    },
  },
  content: {
    [theme.breakpoints.up('lg')]: {
      backgroundColor: theme.palette.common.neutral2,
    },
    paddingBottom: `${theme.spacing(3)}`,
  },
  body: {
    whiteSpace: 'pre-line',
    paddingBottom: `${theme.spacing(2)}`,
  },
  header: {
    padding: `${theme.spacing(2.5)}`,
    borderBottom: `1px solid ${theme.palette.common.shadow}`,
  },
  title: {
    padding: `${theme.spacing(1)} 0 `,
  },
  paper: {
    [theme.breakpoints.up('lg')]: {
      padding: `${theme.spacing(2)}`,
      margin: `${theme.spacing(2)}`,
      border: `1px solid ${theme.palette.common.shadow}`,
      borderRadius: `${theme.spacing(1)}`,
      backgroundColor: theme.palette.common.white,
    },
  },
  action: {
    width: '100%',
  },
  secondaryButton: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logo: {
    pointerEvents: 'none',
  },
  goBack: {
    padding: '5px 10px !important',
  }
}));

export default useTermsAndConditionsStyles;
