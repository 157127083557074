import { makeStyles } from 'tss-react/mui';

const useECContractStyles = makeStyles()(theme => ({
  optionButton: {
    width: '100%',
  },

  container: {
    marginTop: theme.spacing(2),
  },
  inputLabel: {
    paddingTop: theme.spacing(1),
  },
  dataLabel: {
    paddingTop: theme.spacing(2),
  },
  noDataAvailable: {
    background: theme.palette.common.neutral4,
    paddingTop: '100px',
    paddingBottom: '120px',
  },
}));

export default useECContractStyles;
