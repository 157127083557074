import { createExpandedFormFields, saveIfValid } from '../productsListService';
import { qtyInput, studentPriceInput } from '../productsListConstants';

import { cloneDeep } from 'lodash';
import { displayError } from '../../../../core/redux/slices/notificationsSlice';
import store from '../../../../core/redux/store';

export const validateFields = async ({
  fieldNames,
  fieldRefs,
  prodListCtx,
}) => {
  await prodListCtx.trigger(fieldNames);
  await prodListCtx.trigger();
  const errorKeys = Object.keys(prodListCtx.errors);
  // if there are errors but not for the Quantity or Student Price when the field was not changed, then display them
  if (
    errorKeys.length &&
    fieldRefs[errorKeys[0]] &&
    !(
      prodListCtx.errors[fieldNames[0]] &&
      !prodListCtx.dirtyFields[fieldNames[0]]
    ) &&
    !(
      fieldNames[1] &&
      prodListCtx.errors[fieldNames[1]] &&
      !prodListCtx.dirtyFields[fieldNames[1]]
    )
  ) {
    store.dispatch(
      displayError({
        message: prodListCtx.errors[errorKeys[0]].message,
      })
    );
    setTimeout(() => {
      fieldRefs[errorKeys[0]].current.focus();
    });
    return false;
  }
  return true;
};

export const allFieldsValid = async (
  editableStudentPriceRef,
  editableQuantityRef,
  prodListCtx
) => {
  const fieldNames = [
    `editableQuantity${prodListCtx.expandedProduct.recordId}`,
  ];
  const fieldRefs = {
    [`editableQuantity${prodListCtx.expandedProduct.recordId}`]:
      editableQuantityRef,
  };
  if (prodListCtx.isOrder) {
    fieldNames.push(
      `editableStudentPrice${prodListCtx.expandedProduct.recordId}`
    );
    fieldRefs[`editableStudentPrice${prodListCtx.expandedProduct.recordId}`] =
      editableStudentPriceRef;
  }
  return validateFields({
    fieldNames,
    fieldRefs,
    prodListCtx,
  });
};

export const expandItem = ({
  index,
  expandedIndex,
  triggerSave,

  createDetailsView,
  createQtyFormInput,
  createStudentPriceFormInput,
  prodListCtx,
}) => {
  const isValid = saveIfValid(prodListCtx.isDirty, expandedIndex, triggerSave);

  if (isValid) {
    // Remove the qty input, set the field value and remove expanded content
    let newProducts = cloneDeep(prodListCtx.displayedProducts);
    if (prodListCtx.expanded !== null) {
      // if student is active and product exists in LN
      if (
        prodListCtx.cartCtx?.student?.active &&
        prodListCtx.displayedProducts[index].lineTotal !== '-'
      ) {
        newProducts[prodListCtx.expanded].quantity = prodListCtx.getValues(
          qtyInput.name + prodListCtx.expandedProduct.recordId
        );
        if (prodListCtx.isOrder) {
          newProducts[prodListCtx.expanded].studentPrice =
            prodListCtx.getValues(
              studentPriceInput.name + prodListCtx.expandedProduct.recordId
            );
        }
      }
      newProducts[prodListCtx.expanded].expandedContent = null;
    }

    // Set qty as input instead of simple value
    if (expandedIndex !== null) {
      newProducts = createExpandedFormFields({
        productsToEdit: newProducts,
        index,
        studentDetails: prodListCtx.cartCtx?.student,
        createDetailsView,
        createQtyFormInput,
        createStudentPriceFormInput,
        isOrder: prodListCtx.isOrder,
        bp: prodListCtx.bp,
        setValue: prodListCtx.setValue,
      });
    }

    // save expanded product and index
    prodListCtx.setExpanded(expandedIndex);
    prodListCtx.setExpandedProduct(
      expandedIndex !== null ? prodListCtx.displayedProducts[index] : null
    );
    prodListCtx.setDisplayedProducts(newProducts);
  }
};

export const checkExpand = async ({
  // other functions:
  triggerSave,
  createDetailsView,
  createQtyFormInput,
  createStudentPriceFormInput,

  // must be sent
  index,
  expandedIndex,
  editableStudentPriceRef,
  editableQuantityRef,

  prodListCtx,
}) => {
  let productIsValid = true;
  if (
    expandedIndex === null ||
    (prodListCtx.expanded !== null &&
      expandedIndex !== null &&
      prodListCtx.expanded !== expandedIndex)
  ) {
    productIsValid = await allFieldsValid(
      editableStudentPriceRef,
      editableQuantityRef,
      prodListCtx
    );
  }

  if (productIsValid) {
    expandItem({
      index,
      expandedIndex,
      triggerSave,

      createDetailsView,
      createQtyFormInput,
      createStudentPriceFormInput,
      prodListCtx,
    });
  }
};
