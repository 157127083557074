import { makeStyles } from 'tss-react/mui';

const useSchoolInfoStyles = makeStyles()(theme => ({
  schoolInfoContainer: {
    '&.MuiBox-root': {
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(4),
    },
  },

  mandatoryMessageContainer: {
    marginTop: theme.spacing(2),
  }
}));

export default useSchoolInfoStyles;
