import { Grid, Skeleton, useMediaQuery } from '@mui/material';
import React from 'react';
import CustomBox from '../../../shared/CustomBox/CustomBox';
import { Stack } from '@mui/system';
import { useOrderDetailsStyles } from '../orderDetailsStyles';

function OrderItemsSkeleton() {
  const css = useOrderDetailsStyles().classes;
  const isSmallDevice = useMediaQuery('(max-width:768px)');

  return (
    <Grid item xs={12} sm={isSmallDevice ? 12 : 7} md={7}>
      <CustomBox>
        <Grid item className={css.title} padding={2}>
          <Skeleton variant="rounded" height="30px" width="30%" />
        </Grid>
        <Grid item xs={12}>
          {Array(4)
            .fill(true)
            .map((_, index) => (
              <Grid
                item
                container
                alignItems="center"
                key={index}
                className={css.content}
              >
                <Grid item xs>
                  <Stack spacing={1}>
                    <Skeleton variant="rounded" width="80%" />
                    <Skeleton variant="rounded" width="40%" />
                    <Skeleton variant="rounded" width="50%" />
                  </Stack>
                </Grid>
                <Skeleton variant="rounded" width="20%" />
              </Grid>
            ))}
        </Grid>
      </CustomBox>
    </Grid>
  );
}

export default OrderItemsSkeleton;
