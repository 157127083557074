import React from 'react';
import { Grid, Skeleton, Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useLocation } from 'react-router-dom';

function OrderHeaderSkeleton() {
  const theme = useTheme();
  const { pathname } = useLocation();
  const isXsDevice = useMediaQuery(theme.breakpoints.only('xs'));
  const isOrderDetails = pathname.indexOf('/orderDetails') !== -1;

  const smallSkeleton = width => (
    <Skeleton variant="rounded" height="24px" width={width} />
  );
  const mediumSkeleton = width => (
    <Skeleton variant="rounded" height="40px" width={width} />
  );

  return (
    <Grid
      container
      spacing={2}
      id="studentOrdersSkeleton"
      data-test-id="orderHeaderSkeleton"
    >
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item xs>
            <Stack spacing={1}>
              {smallSkeleton('90%')}
              {!isOrderDetails && smallSkeleton('60%')}
            </Stack>
          </Grid>
          {!isXsDevice && !isOrderDetails && (
            <Grid item xs>
              {mediumSkeleton('90px')}
            </Grid>
          )}
          <Grid
            item
            container
            spacing={1}
            xs
            direction="column"
            alignItems="flex-end"
          >
            <Grid item>{smallSkeleton('80px')}</Grid>
            {!isOrderDetails && <Grid item>{smallSkeleton('70px')}</Grid>}
          </Grid>
          {isXsDevice && !isOrderDetails && (
            <Grid item xs={12}>
              {mediumSkeleton('90px')}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default OrderHeaderSkeleton;
