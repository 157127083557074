import {
  currentPathRestore,
  listOfPathsRestore,
} from './restorePurchasesConstants';
import {
  saveRestorePurchasesDetails,
  updateFiltersRestore,
} from '../../../core/redux/slices/restorePurchasesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';

import CompleteRestoreSelectedItems from './components/CompleteRestoreSelectedItems';
import CustomBox from '../../shared/CustomBox/CustomBox';
import CustomBreadcrumbs from '../../shared/CustomBreadcrumbs/CustomBreadcrumbs';
import { Grid } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import NameCard from '../NameCard/NameCard';
import NoDataAvailable from '../../shared/NoDataAvailable/NoDataAvailable';
import RestoreFilters from './components/RestoreFilters';
import RestorePurchasesItems from './components/RestorePurchasesItems';
import RestoreTotals from './components/RestoreTotals';
import UndoLastRestore from './components/UndoLastRestore';
import appStrings from '../../../core/strings/appStrings';
import { displayError } from '../../../core/redux/slices/notificationsSlice';
import { getListOfItemsAvailable } from './restorePurchasesService';
import { icons } from '../../../core/strings/icons';
import { isEqual } from 'lodash';
import useRestorePurchaseStyles from './useRestorePurchaseStyles';
import { getPurchasePower } from '../PurchasePower/purchasePowerService';
import { purchaseTypes } from '../PurchasePower/purchasePowerConstants';
import { useParams } from 'react-router';

function RestorePurchases() {
  const { id, bp } = useParams();
  const dispatch = useDispatch();
  const css = useRestorePurchaseStyles().classes;
  const { filters, rowsCount } = useSelector(state => state.restorePurchases);
  const [callInProgress, setCallInProgress] = useState(false);
  const [hasFiltersOptions, setHasFiltersOptions] = useState(false);
  const student = useSelector(
    state => state.schoolsWithStudents.selectedStudent.student
  );
  const [triggerReloadData, setTriggerReloadData] = useState(false);

  const lastFilters = useRef({});
  const handlePageChange = page => {
    if (page !== filters.page.pageNumber) {
      dispatch(
        updateFiltersRestore({
          ...filters,
          page: {
            ...filters.page,
            pageNumber: page,
          },
        })
      );
    }
  };

  const updatePurchasePower = () => {
    getPurchasePower(id, purchaseTypes.restore);
  };

  useEffect(() => {
    if ((id && !isEqual(lastFilters.current, filters)) || triggerReloadData) {
      setCallInProgress(true);
      lastFilters.current = filters;
      getListOfItemsAvailable({ ...filters, studentId: id })
        .then(resp => {
          dispatch(saveRestorePurchasesDetails(resp));
        })
        .catch(e => dispatch(displayError({ message: e })))
        .finally(() => {
          setCallInProgress(false);
          if (triggerReloadData) {
            setTriggerReloadData(false);
          }
        });
    }
  }, [id, dispatch, filters, triggerReloadData]);

  useEffect(() => {
    setHasFiltersOptions(() => {
      let nextValue = false;

      Object.keys(filters).forEach(key => {
        if (typeof filters[key] === 'string' && filters[key].length) {
          nextValue = true;
        }
      });

      return nextValue;
    });
  }, [filters]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <NameCard student={student} showName={false}>
          <CustomBreadcrumbs
            listOfPaths={listOfPathsRestore(bp, id, student)}
            currentPath={currentPathRestore}
            isLoading={!student?.firstName}
          />
        </NameCard>
      </Grid>

      {rowsCount || hasFiltersOptions ? (
        <>
          <Grid item xs={12}>
            <RestoreTotals />
          </Grid>

          <Grid item xs={12}>
            <RestoreFilters />
          </Grid>
        </>
      ) : null}

      {!rowsCount && !callInProgress && (
        <Grid item xs={12}>
          <CustomBox customClass={css.customBoxNoDataAvailable}>
            <NoDataAvailable
              message={
                hasFiltersOptions
                  ? appStrings.restorePurchase.noItemsFoundMsg
                  : appStrings.restorePurchase.noItemsToRestoreMsg
              }
              iconElement={!hasFiltersOptions ? <InfoOutlinedIcon /> : null}
              icon={hasFiltersOptions ? icons.noResults : null}
            />
          </CustomBox>
        </Grid>
      )}

      <Grid item xs={12}>
        <RestorePurchasesItems
          onPageChange={handlePageChange}
          callInProgress={callInProgress}
        />
      </Grid>

      {!callInProgress && student?.active ? (
        <>
          <Grid item xs={12}>
            <CompleteRestoreSelectedItems
              setTriggerReloadData={setTriggerReloadData}
              setCallInProgress={setCallInProgress}
              updatePurchasePower={updatePurchasePower}
            />
          </Grid>

          <Grid item xs={12}>
            <UndoLastRestore
              setTriggerReloadData={setTriggerReloadData}
              setCallInProgress={setCallInProgress}
              updatePurchasePower={updatePurchasePower}
            />
          </Grid>
        </>
      ) : null}
    </Grid>
  );
}
export default RestorePurchases;
