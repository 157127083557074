import { CHECKOUT, ROOT, LOGIN } from '../../../core/navigation/paths';
import { buttonColors, buttonTypes } from '../../../core/strings/appConstants';
import appStrings from '../../../core/strings/appStrings';

export const cartTotalsPrices = {
  sectionOne: [
    { label: appStrings.cart.tax, name: 'tax', valueClass: 'tax-value' },
    { label: appStrings.cart.shippingAndHandling, name: 'shippingAndHandling' },
  ],
  sectionTwo: [
    { label: appStrings.cart.sepSavings, name: 'sepSavings' },
    { label: appStrings.cart.orderTotal, name: 'grandTotal' },
  ],
};

export const totalsButtonsConstants = isLoggedIn => [
  {
    id: 'ShoppingCart-refreshButton',
    label: appStrings.common.refresh,
    btnType: buttonTypes.secondary,
    testId: 'refreshButtonTestId',
    color: buttonColors.black,
    disableButton: () => false,
    displayButton: ({ isLoggedIn, isCheckOut }) =>
      isLoggedIn && !isCheckOut,
  },
  {
    id: 'ShoppingCart-continueShoppingButton',
    label: appStrings.cart.continueShopping,
    btnType: buttonTypes.secondary,
    to: ROOT,
    color: buttonColors.black,
    testId: 'continueShoppingButtonTestId',
    disableButton: () => false,
    displayButton: ({ isCheckOut }) => !isCheckOut,
  },
  {
    id: 'ShoppingCart-checkoutButton',
    label: appStrings.cart.checkoutNow,
    btnType: buttonTypes.primary,
    to: isLoggedIn ? CHECKOUT : LOGIN,
    color: buttonColors.red,
    testId: 'checkoutButtonTestId',
    disableButton: ({ cartLength }) => !cartLength,
    displayButton: ({ isCheckOut }) => !isCheckOut,
  },
  {
    id: 'ShoppingCart-placeOrder',
    label: appStrings.order.placeOrder,
    btnType: buttonTypes.primary,
    color: buttonColors.red,
    testId: 'placeOrderButtonTestId',
    disableButton: ({ cartLength, disablePlaceOrder }) =>
      !cartLength || disablePlaceOrder,
    displayButton: ({ isLoggedIn, isCheckOut }) => isLoggedIn && isCheckOut,
  },
];
