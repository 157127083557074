import urls from '../../../core/strings/urls';
import ApiService from '../../shared/Api/apiService';
import { getBrUID } from '../../shared/BrSearchBar/brSearchBarService';

export const getSearchedProduct = (productNumber, itemsCount, pageNumber, facets) => {
  return ApiService.post(urls.searchProducts, {
    productNumber,
    itemsCount,
    pageNumber,
    facets,
    brUID2: getBrUID()
  });
}

export const getBrItemRecommendationProducts = (itemIDs) => {
  return ApiService.externalGet(urls.bloomreachItemRecommendationProductsUrl(getBrUID(), itemIDs));
}

export const getBrGlobalRecommendationProducts = (itemIds) => {
  return ApiService.externalGet(urls.bloomreachGlobalRecommendationProductsUrl(getBrUID(), itemIds));
}

export const parseBrRecommendations = (response) => {
  const { response: recResponse } = response;
  const { docs } = recResponse;
  return docs;
}
