import React, { Fragment } from 'react';
import CustomDialog from '../../../shared/CustomDialog/CustomDialog';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { buttonTypes } from '../../../../core/strings/appConstants';
import appStrings from '../../../../core/strings/appStrings';
import { Grid } from '@mui/material';
import { dialogFields } from './filteredStudentsListConstants';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import useFilteredStudentsListStyles from './useFilteredStudentsListStyles';

function FilteredStudentsListDialog({
  dialogData,
  setDialogData,
  checkedStudentsStatuses,
}) {
  const css = useFilteredStudentsListStyles().classes;

  const dialogMessage = (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={css.dialog}
    >
      {dialogFields(checkedStudentsStatuses).map((field, index) => (
        <Fragment key={index}>
          <SquareRoundedIcon key={index} id={field.iconId} />
          <p>{field.text}</p>
          {field.divider}
        </Fragment>
      ))}
    </Grid>
  );

  return (
    <CustomDialog
      open={dialogData.open}
      icon={<InfoOutlinedIcon />}
      title={dialogData?.title}
      message={dialogMessage}
      buttons={[
        {
          btnType: buttonTypes.secondary,
          label: appStrings.common.cancel,
          onClick: () => setDialogData(prev => ({ ...prev, open: false })),
        },
        {
          label: dialogData.continueButtonLabel,
          onClick: dialogData.action,
        },
      ]}
    />
  );
}

export default FilteredStudentsListDialog;
