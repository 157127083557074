import { Slider, Typography } from '@mui/material';

import { Controller } from 'react-hook-form';
import propTypes from '../propTypes';
import useSliderFieldStyles from './useSliderFieldStyles';

function SliderField({ field, control }) {
  const css = useSliderFieldStyles().classes;
  return (
    <>
      <Typography
        id={`slider-${field.name}`}
        gutterBottom
        style={{ textTransform: 'capitalize' }}
      >
        {field.label}
      </Typography>
      <Controller
        defaultValue={field.defaultValue ?? 0}
        control={control}
        name={field.name}
        rules={{ ...field.validations }}
        render={({ field: { onChange, name, value } }) => (
          <Slider
            aria-labelledby={`slider-${field.name}`}
            step={field.step || 1}
            marks
            min={field.validations.min}
            max={field.validations.max}
            name={name}
            valueLabelDisplay="auto"
            classes={{ colorPrimary: css.colorPrimary }}
            onChange={(e, v) => {
              onChange(v);
              field.onChangeCallback && field.onChangeCallback(v, field.name);
            }}
            value={value}
            data-test-id={`sliderInput-${field.name}`}
          />
        )}
      />
    </>
  );
}

SliderField.propTypes = propTypes;

export default SliderField;
