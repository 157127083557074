import { useNavigate } from 'react-router';
import { buttonColors, buttonTypes } from '../../../core/strings/appConstants';
import appStrings from '../../../core/strings/appStrings';
import urls from '../../../core/strings/urls';
import { purchaseTypes } from '../../industrial/PurchasePower/purchasePowerConstants';
import ApiService from '../../shared/Api/apiService';
import CustomButton from '../../shared/CustomButton/CustomButton';
import { mySectionsPaths } from './accountConstants';

export const getStudentPurchasePower = studentId =>
  ApiService.get(urls.getPurchasePower(studentId, purchaseTypes.cart));

export const updateStudentPicture = (file, userId) =>
  ApiService.post(
    urls.updateStudentPicture,
    { file: file, studentId: userId },
    false,
    !file ? false : true
  );

export const getStudentPicture = studentId =>
  ApiService.get(`${urls.getStudentPicture}/${studentId}`, true);

export const deleteStudentPicture = studentId =>
  ApiService.post(urls.deleteStudentProfilePicture, {
    studentId: studentId,
  });

export const getBreadcrumbs = (pathname, params) => {
  let segments = pathname.split('/');
  segments[0] = 'home';
  let prevSeg;
  Object.keys(params).forEach(key => {
    segments.pop(segments.indexOf(key));
  });
  segments = segments.map((segment, index) => {
    const capitalized = segment.charAt(0).toUpperCase() + segment.slice(1);
    const formatSegment = capitalized.replace(/([A-Z])/g, ' $1').trim();
    const shouldAddMyWord = mySectionsPaths.indexOf(segment) !== -1;
    const segmentLink = index > 1 ? `${prevSeg}/${segment}` : `/${segment}`;
    prevSeg = segmentLink;

    return {
      link: segmentLink,
      label: shouldAddMyWord ? `My ${formatSegment}` : formatSegment,
      id: `breadCrumb-${segment}`,
      elementId: `breadCrumb-${segment}`,
    };
  });

  return {
    segments: segments.slice(0, -1),
    currentPath: segments[segments.length - 1],
  };
};

export const GoBackBtn = () => {
  const navigate = useNavigate();

  return (
    <CustomButton
      id="account-backBtn"
      data-test-id="account-backBtnTestId"
      btnType={buttonTypes.secondary}
      color={buttonColors.black}
      label={appStrings.common.goBack}
      onClick={() => navigate(-1)}
    />
  );
};
