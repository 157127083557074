export const fullIcons = {
  calendar: 'icon-calendar',
  cart: 'icon-cart',
  clear: 'icon-clear',
  edit: 'icon-edit',
  eraser: 'icon-eraser',
  expandMore: 'icon-expandMore',
  logo: 'icon-logo',
  order: 'icon-order',
  orders: 'icon-orders',
  search: 'icon-search',
  shop: 'icon-shop',
  student: 'icon-student',
  user: 'icon-user',
};

export const icons = {
  add: 'add',
  admin: 'admin',
  cart: 'cart',
  checked: 'checked',
  clear: 'clear',
  consigned: 'consigned',
  download: 'download',
  edit: 'edit',
  editProfile: 'editProfile',
  email: 'email',
  eraser: 'eraser',
  error1: 'error1',
  error: 'error',
  export: 'export',
  exportPdf: 'export-pdf',
  exportXls: 'export-xls',
  help: 'help',
  hide: 'hide',
  history: 'history',
  importExcel: 'import-excel',
  info: 'info',
  launch: 'launch',
  like: 'like',
  logout: 'logout',
  moveToCart: 'moveToCart',
  moveToOrder: 'moveToOrder',
  moveToSavedItems: 'moveToSavedItems',
  noEmployers: 'no-employers',
  noReferences: 'no-references',
  noResults: 'noResults',
  order: 'order',
  otherPayments: 'otherPayments',
  register: 'register',
  rep: 'rep',
  save: 'save',
  savedItems: 'saved_items',
  search: 'search',
  searchTips: 'searchTips',
  show: 'show',
  star: 'star',
  student1: 'student1',
  student: 'student',
  studentHat: 'studentHat',
  success: 'success',
  trash: 'trash',
  userWaiting: 'userWaiting',
  viewPicture: 'view-picture',
  warehouse1: 'warehouse1',
};
