import {
  orderNumberPattern,
  productNumberPattern,
} from '../../../core/strings/regexValidations';

import { HISTORY } from '../../../core/navigation/paths';
import appStrings from '../../../core/strings/appStrings';

export const categories = { none: 'None' };

export const listOfPathsRestore = (bp, id, student) => [
  {
    link: HISTORY(bp, id),
    label: `${student?.firstName} ${student?.lastName} ${appStrings.menu.history}`,
  },
];
export const currentPathRestore = {
  label: appStrings.history.restorePurchasePower,
};

export const lineItemsTableColumns = [
  {
    label: appStrings.orderDetailsHistory.orderNumber,
    name: 'orderNumber',
  },
  {
    label: appStrings.cart.partNumber,
    name: 'productNumber',
  },
  {
    label: appStrings.cart.description,
    name: 'description',
  },
  {
    label: appStrings.cart.quantity,
    name: 'quantity',
  },
  {
    label: appStrings.cart.listPrice,
    name: 'listPrice',
    isCurrency: true,
  },
  {
    label: appStrings.cart.lineTotal,
    name: 'lineTotal',
    isCurrency: true,
  },
  {
    label: appStrings.order.warehouse,
    name: 'warehouse',
  },
];

export const restoreFiltersOptions = filters => [
  {
    label: appStrings.cart.partNumber,
    value: 'productNumber',
    disabled: Boolean(filters.productNumber?.length),
    fieldValidation: {
      maxLength: 30,
      pattern: productNumberPattern,
    },
    defaultValue: '',
  },
  {
    label: appStrings.cart.description,
    value: 'description',
    disabled: Boolean(filters.description?.length),
    defaultValue: '',
    fieldValidation: {
      maxLength: 30,
      minLength: 1,
    },
  },
  {
    label: appStrings.orderDetailsHistory.orderNumber,
    value: 'orderNumber',
    disabled: Boolean(filters.orderNumber?.length),
    defaultValue: 'SEP-',
    fieldValidation: {
      maxLength: 13,
      pattern: orderNumberPattern,
    },
  },
];
