import PropTypes from 'prop-types';

export const paymentErrors = {
  200: 'Name required',
  300: 'Amount entered is invalid',
  310: 'Credit card required',
  315: 'Invalid credit card number',
  320: 'Invalid credit card type',
  330: 'Invalid credit card month',
  340: 'Invalid credit card expiration year',
  350: 'Invalid CVC',
  355: 'CVC required',
  357: 'Invalid CVC',
  360: 'An error has occurred. Please contact Support',
  370: 'Invalid credit card expiration date',
  500: 'Address required',
  510: 'City required',
  520: 'State required',
  530: 'Zip code required',
  531: 'Invalid Zip code',
  550: 'Country required',
};

export const paymentCodes = {
  invalidExpirationDate: '370',
  failedPayment: '360',
};

export const propTypes = {
  onCancel: PropTypes.func.isRequired,
  onPaymentComplete: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export const orderAndPaymentStatus = {
  completed: 1,
  rejected: 3,
  failed: 9,
};
