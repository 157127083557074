import { Grid, Typography } from '@mui/material';
import {
  displayError,
  displaySuccess,
} from '../../../../core/redux/slices/notificationsSlice';
import { useDispatch, useSelector } from 'react-redux';

import CustomBox from '../../../shared/CustomBox/CustomBox';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import appStrings from '../../../../core/strings/appStrings';
import { buttonTypes } from '../../../../core/strings/appConstants';
import { resetLastRestore } from '../../../../core/redux/slices/restorePurchasesSlice';
import { restorePurchasesSelectedItems } from '../restorePurchasesService';
import useRestorePurchaseStyles from '../useRestorePurchaseStyles';

function UndoLastRestore({
  setTriggerReloadData,
  setCallInProgress,
  updatePurchasePower,
}) {
  const css = useRestorePurchaseStyles().classes;
  const dispatch = useDispatch();
  const { lastRestoreList } = useSelector(state => state.restorePurchases);
  const student = useSelector(
    state => state.schoolsWithStudents.selectedStudent.student
  );

  const getSectionTitle = () => {
    let sectionTitle = appStrings.restorePurchase.undoRestoreTitle;

    lastRestoreList.forEach((element, index) => {
      sectionTitle += `${element.productNumber}${
        index !== lastRestoreList.length - 1 ? ', ' : ''
      }`;
    });

    sectionTitle += '?';

    return sectionTitle;
  };

  const handleUndoRestore = () => {
    setCallInProgress(true);

    const payload = {
      studentId: student.id,
      undoChanges: true,
      returnItems: lastRestoreList,
    };

    restorePurchasesSelectedItems(payload)
      .then(resp => {
        dispatch(resetLastRestore());
        updatePurchasePower();
        setTriggerReloadData(true);
        dispatch(
          displaySuccess({
            message: appStrings.restorePurchase.undoRestoreSuccessMsg,
          })
        );
      })
      .catch(e => {
        dispatch(
          displayError({
            message: e,
          })
        );
        setCallInProgress(false);
      });
  };

  return (
    <Grid
      container
      data-test-id="undoRestorePurchasesContainer"
      className={!lastRestoreList.length ? css.hide : null}
    >
      <Grid item xs={12}>
        <CustomBox customClass={css.greyContainer}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography className={css.bold}>
                    {getSectionTitle()}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {appStrings.restorePurchase.undoRestoreMessage}
                    <CustomButton
                      label={appStrings.restorePurchase.undoRestoreBtnLabel}
                      onClick={handleUndoRestore}
                      btnType={buttonTypes.tertiary}
                      customClass={css.undoBtn}
                      data-test-id="undoRestoredItems"
                      id="undoRestoredItems"
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CustomBox>
      </Grid>
    </Grid>
  );
}

export default UndoLastRestore;
