import appStrings from '../../../core/strings/appStrings';
import { passwordFormat } from '../../../core/strings/regexValidations';

export const gridLayout = {
  xs: 12,
};

export const resetPassInputs = (getValues, disableSubmit, setDisableSubmit) => {
  const onChangeHandler = () => {
    const resetValues = getValues();

    if (
      (!resetValues.passwordConfirmation.length ||
        !resetValues.password.length ||
        resetValues.passwordConfirmation !== resetValues.password) &&
      !disableSubmit
    ) {
      return setDisableSubmit(true);
    }

    if (
      resetValues.passwordConfirmation.length &&
      resetValues.password.length &&
      disableSubmit &&
      resetValues.passwordConfirmation === resetValues.password
    ) {
      setDisableSubmit(false);
    }
  };

  return [
    {
      name: 'password',
      label: appStrings.forgotPassword.newPassword,
      type: 'password',
      validations: {
        required: true,
        pattern: passwordFormat,
      },
      gridLayout,
      helperText: appStrings.validationMessages.passwordFormat,
      showVisibilityBtn: true,
      onChangeCallback: onChangeHandler,
    },
    {
      name: 'passwordConfirmation',
      label: appStrings.forgotPassword.confirmNewPassword,
      type: 'password',
      validations: {
        required: true,
        validate: {
          invalidPasswordConfirmation: value => value === getValues('password'),
        },
      },
      gridLayout,
      helperText: appStrings.validationMessages.passwordMatch,
      showVisibilityBtn: true,
      onChangeCallback: onChangeHandler,
    },
  ];
};
