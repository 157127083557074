import { makeStyles } from 'tss-react/mui';

export const useStudentPurchaseLimitStyles = makeStyles()(theme => ({
  labelStyle: {
    fontSize: '12px',
    color: theme.palette.common.grey2,
  },

  progressContainer: {
    padding: theme.spacing(3, 0, 2, 0),
    alignItems: 'center',
  },

  progressLine: {
    height: theme.spacing(1),
    borderRadius: theme.spacing(1),
    border: 'none',
  },

  purchasedLine: {
    backgroundColor: theme.palette.common.neutralRed,
  },

  currentLine: {
    backgroundColor: theme.palette.common.red,
    height: '12px',
  },

  remainingLine: {
    backgroundColor: theme.palette.common.grey5,
  },

  alertStyle: {
    marginBottom: theme.spacing(2),

    '& .MuiPaper-root': {
      border: '1px solid',
      borderColor: theme.palette.common.red,
      color: theme.palette.text.primary,
    },

    '& .MuiSvgIcon-root': {
      color: theme.palette.common.red,
    },
  },
}));
