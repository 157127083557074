import {
  getSchoolAddress,
  getSchoolsByStateAndRep,
} from '../../Register/registerService';

import appStrings from '../../../../core/strings/appStrings';
import { approvalStatusOptions } from '../../../../core/strings/options';
import { dateFormat } from '../../../../core/strings/appConstants';
import { handleError } from './schoolInformationService';
import { currentInstructorNamePattern } from '../../../../core/strings/regexValidations';

const gridLayout = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 4,
};

export const studentGridLayout = {
  xs: 12,
  sm: 6,
  md: 6,
  lg: 4,
};

const onSchoolStateChange = async ({
  getValues,
  previousSchoolState,
  setPreviousSchoolState,
  setValue,
  schoolBpOptions,
  setSchoolBpOptions,
  employeeId,
}) => {
  const newSchoolState = getValues('schoolState');

  if (
    (newSchoolState && newSchoolState !== previousSchoolState) ||
    (newSchoolState && !schoolBpOptions?.length)
  ) {
    setValue('schoolBp', '');
    getSchoolsByStateAndRep(newSchoolState, employeeId, true)
      .then(res => {
        if (res) {
          const data = res.map(item => {
            return getSchoolAddress(item);
          });
          setSchoolBpOptions(data ?? []);
          setPreviousSchoolState(newSchoolState);
        }
      })
      .catch(error => handleError({ error }));
  } else {
    setValue('schoolBp', '');
    setSchoolBpOptions([]);
  }
};

const onIsEmployedChange = ({ getValues, setValue, setDisableUsesTools }) => {
  const isEmployedField = getValues('isEmployed');
  setValue('usesTools', false, { shouldDirty: true });
  setDisableUsesTools(isEmployedField ? false : true);
};

export const inputs = data => {
  const { schoolBpOptions, disableUsesTools, isStudent } = data;
  const grid = isStudent ? studentGridLayout : gridLayout;

  return [
    {
      name: 'schoolState',
      label: appStrings.register.schoolState,
      type: 'autocomplete',
      options: data.stateOptions,
      validations: {
        required: true,
      },
      onChangeCallback: () => onSchoolStateChange(data),
      gridLayout: grid,
    },
    {
      name: 'schoolBp',
      label: appStrings.register.schoolName,
      type: 'autocomplete',
      options: schoolBpOptions,
      validations: {
        required: true,
      },
      key: '',
      gridLayout: grid,
    },
    {
      name: 'graduationDate',
      label: appStrings.register.graduationDate,
      type: 'date',
      format: dateFormat,
      minDate: new Date(),
      maxDate: xYearsFromNow(10),
      maxDateValidationMessage: appStrings.validationMessages.graduationDate,
      minDateValidationMessage:
        appStrings.validationMessages.graduationDateFuture,
      validations: {
        required: true,
      },
      gridLayout: grid,
      disabled: isStudent,
    },
    {
      name: 'currentInstructorName',
      label: appStrings.register.currentInstructorName,
      type: 'text',
      validations: {
        maxLength: 50,
        pattern: currentInstructorNamePattern,
        required: true,
      },
      gridLayout: grid,
      helperText: appStrings.validationMessages.nameString,
    },
    {
      name: 'schoolStudentId',
      label: appStrings.register.studentId,
      type: 'text',
      validations: { maxLength: 20 },
      gridLayout: grid,
      disabled: isStudent,
    },
    {
      name: 'approvalStatus',
      label: appStrings.studentProfile.studentApproval,
      type: 'select',
      options: approvalStatusOptions,
      validations: {},
      gridLayout: grid,
    },
    {
      name: 'isActive',
      label: appStrings.studentProfile.activeStudent,
      type: 'switch',
      validations: {},
      gridLayout: grid,
    },
    {
      name: 'isEmployed',
      label: appStrings.register.isEmployed,
      type: 'switch',
      validations: {},
      onChangeCallback: () => onIsEmployedChange(data),
      gridLayout: grid,
    },
    {
      name: 'usesTools',
      label: appStrings.register.usesTools,
      type: 'switch',
      validations: {},
      disabled: disableUsesTools,
      gridLayout: grid,
    },
    {
      name: 'receivePromotionalMessages',
      label: appStrings.register.promotionalMessages,
      type: 'switch',
      validations: {},
      gridLayout: grid,
    },
    {
      name: 'receivePromotionalEmails',
      label: appStrings.register.promotionalEmails,
      type: 'switch',
      validations: {},
      gridLayout: grid,
    },
  ];
};

const xYearsFromNow = x => {
  const yearsFromNow = new Date();
  return yearsFromNow.setFullYear(yearsFromNow.getFullYear() + x);
};
