import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { formatPrice } from '../ShoppingCartService';
import useShoppingCartStyles from '../shoppingCartStyles';
import appStrings from '../../../../core/strings/appStrings';

const ItemPrice = ({ item, isCartSummary, index, isSetItem, showPerItem = false }) => {
  const isSmallDevice = useMediaQuery('(max-width:768px)');
  const css = useShoppingCartStyles(isSmallDevice, isCartSummary).classes;

  const itemListPrice = formatPrice(item.listPrice);

  return (
    <Grid item container sm={isSmallDevice ? 12 : 4}>
      <Grid
        item
        container
        direction={isSetItem ? 'row' : isSmallDevice ? 'row-reverse' : 'column'}
        alignItems={isSmallDevice || isSetItem ? 'center' : 'flex-end'}
        justifyContent={isSmallDevice || isSetItem ? 'flex-start' : 'flex-end'}
        flexWrap={isSetItem ? 'nowrap' : 'unset'}
      >
        {item.studentPrice ? (
          <>
            <div id={`itemListPrice_${index}`}>
              <Typography
                paddingLeft={isSmallDevice && 1}
                className={css.textOverline}
                marginRight={isSetItem && 1}
              >
                {isSmallDevice ? `(${itemListPrice})` : `${itemListPrice}`}
              </Typography>
            </div>
            <div id={`itemStudentPrice_${index}`}>
              <Typography
                className={css.studentPriceStyle}
                fontWeight={isCartSummary ? 'bold' : 'regular'}
              >
                {`${formatPrice(item.studentPrice)}`}
              </Typography>
            </div>
          </>
        ) : (
          <div id={`itemListPrice_${index}`}>
            <Typography fontWeight="bold">{itemListPrice}</Typography>
          </div>
        )}
      </Grid>
      <Grid item container justifyContent="flex-end">
        {showPerItem &&
          <div id={`perItemLabel_${index}`} className={css.unitPrice}>
            <Typography>{appStrings.cart.unitPrice}</Typography>
          </div>
        }
      </Grid>
    </Grid>
  );
};

ItemPrice.propTypes = {
  item: PropTypes.object,
  isCartSummary: PropTypes.bool,
  index: PropTypes.number,
  isSetItem: PropTypes.bool,
  showPerItem: PropTypes.bool,
};

export default ItemPrice;
