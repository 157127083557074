import { Box } from '@mui/material';
import classNames from 'classnames';
import useCustomBoxStyles from './useCustomBoxStyles';

function CustomBox({ fadeBorder, customClass, children, ...props }) {
  const css = useCustomBoxStyles().classes;
  return (
    <Box
      className={classNames(
        css.customBox,
        fadeBorder ? css.fadeBorder : '',
        customClass ?? ''
      )}
      {...props}
    >
      {children}
    </Box>
  );
}

export default CustomBox;
