import {
  digitPattern,
  nameStringPattern,
} from '../../../../../core/strings/regexValidations';
import {
  xYearsFromNow,
  xYearsInPast,
} from '../../../../../core/services/utilsService';

import appStrings from '../../../../../core/strings/appStrings';
import {
  dateFormat,
  phoneMask,
} from '../../../../../core/strings/appConstants';
import { statesOptionsSortedByLabel } from '../../../../../core/strings/states';

export const gridLayout = {
  xs: 12,
  sm: 6,
  md: 6,
  lg: 6,
};

const onDriverLicenseEmpty = ({
  getValues,
  setValue,
  setDisableDriverLicenseState,
}) => {
  const isDriverLicenseNrEmpty = Boolean(
    getValues('driverLicenseNumber').trim()
  );
  if (isDriverLicenseNrEmpty) {
    setDisableDriverLicenseState(false);
  } else {
    setValue('driverLicenseState', '', { shouldDirty: true });
    setDisableDriverLicenseState(true);
  }
};

const onIsEmployedChange = ({ getValues, setValue, setDisableUsesTools }) => {
  const isEmployedField = getValues('isEmployed');
  setValue('usesTools', false, { shouldDirty: true });
  setDisableUsesTools(isEmployedField ? false : true);
};
const phoneInputs = (index, setValue, getValues) => [
  {
    name: `phone${index}.phoneNumber`,
    label: appStrings.studentProfile.phoneNumber,
    type: 'tel',
    validations: {
      maxLength: 10,
      minLength: 10,
      pattern: digitPattern,
    },
    gridLayout,
    helperText: appStrings.validationMessages.xDigitsOnly(10),
    maskFormat: phoneMask,
  },
];

export const switchFields = ({
  setValue,
  getValues,
  disableUsesTools,
  setDisableUsesTools,
}) => [
  {
    name: 'isEmployed',
    label: appStrings.register.isEmployed,
    type: 'switch',
    validations: {},
    onChangeCallback: () =>
      onIsEmployedChange({ getValues, setValue, setDisableUsesTools }),
    gridLayout,
  },
  {
    name: 'usesTools',
    label: appStrings.register.usesTools,
    type: 'switch',
    validations: {},
    disabled: disableUsesTools,
    gridLayout,
  },
];

export const getCustomerInfoInputs = data => {
  const {
    getValues,
    setValue,
    disableDriverLicenseState,
    setDisableDriverLicenseState,
    disableUsesTools,
    setDisableUsesTools,
  } = data;

  return [
    {
      name: 'firstName',
      label: appStrings.common.firstName,
      type: 'text',
      validations: {
        maxLength: 25,
        pattern: nameStringPattern,
        required: true,
      },
      gridLayout,
      helperText: appStrings.validationMessages.nameString,
    },

    {
      name: 'lastName',
      label: appStrings.common.lastName,
      type: 'text',
      validations: {
        maxLength: 25,
        pattern: nameStringPattern,
        required: true,
      },
      gridLayout,
      helperText: appStrings.validationMessages.nameString,
    },
    {
      name: 'birthdate',
      label: appStrings.studentProfile.birthDate,
      type: 'date',
      format: dateFormat,
      maxDateValidationMessage: appStrings.validationMessages.maxBirthdate,
      minDateValidationMessage: appStrings.validationMessages.minBirthdate,
      validations: {},
      disableFuture: true,
      gridLayout,
      initialFocusedDate: xYearsInPast(20),
    },
    {
      name: 'socialSecurityNumber',
      label: appStrings.studentProfile.socialSecurityNumber,
      type: 'ssn',
      validations: {
        maxLength: 9,
        pattern: digitPattern,
      },
      gridLayout,
      showVisibilityBtn: true,
    },
    {
      name: 'driverLicenseNumber',
      label: appStrings.studentProfile.driverLicenseNumber,
      type: 'text',
      validations: {
        maxLength: 50,
      },
      onChangeCallback: () =>
        onDriverLicenseEmpty({
          getValues,
          setValue,
          setDisableDriverLicenseState,
        }),
      gridLayout,
    },
    {
      name: 'driverLicenseState',
      label: appStrings.studentProfile.driverLicenseState,
      type: 'autocomplete',
      validations: {},
      options: statesOptionsSortedByLabel,
      gridLayout,
      disabled: disableDriverLicenseState,
    },
    {
      name: 'graduationDate',
      label: appStrings.register.graduationDate,
      type: 'date',
      format: dateFormat,
      minDate: new Date(),
      maxDate: xYearsFromNow(10),
      maxDateValidationMessage: appStrings.validationMessages.graduationDate,
      minDateValidationMessage:
        appStrings.validationMessages.graduationDateFuture,
      validations: {
        required: true,
      },
      gridLayout,
    },
    ...phoneInputs(1, setValue, getValues),
    ...switchFields({
      setValue,
      getValues,
      disableUsesTools,
      setDisableUsesTools,
    }),
  ];
};
