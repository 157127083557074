import { Grid, Typography } from '@mui/material';

import CustomBox from '../../../shared/CustomBox/CustomBox';
import appStrings from '../../../../core/strings/appStrings';
import { formatNumberWithCurrency } from '../../../../core/services/utilsService';
import useOrderHistoryDetailsStyles from '../useOrderHistoryDetailsStyles';

function OrderTotals({ orderDetails }) {
  const css = useOrderHistoryDetailsStyles().classes;

  return !orderDetails ? null : (
    <Grid
      item
      xs={12}
      data-test-id="orderHistoryDetails-orderTotals"
      id="historyOrderTotalsContainer"
    >
      <CustomBox customClass={css.customBox}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              className={css.sectionTitle}
              id="historyOrderTotals-label"
            >{`${appStrings.order.orderTotals}:`}</Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            spacing={2}
            id="historyOrderTotals-values"
          >
            <Grid item id="historyOrderTotals-subtotal">
              <Typography>
                {`${appStrings.cart.subtotal}: ${formatNumberWithCurrency(
                  orderDetails?.subtotal
                )}`}
              </Typography>
            </Grid>

            <Grid item id="historyOrderTotals-tax">
              <Typography>
                {`${appStrings.cart.tax}: ${formatNumberWithCurrency(
                  orderDetails?.tax
                )}`}
              </Typography>
            </Grid>

            <Grid item id="historyOrderTotals-grandTotal">
              <Typography>
                {`${appStrings.cart.grandTotal}: ${formatNumberWithCurrency(
                  orderDetails?.total
                )}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CustomBox>
    </Grid>
  );
}

export default OrderTotals;
