import { Divider, Grid, Stack } from '@mui/material';
import React, { useState } from 'react';
import InputsTemplates from '../../../../shared/StudentProfileForm/Addresses/InputsTemplate';
import { generatePresentInputs } from '../../../../shared/StudentProfileForm/EmployerInformation/employerInfoInputs';
import {
  onEmploymentLengthChange,
  onZipCodeChangeHandler,
} from '../../../../shared/StudentProfileForm/EmployerInformation/employerInformationService';
import appStrings from '../../../../../core/strings/appStrings';
import PropTypes from 'prop-types';
import References from '../../../../shared/StudentProfileForm/References/References';
import { useParams } from 'react-router-dom';

function ContractFields({
  getValues,
  setValue,
  control,
  watch,
  errors,
  isDirty,
  reset,
}) {
  const { id } = useParams();
  const [disabledStates, setDisabledStates] = useState({});

  const changeActions = {
    onZipCodeChange: ({ section, index }) =>
      onZipCodeChangeHandler({
        section,
        index,
        getValues,
        setValue,
        setDisabledStates,
      }),
    onEmploymentLengthChange: ({ section, index }) =>
      onEmploymentLengthChange({ section, index, getValues, setValue }),
  };

  const formProps = {
    control,
    setValue,
    watch,
    errors,
    getValues,
    isDirty,
    reset,
    id,
    isStudent: true,
    contractFields: true,
  };

  return (
    <Grid container>
      <Stack spacing={2}>
        <InputsTemplates
          formProps={formProps}
          inputs={generatePresentInputs({
            changeActions,
            getValues,
            setValue,
            disabledStates,
            otherGridLayout: { xs: 12, sm: 6 },
          })}
          title={appStrings.studentProfile.presentEmployer}
          titleInsideTheBox
          id="presentEmployerContract"
        />
        <Divider />
        <References {...formProps} />
      </Stack>
    </Grid>
  );
}

ContractFields.propTypes = {
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  control: PropTypes.object,
  watch: PropTypes.func,
  errors: PropTypes.object,
  isDirty: PropTypes.bool,
  reset: PropTypes.func,
};

export default ContractFields;
