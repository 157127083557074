import ApiService from '../Api/apiService';
import urls from '../../../core/strings/urls';
import {v4 as uuidv4} from 'uuid';
import React from 'react';
import CookieService from '../Cookie/cookieService';

export const bolder = (el, searchValue) => {
  if (el) {
    let items = el.getElementsByTagName('li');
    for (let i = 0; i < items.length; ++i) {
      let regex = new RegExp(`(?<name>${searchValue})`, 'gi');
      let text = items[i].getElementsByTagName('span')[0];
      text.innerHTML = text.innerHTML
        .replace(/<strong style="color:black">/gm, '')
        .replace(/<\/strong>/g, '');
      text.innerHTML = text.innerHTML.replace(
        regex,
        '<strong style=color:black>$&</strong>'
      );
    }
  }
};

export const getBloomreachSuggestions = (sanitizedSearchTerm, brUID2, requestId) => {
  return ApiService.externalGet(urls.bloomreachSearchSuggestionProducts(sanitizedSearchTerm, brUID2, requestId));
}

const destructureBloomreachSuggestionResults = result => {
  if (result) {
    const { attributeSuggestions, querySuggestions, searchSuggestions } = result;
    return {
      categories: attributeSuggestions,
      products: searchSuggestions,
      suggestions: querySuggestions,
    };
  }
  return {
    categories: [],
    products: [],
    suggestions: [],
  };
};

export const sanitizeSearchTerm = searchString => {
  const beginIndex = searchString.indexOf(' - ');
  return beginIndex > -1 ? searchString.substr(0, beginIndex) : searchString;
};

export const getHighlightedText = (text, searchValue) => {
  const parts = text.split(new RegExp(`(${searchValue})`, 'gi'));
  return (
    <p>
      { parts.map(part => (
        <span key={uuidv4()} style={part.toLowerCase() === searchValue.toLowerCase() ? { fontWeight: 'bold', color: 'black' } : {}}>
            { part }
          </span>
      ))}
    </p>
  );
};

export const parseBloomreachResult = (brResult, setBloomreachResults) => {
  let parsedResult;
  if (Array.isArray(brResult) && brResult.length === 0) {
    parsedResult = {
      suggestionGroups: [],
    };
  } else {
    parsedResult = { ...brResult };
  }
  if (parsedResult) {
    const { suggestionGroups } = parsedResult;
    if (suggestionGroups && suggestionGroups.length) {
      setBloomreachResults(destructureBloomreachSuggestionResults(suggestionGroups[0]));
    }
  }
};

export const getBrUID = () => {
  let brUID;
  try {
    brUID = encodeURIComponent(CookieService.getCookie('_br_uid_2')).trim();
  } catch (e) {
    brUID = null;
  }
  return brUID;
};
