import { makeStyles } from 'tss-react/mui';

const useCustomAccordionContainerStyles = makeStyles()(theme => ({
  accordionContainer: {
    '& .MuiAccordion-root:first-of-type': {
      '& .MuiAccordionSummary-root': {
        borderRadius: '4px 4px 0px 0px',
      },
    },
    '& .MuiAccordion-root:last-of-type': {
      '& .MuiAccordionSummary-root': {
        borderRadius: '0px 0px 4px 4px',
        '&.Mui-expanded': {
          borderRadius: '0px 0px 0px 0px',
        },
      },
    },
    '& >.MuiDivider-root': {
      margin: '1px',
      backgroundColor: theme.palette.common.fadeGrey,
    },
  },
}));

export default useCustomAccordionContainerStyles;
