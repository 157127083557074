import ConsignedInventoryDetailsSkeleton from './ConsignedInventoryDetails/ConsignedInventoryDetailsSkeleton';
import ConsignedSourceSkeleton from './ConsignedSource/ConsignedSourceSkeleton';
import CustomSkeleton from '../../shared/CustomSkeleton/CustomSkeleton';
import { Grid } from '@mui/material';

function ConsignedInventorySkeleton() {
  const topLayout = [
    { xs: 6, width: '17rem' },
    {
      xs: 6,
      width: '7.6rem',
      variant: 'rect',
      height: '2rem',
      container: true,
      justifyContent: 'flex-end',
    },
  ];
  return (
    <Grid data-test-id="consignedInventorySkeleton">
      <CustomSkeleton layout={topLayout} />
      <ConsignedSourceSkeleton />
      <ConsignedInventoryDetailsSkeleton />
    </Grid>
  );
}

export default ConsignedInventorySkeleton;
