import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import industrialRoutes from '../../../core/navigation/Routes/industrialRoutes';

export const initGA = () => {
  if (!window.OnetrustActiveGroups?.includes('C0002')) {
    return;
  }
  ReactGA.initialize([
    {
      trackingId: process.env.REACT_APP_ANALYTICS_TRACKING_ID,
      testMode: process.env.REACT_APP_WEB_ROOT !== 'https://sep.snapon.com',
    },
  ]);
  ReactGA.ga('set', 'anonymizeIp', true);
};

export const trackGAEvent = (action, category, label) => {
  if (!window.OnetrustActiveGroups?.includes('C0002')) {
    return;
  }

  ReactGA.event({
    category,
    action,
    label,
  });
};

export const trackGAPageViews = location => {
  if (!window.OnetrustActiveGroups?.includes('C0002')) {
    return;
  }

  ReactGA.send({
    hitType: 'pageview',
    page: location,
  });
  ReactGA.set({ page: location });
};

export const initializeTagManager = () => {
  if (!window.OnetrustActiveGroups?.includes('C0002')) {
    return;
  }

  const gtmId = process.env.REACT_APP_GTM_ID;
  TagManager.initialize({
    gtmId,
  });
};

export const trackGTMEvent = (event, props) => {
  if (!window.OnetrustActiveGroups?.includes('C0002')) {
    return;
  }

  let propsObj = {
    domain_key: process.env.REACT_APP_BLOOMREACH_DOMAIN_KEY,
    ...props,
  };

  if (process.env.REACT_APP_BLOOMREACH_TEST_DATA === 'true') {
    propsObj = { test_data: true, ...propsObj };
  }

  if (
    !industrialRoutes.find(route =>
      window.location.pathname.includes(route.path)
    )
  ) {
    window.dataLayer = window.dataLayer || [];
    if (!event) {
      window.dataLayer.push(propsObj);
    } else {
      window.dataLayer.push({
        event,
        ...propsObj,
      });
    }
  }
};

export const buildBasket = cartItems => {
  if (cartItems) {
    return {
      items: cartItems.map(item => ({
        prod_id: item.productNumber,
        sku: item.productNumber,
        name: item.description,
        quantity: item.quantity,
        price: item.studentPrice,
      })),
    };
  }
  return [];
};
