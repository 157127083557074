import {
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Fragment, useEffect, useRef, useState } from 'react';
import { LOGIN, RESEND_LINK } from '../../../core/navigation/paths';
import { buttonColors } from '../../../core/strings/appConstants';
import { gridLayout, inputs } from './forgotPasswordConstants';

import CustomButton from '../CustomButton/CustomButton';
import FormInput from '../FormInput/FormInput';
import LoginErrors from '../Login/LoginErrors/LoginErrors';
import appStrings from '../../../core/strings/appStrings';
import { displaySuccess } from '../../../core/redux/slices/notificationsSlice';
import { submitForgotPassword } from './forgotPasswordService';
import { useDispatch } from 'react-redux';
import useForgotPasswordStyles from './useForgotPasswordStyles';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

function ForgotPassword() {
  const [receivedErrors, setReceivedErrors] = useState(null);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();
  const css = useForgotPasswordStyles().classes;
  const dispatch = useDispatch();
  const location = useLocation();

  const isResendLink = location?.pathname === RESEND_LINK;

  const isSmallDevice = useMediaQuery('(max-width:768px)');
  const forgotPassClasses = classNames(css.forgotPassContainer, {
    [css.desktopContainer]: !isSmallDevice,
    [css.smallDeviceContainer]: isSmallDevice,
  });

  const emailInputRef = useRef();

  const onSubmit = data => {
    setSubmitInProgress(true);
    setReceivedErrors(null);

    submitForgotPassword(data)
      .then(() => {
        dispatch(
          displaySuccess({
            message: appStrings.forgotPassword.submittedMessage,
          })
        );
        reset({ email: '' });
      })
      .catch(error => {
        setReceivedErrors(error);
      })
      .finally(() => {
        setSubmitInProgress(false);
        emailInputRef.current.focus();
      });
  };

  useEffect(() => {
    setTimeout(() => emailInputRef.current?.focus());
  }, []);

  return (
    <Grid container justifyContent="center">
      <Grid
        item
        container
        className={forgotPassClasses}
        xs={12}
        sm={7}
        md={6}
        lg={5}
      >
        <Grid item className={css.titleSection}>
          <Typography className={css.title}>
            {isResendLink
              ? appStrings.forgotPassword.resendLink
              : appStrings.common.forgotPassword}
          </Typography>
          <Typography>
            {!isResendLink ? appStrings.forgotPassword.description : null}
          </Typography>
        </Grid>

        <Grid item {...gridLayout}>
          <Grid item {...gridLayout}>
            <LoginErrors error={receivedErrors} />
          </Grid>
          <Grid item {...gridLayout}>
            <form
              name="forgotPass"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              autoComplete="off"
              id="forgotPasswordForm"
              data-test-id="forgotPass"
            >
              {inputs.map(field => (
                <Fragment key={`${field.name}-container`}>
                  <Grid item {...field.gridLayout} data-test-id={field.name}>
                    <FormInput
                      field={field}
                      control={control}
                      errors={errors}
                      fieldRef={field.name === 'email' && emailInputRef}
                      size="large"
                    />
                  </Grid>
                </Fragment>
              ))}

              <Grid item {...gridLayout}>
                <CustomButton
                  color={buttonColors.red}
                  size="large"
                  label={
                    <>
                      {appStrings.forgotPassword.resetMyPassword}
                      {submitInProgress && (
                        <CircularProgress size={14} className={css.loading} />
                      )}
                    </>
                  }
                  data-test-id="forgotPassButton"
                  id="forgotPasswordButton"
                  customClass={css.forgotPassBtn}
                  disabled={submitInProgress}
                  type="submit"
                />
              </Grid>
            </form>
          </Grid>
        </Grid>

        <Grid
          item
          container
          {...gridLayout}
          className={css.cancelBtnContainer}
          alignItems="center"
        >
          Do you remember?
          <Link
            data-test-id="cancelButton"
            id="cancelButton"
            className={css.cancelBtn}
            to={LOGIN}
          >
            {appStrings.common.goBack}
          </Link>
          to Sign In.
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ForgotPassword;
