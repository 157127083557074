import CustomAccordionSkeleton from '../../shared/CustomSkeleton/CustomAccordionSkeleton';
import { Grid, Skeleton } from '@mui/material';
import useSchoolsListStyles from './useSchoolListStyles';

const titleSkeleton = () => {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12} sm={5} lg={7}>
        <Skeleton width={'50%'} />
      </Grid>
      <Grid item xs={12} sm={4} lg={3}>
        <Skeleton width={'50%'} />
      </Grid>
      <Grid item xs={12} sm={2} lg={1}>
        <Skeleton width={'60%'} />
      </Grid>
      <Grid item xs={12} sm={1} lg={1}>
        <Skeleton variant="circular" />
      </Grid>
    </Grid>
  );
};

function SchoolsListSkeleton() {
  const css = useSchoolsListStyles().classes;
  return (
    <CustomAccordionSkeleton
      css={css.schoolsContainer}
      numberOfSections={10}
      titleSkeleton={titleSkeleton()}
    />
  );
}

export default SchoolsListSkeleton;
