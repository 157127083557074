import CustomTableSkeleton from '../../../shared/CustomSkeleton/CustomTableSkeleton';
import appStrings from '../../../../core/strings/appStrings';

function HistoryDetailsSkeleton() {
  const columns = [
    {
      label: appStrings.history.type,
      name: 'transactionType',
    },
    {
      label: appStrings.history.date,
      name: 'transactionDate',
    },
    {
      label: appStrings.history.transactionNo,
      name: 'number',
    },
    {
      label: appStrings.history.status,
      name: 'transactionStatus',
    },
    {
      label: appStrings.history.transactionTotal,
      name: 'transactionTotal',
      align: 'center',
    },
    {
      label: appStrings.history.placedBy,
      name: 'placedBy',
      align: 'center',
    },
    { label: '', name: 'primaryAction', align: 'center' },
    { label: '', name: 'secondaryAction', align: 'center' },
  ];

  return <CustomTableSkeleton columns={columns} />;
}

export default HistoryDetailsSkeleton;
