import urls from '../../../../core/strings/urls';
import ApiService from '../../../shared/Api/apiService';

export const getAllSchools = () => {
  return ApiService.get(urls.getAllSchools);
};

export const formatSchoolsList = list => {
  return list.map(school => ({
    label: school.schoolName,
    value: school.bpNumber,
    description: school.bpNumber,
  }));
};

export const getStudentsBySchool = bpNumber => {
  return ApiService.get(urls.getApproveRejectStudentsBySchool(bpNumber));
};
