import urls from '../../../core/strings/urls';
import ApiService from '../../shared/Api/apiService';

export const getReps = () => {
  const url = `${urls.getAllReps}`;

  return ApiService.get(url).then(repList => {
    return repList;
  });
};

export const sortRepsByName = (a, b) => {
  const nameA = a.label.toUpperCase();
  const nameB = b.label.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  return 0;
};
