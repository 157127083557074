import AddIcon from '@mui/icons-material/Add';
import ApiService from '../../Api/apiService';
import CheckIcon from '@mui/icons-material/Check';
import appStrings from '../../../../core/strings/appStrings';
import { getZipCodeInfo } from '../../../../core/services/zipCodeService';
import moment from 'moment';
import { states } from '../../../../core/strings/states';
import store from '../../../../core/redux/store';
import urls from '../../../../core/strings/urls';
import { saveEmployerInformation } from '../../../industrial/StudentProfile/Services/studentProfileService';

const maxEmployerNumber = 17;
const getIndexedValue = index => (index >= 0 ? `.${index}` : '');

export const getEmployersInformation = studentId => {
  const url = `${urls.getEmployersInfo}/${studentId}`;

  return ApiService.get(url);
};

export const onEmploymentLengthChange = ({
  section,
  index,
  setValue,
  getValues,
}) => {
  const finalSection = `${section}${getIndexedValue(index)}`;
  let years, months;
  if (finalSection === 'presentEmployer' || finalSection === 'futureEmployer') {
    const date = getValues(`${finalSection}.startDate`);
    const now = moment();
    const diffInMonths = now.diff(date, 'months');
    if (diffInMonths) {
      years = Math.floor(diffInMonths / 12);
      months = diffInMonths - years * 12;
    } else {
      years = 0;
      months = 0;
    }
    if (`${finalSection}.employmentLength`) {
      setValue(`${finalSection}.employmentLength`, diffInMonths || 0);
    }
  } else {
    years = getValues(`${finalSection}.years`);
    months = getValues(`${finalSection}.months`);
  }

  setValue(
    `${finalSection}.lengthOfEmployment`,
    appStrings.studentProfile.yearsAndMonths(years, months)
  );
};

export const onZipCodeChangeHandler = ({
  section,
  index,
  getValues,
  setValue,
  setDisabledStates,
}) => {
  const finalSection = `${section}${getIndexedValue(index)}`;
  const currentZip = getValues(`${finalSection}.zipCode`);
  const currentState = getValues(`${finalSection}.state`);
  const currentCity = getValues(`${finalSection}.city`);

  if (currentState || currentCity) {
    setValue(`${finalSection}.city`, null);
    setValue(`${finalSection}.state`, null);
  }

  if (currentZip.length === 5) {
    const zipCodes = store.getState().zipCode.formattedCodeList;
    const handleZipCodeResponse = resp => {
      setValue(`${finalSection}.state`, resp?.state);
    };

    if (zipCodes[currentZip]) {
      handleZipCodeResponse(zipCodes[currentZip]);
      // Enable State field
      setDisabledStates(prevStates => ({
        ...prevStates,
        ...{ [finalSection]: false },
      }));
    } else {
      getZipCodeInfo(currentZip).then(result => {
        if (result) {
          handleZipCodeResponse(result);
          // Enable State field
          setDisabledStates(prevStates => ({
            ...prevStates,
            ...{ [finalSection]: false },
          }));
        } else {
          // Disable State field
          setDisabledStates(prevStates => ({
            ...prevStates,
            ...{ [finalSection]: true },
          }));
        }
      });
    }
  } else {
    // Disable State field
    setDisabledStates(prevStates => ({
      ...prevStates,
      ...{ [finalSection]: true },
    }));
  }
};

const formatLengthOfEmployment = employersInfo => {
  if (employersInfo.presentEmployer) {
    employersInfo.presentEmployer.lengthOfEmployment =
      appStrings.studentProfile.yearsAndMonths(
        Math.floor(employersInfo.presentEmployer.employmentLength / 12),
        employersInfo.presentEmployer.employmentLength % 12
      );
  }
  if (employersInfo.previousEmployers) {
    employersInfo.previousEmployers.forEach(employer => {
      const years = Math.floor(employer.employmentLength / 12);
      const months = employer.employmentLength % 12;

      employer.months = months;
      employer.years = years;
      employer.lengthOfEmployment = appStrings.studentProfile.yearsAndMonths(
        years,
        months
      );
    });
  }
  return employersInfo;
};

const formatPosition = employersInfo => {
  if (employersInfo.presentEmployer) {
    employersInfo.presentEmployer.positionId =
      employersInfo.presentEmployer.positionId === 0
        ? ''
        : employersInfo.presentEmployer.positionId;
  }
  if (employersInfo.futureEmployer) {
    employersInfo.futureEmployer.positionId =
      employersInfo.futureEmployer.positionId === 0
        ? ''
        : employersInfo.futureEmployer.positionId;
  }
  if (employersInfo.previousEmployers) {
    employersInfo.previousEmployers.forEach(employer => {
      employer.positionId =
        employer.positionId === 0 ? '' : employer.positionId;
    });
  }
  return employersInfo;
};

export const getCities = employersInfo => {
  if (!employersInfo.previousEmployers) {
    employersInfo.previousEmployers = [];
  }
  return Promise.all([
    getSectionCities(employersInfo, 'presentEmployer'),
    getSectionCities(employersInfo, 'futureEmployer'),
    ...employersInfo.previousEmployers.map((_, index) =>
      getSectionCities(employersInfo, 'previousEmployers', index)
    ),
  ]);
};

export const getSectionCities = (employersInfo, section, index) => {
  const finalSection =
    index >= 0 ? employersInfo[section][index] : employersInfo[section];
  if (employersInfo[section] && finalSection.zipCode) {
    return getZipCodeInfo(finalSection.zipCode);
  } else {
    return Promise.resolve(null);
  }
};

export const handleEmployersInfo = ({
  res,
  setEmployerInputs,
  setFormattedValues,
  setInitialFormValues,
}) => {
  if (res) {
    let formattedValues = formatLengthOfEmployment(res);
    formattedValues = formatStates(formattedValues);
    formattedValues = formatPosition(formattedValues);

    setEmployerInputs({
      presentEmployer: Boolean(formattedValues.presentEmployer),
      futureEmployer: Boolean(formattedValues.futureEmployer),
      previousEmployers: Array(
        formattedValues.previousEmployers?.length ?? 0
      ).fill(''),
      prevEmpActualLength: formattedValues.previousEmployers?.length,
    });

    getCities(formattedValues).then(resp => {
      setFormattedValues(formattedValues);
      if (setInitialFormValues) {
        setInitialFormValues(formattedValues);
      }
    });
  } else {
    setFormattedValues({});
    if (setInitialFormValues) {
      setInitialFormValues({});
    }
  }
};

const formatStates = val => {
  if (val.presentEmployer?.state && states[val.presentEmployer.state]) {
    val.presentEmployer.state = states[val.presentEmployer.state];
  }
  if (val.futureEmployer?.state && states[val.futureEmployer.state]) {
    val.futureEmployer.state = states[val.futureEmployer.state];
  }
  if (val.previousEmployers?.length) {
    val.previousEmployers.forEach(emp => {
      if (emp.state && states[emp.state]) {
        emp.state = states[emp.state];
      }
    });
  }
  return val;
};

export const clearEmptySections = (
  emptySections,
  setEmployerInputs,
  setValue,
  getValues
) => {
  if (emptySections?.presentEmployer) {
    setValue(
      `presentEmployer.lengthOfEmployment`,
      appStrings.studentProfile.yearsAndMonths(0, 0)
    );
    setEmployerInputs(employerInputs => {
      return { ...employerInputs, presentEmployer: false };
    });
  }

  if (emptySections?.futureEmployer) {
    setEmployerInputs(employerInputs => {
      return { ...employerInputs, futureEmployer: false };
    });
  }

  if (emptySections?.previousEmployers?.length) {
    // sort indexes in descending order to not mess up deleting
    emptySections.previousEmployers
      ?.sort((a, b) => b - a)
      .forEach(index =>
        handleDeletePreviousEmployer({
          index,
          id: null,
          addressId: null,
          setDeletedEmployers: null,
          setEmployerInputs,
          setValue,
          getValues,
        })
      );
  }
};

export const handleDeletePresentEmployer = ({
  id,
  addressId,
  setDeletedEmployers,
  setEmployerInputs,
  getValues,
  setValue,
  studentId,
  reset
}) => {
  if (addressId) {
    setDeletedEmployers(deletedEmployers => {
      return {
        ...deletedEmployers,
        presentEmployer: { id, addressId, isDeleted: true },
      };
    });
  }

  const presentEmployer = getValues().presentEmployer;
  Object.keys(presentEmployer).forEach(field => {
    setValue(`presentEmployer.${field}`, null, { shouldDirty: true });
  });
  setValue(
    `presentEmployer.lengthOfEmployment`,
    appStrings.studentProfile.yearsAndMonths(0, 0)
  );

  setEmployerInputs(employerInputs => {
    return { ...employerInputs, presentEmployer: false };
  });

  if (addressId) {
    setTimeout(() => {
      saveDeletedEmployers({studentId, data: getValues(), setValue, reset});
    });
  }
};

export const handleDeleteFutureEmployer = ({
  id,
  addressId,
  setDeletedEmployers,
  setEmployerInputs,
  getValues,
  setValue,
  studentId,
  reset
}) => {
  if (addressId) {
    setDeletedEmployers(deletedEmployers => {
      return {
        ...deletedEmployers,
        futureEmployer: { id, addressId, isDeleted: true },
      };
    });
  }

  const futureEmployer = getValues().futureEmployer;
  Object.keys(futureEmployer).forEach(field => {
    setValue(`futureEmployer.${field}`, null, { shouldDirty: true });
  });

  setEmployerInputs(employerInputs => {
    return { ...employerInputs, futureEmployer: false };
  });

  if (addressId) {
    setTimeout(() => {
      saveDeletedEmployers({studentId, data: getValues(), setValue, reset});
    });
  }
};

export const handleDeletePreviousEmployer = ({
  index,
  id,
  addressId,
  setDeletedEmployers,
  setEmployerInputs,
  setValue,
  getValues,
  studentId,
  reset
}) => {
  if (addressId) {
    setDeletedEmployers(emp => {
      const outputState = emp.previousEmployers
        ? emp.previousEmployers.slice(0)
        : [];
      outputState.push({ id, addressId, isDeleted: true });

      return {
        ...emp,
        previousEmployers: outputState,
      };
    });

    setValue('', null, { shouldDirty: true });
  } else {
    getValues().previousEmployers.splice(index, 1);
  }

  setEmployerInputs(emp => {
    const outputState = emp.previousEmployers.slice(0);
    // `delete` removes the element while preserving the indexes.
    delete outputState[index];
    return {
      ...emp,
      previousEmployers: outputState,
      prevEmpActualLength: emp.prevEmpActualLength - 1,
    };
  });

  if (addressId) {
    setTimeout(() => {
      saveDeletedEmployers({studentId, data: getValues(), setValue, reset});
    });
  }
};

export const handleAddPresentEmployer = (employerInputs, setEmployerInputs) => {
  if (!employerInputs.presentEmployer) {
    setEmployerInputs({ ...employerInputs, presentEmployer: true });
  }
};
const handleAddFutureEmployer = (employerInputs, setEmployerInputs) => {
  if (!employerInputs.futureEmployer) {
    setEmployerInputs({ ...employerInputs, futureEmployer: true });
  }
};

const handleAddPreviousEmployer = (employerInputs, setEmployerInputs) => {
  if (!employerInputs.prevEmpActualLength < maxEmployerNumber) {
    setEmployerInputs(emp => {
      const outputState = emp.previousEmployers.slice(0);
      outputState.push('');
      return {
        ...emp,
        previousEmployers: outputState,
        prevEmpActualLength: emp.prevEmpActualLength + 1,
      };
    });
  }
};

export const getSpeedDialOptions = ({ employerInputs, setEmployerInputs }) => {
  return [
    {
      text: !employerInputs.presentEmployer
        ? appStrings.studentProfile.addPresentEmployer
        : appStrings.studentProfile.presentEmployerAdded,
      icon: Boolean(employerInputs.presentEmployer) ? (
        <CheckIcon />
      ) : (
        <AddIcon />
      ),
      onClick: () =>
        handleAddPresentEmployer(employerInputs, setEmployerInputs),
      disabled: Boolean(employerInputs.presentEmployer),
    },
    {
      text: !employerInputs.futureEmployer
        ? appStrings.studentProfile.addFutureEmployer
        : appStrings.studentProfile.futureEmployerAdded,
      icon: Boolean(employerInputs.futureEmployer) ? (
        <CheckIcon />
      ) : (
        <AddIcon />
      ),
      onClick: () => handleAddFutureEmployer(employerInputs, setEmployerInputs),
      disabled: Boolean(employerInputs.futureEmployer),
    },
    {
      text:
        employerInputs.prevEmpActualLength >= maxEmployerNumber
          ? appStrings.studentProfile.previousEmployerAdded
          : appStrings.studentProfile.addPreviousEmployer,
      icon:
        employerInputs.prevEmpActualLength >= maxEmployerNumber ? (
          <CheckIcon />
        ) : (
          <AddIcon />
        ),
      onClick: () =>
        handleAddPreviousEmployer(employerInputs, setEmployerInputs),
      disabled: employerInputs.prevEmpActualLength >= maxEmployerNumber,
    },
  ];
};

export const saveDeletedEmployers = ({studentId, data, setValue, reset}) => {
  saveEmployerInformation({id: studentId, data, setValue})
    .then(() => reset(data));
}
